import axios, { AxiosPromise } from "axios";
import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";

import {
  BagRequestItem,
  BagResponseItem,
  Combo,
  Condiment,
  ItemEvent,
  OrderingSystem,
  PurchaseOrderLoyaltyDiscount,
  PurchaseOrderSpecialDiscount,
  RetailModifiedItem,
  UpdateShoppingBagRequest,
  UpdateShoppingBagResponse,
} from "assets/dtos/anywhere-dto";

import { UPDATE_SHOPPING_BAG } from "endpoints/order.endpoints";

import {
  PortionedCondiment,
  isFavoriteOrderCondiment,
  isPortionedCondiment,
} from "util/Customization.util";
import {
  CustomizedItem,
  createItemsForRequests,
  flattenBag,
  isCustomizedItem,
} from "util/Order.util";
import {
  FavoriteOrderPricedCondiment,
  ReorderedShoppingBagItem,
  isReorderedShoppingBagItem,
} from "util/Reorder.util";
import { postWarningLog } from "util/ServerLogging.util";

export interface ShoppingBag {
  items: ShoppingBagItem[];
  combos: ShoppingBagCombo[];
}

export interface BagRequestItemEvent extends BagRequestItem {
  event: ItemEvent;
}

export interface ShoppingBagEntity {
  id: number;
  quantity: number;
}

export interface CustomizedShoppingBagItem extends ShoppingBagEntity {
  itemDetails: CustomizedItem;
  itemCustomizationId: string;
}

export type ShoppingBagItem = CustomizedShoppingBagItem | ReorderedShoppingBagItem;

export interface ShoppingBagCombo extends ShoppingBagEntity {
  items: (CustomizedItem | ReorderedShoppingBagItem)[];
  comboDetails: Combo;
}

export function isCustomizedShoppingBagItem(
  //eslint-disable-next-line
  shoppingBagItem: any
): shoppingBagItem is CustomizedShoppingBagItem {
  return shoppingBagItem && shoppingBagItem.itemDetails;
}

//eslint-disable-next-line
export function isShoppingBagCombo(shoppingBagCombo: any): shoppingBagCombo is ShoppingBagCombo {
  return shoppingBagCombo && shoppingBagCombo.comboDetails;
}

//eslint-disable-next-line
export function isShoppingBagItem(item: any): item is ShoppingBagItem {
  return isCustomizedShoppingBagItem(item) || isReorderedShoppingBagItem(item);
}

export function isLoyaltyDiscount(
  //eslint-disable-next-line
  loyaltyDiscount: any
): loyaltyDiscount is PurchaseOrderLoyaltyDiscount {
  return loyaltyDiscount && loyaltyDiscount.offerId;
}

export function isSpecialDiscount(
  //eslint-disable-next-line
  specialDiscount: any
): specialDiscount is PurchaseOrderSpecialDiscount {
  return specialDiscount && specialDiscount.specialId;
}

export function updateShoppingBag(
  request: UpdateShoppingBagRequest,
  orderSessionId?: string
): AxiosPromise<UpdateShoppingBagResponse> {
  return axios({
    method: "PUT",
    url: UPDATE_SHOPPING_BAG,
    data: request,
    headers: { sessionId: orderSessionId },
  });
}

/**
 * Transform the shopping bag contents into a list of items for consumption
 * by the Update Shopping Bag request.
 */
export function createUpdateShoppingBagRequest(
  storeNumber: number,
  orderingSystem: OrderingSystem,
  shoppingBag: ShoppingBag
): UpdateShoppingBagRequest {
  const items = createItemsForRequests(shoppingBag) as BagRequestItem[];

  return {
    storeNumber: storeNumber,
    orderingSystem: orderingSystem,
    // TODO: Figure out if we actually ever have a false here
    loyaltyCardHolder: true,
    items: items,
  };
}

export interface BagUpdates {
  updatedBag: ShoppingBag;
  availability: string[];
  prices: string[];
}

export function updateBagFromServerCall(
  bag: ShoppingBag,
  serverUpdate: BagResponseItem[],
  preUpdatedItems: BagRequestItemEvent[]
): BagUpdates {
  serverUpdate.sort((a, b) => (a.lineNumber < b.lineNumber ? -1 : 1));
  const newBag: ShoppingBag = { ...bag };
  const flattenedBag = flattenBag(newBag);

  if (flattenedBag.length !== serverUpdate.length) {
    throw new SheetzError("Bag length does not match server response length for comparison", {
      userReadableMessage: "Unable to add item. Please try again.",
      primaryButton: SheetzErrorButtonType.TRY_AGAIN,
      secondaybutton: SheetzErrorButtonType.CLOSE,
    });
  }

  const bagEntityMap = mapFlattenedBagItemsToBagEntities(flattenedBag, newBag);
  const itemsToRemove: number[] = [];
  const itemsWithUpdatedPrice: [number, number][] = [];
  const availabilitySet = new Set<string>();
  const priceSet = new Set<string>();

  flattenedBag.forEach((item, itemIndex) => {
    const bagResponseItem = serverUpdate[itemIndex];

    if (
      (isCustomizedItem(item) &&
        item.retailModifiedItem?.retailModifiedItemId !== bagResponseItem.retailModifiedItemId) ||
      (isReorderedShoppingBagItem(item) &&
        item.retailModifiedItemId !== bagResponseItem.retailModifiedItemId)
    ) {
      postWarningLog(
        `Mismatch in price and availability updates - item with RMIID=${bagResponseItem.retailModifiedItemId} 
        in position=${itemIndex} does not match.`
      );
    }

    if (bagResponseItem.availability !== "AVAILABLE") {
      /*
       * This item index is both the index of the flattened bag, and the key for the map,
       * so we know which items (or combo containing the item) are removed from the bag
       * despite being in a flattened order at the moment.
       */
      itemsToRemove.push(itemIndex);
    } else {
      const serverUpdatePrice = calculateServerUpdatePrice(bagResponseItem);

      // If there is a special discount, then bypass price comparison as quantity can affect pricing.
      if (serverUpdatePrice !== item.price && bagResponseItem.specialDiscountAmount === 0) {
        // If the price of the item wasn't previously known, then there is no need to notify the user about a price "update".
        if (item.price !== undefined && item.price !== 0) {
          itemsWithUpdatedPrice.push([itemIndex, serverUpdatePrice]);
        }
      }

      item.price = serverUpdatePrice;
      // Update the discount regardless, since changing quantity can affect it (i.e. 2 for $1 hot dogs)
      const associatedCombo = newBag.combos.find((combo) => combo.id === item.comboId);
      // The specialDiscountAmount factors in combo quantity, so we must divide by combo quantity to get the item discount.
      item.discount = bagResponseItem.specialDiscountAmount / (associatedCombo?.quantity ?? 1);

      // Add any extra condiments sent from the server for item normalization and required condiments.
      // Extra condiments should come in from the server at the end of the list.
      // Handle this before removing, so the index on existing condiments is not shifted.

      const condimentsToAdd = bagResponseItem.condiments.slice(item.condiments?.length || 0);
      if (condimentsToAdd.length > 0) {
        if (isReorderedShoppingBagItem(item)) {
          condimentsToAdd.forEach((condimentToAdd) => {
            const newCondiment: FavoriteOrderPricedCondiment = {
              retailModifiedItemId: condimentToAdd.retailModifiedItemId,
              receiptText: condimentToAdd.receiptText,
            };
            item.condiments
              ? item.condiments.push(newCondiment)
              : (item.condiments = [newCondiment]);
          });
        } else {
          condimentsToAdd.forEach((condimentToAdd) => {
            const condimentRMI: RetailModifiedItem = {
              retailModifiedItemId: condimentToAdd.retailModifiedItemId,
              receiptText: condimentToAdd.receiptText,
              name: "",
              image: "2Col_noImage.png",
              price: condimentToAdd.price,
            };
            const newCondiment: Condiment = {
              conflicts: [],
              correlates: [],
              portions: {
                regular: condimentRMI,
              },
              retailModifiedItem: condimentRMI,
              isAvailable: condimentToAdd.availability === "AVAILABLE",
              sortingRank: 0,
              tags: [],
              image: "2Col_noImage.png",
            };
            item.condiments
              ? item.condiments.push(newCondiment)
              : (item.condiments = [newCondiment]);
          });
        }
      }

      if (item.condiments && item.condiments.length > 0) {
        const condimentsToRemove: number[] = [];
        item.condiments.forEach(
          (
            condiment: Condiment | PortionedCondiment | FavoriteOrderPricedCondiment,
            condimentIndex: number
          ) => {
            const bagResponseCondiment = bagResponseItem.condiments[condimentIndex];
            let receiptText = "";
            let condimentRMI: RetailModifiedItem | undefined = undefined;
            if (isPortionedCondiment(condiment)) {
              receiptText = condiment.condiment.retailModifiedItem.receiptText;
              condimentRMI = condiment.condiment.retailModifiedItem;
            } else if (isFavoriteOrderCondiment(condiment)) {
              receiptText = condiment.receiptText;
            } else {
              receiptText = condiment.retailModifiedItem.receiptText;
              condimentRMI = condiment.retailModifiedItem;
            }

            if (bagResponseCondiment.availability !== "AVAILABLE") {
              condimentsToRemove.push(condimentIndex);
              availabilitySet.add(receiptText);
            } else {
              if (bagResponseCondiment.price !== condimentRMI?.price) {
                if (isFavoriteOrderCondiment(condiment)) {
                  condiment.price = bagResponseCondiment.price;
                } else if (condimentRMI) {
                  condimentRMI.price = bagResponseCondiment.price;
                }
              }
            }
          }
        );
        // This code is duplicated for the different types because of `ts(2349)`.
        // More info here: https://github.com/microsoft/TypeScript/issues/33591
        if (condimentsToRemove.length > 0 && !isReorderedShoppingBagItem(item)) {
          item.condiments = item.condiments.filter(
            (condiment, index) => !condimentsToRemove.includes(index)
          );
        } else if (condimentsToRemove.length > 0 && isReorderedShoppingBagItem(item)) {
          item.condiments = item.condiments.filter(
            (condiment, index) => !condimentsToRemove.includes(index)
          );
        }
      }
    }
  });

  if (itemsToRemove.length > 0) {
    for (const itemKey of itemsToRemove) {
      const entityToRemove = bagEntityMap.get(itemKey);
      if (entityToRemove) {
        if (Array.isArray(entityToRemove)) {
          const comboToRemove = newBag.combos.find((combo) => combo.id === entityToRemove[0].id);
          comboToRemove && availabilitySet.add(comboToRemove.comboDetails.name);
          newBag.combos = newBag.combos.filter((combo) => combo.id !== entityToRemove[0].id);
        } else {
          const itemToRemove: ShoppingBagItem | undefined = newBag.items.find(
            (item) => item.id === entityToRemove.id
          );
          if (itemToRemove) {
            if (isCustomizedShoppingBagItem(itemToRemove)) {
              const item = itemToRemove.itemDetails;
              availabilitySet.add(
                item.retailModifiedItem?.receiptText || item.retailItem?.receiptText || ""
              );
            } else if (isReorderedShoppingBagItem(itemToRemove)) {
              availabilitySet.add(itemToRemove.receiptText);
            }
          }
          newBag.items = newBag.items.filter((item) => item.id !== entityToRemove.id);
        }
      }
    }
  }

  if (itemsWithUpdatedPrice.length > 0) {
    // While we can adjust the prices as we go through the bag, we wait until afterwards to make the messages for the actionsheet,
    // as even though a price can change on an item, we may need to show the user a combo instead of just an item.
    for (const itemKey of itemsWithUpdatedPrice) {
      const entityWithUpdatedPrice = bagEntityMap.get(itemKey[0]);
      if (entityWithUpdatedPrice) {
        if (Array.isArray(entityWithUpdatedPrice)) {
          const updatedCombo = newBag.combos.find(
            (combo) => combo.id === entityWithUpdatedPrice[0].id
          );
          updatedCombo && priceSet.add(updatedCombo.comboDetails.name);
        } else {
          const updatedItem: ShoppingBagItem | undefined = newBag.items.find(
            (item) => item.id === entityWithUpdatedPrice.id
          );
          if (updatedItem) {
            if (isCustomizedShoppingBagItem(updatedItem)) {
              const item = updatedItem.itemDetails;
              priceSet.add(
                item.retailModifiedItem?.receiptText || item.retailItem?.receiptText || ""
              );
            } else if (isReorderedShoppingBagItem(updatedItem)) {
              priceSet.add(updatedItem.receiptText);
            }
          }
        }
      }
    }
  }

  // During updateShoppingBag server call this data is lost on the updated items
  if (preUpdatedItems && preUpdatedItems.length > 0) {
    newBag.items.forEach((item, itemIndex) => {
      if (isCustomizedItem(item)) {
        const matchedItem = preUpdatedItems.find(
          (itemWithEvent) =>
            item.retailModifiedItem?.retailModifiedItemId === itemWithEvent.retailModifiedItemId &&
            itemIndex === itemWithEvent.lineNumber
        );
        if (matchedItem) {
          item.event = matchedItem.event;
        }
      }
    });
  }

  return {
    updatedBag: newBag,
    availability: Array.from(availabilitySet),
    prices: Array.from(priceSet),
  };
}

// This function acts as an intermediary so we can map the responses from the server on a bag update back into the entities of the bag.
// The map it creates corresponds to positions in the server response and the bag.
// When flattened to send to the server, it is returned in the same order.
// The key is the array position of the server (So, line number 1 is array[0] and so on), with the ShoppingBagEntity it corresponds with.

// In the event of a combo, it contains a tuple of the entity, and the index of the item in the combo.
// In this case, a combo with three items makes three entries, [refToCombo, 0], [refToCombo, 1], [refToCombo, 2]
function mapFlattenedBagItemsToBagEntities(
  flattenedBag: (CustomizedItem | ReorderedShoppingBagItem)[],
  bag: ShoppingBag
): Map<number, ShoppingBagEntity | [ShoppingBagEntity, number]> {
  const itemMap: Map<number, ShoppingBagEntity | [ShoppingBagEntity, number]> = new Map<
    number,
    ShoppingBagEntity | [ShoppingBagEntity, number]
  >();
  const allBagEntities = [...bag.items, ...bag.combos];

  allBagEntities.sort((a, b) => a.id - b.id);
  // At this point, iterating through allBagEntities should match up to the flattenedBag in terms of order... So go through and pair it up.
  let flattenedBagPos = 0;
  allBagEntities.forEach((bagEntity) => {
    if (isCustomizedShoppingBagItem(bagEntity) || isReorderedShoppingBagItem(bagEntity)) {
      itemMap.set(flattenedBagPos, bagEntity);
      flattenedBagPos++;
    } else if (isShoppingBagCombo(bagEntity)) {
      const comboLength = bagEntity.items.length;
      for (let i = 0; i < comboLength; i++) {
        itemMap.set(flattenedBagPos, [bagEntity, i]);
        flattenedBagPos++;
      }
    }
  });
  return itemMap;
}

function calculateServerUpdatePrice(item: BagResponseItem): number {
  let price = item.price;
  item.condiments.forEach((condiment) => {
    price += condiment.price;
  });
  return price;
}

/**
 * The combo price is all items of the combos added together, including their discounts (i.e. -0.40).
 * While the item price needs to be multiplied by the quantity, the discount already comes multiplied from the server.
 */
export function calculateComboPrice(combo: ShoppingBagCombo, noDiscount = false): number {
  let comboPrice = 0;

  combo.items.forEach((item) => {
    if (item.price === undefined) {
      return;
    }

    if (item.event?.source === "REORDER") {
      comboPrice += noDiscount ? item.price : item.price + (item.discount ?? 0);
    } else {
      comboPrice += noDiscount
        ? item.price
        : item.price * (item.quantity ?? 1) + (item.discount ?? 0);
    }
  });

  return comboPrice * combo.quantity;
}

export function calculateShoppingBagSubTotal(shoppingBag?: ShoppingBag): number {
  let subtotal = 0;

  if (shoppingBag) {
    shoppingBag.items.forEach((shoppingBagItem) => {
      if (isCustomizedShoppingBagItem(shoppingBagItem)) {
        let itemPrice = shoppingBagItem.itemDetails.price * shoppingBagItem.quantity;
        if (shoppingBagItem.itemDetails.discount !== undefined) {
          itemPrice += shoppingBagItem.itemDetails.discount;
        }
        subtotal += itemPrice;
      } else {
        // The price for reordered items is initially missing until the shopping bag is sent to the server for price/availability updates.
        let itemPrice = (shoppingBagItem.price ?? 0) * shoppingBagItem.quantity;
        if (shoppingBagItem.discount) {
          itemPrice += shoppingBagItem.discount;
        }
        subtotal += itemPrice;
      }
    });

    shoppingBag.combos.forEach((shoppingBagCombo) => {
      const comboPrice = calculateComboPrice(shoppingBagCombo);
      subtotal += comboPrice;
    });
  }

  return subtotal;
}
