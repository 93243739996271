import { Condiment, Selector } from "assets/dtos/anywhere-dto";

import { ItemCustomization } from "util/Customization.util";
import { CustomizedItem } from "util/Order.util";
import { deriveCondiments, getAllTags } from "util/Tag.util";

function deriveAutoCondiments(
  selector: Selector,
  existingTags: string[],
  itemCustomization: ItemCustomization
): Condiment[] {
  const condiments = deriveCondiments(
    selector.tags ?? [],
    existingTags,
    itemCustomization,
    selector.okIfEmpty ?? false
  );

  if (!selector.okToDeriveMany && condiments.length > 1) {
    // TODO: Possibly throw error instead that is caught and logged to splunk.
    console.warn(`More than one condiment derived for auto condiment selector with tags='${selector.tags}'
    and itemCustomizationId='${itemCustomization.itemCustomizationId}'`);
    return [condiments[0]];
  }

  return condiments;
}

export function processAutoCondimentSelector(
  selector: Selector,
  item: CustomizedItem,
  itemCustomization: ItemCustomization
): void {
  const exisitingTags = getAllTags(item);
  const derivedCondiments = deriveAutoCondiments(selector, exisitingTags, itemCustomization);
  Array.prototype.push.apply(item.condiments, derivedCondiments);
}
