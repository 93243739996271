import { AxiosError } from "axios";
import React, { useContext, useState } from "react";

import { BFFErrorResponse } from "axiosConfig";

import "./OrderDetailListItem.scss";

import { CreatePurchaseOrderRequest } from "assets/dtos/anywhere-dto";

import { OrderSubviewProps } from "components/Order/Order";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";

import { AppContext } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";
import { isInNativeMobileContext } from "util/MobileApp.util";
import {
  createPurchaseOrder,
  createPurchaseOrderRequest,
  getTipChoiceFromPercentage,
} from "util/Order.util";

const OrderRetry = (props: OrderSubviewProps) => {
  const appContext = useContext(AppContext);
  const [tries, setTries] = useState(0);

  function orderRetrySelected(): void {
    if (props.orderSession.purchaseOrder === undefined) {
      return;
    }
    if (props.orderSession.shoppingBag === undefined) {
      return;
    }
    const request: CreatePurchaseOrderRequest = createPurchaseOrderRequest(
      props.orderSession.purchaseOrder?.storeNumber,
      isInNativeMobileContext() ? "MOBILE" : "WEB",
      props.orderSession.pickupLocation !== undefined
        ? props.orderSession.pickupLocation
        : "IN_STORE",
      props.orderSession.purchaseOrder.paymentMode,
      props.orderSession.shoppingBag,
      true, // This indicates that the automaticOffers were returned on "Retry"
      props.orderSession.asapOrder ? undefined : props.orderSession.pickupTime,
      props.orderSession.purchaseOrder?.integratedDelivery?.deliveryAddressId,
      props.orderSession.deliveryTipChoice,
      props.orderSession.deliveryTipAmount
    );

    createPurchaseOrder(request, props.orderSession.orderSessionId)
      .then((response) => {
        const offerStatus = response.data.purchaseOrder.redeemableOfferStatus;

        if (offerStatus && offerStatus === "FAILED") {
          setTries((currentValue) => currentValue + 1);
          return;
        }

        props.dispatch({ type: "SAVE_PURCHASE_ORDER", payload: response.data.purchaseOrder });
        props.dispatch({
          type: "SET_DELIVERY_TIP_SUGGESTIONS",
          payload: response.data.suggestedDeliveryTips,
        });
        props.dispatch({ type: "SAVE_ORDER_TOKEN", payload: response.headers["order-token"] });
        if (response.data.purchaseOrder.integratedDelivery !== undefined) {
          if (response.data.purchaseOrder.integratedDelivery.deliveryTipPercentage !== undefined) {
            props.dispatch({
              type: "SET_DELIVERY_TIP_CHOICE",
              payload: getTipChoiceFromPercentage(
                response.data.purchaseOrder.integratedDelivery.deliveryTipPercentage
              ),
            });
          } else {
            props.dispatch({
              type: "SET_DELIVERY_TIP_AMOUNT",
              payload: response.data.purchaseOrder.integratedDelivery.deliveryTip,
            });
          }
        }
      })
      .catch((error: Error | AxiosError<BFFErrorResponse>) => {
        setTries((currentValue) => currentValue + 1);
      });
  }
  if (props.orderSession.purchaseOrder?.redeemableOfferStatus === "SUCCESS") {
    return null;
  }

  return (
    <div className="order-checkout-info">
      <div className="error-details">
        {getIcon(IconType.alert, "alert-icon")}
        {tries < 3 ? (
          <>
            <div className="error-heading">
              Unable to load your Rewardz and Offerz
              <p>{`Please retry or continue without Rewardz and Offerz`}</p>
            </div>
            <div className="error-details-button">
              <SheetzButton
                buttonColor={ButtonColor.darkGray}
                transparentButton
                className="order-retry"
                label="Retry"
                onClick={(): void => {
                  appContext.showLoading();
                  orderRetrySelected();
                  setTimeout(() => {
                    appContext.hideLoading();
                  }, 1000);
                }}
              />
            </div>
          </>
        ) : (
          <div className="error-heading">
            Unable to load your Rewardz and Offerz
            <p>{`You may continue without Rewardz and Offerz`}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderRetry;
