import React from "react";

import { Condiment, RetailModifiedItem, Selector, SwitchDTO } from "assets/dtos/anywhere-dto";

import SelectorContainer from "components/Order/ItemCustomization/SelectorContainer/SelectorContainer";
import MultiOptionSwitchSelector from "components/Order/ItemCustomization/Selectors/MultiOptionSwitchSelector";
import NoOptionSwitchSelector from "components/Order/ItemCustomization/Selectors/NoOptionSwitchSelector";
import { OrderSessionAction } from "components/Order/OrderSessionReducer";

import {
  InlineSubOption,
  ItemCustomization,
  MultiOptionSwitchOption,
  handleTagAlongTags,
  isCondimentInList,
} from "util/Customization.util";
import { CustomizedItem } from "util/Order.util";
import { deriveCondiment, deriveSwitch, deriveSwitchSize, getAllTags } from "util/Tag.util";

export function processNoOptionSwitchSelector(
  selector: Selector,
  selected: boolean | undefined,
  item: CustomizedItem,
  itemCustomization: ItemCustomization,
  dispatch: React.Dispatch<OrderSessionAction>,
  index: number
): JSX.Element | null {
  if (!selector.switchTags) {
    // TODO: Possibly throw error instead that is caught and logged to splunk.
    console.warn(`Switch selector has no switch tags switchText='${selector.text}', 
    and itemCustomizationId='${itemCustomization.itemCustomizationId}'`);
    return null;
  }

  const switches = filterCurrentRIFromSwitches(itemCustomization.retailItem.switches, item);

  const switchRI = deriveSwitch(selector.switchTags, itemCustomization, switches);

  if (!switchRI || !item.retailModifiedItem) {
    return null;
  }

  let switchRMI: RetailModifiedItem | undefined;
  // If there are no tags on the original RMI, then we can assume that there is only one RMI under the switch RI.
  if (item.retailModifiedItem.tags === undefined) {
    switchRMI = switchRI.retailModifiedItems[0];
  } else {
    switchRMI = deriveSwitchSize(switchRI.retailModifiedItems, item.retailModifiedItem.tags);
  }

  if (!switchRMI) {
    return null;
  }

  if (selected) {
    item.retailItem = switchRI;
    item.retailModifiedItem = switchRMI;
  }

  function switchToggled(): void {
    dispatch({
      type: "SET_ITEM_CUSTOMIZATIONS",
      payload: [selector.text ?? "", !selected],
    });
  }

  return (
    <SelectorContainer key={index} selector={selector}>
      <NoOptionSwitchSelector
        selector={selector}
        isSelected={!!selected}
        switchRMI={switchRMI}
        onSwitchToggled={switchToggled}
      />
    </SelectorContainer>
  );
}

// Remove the existing RI that is currently the customized item RI, so we don't try and switch it to itself.
export function filterCurrentRIFromSwitches(
  switchDTOs: SwitchDTO[],
  item: CustomizedItem
): SwitchDTO[] {
  let switches: SwitchDTO[];
  if (item.retailItem !== undefined) {
    switches = switchDTOs.filter(
      (switchRi) => switchRi.retailItemId !== item.retailItem?.retailItemId
    );
  } else {
    switches = switchDTOs;
  }
  return switches;
}

export function processMultiOptionSwitchSelector(
  selector: Selector,
  selectorState: MultiOptionSwitchOption | undefined,
  item: CustomizedItem,
  itemCustomization: ItemCustomization,
  dispatch: React.Dispatch<OrderSessionAction>,
  index: number
): JSX.Element | null {
  if (!selector.switchTags) {
    // TODO: Possibly throw error instead that is caught and logged to splunk.
    console.warn(`Switch selector has no switch tags switchText='${selector.text}', 
    and itemCustomizationId='${itemCustomization.itemCustomizationId}'`);
    return null;
  }

  const existingTags = getAllTags(item);
  const options = deriveMultiOptionSwitchOptions(selector, existingTags, itemCustomization);

  if (!options.length) {
    return null;
  }

  const switches = filterCurrentRIFromSwitches(itemCustomization.retailItem.switches, item);

  // The existing item tags are needed as well, as the switch RIs may have tags that rely on a size (i.e. 2 pack, 3 pack).
  const switchRI = deriveSwitch(
    existingTags.concat(selector.switchTags),
    itemCustomization,
    switches
  );

  if (!switchRI || !item.retailModifiedItem) {
    return null;
  }

  let switchRMI: RetailModifiedItem | undefined;
  // If there are no tags on the original RMI, then we can assume that there is only one RMI under the switch RI.
  if (item.retailModifiedItem.tags === undefined) {
    switchRMI = switchRI.retailModifiedItems[0];
  } else {
    switchRMI = deriveSwitchSize(switchRI.retailModifiedItems, item.retailModifiedItem.tags);
  }

  if (!switchRMI) {
    return null;
  }

  let selectedOption = selectorState;
  if (selectedOption) {
    const selectedCondiment = selectedOption.selectedInlineSubOption
      ? selectedOption.selectedInlineSubOption.condiment
      : selectedOption.condiment;

    const condimentList = options.reduce<Condiment[]>((condiments, option) => {
      if (option.inlineSubOptions) {
        return condiments.concat([
          option.condiment,
          ...option.inlineSubOptions.map((inlineOption) => inlineOption.condiment),
        ]);
      } else {
        return condiments.concat([option.condiment]);
      }
    }, [] as Condiment[]);

    if (!isCondimentInList(condimentList, selectedCondiment)) {
      selectedOption = undefined;
    } else {
      item.retailItem = switchRI;
      item.retailModifiedItem = switchRMI;
      item.condiments.push(selectedCondiment);

      // If the selected option has tagAlongTags, then derive that condiment as well and add it to the item.
      handleTagAlongTags(selectedOption.option, itemCustomization, item);
    }
  }

  const switchOptionSelected = (switchOption: MultiOptionSwitchOption): void => {
    dispatch({ type: "SET_ITEM_CUSTOMIZATIONS", payload: [selector.text ?? "", switchOption] });
  };

  const switchOptionDeselected = (): void => {
    dispatch({ type: "REMOVE_CUSTOMIZATION_SELECTION", payload: selector.text ?? "" });
  };

  return (
    <SelectorContainer key={index} selector={selector}>
      <MultiOptionSwitchSelector
        selector={selector}
        options={options}
        selectedOption={selectedOption}
        onSwitchOptionSelected={switchOptionSelected}
        onSwitchOptionDeselected={switchOptionDeselected}
      />
    </SelectorContainer>
  );
}

export function deriveMultiOptionSwitchOptions(
  selector: Selector,
  existingTags: string[],
  itemCustomization: ItemCustomization
): MultiOptionSwitchOption[] {
  const options: (MultiOptionSwitchOption | null)[] = !selector.options
    ? []
    : selector.options.map((option) => {
        const derivedCondiment = deriveCondiment(
          option.tags ?? [],
          existingTags,
          itemCustomization,
          true
        );

        if (!derivedCondiment) {
          return null;
        }

        let inlineSubOptions: InlineSubOption[] = [];
        if (option.inlineSubSelector?.options) {
          inlineSubOptions = option.inlineSubSelector.options.map((option) => {
            const condiment = deriveCondiment(
              option.tags ?? [],
              existingTags,
              itemCustomization,
              false
            );
            return { option: option, condiment: condiment } as InlineSubOption;
          });
        }

        if (option.inlineSubSelector?.noOption) {
          const noOption = option.inlineSubSelector.noOption;
          const noCondiment = deriveCondiment(
            noOption.tags ?? [],
            existingTags,
            itemCustomization,
            false
          );
          inlineSubOptions.push({ option: noOption, condiment: noCondiment });
        }

        return {
          option: option,
          condiment: derivedCondiment,
          inlineSubOptions,
        } as MultiOptionSwitchOption;
      });

  // Filter out any elements that are null and log a warning if there aren't any options left.
  const filteredOptions = options.filter((option) => option !== null) as MultiOptionSwitchOption[];
  if (!filteredOptions.length) {
    // TODO: Possibly throw error instead that is caught and logged to splunk.
    console.warn(`No options derived a condiment for switch selector with name='${selector.text}'
    and itemCustomizationId='${itemCustomization.itemCustomizationId}'`);
  }

  return filteredOptions;
}
