import React, { ReactElement, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { createErrorInterceptor } from "axiosConfig";

import { AppContext } from "util/AppContext.util";

const ToastHelper = (): ReactElement => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    createErrorInterceptor(appContext.showToast, navigate);
    // Specifically disabling this warning because otherwise this would call every time the context changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default ToastHelper;
