const helpDeskSubjects = [
  { subject: "Food Comments", subjectId: 1 },
  { subject: "Service Issue", subjectId: 2 },
  { subject: "Beverage Comments", subjectId: 3 },
  { subject: "Transaction Concern", subjectId: 4 },
  { subject: "Online Ordering", subjectId: 5 },
  { subject: "Login", subjectId: 6 },
  { subject: "Account Questions", subjectId: 7 },
  { subject: "Website", subjectId: 8 },
  { subject: "Mobile App", subjectId: 9 },
  { subject: "Jobs", subjectId: 10 },
  { subject: "Store Conditions", subjectId: 11 },
  { subject: "Fuel", subjectId: 12 },
  { subject: "Fundraising", subjectId: 13 },
  { subject: "Suggestions", subjectId: 14 },
  { subject: "California Privacy", subjectId: 15 },
  { subject: "General Comments", subjectId: 16 },
];

export default helpDeskSubjects;
