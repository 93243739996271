import React from "react";
import { NumericFormat } from "react-number-format";

import "./DiscountDisplay.scss";

import { IconType, getIcon } from "util/Icon.util";

interface DiscountDisplayProps {
  discount?: number;
  name?: string;
  showDiscountPrice?: boolean;
}

const DiscountDisplay = (props: DiscountDisplayProps) => {
  return (
    <div className="discount-display">
      <div className="discount-left">
        <div className="discount-icon">{getIcon(IconType.offer, "offer-icon")}</div>
        <div className="discount-text">
          <p>{props.name}</p>
        </div>
      </div>

      {props.showDiscountPrice && (
        <div className="discount-price">
          <p>
            <NumericFormat
              value={props.discount}
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
            />
          </p>
        </div>
      )}
    </div>
  );
};

export default DiscountDisplay;
