import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

import "./Tabs.scss";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";

import { useMediaQuery } from "hooks";

import { desktopMediaQuery } from "util/AppContext.util";

interface TabProps {
  label: string | JSX.Element;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  disabled: boolean;
  activeTab: boolean;
}

interface TabsProps {
  tabs: TabProps[];
  showMenuButton?: boolean;
}

const Tab = (props: TabProps) => {
  return (
    <button
      onClick={props.onClick}
      className={props.className + " tab-button"}
      disabled={props.disabled}
    >
      {props.label}
    </button>
  );
};

const Tabs = (props: PropsWithChildren<TabsProps>) => {
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);
  const navigate = useNavigate();
  const tabList = props.tabs.map((tab, index) => {
    return (
      <Tab
        key={index}
        onClick={tab.onClick}
        className={tab.activeTab ? tab.className + " active-tab" : tab.className}
        disabled={tab.disabled}
        label={tab.label}
        activeTab={tab.activeTab}
      />
    );
  });

  return (
    <div className="tab-container">
      <div className="tab-bar">
        <div className="tab-list">
          {tabList}
          {props.showMenuButton && useDesktopView && (
            <SheetzButton
              className="view-menu-button"
              label="View Main Menu"
              onClick={(): void => navigate("/order/menu")}
            />
          )}
        </div>
      </div>
      <div className="tab-content">{props.children}</div>
    </div>
  );
};

export default Tabs;
