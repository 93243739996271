export const GET_STORE = (storeNumber: number): string => "/stores/" + storeNumber;
export const STORE_SEARCH = "/stores/search";
export const GET_STORE_ATTRIBUTES = "/storeAttributes";
export const GET_OPERATING_STATES = "/stores/getOperatingStates";
export const START_ORDER_SESSION = "/orders/startSession";
export const START_DEFAULT_REORDER_SESSION = "/orders/startDefaultReorderSession";
export const GET_FAVORITES_AND_HISTORY = "/orders/favoritesAndHistory";
export const ADD_FAVORITE_ORDER = "/orders/favorites";
export const UPDATE_OR_DELETE_FAVORITE_ORDER = (favoriteOrderId: number): string =>
  "/orders/favorites/" + favoriteOrderId;
export const GET_CURRENT_SERVER_TIME = "/orders/currentTime";
export const VALIDATE_PICKUP_TIME = "/orders/validatePickupTime";
export const GET_PAYMENT_OPTIONS = "/orders/paymentOptions";
export const DELIVERY_ADDRESSES = "/orders/delivery/addresses";
export const DELETE_DELIVERY_ADDRESS = (deliveryAddressId: number): string =>
  "/orders/delivery/addresses/" + deliveryAddressId;
export const UPDATE_DELIVERY_ADDRESS = (deliveryAddressId: number): string =>
  "/orders/delivery/addresses/" + deliveryAddressId + "/verify";
export const GET_ORDER = (orderNumber: number): string => "/orders/" + orderNumber;
export const DELIVERY_INFO = "/orders/delivery/info";
export const DELIVERY_PHONE_NUMBER = "/orders/delivery/phoneNumber";
export const DELIVERY_ESTIMATE = "/orders/delivery/estimates";
export const PURCHASE_ORDER = "/purchaseOrders";
export const SUBMIT_ORDER = "/orders";
export const CANCEL_ORDER = (orderNumber: number): string => "/orders/" + orderNumber;
export const UPDATE_SHOPPING_BAG = "/shoppingBags";
export const GET_RECEIPTS = "/orders/receipts";
