import axios, { AxiosError } from "axios";
import { createBrowserHistory } from "history";
import { useNavigate } from "react-router-dom";

import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { removeAuthorization } from "util/Authentication.util";
import {
  getAuthTokenFromNativeMobileBridge,
  isInNativeMobileContext,
  nativeMobileAuthTokenInvalid,
} from "util/MobileApp.util";
import { removeOrderSession } from "util/Storage.util";

declare module "axios" {
  interface AxiosRequestConfig {
    disableErrorMessageDisplay?: boolean;
    displayErrorInModal?: boolean;
    skipNativeAuthToken?: boolean;
  }
}

export interface BFFErrorResponse {
  message: string;
  timestamp: string;
  path: string;
}

export function setAxiosAuthorizationHeader(auth: string): void {
  if (auth.length) {
    axios.defaults.headers.common["Authorization"] = auth;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

export function setupInterceptor(): void {
  axios.interceptors.request.use((config) => {
    /**
     * Get token from JS bridge, which ensures token is not expired.
     * The mobile apps have a timer to refresh the auth token, so that a user will not get logged out.
     * We skip asking for the token from the mobile bridge when we need to override the authorization
     * header, such as in the case of force password reset.
     */
    if (isInNativeMobileContext() && !config.skipNativeAuthToken) {
      const nativeAuthToken = getAuthTokenFromNativeMobileBridge();
      if (nativeAuthToken !== null && nativeAuthToken !== undefined) {
        config.headers = { ...config.headers, Authorization: nativeAuthToken };
      } else {
        removeOrderSession();
        nativeMobileAuthTokenInvalid();
      }
    }

    config.url = config.url ? "/anybff/api".concat(config.url) : undefined;
    return config;
  });
}

export function setClientVersionHeader(version: string): void {
  axios.defaults.headers.common["Client-Version"] =
    process.env.REACT_APP_VERSION + "-" + process.env.REACT_APP_BUILD_NUMBER ?? "local";
}

export function createErrorInterceptor(
  showToast: (newTitle: string, newText: string, type: ToastType, modal?: boolean) => void,
  navigate: ReturnType<typeof useNavigate>
): void {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error: AxiosError<BFFErrorResponse>) {
      const history = createBrowserHistory({ window });

      // Handle 401 unauthorized responses
      if (error.response?.status === 401) {
        removeAuthorization();

        if (isInNativeMobileContext()) {
          nativeMobileAuthTokenInvalid();
          return;
        }

        // If we're already on the login page, simply return the rejected promise.
        if (history.location.pathname === "/auth/login") {
          return Promise.reject(error.response);
        }

        navigate(`/auth/login?destination=${history.location.pathname}${history.location.search}`, {
          state: history.location.state,
        });

        // Timeout is needed due to toast dismissal being tied to navigation.
        setTimeout(() => {
          showToast("Your session has expired.", "Please log in again.", ToastType.error);
        }, 1000);
        return Promise.reject(error.response);
      }

      // Unless specified otherwise, the default behavior for failed requests is to display an error toast.
      if (!error.config.disableErrorMessageDisplay) {
        showToast(
          "Oh Sheetz!",
          error.response?.data.message ?? "An unknown error has occurred.",
          ToastType.error,
          error.config.displayErrorInModal
        );
      }

      return Promise.reject(error);
    }
  );
}
