import SelectorContainer from "../../SelectorContainer/SelectorContainer";
import MultiSelectCondimentSelector from "./MultiSelectCondimentSelector";
import React from "react";

import { Selector } from "assets/dtos/anywhere-dto";

import { OrderSessionAction } from "components/Order/OrderSessionReducer";

import { ItemCustomization, PortionedCondiment, isCondimentInList } from "util/Customization.util";
import { CustomizedItem } from "util/Order.util";
import { deriveCondiments, getAllTags } from "util/Tag.util";

export function processMultiSelectCondimentSelector(
  selector: Selector,
  selectorState: PortionedCondiment[] | undefined,
  item: CustomizedItem,
  itemCustomization: ItemCustomization,
  dispatch: React.Dispatch<OrderSessionAction>,
  index: number
): JSX.Element | null {
  const exisitingTags = getAllTags(item);
  const condiments = deriveCondiments(
    selector.tags ?? [],
    exisitingTags,
    itemCustomization,
    selector.okIfEmpty ?? false
  ).sort((a, b) => a.sortingRank - b.sortingRank);

  if (!condiments.length) {
    return null;
  }

  const portionedCondiments: PortionedCondiment[] = condiments.map((condiment) => {
    // If a matching portionedCondiment is found as part of the selector state, add its portion to the generated PortionedCondiment.
    const selectedCondiment = selectorState?.find(
      (portionedCondiment) =>
        portionedCondiment.condiment.retailModifiedItem.retailModifiedItemId ===
        condiment.retailModifiedItem.retailModifiedItemId
    );
    return { condiment: condiment, selectedPortion: selectedCondiment?.selectedPortion };
  });

  const selectedCondiments = selectorState;
  let filteredSelectedCondiments: PortionedCondiment[] = [];
  if (selectedCondiments && selectedCondiments.length) {
    filteredSelectedCondiments = selectedCondiments.filter((portionedCondiment) => {
      const isValidCondiment = isCondimentInList(
        portionedCondiments.map((portionedCondiment) => portionedCondiment.condiment),
        portionedCondiment.condiment
      );
      if (!isValidCondiment) {
        // TODO: Anything filtered out should be removed via a state update. Need an item customization where a choice generates a conflict on a selector below it.
      }
      return isValidCondiment;
    });
  }
  Array.prototype.push.apply(item.condiments, filteredSelectedCondiments);

  const optionSelected = (selectedCondiments: PortionedCondiment[]): void => {
    dispatch({
      type: "SET_ITEM_CUSTOMIZATIONS",
      payload: [selector.text ?? "", selectedCondiments],
    });
  };

  return (
    <SelectorContainer key={index} selector={selector}>
      <MultiSelectCondimentSelector
        selector={selector}
        condiments={portionedCondiments}
        selectedCondiments={filteredSelectedCondiments}
        onOptionSelected={optionSelected}
      />
    </SelectorContainer>
  );
}
