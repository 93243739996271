export function getCurrentGeolocation(
  successCallback: PositionCallback,
  errorCallback?: PositionErrorCallback,
  positionOptions?: PositionOptions
): void {
  navigator.geolocation.getCurrentPosition(successCallback, errorCallback, positionOptions);
}

export function getGeolocationStatus(): Promise<PermissionStatus> {
  return navigator.permissions.query({ name: "geolocation" });
}

export const sheetzMapBoxAccessToken =
  "pk.eyJ1Ijoic2hlZXR6LW1hcHMiLCJhIjoiY2tqeWMxd3A2MDdjYzJucDh5dG9yY2g1YSJ9._in8aErzpojn_UCquW_Ldg";

export const mapboxConfirmConfig = {
  accessToken: sheetzMapBoxAccessToken,
  minimap: {
    theme: {
      icons: {
        marker: `
        <svg width="32" height="32" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 32 32" aria-labelledby="Map Pin"><defs><style>.mapbox-pin{fill:#3f9fd5;}</style></defs>
        <path class="mapbox-pin" d="M16,2.08a9.55,9.55,0,0,0-9.54,9.54c0,2.17,1.42,5.61,4.36,10.49,2.09,3.49,4.16,6.36,4.24,6.48L16,29.92l.94-1.33c.08-.12,2.15-3,4.24-6.48,2.94-4.9,4.36-8.32,4.36-10.49A9.55,9.55,0,0,0,16,2.08Zm0,14.14a3.68,3.68,0,1,1,3.68-3.68A3.68,3.68,0,0,1,16,16.22Z"/>
        </svg>
        `,
      },
    },
    satelliteToggle: true,
  },
  // skipConfirmModal: () => false,
  theme: {
    variables: {
      fontFamily: "Roboto, sans-serif",
      colorPrimary: "#2f3637",
      colorSecondary: "#586567",
    },
    icons: {
      close: `
      <svg width="18" height="18" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 100 100" aria-labelledby="X"><defs><style>.x_icon_1{fill:#343434;}</style></defs>
      <path id="Path_106" data-name="Path 106" class="x_icon_1" d="M89.45,10.54h0a10.12,10.12,0,0,0-14.31,0l-18,18a10.12,10.12,0,0,1-14.3,0h0l-18-18a10.12,10.12,0,0,0-14.31,0h0a10.13,10.13,0,0,0,0,14.32l18,18a10.11,10.11,0,0,1,0,14.3v0l-18,18a10.13,10.13,0,0,0,0,14.32h0a10.12,10.12,0,0,0,14.31,0l18-18a10.12,10.12,0,0,1,14.3,0h0l18,18a10.12,10.12,0,0,0,14.31,0h0a10.13,10.13,0,0,0,0-14.32l-18-18a10.11,10.11,0,0,1,0-14.3v0l18-18A10.13,10.13,0,0,0,89.45,10.54Z"/>
      </svg>
      `,
      question: `
      <svg width="18" height="18" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 32 32" aria-labelledby="Map Pin"><defs><style>.mapbox-pin{fill:#3f9fd5;}</style></defs>
      <path class="mapbox-pin" d="M16,2.08a9.55,9.55,0,0,0-9.54,9.54c0,2.17,1.42,5.61,4.36,10.49,2.09,3.49,4.16,6.36,4.24,6.48L16,29.92l.94-1.33c.08-.12,2.15-3,4.24-6.48,2.94-4.9,4.36-8.32,4.36-10.49A9.55,9.55,0,0,0,16,2.08Zm0,14.14a3.68,3.68,0,1,1,3.68-3.68A3.68,3.68,0,0,1,16,16.22Z"/>
      </svg>
      `,
    },
    cssText: `
      .ModalHeaderTitle { 
        font-family: "Obviously", sans-serif;
        text-transform: uppercase;
      }
      .Button {
        border-radius: 32px;
        padding-top: 16px;
        padding-bottom: 16px;
      }
      .Button.ButtonPrimary.ButtonApprove {
        background-color: #8cb500;
        border: none;
      }
      .Button.ButtonSecondary.ButtonReject {
        background-color: #88979a;
        border: none;
        color: #fff;
      }
    `,
  },
};
