import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";

import "./HomepageCarousel.scss";

import { HomeScreenBanner } from "assets/dtos/anywhere-dto";

import { useMediaQuery } from "hooks";

import { AppContext, desktopMediaQuery } from "util/AppContext.util";
import { getImageSrc, imageBucketSizes } from "util/Image.util";
import { getMenuHome } from "util/Menu.util";
import { generateUuid } from "util/Metrics.util";

enum NavigationType {
  "ITEM_CUSTOMIZATION" = "ITEM_CUSTOMIZATION",
  "MENU_CATEGORY" = "MENU_CATEGORY",
  "INTERNAL_DESTINATION" = "INTERNAL_DESTINATION",
  "EXTERNAL_DESTINATION" = "EXTERNAL_DESTINATION",
}

const HomepageCarousel = (): ReactElement => {
  const appContext = useContext(AppContext);
  const [homeScreenBanners, setHomeScreenBanners] = useState(
    appContext.homeScreenBanners ? appContext.homeScreenBanners : []
  );
  const navigate = useNavigate();
  const [matchesDesktop] = useMediaQuery(desktopMediaQuery);
  const imageBucketSize: keyof typeof imageBucketSizes = matchesDesktop ? "xlarge" : "large";

  const openUrlInNewTab = (url: string | undefined): void => {
    const newWindow = window.open(url, "_blank");
    newWindow?.focus();
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getMenuHome().then((response) => {
      setHomeScreenBanners(response.data.homeScreenBanners);
    });
  }, []);

  function handleOnClick(homeScreenBanner: HomeScreenBanner): void {
    switch (homeScreenBanner.navigationType) {
      case NavigationType.EXTERNAL_DESTINATION:
        openUrlInNewTab(homeScreenBanner.destinationURL);
        break;
      case NavigationType.INTERNAL_DESTINATION:
        if (homeScreenBanner.destinationURL) {
          navigate(homeScreenBanner.destinationURL);
        }
        break;
      case NavigationType.MENU_CATEGORY:
        navigate("/order/menu/" + homeScreenBanner.destinationObjectId, {
          state: {
            homepageBannerRedirect: "/order/menu/" + homeScreenBanner.destinationObjectId,
            event: {
              homeScreenBannerServedEventId: generateUuid(),
              homeScreenBannerId: homeScreenBanner.homeScreenBannerId,
              homeScreenBannerName: homeScreenBanner.name,
              source: "HOME_SCREEN_BANNER",
            },
          },
        });
        break;
      case NavigationType.ITEM_CUSTOMIZATION:
        navigate("/order/customize/" + homeScreenBanner.destinationObjectId, {
          state: {
            homepageBannerRedirect: "/order/customize/" + homeScreenBanner.destinationObjectId,
            event: {
              homeScreenBannerServedEventId: generateUuid(),
              homeScreenBannerId: homeScreenBanner.homeScreenBannerId,
              homeScreenBannerName: homeScreenBanner.name,
              source: "HOME_SCREEN_BANNER",
            },
          },
        });
        break;
      default:
        openUrlInNewTab(homeScreenBanner.destinationURL);
        break;
    }
  }

  function createCarouselBanner(homeScreenBanner: HomeScreenBanner, index: number): ReactElement {
    return (
      <div
        key={index}
        className={"banner-background"}
        style={{ backgroundImage: `url(${getImageSrc(homeScreenBanner.image, imageBucketSize)})` }}
      >
        <div className={"banner-container-wrapper"}>
          <p className="title-text" style={{ color: homeScreenBanner.titleTextColor }}>
            {homeScreenBanner.titleText}
          </p>
          <button
            className="banner-btn"
            type="button"
            onClick={(): void => handleOnClick(homeScreenBanner)}
            style={{ color: homeScreenBanner.linkColor }}
          >
            {homeScreenBanner.linkText}
          </button>
        </div>
      </div>
    );
  }

  const baseBanners: ReactElement = <div>{homeScreenBanners?.map(createCarouselBanner)}</div>;

  return (
    <Carousel
      showArrows={false}
      showThumbs={false}
      showIndicators={true}
      autoPlay={true}
      showStatus={false}
      infiniteLoop={true}
      stopOnHover={true}
      transitionTime={1000}
      interval={3000}
    >
      {baseBanners.props.children}
    </Carousel>
  );
};

export default HomepageCarousel;
