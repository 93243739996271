import { DateTime } from "luxon";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import "./CancelOrder.scss";

import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";

import { AppContext } from "util/AppContext.util";
import { cancelOrder } from "util/Order.util";
import { getDateTimeFromString } from "util/Time.util";

export interface CancelOrderProps {
  delivery: boolean;
  orderNumber: number;
  orderState?: string;
  orderDeliveryPickupTime?: string;
}

const CancelOrder = (props: CancelOrderProps) => {
  const appContext = useContext(AppContext);
  const currentTime = DateTime.now();
  const navigate = useNavigate();

  let beforeDeliveryPickupTime = false;

  if (props.orderDeliveryPickupTime) {
    beforeDeliveryPickupTime = getDateTimeFromString(props.orderDeliveryPickupTime) > currentTime;
  }

  const showNoCancelText =
    (props.orderState === "COMPLETED" || props.delivery) && beforeDeliveryPickupTime;

  function cancelOrderSelected(): void {
    if (props.orderNumber) {
      cancelOrder(props.orderNumber).then(
        () => {
          appContext.hideConfirmationActionSheet();
          appContext.showAlertActionSheet(
            "Your order has been canceled and you will not be charged. You can find this canceled order in your recent orders.",
            () => null,
            "Order Canceled"
          );

          navigate("/order/information");
        },
        () => appContext.hideConfirmationActionSheet()
      );
    }
  }

  return (
    <>
      <div className="cancel-order-button-container">
        <SheetzButton
          buttonColor={ButtonColor.darkGray}
          disabled={
            (props.orderState !== "PROCESSING" && props.orderState !== "SCHEDULED") ||
            props.delivery
          }
          transparentButton
          className="order-cancel-button"
          label="Cancel Order"
          onClick={(): void =>
            appContext.showConfirmationActionSheet(
              "Are you sure you want to cancel your order?",
              "Cancel Order",
              cancelOrderSelected
            )
          }
        />

        {showNoCancelText && (
          <p>
            <strong>
              We&apos;re already preparing this order.
              <br />
              It can no longer be canceled.
            </strong>
          </p>
        )}
      </div>
    </>
  );
};

export default CancelOrder;
