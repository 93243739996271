import React from "react";

import { Option, Selector } from "assets/dtos/anywhere-dto";

import SelectorContainer from "components/Order/ItemCustomization/SelectorContainer/SelectorContainer";
import ExtraableCondimentSelector from "components/Order/ItemCustomization/Selectors/ExtraableCondimentSelector/ExtraableCondimentSelector";
import { OrderSessionAction } from "components/Order/OrderSessionReducer";

import {
  ExtraableOption,
  ItemCustomization,
  handleDefaultOption,
  handleTagAlongTags,
  isCondimentInList,
} from "util/Customization.util";
import { CustomizedItem } from "util/Order.util";
import { deriveCondiment, getAllTags } from "util/Tag.util";

/**
 * Iterate over all options and derive the associated condiment along with the double/extra version of the condiment.
 * We pass `true` for the `okIfEmpty` flag since we are now allowing explicit options to not
 * derive a condiment. For instance, in the Bread selector, the "croissant" option will not derive
 * anything when the size is half or whole sub, since there is no croissant sub roll.
 */
function deriveCondiments(
  selector: Selector,
  existingTags: string[],
  itemCustomization: ItemCustomization
): ExtraableOption[] {
  const options: (ExtraableOption | null)[] = !selector.options
    ? []
    : selector.options?.map((option) => {
        const derivedCondiment = deriveCondiment(
          option.tags ?? [],
          existingTags,
          itemCustomization,
          true
        );

        if (!derivedCondiment) {
          return null;
        }

        const extraOption = selector.extraOptions?.find(
          (extraOption) => option.text === extraOption.text
        );

        if (!extraOption) {
          return null;
        }

        const derivedExtraCondiment = deriveCondiment(
          extraOption.tags ?? [],
          existingTags,
          itemCustomization,
          true
        );

        if (!derivedExtraCondiment) {
          return null;
        }

        return {
          option: option,
          condiment: derivedCondiment,
          extraOption: extraOption,
          extraCondiment: derivedExtraCondiment,
        };
      });

  if (selector.noOption) {
    const noOption = createNoOption(selector.noOption, existingTags, itemCustomization);
    if (noOption) {
      options.push(noOption);
    }
  }

  // Filter out any elements that are null and log a warning if there aren't any options left.
  const filteredOptions = options.filter((option) => option !== null) as ExtraableOption[];
  if (!filteredOptions.length) {
    // TODO: Possibly throw error instead that is caught and logged to splunk.
    console.warn(`No options derived a condiment/extraCondiment for extraable condiment selector with name='${selector.text}'
    and itemCustomizationId='${itemCustomization.itemCustomizationId}'`);
  }
  return filteredOptions;
}

export function createNoOption(
  noOption: Option,
  existingTags: string[],
  itemCustomization: ItemCustomization
): ExtraableOption | undefined {
  if (!noOption.tags) {
    return;
  }
  const noOptionCondiment = deriveCondiment(noOption.tags, existingTags, itemCustomization, false);
  const extraableNoOption = { option: noOption, condiment: noOptionCondiment };
  return extraableNoOption;
}

export function processExtraableCondimentSelector(
  selector: Selector,
  selectorState: ExtraableOption[] | undefined,
  item: CustomizedItem,
  itemCustomization: ItemCustomization,
  dispatch: React.Dispatch<OrderSessionAction>,
  index: number
): JSX.Element | null {
  const existingTags = getAllTags(item);
  const options = deriveCondiments(selector, existingTags, itemCustomization);
  if (!options.length) {
    return null;
  }

  let selectedOptions = selectorState;

  // Remove any selected options that are no longer selectable due to conflicting tags.
  if (selectedOptions && selectedOptions.length) {
    selectedOptions.forEach((selectedOption) => {
      const optionIsSelectable = isCondimentInList(
        options.map((option) => option.condiment),
        selectedOption.condiment
      );
      if (!optionIsSelectable) {
        // TODO: Anything filtered out should be removed via a state update. Need an item customization where a choice generates a conflict on a selector below it.
        const index = selectedOptions?.indexOf(selectedOption);
        if (index && index > -1) {
          selectedOptions?.splice(index, 1);
        }
      }
    });

    // If there are still selected options remaining, add their condiments to the customized item.
    if (!selectedOptions.length) {
      selectedOptions = undefined;
      handleDefaultOption(selector, existingTags, itemCustomization, item);
    } else {
      selectedOptions.forEach((selectedOption) => {
        // If there is only one option selected, add the primary condiment associated with the selected option.
        // If there are two options selected and this one is NOT the "extra", then add the primary condiment.
        if (
          selectedOptions?.length === 1 ||
          (selectedOptions?.length === 2 && !selectedOption.extraOptionSelected)
        ) {
          item.condiments.push(selectedOption.condiment);
        }

        // Add the extra option condiment, if it exists.
        if (selectedOption.extraOptionSelected && selectedOption.extraCondiment) {
          item.condiments.push(selectedOption.extraCondiment);
        }

        // If the selected option has tagAlongTags, then derive that condiment as well and add it to the item.
        handleTagAlongTags(selectedOption.option, itemCustomization, item);
      });
    }
  } else {
    handleDefaultOption(selector, existingTags, itemCustomization, item);
  }

  const optionSelected = (extraableOptions: ExtraableOption[]): void => {
    if (!extraableOptions.length) {
      dispatch({ type: "REMOVE_CUSTOMIZATION_SELECTION", payload: selector.text ?? "" });
    } else {
      dispatch({
        type: "SET_ITEM_CUSTOMIZATIONS",
        payload: [selector.text ?? "", extraableOptions],
      });
    }
  };

  const extraSelected = (): void => {
    if (!selectedOptions) {
      return;
    }
    selectedOptions[0].extraOptionSelected = !selectedOptions[0].extraOptionSelected;
    dispatch({ type: "SET_ITEM_CUSTOMIZATIONS", payload: [selector.text ?? "", selectedOptions] });
  };

  return (
    <SelectorContainer key={index} selector={selector}>
      <ExtraableCondimentSelector
        selector={selector}
        options={options}
        selectedOptions={selectedOptions}
        onOptionSelected={optionSelected}
        onExtraSelected={extraSelected}
        index={index}
      />
    </SelectorContainer>
  );
}
