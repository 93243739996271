import classNames from "classnames";
import React from "react";

import "./ButtonFooterContainer.scss";

import { isInNativeMobileContext } from "util/MobileApp.util";

const ButtonFooterContainer = (props: React.PropsWithChildren<unknown>) => {
  const buttonFooterContainerClasses = classNames("button-footer-container", {
    "in-mobile-app": isInNativeMobileContext(),
  });

  return <div className={buttonFooterContainerClasses}>{props.children}</div>;
};

export default ButtonFooterContainer;
