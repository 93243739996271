import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./OrderSubmitted.scss";

import { Order, PaymentMethod, SubmitOrderResponse } from "assets/dtos/anywhere-dto";

import CancelOrder from "components/Account/MyReceipts/Receipt/CancelOrder/CancelOrder";
import MyOrder from "components/Order/MyOrder/MyOrder";
import OrderDeliveryAddress from "components/Order/OrderConfirmation/OrderDeliveryAddress/OrderDeliveryAddress";
import OrderTotals from "components/Order/OrderTotals/OrderTotals";
import ReceiptHeader from "components/Order/Receipt/ReceiptHeader/ReceiptHeader";
import ReceiptLoyaltyPointsEarned from "components/Order/Receipt/ReceiptLoyaltyPointsEarned/ReceiptLoyaltyPointsEarned";
import ReceiptOrderDetail from "components/Order/Receipt/ReceiptOrderDetail/ReceiptOrderDetail";
import ReceiptPaymentDetail from "components/Order/Receipt/ReceiptPaymentDetail/ReceiptPaymentDetail";
import ReceiptAdditionalOrderInfo from "components/Order/Receipt/RecieptAdditionalOrderInfo/ReceiptAdditionalOrderInfo";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import ListItem from "components/misc/list/ListItem/ListItem";
import StoreDisplay from "components/misc/store/StoreDisplay/StoreDisplay";

import { IconType, getIcon } from "util/Icon.util";
import { OrderSession, createFavoriteOrderRequest, getOrder } from "util/Order.util";
import { addFavoriteOrder, deleteFavoriteOrder } from "util/Reorder.util";

interface OrderSubmittedProps {
  orderSession: OrderSession;
  submitOrderResponse: SubmitOrderResponse;
  paymentMethod?: PaymentMethod;
}

const OrderSubmitted = (props: OrderSubmittedProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as Record<string, unknown>;
  const [order, setOrder] = useState<Order>();
  const [orderDeliveryPickupTime, setOrderDeliveryPickupTime] = useState<string>();
  const purchaseOrder = props.orderSession.purchaseOrder;
  const store = props.orderSession.store;
  const [isFavoriteOrder, setIsFavoriteOrder] = useState<boolean>(
    props.submitOrderResponse.favoriteOrderId !== undefined
  );
  const [favoriteOrderId, setFavoriteOrderId] = useState<number | undefined>(
    props.submitOrderResponse.favoriteOrderId
  );
  const paymentDetails = useRef<HTMLDivElement>(null);
  const isDelivery = purchaseOrder?.integratedDelivery !== undefined;
  let numberOfItems = 0;
  (order ?? purchaseOrder)?.items.forEach((item) => (numberOfItems += item.quantity));

  useEffect(() => {
    getOrder(props.submitOrderResponse.orderNumber, true).then((response) => {
      setOrder(response.data.order);
      setOrderDeliveryPickupTime(
        response.data.order.integratedDelivery !== undefined
          ? response.data.order.integratedDelivery.estimatedDeliveryTime
          : response.data.order.pickupTime
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function toggleFavoriteOrder(): void {
    if (isFavoriteOrder && favoriteOrderId) {
      deleteFavoriteOrder(favoriteOrderId).then(() => {
        setIsFavoriteOrder(false);
        setFavoriteOrderId(undefined);

        navigate(location.pathname, {
          state: {
            ...locationState,
            isFavoriteOrder: !isFavoriteOrder,
          },
          replace: true,
        });
      });
    } else if (purchaseOrder !== undefined) {
      const request = createFavoriteOrderRequest(
        props.submitOrderResponse.orderNumber,
        purchaseOrder.items,
        purchaseOrder.combos
      );
      addFavoriteOrder(request).then((response) => {
        setIsFavoriteOrder(true);
        setFavoriteOrderId(response.data.order.favoriteId);

        navigate(location.pathname, {
          state: {
            ...locationState,
            isFavoriteOrder: !isFavoriteOrder,
          },
          replace: true,
        });
      });
    }
  }

  const orderSubmittedClasses = classNames("order-submitted", {
    delivery: isDelivery,
  });

  return (
    <div className={orderSubmittedClasses}>
      <ReceiptHeader
        numberOfItems={numberOfItems}
        orderTotal={props.submitOrderResponse.total}
        pickupTime={props.submitOrderResponse.pickupTime}
        orderNumber={props.submitOrderResponse.orderNumber}
        barcode={props.submitOrderResponse.orderNumberBarcode}
        viewDetailsPressed={() => {
          paymentDetails.current?.scrollIntoView({ behavior: "smooth" });
        }}
      />

      <ResponsiveLayoutContainer>
        <ReceiptOrderDetail
          isDeliveryOrder={isDelivery}
          pickupTime={
            order?.integratedDelivery?.estimatedDeliveryTime ??
            purchaseOrder?.integratedDelivery?.estimatedDeliveryTime ??
            props.submitOrderResponse.pickupTime
          }
          pickupLocation={purchaseOrder?.pickupLocation}
          orderingSystem={purchaseOrder?.orderingSystem}
        />

        {!isDelivery && store !== undefined && (
          <div className="store-display-container">
            <StoreDisplay store={store} showStoreNumber showFavoriteToggle />
          </div>
        )}

        {isDelivery && (
          <div className="delivery-address-container">
            <OrderDeliveryAddress deliveryAddress={order?.integratedDelivery?.deliveryAddress} />
          </div>
        )}

        <div className="order-details-container">
          <ListItem hideArrow clickHandler={toggleFavoriteOrder}>
            <div className="save-order-button-flex-container">
              <div className="circle">
                {getIcon(IconType.heart, isFavoriteOrder ? "heart-icon favorite" : "heart-icon")}
              </div>
              <p className="label">Favorite entire order</p>
            </div>
          </ListItem>

          <div className="padding-container">
            <MyOrder orderSession={props.orderSession} />
          </div>

          <div className="section-container order-totals">
            <OrderTotals purchaseOrder={purchaseOrder} />
            {props.submitOrderResponse.loyaltyPoints?.earned !== undefined &&
              props.submitOrderResponse.loyaltyPoints?.earned > 0 && (
                <div className="points-earned-container">
                  <ReceiptLoyaltyPointsEarned
                    pointsEarned={props.submitOrderResponse.loyaltyPoints?.earned}
                  />
                </div>
              )}
          </div>

          {props.paymentMethod && (
            <div className="section-container">
              <ReceiptPaymentDetail paymentMethod={props.paymentMethod} />
            </div>
          )}

          <div className="section-container">
            <ReceiptAdditionalOrderInfo
              orderNumber={props.submitOrderResponse.orderNumber}
              pickupTime={props.submitOrderResponse.pickupTime}
            />
          </div>

          <div className="cancel-order-container" ref={paymentDetails}>
            <CancelOrder
              delivery={isDelivery}
              orderNumber={props.submitOrderResponse.orderNumber}
              orderDeliveryPickupTime={orderDeliveryPickupTime}
              orderState={order?.state}
            />
          </div>
        </div>
      </ResponsiveLayoutContainer>

      <div className="new-order-footer">
        <SheetzButton label="New Order" onClick={(): void => navigate("/order")} />
      </div>
    </div>
  );
};

export default OrderSubmitted;
