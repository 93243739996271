import axios, { AxiosPromise } from "axios";
import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";
import React from "react";

import {
  AddFavoriteOrderRequest,
  AddFavoriteOrderResponse,
  Combo,
  FavoriteOrder,
  FavoriteOrderCombo,
  FavoriteOrderCondiment,
  FavoriteOrderItem,
  GetFavoritesAndHistoryResponse,
  GetReOrderAIUsResponse,
  ItemEvent,
  LoyaltyDiscountItem,
  Order,
  OrderHistoryCombo,
  OrderHistoryCondiment,
  OrderHistoryItem,
  OrderLoyaltyDiscount,
  OrderSpecialDiscount,
  OrderType,
  PickupLocation,
  SpecialDiscountItem,
  StartDefaultReorderSessionRequest,
  StartDefaultReorderSessionResponse,
  UpdateFavoriteOrderRequest,
  UpdateFavoriteOrderResponse,
} from "assets/dtos/anywhere-dto";

import ReorderDetailListItem, {
  ReorderDetailListItemProps,
} from "components/Order/Reorder/ReorderDetailListItem/ReorderDetailListItem";

import { REORDER_AIUS } from "endpoints/menu.endpoints";
import {
  ADD_FAVORITE_ORDER,
  GET_FAVORITES_AND_HISTORY,
  START_DEFAULT_REORDER_SESSION,
  UPDATE_OR_DELETE_FAVORITE_ORDER,
} from "endpoints/order.endpoints";

import { ShoppingBag, ShoppingBagCombo, ShoppingBagEntity } from "util/Bag.util";
import { createShoppingBagItemUUID } from "util/Customization.util";

export interface UnavailabilityDetails {
  noLongerOffer?: Set<string>;
  currentlyUnavailable?: Set<string>;
  temporarilyUnavailable?: Set<string>;
  unavailableAtStore?: Set<string>;
  unavailableAsCustomized?: Set<string>;
}

export interface FavoriteOrderPricedCondiment extends FavoriteOrderCondiment {
  price?: number;
}

export interface ReorderedShoppingBagItem extends ShoppingBagEntity {
  comboId?: number;
  retailModifiedItemId: number;
  receiptText: string;
  image?: string;
  condiments?: FavoriteOrderPricedCondiment[];
  price?: number;
  discount?: number;
  event?: ItemEvent;
}

export interface Discount {
  discount?: number;
  name?: string;
  price?: number;
}

interface DiscountCombo {
  comboId?: number;
  discounts?: Discount[];
}

export interface DiscountItem {
  amount?: number;
  lineItems: SpecialDiscountItem[] | LoyaltyDiscountItem[];
  name: string;
  type: "loyaltyDiscount" | "specialDiscount";
}

export function startDefaultReorderSession(
  request: StartDefaultReorderSessionRequest,
  orderSessionId?: string
): AxiosPromise<StartDefaultReorderSessionResponse> {
  return axios({
    method: "POST",
    url: START_DEFAULT_REORDER_SESSION,
    data: request,
    headers: { sessionId: orderSessionId },
  });
}

export function getReorderAIUs(
  storeNumber: number,
  dayPart: string,
  orderType: OrderType,
  pickupLocation: PickupLocation
): AxiosPromise<GetReOrderAIUsResponse> {
  return axios({
    method: "GET",
    params: { dayPart: dayPart, pickupLocation: pickupLocation, orderType: orderType },
    url: REORDER_AIUS(storeNumber),
  });
}

export function getFavoritesAndHistory(
  storeNumber?: string,
  orderSessionId?: string
): AxiosPromise<GetFavoritesAndHistoryResponse> {
  return axios({
    method: "GET",
    url: GET_FAVORITES_AND_HISTORY,
    headers: { sessionId: orderSessionId },
    params: { storeNumber: storeNumber },
  });
}

export function addFavoriteOrder(
  request: AddFavoriteOrderRequest
): AxiosPromise<AddFavoriteOrderResponse> {
  return axios({ method: "POST", url: ADD_FAVORITE_ORDER, data: request });
}

export function updateFavoriteOrder(
  request: UpdateFavoriteOrderRequest
): AxiosPromise<UpdateFavoriteOrderResponse> {
  if (request.order.favoriteId === undefined) {
    throw new SheetzError("Unable to update favorite: no favorite ID.", {
      userReadableMessage: "Unable to update your Favorite. Please try again.",
      primaryButton: SheetzErrorButtonType.TRY_AGAIN,
    });
  }
  return axios({
    method: "PUT",
    url: UPDATE_OR_DELETE_FAVORITE_ORDER(request.order.favoriteId),
    data: request,
  });
}

export function deleteFavoriteOrder(favoriteOrderId: number): AxiosPromise<null> {
  return axios({ method: "DELETE", url: UPDATE_OR_DELETE_FAVORITE_ORDER(favoriteOrderId) });
}

//eslint-disable-next-line
export function isOrderFromHistory(order: any): order is Order {
  return order && order.orderNumber !== undefined;
}

//eslint-disable-next-line
export function isOrderHistoryItem(orderHistoryItem: any): orderHistoryItem is OrderHistoryItem {
  return orderHistoryItem;
}

//eslint-disable-next-line
export function isReorderedShoppingBagItem(item: any): item is ReorderedShoppingBagItem {
  return item && item.retailModifiedItemId && item.id && item.quantity;
}

//eslint-disable-next-line
export function isFavoriteOrderCombo(combo: any): combo is FavoriteOrderCombo {
  return combo && combo.comboId;
}

export function isOrderFullyAvailable(order: FavoriteOrder | Order): boolean {
  // Need to declare an array of the union between FavoriteOrderItem and OrderHistoryItem.
  // See: https://stackoverflow.com/questions/49510832/typescript-how-to-map-over-union-array-type
  const items: (FavoriteOrderItem | OrderHistoryItem)[] = order.items;
  const allItemsAvailable = !items.find((item) => item.availability !== "AVAILABLE");

  let allCondimentsAvailable = true;
  if (allItemsAvailable) {
    allCondimentsAvailable = !items.find((item) => {
      return !!item.condiments?.find((condiment) => condiment.availability !== "AVAILABLE");
    });
  }

  if (order.combos && order.combos.length > 0) {
    const allCombosAvailable = !order.combos.find((combo) => combo.availability !== "AVAILABLE");
    return allItemsAvailable && allCondimentsAvailable && allCombosAvailable;
  } else {
    return allItemsAvailable && allCondimentsAvailable;
  }
}

export function getUnavailabilityDetails(order: FavoriteOrder | Order): UnavailabilityDetails {
  const details: UnavailabilityDetails = {};
  const noLongerOffer = new Set<string>();
  const currentlyUnavailable = new Set<string>();
  const temporarilyUnavailable = new Set<string>();
  const unavailableAtStore = new Set<string>();
  const unavailableAsCustomized = new Set<string>();

  function handleAvailability(
    entity:
      | FavoriteOrderItem
      | FavoriteOrderCondiment
      | FavoriteOrderCombo
      | OrderHistoryItem
      | OrderHistoryCondiment
      | OrderHistoryCombo,
    name: string
  ): void {
    switch (entity.availability) {
      case "NO_LONGER_AVAILABLE":
        noLongerOffer.add(name);
        break;
      case "UNAVAILABLE":
      case "NOT_SELLABLE":
        currentlyUnavailable.add(name);
        break;
      case "TEMPORARILY_UNAVAILABLE":
        temporarilyUnavailable.add(name);
        break;
      case "UNAVAILABLE_AT_THIS_STORE":
        unavailableAtStore.add(name);
        break;
      default:
        break;
    }
  }

  order.items.forEach((item: FavoriteOrderItem | OrderHistoryItem) => {
    //Keeping separate due to handleAvailability logic including combos and condiments
    if (item.normalizationStatuses?.includes("UNABLE_TO_ADD_REQUIRED_CONDIMENT")) {
      unavailableAsCustomized.add(item.receiptText);
    } else {
      handleAvailability(item, item.receiptText);
    }
    item.condiments?.forEach((condiment) => handleAvailability(condiment, condiment.receiptText));
  });

  order.combos?.forEach((combo) => handleAvailability(combo, combo.name));

  details.noLongerOffer = noLongerOffer.size > 0 ? noLongerOffer : undefined;
  details.currentlyUnavailable = currentlyUnavailable.size > 0 ? currentlyUnavailable : undefined;
  details.temporarilyUnavailable =
    temporarilyUnavailable.size > 0 ? temporarilyUnavailable : undefined;
  details.unavailableAtStore = unavailableAtStore.size > 0 ? unavailableAtStore : undefined;
  details.unavailableAsCustomized =
    unavailableAsCustomized.size > 0 ? unavailableAsCustomized : undefined;

  return details;
}

export function areAllItemsUnavailable(order: Order | FavoriteOrder): boolean {
  let allCombosUnavailable = true;

  if (order.combos !== undefined && order.combos.length) {
    allCombosUnavailable = order.combos.every(
      (combo: FavoriteOrderCombo) => combo.availability !== "AVAILABLE"
    );
  }

  const items: (FavoriteOrderItem | OrderHistoryItem)[] = order.items;
  const allItemsUnavailable = items.every(
    (item: FavoriteOrderItem | OrderHistoryItem) => item.availability !== "AVAILABLE"
  );

  return allCombosUnavailable && allItemsUnavailable;
}

export function filterUnavailableEntities(order: FavoriteOrder | Order): FavoriteOrder | Order {
  const filteredOrder = { ...order } as FavoriteOrder | Order;

  const items: (FavoriteOrderItem | OrderHistoryItem)[] = order.items;
  const filteredItems = items.filter(
    (item: FavoriteOrderItem | OrderHistoryItem) => item.availability === "AVAILABLE"
  );
  filteredItems.forEach((item: FavoriteOrderItem | OrderHistoryItem) => {
    const filteredCondiments = item.condiments?.filter(
      (condiment) => condiment.availability === "AVAILABLE"
    );
    item.condiments = filteredCondiments;
  });
  filteredOrder.items = filteredItems as FavoriteOrderItem[] | OrderHistoryItem[];

  const filteredCombos = filteredOrder.combos?.filter(
    (combo) => combo.availability === "AVAILABLE"
  );
  filteredOrder.combos = filteredCombos;

  return filteredOrder;
}

export function mapPreviousOrderToShoppingBag(
  order: FavoriteOrder | Order,
  source: "FAVORITE" | "REORDER"
): ShoppingBag {
  const shoppingBagItems: ReorderedShoppingBagItem[] = [];
  order.items.forEach((orderItem: FavoriteOrderItem | OrderHistoryItem) => {
    const item: ReorderedShoppingBagItem = {
      id: createShoppingBagItemUUID(),
      comboId: orderItem.comboId,
      retailModifiedItemId: orderItem.retailModifiedItemId,
      receiptText: orderItem.receiptText,
      quantity: orderItem.quantity,
      image: orderItem.image,
      condiments: orderItem.condiments,
      event: {
        source,
      },
    };
    shoppingBagItems.push(item);
  });

  if (!order.combos || order.combos.length === 0) {
    const shoppingBag: ShoppingBag = { items: shoppingBagItems, combos: [] };
    return shoppingBag;
  }

  const combos: ShoppingBagCombo[] = [];
  order.combos.forEach((combo: FavoriteOrderCombo | OrderHistoryCombo) => {
    // Search terms aren't needed for reordering, and price will be updated when bag contents are sent to server.
    const comboDetails: Combo = {
      menuComboId: combo.menuComboId,
      name: combo.name,
      searchTerms: [],
      startsAtPrice: 0,
      image: combo.image ?? "",
      isCustomizable: false,
    };
    const items: ReorderedShoppingBagItem[] = shoppingBagItems.filter(
      (item: ReorderedShoppingBagItem) => item.comboId === combo.comboId
    );
    const bagCombo: ShoppingBagCombo = {
      id: createShoppingBagItemUUID(),
      quantity: combo.quantity,
      comboDetails: comboDetails,
      items: items,
    };
    // Add the generated shopping bag entity ID to each combo item.
    items.forEach((item: ReorderedShoppingBagItem) => (item.comboId = bagCombo.id));
    combos.push(bagCombo);
  });

  const shoppingBag: ShoppingBag = { items: shoppingBagItems, combos: combos };
  return shoppingBag;
}

export function createReorderItemsDisplay(
  order: Order | FavoriteOrder,
  removeItemHandler?: (index: number, remove: boolean) => void,
  showPriceDetails = false,
  unactionableOrder?: boolean,
  receipt?: boolean
): JSX.Element[] {
  const items: ReorderDetailListItemProps[] = [];
  const combos: ReorderDetailListItemProps[] = [];
  const discountCombos: DiscountCombo[] = [];
  const discountItems: DiscountItem[] = [];

  if (isOrderFromHistory(order)) {
    order.specialDiscounts?.forEach((specialDiscount: OrderSpecialDiscount) => {
      const specialDiscountItem: DiscountItem = {
        amount: specialDiscount.amount,
        lineItems: specialDiscount.specialDiscountItems,
        name: specialDiscount.receiptText,
        type: "specialDiscount",
      };

      discountItems.push(specialDiscountItem);
    });

    order.loyaltyDiscounts?.forEach((loyaltyDiscount: OrderLoyaltyDiscount) => {
      const loyaltyDiscountItem: DiscountItem = {
        amount: loyaltyDiscount.amount,
        lineItems: loyaltyDiscount.loyaltyDiscountItems,
        name: loyaltyDiscount.receiptText,
        type: "loyaltyDiscount",
      };

      discountItems.push(loyaltyDiscountItem);
    });
  }

  order.combos?.forEach((combo) => {
    // Create shells for the combos to fill in while going through the items
    combos.push({
      available: combo.availability === undefined ? true : combo.availability === "AVAILABLE",
      comboId: combo.comboId,
      description: "",
      discount: {},
      image: combo.image,
      name: combo.name,
      price: 0,
      quantity: combo.quantity,
    } as ReorderDetailListItemProps);

    discountCombos.push({
      comboId: combo.comboId,
      discounts: [],
    });
  });

  if (isOrderFromHistory(order)) {
    order.items.sort((a: OrderHistoryItem, b: OrderHistoryItem) =>
      a.lineNumber < b.lineNumber ? -1 : 1
    );
  }

  order.items.forEach((item: OrderHistoryItem | FavoriteOrderItem): void => {
    let condimentText = "";
    let condimentsPrice = 0;

    // Loop through condiments to add to final price & also create condiment list
    if (item.condiments && item.condiments.length > 0) {
      item.condiments.forEach((condiment) => {
        condimentText +=
          condimentText.length === 0 ? condiment.receiptText : ", " + condiment.receiptText;

        if (condiment.price) {
          condimentsPrice += condiment.price;
          condimentText += ` (+${(condiment.price * item.quantity).toFixed(2)})`;
        }
      });
    }

    const price = item.price ? item.price + condimentsPrice : condimentsPrice;

    // If an item has a combo id, find the corresponding combo to append the item name to, and add to the price.
    if (item.comboId) {
      const comboIndex = combos.findIndex(
        (combo: ReorderDetailListItemProps) => combo.comboId === item.comboId
      );

      if (comboIndex >= 0) {
        combos[comboIndex].description +=
          combos[comboIndex].description.length === 0 ? item.receiptText : ", " + item.receiptText;

        combos[comboIndex].price += price;
      }

      const discountComboIndex = discountCombos.findIndex(
        (discountCombo: DiscountCombo) => discountCombo.comboId === item.comboId
      );

      if (discountComboIndex >= 0) {
        const discount: Discount | undefined = isOrderHistoryItem(item)
          ? findDiscountItem(item)
          : undefined;

        if (discount) {
          discountCombos[discountComboIndex].discounts?.push(discount);
        }
      }
    } else {
      const discount: Discount | undefined = isOrderHistoryItem(item)
        ? findDiscountItem(item)
        : undefined;

      let condimentsAvailable = true;

      const available =
        item.availability === undefined
          ? true
          : item.availability === "AVAILABLE" && condimentsAvailable;

      item.condiments?.forEach((condiment) => {
        if (condimentsAvailable) {
          condimentsAvailable = condiment.availability === "AVAILABLE";
        }
      });

      items.push({
        name: item.receiptText,
        description: condimentText,
        discount,
        image: item.image,
        comboId: item.comboId,
        quantity: item.quantity,
        price,
        available,
      } as ReorderDetailListItemProps);
    }
  });

  combos.forEach((combo: ReorderDetailListItemProps) => {
    const comboWithDiscount = discountCombos.find(
      (discountCombo: DiscountCombo) => discountCombo.comboId === combo.comboId
    );

    if (comboWithDiscount) {
      let discountPrice = 0;

      if (comboWithDiscount.discounts?.length) {
        comboWithDiscount.discounts?.forEach((discount: Discount) => {
          if (discount.discount) {
            return (discountPrice += discount.discount);
          } else {
            return discountPrice;
          }
        });

        if (combo.discount) {
          combo.discount.discount = discountPrice;
          combo.discount.name = comboWithDiscount.discounts?.[0].name;
          combo.discount.price = combo.price;
        }
      }
    }
  });

  // NOTE: The order of this array matters, as the index is used to remove a specific row from the favorite.
  return [...combos, ...items].map((displayItem: ReorderDetailListItemProps, index: number) => {
    return (
      <li key={displayItem.name + index}>
        <ReorderDetailListItem
          available={displayItem.available}
          comboId={displayItem.comboId}
          description={displayItem.description}
          discount={displayItem.discount}
          image={displayItem.image}
          name={displayItem.name}
          onRemoveItem={(remove: boolean): void => removeItemHandler?.(index, remove)}
          price={displayItem.price}
          quantity={displayItem.quantity}
          showPriceDetails={showPriceDetails}
          type={isOrderFromHistory(order) ? "orderHistoryItem" : "favoriteOrderItem"}
          unactionableOrder={unactionableOrder ?? false}
          receipt={receipt}
        />
      </li>
    );
  });

  function findDiscountItem(item: OrderHistoryItem): Discount | undefined {
    const discountNames: string[] = [];
    const lineItemDiscounts: (SpecialDiscountItem | LoyaltyDiscountItem)[] = [];

    let discountPriceLoyalty = 0;
    let discountPriceSpecial = 0;
    let price = item.price ?? 0;

    // add condiments to base price
    if (item.condiments?.length) {
      item.condiments.forEach((condiment: OrderHistoryCondiment) => {
        if (condiment.price) {
          price += condiment.price;
        }
      });
    }

    // map discountItem to item and create final discount
    discountItems.forEach((discountItem: DiscountItem) => {
      // there may be multiple discounts per item
      discountItem.lineItems.forEach((lineItem: SpecialDiscountItem | LoyaltyDiscountItem) => {
        // match lineItems to item
        if (lineItem.itemLineNumber === item.lineNumber) {
          lineItemDiscounts.push(lineItem);

          // create array of discount names
          if (!discountNames.includes(discountItem.name)) {
            discountNames.push(discountItem.name);
          }

          // create loyalty discount amount
          if (discountItem.type === "loyaltyDiscount") {
            discountPriceLoyalty += Math.abs(lineItem.amount);
          }

          // create special discount amount
          if (discountItem.type === "specialDiscount") {
            discountPriceSpecial += Math.abs(lineItem.amount);
          }
        }
      });
    });

    if (lineItemDiscounts.length) {
      return {
        discount: discountPriceLoyalty + discountPriceSpecial,
        name: discountNames.join(", "),
        price: Number(item.condiments ? price.toFixed(2) : (item.price ?? 0).toFixed(2)),
      };
    }

    return undefined;
  }
}
