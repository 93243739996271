import React, { ReactElement } from "react";
import { PatternFormat } from "react-number-format";

import "./OrderCurbsideDetails.scss";

import { GetUserProfileResponse, PurchaseOrder } from "assets/dtos/anywhere-dto";

import VehicleIcon from "components/Account/AccountSettings/Preferences/MyOrderingPreferences/MyVehicles/VehicleIcon/VehicleIcon";
import OrderDetailListItem from "components/Order/OrderConfirmation/OrderDetailsListItem/OrderDetailListItem";

import { IconType, getIcon } from "util/Icon.util";
import { UserVehicle } from "util/MyVehicles.util";

interface OrderCurbsideDetailsProps {
  purchaseOrder?: PurchaseOrder;
  curbsideVehicle?: UserVehicle;
  userVehicles?: UserVehicle[];
  userProfile?: GetUserProfileResponse;
  onShowCurbsideInfoActionSheet?: () => void;
}

const OrderCurbsideDetails = (props: OrderCurbsideDetailsProps): ReactElement => {
  // If no vehicles or phone number is returned, then we need to notify the user to update this info
  const curbsideInfoNeeded =
    (props.userVehicles && props.userVehicles.length === 0) ||
    (props.userProfile && props.userProfile.phoneNumber === "");
  const defaultVehicle = props.userVehicles && props.userVehicles[0];
  const curbsideVehicleDescription = curbsideInfoNeeded
    ? "Curbside info required"
    : (props.curbsideVehicle?.color || defaultVehicle?.color || "") +
      " " +
      (props.curbsideVehicle?.type || defaultVehicle?.type || "");

  if (props.purchaseOrder === undefined || props.purchaseOrder.pickupLocation !== "CURBSIDE") {
    return <></>;
  }

  return (
    <OrderDetailListItem
      action={(): void => props.onShowCurbsideInfoActionSheet?.()}
      actionTitle="Update"
      showAction={true}
    >
      <div className="curbside-details">
        <div className="icon-container">
          {curbsideInfoNeeded && (
            <div className="alert">{getIcon(IconType.alert, "alert-icon")}</div>
          )}
          {!curbsideInfoNeeded && defaultVehicle && (
            <VehicleIcon
              vehicle={props.curbsideVehicle || defaultVehicle}
              className="curbside-vehicle-icon"
            />
          )}
        </div>

        <div className="curbside-details-container">
          <p className="details-heading">{curbsideVehicleDescription}</p>
          <p className="details-sub-heading">
            {curbsideInfoNeeded ? (
              "Tap here to update"
            ) : (
              <PatternFormat
                value={props.userProfile?.phoneNumber}
                displayType="text"
                format="(###) ###-####"
                valueIsNumericString
              />
            )}
          </p>
        </div>
      </div>
    </OrderDetailListItem>
  );
};

export default OrderCurbsideDetails;
