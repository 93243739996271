import React from "react";
import "./ProgressBar.scss";

interface ProgressBarProps {
  maxValue: number;
  currentValue: number;
}

const ProgressBar: React.FC<ProgressBarProps> = (props: ProgressBarProps) => {
  return (
    <progress
      className="progress-bar"
      max={props.maxValue}
      value={props.currentValue}
    ></progress>
  );
};

export default ProgressBar;
