import React, { ReactElement, useState } from "react";
import { Collapse } from "react-collapse";
import { useNavigate } from "react-router-dom";

import { useMediaQuery } from "hooks";

import { desktopMediaQuery } from "util/AppContext.util";
import { isLoggedIn } from "util/Authentication.util";
import { IconType, getIcon } from "util/Icon.util";

interface NavMenuItemProps {
  closeFunction: () => void;
}

const NavMenuItems = (props: NavMenuItemProps): ReactElement => {
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);
  const navigate = useNavigate();
  const openUrlInNewTab = (url: string): void => {
    const newWindow = window.open(url, "_blank");
    newWindow?.focus();
  };

  const cardSubItems = [
    {
      label: "Gift Cards",
      action: (): void => openUrlInNewTab("https://www.sheetz.com/gift-cards"),
    },
    {
      label: "Sheetz CardCash Gift Card Exchange",
      action: (): void => openUrlInNewTab("https://sheetz.cardcash.com"),
    },
    {
      label: "Sheetz Platinum Edition Visa",
      action: (): void => openUrlInNewTab("https://www.firstbankcard.com/sheetz/lp/sheetzhomepage"),
    },
    {
      label: "Sheetz Business Edge",
      action: (): void => openUrlInNewTab("https://www.sheetz.com/payment-cards/business-edge"),
    },
    {
      label: "All Credit Cards",
      action: (): void => openUrlInNewTab("https://www.sheetz.com/payment-cards"),
    },
  ];

  if (isLoggedIn()) {
    cardSubItems.unshift({
      label: "My Loyalty Card",
      action: (): void => openUrlInNewTab("https://www.sheetz.com/mysheetz-card"),
    });
  } else {
    cardSubItems.push({
      label: "Sign Up for a MySheetz Card Account",
      action: (): void => navigate("/auth/register"),
    });
  }

  const navMenuItems = [
    {
      label: "Find a Sheetz",
      action: (): void => navigate("/findASheetz"),
    },
    {
      label: "What's a Sheetz?",
      subItems: [
        {
          label: "What's a Sheetz",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/sheetz"),
        },
        {
          label: "Our Story",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/sheetz"),
        },
        {
          label: "Our Food",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/food"),
        },
        {
          label: "Our Drink",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/drink"),
        },
        {
          label: "Our Fuel",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/fuel"),
        },
        {
          label: "Our Family",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/family"),
        },
        {
          label: "Our App",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/app"),
        },
      ],
    },
    {
      label: "Jobz",
      subItems: [
        {
          label: "Job Openingz",
          action: (): void => openUrlInNewTab("https://jobs.sheetz.com"),
        },
        {
          label: "Contractor Bids",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/contractorbids"),
        },
      ],
    },
    {
      label: "Community",
      subItems: [
        {
          label: "Charities",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/charities"),
        },
        {
          label: "Newsroom",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/newsroom"),
        },
        {
          label: "Sponsorship",
          action: (): void => openUrlInNewTab("https://sheetz.versaic.com/login"),
        },
        {
          label: "Sheetz for the Kidz",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/ftk"),
        },
        {
          label: "Scrip Program",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/scrip"),
        },
        {
          label: "Sustainability",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/sustainability"),
        },
        {
          label: "Real Estate",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/real-estate"),
        },
      ],
    },
    {
      label: "Cards",
      subItems: cardSubItems,
    },
    {
      label: "Buy Stuff",
      subItems: [
        {
          label: "Buy Food",
          action: (): void => navigate("/order"),
        },
        {
          label: "Buy Gift Cards",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/gift-cards"),
        },
        {
          label: "Business Quantity Gift Cards",
          action: (): void =>
            openUrlInNewTab("http://www.shopsheetz.com/zCard-Gift-Card-P6C2.aspx"),
        },
        {
          label: "Sheetz Merchandise",
          action: (): void => openUrlInNewTab("http://www.sheetzshop.com/"),
        },
      ],
    },
    {
      label: "Nutrition",
      subItems: [
        {
          label: "Calculator",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/nutrition/calculator"),
        },
        {
          label: "Self-Serve",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/nutrition/self-serve"),
        },
        {
          label: "Shweetz",
          action: (): void => openUrlInNewTab("https://www.sheetz.com/nutrition/shweetz"),
        },
      ],
    },
    { label: "FAQ", action: (): void => navigate("/FAQ") },
    {
      label: "Contact us",
      action: (): void => {
        navigate("/contact");
      },
    },
    {
      label: "Accessibility",
      action: (): void => openUrlInNewTab("https://www.sheetz.com/accessibility"),
    },
    {
      label: "Terms & Conditions",
      action: (): void => openUrlInNewTab("https://www.sheetz.com/legal/terms"),
    },
    {
      label: "Privacy Policy",
      action: (): void => openUrlInNewTab("https://www.sheetz.com/legal/privacy"),
    },
  ];

  if (!useDesktopView) {
    navMenuItems.push({
      label: "Tobacco Offers",
      action: (): void => openUrlInNewTab("https://www.sheetz.com/tobacco"),
    });
  }

  const NavMenuListItem = ({
    itemLabel,
    action,
    subItems,
  }: {
    itemLabel: string;
    action?: () => void;
    subItems?: { label: string; action: () => void }[];
  }): ReactElement => {
    const [subItemsExpanded, setSubItemsExpanded] = useState(false);
    const arrowClasses = subItemsExpanded ? "collapse-arrow up" : "collapse-arrow";

    return (
      <li>
        <button
          onClick={(): void => {
            action && action();
            setSubItemsExpanded(!subItemsExpanded);
            !subItems && props.closeFunction();
          }}
        >
          {itemLabel} {subItems && subItems.length && getIcon(IconType.arrow, arrowClasses)}
        </button>
        {subItems && subItems.length && (
          <Collapse
            isOpened={subItemsExpanded}
            theme={{
              collapse: "sub-items-container",
              content: "sub-items-content",
            }}
          >
            <ul className="sub-items">
              {subItems.map((subItem) => (
                <li key={subItem.label}>
                  <button
                    onClick={(): void => {
                      subItem.action();
                      props.closeFunction();
                    }}
                  >
                    {subItem.label}
                  </button>
                </li>
              ))}
            </ul>
          </Collapse>
        )}
      </li>
    );
  };

  const menuItemList = navMenuItems.map((navMenuItem) => (
    <NavMenuListItem
      key={navMenuItem.label}
      itemLabel={navMenuItem.label}
      action={navMenuItem.action}
      subItems={navMenuItem.subItems}
    />
  ));

  return <>{menuItemList}</>;
};
export default NavMenuItems;
