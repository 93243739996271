import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";
import React, { ReactElement, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./DeliveryTime.scss";

import { DeliveryAddress, ItemEvent } from "assets/dtos/anywhere-dto";

import { OrderSubviewProps } from "components/Order/Order";
import OrderConfiguration from "components/Order/OrderConfiguration/OrderConfiguration";
import FlexContainer from "components/misc/containers/FlexContainer";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import ListContainer from "components/misc/list/ListContainer/ListContainer";
import PathListItem from "components/misc/list/PathListItem/PathListItem";

import {
  generateOrderSessionId,
  getDeliveryEstimate,
  orderStartedEvent,
  startOrderSession,
} from "util/Order.util";
import { getUserId } from "util/Storage.util";

interface DeliveryTimeProps extends OrderSubviewProps {
  deliveryAddress?: DeliveryAddress;
}

const DeliveryTime = (props: DeliveryTimeProps): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as {
    event: ItemEvent;
    homepageBannerRedirect: string;
    redirectOnOrderFlowFinish: string;
  };

  useEffect(() => {
    if (
      props.orderSession.deliveryEstimateInMinutes === undefined &&
      props.deliveryAddress !== undefined
    ) {
      getDeliveryEstimate(props.deliveryAddress.deliveryAddressId).then((response): void => {
        props.dispatch({
          type: "SET_DELIVERY_ESTIMATED_DURATION",
          payload: response.data.deliveryEstimate.estimatedNumberOfMinutesToDelivery,
        });
      });
    }
    // Disable lint check due to numerous dependencies involved in useEffect hook above.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function confirmTime(): void {
    if (props.orderSession.storeNumber === undefined) {
      throw new SheetzError(
        "No store number for delivery order, cannot load menu or start session.",
        {
          userReadableMessage:
            "There is something wrong with the delivery address. Please select a new address and try again.",
          primaryButton: SheetzErrorButtonType.SELECT_ADDRESS,
        }
      );
    }

    let orderSessionId: string = generateOrderSessionId();
    // If the order session already has a session ID, then re-use that one.
    if (props.orderSession.orderSessionId !== undefined) {
      orderSessionId = props.orderSession.orderSessionId;
    }

    startOrderSession({ storeNumber: props.orderSession.storeNumber }, orderSessionId).then(
      (response) => {
        props.dispatch({ type: "SET_ORDER_SESSION_ID", payload: orderSessionId });
        const userId = getUserId();
        props.dispatch({ type: "SET_USER_ID", payload: userId ? parseInt(userId) : undefined });
        props.dispatch({
          type: "SET_TIME_AND_AVAILABILITY",
          payload: response.data,
        });
        orderStartedEvent(props.orderSession, props.dispatch, "IN_STORE");
        if (
          locationState &&
          (!!locationState.redirectOnOrderFlowFinish ||
            !!locationState.homepageBannerRedirect ||
            !!locationState.event)
        ) {
          // If the user is to be taken back to checkout, continue to the flow to payment type.
          if (locationState.redirectOnOrderFlowFinish === "/order/confirm") {
            navigate("/order/paymentType", {
              state: {
                redirectOnOrderFlowFinish: locationState.redirectOnOrderFlowFinish,
                event: locationState.event,
              },
            });
          } else if (locationState.homepageBannerRedirect) {
            navigate(locationState.homepageBannerRedirect, { state: locationState });
          } else {
            navigate(locationState.redirectOnOrderFlowFinish, { state: locationState });
          }
        } else {
          navigate("/order/menu");
        }
      }
    );
  }

  return (
    <FlexContainer flexStyles={{ flexDirection: "column", height: "100%" }}>
      <OrderConfiguration
        deliveryAddress={props.deliveryAddress}
        dispatch={props.dispatch}
        orderSession={props.orderSession}
      />

      <div className="delivery-time-container">
        <ResponsiveLayoutContainer>
          <p className="heading">Delivery time</p>

          <ListContainer>
            <PathListItem
              title="Deliver ASAP"
              clickHandler={confirmTime}
              subtitle={`Approx. ${props.orderSession.deliveryEstimateInMinutes}min`}
            />
          </ListContainer>
        </ResponsiveLayoutContainer>
      </div>
    </FlexContainer>
  );
};

export default DeliveryTime;
