import { Formik, FormikProps } from "formik";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import "./FAQ.scss";

import Header, { HeaderType } from "components/layout/Header/Header";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import SheetzSelect from "components/misc/form/SheetzSelect/SheetzSelect";
import QAndAListItem from "components/pages/FAQ/QAndAListItem/QAndAListItem";

import {
  FAQElementList,
  faqTopics,
  fuelItems,
  generalItems,
  jobsItems,
  mySheetzRewardzItems,
  orderingAndNutritionItems,
} from "util/FAQ.util";

export interface FAQTopicsValues {
  topic: string;
}

const anchors: Record<string, string> = {
  all: "",
  fuel: "Fuel",
  general: "General",
  jobz: "Jobs",
  orderingAndNutrition: "Ordering & Nutrition",
  rewardz: "My Sheetz Rewardz Card",
};

const FAQ = (): ReactElement => {
  const [selectedTopic, setSelectedTopic] = useState<string>("All");
  const navigate = useNavigate();
  const { section } = useParams<{ section: string }>();
  const initialValues: FAQTopicsValues = {
    topic: "All",
  };

  const [formValues, setFormValues] = useState<FAQTopicsValues>(initialValues);

  useEffect(() => {
    if (section === undefined) {
      return;
    }

    const topic = anchors[section];

    if (section && topic) {
      setSelectedTopic(topic);
      setFormValues({ topic });
    }
  }, [section]);

  const FAQElements: FC<FAQElementList> = (props) => {
    const faqElements = props.questionAnswerList.map((qAndAItem) => {
      return (
        <ul className="nav-menu-items" key={qAndAItem.title}>
          <QAndAListItem qAndAList={qAndAItem} />
        </ul>
      );
    });
    return <>{faqElements}</>;
  };

  return (
    <>
      <Header type={HeaderType.main} title={"FAQ"} fixed={true} />
      <ResponsiveLayoutContainer>
        <div className="faq-container">
          <div className="filter-container">
            <Formik
              enableReinitialize={true}
              initialValues={formValues}
              validationSchema={Yup.object({
                topic: Yup.string(),
              })}
              onSubmit={(value: FAQTopicsValues): void => {
                setSelectedTopic(value.topic);
              }}
            >
              {(props: FormikProps<FAQTopicsValues>): ReactElement => (
                <form onSubmit={props.handleSubmit}>
                  <div className="topic-select-container">
                    <p className="topic-label">Topic:</p>
                    <div className="topic-select">
                      <SheetzSelect
                        name="topic"
                        options={faqTopics}
                        buttonStyle
                        onChangeEvent={(val): void => {
                          props.handleChange(val);
                          if (val) {
                            setSelectedTopic(val.target.value);
                            const urlParam = Object.keys(anchors).find(
                              (key) => anchors[key] === val.target.value
                            );
                            const url = urlParam ? "/FAQ/" + urlParam : "/FAQ";
                            navigate(url, { replace: true });
                          }
                        }}
                      />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          {(selectedTopic === "All" || selectedTopic === "General") && (
            <>
              <p className="section-header">General</p>
              <FAQElements questionAnswerList={generalItems} />
            </>
          )}

          {(selectedTopic === "All" || selectedTopic === "My Sheetz Rewardz Card") && (
            <>
              <p className="section-header">My Sheetz Rewardz</p>
              <FAQElements questionAnswerList={mySheetzRewardzItems} />
            </>
          )}

          {(selectedTopic === "All" || selectedTopic === "Jobs") && (
            <>
              <p className="section-header">Jobs</p>
              <FAQElements questionAnswerList={jobsItems} />
            </>
          )}

          {(selectedTopic === "All" || selectedTopic === "Ordering & Nutrition") && (
            <>
              <p className="section-header">Ordering &amp; Nutrition</p>
              <FAQElements questionAnswerList={orderingAndNutritionItems} />
            </>
          )}

          {(selectedTopic === "All" || selectedTopic === "Fuel") && (
            <>
              <p className="section-header">Fuel</p>
              <FAQElements questionAnswerList={fuelItems} />
            </>
          )}
        </div>
      </ResponsiveLayoutContainer>
    </>
  );
};

export default FAQ;
