import React, { ReactElement, useEffect } from "react";

import { Condiment, Selector } from "assets/dtos/anywhere-dto";

import CondimentGrid from "components/Order/ItemCustomization/CondimentGrid/CondimentGrid";
import CustomizationOption from "components/Order/ItemCustomization/CustomizationOption/CustomizationOption";
import SelectorHeader from "components/Order/ItemCustomization/SelectorHeader/SelectorHeader";
import InlineSubSelector from "components/Order/ItemCustomization/Selectors/InlineSubSelector/InlineSubSelector";
import { setInlineSubSelectorRowPlacement } from "components/Order/ItemCustomization/Selectors/InlineSubSelector/InlineSubSelector.util";

import { useMediaQuery } from "hooks";

import { desktopMediaQuery } from "util/AppContext.util";
import { ExtraableOption } from "util/Customization.util";

interface ExtraableCondimentSelectorProps {
  selector: Selector;
  options: ExtraableOption[];
  selectedOptions?: ExtraableOption[];
  onOptionSelected: (selectedOptions: ExtraableOption[]) => void;
  onExtraSelected: () => void;
  index: number;
}

const ExtraableCondimentSelector = (props: ExtraableCondimentSelectorProps): ReactElement => {
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);
  const gridRef = React.createRef<HTMLDivElement>();
  const inlineSubSelectorRef = React.createRef<HTMLDivElement>();

  const options = props.options.map((extraableOption) => {
    const isSelected =
      props.selectedOptions &&
      !!props.selectedOptions.find(
        (selectedOption) =>
          selectedOption.condiment.retailModifiedItem.retailModifiedItemId ===
          extraableOption.condiment.retailModifiedItem.retailModifiedItemId
      );

    let condiment: Condiment | undefined = extraableOption.condiment;
    // If there is already a selection, and this option is not selected, then the condiment should be the "extra" version.
    if (props.selectedOptions?.length === 1 && !isSelected) {
      condiment = extraableOption.extraCondiment;
    }

    return (
      <CustomizationOption
        key={extraableOption.option.text}
        option={extraableOption.option}
        condiment={condiment}
        type={props.selector.type}
        isSelected={isSelected}
        onCondimentOptionSelected={condimentOptionSelected}
      />
    );
  });

  let isMaxSelectionsReached = false;
  if (props.selectedOptions) {
    // The maximum number of selections is two separate options or...
    if (props.selectedOptions.length === 2) {
      isMaxSelectionsReached = true;
      // The maximum number of selections is one selected option with its extra option applied or...
    } else if (props.selectedOptions?.[0]?.extraOptionSelected) {
      isMaxSelectionsReached = true;
      // The maximum number of selections is a noOption selected.
    } else if (props.selectedOptions[0] && !props.selectedOptions[0].extraCondiment) {
      isMaxSelectionsReached = true;
    }
  }

  function condimentOptionSelected(condiment: Condiment): void {
    const isCondimentAlreadySelected = !!props.selectedOptions?.find(
      (selectedOption) =>
        selectedOption.condiment.retailModifiedItem.retailModifiedItemId ===
          condiment.retailModifiedItem.retailModifiedItemId ||
        selectedOption.extraCondiment?.retailModifiedItem.retailModifiedItemId ===
          condiment.retailModifiedItem.retailModifiedItemId
    );

    /**
     * If the max number of selections has been reached and the condiment isn't being removed,
     * then do nothing.
     */
    if (isMaxSelectionsReached && !isCondimentAlreadySelected) {
      return;
    }

    // Filter out the selected condiment, which allows the user to de-select the condiment.
    if (isCondimentAlreadySelected && props.selectedOptions) {
      const updatedSelectedOptions = props.selectedOptions.filter(
        (selectedOption) =>
          selectedOption.condiment.retailModifiedItem.retailModifiedItemId !==
          condiment.retailModifiedItem.retailModifiedItemId
      );

      // If we are now down to 1 selection, then this selection cannot be the "extra" anymore, which it could have been.
      if (updatedSelectedOptions.length === 1) {
        updatedSelectedOptions[0].extraOptionSelected = false;
      }

      props.onOptionSelected(updatedSelectedOptions);
    } else {
      // Find the matching option and add it to the existing selection or create a new array.
      const newlySelectedOption = props.options.find(
        (option) =>
          option.condiment.retailModifiedItem.retailModifiedItemId ===
            condiment.retailModifiedItem.retailModifiedItemId ||
          option.extraCondiment?.retailModifiedItem.retailModifiedItemId ===
            condiment.retailModifiedItem.retailModifiedItemId
      );
      if (newlySelectedOption) {
        // If there is already a selection and this new one is a noOption, do nothing. (You can't select a noOption once a selection is made.)
        if (props.selectedOptions?.length === 1 && !newlySelectedOption.extraCondiment) {
          return;
        }
        let updatedSelectedOptions = [newlySelectedOption];
        if (props.selectedOptions) {
          updatedSelectedOptions = updatedSelectedOptions.concat(props.selectedOptions);
        }

        // If there is already one selected option, then this newSelectedOption is the "extra".
        if (props.selectedOptions?.length === 1) {
          newlySelectedOption.extraOptionSelected = true;
        }
        props.onOptionSelected(updatedSelectedOptions);
      }
    }
  }

  // The option to add extra of a condiment should only show when a single option is selected and it is not a noOption.
  function shouldInlineSubSelectorShow(): boolean {
    if (props.selectedOptions) {
      return props.selectedOptions.length === 1 && !!props.selectedOptions[0].extraCondiment;
    }
    return false;
  }

  useEffect(() => {
    if (
      props.selectedOptions &&
      props.selectedOptions.length === 1 &&
      !!props.selectedOptions[0].extraCondiment
    ) {
      setInlineSubSelectorRowPlacement(
        useDesktopView,
        gridRef.current,
        inlineSubSelectorRef.current,
        props.options,
        props.selectedOptions[0]
      );
    }
  }, [gridRef, inlineSubSelectorRef, props.options, props.selectedOptions, useDesktopView]);

  return (
    <div
      className={
        isMaxSelectionsReached
          ? "extraable-condiment-selector limit-reached"
          : "extraable-condiment-selector"
      }
    >
      <SelectorHeader
        headerText={props.selector.text ?? ""}
        maxSelections={2}
        required={props.selector.optional === false}
        selected={!!props.selectedOptions}
      />
      <CondimentGrid ref={gridRef}>
        {options}
        {shouldInlineSubSelectorShow() &&
          props.selectedOptions &&
          props.selectedOptions[0]?.extraCondiment && (
            <InlineSubSelector
              ref={inlineSubSelectorRef}
              condiment={props.selectedOptions[0].extraCondiment}
              selected={!!props.selectedOptions[0].extraOptionSelected}
              onInlineSubSelectorSelected={(): void => props.onExtraSelected()}
            />
          )}
      </CondimentGrid>
    </div>
  );
};

export default ExtraableCondimentSelector;
