/**
 * This will trigger the visa branding animation in fullscreen. It takes about 1.5 seconds to complete.
 * @param usePayGreen Whether or not to show the background of the animation as pay green.
 */
export function showVisaSuccess(usePayGreen = false): void {
  const path = process.env.PUBLIC_URL + "/VisaSensoryBrandingSDK";
  window.VisaSensoryBranding?.init(
    {
      constrained: false,
      checkmark: "checkmark",
      sound: true,
      color: usePayGreen ? "359c57" : "dd132c",
      languageCode: "en",
    },
    path
  );
  window.VisaSensoryBranding?.show();
}
