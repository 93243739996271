import classNames from "classnames";
import React, { ReactElement } from "react";

import "components/misc/item/ItemDisplay/ItemImageTextDisplay/ItemImageTextDisplay.scss";

import { getImageSrc, getImageSrcSet } from "util/Image.util";

interface ItemImageTextDisplayProps {
  description?: JSX.Element | string | null;
  icon?: ReactElement | null;
  image?: string;
  paddingRight?: boolean;
  staticImage?: string;
  staticImageWidth?: number;
  title?: string;
}

const ItemImageTextDisplay = (props: ItemImageTextDisplayProps) => {
  const itemTextClassNames = classNames("item-text", {
    "padding-right": props.paddingRight,
  });

  const itemTitleClassNames = classNames("item-title", {
    "margin-bottom": props.description,
  });

  return (
    <>
      <div className="item-image">
        {props.image && (
          <img
            src={getImageSrc(props.image)}
            srcSet={getImageSrcSet(props.image)}
            sizes="64px"
            alt={props.title}
          />
        )}

        {props.staticImage && (
          <img className="static-image" src={props.staticImage} alt={props.title} />
        )}

        {!props.image && !props.staticImage && props.icon}
      </div>

      <div className={itemTextClassNames}>
        {props.title && <p className={itemTitleClassNames}>{props.title}</p>}
        {props.description && <p className="item-description">{props.description}</p>}
      </div>
    </>
  );
};

export default ItemImageTextDisplay;
