const pageNames: { [key: string]: string } = {
  "/order": "Pick up information",
  "/order/information": "Order information",
  "/order/delivery/address": "Choose an address",
  "/order/delivery/createAddress": "Add new address",
  "/order/delivery/editAddress": "Edit address",
  "/order/delivery/phoneNumber": "Mobile phone number",
  "/order/delivery/time": "Delivery time",
  "/order/selectStore": "Select Store",
  "/order/time": "Pick up time",
  "/order/location": "Select a pick up Location",
  "/order/menu": "Full menu",
  "/order/search": "Search",
  "/order/paymentType": "Choose payment",
  "/order/confirmOffers": "Available rewardz",
  "/order/confirm": "Confirm my order",
  "/order/success": "Order Complete",
  "/order/favorites": "Favorites",
  "/order/history": "My Purchase History",
  "/order/history/detail": "My order",
  "/order/favorites/detail": "Edit favorite",
  "/account/settings": "Account settings",
  "/account/settings/employeeInformation": "Employee information",
  "/account/settings/security": "Security",
  "/account/settings/security/changePassword": "Change password",
  "/account/settings/preferences": "Preferences",
  "/account/settings/preferences/notifications": "Notifications",
  "/account/settings/preferences/myOrderingPreferences": "My ordering preferences",
  "/account/settings/preferences/myOrderingPreferences/myStores": "My stores",
  "/account/settings/preferences/myOrderingPreferences/addVehicle": "Add vehicles",
  "/account/settings/preferences/myOrderingPreferences/myVehicles": "My vehicles",
  "/account/settings/preferences/myOrderingPreferences/addAddress": "Add addresses",
  "/account/settings/preferences/myOrderingPreferences/myAddresses": "My addresses",
  "/account/favorites": "Favorites",
  "/account/mySheetzCards": "My loyalty card",
  "/account/myWallet": "My wallet",
  "/account/addPaymentMethod": "Add payment method",
  "/account/buyGiftCard": "Buy a gift card",
  "/account/autoReload": "Auto reload",
  "/account/addFunds": "Add funds",
  "/account/rewardz": "My Rewardz",
  "/account/myOfferz": "My Offerz",
  "/account/ageRestrictedOffers": "21+ Offers",
  "/account/myReceipts": "My receipts",
  "/account/receipt": "Receipt",
  "/account/deferredFavorite": "My favorites",
  "/account/verifyAge": "Verify my age",
  "/auth/login": "Login",
  "/auth/register": "Register",
  "/auth/forgot": "Forgot Password",
  "/auth/resetPassword": "Reset Password",
  "/auth/codeEntry": "Enter Reset Code",
  "/findASheetz": "Find a Sheetz",
  "/FAQ": "FAQ's",
  "/contact": "Contact us",
};

export default pageNames;
