import React from "react";

import "./CondimentGrid.scss";

const CondimentGrid = React.forwardRef<HTMLDivElement, React.PropsWithChildren<unknown>>(
  (props, gridRef) => (
    <div ref={gridRef} className="condiment-grid">
      {props.children}
    </div>
  )
);

// Needed to suppress ESLint React/Display-Name warning. See: https://github.com/yannickcr/eslint-plugin-react/issues/2269
CondimentGrid.displayName = "CondimentGrid";

// const CondimentGrid = (props: React.PropsWithChildren<{}>): ReactElement => {
//   return <div className="condiment-grid">{props.children}</div>;
// };

export default CondimentGrid;
