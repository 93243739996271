import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import "./SheetzToast.scss";

export enum ToastType {
  success = "success",
  error = "error",
  confirm = "confirm",
}

interface ToastProps {
  title: string;
  text: string;
  type: ToastType;
  shouldShowToast: boolean;
  modal?: boolean;
  toastPressed?: () => void;
}

const SheetzToast = ({
  title,
  text,
  type,
  shouldShowToast,
  modal = false,
  toastPressed,
}: ToastProps) => {
  const location = useLocation();
  const [showToast, setShowToast] = useState<boolean>(false);
  const previousLocation = useRef(location.pathname);

  // search for the header and if it is the homepage header for dynamic styling
  const headerElement = document.getElementsByClassName("sheetz-header");
  let homepageHeaderElement = undefined;
  if (headerElement.length > 0) {
    homepageHeaderElement = document.getElementsByClassName("homepage");
  }

  const toastClasses = classNames("toast", {
    active: showToast,
    success: type === "success",
    error: type === "error",
    confirm: type === "confirm",
    hasHeader:
      headerElement.length > 0 && homepageHeaderElement && homepageHeaderElement.length === 0,
    hasHomepageHeader:
      headerElement.length > 0 && homepageHeaderElement && homepageHeaderElement.length > 0,
    modal: modal,
  });

  useEffect(() => {
    setShowToast(shouldShowToast);

    if (previousLocation.current !== location.pathname) {
      toastPressed?.();
    }

    previousLocation.current = location.pathname;
  }, [location, shouldShowToast, toastPressed]);

  function hideToast(): void {
    toastPressed?.();
  }

  return (
    <div
      onClick={hideToast}
      className={toastClasses}
      role="button"
      aria-pressed="false"
      tabIndex={0}
    >
      <div
        className={classNames("toastContent", {
          homepageMargin: headerElement && headerElement.length > 0 && homepageHeaderElement,
        })}
      >
        <div className="title">{title}</div>
        <div className="text">{text}</div>
      </div>
    </div>
  );
};

export default SheetzToast;
