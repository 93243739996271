import React, { ReactElement } from "react";
import { NumericFormat } from "react-number-format";

import { ShoppingBag, calculateShoppingBagSubTotal } from "util/Bag.util";

interface ShoppingBagSubtotalProps {
  shoppingBag?: ShoppingBag;
}

const ShoppingBagSubtotal = (props: ShoppingBagSubtotalProps): ReactElement | null => {
  if (props.shoppingBag === undefined) {
    return null;
  }

  const subtotal = calculateShoppingBagSubTotal(props.shoppingBag);

  return (
    <NumericFormat displayType="text" value={subtotal} decimalScale={2} fixedDecimalScale={true} />
  );
};

export default ShoppingBagSubtotal;
