import classNames from "classnames";
import { useField } from "formik";
import React, { HTMLProps, ReactElement } from "react";

import "./SheetzCheckbox.scss";

interface CheckboxProps extends HTMLProps<HTMLInputElement> {
  green?: boolean;
  noPaddingTop?: boolean;
}

const SheetzCheckbox = (checkboxProps: CheckboxProps): ReactElement => {
  const { green, noPaddingTop, ...props } = checkboxProps;
  const [field] = useField(props?.name ?? "");
  const checkboxClassNames = classNames(props.className, {
    green: green,
  });
  const sheetzCheckboxClassNames = classNames("sheetz-checkbox", {
    green: green,
    "no-padding-top": noPaddingTop,
  });
  const checked = props.checked ?? field.value;

  return (
    <div className={sheetzCheckboxClassNames}>
      <label htmlFor={props.name}>
        <input
          aria-describedby={props.name + "-checkbox"}
          aria-label={props.label}
          {...field}
          {...props}
          id={props.name}
          className={checkboxClassNames}
          checked={checked}
          disabled={props.disabled}
          type="checkbox"
        />

        <span id={props.name + "-checkbox"}>{props.label}</span>
      </label>
    </div>
  );
};

export default SheetzCheckbox;
