import React, { useContext, useEffect, useRef, useState } from "react";

import "./AgeRestrictedOffers.scss";

import { Offer as OfferDTO, OfferRequestAction } from "assets/dtos/anywhere-dto";

import Offer from "components/Account/Offerz/Offer";
import EmptyPage from "components/misc/indicators/EmptyPage/EmptyPage";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { IconType } from "util/Icon.util";
import { OfferAction, getActiveOffers, updateOffer } from "util/Offerz.util";

const MyOfferz = () => {
  const appContext = useContext(AppContext);
  const [offers, setOffers] = useState<OfferDTO[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const showLoading = appContext.showLoading;
  const hideLoading = appContext.hideLoading;
  const evergageEventElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getOffers();
    const viewMyOfferzItemEvent = new CustomEvent("viewMyOffers", {
      detail: {
        eventName: "My Offerz",
      },
    });
    evergageEventElement.current?.dispatchEvent(viewMyOfferzItemEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const update = function (offerId: number, offerAction: OfferRequestAction): void {
    updateOffer(offerId, { action: offerAction }).then(() => {
      const offerMessage = offerAction === OfferAction.ACCEPT.toString() ? "accepted" : "removed";
      appContext.showToast("Successfully " + offerMessage + " offer!", "", ToastType.success);
      setTimeout(() => {
        getOffers();
      }, 1000);
    });
  };

  const getOffers = function (): void {
    setLoading(true);
    showLoading();

    getActiveOffers()
      .then((response) => {
        if (response.data && response.data.offers && response.data.offers.length > 0) {
          const offerItems: OfferDTO[] = response.data.offers;
          setOffers(
            offerItems.filter((offerItem) => !offerItem.isDeclined || !offerItem.isRedeemed)
          );
        }
      })
      .finally(() => {
        hideLoading();
        setLoading(false);
      });
  };

  const myOfferItems = offers?.map((offer) => {
    return (
      <>
        <Offer key={offer.offerId} offer={offer} onUpdateOffer={update} />
      </>
    );
  });
  if (offers && offers.length > 0) {
    return (
      <div className="age-restricted-offers-container evergage-event" ref={evergageEventElement}>
        <div className="my-offers-container">{myOfferItems}</div>
      </div>
    );
  } else if (!loading) {
    return <EmptyPage title="You have no available Offerz" icon={IconType.offer} />;
  } else {
    return <></>;
  }
};

export default MyOfferz;
