import React, { useState } from "react";

import "./MyRewardz.scss";

import { PurchasedReward } from "assets/dtos/anywhere-dto";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import EmptyPage from "components/misc/indicators/EmptyPage/EmptyPage";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { IconType } from "util/Icon.util";
import { getDateTimeFromString } from "util/Time.util";

interface MyRewardzProps {
  purchasedRewards?: PurchasedReward[];
}

interface RewardDetailItemProps {
  rewards: PurchasedReward[];
}

const MyRewardz = (props: MyRewardzProps) => {
  const [showRewardDetails, setShowRewardDetails] = useState<boolean>(false);
  const [actionSheetReward, setActionSheetReward] = useState<PurchasedReward>();

  const rewardDetailsMessage = (
    <ActionSheet
      color={ActionSheetColor.red}
      title="My Reward"
      shouldDisplay={showRewardDetails}
      required
      overlay={true}
    >
      <div className="action-sheet-rewardz-container">
        <div className="action-sheet-rewardz">
          <img
            className="action-sheet-rewardz-image"
            src={actionSheetReward?.imageURL}
            alt={actionSheetReward?.offerName}
          />
          <div className="action-sheet-rewardz-info">
            <div className="action-sheet-rewardz-name">{actionSheetReward?.offerName}</div>
            <div className="action-sheet-rewardz-detail">{actionSheetReward?.description}</div>
          </div>
        </div>
        <div className="action-sheet-btn-wrapper">
          <SheetzButton
            className="action-sheet-close-btn"
            label="Close"
            onClick={(): void => {
              setShowRewardDetails(false);
            }}
          />
        </div>
      </div>
    </ActionSheet>
  );

  const RewardDetailItem = (props: RewardDetailItemProps) => {
    if (props.rewards) {
      const rewardList = props.rewards.map((reward) => {
        return (
          <>
            <div
              tabIndex={0}
              role="button"
              aria-pressed="false"
              className="my-rewardz-item"
              onClick={(event?): void => {
                event?.preventDefault();
                setActionSheetReward(reward);
                setShowRewardDetails(true);
              }}
            >
              <img className="my-rewardz-image" src={reward.imageURL} alt={reward.offerName} />
              <div className="my-rewardz-info">
                <div className="my-rewardz-name">{reward.offerName}</div>
                <div className="my-rewardz-detail">{reward.description}</div>
              </div>
              <div className="my-rewardz-amount-date">
                <div className="my-rewardz-count">{reward.balance}</div>
                <div className="my-rewardz-expiration">
                  {"Expires " +
                    getDateTimeFromString(reward.balanceExpireDate).toFormat("L'/'d'/'yy")}
                </div>
              </div>
            </div>
          </>
        );
      });
      return <>{rewardList}</>;
    } else {
      return null;
    }
  };

  if (props.purchasedRewards && props.purchasedRewards.length > 0) {
    const rewardElements = <RewardDetailItem rewards={props.purchasedRewards} />;
    return (
      <ResponsiveLayoutContainer>
        <div className="my-rewardz-label">Tap on a Reward to view details.</div>
        <div className="my-rewardz-container">{rewardElements}</div>
        {rewardDetailsMessage}
      </ResponsiveLayoutContainer>
    );
  } else {
    return (
      <EmptyPage
        title="You have no available Rewardz"
        icon={IconType.reward}
        detail="Purchase Rewardz in Get Rewardz with your Spendable Pointz you earn while buying shtuff at Sheetz!"
      />
    );
  }
};

export default MyRewardz;
