import axios, { AxiosPromise } from "axios";

import {
  GetActiveAgeRestrictedOffersResponse,
  GetActiveAgeRestrictedRebatesResponse,
  UpdateOfferRequest,
  UpdateRebateRequest,
} from "assets/dtos/anywhere-dto";

import {
  ACTIVE_AGE_RESTRICTED_OFFERS,
  ACTIVE_AGE_RESTRICTED_REBATES,
  UPDATE_AGE_RESTRICTED_OFFER,
  UPDATE_AGE_RESTRICTED_REBATE,
} from "endpoints/loyalty.endpoints";

export function getActiveAgeRestrictedOffers(): AxiosPromise<GetActiveAgeRestrictedOffersResponse> {
  return axios({
    method: "GET",
    url: ACTIVE_AGE_RESTRICTED_OFFERS,
    disableErrorMessageDisplay: true,
  });
}

export enum RebateAction {
  "READ" = "READ",
  "CLIP" = "CLIP",
  "DISMISS" = "DISMISS",
}

export function getActiveAgeRestrictedRebates(): AxiosPromise<GetActiveAgeRestrictedRebatesResponse> {
  return axios({
    method: "GET",
    url: ACTIVE_AGE_RESTRICTED_REBATES,
    disableErrorMessageDisplay: true,
  });
}

export function updateOffer(offerId: number, request: UpdateOfferRequest): AxiosPromise<null> {
  return axios({
    method: "PUT",
    url: UPDATE_AGE_RESTRICTED_OFFER(offerId),
    data: request,
  });
}
export function updateRebate(rebateId: number, request: UpdateRebateRequest): AxiosPromise<null> {
  return axios({
    method: "PUT",
    url: UPDATE_AGE_RESTRICTED_REBATE(rebateId),
    data: request,
  });
}
export function updateAllOffersAsRead(request: UpdateOfferRequest): AxiosPromise<null> {
  return axios({
    method: "PUT",
    url: UPDATE_AGE_RESTRICTED_OFFER(),
    data: request,
  });
}

export function updateAllRebatesAsRead(request: UpdateRebateRequest): AxiosPromise<null> {
  return axios({
    method: "PUT",
    url: UPDATE_AGE_RESTRICTED_REBATE(),
    data: request,
  });
}

export interface FilterOptions {
  value: string;
  label: string;
}
