import { DateTime } from "luxon";

import { categoryPathStorageKey } from "components/Order/Menu/Menu";

import { PinAuthentication } from "util/Pin.util";

export function setUserId(userId: string): void {
  sessionStorage.setItem("userId", userId);
}

export function getUserId(): string | undefined {
  return sessionStorage.getItem("userId") || undefined;
}

export function removeUserId(): void {
  sessionStorage.removeItem("userId");
}

export function setRecentStore(recentStore: string): void {
  sessionStorage.setItem("recentStore", recentStore);
}

export function getRecentStore(): string | null {
  return sessionStorage.getItem("recentStore") || null;
}

export function removeRecentStore(): void {
  sessionStorage.removeItem("recentStore");
}

export function setFirstName(firstName: string): void {
  sessionStorage.setItem("firstName", firstName);
}

export function getFirstName(): string | null {
  return sessionStorage.getItem("firstName") || null;
}

export function removeFirstName(): void {
  sessionStorage.removeItem("firstName");
}

export function setAuthToken(authToken: string): void {
  sessionStorage.setItem("authToken", authToken);
}

export function getAuthToken(): string | null {
  return sessionStorage.getItem("authToken");
}

export function removeAuthToken(): void {
  sessionStorage.removeItem("authToken");
}

export function setFPRToken(fprToken: string): void {
  sessionStorage.setItem("fprToken", fprToken);
}

export function getFPRToken(): string | null {
  return sessionStorage.getItem("fprToken");
}

export function removeFPRToken(): void {
  sessionStorage.removeItem("fprToken");
}

export function setPinAuthToken(pinAuthToken: string): void {
  sessionStorage.setItem(
    "pinAuthToken",
    JSON.stringify({ jwtToken: pinAuthToken, expiration: new Date() } as PinAuthentication)
  );
}

export function getPinAuthToken(): PinAuthentication | null {
  const pinAuth = sessionStorage.getItem("pinAuthToken");
  return pinAuth ? (JSON.parse(pinAuth) as PinAuthentication) : null;
}

export function removePinAuthToken(): void {
  sessionStorage.removeItem("pinAuthToken");
}

export function setUsername(username: string): void {
  localStorage.setItem("username", username);
}

export function getUsername(): string | null {
  return localStorage.getItem("username") || null;
}

export function removeUsername(): void {
  localStorage.removeItem("username");
}

export function setOrderSession(orderSession: string): void {
  localStorage.setItem("orderSession", orderSession);
}

export function getOrderSession(): string | undefined {
  return localStorage.getItem("orderSession") || undefined;
}

export function removeOrderSession(): void {
  localStorage.removeItem("orderSession");
}

export function setSelectedPaymentMethodId(paymentMethodId: number): void {
  sessionStorage.setItem("selectedPaymentMethodId", paymentMethodId.toString());
}

export function getSelectedPaymentMethodId(): string | null {
  return sessionStorage.getItem("selectedPaymentMethodId");
}

export function removeSelectedPaymentMethodId(): void {
  sessionStorage.removeItem("selectedPaymentMethodId");
}

export function setSelectedWalletPaymentMethodId(paymentMethodId: number): void {
  sessionStorage.setItem("selectedWalletPaymentMethodId", paymentMethodId.toString());
}

export function getSelectedWalletPaymentMethodId(): string | null {
  return sessionStorage.getItem("selectedWalletPaymentMethodId");
}

export function removeSelectedWalletPaymentMethodId(): void {
  sessionStorage.removeItem("selectedWalletPaymentMethodId");
}

export function setReorderActionSheetShown(): void {
  localStorage.setItem("reorderActionSheetShown", "true");
}

export function getReorderActionSheetShown(): string | null {
  return localStorage.getItem("reorderActionSheetShown");
}

export function clearCategoryPathData(): void {
  sessionStorage.removeItem(categoryPathStorageKey);
}

export function setOrUpdateLastActivityTime(): void {
  localStorage.setItem("lastActivityTime", DateTime.now().toJSON());
}

export function getLastActivityTime(): string | null {
  return localStorage.getItem("lastActivityTime");
}

export function clearLastActivityTime(): void {
  localStorage.removeItem("lastActivityTime");
}
