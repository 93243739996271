import React from "react";
import { useNavigate } from "react-router-dom";

import "./MyOrderingPreferences.scss";

import PathListItem from "components/misc/list/PathListItem/PathListItem";

import { IconType, getIcon } from "util/Icon.util";

const MyOrderingPreferences = () => {
  const navigate = useNavigate();

  return (
    <div className="ordering-preferences-container">
      <>
        <PathListItem
          title={
            <div className="ordering-preferences-link">
              {getIcon(IconType.store, "preference-icon")}
              <span>My Stores</span>
            </div>
          }
          clickHandler={(): void => {
            navigate("/account/settings/preferences/myOrderingPreferences/myStores");
          }}
        />
        <PathListItem
          title={
            <div className="ordering-preferences-link">
              {getIcon(IconType.vehicle, "preference-icon")}
              <span>My Vehicles</span>
            </div>
          }
          clickHandler={(): void => {
            navigate("/account/settings/preferences/myOrderingPreferences/myVehicles");
          }}
        />
        <PathListItem
          title={
            <div className="ordering-preferences-link">
              {getIcon(IconType.home, "preference-icon")}
              <span>My Addresses</span>
            </div>
          }
          clickHandler={(): void => {
            navigate("/account/settings/preferences/myOrderingPreferences/myAddresses");
          }}
          hideBottomBorder
        />
      </>
    </div>
  );
};

export default MyOrderingPreferences;
