import { useField } from "formik";
import React, { HTMLProps, useState } from "react";

import "./SheetzInput.scss";

import { IconType, getIcon } from "util/Icon.util";

export interface SelectOption {
  label: string;
  value: string;
}

interface InputProps extends HTMLProps<HTMLInputElement> {
  showError?: boolean;
  name: string;
  isSearchInput?: boolean;
}

const SheetzInput = React.forwardRef<HTMLInputElement, InputProps>((inputProps, ref) => {
  const { showError, isSearchInput, ...props } = inputProps;
  const [field, meta] = useField(props.name);
  const [showPassword, setShowPassword] = useState(false);
  // Showing errors on the input fields is turned on by default - This is for things like the password checklist.
  const showErrors: boolean = showError === undefined ? true : showError;
  const passwordButton =
    props.type === "password" ? (
      <button
        aria-label={showPassword ? "Hide password" : "Show password"}
        type={"button"}
        className={"showPassword"}
        onClick={(): void => {
          setShowPassword(!showPassword);
        }}
      >
        {getIcon(IconType.eyeball, "showPasswordIcon")}
      </button>
    ) : (
      <></>
    );

  return (
    <div
      className={
        "sheetz-input " +
        props.type +
        (meta.touched && meta.error ? " error" : "") +
        (isSearchInput ? " search" : "")
      }
    >
      {props.type !== "select" && (
        <input
          aria-describedby={props.name + "_error"}
          {...field}
          {...props}
          id={props.name}
          ref={ref}
          type={showPassword ? "text" : props.type}
        />
      )}
      {passwordButton}
      <label htmlFor={props.name}>{props.label}</label>
      {meta.touched && meta.error && showErrors ? (
        <div className="error" id={props.name + "_error"}>
          {meta.error}
        </div>
      ) : null}
      {isSearchInput && (
        <button className="search-button" type="submit">
          Search
        </button>
      )}
    </div>
  );
});

// Needed to suppress ESLint React/Display-Name warning. See: https://github.com/yannickcr/eslint-plugin-react/issues/2269
SheetzInput.displayName = "SheetzInput";

export default SheetzInput;
