import React, { ReactElement, useRef, useState } from "react";

import { HomeScreenBanner } from "assets/dtos/anywhere-dto";

import Loading from "components/misc/indicators/Loading/Loading";
import AlertActionSheet from "components/misc/view/AlertActionSheet/AlertActionSheet";
import ConfirmationActionSheet from "components/misc/view/ConfirmationActionSheet/ConfirmationActionSheet";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import {
  hideNativeMobileLoadingIndicator,
  isInNativeMobileContext,
  showNativeDialog,
  showNativeDialogAvailable,
  showNativeMobileLoadingIndicator,
} from "util/MobileApp.util";

const loadingOpenMinimumMillis = 700;
const loadingCloseMinimumMillis = 1500;

export const desktopMediaQuery = "(min-width: 640px)";
export const initiateStoreDetailsMapMediaQuery = "(min-width: 600px)";
export const tokenIssuerForcePasswordReset = "clifpr";

export interface AppContextState {
  homeScreenBanners: HomeScreenBanner[];
  loading: {
    render: boolean;
    show: boolean;
    renderTimestamp: number;
  };
  showLoading: () => void;
  hideLoading: () => void;
  toast: {
    render: boolean;
    show: boolean;
    text: string;
    title: string;
    type: ToastType;
    modal: boolean;
  };
  showToast: (newTitle: string, newText: string, type: ToastType, modal?: boolean) => void;
  hideToast: () => void;
  addToBagPopup: {
    render: boolean;
    show: boolean;
    title: string;
  };
  showAddToBagPopup: (isEdit: boolean) => void;
  hideAddToBagPopup: () => void;
  confirmationActionSheet: {
    show: boolean;
    message: string | JSX.Element;
    confirmButtonTitle: string;
    confirmButtonHandler: () => void;
    cancelButtonTitle?: string;
    cancelButtonHandler?: () => void;
  };
  showConfirmationActionSheet: (
    message: string | JSX.Element,
    confirmButtonTitle: string,
    confirmButtonHandler: () => void,
    cancelButtonHandler?: () => void,
    cancelButtonTitle?: string
  ) => void;
  hideConfirmationActionSheet: () => void;
  alertActionSheet: {
    show: boolean;
    alertTitle?: string;
    alertMessage: string;
    confirmationButtonHandler?: () => void;
  };
  showAlertActionSheet: (
    alertMessage: string,
    confirmationButtonHandler?: () => void,
    alertTitle?: string
  ) => void;
  hideAlertActionSheet: () => void;
}

export const AppContext = React.createContext<AppContextState>({
  homeScreenBanners: [] as HomeScreenBanner[],
  loading: {
    render: false,
    show: false,
    renderTimestamp: 0,
  },
  showLoading: () => {
    return;
  },
  hideLoading: () => {
    return;
  },
  toast: {
    render: false,
    show: false,
    text: "",
    title: "",
    type: ToastType.success,
    modal: false,
  },
  showToast: (newTitle: string, newText: string, type: ToastType, modal?: boolean) => {
    return;
  },
  hideToast: () => {
    return;
  },
  addToBagPopup: {
    render: false,
    show: false,
    title: "",
  },
  showAddToBagPopup: (isEdit: boolean) => {
    return;
  },
  hideAddToBagPopup: () => {
    return;
  },
  confirmationActionSheet: {
    show: false,
    message: "",
    confirmButtonTitle: "",
    confirmButtonHandler: (): void => {
      return;
    },
    cancelButtonTitle: "",
    cancelButtonHandler: (): void => {
      return;
    },
  },
  showConfirmationActionSheet: (
    message: string | JSX.Element,
    confirmButtonTitle: string,
    confirmButtonHandler: () => void,
    cancelButtonHandler?: () => void,
    cancelButtonTitle?: string
  ) => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  hideConfirmationActionSheet: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  alertActionSheet: {
    show: false,
    alertTitle: undefined,
    alertMessage: "",
    confirmationButtonHandler: undefined,
  },
  showAlertActionSheet: (
    alertMessage: string,
    confirmationButtonHandler?: () => void,
    alertTitle?: string
  ) => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  hideAlertActionSheet: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

interface AppContextProviderProps {
  children: React.ReactChild;
  homeScreenBanners: HomeScreenBanner[];
}

export const AppContextProvider = (props: AppContextProviderProps): ReactElement => {
  let showLoadingTimeout: number | undefined;

  function showLoading(): void {
    if (showLoadingTimeout === undefined) {
      showLoadingTimeout = window.setTimeout(openLoading, loadingOpenMinimumMillis);
    }
  }

  function hideLoading(): void {
    if (showLoadingTimeout) {
      clearTimeout(showLoadingTimeout);
      showLoadingTimeout = undefined;
    }
    const currentTime = new Date().getTime();
    const lapsedTime = currentTime - showLoadingRef.current;
    if (lapsedTime >= loadingCloseMinimumMillis) {
      closeLoading();
    } else {
      setTimeout(() => {
        closeLoading();
      }, loadingCloseMinimumMillis - lapsedTime);
    }
  }

  function showToast(newTitle: string, newText: string, type: ToastType, modal = false): void {
    if (showNativeDialogAvailable()) {
      if (isInNativeMobileContext() && type === ToastType.error) {
        try {
          showNativeDialog(true, newTitle ?? null, newText ?? "An unknown error has occurred.");
          return;
        } catch {
          // TODO: Eventually remove the try /catch
        }
      }
    }
    setState((prevState) => {
      return {
        ...prevState,
        toast: {
          ...prevState.toast,
          modal,
          render: true,
          show: false,
          title: newTitle,
          text: newText,
          type: type,
        },
      };
    });
    setTimeout(() => {
      setState((prevState) => {
        return {
          ...prevState,
          toast: { ...prevState.toast, show: true },
        };
      });
    }, 100);
    if (ToastType.success === type) {
      setTimeout(() => hideToast(), 3000);
    }
  }

  function hideToast(): void {
    setState((prevState) => {
      return {
        ...prevState,
        toast: { ...prevState.toast, show: false, render: true },
      };
    });
    setTimeout(() => {
      setState((prevState) => {
        return {
          ...prevState,
          toast: { ...prevState.toast, render: false },
        };
      });
    }, 300);
  }

  function showAddToBagPopup(isEdit: boolean): void {
    setState((prevState) => {
      return {
        ...prevState,
        addToBagPopup: {
          ...prevState.addToBagPopup,
          render: true,
          show: false,
          title: isEdit ? "Item updated!" : "Added to bag!",
        },
      };
    });
    setTimeout(() => {
      setState((prevState) => {
        return {
          ...prevState,
          addToBagPopup: { ...prevState.addToBagPopup, show: true },
        };
      });
    }, 100);
    setTimeout(() => {
      setState((prevState) => {
        return {
          ...prevState,
          addToBagPopup: { ...prevState.addToBagPopup, show: false },
        };
      });
    }, 6000);
    setTimeout(() => {
      setState((prevState) => {
        return {
          ...prevState,
          addToBagPopup: { ...prevState.addToBagPopup, render: false },
        };
      });
    }, 6300);
  }

  function hideAddToBagPopup(): void {
    setState((prevState) => {
      return {
        ...prevState,
        addToBagPopup: { ...prevState.addToBagPopup, show: false },
      };
    });
    setTimeout(() => {
      setState((prevState) => {
        return {
          ...prevState,
          addToBagPopup: { ...prevState.addToBagPopup, render: false },
        };
      });
    }, 300);
  }

  function showConfirmationActionSheet(
    message: string | JSX.Element,
    confirmButtonTitle: string,
    confirmButtonHandler: () => void,
    cancelButtonHandler?: () => void,
    cancelButtonTitle?: string
  ): void {
    setState((prevState) => {
      return {
        ...prevState,
        confirmationActionSheet: {
          show: true,
          message: message,
          confirmButtonTitle: confirmButtonTitle,
          confirmButtonHandler: confirmButtonHandler,
          cancelButtonTitle: cancelButtonTitle,
          cancelButtonHandler: cancelButtonHandler,
        },
      };
    });
  }

  function hideConfirmationActionSheet(): void {
    setState((prevState) => {
      return {
        ...prevState,
        confirmationActionSheet: { ...prevState.confirmationActionSheet, show: false },
      };
    });
  }

  function showAlertActionSheet(
    alertMessage: string,
    confirmationButtonHandler?: () => void,
    alertTitle?: string
  ): void {
    setState((prevState) => {
      return {
        ...prevState,
        alertActionSheet: {
          show: true,
          alertMessage: alertMessage,
          alertTitle: alertTitle,
          confirmationButtonHandler: confirmationButtonHandler,
        },
      };
    });
  }

  function hideAlertActionSheet(): void {
    setState((prevState) => {
      return {
        ...prevState,
        alertActionSheet: { ...prevState.alertActionSheet, show: false },
      };
    });
  }

  const initState: AppContextState = {
    homeScreenBanners: props.homeScreenBanners,
    loading: {
      render: false,
      show: false,
      renderTimestamp: 0,
    },
    showLoading: showLoading,
    hideLoading: hideLoading,
    toast: {
      render: false,
      show: false,
      text: "",
      title: "",
      type: ToastType.success,
      modal: false,
    },
    showToast: showToast,
    hideToast: hideToast,
    addToBagPopup: {
      render: false,
      show: false,
      title: "",
    },
    showAddToBagPopup: showAddToBagPopup,
    hideAddToBagPopup: hideAddToBagPopup,
    confirmationActionSheet: {
      show: false,
      message: "",
      confirmButtonTitle: "",
      confirmButtonHandler: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
      cancelButtonTitle: undefined,
      cancelButtonHandler: undefined,
    },
    showConfirmationActionSheet: showConfirmationActionSheet,
    hideConfirmationActionSheet: hideConfirmationActionSheet,
    alertActionSheet: {
      show: false,
      alertMessage: "",
      alertTitle: undefined,
      confirmationButtonHandler: undefined,
    },
    showAlertActionSheet: showAlertActionSheet,
    hideAlertActionSheet: hideAlertActionSheet,
  };

  function openLoading(): void {
    // For mobile apps only. Calling this outside of the mobile app is fine, as nothign will happen.
    showNativeMobileLoadingIndicator();

    setState((prevState) => {
      return {
        ...prevState,
        loading: {
          ...prevState.loading,
          render: true,
          show: false,
          renderTimestamp: new Date().getTime(),
        },
      };
    });
    setTimeout(() => {
      setState((prevState) => {
        return {
          ...prevState,
          loading: { ...prevState.loading, show: true },
        };
      });
    }, 100);
  }

  function closeLoading(): void {
    // For mobile apps only. Calling this outside of the mobile app is fine, as nothing will happen.
    hideNativeMobileLoadingIndicator();

    setState((prevState) => {
      return {
        ...prevState,
        loading: { ...prevState.loading, show: false, render: true },
      };
    });
    setTimeout(() => {
      setState((prevState) => {
        return {
          ...prevState,
          loading: { ...prevState.loading, render: false },
        };
      });
    }, 300);
  }

  const [state, setState] = useState(() => initState);
  const showLoadingRef = useRef(state.loading.renderTimestamp);
  showLoadingRef.current = state.loading.renderTimestamp;

  return (
    <AppContext.Provider value={state}>
      {props.children}
      {/* If running inside of the mobile apps, then don't ever render the built-in loading indicator. */}
      {state.loading.render && !isInNativeMobileContext() ? <Loading /> : <></>}
      <ConfirmationActionSheet
        show={state.confirmationActionSheet.show}
        confirmationMessage={state.confirmationActionSheet.message}
        confirmButtonTitle={state.confirmationActionSheet.confirmButtonTitle}
        confirmButtonHandler={state.confirmationActionSheet.confirmButtonHandler}
        cancelButtonTitle={state.confirmationActionSheet.cancelButtonTitle}
        cancelButtonHandler={(): void => {
          state.confirmationActionSheet.cancelButtonHandler?.();
          hideConfirmationActionSheet();
        }}
      />
      <AlertActionSheet
        show={state.alertActionSheet.show}
        alertMessage={state.alertActionSheet.alertMessage}
        alertTitle={state.alertActionSheet.alertTitle}
        confirmationButtonHandler={(): void => {
          state.alertActionSheet.confirmationButtonHandler?.();
          hideAlertActionSheet();
        }}
      />
    </AppContext.Provider>
  );
};
