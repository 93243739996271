import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { useNavigate } from "react-router-dom";

import "./QAndAListItem.scss";

import ListContainer from "components/misc/list/ListContainer/ListContainer";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";

import { FAQElementProp } from "util/FAQ.util";
import { IconType, getIcon } from "util/Icon.util";

export interface QAndAListItemProps {
  qAndAList: FAQElementProp;
}

const QAndAListItem = (props: QAndAListItemProps) => {
  const [subItemsExpanded, setSubItemsExpanded] = useState(false);
  const arrowClasses = subItemsExpanded ? "collapse-arrow up" : "collapse-arrow";
  const navigate = useNavigate();

  return (
    <>
      <li>
        <button
          className={subItemsExpanded ? "title-container" : ""}
          onClick={(): void => {
            setSubItemsExpanded(!subItemsExpanded);
          }}
        >
          <div className="question-content">
            <div className="title">
              <p>{props.qAndAList.title}</p>{" "}
            </div>
            <div className="arrow">{getIcon(IconType.arrow, arrowClasses)}</div>
          </div>
        </button>
        <Collapse
          isOpened={subItemsExpanded}
          theme={{
            collapse: "sub-items-container",
            content: "sub-items-content",
          }}
        >
          <div className="list-container">
            <ListContainer>
              <li key={props.qAndAList.bodyText}>
                <div className="faq-body-text">{props.qAndAList.bodyText}</div>
                <div className="button-container">
                  {props.qAndAList.buttons && props.qAndAList.buttons[0] && (
                    <div className="button-row">
                      <SheetzButton
                        className="faq-button-one"
                        type="button"
                        label={props.qAndAList.buttons[0].buttonText}
                        onClick={(): void => {
                          if (props.qAndAList.buttons && props.qAndAList.buttons[0].buttonLink) {
                            props.qAndAList.buttons[0].externalLink
                              ? window.open(props.qAndAList.buttons[0].buttonLink, "_blank")
                              : navigate(props.qAndAList.buttons[0].buttonLink);
                          }
                        }}
                      />
                    </div>
                  )}
                  {props.qAndAList.buttons && props.qAndAList.buttons[1] && (
                    <div className="button-row">
                      <SheetzButton
                        className="faq-button-two"
                        type="button"
                        label={props.qAndAList.buttons[1].buttonText}
                        onClick={(): void => {
                          if (props.qAndAList.buttons && props.qAndAList.buttons[1].buttonLink) {
                            props.qAndAList.buttons[1].externalLink
                              ? window.open(props.qAndAList.buttons[1].buttonLink, "_blank")
                              : navigate(props.qAndAList.buttons[1].buttonLink);
                          }
                        }}
                      />
                    </div>
                  )}
                  {props.qAndAList.buttons && props.qAndAList.buttons[2] && (
                    <div className="button-row">
                      <SheetzButton
                        className="faq-button-three"
                        type="button"
                        label={props.qAndAList.buttons[2].buttonText}
                        onClick={(): void => {
                          if (props.qAndAList.buttons && props.qAndAList.buttons[2].buttonLink) {
                            props.qAndAList.buttons[2].externalLink
                              ? window.open(props.qAndAList.buttons[2].buttonLink, "_blank")
                              : navigate(props.qAndAList.buttons[2].buttonLink);
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </li>
            </ListContainer>
          </div>
        </Collapse>
      </li>
    </>
  );
};

export default QAndAListItem;
