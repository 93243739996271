import classNames from "classnames";
import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./AgeRestrictedOffers.scss";

import {
  AgeRestrictedOffer,
  AgeRestrictedOffer as AgeRestrictedOfferDTO,
  AgeRestrictedRebate,
  GetUserProfileResponse,
  OfferRequestAction,
  RebateRequestAction,
} from "assets/dtos/anywhere-dto";

import Rebate from "components/Account/Offerz/Rebate";
import RestrictedOffer from "components/Account/Offerz/RestrictedOffer";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import SheetzSelect from "components/misc/form/SheetzSelect/SheetzSelect";
import EmptyPage from "components/misc/indicators/EmptyPage/EmptyPage";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { getUserProfile, isAgeVerified } from "util/Account.util";
import {
  RebateAction,
  getActiveAgeRestrictedOffers,
  getActiveAgeRestrictedRebates,
  updateAllOffersAsRead,
  updateAllRebatesAsRead,
  updateOffer,
  updateRebate,
} from "util/AgeRestricted.util";
import { AppContext } from "util/AppContext.util";
import { IconType } from "util/Icon.util";
import {
  isInNativeMobileContext,
  openAccountSettings,
  showNativeMobileLoadingIndicator,
  showNativeSuccess,
} from "util/MobileApp.util";
import { OfferAction } from "util/Offerz.util";

const AgeRestrictedOffers = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const showLoading = appContext.showLoading;
  const hideLoading = appContext.hideLoading;
  const [category, setCategory] = useState("");
  const [filter, setFilter] = useState<string>();
  const [filteredOffers, setFilteredOffers] = useState<AgeRestrictedOfferDTO[]>();
  const [filteredRebates, setFilteredRebates] = useState<AgeRestrictedRebate[]>();
  const [offers, setOffers] = useState<AgeRestrictedOfferDTO[]>();
  const [nativeError, setNativeError] = useState(true);
  const [rebates, setRebates] = useState<AgeRestrictedRebate[]>();
  const [showOptOutMessage, setShowOptOutMessage] = useState(false);
  const [userProfile, setUserProfile] = useState<GetUserProfileResponse | undefined>();
  const evergageEventElement = useRef<HTMLDivElement>(null);
  const offerFilters = [
    { label: "All 21+ Offerz", value: "ALL" },
    { label: "Beer Rebates", value: "BEER" },
    { label: "CBD", value: "CBD" },
    { label: "Chewing Tobacco", value: "CHEWING_TOBACCO" },
    { label: "Cigarettes", value: "CIGARETTE" },
    { label: "Cigars", value: "CIGAR" },
    { label: "Nicotine Pouch", value: "NICOTINE_POUCH" },
    { label: "Spirits Rebates", value: "SPIRIT" },
    { label: "Vapor", value: "VAPOR" },
    { label: "Wine Rebates", value: "WINE" },
  ];
  const alcoholFilters = ["BEER", "SPIRIT", "WINE"];
  const tobaccoFilters = [
    "CBD",
    "CHEWING_TOBACCO",
    "CIGARETTE",
    "CIGAR",
    "NICOTINE_POUCH",
    "VAPOR",
  ];

  useEffect(() => {
    const view21ItemEvent = new CustomEvent("view21+Offers", {
      detail: {
        eventName: "21+ Offers",
      },
    });
    evergageEventElement.current?.dispatchEvent(view21ItemEvent);
  }, []);

  useEffect(() => {
    if (!userProfile) {
      getUserProfile().then((response) => {
        if (isAgeVerified(response.data.ageVerified)) {
          setUserProfile(response.data);
        } else {
          navigate("/account/verifyAge?destination=/account/ageRestrictedOffers", {
            replace: true,
          });
        }
      });
    }
  }, [navigate, userProfile]);

  useEffect(() => {
    filterByCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, offers, rebates]);

  useEffect(() => {
    if (userProfile !== undefined && isAgeVerified(userProfile?.ageVerified)) {
      showLoading();

      Promise.allSettled([getActiveAgeRestrictedOffers(), getActiveAgeRestrictedRebates()])
        .then((values) => {
          const ageRestrictedOffers = values[0];
          const ageRestrictedRebates = values[1];

          if (ageRestrictedOffers.status === "fulfilled") {
            updateAllOffersAsRead({ action: "READ" });
            formatAgeRestrictedOffers(ageRestrictedOffers.value.data.offers);
          } else {
            setNativeError(true);
          }

          if (ageRestrictedRebates.status === "fulfilled") {
            updateAllRebatesAsRead({ action: "READ" });
            formatAgeRestrictedRebates(ageRestrictedRebates.value.data.rebates);
          } else {
            setNativeError(true);
          }
        })
        .finally(() => {
          hideLoading();
          setFilter("ALL");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  function updateAgeRestrictedOffers(offerId: number, offerAction: OfferRequestAction): void {
    isInNativeMobileContext() ? showNativeMobileLoadingIndicator() : showLoading();

    updateOffer(offerId, { action: offerAction }).then(() => {
      const offerMessage = offerAction === OfferAction.ACCEPT.toString() ? "accepted" : "removed";

      isInNativeMobileContext()
        ? showNativeSuccess("Success", "Successfully " + offerMessage + " offer!")
        : appContext.showToast("Successfully " + offerMessage + " offer!", "", ToastType.success);

      setTimeout(() => {
        getActiveAgeRestrictedOffers()
          .then((response) => formatAgeRestrictedOffers(response.data.offers))
          .finally(() => {
            setFilter("ALL");
            hideLoading();
          });
      }, 1000);
    });
  }

  function updateRebates(rebateId: number, rebateAction: RebateRequestAction): void {
    isInNativeMobileContext() ? showNativeMobileLoadingIndicator() : showLoading();

    updateRebate(rebateId, { action: rebateAction }).then(() => {
      const rebateMessage = rebateAction === RebateAction.CLIP.toString() ? "accepted" : "removed";

      isInNativeMobileContext()
        ? showNativeSuccess("Success", "Successfully " + rebateMessage + " rebate!")
        : appContext.showToast("Successfully " + rebateMessage + " rebate!", "", ToastType.success);

      setTimeout(() => {
        getActiveAgeRestrictedRebates()
          .then((response) => formatAgeRestrictedRebates(response.data.rebates))
          .finally(() => {
            setFilter("ALL");
            hideLoading();
          });
      }, 1000);
    });
  }

  function formatAgeRestrictedOffers(offers: AgeRestrictedOffer[]) {
    const ageRestrictedOffers = offers.filter((offer) => {
      const notDeclinedRedeemed = !offer.offer.isDeclined || !offer.offer.isRedeemed;
      let alcoholTobaccoOptIn = true;

      if (!userProfile?.alcoholOffers && alcoholFilters.includes(offer.category)) {
        alcoholTobaccoOptIn = false;
      } else if (!userProfile?.tobaccoOffers && tobaccoFilters.includes(offer.category)) {
        alcoholTobaccoOptIn = false;
      }

      return notDeclinedRedeemed && alcoholTobaccoOptIn;
    });

    setOffers(ageRestrictedOffers);
  }

  function formatAgeRestrictedRebates(rebates: AgeRestrictedRebate[]): void {
    const ageRestrictedRebates = rebates.filter((rebate) => {
      const notNewClipped = !rebate.isNew || !rebate.isClipped;
      let alcoholTobaccoOptIn = true;

      if (!userProfile?.alcoholOffers && alcoholFilters.includes(rebate.rebateType)) {
        alcoholTobaccoOptIn = false;
      } else if (!userProfile?.tobaccoOffers && tobaccoFilters.includes(rebate.rebateType)) {
        alcoholTobaccoOptIn = false;
      }

      return notNewClipped && alcoholTobaccoOptIn;
    });

    setRebates(ageRestrictedRebates);
  }

  function filterByCategory(): void {
    if (filter) {
      const showOptOutMessage =
        (!userProfile?.alcoholOffers && alcoholFilters.includes(filter)) ||
        (!userProfile?.tobaccoOffers && tobaccoFilters.includes(filter));

      setShowOptOutMessage(showOptOutMessage);

      const category = offerFilters.find((offerFilter) => offerFilter.value === filter);

      if (category) {
        const label = category?.value !== "ALL" ? category?.label : "All 21+ Offers";
        setCategory(label);
      }

      const offersFiltered =
        filter === "ALL" ? offers : offers?.filter((offer) => offer.category === filter);
      const rebatesFiltered =
        filter === "ALL" ? rebates : rebates?.filter((rebate) => rebate.rebateType === filter);

      setFilteredOffers(offersFiltered);
      setFilteredRebates(rebatesFiltered);
    }
  }

  function navigateToAccountSettings(): void {
    isInNativeMobileContext()
      ? openAccountSettings()
      : navigate("/account/settings/preferences/twentyOnePlus");
  }

  const optedOutEmptyContainerClasses = classNames("opted-out-empty-container", {
    "mobile-margin": isInNativeMobileContext(),
  });

  const filterForm = (
    <div className="filter-container">
      <br></br>
      <div className="filtername-label"> Filter By: </div>
      <Formik
        initialValues={{ offerFilter: "ALL" }}
        onSubmit={(): void => {
          return;
        }}
      >
        <div className="sheetz-input-filter">
          <SheetzSelect
            name="offersFilter"
            placeholder="All 21+ Offerz"
            buttonStyle
            options={offerFilters}
            onChangeEvent={(event): void => {
              setFilter(event.target.value);
            }}
          />
        </div>
      </Formik>
      <br></br>
    </div>
  );

  if (filteredOffers && filteredRebates) {
    if ((!userProfile?.alcoholOffers && !userProfile?.tobaccoOffers) || showOptOutMessage) {
      return (
        <div className="age-restricted-offers-container">
          {filterForm}
          <div className={optedOutEmptyContainerClasses}>
            <EmptyPage title="Psst! 21+ Offerz are hiding." icon={IconType.offer}>
              <p>
                Opt-in for 21+ Offerz by adjusting your{" "}
                <SheetzTextButton
                  className="link"
                  textSize="large-text"
                  underline
                  label="Account Settings"
                  onClick={navigateToAccountSettings}
                />
                .
              </p>
            </EmptyPage>
          </div>
        </div>
      );
    }

    if ((offers && offers.length > 0) || (rebates && rebates.length > 0)) {
      return (
        <div className="age-restricted-offers-container evergage-event" ref={evergageEventElement}>
          {filterForm}

          {(filteredOffers && filteredOffers.length > 0) ||
          (filteredRebates && filteredRebates.length > 0) ? (
            <div className="my-offers-container">
              <div className="offername-label">All 21+ Offers:</div>

              {filteredRebates?.map((rebate) => (
                <Rebate
                  key={rebate.rebateId}
                  ageRestrictedRebate={rebate}
                  onUpdateRebates={updateRebates}
                />
              ))}

              {filteredOffers?.map((offer) => (
                <RestrictedOffer
                  key={offer.offer.offerId}
                  ageRestrictedOffer={offer}
                  onUpdateAgeRestrictedOffer={updateAgeRestrictedOffers}
                />
              ))}
            </div>
          ) : (
            <div className="opted-out-empty-container">
              <EmptyPage
                title={`No results for ${category ?? "this category"}, please try another!`}
                icon={IconType.offer}
              />
            </div>
          )}
        </div>
      );
    } else if ((!offers && !rebates && !isInNativeMobileContext()) || nativeError) {
      return (
        <div className="age-restricted-offers-container">
          {filterForm}

          <div className="opted-out-empty-container">
            <EmptyPage
              title="You have no available Offerz. Please check back another time"
              icon={IconType.offer}
            />
          </div>
        </div>
      );
    }
  }

  return null;
};

export default AgeRestrictedOffers;
