import { Formik, FormikErrors, FormikProps } from "formik";
import { DateTime } from "luxon";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import TagManager from "react-gtm-module";
import { PatternFormat } from "react-number-format";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import "./Register.scss";

import { ValidateDisplayNameRequest } from "assets/dtos/anywhere-dto";

import EmailAlreadyInUseActionSheet from "components/Account/ErrorActionSheets/EmailAlreadyInUseActionSheet";
import ProfanityFilterActionSheet from "components/Account/ErrorActionSheets/ProfanityFilterActionSheet";
import { AuthBannerType } from "components/Auth/Auth";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import PasswordChecklist, {
  processValidationErrors,
} from "components/misc/form/PasswordChecklist/PasswordChecklist";
import SheetzInput from "components/misc/form/SheetzInput/SheetzInput";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import {
  checkEmailAvailability,
  createAccount,
  setAuthorization,
  validateDisplayName,
} from "util/Authentication.util";
import { setUserId } from "util/Storage.util";
import {
  ageBirthDateRegistration,
  emailValidation,
  nameValidation,
  passwordValidation,
  phoneValidation,
  sheetzCardNameValidation,
  sheetzCardValidation,
  zipValidation,
} from "util/Validation.util";

export interface RegistrationFormValues {
  emailAddress: string;
  password: string;
  firstName: string;
  lastName: string;
  dob: string;
  zipCode: string;
  phoneNumber: string;
}

export interface CardFormValues {
  cardNumber?: string;
  pin?: string;
  cardName?: string;
}

enum emailAvailability {
  AVAILABLE = "Available",
}

interface RegisterProps {
  setBanner: Dispatch<SetStateAction<AuthBannerType>>;
  setAuthHeader: Dispatch<SetStateAction<string>>;
}

const Register = ({ setBanner, setAuthHeader }: RegisterProps) => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const initialRegistrationValues: RegistrationFormValues = {
    emailAddress: "",
    password: "",
    firstName: "",
    lastName: "",
    dob: "",
    zipCode: "",
    phoneNumber: "",
  };

  const initialCardValues: CardFormValues = {
    cardNumber: undefined,
    pin: undefined,
    cardName: undefined,
  };

  const [registrationValues, setRegistrationValues] =
    useState<RegistrationFormValues>(initialRegistrationValues);

  const [cardValues, setCardValues] = useState<CardFormValues>(initialCardValues);
  const [showSheetzCard, setShowSheetzCard] = useState<boolean>(false);
  const [showEmailAlreadyInUseActionSheet, setShowEmailAlreadyInUseActionSheet] = useState(false);
  const [showProfanityFilterActionSheet, setShowProfanityFilterActionSheet] = useState(false);
  const minimumAge: Date = new Date();
  minimumAge.setFullYear(minimumAge.getFullYear() - 13);

  const submitCreateAccount = (newCard: boolean, card: CardFormValues): void => {
    setCardValues(card);

    const registrationResponseValues = {
      emailAddress: registrationValues.emailAddress,
      password: registrationValues.password,
      firstName: registrationValues.firstName,
      lastName: registrationValues.lastName,
      dob: DateTime.fromFormat(registrationValues.dob, "MMddyyyy").toFormat("yyyy-MM-dd"),
      zipCode: registrationValues.zipCode,
      phoneNumber: registrationValues.phoneNumber,
    };
    const createAccountValues = { ...registrationResponseValues, ...card };

    appContext.hideToast();
    appContext.showLoading();

    setTimeout(() => {
      createAccount(createAccountValues)
        .then((response) => {
          setAuthorization(response.data.authorizationToken);
          setUserId(response.data.userId.toString());

          const tagManagerArgs = {
            dataLayer: {
              gtmevent: "newaccountcomplete",
            },
            dataLayerName: "PageDataLayer",
          };

          TagManager.dataLayer(tagManagerArgs);

          appContext.hideToast();
          navigate("/order");

          appContext.showToast(
            "Account Created!",
            "Your card has been added to your account",
            ToastType.success
          );
        })
        .finally(() => {
          appContext.hideLoading();
        });
    }, 3000);
  };

  const switchPage = (values: RegistrationFormValues): void => {
    setRegistrationValues(values);
    checkEmailAvailability(values.emailAddress).then((response) => {
      if (response.data.availability === emailAvailability.AVAILABLE) {
        const validateDisplayNameRequest: ValidateDisplayNameRequest = {
          firstName: values.firstName,
          lastName: values.lastName,
        };

        validateDisplayName(validateDisplayNameRequest).then((response) => {
          if (response.data.status === "VALID") {
            appContext.hideToast();
            setAuthHeader("Add Loyalty Card");
            setBanner(AuthBannerType.msc);
            setShowSheetzCard(true);
          } else {
            setShowProfanityFilterActionSheet(true);
          }
        });
      } else {
        setShowEmailAlreadyInUseActionSheet(true);
      }
    });
  };

  const registrationValidationSchema = Yup.object({
    emailAddress: emailValidation,
    password: passwordValidation,
    firstName: nameValidation,
    lastName: nameValidation,
    dob: ageBirthDateRegistration,
    zipCode: zipValidation,
    phoneNumber: phoneValidation.required("Required"),
  });

  const validateRegistrationSchema = (
    values: RegistrationFormValues
  ): Promise<RegistrationFormValues | FormikErrors<RegistrationFormValues>> => {
    const emptyError: FormikErrors<RegistrationFormValues> = {};
    return registrationValidationSchema
      .validate(values, { abortEarly: false })
      .then(() => {
        return emptyError;
      })
      .catch((errors) => {
        return processValidationErrors(errors);
      });
  };

  const RegisterForm = () => {
    return (
      <div className="register-form">
        <Formik
          initialValues={registrationValues}
          onSubmit={(values): void => {
            switchPage(values);
          }}
          validate={(
            values: RegistrationFormValues
          ): Promise<RegistrationFormValues | FormikErrors<RegistrationFormValues>> =>
            validateRegistrationSchema(values)
          }
        >
          {(props: FormikProps<RegistrationFormValues>) => (
            <form onSubmit={props.handleSubmit}>
              <SheetzInput
                type="email"
                name="emailAddress"
                placeholder="Email"
                label="Email"
                required
              />
              <SheetzInput
                type="password"
                name="password"
                placeholder="password"
                label="Password"
                showError={false}
                required
              />
              <PasswordChecklist
                trackChanges={props.values.password.length > 0}
                passwordError={props.errors.password || ""}
                validateCurrentPassword={false}
              />
              <SheetzInput
                type="text"
                name="firstName"
                placeholder="First Name"
                label="First Name"
                required
              />
              <SheetzInput
                type="text"
                name="lastName"
                placeholder="Last Name"
                label="Last Name"
                required
              />
              <PatternFormat
                format="##/##/####"
                name="dob"
                placeholder="Birthdate (mm/dd/yyyy)"
                label="Birthdate (mm/dd/yyyy)"
                customInput={SheetzInput}
                value={props.values.dob}
                onValueChange={(value): void => props.setFieldValue("dob", value.value)}
                valueIsNumericString
              />
              <SheetzInput
                type={"tel"}
                name={"phoneNumber"}
                placeholder={"Phone Number"}
                label={"Phone Number"}
              />
              <SheetzInput
                type="text"
                name="zipCode"
                placeholder="Zip Code"
                label="Zip Code"
                required
              />

              <div className="agreement">
                By creating an account you agree to receive email updates & promotional material,
                and accept our terms of use & privacy policy
              </div>

              <SheetzButton className="submit-button" type="submit" label="Next" />
            </form>
          )}
        </Formik>
        <div className="forgot-password">
          <Link to="/auth/login">I have a Sheetz account</Link>
        </div>
      </div>
    );
  };

  const cardValidationSchema = Yup.object({
    cardNumber: sheetzCardValidation.required("Required"),
    cardName: sheetzCardNameValidation,
  });

  const SheetzCardForm = () => {
    return (
      <div className="register-form">
        <Formik
          initialValues={cardValues}
          onSubmit={(values): void => {
            submitCreateAccount(false, values);
          }}
          validationSchema={cardValidationSchema}
        >
          {(props: FormikProps<CardFormValues>) => (
            <form onSubmit={props.handleSubmit}>
              <SheetzInput
                type="text"
                name="cardNumber"
                placeholder="Card Number"
                label="Card Number"
              />
              <SheetzInput
                type="text"
                name="cardName"
                placeholder="Name it! (Optional)"
                label="Name it! (Optional)"
              />
              <SheetzButton className="submit-button" type="submit" label="Add Card & Continue" />
              <SheetzButton
                className="submit-button"
                buttonColor={ButtonColor.darkGray}
                transparentButton
                type="button"
                label="I need a MySheetz Card"
                onClick={(): void => {
                  submitCreateAccount(true, {
                    cardNumber: "",
                    pin: "",
                    cardName: "",
                  });
                }}
              />
            </form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <>
      {showSheetzCard ? <SheetzCardForm /> : <RegisterForm />}

      {showEmailAlreadyInUseActionSheet && (
        <EmailAlreadyInUseActionSheet
          message="This email cannot be used. Please try another or log in."
          setShowEmailAlreadyInUseActionSheet={setShowEmailAlreadyInUseActionSheet}
          showEmailAlreadyInUseActionSheet={showEmailAlreadyInUseActionSheet}
          showLoginButton
        />
      )}

      {showProfanityFilterActionSheet && (
        <ProfanityFilterActionSheet
          setShowProfanityFilterActionSheet={setShowProfanityFilterActionSheet}
          showProfanityFilterActionSheet={showProfanityFilterActionSheet}
        />
      )}
    </>
  );
};

export default Register;
