import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";

import { Condiment, RetailItem, RetailModifiedItem, SwitchDTO } from "assets/dtos/anywhere-dto";

import { ItemCustomization, isPortionedCondiment } from "util/Customization.util";
import { CustomizedItem } from "util/Order.util";

export type Tags = string[];

/**
 * Given a set of tags (e.g. "6 inch") and a list of RetailModifiedItems (e.g. the 6 inch, 12 inch, and Sandwich
 * versions of the Turkey Sub, return the one that matches the tags.
 */
export function deriveSize(
  tags: Tags,
  itemCustomization: ItemCustomization
): RetailModifiedItem | undefined {
  if (!tags || !tags.length) {
    throw new SheetzError("Cannot derive size - tags are null or empty.", {
      userReadableMessage: "Looks like we've hit a snag. Please try again.",
      primaryButton: SheetzErrorButtonType.TRY_AGAIN,
    });
  }

  const rmi = itemCustomization.retailItem.retailModifiedItems.find((rmi) => {
    return intersection(tags, rmi.tags).length === tags.length;
  });

  return rmi;
}

/**
 * Given a set of RMIs from a switch RI and a list of existing tags that currently make up the
 * item, return the switch RMI that matches the existing tags.
 */
export function deriveSwitchSize(
  switchRMIs: RetailModifiedItem[],
  existingTags: string[]
): RetailModifiedItem | undefined {
  if (!switchRMIs || !switchRMIs.length) {
    throw new SheetzError("Cannot derive size - switchRMIs are null or empty.", {
      userReadableMessage: "Looks like we've hit a snag. Please try again.",
      primaryButton: SheetzErrorButtonType.TRY_AGAIN,
    });
  }

  const rmi = switchRMIs.find((rmi) => {
    return intersection(existingTags, rmi.tags).length === rmi.tags?.length;
  });

  if (!rmi) {
    throw new SheetzError("Cannot derive switch size - no size derived.", {
      userReadableMessage: "Looks like we've hit a snag. Please try again.",
      primaryButton: SheetzErrorButtonType.TRY_AGAIN,
    });
  }

  return rmi;
}

/**
 * Just an overload, essentially, which derives condiments based on tags, and then ensures that only one was found.
 */
export function deriveCondiment(
  matchTags: string[],
  existingTags: string[],
  itemCustomization: ItemCustomization,
  okIfEmpty: boolean
): Condiment {
  const filteredCondiments = deriveCondiments(
    matchTags,
    existingTags,
    itemCustomization,
    okIfEmpty
  );

  if (filteredCondiments.length > 1) {
    // TODO: Possibly throw error instead that is caught and logged to splunk.
    console.warn(`Derived more than one condiment given matchTags='${matchTags}', existingTags='${existingTags}', 
    and itemCustomizationId='${itemCustomization.itemCustomizationId}'`);
  }

  return filteredCondiments[0];
}

/**
 * Given the tags that were on the selector and given what the customer has customized so far,
 * find the condiments that match. For example, if the selector specifies "Brew" and "Decaf", there would probably
 * be three condiments that result (e.g. one for "Small", one for "Medium", and one for "Large"). Of these
 * condiments, filter out all except the ones where the correlates satisfy and there are no conflicts. For example,
 * if the customer was currently customizing a "Large", then it should filter find the "Large" condiment that is
 * "Brew" and "Decaf".
 */
export function deriveCondiments(
  matchTags: string[],
  existingTags: string[],
  itemCustomization: ItemCustomization,
  okIfEmpty: boolean
): Condiment[] {
  const matchedCondiments = filterByTags(matchTags, itemCustomization.condiments);
  const matchedCorrelateCondiments = filterByCorrelates(
    Array.from(existingTags),
    matchedCondiments
  );
  const filteredCondiments = filterByConflicts(
    Array.from(existingTags),
    matchedCorrelateCondiments
  );

  if (!filteredCondiments.length && !okIfEmpty) {
    // TODO: Possibly throw error instead that is caught and logged to splunk.
    console.warn(`Could not derive condiment given matchTags='${matchTags}', existingTags='${existingTags}', 
    and itemCustomizationId='${itemCustomization.itemCustomizationId}'`);
  }

  /**
   * Filter out all the unavailable condiments (either because of recall or just not available for a given store).
   * Also filter out any condiment whose retailModifiedItem doesn't have receipt text. If there is no receipt
   * text, then there is no way for the user to distinguish what the condiment is.
   * Do this here in the UI so that we can distinguish between the case of whether a condiment can't be derived due
   * to authoring or a valid reason.
   */
  return filteredCondiments.filter(
    (condiment) => condiment.isAvailable && condiment.retailModifiedItem?.receiptText
  );
}

/**
 * From an array of tags (e.g. "ultimate"), find correct switch-to retail item (e.g. "Ultimate Turkey Sub")
 * given the list of switches from the original Retail Item (e.g. "Turkey Sub").
 */
export function deriveSwitch(
  switchTags: string[],
  itemCustomization: ItemCustomization,
  switches: SwitchDTO[]
): RetailItem | null {
  /**
   * When matching tags the second argument is the MATCH TAGS (the list of source tags must contain ALL match tags) -
   * for switching RIs, this means the match tags come from the switch RI you are trying to derive.
   *
   * For deriving multi-option switches, the switch tag on the selector is not enough as the source.
   * All tags need to be part of a source - otherwise a double switch cannot be made (ITEM Switch, followed by a Multi-option switch).
   *
   * Note: An RI cannot have itself as one the of RIs in the "switches" field (list of RIs you can switch to from this RI). Otherwise it will match with itself.
   */

  // Find the switch whose tags are ALL contained within the passed in tags.
  const switchRi = switches.find((switchRi) => tagsMatch(switchTags, switchRi.tags));

  if (!switchRi) {
    // TODO: Possibly throw error instead that is caught and logged to splunk.
    console.warn(`Could not derive switch given switchTags='${switchTags}', 
    and itemCustomizationId='${itemCustomization.itemCustomizationId}'`);
    return null;
  }

  // Sometimes due to item availabiltiy and recalls, an IC with switch selectors will not have any `switchRetailItems`. We guard against that.
  if (itemCustomization.switchRetailItems === undefined) {
    return null;
  }

  const ri = itemCustomization.switchRetailItems.find(
    (switchRI) => switchRI.retailItemId === switchRi.retailItemId
  );

  if (!ri) {
    // TODO: Possibly throw error instead that is caught and logged to splunk.
    console.warn(`Could not find switch RI given switchTags='${switchTags}', retailItemId='${switchRi.retailItemId}',
    and itemCustomizationId='${itemCustomization.itemCustomizationId}'`);
    return null;
  }

  return ri;
}

/**
 * Produce a master list of all tags that make up the RI, RMI, and all Condiments.
 * This is used to help derive condiments with correlates and conflicts.
 */
export function getAllTags(customizedItem: CustomizedItem): string[] {
  // This should never happen, but it makes the compiler happy.
  if (!customizedItem.retailItem) {
    return [];
  }

  const condimentTags = customizedItem.condiments
    .map((condiment) => {
      if (isPortionedCondiment(condiment)) {
        return condiment.condiment.tags;
      } else {
        return condiment.tags;
      }
    })
    .flat();

  return customizedItem.retailItem.tags
    .concat(
      customizedItem.retailModifiedItem?.tags ? customizedItem.retailModifiedItem.tags : [],
      condimentTags
    )
    .filter(onlyUnique);
}

/**
 * Find the condiments which match *all* the tags passed in. So if "Brew" and "Decaf" were passed in, then it would
 * look for a condiment within the condiment group that has both of those tags. For that case, there should probably
 * be three results, one for "Small", one for "Medium", and one for "Large".
 */
function filterByTags(tags: string[], condiments: Condiment[]): Condiment[] {
  return condiments.filter((condiment) => tagsMatch(condiment.tags, tags));
}

/**
 * Loop through those condiments that remain, and pick out only those ones that correctly "correlate" with the
 * item/condiments that has been customized to this point. For example, if a "6 inch" sub is being built, and the
 * option is "Cheese", "American", return only those cheeses that go with a "6 inch", not the cheeses that go with
 * the "12 inch".
 */
function filterByCorrelates(existingTags: string[], condiments: Condiment[]): Condiment[] {
  return condiments.filter((condiment) => {
    if (!condiment.correlates || !condiment.correlates.length) {
      return true;
    }

    if (tagsMatch(existingTags, condiment.correlates)) {
      return true;
    }

    return false;
  });
}

/**
 * Loop through all the condiments that have matched so far and could be returned, and then eliminate any that have
 * a conflict. For example, if there's a condiment "Add Bacon", this condiment could have a conflict with any
 * "Bacon" RI, so if this was a "Bacon Sub" the "Add Bacon" would not be added, but if it was a "Turkey Sub" it
 * would be added.
 */
function filterByConflicts(existingTags: string[], condiments: Condiment[]): Condiment[] {
  return condiments.filter((condiment) => {
    if (!condiment.conflicts || !condiment.conflicts.length) {
      return true;
    }

    if (!tagsMatch(existingTags, condiment.conflicts)) {
      return true;
    }

    return false;
  });
}

/**
 * Test to see whether the given retail modified item source tags have ALL of the match tags. For example, if the match
 * tags are "Brew" and "Decaf", then a condiment RMI with only "Brew" would not be a match. But if the match tag was
 * "Brew", and the condiment RMI had "Brew" and "Decaf", it would be a match. Note that individual tags can be
 * "or"ed together using the ",". A match tag of "Regular,Decaf" would match to a RI/RMI with either "Regular" or
 * "Decaf".
 */
export function tagsMatch(sourceTags: string[], matchTags: string[]): boolean {
  /**
   * If the tags that we're trying to match with are empty/null, then there's an implicit match (i.e. show everything).
   */
  if (!matchTags.length) {
    return true;
  }

  /**
   * If there are no source tags, but there are match tags, then there's obviously not a match.
   */
  if (!sourceTags.length) {
    return false;
  }

  /**
   * Using an old-school loop, because we want to exit out of the function if something doesn't match.
   */
  for (let i = 0; i < matchTags.length; i++) {
    const matchTag = matchTags[i];

    /**
     * If there's a " or ", that means there's an "or" condition in play (e.g. "Sandwich,6 inch" and to check to
     * see whether one of the match tags matches the source tag.
     */
    if (matchTag.indexOf(" or ")) {
      if (!atLeastOneTagMatches(sourceTags, matchTag.split(" or "))) {
        return false;
      }
    } else {
      /**
       * If there's no "or" in play, then just check to see if the match tag is not in the source, and if it's not
       * a complete match, just return false.
       */
      if (!sourceTags.includes(matchTag)) {
        return false;
      }
    }
  }

  return true;
}

/**
 * Find whether one of the match tags matches the tags in the source.
 */
function atLeastOneTagMatches(sourceTags: string[], matchTags: string[]): boolean {
  return !!intersection(sourceTags, matchTags).length;
}

/**
 * Ensure that all of the match tags are included in the source tags array.
 * Using an intersection to get the tags in common, and then ensure that it has the same number of tags as the
 * match tags array accomplishes this.
 */
// function allTagsMatch(sourceTags: string[], matchTags: string[]): boolean {
//   return intersection(sourceTags, matchTags).length === matchTags.length;
// }

/**
 * Return an array of values that both arguments share.
 */
function intersection<T>(array1?: T[], array2?: T[]): T[] {
  // If either argument is undefined, then they can't intersect.
  if (!array1 || !array2) {
    return [];
  }
  const arrays = [array1, array2];
  return arrays.reduce((a, b) => a.filter((c) => b.includes(c)));
}

/**
 * A function that can be used as the callback for a call to filter.
 * This will remove duplicate values in the array.
 */
function onlyUnique<T>(value: T, index: number, self: T[]): boolean {
  return self.indexOf(value) === index;
}
