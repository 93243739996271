import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AccompanyingItemUpsell,
  ItemEvent,
  OrderType,
  PickupLocation,
} from "assets/dtos/anywhere-dto";

import CustomizationOption from "components/Order/ItemCustomization/CustomizationOption/CustomizationOption";
import { OrderItemActions } from "components/Order/OrderItemReducer";
import { OrderSessionAction } from "components/Order/OrderSessionReducer";
import ActionSheetGrid from "components/misc/view/ActionSheet/ActionSheetGrid/ActionSheetGrid";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import {
  ItemCustomization,
  buildNonCustomizableItem,
  findItemSwitchSelector,
  findSizeSelector,
  isItemCustomizable,
} from "util/Customization.util";
import { aiuServedEvent, generateUuid } from "util/Metrics.util";
import { OrderItemState, OrderSession, getItemCustomization } from "util/Order.util";

interface AIUSelectorProps {
  AIUs: AccompanyingItemUpsell[];
  dispatch: React.Dispatch<OrderSessionAction>;
  dispatchOrderItem: React.Dispatch<OrderItemActions>;
  evergageAddToBagEvent: (itemRmiId?: number, price?: number) => void;
  orderItemState: OrderItemState;
  orderSession: OrderSession;
  resetOrderItemState: () => void;
}

const AIUSelector = (props: AIUSelectorProps): ReactElement => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const aiuSelectors = props.AIUs.map((AIU) => {
    return (
      <CustomizationOption
        type="AIU"
        key={AIU.retailModifiedItem.retailModifiedItemId}
        aiu={AIU}
        onAIUOptionSelected={addToBag}
      />
    );
  });
  const orderType: OrderType = props.orderSession.delivery ? "DELIVERY" : "PICKUP";
  const pickupLocation: PickupLocation = props.orderSession.pickupLocation ?? "IN_STORE";
  const storeNumber = props.orderSession.store?.storeNumber ?? props.orderSession.storeNumber;

  const [aiuServedEventId, setAiuServedEventId] = useState<string>();

  // Send AIU Served Event
  useEffect(() => {
    const aiuServedEventId = generateUuid();

    setAiuServedEventId(aiuServedEventId);

    aiuServedEvent(props.orderSession, props.AIUs, aiuServedEventId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function addToBag(aiu: AccompanyingItemUpsell): void {
    if (storeNumber === undefined || !props.orderSession.dayPart || !aiu.itemCustomizationId) {
      props.resetOrderItemState();
      throw new SheetzError("Missing required variables to check AIU", {
        userReadableMessage: "Unable to add your item. Please try again.",
        primaryButton: SheetzErrorButtonType.TRY_AGAIN,
      });
    }

    let aiuItemResponse: ItemCustomization | undefined;

    try {
      getItemCustomization(
        aiu.itemCustomizationId,
        storeNumber,
        props.orderSession.dayPart,
        orderType,
        pickupLocation
      )
        .then((response) => {
          aiuItemResponse = response.data;
        })
        .finally(() => {
          if (aiuItemResponse) {
            // AIU is unavailable, stop addToBag
            if (
              aiuItemResponse.retailItem.retailModifiedItems === undefined ||
              aiuItemResponse.retailItem.retailModifiedItems.length === 0
            ) {
              appContext.showToast(
                "Oh Sheetz!",
                "Your goodie is currently unavailable, please try another item.",
                ToastType.error
              );

              props.resetOrderItemState();

              return;
            }

            // Create AIU event
            createAIUEvent(aiuItemResponse);

            // Handle item switch and size selectors
            const sizeSelector = findSizeSelector(aiuItemResponse.template);
            const itemSwitchSelector = findItemSwitchSelector(aiuItemResponse.template);

            if (
              (sizeSelector && sizeSelector.displayAsActionSheet) ||
              (itemSwitchSelector && itemSwitchSelector.displayAsActionSheet)
            ) {
              props.dispatchOrderItem({
                type: "SET_ITEM_SIZE_INITIAL_STATE",
                payload: {
                  chosenItem: aiuItemResponse,
                  menuCategory: props.orderItemState.menuCategory,
                },
              });

              return;
            } else {
              props.dispatchOrderItem({
                type: "SHOW_AIU_ACTION_SHEET",
                payload: false,
              });
            }

            // AIU is customizable
            if (isItemCustomizable(aiuItemResponse)) {
              // Navigate to ItemCustomization component to customize AIU
              navigate("/order/customize/" + aiu.itemCustomizationId, {
                state: {
                  previousCategory: props.orderItemState.menuCategory ?? "",
                  event: props.orderItemState.itemEvent,
                },
              });
            } else {
              // Add AUI to bag
              props.dispatch({
                type: "ADD_ITEM_TO_BAG",
                payload: buildNonCustomizableItem(aiuItemResponse, props.orderItemState.itemEvent),
              });

              // Dispatch "addToBag" event
              props.evergageAddToBagEvent(
                aiuItemResponse.retailItem.retailModifiedItems[0].retailModifiedItemId,
                aiuItemResponse.retailItem.retailModifiedItems[0].price
              );

              if (props.orderItemState.navigate) {
                navigate(props.orderItemState.navigate, { replace: true });
              }

              props.resetOrderItemState();

              setTimeout(() => appContext.showAddToBagPopup(false), 600);
            }
          } else {
            appContext.showToast(
              "We couldn't add your goodie",
              "Your item/combo was added to the bag, though!",
              ToastType.error
            );

            props.resetOrderItemState();
          }
        });
    } catch (e) {
      // We couldn't retrieve the AIU item customizations
      appContext.showToast(
        "We couldn't add your goodie",
        "Your item was added to the bag, though!",
        ToastType.error
      );

      props.resetOrderItemState();
    }
  }

  function createAIUEvent(aiu: ItemCustomization): void {
    let aiuPosition = 0;

    // Find the position of the AIU based on its index in the array and also keep track of that AIU
    const selectedAIU = props.AIUs.find((currentAIU, index): AccompanyingItemUpsell | void => {
      if (
        aiu.itemCustomizationId === aiu?.itemCustomizationId &&
        aiu.retailItem &&
        aiu.retailItem.retailModifiedItems.find(
          (rmi) => currentAIU.retailModifiedItem.retailModifiedItemId === rmi.retailModifiedItemId
        )
      ) {
        aiuPosition = index;

        return currentAIU;
      }
      return undefined;
    });

    // Create the event that will be passed on the AIU through checkout
    const itemEvent: ItemEvent = {
      source: "AIU",
      accompanyingItemUpsellOriginalItemCustomizationId: props.orderItemState.itemCustomizationId,
      accompanyingItemUpsellPosition: aiuPosition,
      accompanyingItemUpsellServedEventId: aiuServedEventId,
      accompanyingItemUpsellBundleId: selectedAIU?.aiuBundleId,
      accompanyingItemUpsellBundleName: selectedAIU?.aiuBundleName,
    };

    props.dispatchOrderItem({
      type: "SET_ITEM_EVENT",
      payload: itemEvent,
    });
  }

  return <ActionSheetGrid>{aiuSelectors}</ActionSheetGrid>;
};

export default AIUSelector;
