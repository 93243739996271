import React from "react";

import { Condiment, Option, Selector } from "assets/dtos/anywhere-dto";

import SelectorContainer from "components/Order/ItemCustomization/SelectorContainer/SelectorContainer";
import SingleSelectCondimentSelector from "components/Order/ItemCustomization/Selectors/SingleSelectCondimentSelector/SingleSelectCondimentSelector";
import { OrderSessionAction } from "components/Order/OrderSessionReducer";

import {
  ItemCustomization,
  SingleSelectOption,
  handleDefaultOption,
  handleInlineSubSelectorNoOption,
  handleTagAlongTags,
  isCondimentInList,
} from "util/Customization.util";
import { CustomizedItem } from "util/Order.util";
import { deriveCondiment, deriveCondiments, getAllTags } from "util/Tag.util";

/**
 * Iterate over all options and derive the associated condiment.
 * We pass `true` for the `okIfEmpty` flag since we are now allowing explicit options to not
 * derive a condiment. For instance, in the Bread selector, the "croissant" option will not derive
 * anything when the size is half or whole sub, since there is no croissant sub roll.
 */
function deriveSingleSelectCondiments(
  selector: Selector,
  existingTags: string[],
  itemCustomization: ItemCustomization
): SingleSelectOption[] {
  if (selector.tags) {
    const condiments = deriveCondiments(
      selector.tags,
      existingTags,
      itemCustomization,
      selector.okIfEmpty ?? false
    );

    const options: SingleSelectOption[] = condiments.map((condiment) => {
      return {
        option: {
          text: condiment.retailModifiedItem.receiptText,
          tags: selector.tags,
          sizeTags: [],
          switchTags: [],
          image: condiment.image,
        },
        condiment: condiment,
      };
    });

    if (selector.noOption) {
      const noOption = createNoOption(selector.noOption, existingTags, itemCustomization);
      if (noOption) {
        options.push(noOption);
      }
    }
    return options;
  }

  const options: (SingleSelectOption | null)[] = !selector.options
    ? []
    : selector.options?.map((option) => {
        const derivedCondiment = deriveCondiment(
          option.tags ?? [],
          existingTags,
          itemCustomization,
          true
        );

        if (!derivedCondiment) {
          return null;
        }

        return { option: option, condiment: derivedCondiment };
      });

  if (selector.noOption) {
    const noOption = createNoOption(selector.noOption, existingTags, itemCustomization);
    if (noOption) {
      options.push(noOption);
    }
  }

  // Filter out any elements that are null and log a warning if there aren't any options left.
  const filteredOptions = options.filter((option) => option !== null) as SingleSelectOption[];
  if (!filteredOptions.length) {
    // TODO: Possibly throw error instead that is caught and logged to splunk.
    console.warn(`No options derived a condiment for single select selector with name='${selector.text}'
    and itemCustomizationId='${itemCustomization.itemCustomizationId}'`);
  }

  return filteredOptions;
}

function createNoOption(
  noOption: Option,
  existingTags: string[],
  itemCustomization: ItemCustomization
): SingleSelectOption | undefined {
  if (!noOption.tags) {
    return { option: noOption, isNoOption: true };
  }
  const noOptionCondiment = deriveCondiment(noOption.tags, existingTags, itemCustomization, false);
  const singleSelectOption = { option: noOption, condiment: noOptionCondiment };
  return singleSelectOption;
}

export function processSingleSelectCondimentSelector(
  selector: Selector,
  selectorState: SingleSelectOption | undefined,
  item: CustomizedItem,
  itemCustomization: ItemCustomization,
  dispatch: React.Dispatch<OrderSessionAction>,
  index: number
): JSX.Element | null {
  const existingTags = getAllTags(item);
  const options = deriveSingleSelectCondiments(selector, existingTags, itemCustomization);
  if (!options.length) {
    return null;
  }

  let selectedOption = selectorState;
  if (
    selectedOption &&
    !selectedOption.isNoOption &&
    !!selectedOption.condiment &&
    !isCondimentInList(
      options
        .filter((option) => !!option.condiment)
        .map((option) => option.condiment) as Condiment[],
      selectedOption.condiment
    )
  ) {
    // TODO: Anything filtered out should be removed via a state update. Need an item customization where a choice generates a conflict on a selector below it.
    selectedOption = undefined;
  } else if (selectedOption) {
    if (!selectedOption.isNoOption && !!selectedOption.condiment) {
      // Add the condiment associated with the selected option.
      item.condiments.push(selectedOption.condiment);
    }

    // If the selected option has a selected inline sub-option selected, then derive that condiment and add it to the item.
    if (selectedOption.selectedInlineSubOption?.tags) {
      const inlineSubCondiment = deriveCondiment(
        selectedOption.selectedInlineSubOption.tags,
        existingTags,
        itemCustomization,
        false
      );
      if (inlineSubCondiment) {
        item.condiments.push(inlineSubCondiment);
      }
    }

    // If there is no selected inline sub option, add the no-option condiment, assuming one exists
    handleInlineSubSelectorNoOption(selectedOption, existingTags, itemCustomization, item);

    // If the selected option has tagAlongTags, then derive that condiment as well and add it to the item.
    handleTagAlongTags(selectedOption.option, itemCustomization, item);
  } else {
    handleDefaultOption(selector, existingTags, itemCustomization, item);
  }

  const optionDeSelected = (singleSelectOption: SingleSelectOption): void => {
    dispatch({ type: "REMOVE_CUSTOMIZATION_SELECTION", payload: selector.text ?? "" });
  };

  const optionSelected = (singleSelectOption: SingleSelectOption): void => {
    dispatch({
      type: "SET_ITEM_CUSTOMIZATIONS",
      payload: [selector.text ?? "", singleSelectOption],
    });
  };

  return (
    <SelectorContainer key={index} selector={selector}>
      <SingleSelectCondimentSelector
        selector={selector}
        options={options}
        selectedOption={selectedOption}
        onOptionSelected={optionSelected}
        onOptionDeSelected={optionDeSelected}
      />
    </SelectorContainer>
  );
}
