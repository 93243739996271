import React, { ReactElement, useContext, useState } from "react";
import { NumericFormat } from "react-number-format";

import "./GetRewardz.scss";

import { GetUserResponse, TierReward } from "assets/dtos/anywhere-dto";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton, {
  TextButtonColor,
} from "components/misc/button/SheetzTextButton/SheetzTextButton";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";
import { purchaseReward } from "util/Rewardz.util";

interface RewardsProps {
  rewards?: TierReward[];
  userData?: GetUserResponse;
  reloadUserData: () => void;
}

interface RewardItemProps {
  reward: TierReward;
  icon?: ReactElement;
  purchaseable?: boolean;
}

const plusButtonIcon = getIcon(IconType.plus, "plus-icon");
const freakIcon = getIcon(IconType.freak, "freak-icon");
const friendIcon = getIcon(IconType.friend, "friend-icon");
const lockButtonIcon = getIcon(IconType.lock, "lock-icon");

const GetRewardz = (props: RewardsProps) => {
  let isFriendLevel = false;
  let isFreakLevel = false;
  let isFriendMaxLevel = false;
  let isFanMaxLevel = false;
  const [showConfirmRewardMessage, setShowConfirmRewardMessage] = useState<boolean>(false);
  const [actionSheetReward, setActionSheetReward] = useState<TierReward>();
  const appContext = useContext(AppContext);
  const tierOneRewards: TierReward[] = props.rewards
    ? props.rewards.filter((reward) => reward.tierRank === 1)
    : [];
  const tierTwoRewards: TierReward[] = props.rewards
    ? props.rewards.filter((reward) => reward.tierRank === 2)
    : [];
  const tierThreeRewards: TierReward[] = props.rewards
    ? props.rewards.filter((reward) => reward.tierRank === 3)
    : [];

  let tierOneAndTwoRewardsElements: JSX.Element[] = [];
  let allRewardsElements: JSX.Element[] = [];
  if (props.userData) {
    switch (props.userData.loyaltyTierStatus.currentTierIndex) {
      case 0:
        isFanMaxLevel = true;
        break;
      case 1:
        isFriendMaxLevel = true;
        isFriendLevel = true;
        break;
      case 2:
        isFriendLevel = true;

        isFreakLevel = true;
        break;
    }
  }

  const tierOneRewardElements = tierOneRewards.map((reward) => {
    return <RewardItem key={reward.offerId} reward={reward} />;
  });

  const tierTwoRewardElements = tierTwoRewards.map((reward) => {
    return <RewardItem key={reward.offerId} reward={reward} />;
  });

  const tierThreeRewardElements = tierThreeRewards.map((reward) => {
    return <RewardItem key={reward.offerId} reward={reward} />;
  });

  if (isFriendMaxLevel) {
    const tierOneAndTwoRewards = tierOneRewards
      .concat(tierTwoRewards)
      .sort((a, b) => a.pointCost - b.pointCost);
    tierOneAndTwoRewardsElements = tierOneAndTwoRewards.map((reward) => {
      return <RewardItem key={reward.offerId} reward={reward} />;
    });
  }

  if (isFreakLevel) {
    const allRewards = tierOneRewards
      .concat(tierTwoRewards, tierThreeRewards)
      .sort((a, b) => a.pointCost - b.pointCost);
    allRewardsElements = allRewards.map((reward) => {
      return <RewardItem key={reward.offerId} reward={reward} />;
    });
  }

  const confirmRewardMessage: ReactElement = (
    <ActionSheet
      color={ActionSheetColor.red}
      title="Confirm Reward"
      shouldDisplay={showConfirmRewardMessage}
      overlay={true}
      required
    >
      <div className="action-sheet-rewardz-container">
        <div className="action-sheet-rewardz">
          <img
            className="action-sheet-rewardz-image"
            src={actionSheetReward?.imageURL}
            alt={actionSheetReward?.offerName}
          />
          <div className="action-sheet-rewardz-info">
            <div className="action-sheet-rewardz-name">{actionSheetReward?.offerName}</div>
            <div className="action-sheet-rewardz-detail">{actionSheetReward?.description}</div>
          </div>
        </div>
        <div className="action-sheet-btn-wrapper">
          <SheetzTextButton
            buttonColor={TextButtonColor.darkGray}
            textSize="large-text"
            className="action-sheet-decline-btn"
            label="No Thanks"
            onClick={(): void => setShowConfirmRewardMessage(false)}
          />
          <SheetzButton
            className="action-sheet-confirm-btn"
            label="Confirm"
            onClick={(): void => {
              if (actionSheetReward) {
                addNewReward(actionSheetReward);
              }
            }}
          />
        </div>
      </div>
    </ActionSheet>
  );

  function RewardItem(props: RewardItemProps): JSX.Element | null {
    const rewardItem: RewardItemProps = getRewardSetting(props.reward);
    if (rewardItem.reward) {
      return (
        <div className="get-rewardz-item">
          <img
            className="rewardz-image"
            src={rewardItem.reward.imageURL}
            alt={rewardItem.reward.offerName}
          />
          <div className="rewardz-info">
            <div className="rewardz-name">{rewardItem.reward.offerName}</div>
            <div className="rewardz-detail">{rewardItem.reward.description}</div>
          </div>
          <div className="rewardz-button-container">
            <SheetzButton
              className={"add-reward-button"}
              label={rewardItem.reward.pointCost + " Ptz"}
              label2={rewardItem.icon}
              disabled={!rewardItem.purchaseable}
              onClick={(event?: React.MouseEvent<HTMLButtonElement>): void => {
                event?.preventDefault();
                setActionSheetReward(rewardItem.reward);
                setShowConfirmRewardMessage(true);
              }}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  function getRewardSetting(reward: TierReward): RewardItemProps {
    const rewardInformation = {} as RewardItemProps;
    // user tier and reward tier start at different indexes
    if (
      props.userData &&
      props.userData.loyaltyTierStatus.currentTierIndex + 1 >= reward.tierRank
    ) {
      rewardInformation.icon =
        props.userData.loyaltyTierStatus.availablePoints > reward.pointCost
          ? plusButtonIcon
          : lockButtonIcon;
      rewardInformation.purchaseable =
        props.userData.loyaltyTierStatus.availablePoints > reward.pointCost;
      rewardInformation.reward = reward;
    } else {
      rewardInformation.icon = lockButtonIcon;
      rewardInformation.purchaseable = false;
      rewardInformation.reward = reward;
    }

    return rewardInformation;
  }

  function addNewReward(reward: TierReward): void {
    purchaseReward({ offerId: reward.offerId }).then(() => {
      setShowConfirmRewardMessage(false);
      appContext.showToast(
        "Hurray! " + actionSheetReward?.offerName + " added.",
        "Please allow up to one minute for your pointz to be updated and the reward to be added!",
        ToastType.confirm
      );
    });
    setTimeout(() => {
      props.reloadUserData();
      appContext.hideToast();
    }, 10000);
  }

  if (props.rewards) {
    return (
      <ResponsiveLayoutContainer>
        <div className="get-rewardz-container">
          <div className="spendable-pointz">
            <NumericFormat
              value={props.userData?.loyaltyTierStatus.availablePoints}
              displayType="text"
              thousandSeparator
              suffix=" Spendable Pointz"
            />
          </div>

          {/* This is for Fan level to display fan rewards  */}
          {isFanMaxLevel && (
            <>
              <div className="rewardz-tier-container">{tierOneRewardElements}</div>
              <div className={isFriendLevel ? "level-label hide" : "level-label"}>
                <span className="level-icon">{friendIcon}</span>
                <div className="level-label">Rewardz available at Friend Level</div>
              </div>
              <div className="rewardz-tier-container">{tierTwoRewardElements}</div>
              <div className={isFreakLevel ? "level-label hide" : "level-label"}>
                <span className="level-icon">{freakIcon}</span>
                <div className="level-label-text">Rewardz available at Freak Level</div>
              </div>
              <div className="rewardz-tier-container">{tierThreeRewardElements}</div>
            </>
          )}

          {/* This is for Friend level to display Fan and Friend rewards together */}
          {isFriendMaxLevel && (
            <>
              <div className="rewardz-tier-container">{tierOneAndTwoRewardsElements}</div>
              <div className={isFreakLevel ? "level-label hide" : "level-label"}>
                <span className="level-icon">{freakIcon}</span>
                <div className="level-label">Rewardz available at Freak Level</div>
              </div>
              <div className="rewardz-tier-container">{tierThreeRewardElements}</div>
            </>
          )}

          {/* This is for a Freak level to display all the rewards together */}
          {isFreakLevel && <div className="rewardz-tier-container">{allRewardsElements}</div>}

          {confirmRewardMessage}
        </div>
      </ResponsiveLayoutContainer>
    );
  }

  return null;
};

export default GetRewardz;
