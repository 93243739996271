import React, { ReactElement, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./OrderInformation.scss";

import { ItemEvent } from "assets/dtos/anywhere-dto";

import { OrderSubviewProps } from "components/Order/Order";
import FlexContainer from "components/misc/containers/FlexContainer";
import PathListItem from "components/misc/list/PathListItem/PathListItem";

import { AppContext } from "util/AppContext.util";

const OrderInformation = (props: OrderSubviewProps): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const appContext = useContext(AppContext);
  const locationState = location.state as {
    event: ItemEvent;
    homepageBannerRedirect: string;
    redirectOnOrderFlowFinish: string;
  };

  useEffect(() => {
    appContext.hideLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Set delivery=false in the order session and navigate to the start of ordering.
   */
  function pickupSelected(): void {
    props.dispatch({ type: "SET_DELIVERY", payload: false });
    if (
      locationState &&
      (!!locationState.redirectOnOrderFlowFinish ||
        !!locationState.homepageBannerRedirect ||
        !!locationState.event)
    ) {
      navigate("/order/selectStore", {
        state: locationState,
      });
    } else {
      navigate("/order/selectStore");
    }
  }

  /**
   * Set delivery=true in the order session and navigate to the delivery address selection screen.
   */
  function deliverySelected(): void {
    props.dispatch({ type: "SET_DELIVERY", payload: true });

    if (
      locationState &&
      (!!locationState.redirectOnOrderFlowFinish ||
        !!locationState.homepageBannerRedirect ||
        !!locationState.event)
    ) {
      navigate("/order/delivery/address", {
        state: locationState,
      });
    } else {
      navigate("/order/delivery/address");
    }
  }

  const subtitle = (
    <span>
      Available in Select Markets <br />
      $10 Minimum Order
    </span>
  );

  return (
    <>
      <div className="order-information-container">
        <FlexContainer
          flexStyles={{
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="order-information-inner-container">
            <p className="heading">Pickup or Delivery?</p>
            <ul>
              <li onClick={pickupSelected}>
                <PathListItem title="Pick up" subtitle="In-store, curbside, or drive-thru" />
              </li>
              <li onClick={deliverySelected}>
                <PathListItem title="Delivery" subtitle={subtitle} />
              </li>
            </ul>
          </div>
        </FlexContainer>
      </div>
    </>
  );
};

export default OrderInformation;
