import classNames from "classnames";
import React from "react";

import "./AgeVerificationHeader.scss";

import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";

import { IconType, getIcon } from "util/Icon.util";

interface AgeVerificationProps {
  ageVerification?: boolean;
}

const AgeVerificationHeader = (props: AgeVerificationProps) => {
  const checkIcon = getIcon(IconType.customizationCheck, "check-icon");
  const stepOneClassNames = classNames("step-indicator", { completed: !props.ageVerification });
  const stepTwoClassNames = classNames("step-indicator", { next: props.ageVerification });

  return (
    <>
      <div className="mid-container bottom-rip sheetz-red">
        <ResponsiveLayoutContainer>
          <div className="step-indicators">
            <div className={stepOneClassNames}>{props.ageVerification ? "1" : checkIcon}</div>
            <div className={stepTwoClassNames}>2</div>
          </div>

          <div className="verify-your-age-header">VERIFY YOUR AGE</div>
          <div className="you-must-be-label">
            You must be 21 years or older to opt into 21+ offers.
          </div>

          <div className="we-will-verify">
            We will verify your age by using a third-party age verification service. By opting into
            21+ Offers, it means you accept our terms of service.
          </div>
        </ResponsiveLayoutContainer>
      </div>
    </>
  );
};

export default AgeVerificationHeader;
