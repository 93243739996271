import classNames from "classnames";
import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./StoreDetailsModal.scss";

import { Store, StoreLocation } from "assets/dtos/anywhere-dto";

import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import StoreDisplay from "components/misc/store/StoreDisplay/StoreDisplay";
import { DefaultMapZoom, MapBoxFeature } from "components/pages/FindASheetz/FindASheetz";

import { useMediaQuery } from "hooks";

import { desktopMediaQuery } from "util/AppContext.util";
import { clearOrderSession } from "util/Authentication.util";
import { sheetzMapBoxAccessToken } from "util/Geolocation.util";
import { IconType, getIcon } from "util/Icon.util";
import { getAppStoreURL } from "util/MobileApp.util";
import { addStoreFavorite, deleteStoreFavorite } from "util/MyStores.util";
import { getOrderSessionFromStorage, isOrderSessionExpired, searchStores } from "util/Order.util";
import {
  EVTypeFilters,
  Filter,
  FuelTypeFilters,
  StoreFilters,
  loadStoreOnGoogleMaps,
} from "util/Store.util";

export interface StoreDetailsModalProps {
  onFavorite?: (store: Store, favorite: boolean) => void;
  noScroll?: boolean;
  storeAttribute: StoreLocation;
  userLocation?: [number, number];
}

interface StoreFeatureElementProps {
  filter: Filter;
}

export interface StoreState {
  store: Store;
}

const StoreDetailsModal = (props: StoreDetailsModalProps) => {
  const navigate = useNavigate();
  const [map, setMap] = useState<mapboxgl.Map>();
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);
  const storeDetailMapContainer = useRef<HTMLDivElement | null>(null);
  const storeMarker = useRef<mapboxgl.Marker[]>();
  const [store, setStore] = useState<Store>();

  useEffect(() => {
    searchStore();

    if (storeDetailMapContainer && storeDetailMapContainer.current) {
      const initializeMap = (
        setMap: React.Dispatch<React.SetStateAction<mapboxgl.Map | undefined>>,
        storeDetailMapContainer: React.MutableRefObject<HTMLDivElement | null>
      ): void => {
        if (storeDetailMapContainer.current === null) {
          return;
        }

        const map = new mapboxgl.Map({
          container: storeDetailMapContainer.current,
          style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
          center: [props.storeAttribute.longitude, props.storeAttribute.latitude],
          zoom: DefaultMapZoom,
          maxZoom: DefaultMapZoom,
          accessToken: sheetzMapBoxAccessToken,
          interactive: false,
        });

        map.on("load", () => {
          const feature: MapBoxFeature[] = [];
          feature.push({
            type: "Feature",
            properties: {
              id: props.storeAttribute.storeNumber,
              lat: props.storeAttribute.latitude,
              lng: props.storeAttribute.longitude,
            },
            geometry: {
              type: "Point",
              coordinates: [props.storeAttribute.longitude, props.storeAttribute.latitude],
            },
          });

          const dataSet = {
            type: "FeatureCollection",
            features: feature,
          };
          // Mapbox only accepts a geoJSON file for the data
          const dataSetJSON = JSON.stringify(dataSet);
          const blob = new Blob([dataSetJSON]);
          const fileDownloadUrl = URL.createObjectURL(blob);

          map.addSource("store-pins", {
            type: "geojson",
            data: fileDownloadUrl,
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 50,
          });

          // Adds a layer of unclustered-points that represent each store not shown in a cluster
          map.addLayer({
            id: "unclustered-point",
            type: "circle",
            source: "store-pins",
            filter: ["!", ["has", "point_count"]],
            paint: {
              "circle-opacity": 0,
            },
          });

          setMap(map);
          map.resize();
          setTimeout(() => {
            map.zoomTo(DefaultMapZoom);
          }, 1500);
        });

        map.on("moveend", function () {
          const myFeature = map.querySourceFeatures("store-pins");
          if (myFeature && myFeature.length >= 1) {
            storeMarker?.current?.forEach((marker) => {
              marker.remove();
            });

            const el = document.createElement("div");
            el.className = "store-pin-icon-marker";
            el.id = props.storeAttribute.storeNumber.toString();

            const marker = new mapboxgl.Marker(el)
              .setLngLat([props.storeAttribute.longitude, props.storeAttribute.latitude])
              .addTo(map);
            storeMarker.current = [marker];
          }
        });
      };
      // if the map has not been initialized do this step once
      if (!map) {
        initializeMap(setMap, storeDetailMapContainer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  function searchStore(): void {
    searchStores(
      0,
      props.userLocation && props.userLocation.length > 0 ? props.userLocation[0] : undefined,
      props.userLocation && props.userLocation.length > 0 ? props.userLocation[1] : undefined,
      props.storeAttribute.storeNumber?.toString()
    ).then((response) => {
      setStore(response.data.stores[0]);
    });
  }

  function toggleFavoriteStore(): void {
    if (store?.favorite) {
      deleteStoreFavorite(props.storeAttribute.storeNumber).then(() => {
        searchStore();
        if (props.onFavorite) {
          props.onFavorite(store, false);
        }
      });
    } else if (store) {
      addStoreFavorite(props.storeAttribute.storeNumber).then(() => {
        searchStore();
        if (props.onFavorite) {
          props.onFavorite(store, true);
        }
      });
    }
  }

  const StoreFeatureElement = (featureProps: StoreFeatureElementProps) => {
    return (
      <div className="store-feature-container">
        <div className="store-feature-icon">{featureProps.filter.icon}</div>
        <div className="store-feature-label">{featureProps.filter.title}</div>
      </div>
    );
  };

  const evTypeElements: (JSX.Element | undefined)[] = [];
  const fuelTypeElements: (JSX.Element | undefined)[] = [];
  const storeFeatureElements: (JSX.Element | undefined)[] = [];

  if (store && store.features) {
    const evFilters = [...EVTypeFilters];
    const fuelTypeFilters = [...FuelTypeFilters];
    const storeFilters = [...StoreFilters];

    // Remove features from the object that are not applicable
    const storeFeatures = {} as Record<string, boolean>;
    Object.entries(store.features).forEach((val): void => {
      // index 0 is the property name and index 1 is the value
      if (val[1]) {
        storeFeatures[val[0]] = val[1];
      }
    });

    // EV Type Filters
    Object.keys(storeFeatures).forEach((feature): void => {
      const filter = evFilters.find((filter) => filter.id === feature);

      if (filter) {
        evTypeElements.push(<StoreFeatureElement key={feature} filter={filter} />);
      }
    });

    // Fuel Type Filters
    Object.keys(storeFeatures).forEach((feature) => {
      const filter = fuelTypeFilters.find((filter) => filter.id === feature);

      if (filter) {
        fuelTypeElements.push(<StoreFeatureElement key={feature} filter={filter} />);
      }
    });

    fuelTypeElements.unshift(
      <StoreFeatureElement
        key="regular"
        filter={{
          title: "Regular 87",
          icon: getIcon(IconType.reg87, "fuel-type-icon"),
          id: "reg87",
          isFuel: true,
          selected: false,
        }}
      />,
      <StoreFeatureElement
        key="midgrade"
        filter={{
          title: "Plus 89",
          icon: getIcon(IconType.mid89, "fuel-type-icon"),
          id: "midgrade",
          isFuel: true,
          selected: false,
        }}
      />,
      <StoreFeatureElement
        key="premium"
        filter={{
          title: "Super 93",
          icon: getIcon(IconType.prem93, "fuel-type-icon"),
          id: "prem93",
          isFuel: true,
          selected: false,
        }}
      />
    );

    const bulkDefElementIndex = fuelTypeElements.findIndex(
      (fuelTypeElement) => fuelTypeElement?.key === "bulkDef"
    );

    if (bulkDefElementIndex !== -1) {
      fuelTypeElements.push(fuelTypeElements.splice(bulkDefElementIndex, 1)[0]);
    }

    // Store Features
    Object.keys(storeFeatures).forEach((feature) => {
      const filter = storeFilters.find((filter) => filter.id === feature);

      if (filter) {
        storeFeatureElements.push(<StoreFeatureElement key={feature} filter={filter} />);
      }
    });
  }

  return (
    <>
      <div className="store-detail-modal-container">
        <div ref={storeDetailMapContainer} className="store-detail-map-container" />
        {store && (
          <div className={classNames("store-details-container", { "no-scroll": props.noScroll })}>
            <div className="store-container">
              <ul className="store-list-container">
                <div>
                  {store && (
                    <StoreDisplay
                      store={store}
                      showFavorite={false}
                      showStoreNumber
                      showCurbside
                      showStoreAlerts
                    />
                  )}
                  <div className="save-store-button-flex-container">
                    <div
                      className={store.favorite ? "circle favorite" : "circle"}
                      role="button"
                      tabIndex={0}
                      onClick={toggleFavoriteStore}
                    >
                      {getIcon(IconType.star, store.favorite ? "star-icon favorite" : "star-icon")}
                    </div>

                    <p className="label">{store.favorite ? "Store saved" : "Save this store"}</p>
                  </div>
                </div>
              </ul>
              <div className="button-row-container">
                <div className="go-here-container">
                  <SheetzButton
                    className="go-here-button"
                    buttonColor={ButtonColor.sheetzBlue}
                    label="Go Here"
                    onClick={(): void => {
                      loadStoreOnGoogleMaps(store.address, store.city, store.state, store.zip);
                    }}
                  />
                </div>
                <div className="order-here-container">
                  <SheetzButton
                    className="order-here-button"
                    label="Order Here"
                    onClick={(): void => {
                      if (isOrderSessionExpired(getOrderSessionFromStorage())) {
                        clearOrderSession();
                      }
                      navigate("/order/selectStore", {
                        state: {
                          store: store,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              {!useDesktopView && store && store.phone && (
                <div className="call-this-store-label">
                  <a href={"tel:+" + store.phone}>
                    {getIcon(IconType.phone, "phone-icon")} Call this store
                  </a>
                </div>
              )}
            </div>
            <div className="store-attributes-container">
              <div className="fuel-prices-container">
                <div className="fuel-prices-content">
                  <div className="looking-for-fuel-prices-label">LOOKING FOR FUEL PRICES?</div>
                  <div className="fuel-prices-available-label">
                    Fuel prices available on the Sheetz&reg; App!
                  </div>
                  <div className="get-the-app-row">
                    <SheetzButton
                      className="get-the-app-button"
                      label="Get the app!"
                      onClick={(): void => {
                        window.open(getAppStoreURL(), "_blank");
                      }}
                    />
                  </div>
                </div>
              </div>

              {fuelTypeElements && (
                <>
                  <div className="fuel-types-label">Fuel</div>
                  <div className="fuel-type-container fuel-type-border">{fuelTypeElements}</div>
                </>
              )}

              {evTypeElements && evTypeElements.length > 0 && (
                <>
                  <div className="fuel-types-label ev-features">EV Charging</div>
                  <div className="fuel-type-container fuel-type-border">{evTypeElements}</div>
                </>
              )}

              {storeFeatureElements && (
                <>
                  <div className="fuel-types-label store-features">Store Features</div>
                  <div className="fuel-type-container">{storeFeatureElements}</div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StoreDetailsModal;
