import React from "react";
import { useNavigate } from "react-router-dom";

import "./Preferences.scss";

import PathListItem from "components/misc/list/PathListItem/PathListItem";

import { isAgeVerified } from "util/Account.util";

interface PreferencesProps {
  ageVerificationStatus: string | undefined;
}

const Preferences = (props: PreferencesProps) => {
  const navigate = useNavigate();

  function handleTwentyOnePlusNavigation(): void {
    if (isAgeVerified(props.ageVerificationStatus)) {
      navigate("/account/settings/preferences/twentyOnePlus");
    } else {
      navigate("/account/verifyAge?destination=/account/settings/preferences/twentyOnePlus");
    }
  }

  return (
    <div className="preferences-container">
      <>
        <PathListItem
          title="Notifications"
          clickHandler={(): void => navigate("/account/settings/preferences/notifications")}
        />
        <PathListItem title="21+ Offers" clickHandler={handleTwentyOnePlusNavigation} />
        <PathListItem
          title="Ordering Preferences"
          clickHandler={(): void => navigate("/account/settings/preferences/myOrderingPreferences")}
          hideBottomBorder
        />
      </>
    </div>
  );
};

export default Preferences;
