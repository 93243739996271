import axios, { AxiosError } from "axios";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { BFFErrorResponse } from "axiosConfig";

import "./EmailValidation.scss";

import { EmailVerificationTokenExpiredEventRequest } from "assets/dtos/anywhere-dto";

import Header, { HeaderType } from "components/layout/Header/Header";
import BoomBoomBackgroundContainer from "components/misc/containers/BoomBoomBackgroundContainer/BoomBoomBackgroundContainer";
import ActionPanel from "components/misc/view/ActionPanel/ActionPanel";

import { validateEmailToken } from "util/Account.util";
import { AppContext } from "util/AppContext.util";
import { emailVerificationTokenExpired, generateUuid } from "util/Metrics.util";
import { getAppStoreURL } from "util/MobileApp.util";

const EmailValidation = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) as { vt: string };
  const [showActionPanel, setShowActionPanel] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(true);

  // Break out these methods from appContext to avoid useEffect() lint warning regarding dependencies.
  // Having appContext within the dependencies array is bad - it will cause useEffect() to fire on all renders.
  const hideLoading = appContext.hideLoading;
  const showLoading = appContext.showLoading;

  useEffect(() => {
    if (queryParams.vt) {
      const validationToken = queryParams.vt;

      if (validationToken) {
        showLoading();

        validateEmailToken(validationToken)
          .then(() => {
            setShowActionPanel(true);
          })
          .catch((error: Error | AxiosError<BFFErrorResponse>): void => {
            if (axios.isAxiosError(error)) {
              setSuccess(false);
              setShowActionPanel(true);

              if (error.response) {
                const emailVerificationTokenExpiredEventRequest: EmailVerificationTokenExpiredEventRequest =
                  {
                    errorMessage: error.response?.data.message,
                    sessionId: generateUuid(),
                  };

                emailVerificationTokenExpired(emailVerificationTokenExpiredEventRequest);
              }
            }
          })
          .finally(hideLoading);
      }
    } else {
      navigate("/");
    }
  }, [hideLoading, navigate, queryParams.vt, showLoading]);

  function sheetzButtonPressed(): void {
    if (success) {
      navigate("/auth/login");
    } else {
      window.open(getAppStoreURL());
      navigate(-1);
    }
  }

  return (
    <div className="email-validation">
      <Header type={HeaderType.homepage} />
      <BoomBoomBackgroundContainer>
        {showActionPanel && (
          <ActionPanel
            bodyText={
              success
                ? "Your account has been activated"
                : "This link has expired or is invalid. You can request a new email after logging in to your account."
            }
            buttonText={success ? "Log in" : "Download the app"}
            onClick={sheetzButtonPressed}
            titleText={success ? "Got It!" : "Son of a shmiscuit!"}
          />
        )}
      </BoomBoomBackgroundContainer>
    </div>
  );
};

export default EmailValidation;
