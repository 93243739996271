import classNames from "classnames";
import React from "react";
import { NumericFormat } from "react-number-format";

import "./PaymentTypeButton.scss";

import SheetzContainerButton from "components/misc/button/SheetzContainerButton/SheetzContainerButton";

import { IconType, getIcon } from "util/Icon.util";
import { SelectedPaymentMethod, getCardName, getPaymentTypeIcon } from "util/Payment.util";

interface PaymentButtonProps {
  fetchPaymentMethods: boolean;
  isReloadGiftCard?: boolean;
  giftCardHasInsufficientFunds?: boolean;
  payInStore: boolean;
  onPaymentMethodClicked?: () => void;
  selectedPaymentMethod?: SelectedPaymentMethod;
}

const PaymentTypeButton = (props: PaymentButtonProps) => {
  const paymentMethod = props.selectedPaymentMethod?.paymentMethod;
  const enablePaymentSelection =
    !props.selectedPaymentMethod?.paymentRestriction &&
    !paymentMethod?.creditCard?.isExpired &&
    !props.giftCardHasInsufficientFunds;

  function getPaymentMethodExpAndBalance(): string | JSX.Element {
    if (!paymentMethod) {
      return "";
    }

    if (paymentMethod.paymentType === "CREDIT_CARD" && paymentMethod.creditCard) {
      return paymentMethod.creditCard.isExpired ? (
        <p className="payment-exp-balance low-funds-expired">Expired - Update Now</p>
      ) : (
        ""
      );
    } else if (paymentMethod.zCard) {
      return (
        <p
          className={classNames("payment-exp-balance", {
            "low-funds-expired": props.giftCardHasInsufficientFunds,
          })}
        >
          <span>Balance&nbsp;</span>
          <NumericFormat
            value={paymentMethod?.zCard?.balance}
            displayType="text"
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
          />
        </p>
      );
    }

    return "";
  }

  function getPaymentMethod(): string | undefined {
    if (props.fetchPaymentMethods || paymentMethod) {
      // Credit card
      if (paymentMethod?.creditCard) {
        return (
          getCardName(paymentMethod?.creditCard?.cardType) +
          " " +
          paymentMethod?.creditCard?.lastFourDigits
        );
      }
    }

    // ZCARD
    if (paymentMethod?.zCard) {
      return paymentMethod?.zCard?.cardName;
    }

    // Pay in store
    if (props.payInStore) {
      return "Pay in-store";
    }
  }

  function getStatusIcon(): React.ReactElement | undefined {
    const selectedPaymentMethod = props.selectedPaymentMethod;

    let icon;

    if (paymentMethod) {
      if (selectedPaymentMethod?.selected) {
        icon = IconType.customizationCheck;
      } else {
        icon = IconType.customizationUnChecked;
      }

      if (
        (props.giftCardHasInsufficientFunds && !props.isReloadGiftCard) ||
        selectedPaymentMethod?.paymentMethod?.creditCard?.isExpired
      ) {
        icon = IconType.exclamationCircle;
      }
    } else if (props.payInStore) {
      icon = IconType.customizationCheck;
    }

    if (icon) {
      return getIcon(icon, "selected-payment");
    }

    return undefined;
  }

  return (
    <SheetzContainerButton
      className="payment-info-container"
      onClick={enablePaymentSelection ? props.onPaymentMethodClicked : undefined}
    >
      {getStatusIcon()}
      {paymentMethod && getPaymentTypeIcon(paymentMethod)}

      <div className="payment-info-flex-container">
        <p className="payment-type">{getPaymentMethod()}</p>
        {getPaymentMethodExpAndBalance()}
      </div>
    </SheetzContainerButton>
  );
};

export default PaymentTypeButton;
