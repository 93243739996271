import classNames from "classnames";
import { useField } from "formik";
import React, { ChangeEvent, HTMLProps, ReactElement } from "react";

import "./SheetzSelect.scss";

import { SelectOption } from "components/misc/form/SheetzInput/SheetzInput";

import { IconType, getIcon } from "util/Icon.util";

interface SheetzSelectProps extends HTMLProps<HTMLSelectElement> {
  options: SelectOption[];
  name: string;
  onChangeEvent?: (val: ChangeEvent<HTMLSelectElement>) => void;
  buttonStyle?: boolean;
  hideSelectArrow?: boolean;
}

const SheetzSelect = (props: SheetzSelectProps) => {
  const [field, meta] = useField(props.name);

  const wrapperClasses = classNames("sheetz-select", "select", {
    error: meta.touched && meta.error,
    "no-button-style": !props.buttonStyle,
  });
  const selectClasses = classNames({
    "button-style": props.buttonStyle,
  });

  const options: ReactElement[] = [];
  if (props.label) {
    options.push(
      <option value="" key="default">
        {props.label}
      </option>
    );
  }
  props.options.forEach((option) => {
    options.push(
      <option value={option.value} key={option.value}>
        {option.label}
      </option>
    );
  });

  return (
    <div className={wrapperClasses}>
      {!props.hideSelectArrow && (
        <span className="select-arrow">{getIcon(IconType.arrow, "arrow-icon")}</span>
      )}
      {!props.buttonStyle && (
        <label htmlFor={props.name} id={props.name + "_label"}>
          {props.label}
        </label>
      )}
      {/* eslint-disable-next-line jsx-a11y/no-onchange */}
      <select
        {...field}
        aria-labelledby={props.name + "_label"}
        name={props.name}
        disabled={props.disabled}
        className={selectClasses}
        onChange={(val): void => {
          props.onChangeEvent?.(val) ?? field.onChange(val);
        }}
      >
        {options}
      </select>
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </div>
  );
};

export default SheetzSelect;
