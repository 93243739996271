import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";

import "./App.scss";

import { HomeScreenBanner } from "assets/dtos/anywhere-dto";

import MainLayout from "components/layout/MainLayout";
import ToastHelper from "components/layout/ToastHelper";
import ScrollToTop from "components/misc/indicators/ScrollToTop/ScrollToTop";
import ErrorDisplay from "components/misc/view/ErrorDisplay/ErrorDisplay";

import { AppContextProvider } from "util/AppContext.util";
import { postErrorLog } from "util/ServerLogging.util";

interface AppProps {
  homeScreenBanners?: HomeScreenBanner[];
}

const App = (props: AppProps) => {
  return (
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorDisplay} onError={postErrorLog}>
        <AppContextProvider homeScreenBanners={props.homeScreenBanners || []}>
          <>
            <ToastHelper />
            <ScrollToTop>
              <MainLayout />
            </ScrollToTop>
          </>
        </AppContextProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
