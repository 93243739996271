import classNames from "classnames";
import React from "react";

import "./InformationFooterContainer.scss";

interface InformationFooterContainerProps {
  mode?: "dark" | "light";
}

const InformationFooterContainer = (
  props: React.PropsWithChildren<InformationFooterContainerProps>
) => {
  const informationFooterContainerProps = classNames("information-footer-container", {
    "dark-mode": props.mode === "dark",
    "light-mode": props.mode === "light",
  });

  return <div className={informationFooterContainerProps}>{props.children}</div>;
};

export default InformationFooterContainer;
