import React, { ReactElement } from "react";
import { NumericFormat } from "react-number-format";

import "./PaymentFooterTipChoice.scss";

import SheetzContainerButton from "components/misc/button/SheetzContainerButton/SheetzContainerButton";

import { IconType, getIcon } from "util/Icon.util";

export enum TipChoice {
  Fifteen = "15%",
  Twenty = "20%",
  TwentyFive = "25%",
  Other = "Other",
}

interface PaymentFooterTipChoiceProps {
  tipChoice: TipChoice;
  tipAmount: number;
  isSelected?: boolean;
  onSelect: () => void;
}

const PaymentFooterTipChoice = (props: PaymentFooterTipChoiceProps): ReactElement => {
  return (
    <SheetzContainerButton
      className={props.isSelected ? "tip-choice btn selected" : "tip-choice btn"}
      onClick={props.onSelect}
    >
      {props.isSelected && getIcon(IconType.customizationCheck, "tip-check-icon")}
      <p className="description">{props.tipChoice}</p>
      <p className="amount">
        <NumericFormat
          displayType="text"
          value={props.tipAmount}
          decimalScale={2}
          fixedDecimalScale={true}
          prefix="+"
        />
      </p>
    </SheetzContainerButton>
  );
};

export default PaymentFooterTipChoice;
