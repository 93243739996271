export const LOGIN = "/users/login";
export const VALIDATE_DISPLAY_NAME = "/users/validateDisplayName";
export const EMAIL_AVAILABILITY = "/users/checkEmailAvailability";
export const RESET_PASSWORD_SEND_EMAIL = "/account/resetPassword/sendEmail";
export const VALIDATE_RESET_PASSWORD_TOKEN = "/account/resetPassword/validate";
export const UPDATE_PASSWORD = "/account/resetPassword/update";
export const RESET_PASSWORD = "/account/resetPassword/reset";
export const CHANGE_PASSWORD = "/users/changePassword";
export const USER = "/users";
export const USER_PROFILE = "/users/profile";
export const USER_IDENTITY_QUESTIONS = "/users/identityQuestions";
export const IDENTITY_QUESTIONS = "/users/identityQuestions";
export const PRINT_URL = "/payment/vouchers/print";
export const VALIDATE_EMAIL_TOKEN = "/users/validateEmailToken";
export const VERIFY_IDENTITY = "/users/verifyIdentity";
export const USER_SUMMARY = "/users/summary";
export const USER_VALIDATE_PASSCODE = "/users/validatePasscode";
export const USER_SEND_CODE = "/users/sendCode";
export const DOWNLOAD_USER_PII = "/users/pii";
export const DRIVER_TYPES = "/users/driverTypes";
export const UPDATE_AGE_RESTRICTED_OFFERS = "/users/ageRestrictedStatus";
