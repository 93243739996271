import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./MyVehicles.scss";

import { UserVehicleResponse } from "assets/dtos/anywhere-dto";

import VehicleIcon from "components/Account/AccountSettings/Preferences/MyOrderingPreferences/MyVehicles/VehicleIcon/VehicleIcon";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import ButtonFooterContainer from "components/misc/containers/ButtonFooterContainer/ButtonFooterContainer";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import EmptyPage from "components/misc/indicators/EmptyPage/EmptyPage";
import LoadingPlaceholder from "components/misc/indicators/LoadingPlaceholder/LoadingPlaceholder";
import ListContainer from "components/misc/list/ListContainer/ListContainer";
import ListItem from "components/misc/list/ListItem/ListItem";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { AppContext } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";
import { UserVehicle, getUserVehicles, removeUserVehicle } from "util/MyVehicles.util";

interface MyVehiclesProps {
  hideDelete?: boolean;
  onVehicleSelected?: (vehicle: UserVehicleResponse) => void;
  onAddVehicleSelected?: () => void;
}

interface UserVehicleProps {
  vehicleData: UserVehicle[];
}

const trashIcon = getIcon(IconType.trash, "trash-icon");

const MyVehicles = (props: MyVehiclesProps) => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const [userVehicles, setUserVehicles] = useState<UserVehicle[]>([]);
  const [showConfirmDeleteMessage, setShowConfirmDeleteMessage] = useState<boolean>(false);
  const [actionSheetVehicle, setActionSheetVehicle] = useState<UserVehicle>();
  const showLoading = appContext.showLoading;
  const hideLoading = appContext.hideLoading;
  const [loading, setLoading] = useState<boolean>(true);

  function addVehiclePressed(): void {
    if (props.onAddVehicleSelected) {
      props.onAddVehicleSelected();
    } else {
      navigate("/account/settings/preferences/myOrderingPreferences/addVehicle");
    }
  }

  function getVehicles(): void {
    setLoading(true);
    showLoading();

    getUserVehicles()
      .then((response) => {
        setUserVehicles(response.data);
      })
      .finally(() => {
        hideLoading();
        setLoading(false);
      });
  }

  useEffect(() => {
    getVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function removeVehicle(vehicleId: number): void {
    removeUserVehicle(vehicleId).then(() => {
      getVehicles();
      setShowConfirmDeleteMessage(false);
    });
  }

  const UserVehicles = (uvProps: UserVehicleProps) => {
    if (userVehicles) {
      const vehicleList = uvProps.vehicleData.map((vehicle, index) => {
        const vehicleDescriptor = `${vehicle.color} ${vehicle.make} ${vehicle.type}`;
        return (
          <ListItem
            key={vehicle.vehicleId}
            hideArrow={true}
            clickHandler={(): void => props.onVehicleSelected?.(vehicle)}
            buttonLabel={"Select " + vehicleDescriptor}
            rightButtonContent={!props.hideDelete && trashIcon}
            rightButtonClickHandler={(): void => {
              setActionSheetVehicle(vehicle);
              setShowConfirmDeleteMessage(true);
            }}
            rightButtonLabel={"Delete " + vehicleDescriptor}
            hideBottomBorder={index + 1 === uvProps.vehicleData.length}
          >
            <div className="user-vehicle-container">
              <VehicleIcon vehicle={vehicle} />
              <p className="user-vehicle-icon-label">{vehicleDescriptor}</p>
            </div>
          </ListItem>
        );
      });
      return <ListContainer>{vehicleList}</ListContainer>;
    } else {
      return <></>;
    }
  };

  const confirmVehicleDeleteMessage: ReactElement = (
    <ActionSheet
      color={ActionSheetColor.red}
      title="Confirm Remove"
      shouldDisplay={showConfirmDeleteMessage}
      overlay={true}
      required
    >
      <div className="user-vehicle-action-sheet">
        <div className="user-vehicle-action-sheet-label">
          Are you sure you want to remove:
          {" " +
            actionSheetVehicle?.color +
            " " +
            actionSheetVehicle?.make +
            " " +
            actionSheetVehicle?.type}
        </div>

        <div className="user-action-sheet-decline-btn-wrapper">
          <SheetzButton
            className="user-action-sheet-decline-button"
            buttonColor={ButtonColor.darkGray}
            label="Cancel"
            transparentButton
            onClick={(): void => setShowConfirmDeleteMessage(false)}
          />
        </div>
        <SheetzButton
          className="action-sheet-confirm-btn"
          label="Confirm"
          onClick={(): void => {
            if (actionSheetVehicle) {
              removeVehicle(actionSheetVehicle.vehicleId);
            }
          }}
        />
      </div>
    </ActionSheet>
  );

  if (userVehicles && userVehicles.length > 0) {
    return (
      <div className="my-vehicles-container">
        <ResponsiveLayoutContainer className="account-settings">
          <UserVehicles vehicleData={userVehicles} />

          {confirmVehicleDeleteMessage}

          <ButtonFooterContainer>
            <SheetzButton
              buttonColor={ButtonColor.sheetzRed}
              label="Add Vehicle"
              onClick={addVehiclePressed}
            />
          </ButtonFooterContainer>
        </ResponsiveLayoutContainer>
      </div>
    );
  } else if (loading) {
    return <LoadingPlaceholder />;
  } else {
    return (
      <>
        <div className="my-vehicles-container empty">
          <EmptyPage title="You have no saved vehicles" icon={IconType.vehicle} />

          <ButtonFooterContainer>
            <SheetzButton
              buttonColor={ButtonColor.sheetzRed}
              label="Add New Vehicle"
              onClick={addVehiclePressed}
            />
          </ButtonFooterContainer>
        </div>
      </>
    );
  }
};

export default MyVehicles;
