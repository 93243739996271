import React, { PropsWithChildren, ReactElement } from "react";
import { ReactComponent as SpinnyBoi } from "../../../../assets/icons/loader_circle_inner.svg";
import "./InlineLoading.scss";

interface InlineLoadingProps {
  hideBackground?: boolean;
  size?: number;
}

const InlineLoading = (props: PropsWithChildren<InlineLoadingProps>): ReactElement => {
  let parentStyles: React.CSSProperties | undefined;
  let iconStyles: React.CSSProperties | undefined;
  if (props.size !== undefined) {
    const sizeRem = props.size / 16;
    parentStyles = { width: sizeRem + "rem", height: sizeRem + "rem" };
    iconStyles = { width: sizeRem * 0.4 + "rem", margin: sizeRem * 0.3 + "rem" };
  }

  const classes = "inline-loading-content " + (props.hideBackground ? "hide-background " : "");

  return (
    <div className={classes} style={parentStyles} aria-label="Loading Content">
      <SpinnyBoi className="loading-spinner" />
      <span className="loading-icon" style={iconStyles}>
        {props.children}
      </span>
    </div>
  );
};

export default InlineLoading;
