import React, { ReactElement } from "react";
import Barcode from "react-barcode";

import "./SheetzBarcode.scss";

interface SheetzBarcodeProps {
  barcode: string;
}

const SheetzBarcode = (props: SheetzBarcodeProps): ReactElement => {
  return (
    <div className="barcode">
      <Barcode value={props.barcode} displayValue={false} margin={0} />
      <p className="instructions">Scan in store to pay</p>
    </div>
  );
};

export default SheetzBarcode;
