import axios, { AxiosPromise } from "axios";
import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";
import { DateTime } from "luxon";

import {
  AccompanyingItemUpsell,
  AddFavoriteOrderRequest,
  BagRequestCondiment,
  BagRequestItem,
  Combo,
  Condiment,
  CreateDeliveryAddressRequest,
  CreateDeliveryAddressResponse,
  CreatePurchaseOrderRequest,
  CreatePurchaseOrderResponse,
  CurrentTimeResponse,
  DateAsString,
  DeliveryTip,
  FavoriteOrder,
  FavoriteOrderCombo,
  FavoriteOrderCondiment,
  FavoriteOrderItem,
  FavoriteOrderRequestCombo,
  FavoriteOrderRequestCondiment,
  FavoriteOrderRequestItem,
  GetComboItemCustomizationsResponse,
  GetDeliveryAddressesResponse,
  GetDeliveryEstimateResponse,
  GetDeliveryInfoResponse,
  GetItemCustomizationResponse,
  GetOrderResponse,
  GetReceiptsResponse,
  GetStoreResponse,
  ItemEvent,
  MenuCategory,
  OperatingStatesResponse,
  OrderHistoryCombo,
  OrderHistoryCondiment,
  OrderHistoryItem,
  OrderType,
  OrderingSystem,
  PaymentMode,
  PaymentOptionsRequest,
  PaymentOptionsRequestCondiment,
  PaymentOptionsRequestItem,
  PaymentOptionsResponse,
  PickupLocation,
  PurchaseOrder,
  PurchaseOrderCombo,
  PurchaseOrderCondiment,
  PurchaseOrderIntegratedDelivery,
  PurchaseOrderItem,
  RetailItem,
  RetailModifiedItem,
  SearchStoresResponse,
  StartOrderEventRequest,
  StartOrderSessionRequest,
  StartOrderSessionResponse,
  Store,
  StoreAttributeResponse,
  SubmitOrderRequest,
  SubmitOrderResponse,
  UpdateDeliveryPhoneNumberRequest,
  UpdatePurchaseOrderRequest,
  UpdatePurchaseOrderResponse,
  ValidatePickupTimeRequest,
  ValidatePickupTimeResponse,
  VerifyDeliveryAddressRequest,
  VerifyDeliveryAddressResponse,
} from "assets/dtos/anywhere-dto";

import { OrderSessionAction } from "components/Order/OrderSessionReducer";
import { TipChoice } from "components/Order/PaymentFooter/PaymentFooterTipChoice/PaymentFooterTipChoice";

import { COMBO_DETAILS, ITEM_CUSTOMIZATION } from "endpoints/menu.endpoints";
import {
  CANCEL_ORDER,
  DELETE_DELIVERY_ADDRESS,
  DELIVERY_ADDRESSES,
  DELIVERY_ESTIMATE,
  DELIVERY_INFO,
  DELIVERY_PHONE_NUMBER,
  GET_CURRENT_SERVER_TIME,
  GET_OPERATING_STATES,
  GET_ORDER,
  GET_PAYMENT_OPTIONS,
  GET_RECEIPTS,
  GET_STORE,
  GET_STORE_ATTRIBUTES,
  PURCHASE_ORDER,
  START_ORDER_SESSION,
  STORE_SEARCH,
  SUBMIT_ORDER,
  UPDATE_DELIVERY_ADDRESS,
  VALIDATE_PICKUP_TIME,
} from "endpoints/order.endpoints";

import { ShoppingBag, ShoppingBagCombo, isCustomizedShoppingBagItem } from "util/Bag.util";
import {
  ItemCustomization,
  ItemCustomizationSelections,
  ItemSwitchOption,
  PortionedCondiment,
  SizeSelectOption,
  isFavoriteOrderCondiment,
  isPortionedCondiment,
} from "util/Customization.util";
import { orderStarted } from "util/Metrics.util";
import { UserVehicle } from "util/MyVehicles.util";
import {
  ReorderedShoppingBagItem,
  UnavailabilityDetails,
  isReorderedShoppingBagItem,
} from "util/Reorder.util";
import { getLastActivityTime, getOrderSession } from "util/Storage.util";
import { StoreSearchFiltersType } from "util/Store.util";
import {
  getDateTimeFromString,
  getHoursSinceDateTime,
  isDateTimeToday,
  isDateTimeTomorrow,
} from "util/Time.util";

const MINIMUM_DEFERRED_OFFSET = 20;
const DEFERRED_TIME_RESOLUTION = 10;
const DEFAULT_DELIVERY_TIP_PERCENTAGE = 20;
export const STORE_PAGINATION_SIZE = 15;
export const suggestedDeliveryTipPercentages = [15, 20, 25];

export enum OrderStatus {
  CANCELLED = "Cancelled",
  COMPLETED = "Completed",
  PROCESSING = "Processing",
  SCHEDULED = "Scheduled",
  FAILED = "Failed",
  CREATED = "Created",
  ABANDONED = "Abandoned",
  PENDING_DELIVERY = "Pending Delivery",
  RETURNED = "Returned",
}

export interface OrderItemState {
  accompanyingItemUpsells?: AccompanyingItemUpsell[];
  chosenCombo?: Combo;
  chosenItem?: ItemCustomization;
  handleNavigate?: boolean;
  itemCustomizationId?: number;
  itemEvent?: ItemEvent;
  itemSwitchOption?: ItemSwitchOption;
  menuCategory?: MenuCategory;
  navigate?: string;
  sizeSelectOption?: SizeSelectOption;
  showCustomizationActionSheets?: boolean;
  showAIUActionSheet?: boolean;
}

export interface OrderSession {
  userId?: number;
  orderSessionId?: string;
  store?: Store;
  storeNumber?: number;
  orderStartedTime?: string;
  driveThruAvailable?: boolean;
  curbsideOffered?: boolean;
  open24x7?: boolean;
  inStorePickup?: boolean;
  curbsideOpen?: boolean;
  curbsideNextTimeOpen?: string;
  asapOrder?: boolean;
  delivery?: boolean;
  deliveryAddressId?: number;
  deliveryTipSuggestions?: DeliveryTip[];
  deliveryTipChoice?: TipChoice;
  deliveryTipAmount?: number;
  deliveryPhoneNumber?: string;
  deliveryInstructions?: string;
  deliveryPhoneNumberConsent?: boolean;
  deliveryEstimateInMinutes?: number;
  pickupTime?: string;
  pickupLocation?: PickupLocation;
  dayPart?: string;
  menu?: MenuCategory[];
  itemCustomizationId?: number;
  itemCustomizationSelections?: ItemCustomizationSelections;
  combo?: Combo;
  currentComboItemIndex?: number;
  comboItemCustomizationSelections?: ItemCustomizationSelections[];
  customizedComboItems?: CustomizedItem[];
  shoppingBag?: ShoppingBag;
  search?: SearchResults;
  editItemId?: string;
  purchaseOrder?: PurchaseOrder;
  orderToken?: string;
  curbsideVehicle?: UserVehicle;
  orderStartedEventCaptured?: boolean;
  estimatedPickupInMinutes?: number;
}

export enum MenuItemType {
  item,
  combo,
  category,
  search,
  favorite,
  order,
  receipt,
}

export interface SearchResult {
  id: string;
  name: string;
  description?: string;
  link?: string;
  image: string;
  type: MenuItemType;
  onClick?: () => void;
}

export interface SearchResults {
  searchTerm?: string;
  results?: SearchResult[];
}

export interface CustomizedItem {
  comboId?: number;
  customizations?: ItemCustomizationSelections;
  itemCustomizationId: string;
  retailItem?: RetailItem;
  retailModifiedItem?: RetailModifiedItem;
  condiments: (Condiment | PortionedCondiment)[];
  quantity?: number;
  price: number;
  discount?: number;
  event?: ItemEvent;
}

export const pickupLocationsDisplayText: { [K in PickupLocation]: string } = {
  IN_STORE: "In-Store pickup",
  DRIVE_THRU: "Drive-thru pickup",
  CURBSIDE: "Curbside pickup",
};

export const paymentModesDisplayText: { [K in PaymentMode]: string } = {
  PAY_ONLINE: "Online",
  PAY_IN_STORE: "In-Store",
  PAY_THIRD_PARTY: "Other",
};

export interface AddNewAddressFormValues {
  alias: string;
  street: string;
  unit: string;
  city: string;
  stateCode: string;
  zipCode: string;
  specialInstructions: string;
}

export function orderStartedEvent(
  orderSession: OrderSession,
  dispatch: React.Dispatch<OrderSessionAction>,
  pickupLocation: PickupLocation
): void {
  if (!orderSession.orderStartedEventCaptured) {
    dispatch({
      type: "SET_ORDER_STARTED",
      payload: true,
    });

    const startOrderEventRequest = {
      orderType: orderSession.delivery ? "DELIVERY" : "PICKUP",
      storeNumber: orderSession.delivery
        ? orderSession.storeNumber
        : orderSession.store?.storeNumber,
      pickupLocation: pickupLocation,
      pickupTime: orderSession.pickupTime,
      dayPart: orderSession.dayPart,
      sessionId: orderSession.orderSessionId,
    } as StartOrderEventRequest;
    orderStarted(startOrderEventRequest);
  }
}

export function getStore(
  storeNumber: number,
  disableErrorMessageDisplay = false
): AxiosPromise<GetStoreResponse> {
  return axios({
    method: "GET",
    url: GET_STORE(storeNumber),
    disableErrorMessageDisplay,
  });
}

export function getOperatingStates(): AxiosPromise<OperatingStatesResponse> {
  return axios({
    method: "GET",
    url: GET_OPERATING_STATES,
  });
}

export function searchStores(
  page: number,
  lat?: number,
  long?: number,
  address?: string | null,
  stateCode?: string | null,
  storeSearchFilters?: StoreSearchFiltersType | null,
  size?: number
): AxiosPromise<SearchStoresResponse> {
  if (!size) {
    size = 15;
  }
  return axios({
    method: "POST",
    url: STORE_SEARCH,
    params: {
      latitude: lat,
      longitude: long,
      address: address,
      stateCode: stateCode,
      page: page,
      size: size,
    },
    data: storeSearchFilters ? storeSearchFilters : {},
  });
}

export function getStoreAttributes(): AxiosPromise<StoreAttributeResponse> {
  return axios({
    method: "GET",
    url: GET_STORE_ATTRIBUTES,
  });
}

export function getReceipts(): AxiosPromise<GetReceiptsResponse> {
  return axios({
    method: "GET",
    url: GET_RECEIPTS,
  });
}

export function startOrderSession(
  request: StartOrderSessionRequest,
  orderSessionId: string
): AxiosPromise<StartOrderSessionResponse> {
  return axios({
    method: "POST",
    url: START_ORDER_SESSION,
    data: request,
    headers: { sessionId: orderSessionId },
  });
}

export function getServerTime(): AxiosPromise<CurrentTimeResponse> {
  return axios({ method: "GET", url: GET_CURRENT_SERVER_TIME });
}

export function validatePickupTime(
  request: ValidatePickupTimeRequest,
  orderSessionId?: string
): AxiosPromise<ValidatePickupTimeResponse> {
  return axios({
    method: "POST",
    url: VALIDATE_PICKUP_TIME,
    data: request,
    headers: { sessionId: orderSessionId },
  });
}

export function getItemCustomization(
  itemCustomizationId: number,
  storeNumber: number,
  dayPart: string,
  orderType: OrderType,
  pickupLocation: PickupLocation
): AxiosPromise<GetItemCustomizationResponse> {
  return axios({
    method: "GET",
    params: { dayPart: dayPart, pickupLocation: pickupLocation, orderType: orderType },
    url: ITEM_CUSTOMIZATION(storeNumber, itemCustomizationId),
  });
}

export function getComboDetails(
  menuComboId: number,
  storeNumber: number,
  dayPart: string,
  orderType: OrderType,
  pickupLocation: PickupLocation
): AxiosPromise<GetComboItemCustomizationsResponse> {
  return axios({
    method: "GET",
    params: { dayPart: dayPart, pickupLocation: pickupLocation, orderType: orderType },
    url: COMBO_DETAILS(storeNumber, menuComboId),
  });
}

export function getPaymentOptions(
  request: PaymentOptionsRequest,
  orderSessionId?: string
): AxiosPromise<PaymentOptionsResponse> {
  return axios({
    method: "POST",
    url: GET_PAYMENT_OPTIONS,
    data: request,
    headers: { sessionId: orderSessionId },
  });
}

export function getOrder(
  orderNumber: number,
  historical?: boolean,
  storeNumber?: number
): AxiosPromise<GetOrderResponse> {
  return axios({
    method: "GET",
    url: GET_ORDER(orderNumber),
    params: { storeNumber: storeNumber, historical: historical },
  });
}

export function createPurchaseOrder(
  request: CreatePurchaseOrderRequest,
  orderSessionId?: string
): AxiosPromise<CreatePurchaseOrderResponse> {
  return axios({
    method: "POST",
    url: PURCHASE_ORDER,
    data: request,
    headers: { sessionId: orderSessionId },
    disableErrorMessageDisplay: true,
  });
}

export function updatePurchaseOrder(
  request: UpdatePurchaseOrderRequest,
  orderToken?: string,
  orderSessionId?: string
): AxiosPromise<UpdatePurchaseOrderResponse> {
  return axios({
    method: "PUT",
    url: PURCHASE_ORDER,
    data: request,
    headers: { "order-token": orderToken, sessionId: orderSessionId },
    disableErrorMessageDisplay: true,
  });
}

export function submitOrder(
  request: SubmitOrderRequest,
  orderToken: string,
  dayPart: string,
  orderSessionId?: string
): AxiosPromise<SubmitOrderResponse> {
  return axios({
    method: "POST",
    url: SUBMIT_ORDER,
    data: request,
    params: { dayPart: dayPart },
    headers: { "order-token": orderToken, sessionId: orderSessionId },
    disableErrorMessageDisplay: true,
  });
}

export function cancelOrder(orderNumber: number): AxiosPromise<null> {
  return axios({ method: "DELETE", url: CANCEL_ORDER(orderNumber) });
}

export function getDeliveryAddresses(): AxiosPromise<GetDeliveryAddressesResponse> {
  return axios({
    method: "GET",
    url: DELIVERY_ADDRESSES,
  });
}

export function deleteDeliveryAddress(deliveryAddressId: number): AxiosPromise<null> {
  return axios({
    method: "DELETE",
    url: DELETE_DELIVERY_ADDRESS(deliveryAddressId),
  });
}

export function createDeliveryAddress(
  request: CreateDeliveryAddressRequest
): AxiosPromise<CreateDeliveryAddressResponse> {
  return axios({
    method: "POST",
    url: DELIVERY_ADDRESSES,
    data: request,
  });
}

export function verifyAndUpdateDeliveryAddress(
  deliveryAddressId: number,
  request: VerifyDeliveryAddressRequest
): AxiosPromise<VerifyDeliveryAddressResponse> {
  return axios({ method: "PUT", url: UPDATE_DELIVERY_ADDRESS(deliveryAddressId), data: request });
}

export function getDeliveryInfo(): AxiosPromise<GetDeliveryInfoResponse> {
  return axios({
    method: "GET",
    url: DELIVERY_INFO,
  });
}

export function updateDeliveryPhoneNumber(
  phoneNumber: string
): AxiosPromise<UpdateDeliveryPhoneNumberRequest> {
  return axios({
    method: "PUT",
    url: DELIVERY_PHONE_NUMBER,
    data: { phoneNumber: phoneNumber },
  });
}

export function getDeliveryEstimate(
  deliveryAddressId: number
): AxiosPromise<GetDeliveryEstimateResponse> {
  return axios({
    method: "POST",
    url: DELIVERY_ESTIMATE,
    data: { deliveryAddressId: deliveryAddressId },
  });
}

export function generateSessionUUID(radix: number): string {
  const random = Math.random().toString(radix).substr(2, 9);
  return DateTime.local().toFormat("yyyyMMddHHmmss") + random;
}

export function pickupLocationsAvailable(store: Store): boolean {
  return (
    store.driveThru || store.inStorePickup || (store.curbside.offered && !store.curbside.disabled)
  );
}

/**
 *
 * @param sessionStartTime The time returned from the server at the start of an order (store selected).
 *
 * Calculates the earliest time a deferred order can be placed. This will be used to pre-populate the time picker
 * for a deferred order. The basic idea is to add 15 minutes to the sessionStartTime and then round up to
 * the closest 10 minute increment of the hour.
 *
 * i.e. Order start time is 12:28 PM. Add 15 minutes - 12:43 PM. Round up to closet allowed choice - 12:50 PM.
 */
export function getEarliestDateTimeForDeferredOrder(sessionStartTime: DateTime): DateTime {
  const minuteStartTime = sessionStartTime.minute;
  const minimumOffset = minuteStartTime + MINIMUM_DEFERRED_OFFSET;
  const roundUpMinutes = DEFERRED_TIME_RESOLUTION - (minimumOffset % DEFERRED_TIME_RESOLUTION);
  const minutesToAdd = MINIMUM_DEFERRED_OFFSET + roundUpMinutes;
  return sessionStartTime.plus({ minutes: minutesToAdd });
}

export function formatZipCode(zip?: string): string {
  if (!zip) {
    return "";
  }

  if (zip.length !== 9) {
    return zip;
  }

  return zip.slice(0, 5);
}

/**
 * Store addresses and cities are all fully uppercase. This fixes that by only
 * capitalizing the first letter of each word.
 * @param address
 */
export function formatStoreAddress(address?: string): string {
  if (!address) {
    return "";
  }

  return address.replace(/\B\w/g, (l) => l.toLowerCase());
}

export function flattenBag(
  shoppingBag: ShoppingBag
): (CustomizedItem | ReorderedShoppingBagItem)[] {
  const allBagEntities = [...shoppingBag.items, ...shoppingBag.combos];
  allBagEntities.sort((a, b) => a.id - b.id);

  return allBagEntities.flatMap((bagEntity) => {
    if (isCustomizedShoppingBagItem(bagEntity)) {
      return bagEntity.itemDetails;
    } else if (isReorderedShoppingBagItem(bagEntity)) {
      return bagEntity;
    } else {
      return bagEntity.items;
    }
  });
}

export function createItemsForRequests(
  shoppingBag: ShoppingBag
): PaymentOptionsRequestItem[] | BagRequestItem[] | PurchaseOrderItem[] {
  return flattenBag(shoppingBag).map((item, index) =>
    createItemForRequests(
      item,
      index,
      shoppingBag.combos.find((combo) => combo.id === item.comboId)
    )
  );
}

function createItemForRequests(
  item: CustomizedItem | ReorderedShoppingBagItem,
  lineNumber: number,
  associatedCombo?: ShoppingBagCombo
): PaymentOptionsRequestItem | BagRequestItem | PurchaseOrderItem {
  let rmiID: number;
  if (isCustomizedItem(item) && !item.retailModifiedItem) {
    // This should never happen.
    throw new SheetzError("Unable to get rmi ID.", {
      userReadableMessage: "Looks like we've hit a snag. Please try again.",
      primaryButton: SheetzErrorButtonType.TRY_AGAIN,
    });
  } else if (isCustomizedItem(item) && item.retailModifiedItem) {
    rmiID = item.retailModifiedItem.retailModifiedItemId;
  } else if (isReorderedShoppingBagItem(item)) {
    rmiID = item.retailModifiedItemId;
  } else {
    throw new SheetzError("The rmi ID is unexpectedly undefined.", {
      userReadableMessage: "Looks like we've hit a snag. Please try again.",
      primaryButton: SheetzErrorButtonType.TRY_AGAIN,
    });
  }

  let condiments;
  if (isReorderedShoppingBagItem(item) && item.condiments) {
    condiments = item.condiments.length
      ? item.condiments.map(createCondimentForRequests)
      : undefined;
  } else if (isCustomizedItem(item)) {
    condiments =
      item.condiments && item.condiments.length
        ? item.condiments.map(createCondimentForRequests)
        : undefined;
  }

  let quantity = item.quantity ?? 1;
  // Calculate actual quantity for items that are part of combos. Don't run if this is a reorder.
  if (
    item.comboId !== undefined &&
    associatedCombo !== undefined &&
    item.event?.source !== "REORDER"
  ) {
    quantity = quantity * associatedCombo.quantity;
  }

  return {
    comboId: item.comboId,
    lineNumber: lineNumber + 1, // Line numbers start at 1, not 0
    retailModifiedItemId: rmiID,
    quantity: quantity,
    condiments: condiments,
  };
}

function createCondimentForRequests(
  bagCondiment: Condiment | PortionedCondiment | FavoriteOrderCondiment
): PaymentOptionsRequestCondiment | BagRequestCondiment | PurchaseOrderCondiment {
  if (isPortionedCondiment(bagCondiment)) {
    if (bagCondiment.selectedPortion) {
      const portionedCondiment = bagCondiment.condiment.portions[bagCondiment.selectedPortion];
      if (portionedCondiment) {
        return { retailModifiedItemId: portionedCondiment.retailModifiedItemId };
      } else {
        // This should never happen
        throw new SheetzError("Unable to locate portion.", {
          userReadableMessage: "Looks like we've hit a snag. Please try again.",
          primaryButton: SheetzErrorButtonType.TRY_AGAIN,
        });
      }
    } else {
      return {
        retailModifiedItemId: bagCondiment.condiment.retailModifiedItem.retailModifiedItemId,
      };
    }
  } else if (isFavoriteOrderCondiment(bagCondiment)) {
    return { retailModifiedItemId: bagCondiment.retailModifiedItemId };
  } else {
    return { retailModifiedItemId: bagCondiment.retailModifiedItem.retailModifiedItemId };
  }
}

function createPurchaseOrderCombo(combo: ShoppingBagCombo): PurchaseOrderCombo {
  return {
    comboId: combo.id,
    menuComboId: combo.comboDetails.menuComboId,
    quantity: combo.quantity,
  };
}

/**
 * Transform the shopping bag contents into a list of items for consumption
 * by the Payment Options request.
 */
export function createPaymentOptionsRequest(
  storeNumber: number,
  orderingSystem: OrderingSystem,
  pickupLocation: PickupLocation,
  shoppingBag: ShoppingBag,
  isIntegratedDelivery?: boolean
): PaymentOptionsRequest {
  const items = createItemsForRequests(shoppingBag) as PaymentOptionsRequestItem[];

  return {
    storeNumber: storeNumber,
    pickupLocation: pickupLocation,
    orderingSystem: orderingSystem,
    // TODO: Is it even possible for this to be false?
    loyaltyCardHolder: true,
    items: items,
    integratedDelivery: !!isIntegratedDelivery,
  };
}

/**
 * Transform the shopping bag contents into a list of items for consumption
 * by the Create Purchase Order request.
 */
export function createPurchaseOrderRequest(
  storeNumber: number,
  orderingSystem: OrderingSystem,
  pickupLocation: PickupLocation,
  paymentMode: PaymentMode,
  shoppingBag: ShoppingBag,
  automaticOffersOnly: boolean,
  pickupTime?: DateAsString,
  deliveryAddressId?: number,
  deliveryTipChoice?: TipChoice,
  deliveryTipAmount?: number
): CreatePurchaseOrderRequest {
  const items = createItemsForRequests(shoppingBag);
  const combos = shoppingBag.combos ? shoppingBag.combos.map(createPurchaseOrderCombo) : undefined;

  let integratedDelivery: PurchaseOrderIntegratedDelivery | undefined = undefined;
  let orderPickupTime = pickupTime;

  // If this is a delivery order, create the base integrated delivery object.
  if (deliveryAddressId !== undefined) {
    let tipPercentage: number | undefined;
    if (deliveryTipChoice === undefined && deliveryTipAmount === undefined) {
      tipPercentage = DEFAULT_DELIVERY_TIP_PERCENTAGE;
    } else if (deliveryTipChoice !== undefined) {
      tipPercentage = getTipPercentageFromChoice(deliveryTipChoice);
    }

    integratedDelivery = {
      integratedDeliveryPartner: undefined,
      deliveryAddressId: deliveryAddressId,
      deliveryTipPercentage: tipPercentage,
      deliveryTip: deliveryTipAmount,
    };

    // Ensure we are not sending a pickup time when creating a PO for an Integrated Delivery order.
    orderPickupTime = undefined;
  }

  return {
    purchaseOrder: {
      storeNumber: storeNumber,
      pickupTime: orderPickupTime,
      orderingSystem: orderingSystem,
      pickupLocation: pickupLocation,
      paymentMode: paymentMode,
      items: items,
      combos: combos,
      integratedDelivery: integratedDelivery,
    },
    suggestedDeliveryTips:
      integratedDelivery !== undefined ? suggestedDeliveryTipPercentages : undefined,
    automaticOffersOnly: automaticOffersOnly,
  };
}

export function createFavoriteOrderRequest(
  orderNumber: number,
  items: PurchaseOrderItem[] | OrderHistoryItem[],
  combos?: PurchaseOrderCombo[] | OrderHistoryCombo[]
): AddFavoriteOrderRequest {
  const favoriteOrderItems: FavoriteOrderRequestItem[] = [];
  let favoriteOrderCombos: FavoriteOrderRequestCombo[] | undefined;

  // Need to declare an array of the union between PurchaseOrderItem and OrderHistoryItem.
  // See: https://stackoverflow.com/questions/49510832/typescript-how-to-map-over-union-array-type
  const itemsUnion: (PurchaseOrderItem | OrderHistoryItem)[] = items;
  // Casting as a PurchaseOrderItem here so that the compiler thinks all items have the virtual field defined.
  const nonVirtualItems: PurchaseOrderItem[] | OrderHistoryItem[] = itemsUnion.filter(
    (item) => !(item as PurchaseOrderItem).virtual
  );

  nonVirtualItems.forEach((item: PurchaseOrderItem | OrderHistoryItem) => {
    const condiments = (
      item.condiments as Array<PurchaseOrderCondiment | OrderHistoryCondiment> | undefined
    )?.map((condiment) => {
      return {
        retailModifiedItemId: condiment.retailModifiedItemId,
      } as FavoriteOrderRequestCondiment;
    });
    favoriteOrderItems?.push({
      comboId: item.comboId,
      retailModifiedItemId: item.retailModifiedItemId,
      quantity: item.quantity,
      condiments: condiments,
    });
  });

  if (combos && combos.length > 0) {
    favoriteOrderCombos = [];
    combos.forEach((combo: PurchaseOrderCombo | OrderHistoryCombo) => {
      favoriteOrderCombos?.push({
        comboId: combo.comboId,
        menuComboId: combo.menuComboId,
        quantity: combo.quantity,
      });
    });
  }

  return {
    order: {
      name: `Order #${orderNumber}`,
      combos: favoriteOrderCombos,
      items: favoriteOrderItems,
    },
  };
}

export function generateOrderSessionId(): string {
  return "_" + generateSessionUUID(36);
}

//eslint-disable-next-line
export function isCustomizedItem(item: any): item is CustomizedItem {
  return item && item.itemCustomizationId;
}

export function isFavoriteOrderAvailable(favoriteOrder: FavoriteOrder): boolean {
  const allItemsAvailable = !favoriteOrder.items.find((item) => item.availability !== "AVAILABLE");

  let allCondimentsAvailable = true;
  if (allItemsAvailable) {
    allCondimentsAvailable = !favoriteOrder.items.find((item) => {
      return !!item.condiments?.find((condiment) => condiment.availability !== "AVAILABLE");
    });
  }

  if (favoriteOrder.combos && favoriteOrder.combos.length > 0) {
    const allCombosAvailable = !favoriteOrder.combos.find(
      (combo) => combo.availability !== "AVAILABLE"
    );
    return allItemsAvailable && allCondimentsAvailable && allCombosAvailable;
  } else {
    return allItemsAvailable && allCondimentsAvailable;
  }
}

export function getUnavailabilityDetails(favoriteOrder: FavoriteOrder): UnavailabilityDetails {
  const details: UnavailabilityDetails = {};
  const noLongerOffer = new Set<string>();
  const currentlyUnavailable = new Set<string>();
  const temporarilyUnavailable = new Set<string>();
  const unavailableAtStore = new Set<string>();

  function handleAvailability(
    entity: FavoriteOrderItem | FavoriteOrderCondiment | FavoriteOrderCombo,
    name: string
  ): void {
    switch (entity.availability) {
      case "NO_LONGER_AVAILABLE":
        noLongerOffer.add(name);
        break;
      case "UNAVAILABLE":
      case "NOT_SELLABLE":
        currentlyUnavailable.add(name);
        break;
      case "TEMPORARILY_UNAVAILABLE":
        temporarilyUnavailable.add(name);
        break;
      case "UNAVAILABLE_AT_THIS_STORE":
        unavailableAtStore.add(name);
        break;
      default:
        break;
    }
  }

  favoriteOrder.items.forEach((item) => {
    handleAvailability(item, item.receiptText);
    item.condiments?.forEach((condiment) => handleAvailability(condiment, condiment.receiptText));
  });

  favoriteOrder.combos?.forEach((combo) => handleAvailability(combo, combo.name));

  details.noLongerOffer = noLongerOffer.size > 0 ? noLongerOffer : undefined;
  details.currentlyUnavailable = currentlyUnavailable.size > 0 ? currentlyUnavailable : undefined;
  details.temporarilyUnavailable =
    temporarilyUnavailable.size > 0 ? temporarilyUnavailable : undefined;
  details.unavailableAtStore = unavailableAtStore.size > 0 ? unavailableAtStore : undefined;

  return details;
}

export function filterUnavailableEntities(favoriteOrder: FavoriteOrder): FavoriteOrder {
  const filteredOrder = { ...favoriteOrder };

  const filteredItems = filteredOrder.items.filter((item) => item.availability === "AVAILABLE");
  filteredItems.forEach((item) => {
    const filteredCondiments = item.condiments?.filter(
      (condiment) => condiment.availability === "AVAILABLE"
    );
    item.condiments = filteredCondiments;
  });
  filteredOrder.items = filteredItems;

  const filteredCombos = filteredOrder.combos?.filter(
    (combo) => combo.availability === "AVAILABLE"
  );
  filteredOrder.combos = filteredCombos;

  return filteredOrder;
}

/**
 * If the phone number is set and the user has consented to sharing, then don't show them the phone number consent screen.
 */
export function shouldShowPhoneNumberConsent(orderSession: OrderSession): boolean {
  return !orderSession.deliveryPhoneNumber || !orderSession.deliveryPhoneNumberConsent;
}

export function getTipPercentageFromChoice(tipChoice: TipChoice): number | undefined {
  switch (tipChoice) {
    case TipChoice.Fifteen:
      return 15;
    case TipChoice.Twenty:
      return 20;
    case TipChoice.TwentyFive:
      return 25;
    case TipChoice.Other:
      return;
  }
}

export function getTipChoiceFromPercentage(percentage?: number): TipChoice | undefined {
  switch (percentage) {
    case 15:
      return TipChoice.Fifteen;
    case 20:
      return TipChoice.Twenty;
    case 25:
      return TipChoice.TwentyFive;
    default:
      return;
  }
}

export function determinePickupTime(orderSession: OrderSession): string {
  const purchaseOrder = orderSession.purchaseOrder;
  if (orderSession.asapOrder) {
    return `ASAP - Approx. ${
      purchaseOrder?.estimatedPickupInMinutes ?? orderSession.estimatedPickupInMinutes ?? "15"
    }min`;
  } else if (orderSession.pickupTime) {
    const pickupDateTime = getDateTimeFromString(orderSession.pickupTime);

    if (isDateTimeToday(pickupDateTime)) {
      return "Today at " + pickupDateTime.toLocaleString(DateTime.TIME_SIMPLE);
    } else if (isDateTimeTomorrow(pickupDateTime)) {
      return "Tomorrow at " + pickupDateTime?.toLocaleString(DateTime.TIME_SIMPLE);
    }
    return pickupDateTime.toFormat("cccc 'at' t");
  } else if (
    purchaseOrder?.integratedDelivery?.estimatedDeliveryInMinutes !== undefined ||
    orderSession.deliveryEstimateInMinutes
  ) {
    return `Approx. ${
      purchaseOrder?.integratedDelivery?.estimatedDeliveryInMinutes ??
      orderSession.deliveryEstimateInMinutes
    }min`;
  }
  return "Please select a pickup time";
}

export function getOrderSessionFromStorage(): OrderSession | undefined {
  const savedOrderSessionJSON = getOrderSession();

  if (savedOrderSessionJSON !== undefined && savedOrderSessionJSON.length) {
    return JSON.parse(savedOrderSessionJSON);
  }

  return undefined;
}

export function isOrderSessionExpired(orderSession: OrderSession | undefined): boolean {
  if (orderSession?.orderStartedTime !== undefined) {
    const lastActivityDateTime = getDateTimeFromString(
      getLastActivityTime() ?? orderSession.orderStartedTime
    );

    // If an order is more than 12 hours old it is considered expired
    return getHoursSinceDateTime(lastActivityDateTime) >= 12;
  }

  return false;
}
