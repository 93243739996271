import classNames from "classnames";
import React, { PropsWithChildren, ReactElement } from "react";

import "./ListContainer.scss";

const ListContainer = (props: PropsWithChildren<{ className?: string }>): ReactElement => {
  return <div className={classNames("list-container", props.className)}>{props.children}</div>;
};

export default ListContainer;
