import React from "react";

import "./ConfirmationActionSheet.scss";

import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton, {
  TextButtonColor,
} from "components/misc/button/SheetzTextButton/SheetzTextButton";

interface ConfirmationActionSheetProps {
  show: boolean;
  confirmationMessage: string | JSX.Element;
  confirmButtonTitle: string;
  confirmButtonHandler: () => void;
  cancelButtonTitle?: string;
  cancelButtonHandler: () => void;
}

const ConfirmationActionSheet = (props: ConfirmationActionSheetProps) => {
  return (
    <ActionSheet
      color={ActionSheetColor.red}
      title="Are You Sure?"
      overlay={true}
      shouldDisplay={props.show}
    >
      <div className="confirmation-action-sheet-message">{props.confirmationMessage}</div>
      <div className="confirmation-action-sheet-flex-container">
        <SheetzTextButton
          buttonColor={TextButtonColor.mediumGray}
          textSize="large-text"
          underline
          label={props.cancelButtonTitle ?? "Go Back"}
          onClick={props.cancelButtonHandler}
        />
        <SheetzButton label={props.confirmButtonTitle} onClick={props.confirmButtonHandler} />
      </div>
    </ActionSheet>
  );
};

export default ConfirmationActionSheet;
