import { useCallback, useEffect, useState } from "react";

/**
 * Custom hook that persists a value with a session storage backed store.
 *
 * @remarks
 * This hook will fail silently if the value fails to persist into session storage (user could have it disabled, etc.).
 *
 * @param key - The key to use in the store
 * @param initialValue - The initial value to set in the store if one doesn't already exist.
 * @returns A tuple of the current value in the store, if there is one, otherwise `initialValue` AND a setter function
 */
function useSessionStorage<S = undefined>(
  key: string,
  initialValue: S
): [S, (value: S | ((prevValue: S) => S)) => void] {
  const [internalValue, setInternalValue] = useState<S>(() => {
    const currentStoragevalue = sessionStorage.getItem(key);
    if (currentStoragevalue !== null) {
      return JSON.parse(currentStoragevalue);
    } else {
      return initialValue;
    }
  });

  // UseEffect hook that monitors the internal value and writes it to session storage.
  useEffect(() => {
    if (internalValue !== undefined) {
      try {
        sessionStorage.setItem(key, JSON.stringify(internalValue));
      } catch (error) {
        console.log(error);
      }
    } else {
      sessionStorage.removeItem(key);
    }
  }, [internalValue, key]);

  const memoizedCallback = useCallback((value: S | ((prevValue: S) => S)) => {
    setInternalValue((oldInternalValue) => {
      let newValue;
      if (value instanceof Function) {
        newValue = value(oldInternalValue);
      } else {
        newValue = value;
      }
      return newValue;
    });
  }, []);

  return [internalValue, memoizedCallback];
}

export { useSessionStorage };
