import axios, { AxiosPromise } from "axios";

import { setAxiosAuthorizationHeader } from "axiosConfig";

import {
  ChangePasswordRequest,
  CheckEmailAvailabilityResponse,
  CreateUserRequest,
  CreateUserResponse,
  LoginRequest,
  LoginResponse,
  PasscodeValidationResponse,
  ResetPasswordRequest,
  ResetPasswordUpdateRequest,
  ResetPasswordValidateTokenRequest,
  ResetPasswordValidateTokenResponse,
  ValidateDisplayNameRequest,
  ValidateDisplayNameResponse,
} from "assets/dtos/anywhere-dto";

import {
  CHANGE_PASSWORD,
  EMAIL_AVAILABILITY,
  LOGIN,
  RESET_PASSWORD,
  RESET_PASSWORD_SEND_EMAIL,
  UPDATE_PASSWORD,
  USER,
  USER_SEND_CODE,
  USER_VALIDATE_PASSCODE,
  VALIDATE_DISPLAY_NAME,
  VALIDATE_RESET_PASSWORD_TOKEN,
} from "endpoints/account.endpoints";

import { isInNativeMobileContext } from "util/MobileApp.util";
import {
  clearLastActivityTime,
  getAuthToken,
  removeAuthToken,
  removeFirstName,
  removeOrderSession,
  removePinAuthToken,
  removeRecentStore,
  removeUserId,
  setAuthToken,
} from "util/Storage.util";

export interface AuthTokenPayload {
  sub: string;
  iat: number;
  exp: number;
  iss: string;
}

export function login(request: LoginRequest): AxiosPromise<LoginResponse> {
  return axios({
    method: "POST",
    url: LOGIN,
    data: request,
  });
}

export function validatePasscode(
  passcode: string,
  fprToken: string | null
): AxiosPromise<PasscodeValidationResponse> {
  return axios({
    method: "POST",
    url: USER_VALIDATE_PASSCODE,
    data: { passcode: passcode },
    headers: { Authorization: fprToken },
  });
}

export function sendCode(fprToken: string | null): AxiosPromise {
  return axios({
    method: "POST",
    url: USER_SEND_CODE,
    headers: { Authorization: fprToken },
  });
}

export function logout(): void {
  removeUserId();
  removeFirstName();
  removeRecentStore();
  clearOrderSession();
  removeAuthorization();
  removePinAuthToken();
}

export function clearOrderSession(): void {
  removeOrderSession();
  clearLastActivityTime();
}

/**
 * For now, this simply checks for the presence of an auth token.
 * It doesn't verify that the auth token is still valid.
 *
 * For the mobile apps, we assume they are logged in until we ask for the token when making a service call.
 */
export function isLoggedIn(): boolean {
  return !!getAuthToken() || isInNativeMobileContext();
}

export function checkEmailAvailability(
  email: string
): AxiosPromise<CheckEmailAvailabilityResponse> {
  return axios({
    method: "GET",
    url: EMAIL_AVAILABILITY,
    params: {
      emailAddress: email,
    },
  });
}

export function createAccount(request: CreateUserRequest): AxiosPromise<CreateUserResponse> {
  return axios({
    method: "POST",
    url: USER,
    data: request,
  });
}

export function sendResetPasswordEmail(request: ResetPasswordRequest): AxiosPromise<null> {
  return axios({
    method: "POST",
    url: RESET_PASSWORD_SEND_EMAIL,
    data: request,
  });
}

export function setAuthorization(authToken: string): void {
  setAuthToken(authToken);
  setAxiosAuthorizationHeader(authToken);
}

export function getAuthorization(): string | null {
  return getAuthToken();
}

export function removeAuthorization(): void {
  removeAuthToken();
  setAxiosAuthorizationHeader("");
}

export function validateDisplayName(
  request: ValidateDisplayNameRequest
): AxiosPromise<ValidateDisplayNameResponse> {
  return axios({
    method: "POST",
    url: VALIDATE_DISPLAY_NAME,
    data: request,
    disableErrorMessageDisplay: true,
  });
}

export function validateResetToken(
  request: ResetPasswordValidateTokenRequest
): AxiosPromise<ResetPasswordValidateTokenResponse> {
  return axios({
    method: "POST",
    url: VALIDATE_RESET_PASSWORD_TOKEN,
    data: request,
  });
}

export function updatePassword(request: ResetPasswordUpdateRequest): AxiosPromise<null> {
  return axios({
    method: "POST",
    url: UPDATE_PASSWORD,
    data: request,
  });
}

export function resetPassword(request: ResetPasswordUpdateRequest): AxiosPromise<null> {
  return axios({
    method: "POST",
    url: RESET_PASSWORD,
    data: request,
    headers: { Authorization: request.resetToken },
    skipNativeAuthToken: true,
  });
}

export function changePassword(request: ChangePasswordRequest): AxiosPromise<null> {
  return axios({
    method: "POST",
    url: CHANGE_PASSWORD,
    data: request,
  });
}

export function getAuthTokenPayload(jwtToken?: string | null): AuthTokenPayload | undefined {
  if (jwtToken !== undefined && jwtToken !== null) {
    const tokenPayloadString = jwtToken.split(".")[1];
    return JSON.parse(base64Decode(tokenPayloadString));
  } else {
    return;
  }
}

// decode JWT payload string which has been base-64 encoded
function base64Decode(tokenString: string): string {
  let output = tokenString.replace("-", "+").replace("_", "/");
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += "==";
      break;
    case 3:
      output += "=";
      break;
  }
  return window.atob(output);
}
