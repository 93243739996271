import classNames from "classnames";
import React, { useState } from "react";

import "./ReorderDetailListItem.scss";

import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import DiscountDisplay from "components/misc/item/DiscountDisplay/DiscountDisplay";
import ItemImageTextDisplay from "components/misc/item/ItemDisplay/ItemImageTextDisplay/ItemImageTextDisplay";
import ItemQuantityDisplay from "components/misc/item/ItemQuantityDisplay/ItemQuantityDisplay";
import PriceDisplay from "components/misc/pricing/PriceDisplay/PriceDisplay";

import { IconType, getIcon } from "util/Icon.util";
import { calculateReorderDetailListItemTotal } from "util/Price.util";
import { Discount } from "util/Reorder.util";

export interface ReorderDetailListItemProps {
  available?: boolean;
  comboId?: number;
  description: string;
  discount?: Discount;
  image: string | undefined;
  name: string;
  onRemoveItem?: (remove: boolean) => void;
  price: number;
  quantity: number;
  showPriceDetails?: boolean;
  type: "favoriteOrderItem" | "orderHistoryItem";
  unactionableOrder: boolean;
  receipt?: boolean;
}

const ReorderDetailListItem = (props: ReorderDetailListItemProps) => {
  const [pendingRemoval, setPendingRemoval] = useState<boolean>(false);

  const total = calculateReorderDetailListItemTotal(props);

  function removeItemPressed(): void {
    // For order history items, there is no ability to remove the item.
    if (props.type === "orderHistoryItem") {
      return;
    }

    setPendingRemoval(!pendingRemoval);
    props.onRemoveItem?.(!pendingRemoval);
  }

  const buttonLabel =
    props.type === "favoriteOrderItem"
      ? pendingRemoval
        ? "Item removed. Undo?"
        : "Remove item from favorite"
      : "Not included in this order";

  return (
    <div className="reorder-detail-list-item">
      <div className={classNames({ unavailable: !props.available && props.receipt === undefined })}>
        <div className="item-image-text-container">
          <ItemImageTextDisplay
            description={props.description}
            icon={
              !props.image
                ? getIcon(IconType.bag, "reorder-detail-item-placeholder-image")
                : undefined
            }
            image={props.image}
            title={props.name}
          />
          {props.showPriceDetails && (
            <div className="item-price">
              <PriceDisplay
                discount={props.discount?.discount}
                originalPrice={props.price * props.quantity}
                total={total}
              />
            </div>
          )}
        </div>
        {props.showPriceDetails && props.discount && (
          <div className="item-discount">
            <DiscountDisplay discount={props.discount?.discount} name={props.discount?.name} />
          </div>
        )}
        <div className="reorder-detail-list-item-quantity">
          <ItemQuantityDisplay quantity={props.quantity} />
        </div>
      </div>

      {props.available !== undefined && !props.available && props.receipt === undefined && (
        <SheetzButton
          className="remove-item-button"
          buttonColor={pendingRemoval ? ButtonColor.white : ButtonColor.errorOrange}
          type="button"
          label={buttonLabel}
          onClick={removeItemPressed}
        />
      )}
    </div>
  );
};

export default ReorderDetailListItem;
