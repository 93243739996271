import { AxiosResponse } from "axios";
import React, { ReactElement, useContext, useEffect, useState } from "react";

import "./Pin.scss";

import CreatePin from "components/Account/MyWallet/Pin/CreatePin/CreatePin";
import PinEntry from "components/Account/MyWallet/Pin/PinEntry/PinEntry";
import LoadingPlaceholder from "components/misc/indicators/LoadingPlaceholder/LoadingPlaceholder";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { checkUserForPin, createUserPin, verifyPinStillValid } from "util/Pin.util";
import { setPinAuthToken } from "util/Storage.util";

interface PinProps {
  setIsAuthenticatedCallback: (val: boolean) => void;
  closedWithoutAuthenticatingCallback: () => void;
}

const Pin = (props: PinProps): ReactElement => {
  const appContext = useContext(AppContext);
  const hideLoading = appContext.hideLoading;
  const showLoading = appContext.showLoading;
  const [loading, setLoading] = useState<boolean>(true);
  const [pinAuthenticated, setPinAuthenticated] = useState<boolean>(false);
  const [pinExists, setPinExists] = useState<boolean>(false);
  const [pinServerSaveError, setPinServerSaveError] = useState<boolean>(false);
  const [showCreatePin, setShowCreatePin] = useState<boolean>(false);
  const [showPinEntry, setShowPinEntry] = useState<boolean>(false);

  useEffect(() => {
    if (verifyPinStillValid()) {
      props.setIsAuthenticatedCallback(true);
      return;
    }

    setLoading(true);
    checkUserForPin()
      .then((response: AxiosResponse) => {
        setPinAuthenticated(false);
        setPinExists(response.data.checkPin.pinExists);
        if (response && !response.data.checkPin.pinExists) {
          setShowCreatePin(true);
        } else {
          setShowPinEntry(true);
          setShowCreatePin(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideLoading, showLoading]);

  function closePinEntry(authenticated: boolean): void {
    setShowPinEntry(false);
    authenticated
      ? props.setIsAuthenticatedCallback(true)
      : props.closedWithoutAuthenticatingCallback();
  }

  function createPin(pin: string): void {
    createUserPin(pin).then(
      (response) => {
        setShowCreatePin(false);
        setPinExists(true);
        setPinAuthToken(response.data.jwtToken);
        props.setIsAuthenticatedCallback(true);
        appContext.showToast("Settings Saved!", "", ToastType.success);
      },
      () => {
        setPinServerSaveError(true);
      }
    );
  }

  const pinCreateActionSheet = (
    <ActionSheet
      color={ActionSheetColor.currency}
      text="LOCK IT UP!"
      title="CREATE A PIN"
      shouldDisplay={showCreatePin}
      overlay={true}
      required
    >
      {showCreatePin && (
        <CreatePin
          editPin={false}
          resetPin={false}
          savePinCallback={createPin}
          serverSaveError={pinServerSaveError}
        />
      )}
    </ActionSheet>
  );

  const pinEntryActionSheet = (
    <ActionSheet
      color={ActionSheetColor.currency}
      title="ENTER PIN"
      shouldDisplay={showPinEntry}
      overlay={true}
      required
    >
      {showPinEntry && <PinEntry verifyPinCallback={closePinEntry} />}
    </ActionSheet>
  );

  if (loading) {
    return <LoadingPlaceholder />;
  } else {
    if (!pinAuthenticated && pinExists) {
      return <>{showPinEntry && pinEntryActionSheet}</>;
    } else {
      return <>{showCreatePin && pinCreateActionSheet}</>;
    }
  }
};

export default Pin;
