import React, { ReactElement } from "react";

import "./DeliveryAddressListItem.scss";

import { DeliveryAddress } from "assets/dtos/anywhere-dto";

import SheetzContainerButton from "components/misc/button/SheetzContainerButton/SheetzContainerButton";
import ListItem from "components/misc/list/ListItem/ListItem";

interface DeliveryAddressProps {
  deliveryAddress: DeliveryAddress;
  hideArrow?: boolean;
  hideBottomBorder?: boolean;
  icon?: ReactElement;
  onDeliveryAddressSelected?: (deliveryAddress: DeliveryAddress) => void;
  onDeliveryIconSelected?: (deliveryAddress: DeliveryAddress) => void;
  specialInstructions?: boolean;
}

const DeliveryAddressListItem = (props: DeliveryAddressProps): ReactElement => {
  return (
    <div className="delivery-address-list-item" key={props.deliveryAddress.deliveryAddressId}>
      <ListItem
        clickHandler={(): void => props.onDeliveryAddressSelected?.(props.deliveryAddress)}
        hideArrow={props.hideArrow}
        hideBottomBorder={props.hideBottomBorder}
      >
        <div className="address-container">
          <div>
            {props.deliveryAddress.alias && (
              <>
                <p className="title">{props.deliveryAddress.alias}</p>
                <p className="subtitle">{props.deliveryAddress.street}</p>
              </>
            )}
            {!props.deliveryAddress.alias && (
              <>
                <p className="title">{props.deliveryAddress.street}</p>
              </>
            )}
            {props.deliveryAddress.unit && (
              <>
                <p className="subtitle">{props.deliveryAddress.unit}</p>
              </>
            )}
            <p className="subtitle">
              {props.deliveryAddress.city}, {props.deliveryAddress.stateCode}{" "}
              {props.deliveryAddress.zipCode}
            </p>

            {props.specialInstructions && (
              <p className="special-instructions">{props.deliveryAddress.specialInstructions}</p>
            )}
          </div>

          {props.icon && (
            <SheetzContainerButton
              className="delivery-address-icon"
              onClick={(): void => props.onDeliveryIconSelected?.(props.deliveryAddress)}
            >
              {props.icon}
            </SheetzContainerButton>
          )}
        </div>
      </ListItem>
    </div>
  );
};

export default DeliveryAddressListItem;
