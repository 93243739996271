import axios, { AxiosPromise } from "axios";
import React, { ReactNode } from "react";

import {
  BuyZCardRequest,
  CardType,
  CardTypeEnum,
  GetCreditCardResponse,
  GetPaymentMethodResponse,
  GetPaymentMethodsResponse,
  GetZCardResponse,
  PaymentMethod,
  PaymentRestriction,
  PurchaseOrder,
  UpdateZCardRequest,
  ZCard,
  ZCardAddFundsRequest,
} from "assets/dtos/anywhere-dto";
import zcardIcon from "assets/images/GC_2021_lg.png";

import { CreditCardFormValues } from "components/Account/MyWallet/MyWallet";

import {
  CREDIT_CARD,
  CREDIT_CARD_POST,
  GIFT_CARD,
  GIFT_CARD_ADD_FUNDS,
  GIFT_CARD_BUY,
  GIFT_CARD_POST,
  PAYMENT_METHODS,
  PAYMENT_METHOD_DELETE,
  UPDATE_GIFT_CARD,
} from "endpoints/payment.endpoints";

import { IconType, getIcon } from "util/Icon.util";
import { getPinAuthToken } from "util/Storage.util";

export interface AddFundsHeaderContextProps {
  setAmount: (val: number) => void;
  amount: number;
}

export const CreditCardMonths = [
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export interface MobilePaymentMethods {
  applePayAvailable: boolean;
  applePayDefault: boolean;
  googlePayAvailable: boolean;
  googlePayDefault: boolean;
}

export enum MobilePayType {
  ApplePay = "APPLE_PAY",
  GooglePay = "GOOGLE_PAY",
}

export interface SelectedPaymentMethod {
  paymentMethod?: PaymentMethod;
  selected?: boolean;
  paymentRestriction?: PaymentRestriction;
}

export interface SelectedMobilePaymentMethod {
  default: boolean;
  mobilePayType: MobilePayType;
  selected?: boolean;
  paymentRestriction?: PaymentRestriction;
}

export const AddFundsHeaderContext = React.createContext({} as AddFundsHeaderContextProps);

export interface GiftCardState {
  paymentMethodId?: number;
  amount?: number;
  reloadThreshold?: number;
  returnUri: string;
  ordering?: boolean;
}

export const getCardName = (card: CardTypeEnum | CardType): string => {
  let cardName = card.toString();
  if (card === "VISA") {
    cardName = "Visa";
  } else if (card === "MASTER_CARD") {
    cardName = "Mastercard";
  } else if (card === "DISCOVER") {
    cardName = "Discover";
  } else if (card === "AMEX") {
    cardName = "Amex";
  } else if (card === "UNKNOWN") {
    cardName = "Unknown";
  }
  return cardName;
};

export function getCreditCardIconType(cardType: CardTypeEnum | CardType): IconType {
  let iconType: IconType;
  switch (cardType) {
    case "AMEX":
      iconType = IconType.amex;
      break;
    case "DISCOVER":
      iconType = IconType.discover;
      break;
    case "MASTER_CARD":
      iconType = IconType.mastercard;
      break;
    case "VISA":
    default:
      iconType = IconType.visa;
      break;
  }
  return iconType;
}

export function getPaymentTypeIcon(paymentMethod?: PaymentMethod): ReactNode {
  if (!paymentMethod) {
    return;
  }

  if (paymentMethod?.paymentType === "CREDIT_CARD" && paymentMethod.creditCard) {
    return getIcon(
      getCreditCardIconType(paymentMethod?.creditCard.cardType),
      "payment-method-icon"
    );
  } else {
    return <img src={zcardIcon} className="payment-method-icon" alt="ZCard" />;
  }
}

export function getPaymentMethods(): AxiosPromise<GetPaymentMethodsResponse> {
  return axios({
    method: "GET",
    url: PAYMENT_METHODS,
  });
}

export function getCreditCard(paymentMethodId: number): AxiosPromise<GetCreditCardResponse> {
  return axios({
    method: "GET",
    url: CREDIT_CARD(paymentMethodId),
  });
}

export function getGiftCard(paymentMethodId: number): AxiosPromise<GetZCardResponse> {
  return axios({
    method: "GET",
    url: GIFT_CARD(paymentMethodId),
  });
}

export function addCreditCard(
  request: CreditCardFormValues
): AxiosPromise<GetPaymentMethodResponse> {
  const jwt = getPinAuthToken();
  return axios({
    method: "POST",
    url: CREDIT_CARD_POST,
    data: { creditCard: request, jwt: jwt?.jwtToken || "" },
    displayErrorInModal: true,
  });
}

export function buyGiftCard(request: BuyZCardRequest): AxiosPromise<GetPaymentMethodResponse> {
  const jwt = getPinAuthToken();
  if (jwt) {
    request.jwt = jwt.jwtToken;
  }
  return axios({
    method: "POST",
    url: GIFT_CARD_BUY,
    data: request,
    displayErrorInModal: true,
  });
}

export function postGiftCard(request: ZCard): AxiosPromise<GetPaymentMethodResponse> {
  const jwt = getPinAuthToken();
  return axios({
    method: "POST",
    url: GIFT_CARD_POST,
    data: { zCard: request, jwt: jwt?.jwtToken },
    displayErrorInModal: true,
  });
}

export function updateGiftCard(request: ZCard): AxiosPromise<GetPaymentMethodResponse> {
  return axios({
    method: "PUT",
    url: UPDATE_GIFT_CARD(),
    data: { zCard: request, jwt: getPinAuthToken()?.jwtToken } as UpdateZCardRequest,
    displayErrorInModal: true,
  });
}

export function updateCreditCard(
  paymentMethodId: number,
  cc: CreditCardFormValues
): AxiosPromise<GetPaymentMethodResponse> {
  const jwt = getPinAuthToken();
  const request = { creditCard: cc, jwt: jwt?.jwtToken || "" };
  return axios({
    method: "PUT",
    url: CREDIT_CARD(paymentMethodId),
    data: request,
    displayErrorInModal: true,
  });
}

export function addFundsGiftCard(
  request: ZCardAddFundsRequest
): AxiosPromise<ZCardAddFundsRequest> {
  return axios({
    method: "PUT",
    url: GIFT_CARD_ADD_FUNDS,
    data: request,
    displayErrorInModal: true,
  });
}

export function deletePaymentMethod(paymentMethodId: number): AxiosPromise<null> {
  return axios({
    method: "DELETE",
    url: PAYMENT_METHOD_DELETE(paymentMethodId),
    data: getPinAuthToken()?.jwtToken,
    displayErrorInModal: true,
  });
}

export function mobilePaymentRestrictionsExist(
  purchaseOrder?: PurchaseOrder,
  mobilePayType?: MobilePayType
): boolean {
  if (
    purchaseOrder !== undefined &&
    purchaseOrder.paymentRestrictions !== undefined &&
    purchaseOrder.paymentRestrictions?.length > 0 &&
    mobilePayType !== undefined
  ) {
    const containsMobilePaymentRestrictions = purchaseOrder?.paymentRestrictions.filter(
      (restriction) => restriction["paymentType"] === mobilePayType
    );

    return containsMobilePaymentRestrictions?.length > 0;
  }

  return false;
}
