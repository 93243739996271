import { Formik, FormikProps } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import "./PinEntry.scss";

import PinInput from "components/Account/MyWallet/Pin/PinInput/PinInput";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import {
  INCORRECT_PIN,
  USER_HAS_PERMANENT_LOCK,
  USER_HAS_TEMPORARY_LOCK,
  verifyUserPin,
} from "util/Pin.util";
import { setPinAuthToken } from "util/Storage.util";

export interface PinFormValues {
  pinEntryOne: string;
  pinEntryTwo: string;
  pinEntryThree: string;
  pinEntryFour: string;
}

interface PinEntryProps {
  verifyPinCallback: (authenticated: boolean) => void;
}

const PinEntry = (props: PinEntryProps) => {
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showPinLockoutSheet, setShowPinLockoutSheet] = useState<boolean>(false);
  const [isLockoutPermanent, setIsLockoutPermanent] = useState<boolean>(false);
  const navigate = useNavigate();

  const initialValues: PinFormValues = {
    pinEntryOne: "",
    pinEntryTwo: "",
    pinEntryThree: "",
    pinEntryFour: "",
  };

  const validationSchema = Yup.object({
    pinEntryOne: Yup.string().required(""),
    pinEntryTwo: Yup.string().required(""),
    pinEntryThree: Yup.string().required(""),
    pinEntryFour: Yup.string().required(""),
  });

  const pinLockoutActionSheet = (
    <ActionSheet
      color={ActionSheetColor.error}
      title=""
      text={getLockoutMessage()}
      shouldDisplay={showPinLockoutSheet}
      overlay={true}
      required
    >
      <div className="pin-entry-error-action-sheet-container">
        <div className="pin-entry-error-message">
          {isLockoutPermanent ? USER_HAS_PERMANENT_LOCK : USER_HAS_TEMPORARY_LOCK}
        </div>
        <div className="button-row">
          <SheetzButton
            className="submit-button"
            type="button"
            onClick={(): void => {
              navigate("/order");
            }}
            label="Return to Home"
          />
        </div>
      </div>
    </ActionSheet>
  );

  function getLockoutMessage(): string {
    if (isLockoutPermanent) {
      return "YOU'VE BEEN PERMANENTLY LOCKED OUT";
    } else {
      return "YOU'VE BEEN TEMPORARILY LOCKED OUT";
    }
  }

  function verifyPin(pin: string): void {
    verifyUserPin(pin)
      .then((response) => {
        setPinAuthToken(response.data.jwtToken);
        setHasErrors(false);
        props.verifyPinCallback(true);
      })
      .catch((error) => {
        setHasErrors(true);
        if (error.response?.data?.message && error.response?.data?.message === INCORRECT_PIN) {
          setErrorMessage(error.response?.data?.message);
        } else if (
          error.response?.data?.message &&
          error.response.data.message === USER_HAS_TEMPORARY_LOCK
        ) {
          setIsLockoutPermanent(false);
          setShowPinLockoutSheet(true);
        } else if (
          error.response?.data?.message &&
          error.response?.data?.message === USER_HAS_PERMANENT_LOCK
        ) {
          setIsLockoutPermanent(true);
          setShowPinLockoutSheet(true);
        }
      });
  }

  return (
    <>
      <div className="enter-pin-container">
        <div className="enter-pin-body">
          <div className="enter-memorable-label">
            Enter your <span className="four-digit-label">4-digit PIN.</span>
          </div>
          <div className="error-message">{hasErrors && <span>{errorMessage}</span>}</div>
          {
            <Formik
              initialValues={initialValues}
              onSubmit={(values: PinFormValues): void => {
                const pin =
                  values.pinEntryOne +
                  values.pinEntryTwo +
                  values.pinEntryThree +
                  values.pinEntryFour;

                verifyPin(pin);
              }}
              validationSchema={validationSchema}
            >
              {(formikProps: FormikProps<PinFormValues>) => (
                <form>
                  <PinInput />

                  <div className="pin-entry-need-help-container">
                    <div>Need Help? Call Customer Support:</div>
                    <div>1-800-765-4686</div>
                  </div>

                  <div className="button-row">
                    <div className="pin-buttons">
                      <button
                        className="pin-back-button"
                        onClick={(): void => props.verifyPinCallback(false)}
                      >
                        Back
                      </button>
                    </div>
                    <div className="pin-buttons">
                      <div className="pin-continue-button">
                        <SheetzButton
                          className="submit-button"
                          buttonColor={ButtonColor.payGreen}
                          type="button"
                          onClick={(): void => {
                            formikProps.handleSubmit();
                          }}
                          label="Continue"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          }
        </div>
      </div>
      {showPinLockoutSheet && pinLockoutActionSheet}
    </>
  );
};

export default PinEntry;
