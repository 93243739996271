import React, { ReactElement, useEffect } from "react";

import { Condiment, Selector } from "assets/dtos/anywhere-dto";

import CondimentGrid from "components/Order/ItemCustomization/CondimentGrid/CondimentGrid";
import CustomizationOption from "components/Order/ItemCustomization/CustomizationOption/CustomizationOption";
import SelectorHeader from "components/Order/ItemCustomization/SelectorHeader/SelectorHeader";
import InlineSubSelector from "components/Order/ItemCustomization/Selectors/InlineSubSelector/InlineSubSelector";
import { setInlineSubSelectorRowPlacement } from "components/Order/ItemCustomization/Selectors/InlineSubSelector/InlineSubSelector.util";

import { useMediaQuery } from "hooks";

import { desktopMediaQuery } from "util/AppContext.util";
import { ExtraableOption } from "util/Customization.util";

interface DoubleableCondimentSelectorProps {
  selector: Selector;
  options: ExtraableOption[];
  selectedOption?: ExtraableOption;
  onOptionSelected: (singleSelectOption: ExtraableOption) => void;
  onOptionDeselected: () => void;
  onExtraSelected: () => void;
  index: number;
}

const DoubleableCondimentSelector = (props: DoubleableCondimentSelectorProps): ReactElement => {
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);
  const gridRef = React.createRef<HTMLDivElement>();
  const inlineSubSelectorRef = React.createRef<HTMLDivElement>();

  const options = props.options.map((doubleableOption) => {
    const isSelected =
      props.selectedOption &&
      doubleableOption.condiment.retailModifiedItem.retailModifiedItemId ===
        props.selectedOption.condiment.retailModifiedItem.retailModifiedItemId;
    return (
      <CustomizationOption
        key={doubleableOption.option.text}
        option={doubleableOption.option}
        condiment={doubleableOption.condiment}
        type={props.selector.type}
        isSelected={isSelected}
        onCondimentOptionSelected={condimentOptionSelected}
      />
    );
  });

  function condimentOptionSelected(condiment: Condiment): void {
    // If the newly selected condiment is the same as the currently selected condiment, then remove it.
    if (
      props.selectedOption?.condiment.retailModifiedItem.retailModifiedItemId ===
      condiment.retailModifiedItem.retailModifiedItemId
    ) {
      props.onOptionDeselected();
      return;
    }

    const newlySelectedOption = props.options.find(
      (option) =>
        option.condiment.retailModifiedItem.retailModifiedItemId ===
        condiment.retailModifiedItem.retailModifiedItemId
    );

    if (newlySelectedOption) {
      props.onOptionSelected(newlySelectedOption);
    }
  }

  useEffect(() => {
    setInlineSubSelectorRowPlacement(
      useDesktopView,
      gridRef.current,
      inlineSubSelectorRef.current,
      props.options,
      props.selectedOption
    );
  }, [gridRef, inlineSubSelectorRef, props.options, props.selectedOption, useDesktopView]);

  return (
    <div className="doubleable-condiment-selector">
      <SelectorHeader
        headerText={props.selector.text ?? ""}
        maxSelections={1}
        required={props.selector.optional === false}
        selected={!!props.selectedOption}
      />
      <CondimentGrid ref={gridRef}>
        {options}
        {props.selectedOption && props.selectedOption.extraCondiment && (
          <InlineSubSelector
            ref={inlineSubSelectorRef}
            condiment={props.selectedOption.extraCondiment}
            selected={!!props.selectedOption.extraOptionSelected}
            onInlineSubSelectorSelected={(): void => props.onExtraSelected()}
          />
        )}
      </CondimentGrid>
    </div>
  );
};

export default DoubleableCondimentSelector;
