import qs from "qs";
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

const UniversalLinks = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  function redirectOrders() {
    if (queryParams.page) {
      return <Navigate to={"/order/" + queryParams.page} replace />;
    }

    return <Navigate to="/order/information" replace />;
  }

  return (
    <>
      <Routes>
        <Route path="accountsettings" element={<Navigate to="/account/settings" replace />} />
        <Route
          path="accountsettings/21optin"
          element={<Navigate to="/account/settings/preferences/twentyOnePlus" replace />}
        />
        <Route
          path="accountsettings/drivertype"
          element={<Navigate to="/account/settings/driverType" replace />}
        />
        <Route
          path="accountsettings/personalinfo"
          element={<Navigate to="/account/settings/personalInformation" replace />}
        />
        <Route
          path="accountsettings/smsoptin"
          element={<Navigate to="/account/settings/preferences/notifications" replace />}
        />
        <Route path="ageverify" element={<Navigate to="/account/verifyAge" replace />} />
        <Route path="buygift" element={<Navigate to="/account/addPaymentMethod" replace />} />
        <Route path="findsheetz" element={<Navigate to="/findASheetz" replace />} />
        <Route path="home" element={<Navigate to="/" replace />} />
        <Route path="loyalty" element={<Navigate to="/account/mySheetzCards" replace />} />
        <Route path="more" element={<Navigate to="/feature-not-available" replace />} />
        <Route path="offers" element={<Navigate to="/account/myOfferz" replace />} />
        <Route path="order" element={redirectOrders()} />
        <Route path="pay" element={<Navigate to="/account/myWallet" replace />} />
        <Route path="rewards" element={<Navigate to="/account/rewardz" replace />} />
        <Route path="scango" element={<Navigate to="/feature-not-available" replace />} />
        <Route path="sendgift" element={<Navigate to="/feature-not-available" replace />} />
        <Route path="subz" element={<Navigate to="/feature-not-available" replace />} />
        <Route path="unlockkiosk" element={<Navigate to="/feature-not-available" replace />} />
        <Route path="voucher" element={<Navigate to="/feature-not-available" replace />} />

        <Route path="/*" element={<Navigate to="/page-not-found" replace />} />
      </Routes>
    </>
  );
};

export default UniversalLinks;
