import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./OrderPickupLocation.scss";

import { ItemEvent, PickupLocation } from "assets/dtos/anywhere-dto";

import { OrderSubviewProps } from "components/Order/Order";
import OrderConfiguration from "components/Order/OrderConfiguration/OrderConfiguration";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import FlexContainer from "components/misc/containers/FlexContainer";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import PathListItem from "components/misc/list/PathListItem/PathListItem";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { getAppStoreURL, isInNativeMobileContext } from "util/MobileApp.util";
import { orderStartedEvent } from "util/Order.util";

const OrderPickupLocation = (props: OrderSubviewProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as {
    event: ItemEvent;
    homepageBannerRedirect: string;
    redirectOnOrderFlowFinish: string;
  };
  const [showCurbideRequirements, setShowCurbideRequirements] = useState(false);

  function pickupLocationSelected(location: PickupLocation): void {
    props.dispatch({
      type: "SET_PICKUP_LOCATION",
      payload: location,
    });
    if (
      locationState &&
      (!!locationState.redirectOnOrderFlowFinish ||
        !!locationState.homepageBannerRedirect ||
        !!locationState.event)
    ) {
      // If the user is to be taken back to checkout, continue to the flow to payment type.
      if (locationState.redirectOnOrderFlowFinish === "/order/confirm") {
        navigate("/order/paymentType", {
          state: {
            redirectOnOrderFlowFinish: locationState.redirectOnOrderFlowFinish,
          },
        });
      } else if (locationState.homepageBannerRedirect) {
        navigate(locationState.homepageBannerRedirect, { state: locationState });
      } else {
        navigate(locationState.redirectOnOrderFlowFinish, { state: locationState });
      }
    } else {
      navigate("/order/menu");
    }
  }

  function getCurbsideSubtitle(): string | undefined {
    if (!isInNativeMobileContext()) {
      return "Curbside only available on the Sheetz Mobile App";
    } else if (!props.orderSession.curbsideOpen) {
      return props.orderSession.curbsideNextTimeOpen;
    } else {
      return undefined;
    }
  }

  function curbsideSelected(): void {
    if (!isInNativeMobileContext()) {
      setShowCurbideRequirements(true);
    } else if (props.orderSession.curbsideOpen) {
      pickupLocationSelected("CURBSIDE");
    }
  }

  return (
    <>
      <FlexContainer flexStyles={{ flexDirection: "column", height: "100%" }}>
        <OrderConfiguration dispatch={props.dispatch} orderSession={props.orderSession} />
        <div className="pickup-location-container">
          <ResponsiveLayoutContainer>
            <p className="heading">Pick up Location</p>
            <ul>
              <li
                className={
                  props.orderSession.inStorePickup ? "in-store-pickup" : "disabled in-store-pickup"
                }
                onClick={(): false | void => {
                  if (props.orderSession.inStorePickup) {
                    pickupLocationSelected("IN_STORE");
                  }
                  orderStartedEvent(props.orderSession, props.dispatch, "IN_STORE");
                }}
              >
                <PathListItem
                  title="In-store"
                  subtitle={
                    props.orderSession.inStorePickup ? undefined : "In-store Pickup Unavailable"
                  }
                />
              </li>

              {props.orderSession.curbsideOffered && (
                <li
                  className={
                    props.orderSession.curbsideOpen && isInNativeMobileContext()
                      ? "curbside"
                      : "disabled curbside"
                  }
                  onClick={(): void => {
                    curbsideSelected();
                    orderStartedEvent(props.orderSession, props.dispatch, "CURBSIDE");
                  }}
                >
                  <PathListItem title="Curbside" subtitle={getCurbsideSubtitle()} />
                </li>
              )}
              {props.orderSession.driveThruAvailable && (
                <li
                  onClick={(): void => {
                    pickupLocationSelected("DRIVE_THRU");
                    orderStartedEvent(props.orderSession, props.dispatch, "DRIVE_THRU");
                  }}
                >
                  <PathListItem title="Drive-Thru" />
                </li>
              )}
            </ul>
          </ResponsiveLayoutContainer>
        </div>
      </FlexContainer>
      <ActionSheet
        color={ActionSheetColor.red}
        title="Mobile App Required"
        shouldDisplay={showCurbideRequirements}
        required
        overlay={true}
      >
        <div className="mobile-app-required-action-sheet">
          <p className="action-sheet-message">
            Sheetz Mobile App is required to successfully pick up your curbside order.
          </p>
          <SheetzButton
            buttonColor={ButtonColor.darkGray}
            transparentButton
            className="action-sheet-button"
            label="Change pickup"
            onClick={(): void => setShowCurbideRequirements(false)}
          />
          <SheetzButton
            buttonColor={ButtonColor.darkGray}
            transparentButton
            className="action-sheet-button"
            label="Download the app now!"
            onClick={(): ReturnType<typeof window.open> => window.open(getAppStoreURL(), "_blank")}
          />
        </div>
      </ActionSheet>
    </>
  );
};

export default OrderPickupLocation;
