import { Field } from "formik";
import React, { ReactElement } from "react";
import "./SheetzTextArea.scss";

interface SheetzTextAreaProps {
  numberOfRows?: number;
  maxLength?: number;
  placeholderText: string;
  name: string;
}

const SheetzTextArea = (props: SheetzTextAreaProps): ReactElement => {
  return (
    <Field
      className="sheetz-text-area"
      as="textarea"
      rows={props.numberOfRows !== undefined ? props.numberOfRows.toString() : "5"}
      maxLength={props.maxLength?.toString()}
      placeholder={props.placeholderText}
      name={props.name}
    />
  );
};

export default SheetzTextArea;
