import { FormikHelpers } from "formik";
import React, { ReactElement, useContext } from "react";
import { useNavigate } from "react-router-dom";

import "./AddNewAddress.scss";

import DeliveryAddressForm from "components/Order/Delivery/DeliveryAddressForm/DeliveryAddressForm";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { AddNewAddressFormValues, createDeliveryAddress } from "util/Order.util";

const AddNewAddress = (): ReactElement => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  function saveNewAddress(
    values: AddNewAddressFormValues,
    formikHelpers: FormikHelpers<AddNewAddressFormValues>,
    verifyAddress: boolean
  ): void {
    appContext.showLoading();
    createDeliveryAddress({
      ...values,
      bypassDeliveryEstimate: true,
      verified: verifyAddress,
    })
      .then(() => {
        appContext.showToast("Address saved successfully!", "", ToastType.success);
        navigate(-1);
      })
      .catch(() => formikHelpers.setSubmitting(false))
      .finally(() => {
        appContext.hideLoading();
      });
  }

  return (
    <div className="add-new-address-container">
      <DeliveryAddressForm submitCallback={saveNewAddress} />
    </div>
  );
};

export default AddNewAddress;
