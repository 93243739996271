import { FormikErrors, FormikValues, getIn, setIn } from "formik";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { ValidationError } from "yup";

import "./PasswordChecklist.scss";

import { ReactComponent as IconCircle } from "assets/icons/loader_circle.svg";

import { IconType, getIcon } from "util/Icon.util";

// This password checklist relies on getting back a list of errors from Yup, processed with the below function.
// Formik doesn't natively support multiple errors for a single field, so we need to concatenate them together.

interface ChecklistProps {
  trackChanges: boolean;
  passwordError: string;
  validateCurrentPassword: boolean;
}

// This is an edited version of Formik's yupToFormErrors function, except this appends the error messages together for password if there are multiple errors for the same field
export function processValidationErrors(yupError: ValidationError): FormikErrors<FormikValues> {
  let errors: FormikErrors<FormikValues> = {};
  if (yupError.inner) {
    if (yupError.inner.length === 0 && yupError.path !== undefined) {
      return setIn(errors, yupError.path, yupError.message);
    }
    for (const err of yupError.inner) {
      if (err.path !== undefined && !getIn(errors, err.path)) {
        errors = setIn(errors, err.path, err.message);
      } else {
        if (err.path === "password") {
          const message = errors[err.path];
          errors = setIn(errors, err.path, message + "; " + err.message);
        }
      }
    }
  }
  return errors;
}

enum ChecklistIndicatorToggle {
  neutral,
  success,
  fail,
}

interface ChecklistIndicatorProps {
  toggle: ChecklistIndicatorToggle;
}

const ChecklistIndicator = (props: ChecklistIndicatorProps): ReactElement => {
  const IndicatorIcon = (toggle: ChecklistIndicatorToggle): ReactElement => {
    switch (toggle) {
      case ChecklistIndicatorToggle.success:
        return (
          <>
            <IconCircle className="indicator-circle success" />
            {getIcon(IconType.check, "indicator-icon")}
          </>
        );
      case ChecklistIndicatorToggle.fail:
        return (
          <>
            <IconCircle className="indicator-circle fail" />
            {getIcon(IconType.x, "indicator-icon")}
          </>
        );
      case ChecklistIndicatorToggle.neutral:
      default:
        return <IconCircle className="indicator-circle" />;
    }
  };

  return <span className={"checklist-indicator "}>{IndicatorIcon(props.toggle)}</span>;
};

const PasswordChecklist: FC<ChecklistProps> = (props) => {
  const [lengthError, setLengthError] = useState<ChecklistIndicatorToggle>(
    ChecklistIndicatorToggle.neutral
  );
  const [validError, setValidError] = useState<ChecklistIndicatorToggle>(
    ChecklistIndicatorToggle.neutral
  );
  const [uppercaseError, setUppercaseError] = useState<ChecklistIndicatorToggle>(
    ChecklistIndicatorToggle.neutral
  );
  const [lowercaseError, setLowercaseError] = useState<ChecklistIndicatorToggle>(
    ChecklistIndicatorToggle.neutral
  );
  const [numberError, setNumberError] = useState<ChecklistIndicatorToggle>(
    ChecklistIndicatorToggle.neutral
  );
  const [specialError, setSpecialError] = useState<ChecklistIndicatorToggle>(
    ChecklistIndicatorToggle.neutral
  );
  const [differentThanCurrentError, setDifferentThanCurrentError] =
    useState<ChecklistIndicatorToggle>(ChecklistIndicatorToggle.neutral);
  useEffect(() => {
    if (props.trackChanges) {
      setLengthError(
        props.passwordError.includes("8") || props.passwordError.includes("256")
          ? ChecklistIndicatorToggle.fail
          : ChecklistIndicatorToggle.success
      );
      setValidError(
        props.passwordError.includes("Invalid")
          ? ChecklistIndicatorToggle.fail
          : ChecklistIndicatorToggle.success
      );
      setUppercaseError(
        props.passwordError.includes("uppercase")
          ? ChecklistIndicatorToggle.fail
          : ChecklistIndicatorToggle.success
      );
      setLowercaseError(
        props.passwordError.includes("lowercase")
          ? ChecklistIndicatorToggle.fail
          : ChecklistIndicatorToggle.success
      );
      setNumberError(
        props.passwordError.includes("number")
          ? ChecklistIndicatorToggle.fail
          : ChecklistIndicatorToggle.success
      );
      setSpecialError(
        props.passwordError.includes("special")
          ? ChecklistIndicatorToggle.fail
          : ChecklistIndicatorToggle.success
      );
      setDifferentThanCurrentError(
        props.passwordError.includes("different")
          ? ChecklistIndicatorToggle.fail
          : ChecklistIndicatorToggle.success
      );
    } else {
      setLowercaseError(ChecklistIndicatorToggle.neutral);
      setUppercaseError(ChecklistIndicatorToggle.neutral);
      setSpecialError(ChecklistIndicatorToggle.neutral);
      setLengthError(ChecklistIndicatorToggle.neutral);
      setNumberError(ChecklistIndicatorToggle.neutral);
      setValidError(ChecklistIndicatorToggle.neutral);
      setDifferentThanCurrentError(ChecklistIndicatorToggle.neutral);
    }
  }, [props.trackChanges, props.passwordError]);

  return (
    <div className="password-checklist">
      <div className="password-criteria">
        <ChecklistIndicator toggle={lengthError} />
        Length Between 8-256 characters
      </div>
      <div className="password-criteria">
        <ChecklistIndicator toggle={uppercaseError} />
        At least one Uppercase letter
      </div>
      <div className="password-criteria">
        <ChecklistIndicator toggle={lowercaseError} />
        At least one Lowercase letter
      </div>
      <div className="password-criteria">
        <ChecklistIndicator toggle={numberError} />
        At least one number
      </div>
      <div className="password-criteria">
        <ChecklistIndicator toggle={specialError} />
        At least one special character ( @!#$%^&*()_`&lt;&gt; )
      </div>
      <div className="password-criteria">
        <ChecklistIndicator toggle={validError} />
        No invalid characters
      </div>
      {props.validateCurrentPassword && (
        <div className="password-criteria">
          <ChecklistIndicator toggle={differentThanCurrentError} />
          Different than current password
        </div>
      )}
    </div>
  );
};

export default PasswordChecklist;
