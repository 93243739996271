import React, { ReactElement } from "react";

import "./OrderDeliveryAddress.scss";

import { DeliveryAddress } from "assets/dtos/anywhere-dto";

interface OrderDeliveryAddressProps {
  deliveryAddress?: DeliveryAddress;
}

const OrderDeliveryAddress = (props: OrderDeliveryAddressProps): ReactElement | null => {
  if (props.deliveryAddress === undefined) {
    return null;
  }

  return (
    <div className="delivery-address">
      <h1>{props.deliveryAddress?.alias}</h1>
      <p className="delivery-address-street">{props.deliveryAddress?.street}</p>
      {props.deliveryAddress?.unit !== undefined && (
        <p className="delivery-address-unit">{props.deliveryAddress?.unit}</p>
      )}
      <p className="delivery-city-state-zip">
        {props.deliveryAddress?.city}, {props.deliveryAddress?.stateCode}{" "}
        {props.deliveryAddress?.zipCode}
      </p>
    </div>
  );
};

export default OrderDeliveryAddress;
