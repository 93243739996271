import { Formik, FormikErrors, FormikProps } from "formik";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";

import "./PickupTimeSelector.scss";

import { OrderSubviewProps } from "components/Order/Order";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import { SelectOption } from "components/misc/form/SheetzInput/SheetzInput";
import SheetzSelect from "components/misc/form/SheetzSelect/SheetzSelect";

import { AppContext } from "util/AppContext.util";
import { getEarliestDateTimeForDeferredOrder, getServerTime } from "util/Order.util";
import {
  PickUpDay,
  PickUpDayKey,
  PickUpHour,
  PickUpHourKey,
  PickUpMinute,
  PickUpMinuteKey,
  PickUpPeriod,
  PickUpPeriodKey,
  getDateTimeFromFormValues,
  getDateTimeFromString,
  getFormattedHourFromDateTime,
  getFormattedMinutesFromDateTime,
  getMeridiemFromDateTime,
  getTomorrowFromDateTime,
} from "util/Time.util";

interface PickUpTimeFormValues {
  day: PickUpDay;
  hour: PickUpHour;
  minute: PickUpMinute;
  period: PickUpPeriod;
}

interface PickupTimeSelectorProps extends OrderSubviewProps {
  onPickUpTimeSelected: (pickUpTime: string) => void;
}

const PickupTimeSelector = (props: PickupTimeSelectorProps) => {
  const appContext = useContext(AppContext);
  const [currentServerTime, setCurrentServerTime] = useState<string | undefined>(undefined);

  useEffect(() => {
    appContext.showLoading();
    getServerTime()
      .then((response) => setCurrentServerTime(response.data.serverTime))
      .finally(() => appContext.hideLoading());
    // No dependencies needed as this is a one-time call on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderSessionDateTime = currentServerTime
    ? getDateTimeFromString(currentServerTime)
    : DateTime.local();

  const earliestDeferredDateTime = getEarliestDateTimeForDeferredOrder(orderSessionDateTime);

  const daySelectOptions: SelectOption[] = Object.keys(PickUpDay).map((key): SelectOption => {
    let label: string;
    if (PickUpDay.Today === PickUpDay[key as PickUpDayKey]) {
      label = `${key} ${earliestDeferredDateTime.toLocaleString({
        month: "numeric",
        day: "numeric",
      })}`;
    } else {
      label = `${key} ${getTomorrowFromDateTime(earliestDeferredDateTime).toLocaleString({
        month: "numeric",
        day: "numeric",
      })}`;
    }

    return {
      label: label,
      value: PickUpDay[key as PickUpDayKey],
    };
  });

  const hourSelectOptions: SelectOption[] = Object.keys(PickUpHour).map((key): SelectOption => {
    const value = PickUpHour[key as PickUpHourKey];
    return { label: value, value: value };
  });

  const minuteSelectOptions: SelectOption[] = Object.keys(PickUpMinute).map((key): SelectOption => {
    const value = PickUpMinute[key as PickUpMinuteKey];
    return { label: value, value: value };
  });

  const periodSelectOptions: SelectOption[] = Object.keys(PickUpPeriod).map((key): SelectOption => {
    return {
      label: key,
      value: PickUpPeriod[key as PickUpPeriodKey],
    };
  });

  function isPickupTimeValid(values: PickUpTimeFormValues): FormikErrors<PickUpTimeFormValues> {
    const errors: FormikErrors<PickUpTimeFormValues> = {};

    const pickUpDateTime = getDateTimeFromFormValues(
      earliestDeferredDateTime,
      values.day,
      values.hour,
      values.minute,
      values.period
    );

    if (pickUpDateTime < earliestDeferredDateTime) {
      errors.day = "Please select a later time.";
    }

    return errors;
  }

  function saveTimeChosen(values: PickUpTimeFormValues): void {
    const pickUpDateTime = getDateTimeFromFormValues(
      earliestDeferredDateTime,
      values.day,
      values.hour,
      values.minute,
      values.period
    );

    props.onPickUpTimeSelected(pickUpDateTime.toString());
  }

  const initialPickUpTimeValues: PickUpTimeFormValues = {
    day: PickUpDay.Today,
    hour: getFormattedHourFromDateTime(earliestDeferredDateTime) as PickUpHour,
    minute: getFormattedMinutesFromDateTime(earliestDeferredDateTime) as PickUpMinute,
    period: getMeridiemFromDateTime(earliestDeferredDateTime) as PickUpPeriod,
  };

  return (
    <div className="pick-up-time-form">
      <Formik
        initialValues={initialPickUpTimeValues}
        validate={isPickupTimeValid}
        onSubmit={saveTimeChosen}
      >
        {(props: FormikProps<PickUpTimeFormValues>) => (
          <form onSubmit={props.handleSubmit}>
            <div className="flex-container">
              <SheetzSelect name="day" options={daySelectOptions} hideSelectArrow />
              <SheetzSelect name="hour" options={hourSelectOptions} hideSelectArrow />
              <span className="time-separator">:</span>
              <SheetzSelect name="minute" options={minuteSelectOptions} hideSelectArrow />
              <SheetzSelect name="period" options={periodSelectOptions} hideSelectArrow />
            </div>
            <p className="error">{props.errors.minute}</p>
            <SheetzButton
              className="submit-button"
              type="submit"
              label="Save & Continue"
              disabled={!props.isValid}
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PickupTimeSelector;
