import React from "react";

import {
  Condiment,
  RetailItem,
  RetailModifiedItem,
  Selector,
  SwitchDTO,
} from "assets/dtos/anywhere-dto";

import SelectorContainer from "components/Order/ItemCustomization/SelectorContainer/SelectorContainer";
import { deriveCondiments } from "components/Order/ItemCustomization/Selectors/DoubleCondimentSelector/DoubleableCondimentSelector.util";
import DoubleOrSwitchSelector from "components/Order/ItemCustomization/Selectors/DoubleableSwitchSelector/DoubleOrSwitchSelector";
import {
  deriveMultiOptionSwitchOptions,
  filterCurrentRIFromSwitches,
} from "components/Order/ItemCustomization/Selectors/SwitchSelectors/SwitchSelectors.util";
import { OrderSessionAction } from "components/Order/OrderSessionReducer";

import {
  DoubleOrSwitchOption,
  ExtraableOption,
  ItemCustomization,
  MultiOptionSwitchOption,
  handleDefaultOption,
  handleTagAlongTags,
  isCondimentInList,
} from "util/Customization.util";
import { CustomizedItem } from "util/Order.util";
import { deriveSwitch, deriveSwitchSize, getAllTags } from "util/Tag.util";

export function processDoubleOrSwitchSelector(
  selector: Selector,
  selectorState: DoubleOrSwitchOption | undefined,
  item: CustomizedItem,
  itemCustomization: ItemCustomization,
  dispatch: React.Dispatch<OrderSessionAction>,
  index: number
): JSX.Element | null {
  if (selector.switchOptions === undefined || selector.doubleOptions === undefined) {
    console.warn(`DoubleOrSwitch selector has no switch or double options: switchText='${selector.text}', 
    and itemCustomizationId='${itemCustomization.itemCustomizationId}'`);
    return null;
  }

  const existingTags = getAllTags(item);
  const switches = filterCurrentRIFromSwitches(itemCustomization.retailItem.switches, item);
  const options = deriveDoubleOrSwitchOptions(
    selector,
    existingTags,
    itemCustomization,
    item,
    switches
  );

  if (!options.length) {
    return null;
  }

  let selectedOption = selectorState;

  if (selectedOption !== undefined && "extraOption" in selectedOption) {
    if (
      selectedOption &&
      !isCondimentInList(
        options.map((option) => option.condiment),
        selectedOption.condiment
      )
    ) {
      selectedOption = undefined;
    } else if (selectedOption) {
      // Add the condiment associated with the selected option.
      item.condiments.push(selectedOption.condiment);

      // Add the extra option condiment, if it exists.
      if (selectedOption.extraOptionSelected && selectedOption.extraCondiment) {
        item.condiments.push(selectedOption.extraCondiment);
      }

      // If the selected option has tagAlongTags, then derive that condiment as well and add it to the item.
      handleTagAlongTags(selectedOption.option, itemCustomization, item);
    } else {
      handleDefaultOption(selector, existingTags, itemCustomization, item);
    }
  } else if (selectedOption !== undefined && "inlineSubOptions" in selectedOption) {
    // The existing item tags are needed as well, as the switch RIs may have tags that rely on a size (i.e. 2 pack, 3 pack).
    const switchRI = deriveSwitch(
      existingTags.concat(selectedOption.option.switchTags ?? []),
      itemCustomization,
      switches
    );

    const switchRMI = deriveSwitchRMI(item, switchRI);

    if (!switchRI || !switchRMI) {
      return null;
    }

    const selectedCondiment = selectedOption.selectedInlineSubOption
      ? selectedOption.selectedInlineSubOption.condiment
      : selectedOption.condiment;

    const switchOptions: MultiOptionSwitchOption[] = options.filter(
      (option) => "inlineSubOptions" in option
    );

    const condimentList = switchOptions.reduce<Condiment[]>((condiments, option) => {
      if (option.inlineSubOptions) {
        return condiments.concat([
          option.condiment,
          ...option.inlineSubOptions.map((inlineOption) => inlineOption.condiment),
        ]);
      } else {
        return condiments.concat([option.condiment]);
      }
    }, [] as Condiment[]);

    if (!isCondimentInList(condimentList, selectedCondiment)) {
      selectedOption = undefined;
    } else {
      item.retailItem = switchRI;
      item.retailModifiedItem = switchRMI;
      item.condiments.push(selectedCondiment);

      // If the selected option has tagAlongTags, then derive that condiment as well and add it to the item.
      handleTagAlongTags(selectedOption.option, itemCustomization, item);
    }
  }

  const optionSelected = (extraableOption: ExtraableOption): void => {
    dispatch({
      type: "SET_ITEM_CUSTOMIZATIONS",
      payload: [selector.text ?? "", extraableOption],
    });
  };

  const optionDeselected = (): void => {
    dispatch({ type: "REMOVE_CUSTOMIZATION_SELECTION", payload: selector.text ?? "" });
  };

  const extraSelected = (): void => {
    if (!selectedOption || !("extraOption" in selectedOption)) {
      return;
    }
    selectedOption.extraOptionSelected = !selectedOption.extraOptionSelected;
    dispatch({ type: "SET_ITEM_CUSTOMIZATIONS", payload: [selector.text ?? "", selectedOption] });
  };

  const switchOptionSelected = (switchOption: DoubleOrSwitchOption): void => {
    dispatch({ type: "SET_ITEM_CUSTOMIZATIONS", payload: [selector.text ?? "", switchOption] });
  };

  const switchOptionDeselected = (): void => {
    dispatch({ type: "REMOVE_CUSTOMIZATION_SELECTION", payload: selector.text ?? "" });
  };

  return (
    <SelectorContainer key={index} selector={selector}>
      <DoubleOrSwitchSelector
        selector={selector}
        options={options}
        selectedOption={selectedOption}
        onOptionSelected={optionSelected}
        onOptionDeselected={optionDeselected}
        onExtraSelected={extraSelected}
        onSwitchOptionSelected={switchOptionSelected}
        onSwitchOptionDeselected={switchOptionDeselected}
      />
    </SelectorContainer>
  );
}

function deriveDoubleOrSwitchOptions(
  selector: Selector,
  existingTags: string[],
  itemCustomization: ItemCustomization,
  item: CustomizedItem,
  switches?: SwitchDTO[]
): DoubleOrSwitchOption[] {
  const switchSelector = { ...selector, options: selector.switchOptions };
  const switchSelectorOptions = deriveMultiOptionSwitchOptions(
    switchSelector,
    existingTags,
    itemCustomization
  );

  // Calculate difference in RMI price between existing RMI for customized item, and the RMI for this switch option
  switchSelectorOptions.forEach((switchOption) => {
    const switchRI = deriveSwitch(
      existingTags.concat(switchOption.option.switchTags ?? []),
      itemCustomization,
      switches ?? []
    );

    const switchRMI = deriveSwitchRMI(item, switchRI);

    if (switchRMI && item.retailModifiedItem) {
      switchOption.priceDifference = switchRMI.price - item.retailModifiedItem.price;
    }
  });

  const doubleableSelectorOptions = deriveCondiments(selector, existingTags, itemCustomization);

  return switchSelectorOptions.concat(doubleableSelectorOptions);
}

function deriveSwitchRMI(
  item: CustomizedItem,
  switchRI: RetailItem | null
): RetailModifiedItem | undefined {
  if (item.retailModifiedItem === undefined || switchRI === null) {
    return;
  }

  // If there are no tags on the original RMI, then we can assume that there is only one RMI under the switch RI.
  if (item.retailModifiedItem.tags === undefined) {
    return switchRI.retailModifiedItems[0];
  } else {
    return deriveSwitchSize(switchRI.retailModifiedItems, item.retailModifiedItem.tags);
  }
}
