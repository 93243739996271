import React, { FC, ReactElement, useContext, useEffect, useState } from "react";

import "./MyStores.scss";

import { Store } from "assets/dtos/anywhere-dto";

import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import EmptyPage from "components/misc/indicators/EmptyPage/EmptyPage";
import ListItem from "components/misc/list/ListItem/ListItem";
import StoreDisplay from "components/misc/store/StoreDisplay/StoreDisplay";

import { AppContext } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";
import { StoreFavoriteResult, deleteStoreFavorite, getAllStoreFavorites } from "util/MyStores.util";

const trashIcon = getIcon(IconType.trash, "trash-icon");
interface MyStoresProps {
  stores: Store[];
}

const MyStores = (): ReactElement => {
  const appContext = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [stores, setStores] = useState<Store[]>();
  const showLoading = appContext.showLoading;
  const hideLoading = appContext.hideLoading;

  useEffect(() => {
    loadAllFavorites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MyStoresElements: FC<MyStoresProps> = (props) => {
    if (stores && stores.length > 0) {
      const storeElements = stores?.map((store, index) => {
        return (
          <ListItem
            key={store.storeNumber}
            hideArrow={true}
            hideBottomBorder={index + 1 === stores.length}
          >
            <div className="store-container">
              <StoreDisplay
                store={store}
                isOrderingPreferences
                showStoreNumber
                showMileage
                showStoreDistance
              />
              <div className="store-trash-can-container">
                <div
                  tabIndex={0}
                  role="button"
                  aria-pressed="false"
                  onClick={(event?): void => {
                    event?.preventDefault();
                    deleteStore(store.storeNumber);
                  }}
                  className="trash-icon-right"
                >
                  {trashIcon}
                </div>
              </div>
            </div>
          </ListItem>
        );
      });
      return <>{storeElements}</>;
    } else {
      return null;
    }
  };

  const deleteStore = (storeNumber: number): void => {
    deleteStoreFavorite(storeNumber).then(() => {
      loadAllFavorites();
    });
  };

  const loadAllFavorites = function (): void {
    setLoading(true);
    showLoading();
    getAllStoreFavorites()
      .then((response) => {
        if (response.data && response.data) {
          const storeResult: StoreFavoriteResult = response.data;
          setStores(storeResult.stores);
        }
      })
      .finally(() => {
        hideLoading();
        setLoading(false);
      });
  };

  if (stores && stores.length > 0) {
    return (
      <div className="my-stores-container">
        <ResponsiveLayoutContainer className="account-settings">
          <MyStoresElements stores={stores} />
        </ResponsiveLayoutContainer>
      </div>
    );
  } else if (!loading) {
    return (
      <div className="my-stores-container empty">
        <EmptyPage title="You have no saved stores" icon={IconType.store} />
      </div>
    );
  } else {
    return <></>;
  }
};

export default MyStores;
