import { Formik, FormikProps } from "formik";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import "./GiftCard.scss";

import { PaymentMethod, ZCard } from "assets/dtos/anywhere-dto";

import AutoReloadAction from "components/Account/MyWallet/AutoReload/AutoReloadAction/AutoReloadAction";
import { SubmitWalletActionEnum } from "components/Account/MyWallet/MyWallet";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import SheetzCheckbox from "components/misc/form/SheetzCheckbox/SheetzCheckbox";
import SheetzInput from "components/misc/form/SheetzInput/SheetzInput";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";
import { updateGiftCard } from "util/Payment.util";
import {
  giftCardPinValidation,
  giftCardValidation,
  sheetzCardNameValidation,
} from "util/Validation.util";

const trashIcon = getIcon(IconType.trash, "trash-icon");

interface GiftCardProps {
  giftCard?: PaymentMethod;
  isDefault: boolean;
  onRequestClose?: () => void;
  submitFunction: (values: ZCard, submitAction: SubmitWalletActionEnum) => void;
}

const validationSchema = Yup.object().shape({
  cardName: sheetzCardNameValidation,
  pin: giftCardPinValidation,
  cardNumber: giftCardValidation,
});

const GiftCard = (giftCardProps: GiftCardProps) => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const [showConfirmDeleteMessage, setShowConfirmDeleteMessage] = useState<boolean>(false);
  const [showAutoReloadActionSheet, setShowAutoReloadActionSheet] = useState<boolean>(false);

  // isDefault must use the value from the isDefault prop, because the server returns isDefault = false on the zCard itself, always.
  const initialFormValues: ZCard =
    giftCardProps.giftCard?.zCard !== undefined
      ? { ...giftCardProps.giftCard?.zCard, isDefault: giftCardProps.isDefault }
      : ({ isDefault: giftCardProps.isDefault, cardName: "", pin: "" } as ZCard);

  const defaultInitial = giftCardProps.isDefault;

  if (giftCardProps.giftCard?.paymentMethodId !== undefined) {
    initialFormValues.paymentMethodId = giftCardProps.giftCard?.paymentMethodId;
  }

  function GiftCardNumberElement() {
    if (giftCardProps?.giftCard?.paymentMethodId) {
      return (
        <>
          <div className={"button-row add-funds-button"}>
            <SheetzButton
              buttonColor={ButtonColor.payGreen}
              type="button"
              label="Add Funds"
              onClick={(): void => {
                navigate("/account/addFunds", {
                  state: { paymentMethodId: giftCardProps?.giftCard?.paymentMethodId },
                });
              }}
            />
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }

  const confirmGiftCardDeleteMessage = (
    <ActionSheet
      color={ActionSheetColor.red}
      title="Are you Sure?"
      shouldDisplay={showConfirmDeleteMessage}
      overlay={true}
      cancelFunction={(): void => {
        setShowConfirmDeleteMessage(false);
      }}
      required
    >
      <div className="gift-card-action-sheet-label">Are you sure you want to delete this Card?</div>
      <div className="gift-card-action-sheet-buttons">
        <div className="user-action-sheet-decline-btn-wrapper">
          <button
            className="user-action-sheet-decline-btn"
            onClick={(): void => setShowConfirmDeleteMessage(false)}
          >
            Cancel
          </button>
        </div>
        <SheetzButton
          className="gift-card-action-sheet-decline-btn"
          label="Confirm"
          onClick={(): void => {
            giftCardProps.submitFunction(initialFormValues, SubmitWalletActionEnum.Delete);
            setShowConfirmDeleteMessage(false);
          }}
        />
      </div>
    </ActionSheet>
  );

  const autoReloadActionSheet = (
    <ActionSheet
      color={ActionSheetColor.currency}
      title="ADD AUTO RELOAD?"
      text="ADD FUNDS TO THIS CARD AUTOMATICALLY!"
      shouldDisplay={showAutoReloadActionSheet}
      overlay={true}
      cancelFunction={(): void => {
        setShowAutoReloadActionSheet(false);
      }}
      required
    >
      <AutoReloadAction
        cancelCallback={closeAutoReloadActionSheet}
        continueCallback={goToAutoReload}
      ></AutoReloadAction>
    </ActionSheet>
  );

  function closeAutoReloadActionSheet(): void {
    setShowAutoReloadActionSheet(false);
  }

  function goToAutoReload(reloadAmount: number, reloadThreshold: number): void {
    setShowAutoReloadActionSheet(false);
    navigate("/account/autoReload", {
      state: {
        amount: reloadAmount,
        reloadThreshold: reloadThreshold,
        paymentMethodId: giftCardProps?.giftCard?.paymentMethodId,
      },
    });
  }

  function removeAutoReload(): void {
    const giftCard = giftCardProps.giftCard;

    if (giftCard && giftCard.zCard) {
      giftCard.zCard.autoReloadEnabled = false;
      giftCard.zCard.paymentMethodId = giftCard.paymentMethodId;

      updateGiftCard(giftCard.zCard).then(() => {
        if (giftCardProps.onRequestClose) {
          giftCardProps.onRequestClose();
        }
        appContext.showToast("Auto reload settings saved!", "", ToastType.success);
      });
    }
  }

  function AutoReloadElement(card: ZCard) {
    if (giftCardProps?.giftCard?.paymentMethodId) {
      return (
        <div className="default-payment-row auto-reload-container">
          <SheetzCheckbox
            label="Auto Reload"
            onClick={(): void =>
              !card.autoReloadEnabled ? setShowAutoReloadActionSheet(true) : removeAutoReload()
            }
            green
            checked={card.autoReloadEnabled}
          />

          <div>
            <p className="default-payment-title">
              {card.autoReloadEnabled ? "Auto Reload: Enabled" : "Auto Reload: Not Set Up"}
            </p>
            <p className="default-payment-text">
              {card.autoReloadEnabled
                ? "When amount is below: $" + card.balanceThreshold
                : "Add funds to this card automatically"}
            </p>
          </div>

          {card.autoReloadEnabled && (
            <div className="auto-reload-button-row">
              <SheetzButton
                buttonColor={ButtonColor.payGreen}
                type="button"
                label="Manage Auto Reload"
                className={"auto-reload-button"}
                onClick={(): void => {
                  setShowAutoReloadActionSheet(true);
                }}
              />
            </div>
          )}
        </div>
      );
    } else {
      return <></>;
    }
  }

  return (
    <>
      <div className="gift-card">
        <Formik
          initialValues={initialFormValues}
          onSubmit={(values: ZCard): void => {
            giftCardProps.submitFunction(values, SubmitWalletActionEnum.Save);
          }}
          validationSchema={validationSchema}
        >
          {(props: FormikProps<ZCard>) => (
            <form className="gift-card-form" onSubmit={props.handleSubmit}>
              {GiftCardNumberElement()}
              {!giftCardProps.giftCard?.paymentMethodId && (
                <SheetzInput
                  type="text"
                  name="cardNumber"
                  placeholder="Card Number"
                  autoComplete="off"
                  label="Card Number"
                  required
                />
              )}
              {giftCardProps.giftCard?.paymentMethodId && (
                <SheetzInput
                  type="text"
                  name="card-number-mask"
                  value={
                    "•••• •••• •••• •••• " + giftCardProps.giftCard?.zCard?.cardNumber.substr(-3)
                  }
                  placeholder="Card Number"
                  label="Card Number"
                  required
                />
              )}
              <div className="flex-row">
                <div className="gift-card-input-container">
                  <SheetzInput
                    type="text"
                    name="pin"
                    placeholder="Verification Code"
                    label="Verification Code"
                    autoComplete="off"
                    disabled={giftCardProps.giftCard?.paymentMethodId !== undefined}
                  />
                </div>
                <span
                  className={
                    props.touched.pin && props.errors.pin
                      ? "gift-card-placeholder gift-card-label-invalid"
                      : "gift-card-placeholder"
                  }
                >
                  4 digit code next to card number
                </span>
              </div>
              <div className="flex-row">
                <div className="gift-card-input-container">
                  <SheetzInput
                    type="text"
                    name="cardName"
                    placeholder={
                      giftCardProps?.giftCard?.paymentMethodId ? "Card Name" : "Name this card"
                    }
                    label={
                      giftCardProps?.giftCard?.paymentMethodId ? "Card Name" : "Name this card"
                    }
                  />
                </div>
                <span
                  className={
                    !props.errors.cardName
                      ? "gift-card-placeholder"
                      : "gift-card-placeholder gift-card-label-invalid"
                  }
                >
                  Optional
                </span>
              </div>

              <>
                <SheetzCheckbox
                  green
                  label="Default Payment Method"
                  name="isDefault"
                  disabled={giftCardProps.giftCard?.paymentMethodId !== undefined && defaultInitial}
                />
              </>

              <div className="default-payment-row">
                {giftCardProps?.giftCard?.isDefault && (
                  <>
                    <p className="default-payment-text">
                      A default card cannot be removed unless another payment method is default
                    </p>
                  </>
                )}
              </div>

              {AutoReloadElement(props.values)}

              <div className="button-row save-button">
                <SheetzButton
                  className="submit-button"
                  buttonColor={ButtonColor.payGreen}
                  type="submit"
                  label={
                    giftCardProps?.giftCard?.paymentMethodId != null
                      ? "Update Gift Card"
                      : "Add Gift Card"
                  }
                />
              </div>
              {giftCardProps?.giftCard?.paymentMethodId != null &&
                giftCardProps.giftCard.zCard?.balance === 0 && (
                  <div className="button-row delete-gift-card-button-container">
                    <div
                      tabIndex={0}
                      role="button"
                      aria-pressed="false"
                      onClick={(event?): void => {
                        event?.preventDefault();
                        setShowConfirmDeleteMessage(true);
                      }}
                      className="delete-gift-card-button"
                    >
                      {trashIcon}
                      <p className="delete-gift-card-button-label">Delete Card</p>
                    </div>
                  </div>
                )}
            </form>
          )}
        </Formik>
      </div>
      {showConfirmDeleteMessage && confirmGiftCardDeleteMessage}
      {showAutoReloadActionSheet && autoReloadActionSheet}
    </>
  );
};

export default GiftCard;
