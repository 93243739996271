import React, { useEffect, useState } from "react";

import "./TwentyOnePlus.scss";

import { UpdateAgeRestrictedStatusRequest } from "assets/dtos/anywhere-dto";

import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import SheetzToggle from "components/misc/form/SheetzToggle/SheetzToggle";
import PathListItem from "components/misc/list/PathListItem/PathListItem";

import { TwentyOnePlusOptInPreferences } from "util/Account.util";
import { setPageTitleForMobileHeader } from "util/MobileApp.util";

interface TwentyOnePlusProps {
  twentyOnePlusOptInPreferences: TwentyOnePlusOptInPreferences;
  updateTwentyOnePlusPreferences: (request: UpdateAgeRestrictedStatusRequest) => void;
}

const TwentyOnePlus = (props: TwentyOnePlusProps) => {
  const [twentyOnePlusOptInPreferences, setTwentyOnePlusOptInPreferences] =
    useState<TwentyOnePlusOptInPreferences>(props.twentyOnePlusOptInPreferences);

  setPageTitleForMobileHeader("21+ Offers");

  useEffect(() => {
    setTwentyOnePlusOptInPreferences(props.twentyOnePlusOptInPreferences);
  }, [props.twentyOnePlusOptInPreferences]);

  function addTwentyOnePlusOptInPreferences(values: TwentyOnePlusOptInPreferences): void {
    const request: UpdateAgeRestrictedStatusRequest = {
      alcoholOffers: values.isAlcoholOffersEnabled,
      tobaccoOffers: values.isTobaccoOffersEnabled,
    };

    setTwentyOnePlusOptInPreferences(values);

    props.updateTwentyOnePlusPreferences(request);
  }

  const alcoholOffersToggle = (
    <div className="toggle">
      <SheetzToggle
        clickHandler={() =>
          addTwentyOnePlusOptInPreferences({
            ...twentyOnePlusOptInPreferences,
            isAlcoholOffersEnabled: !twentyOnePlusOptInPreferences.isAlcoholOffersEnabled,
          })
        }
        checked={twentyOnePlusOptInPreferences.isAlcoholOffersEnabled}
      />
    </div>
  );

  const tobaccoOffersToggle = (
    <div className="toggle">
      <SheetzToggle
        clickHandler={() =>
          addTwentyOnePlusOptInPreferences({
            ...twentyOnePlusOptInPreferences,
            isTobaccoOffersEnabled: !twentyOnePlusOptInPreferences.isTobaccoOffersEnabled,
          })
        }
        checked={twentyOnePlusOptInPreferences.isTobaccoOffersEnabled}
      />
    </div>
  );

  return (
    <div className="twenty-one-plus-container">
      <ResponsiveLayoutContainer className="account-settings">
        <p className="offer-text">
          Show me recommendations, dealz, offerz, and new product alerts throughout the Sheetz App
          for the following 21+ products.
        </p>

        <PathListItem title="Alcohol" hideArrow rightButtonContent={alcoholOffersToggle} />
        <PathListItem
          title="Tobacco"
          hideArrow
          hideBottomBorder
          rightButtonContent={tobaccoOffersToggle}
        />
      </ResponsiveLayoutContainer>
    </div>
  );
};

export default TwentyOnePlus;
