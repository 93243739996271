import React, { Dispatch, SetStateAction } from "react";

import { Option, Selector } from "assets/dtos/anywhere-dto";

import SelectorContainer from "components/Order/ItemCustomization/SelectorContainer/SelectorContainer";
import SizeSelector from "components/Order/ItemCustomization/Selectors/SizeSelector/SizeSelector";
import { OrderSessionAction } from "components/Order/OrderSessionReducer";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import {
  ItemCustomization,
  ItemCustomizationSelections,
  SizeSelectOption,
  findSizeSelector,
  handleTagAlongTags,
} from "util/Customization.util";
import { CustomizedItem } from "util/Order.util";
import { deriveSize } from "util/Tag.util";

export function deriveSizeOptions(
  options: Option[],
  itemCustomization: ItemCustomization
): SizeSelectOption[] {
  // First, filter out any size options that can't derive a size (can be due to recalls, authoring errors, etc.).
  const derivableOptions = options.filter(
    (option) => deriveSize(option.sizeTags ?? [], itemCustomization) !== undefined
  );
  const sizeSelectOptions = derivableOptions.map((option) => {
    return {
      option: option,
      retailModifiedItem: deriveSize(option.sizeTags ?? [], itemCustomization),
    } as SizeSelectOption;
  });
  return sizeSelectOptions;
}

/**
 * This function is used for managing/displaying the size selector as an action sheet from the Menu or when an AIU was selected within customization.
 */
export function createSizeSelector(
  cancelFunction: () => void,
  displayActionSheet: boolean,
  onSizeSelected: (sizeOption: SizeSelectOption) => void,
  itemCustomization?: ItemCustomization,
  closeCallback?: Dispatch<SetStateAction<boolean>>
): React.ReactNode {
  let sizeSelector: JSX.Element | null;
  let selector: Selector | undefined;

  if (!itemCustomization) {
    sizeSelector = null;
  } else {
    selector = findSizeSelector(itemCustomization.template);
  }

  if (!selector || !selector.options || !itemCustomization || selector.type !== "SIZE") {
    return;
  } else {
    const sizeSelectOptions = deriveSizeOptions(selector.options, itemCustomization);
    sizeSelector = (
      <SizeSelector
        selector={selector}
        sizeSelectOptions={sizeSelectOptions}
        itemCustomization={itemCustomization}
        onSizeOptionSelected={(sizeOption: SizeSelectOption): void => onSizeSelected(sizeOption)}
      />
    );
  }

  const actionSheet = itemCustomization ? (
    <ActionSheet
      color={ActionSheetColor.red}
      title={selector?.text ?? ""}
      shouldDisplay={!!displayActionSheet}
      cancelFunction={cancelFunction}
      closeCallback={closeCallback}
      overlay={true}
    >
      {sizeSelector}
    </ActionSheet>
  ) : (
    <></>
  );

  return actionSheet;
}

export function processSizeSelector(
  selector: Selector,
  item: CustomizedItem,
  itemCustomization: ItemCustomization,
  dispatch: React.Dispatch<OrderSessionAction>,
  index: number,
  itemCustomizationSelections?: ItemCustomizationSelections
): JSX.Element | null {
  const selection = itemCustomizationSelections
    ? (itemCustomizationSelections[selector.text ?? ""] as SizeSelectOption)
    : undefined;
  item.retailModifiedItem = selection?.retailModifiedItem;

  if (selection && selection.option) {
    handleTagAlongTags(selection?.option, itemCustomization, item);
  }

  // If we are displaying the selector as an action sheet, we don't need to render it in the body.
  if (selector.displayAsActionSheet) {
    return null;
  }

  if (!selector.options) {
    return null;
  } else {
    const sizeSelectOptions = deriveSizeOptions(selector.options, itemCustomization);

    const onSizeSelected = (sizeOption: SizeSelectOption): void => {
      dispatch({
        type: "RESET_ITEM_CUSTOMIZATIONS",
        payload: [selector.text ?? "", sizeOption],
      });
    };

    return (
      <SelectorContainer key={index} selector={selector}>
        <SizeSelector
          selector={selector}
          sizeSelectOptions={sizeSelectOptions}
          selectedOption={selection}
          itemCustomization={itemCustomization}
          onSizeOptionSelected={(sizeOption: SizeSelectOption): void => onSizeSelected(sizeOption)}
        />
      </SelectorContainer>
    );
  }
}
