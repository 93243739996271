import React, { useContext, useEffect, useState } from "react";

import "./AlcoholDeliveryActionSheet.scss";

import { MenuCategory } from "assets/dtos/anywhere-dto";

import { OrderSubviewProps } from "components/Order/Order";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton, {
  TextButtonColor,
} from "components/misc/button/SheetzTextButton/SheetzTextButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { ShoppingBagItem, isCustomizedShoppingBagItem } from "util/Bag.util";

interface AlcoholDeliveryActionSheetProps extends OrderSubviewProps {
  show: boolean;
  setShowAlcoholDeliveryActionSheet: React.Dispatch<React.SetStateAction<boolean>>;
  menu: MenuCategory[];
}

const AlcoholDeliveryActionSheet = (props: AlcoholDeliveryActionSheetProps) => {
  const appContext = useContext(AppContext);
  const [itemsToRemove, setItemsToRemove] = useState<ShoppingBagItem[]>([]);
  const shoppingBagItems = props.orderSession.shoppingBag?.items;
  const menu = props.menu;

  const itemsToRemoveText = itemsToRemove
    .map((item) => {
      if (isCustomizedShoppingBagItem(item)) {
        return item.itemDetails.retailItem?.receiptText;
      } else {
        return "";
      }
    })
    .join(", ");

  useEffect(() => {
    if (props.show) {
      setItemsToRemove(getItemsToRemove());
    }

    /**
     * This gets the items in the bag that are alcohol types.
     * Since this info has to be retrived from the menu, we cannot support reordered items.
     * Instead, we use the "soldFromCategoryPath" to get the category that the item was part of.
     * This helps us get the related MenuCategoryRetailItem.
     */
    function getItemsToRemove(): ShoppingBagItem[] {
      if (shoppingBagItems === undefined) {
        return [];
      }

      const items = shoppingBagItems.filter((item) => {
        if (isCustomizedShoppingBagItem(item)) {
          const categoryPath = item.itemDetails.event?.soldFromCategoryPath;

          if (categoryPath && categoryPath.length > 0) {
            const menuCategoryId = categoryPath[categoryPath.length - 1];
            const menuCategory = menu.find(
              (category) => category.menuCategoryId === menuCategoryId
            );

            if (menuCategory?.menuItems?.retailItems !== undefined) {
              const menuCategoryItem = menuCategory.menuItems.retailItems.find(
                (retailItem) =>
                  retailItem.itemCustomizationId === parseInt(item.itemCustomizationId)
              );
              return (
                menuCategoryItem?.restrictedItemType === "BEER" ||
                menuCategoryItem?.restrictedItemType === "WINE" ||
                menuCategoryItem?.restrictedItemType === "CIDER"
              );
            }
          }
        }
        return false;
      });

      return items;
    }
  }, [menu, props.show, shoppingBagItems]);

  function removeItemsSelected(): void {
    if (itemsToRemove.length < 1) {
      appContext.showToast(
        "Oh Sheetz!",
        "We were unable to remove the alcohol items from your bag. Please remove them manually",
        ToastType.error
      );
    } else {
      itemsToRemove.forEach((item) => {
        props.dispatch({
          type: "REMOVE_ITEM_FROM_BAG",
          payload: item.id,
        });
      });
    }
    props.setShowAlcoholDeliveryActionSheet(false);
  }

  return (
    <ActionSheet
      color={ActionSheetColor.red}
      title="Hold Up!"
      overlay={true}
      shouldDisplay={props.show}
    >
      <div className="alcohol-delivery-actionsheet">
        <p className="subtitle-text">
          Some items in your bag cannot be delivered by the available delivery provider. Please
          remove those items to continue.
        </p>
        <p className="alcohol-items-list">{itemsToRemoveText}</p>
        <div className="alcohol-delivery-buttons">
          <SheetzTextButton
            buttonColor={TextButtonColor.mediumGray}
            textSize="large-text"
            underline
            label="Remove Item(s)"
            onClick={removeItemsSelected}
          />
          <SheetzButton
            label="Close"
            onClick={() => props.setShowAlcoholDeliveryActionSheet(false)}
          />
        </div>
      </div>
    </ActionSheet>
  );
};

export default AlcoholDeliveryActionSheet;
