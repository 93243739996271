import { Formik, FormikProps } from "formik";
import React, { useContext } from "react";
import { PatternFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import "./PhoneNumberConsent.scss";

import { ItemEvent } from "assets/dtos/anywhere-dto";

import { OrderSubviewProps } from "components/Order/Order";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzCheckbox from "components/misc/form/SheetzCheckbox/SheetzCheckbox";
import SheetzInput from "components/misc/form/SheetzInput/SheetzInput";

import { AppContext } from "util/AppContext.util";
import { updateDeliveryPhoneNumber } from "util/Order.util";
import { phoneValidation } from "util/Validation.util";

interface FormValues {
  phoneNumber: string;
  phoneNumberConsent: boolean;
}

const PhoneNumberConsent = (props: OrderSubviewProps) => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as { homepageBannerRedirect: string; event: ItemEvent };

  const submitDeliveryAddress = (values: FormValues): void => {
    appContext.showLoading();
    updateDeliveryPhoneNumber(values.phoneNumber)
      .then(() => {
        props.dispatch({
          type: "SET_DELIVERY_PHONE_NUMBER",
          payload: values.phoneNumber,
        });
        props.dispatch({
          type: "SET_DELIVERY_PHONE_NUMBER_CONSENT",
          payload: values.phoneNumberConsent,
        });
        navigate("/order/delivery/time", {
          state: locationState,
        });
      })
      .finally(() => {
        appContext.hideLoading();
      });
  };

  const validationSchema = Yup.object().shape({
    phoneNumber: phoneValidation.required("Required").min(10),
    phoneNumberConsent: Yup.boolean().required("Required").oneOf([true], "Required"),
  });

  return (
    <div className="delivery-phone-number-container">
      <p className="delivery-phone-number-instructions">
        A mobile phone number is required for delivery orders. We&apos;ll also update your account
        with this number.
      </p>
      <Formik
        initialValues={{
          phoneNumber: props.orderSession.deliveryPhoneNumber || "",
          phoneNumberConsent: false,
        }}
        onSubmit={submitDeliveryAddress}
        validationSchema={validationSchema}
      >
        {(props: FormikProps<FormValues>) => (
          <form className="delivery-phone-number-form" onSubmit={props.handleSubmit}>
            <div className="delivery-phone-number-top">
              <PatternFormat
                format="(###) ###-####"
                name="phoneNumber"
                placeholder="Phone Number"
                label="Phone Number"
                type="tel"
                customInput={SheetzInput}
                defaultValue={props.values.phoneNumber}
                onValueChange={(value): void => props.setFieldValue("phoneNumber", value.value)}
                valueIsNumericString
              />
            </div>

            <div className="delivery-phone-number-bottom">
              <SheetzCheckbox
                label="I Consent to share phone number with Sheetz"
                name="phoneNumberConsent"
              />
              <SheetzButton type="submit" className="submit-phone-number" label="Continue" />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PhoneNumberConsent;
