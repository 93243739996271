import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { useErrorBoundary } from "react-error-boundary";

import "./SheetzContainerButton.scss";

const SheetzContainerButton = (
  props: PropsWithChildren<React.ComponentPropsWithoutRef<"button">>
) => {
  const { showBoundary } = useErrorBoundary();

  const { children, onClick, ...rest } = props;

  const buttonClasses = classNames("sheetz-container-button", rest.className, {
    disabled: props.disabled,
  });

  function handleClickWithErrorBoundary(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    try {
      props.onClick?.(event);
    } catch (error) {
      showBoundary(error);
    }
  }

  return (
    <button {...rest} onClick={handleClickWithErrorBoundary} className={buttonClasses}>
      {children}
    </button>
  );
};

export default SheetzContainerButton;
