import React, { ReactElement } from "react";

import "./PortioningSelector.scss";

import { Portions } from "assets/dtos/anywhere-dto";

import CustomizationOption from "components/Order/ItemCustomization/CustomizationOption/CustomizationOption";
import ActionSheetGrid from "components/misc/view/ActionSheet/ActionSheetGrid/ActionSheetGrid";

import { PortionTypes } from "util/Customization.util";
import { IconType } from "util/Icon.util";

interface PortioningSelectorProps {
  portions: Portions;
  onPortionSelected: (portion: PortionTypes) => void;
}

const PortioningSelector = (props: PortioningSelectorProps): ReactElement => {
  const lessPortion = props.portions.less ? (
    <div key={props.portions.less.retailModifiedItemId}>
      <CustomizationOption
        type="PORTIONING"
        portion={PortionTypes.LESS}
        rmi={props.portions.less}
        option={{ text: "Lite" }}
        icon={IconType.portionLite}
        onPortionOptionSelected={handlePortionSelected}
      />
    </div>
  ) : null;

  const regularPortion = props.portions.regular ? (
    <div key={props.portions.regular.retailModifiedItemId}>
      <CustomizationOption
        type="PORTIONING"
        portion={PortionTypes.REGULAR}
        rmi={props.portions.regular}
        option={{ text: "Regular" }}
        icon={IconType.portionRegular}
        onPortionOptionSelected={handlePortionSelected}
      />
    </div>
  ) : null;

  const morePortion = props.portions.more ? (
    <div key={props.portions.more.retailModifiedItemId}>
      <CustomizationOption
        type="PORTIONING"
        portion={PortionTypes.MORE}
        rmi={props.portions.more}
        option={{ text: "Heavy" }}
        icon={IconType.portionHeavy}
        onPortionOptionSelected={handlePortionSelected}
      />
    </div>
  ) : null;

  const sidePortion = props.portions.side ? (
    <div key={props.portions.side.retailModifiedItemId}>
      <CustomizationOption
        type="PORTIONING"
        portion={PortionTypes.SIDE}
        rmi={props.portions.side}
        option={{ text: "On the Side" }}
        icon={IconType.portionSide}
        onPortionOptionSelected={handlePortionSelected}
      />
    </div>
  ) : null;

  const portionOptions = [lessPortion, regularPortion, morePortion, sidePortion];

  function handlePortionSelected(portion: PortionTypes): void {
    props.onPortionSelected(portion);
  }

  return <ActionSheetGrid>{portionOptions}</ActionSheetGrid>;
};

export default PortioningSelector;
