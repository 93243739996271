import "polyfills";

import * as serviceWorker from "./serviceWorker";
import { AxiosResponse } from "axios";
import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "what-input";

import {
  setAxiosAuthorizationHeader,
  setClientVersionHeader,
  setupInterceptor,
} from "./axiosConfig";

import "./index.scss";

import { HomeScreenBanner } from "./assets/dtos/anywhere-dto";

import App from "./components/App";
import Loading from "./components/misc/indicators/Loading/Loading";

import { getMenuHome } from "./util/Menu.util";
import { clearOrderSession, getAuthorization } from "util/Authentication.util";
import {
  AnywhereNativeMobile,
  SheetzNativeOrderz,
  getUserIdFromNativeMobileAuthToken,
  isInNativeMobileContext,
} from "util/MobileApp.util";
import { OrderSession } from "util/Order.util";
import { getOrderSession, setOrderSession, setUserId } from "util/Storage.util";

// See: https://mariusschulz.com/blog/declaring-global-variables-in-typescript#augmenting-the-window-interface
declare global {
  interface Window {
    anywhere?: AnywhereNativeMobile;
    sheetzNativeOrderz?: SheetzNativeOrderz;
    VisaSensoryBranding?: {
      show: () => void;
      init: (config: Record<string, unknown>, path: string) => void;
    };
  }
}

let homeScreenBanners: HomeScreenBanner[];

// Configure app-level things before we render the main APP component.
setClientVersionHeader("clientVersion");

setupInterceptor();

// Handle auth token input from mobile, if running inside of mobile app.
if (isInNativeMobileContext()) {
  /**
   * Set the inital value of the `window.anywhere` mobile app interface namespace to mock implementations.
   * Other code will implement functions in this namespace.
   */
  window.anywhere = {
    closeBag: (): void => {
      return;
    },
    closeSearch: (): void => {
      return;
    },
    isBagOpen: (): boolean => false,
    isSearchOpen: (): boolean => false,
    nativeWalletCanceled: (): void => {
      return;
    },
    pinCreateUpdateStatus: (status: boolean): void => {
      return;
    },
    setMobileAccountPreferences: (mobileAccountPreferences: string) => {
      return;
    },
    setMobilePayAuthorization: (base64Data: string | undefined): void => {
      return;
    },
    setPaymentMethodId: (paymentMethodId: number): void => {
      return;
    },
  };

  // Get the userId from the auth token and compare it to any existing userId on the order session.
  const newUserId = getUserIdFromNativeMobileAuthToken();
  const orderSessionJSON = getOrderSession();
  const orderSession: OrderSession =
    orderSessionJSON !== undefined ? JSON.parse(orderSessionJSON) : {};
  const existingUserId = orderSession.userId;

  /**
   * If the newUserId and existingUserId do not match, then wipe out the order session.
   * This indicates that a user has logged out on their device and logged back in with another account.
   * Regardless - ensure that the newUserId, which was retrieved from the mobile auth token, gets set on the order session.
   */
  if (existingUserId !== undefined && newUserId !== undefined && existingUserId !== newUserId) {
    clearOrderSession();
    setOrderSession(JSON.stringify({ userId: newUserId } as OrderSession));
  } else {
    setOrderSession(JSON.stringify({ ...orderSession, userId: newUserId } as OrderSession));
  }

  if (newUserId !== undefined) {
    setUserId(newUserId.toString());
  }

  // Go straight to rendering the main app component, as the homepage content isn't needed for the mobile apps.
  ReactDOM.render(<App />, document.getElementById("root"));
} else {
  getHomepageBanners();
  const authToken = getAuthorization();
  if (authToken !== null) {
    setAxiosAuthorizationHeader(authToken);
  }
}

// Configure Google Tag Manager

const tagManagerArgs = {
  gtmId: "GTM-KVK5K77",
  dataLayerName: "PageDataLayer",
};

TagManager.initialize(tagManagerArgs);

function getHomepageBanners(): void {
  const container = document.getElementById("root");
  const root = createRoot(container ?? document.body);

  // render the Loading element only until the home page banners are returned
  root.render(<Loading />);

  // Fetch homepage banner content before we render the main APP component
  getMenuHome()
    .then((response: AxiosResponse) => {
      if (response.data) {
        homeScreenBanners = response.data.homeScreenBanners;
      }
    })
    .finally(() => {
      root.render(<App homeScreenBanners={homeScreenBanners} />);
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
