import React, { ReactElement } from "react";
import { NumericFormat } from "react-number-format";

import "./PriceDisplay.scss";

interface PriceDisplayProps {
  discount?: number;
  loyaltyDiscounts?: number;
  originalPrice?: number;
  total?: number;
}

const PriceDisplay = (props: PriceDisplayProps): ReactElement | null => {
  const hasDiscounts =
    (props.discount !== undefined && props.discount !== 0) ||
    (props.loyaltyDiscounts !== undefined && props.loyaltyDiscounts !== 0);

  function renderText(formattedValue: string): ReactElement {
    return (
      <span style={hasDiscounts ? { textDecoration: "line-through" } : undefined}>
        {formattedValue}
      </span>
    );
  }

  return (
    <div className="price-display">
      {hasDiscounts && (
        <>
          <p className="item-original-price">
            <NumericFormat
              displayType="text"
              value={props.originalPrice}
              decimalScale={2}
              fixedDecimalScale={true}
              renderText={renderText}
            />
          </p>

          <p className="item-price-discount">
            <NumericFormat
              displayType="text"
              value={props.discount}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </p>
        </>
      )}

      <p className="item-total">
        <NumericFormat
          displayType="text"
          value={props.total}
          decimalScale={2}
          fixedDecimalScale={true}
          allowNegative={false}
        />
      </p>
    </div>
  );
};

export default PriceDisplay;
