import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./Reorder.scss";

import {
  FavoriteOrder,
  Order,
  StartDefaultReorderSessionRequest,
  Store,
} from "assets/dtos/anywhere-dto";

import { OrderSubviewProps } from "components/Order/Order";
import ReorderAvailabilityActionSheet from "components/Order/Reorder/RedorderAvailabilityActionSheet/ReorderAvailabilityActionSheet";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import EmptyPage from "components/misc/indicators/EmptyPage/EmptyPage";
import MenuItem from "components/misc/item/MenuItem/MenuItem";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";
import Tabs from "components/misc/view/Tabs/Tabs";

import { useMediaQuery } from "hooks";

import { AppContext, desktopMediaQuery } from "util/AppContext.util";
import { ShoppingBag } from "util/Bag.util";
import { IconType } from "util/Icon.util";
import { MenuItemType, OrderStatus, generateOrderSessionId } from "util/Order.util";
import {
  UnavailabilityDetails,
  areAllItemsUnavailable,
  filterUnavailableEntities,
  getFavoritesAndHistory,
  getUnavailabilityDetails,
  isOrderFullyAvailable,
  mapPreviousOrderToShoppingBag,
  startDefaultReorderSession,
} from "util/Reorder.util";
import { getRecentStore, getUserId } from "util/Storage.util";

export enum ReorderViews {
  "My Favorites",
  "Recent Orders",
}

export interface ReorderProps extends OrderSubviewProps {
  handleAddHistoryFavoriteToBag: (order: ShoppingBag, path?: string) => void;
  tab?: ReorderViews;
}

const Reorder = (props: ReorderProps) => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);

  const [favoriteOrders, setFavoriteOrders] = useState<FavoriteOrder[]>();
  const [orderHistory, setOrderHistory] = useState<Order[]>();
  const [selectedFavorite, setSelectedFavorite] = useState<FavoriteOrder | undefined>();
  const [selectedTab, setSelectedTab] = useState<ReorderViews>(
    props.tab ? props.tab : ReorderViews["My Favorites"]
  );
  const [showUnavailabilityActionSheet, setShowUnavailabilityActionSheet] =
    useState<boolean>(false);
  const [unavailabilityDetails, setUnavailabilityDetails] = useState<
    UnavailabilityDetails | undefined
  >();

  const defaultReorderParams = location.state as {
    defaultReorderParams: boolean;
    recentStore: Store;
  };
  const reorderTabs = [
    {
      label: "My Favorites",
      onClick: (): void => {
        setSelectedTab(ReorderViews["My Favorites"]);
        navigate("/order/favorites", { replace: true });
      },
      disabled: false,
      activeTab: selectedTab === ReorderViews["My Favorites"],
    },
    {
      label: "Recent Orders",
      onClick: (): void => {
        setSelectedTab(ReorderViews["Recent Orders"]);
        navigate("/order/history", { replace: true });
      },
      disabled: false,
      activeTab: selectedTab === ReorderViews["Recent Orders"],
    },
  ];
  const hasUsableSession =
    (!!props.orderSession.store &&
      !!props.orderSession.pickupTime &&
      !!props.orderSession.pickupLocation) ||
    (props.orderSession.delivery &&
      !!props.orderSession.deliveryAddressId &&
      props.orderSession.deliveryPhoneNumberConsent);

  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    if (hasUsableSession) {
      return;
    }
    if (defaultReorderParams && !hasUsableSession) {
      startDefaultSession(defaultReorderParams.recentStore);
      window.history.replaceState({ ...defaultReorderParams, defaultReorderParams: undefined }, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultReorderParams]);

  useEffect(() => {
    appContext.showLoading();

    if (!hasUsableSession) {
      const recentStore: Store | null = checkForRecentStore();
      if (recentStore) {
        startDefaultSession(recentStore);
      }
    }

    getFavoritesAndHistory(
      props.orderSession.store?.storeNumber.toString() ?? getRecentStoreNumber()?.toString(),
      props.orderSession.orderSessionId
    )
      .then((response) => {
        setFavoriteOrders(response.data.favorites);
        setOrderHistory(response.data.orderHistory);

        if (queryParams.favoriteIdToAdd) {
          const favoriteId = parseInt(queryParams.favoriteIdToAdd as string);
          const favorite = response.data.favorites.find(
            (favorite) => favorite.favoriteId === favoriteId
          );
          if (favorite) {
            navigate("/order/favorites", { replace: true });
            addOrderToBag(favorite);
          }
        }
      })
      .finally(() => {
        appContext.hideLoading();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function addOrderToBag(favorite: FavoriteOrder): void {
    const tempShoppingBag = mapPreviousOrderToShoppingBag(favorite, "FAVORITE");

    props.handleAddHistoryFavoriteToBag(tempShoppingBag, "/order/favorites");
  }

  function checkForRecentStore(): Store | null {
    const recentStoreData: string | null = getRecentStore();
    if (recentStoreData) {
      try {
        return JSON.parse(recentStoreData) as Store;
      } catch (e) {
        throw new SheetzError("Recent store data exists but could not be parsed", {
          userReadableMessage: "Unable to retreive store data. Please try again.",
          primaryButton: SheetzErrorButtonType.TRY_AGAIN,
        });
      }
    }
    return null;
  }

  const getRecentStoreNumber = () => {
    return checkForRecentStore()?.storeNumber;
  };

  function generateSubtext(order: FavoriteOrder | Order): string {
    let subtext = "";
    order.items.forEach((item: { receiptText: string }, index: number) => {
      let separator = ", ";
      if (order.items.length === index + 1) {
        separator = "";
      }
      subtext += item.receiptText + separator;
    });
    return subtext.trim();
  }

  function handleFavoriteSelected(favorite: FavoriteOrder): void {
    // Delivery scenario
    if (props.orderSession.delivery === true) {
      if (props.orderSession.storeNumber === undefined) {
        navigate("/order/delivery/address", {
          state: {
            redirectOnOrderFlowFinish: "/order/favorites?favoriteIdToAdd=" + favorite.favoriteId,
          },
        });
      } else if (props.orderSession.orderSessionId === undefined) {
        navigate("/order/delivery/time", {
          state: {
            redirectOnOrderFlowFinish: "/order/favorites?favoriteIdToAdd=" + favorite.favoriteId,
          },
        });
      } else {
        addOrderToBag(favorite);
      }
      return;
    }

    // Pick up scenario
    if (
      !props.orderSession.store ||
      !props.orderSession.pickupTime ||
      !props.orderSession.pickupLocation
    ) {
      navigate("/order/selectStore", {
        state: {
          redirectOnOrderFlowFinish: "/order/favorites?favoriteIdToAdd=" + favorite.favoriteId,
        },
      });
    } else {
      addOrderToBag(favorite);
    }
  }

  function handleMenuRedirect(): void {
    if (hasUsableSession) {
      navigate("/order/menu");
    } else {
      navigate("/order");
    }
  }

  function orderWithoutPressed(): void {
    setShowUnavailabilityActionSheet(false);
    if (selectedFavorite) {
      const filteredFavorite = filterUnavailableEntities(selectedFavorite) as FavoriteOrder;
      addOrderToBag(filteredFavorite);
    }
  }

  function reorderItemsWithUnavailableContents(favoriteOrder: FavoriteOrder): void {
    if (areAllItemsUnavailable(favoriteOrder)) {
      appContext.showToast(
        "Oh Sheetz!",
        "No items from this favorite are available for order.",
        ToastType.error
      );
      return;
    }

    setUnavailabilityDetails(getUnavailabilityDetails(favoriteOrder));
    setSelectedFavorite(favoriteOrder);
    setShowUnavailabilityActionSheet(true);
  }

  function startDefaultSession(store: Store): void {
    const orderSessionId = generateOrderSessionId();
    // We default to pickup in store for a default session.
    const startDefaultReorderSessionRequest: StartDefaultReorderSessionRequest = {
      storeNumber: store.storeNumber,
      orderType: "PICKUP",
      pickupLocation: "IN_STORE",
    };

    startDefaultReorderSession(startDefaultReorderSessionRequest, orderSessionId).then(
      (response) => {
        props.dispatch({ type: "SET_STORE", payload: store });
        props.dispatch({ type: "SET_ORDER_SESSION_ID", payload: orderSessionId });
        const userId = getUserId();
        props.dispatch({ type: "SET_USER_ID", payload: userId ? parseInt(userId) : undefined });
        props.dispatch({
          type: "SET_TIME_AND_AVAILABILITY",
          payload: response.data.startOrderSessionResponse,
        });
        props.dispatch({ type: "SET_ASAP_ORDER", payload: true });
        props.dispatch({
          type: "SET_PICKUP_DETAILS",
          payload: response.data.validatePickupTimeResponse,
        });
        props.dispatch({
          type: "SET_DAYPART",
          payload: response.data.validatePickupTimeResponse.dayPart,
        });
        props.dispatch({
          type: "SET_PICKUP_LOCATION",
          payload: startDefaultReorderSessionRequest.pickupLocation,
        });
        props.dispatch({ type: "CLEAR_DELIVERY_DATA", payload: undefined });
      }
    );
  }

  const favoritesDisplay =
    favoriteOrders && favoriteOrders.length > 0
      ? favoriteOrders.map((favorite) => {
          const isFavoriteFullyAvailable = isOrderFullyAvailable(favorite);
          return (
            <li key={favorite.favoriteId}>
              <MenuItem
                image={favorite.items[0].image}
                name={favorite.name}
                subtext={generateSubtext(favorite)}
                type={MenuItemType.favorite}
                reorderUnavailable={!isFavoriteFullyAvailable}
                onEditSelected={(): void =>
                  navigate("/order/favorites/detail", { state: { favoriteOrder: favorite } })
                }
                onReorderUnavailableSelected={(): void =>
                  reorderItemsWithUnavailableContents(favorite)
                }
                onClick={(): void => handleFavoriteSelected(favorite)}
              />
            </li>
          );
        })
      : "No favorites";

  const historyDisplay =
    orderHistory && orderHistory.length > 0
      ? orderHistory.map((order) => {
          const isOrderFromHistoryFullyAvailable = isOrderFullyAvailable(order);
          return (
            <li key={order.orderNumber}>
              <MenuItem
                image={order.items[0].image}
                name={"Order #" + order.orderNumber}
                subtext={generateSubtext(order)}
                type={MenuItemType.order}
                orderStatus={
                  OrderStatus[order.state] === OrderStatus.CANCELLED ||
                  OrderStatus[order.state] === OrderStatus.PENDING_DELIVERY
                    ? OrderStatus[order.state]
                    : undefined
                }
                reorderUnavailable={!isOrderFromHistoryFullyAvailable}
                onReorderUnavailableSelected={(): void =>
                  navigate("/order/history/detail", {
                    state: {
                      order: order,
                      storeNumber:
                        props.orderSession.store?.storeNumber.toString() ??
                        getRecentStoreNumber()?.toString(),
                    },
                  })
                }
                onClick={(): void =>
                  navigate("/order/history/detail", {
                    state: {
                      order: order,
                      storeNumber:
                        props.orderSession.store?.storeNumber.toString() ??
                        getRecentStoreNumber()?.toString(),
                    },
                  })
                }
              />
            </li>
          );
        })
      : "No History";

  return (
    <>
      <div className="reorder-container ">
        <Tabs tabs={reorderTabs} showMenuButton>
          {selectedTab === ReorderViews["My Favorites"] &&
            (favoriteOrders && favoriteOrders.length > 0 ? (
              <ul className="favorites-list">{favoritesDisplay}</ul>
            ) : (
              <EmptyPage
                title="You have no favorites!"
                icon={IconType.bag}
                detail="Add a favorite or start a new order."
              >
                <div className="reorder-empty-page-buttons">
                  {orderHistory?.length && orderHistory.length > 0 ? (
                    <SheetzButton
                      buttonColor={ButtonColor.darkGray}
                      transparentButton
                      label="View Recent Orders"
                      onClick={(): void => {
                        setSelectedTab(ReorderViews["Recent Orders"]);
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  <SheetzButton
                    buttonColor={ButtonColor.darkGray}
                    transparentButton
                    label="Main Menu"
                    onClick={(): void => {
                      handleMenuRedirect();
                    }}
                  />
                </div>
              </EmptyPage>
            ))}

          {selectedTab === ReorderViews["Recent Orders"] && (
            <>
              {orderHistory?.length && orderHistory.length > 0 ? (
                <ul className="history-list"> {historyDisplay} </ul>
              ) : (
                <EmptyPage
                  title="You have no orders!"
                  icon={IconType.bag}
                  detail="Get some by starting a new order."
                >
                  <div className="reorder-empty-page-buttons">
                    <SheetzButton
                      buttonColor={ButtonColor.darkGray}
                      transparentButton
                      label="Main Menu"
                      onClick={(): void => {
                        handleMenuRedirect();
                      }}
                    />
                  </div>
                </EmptyPage>
              )}
            </>
          )}
        </Tabs>

        {!useDesktopView && (
          <div className="history-menu-button">
            <SheetzButton
              label="View main menu"
              onClick={(): void => {
                navigate("/order");
              }}
            />
          </div>
        )}
      </div>

      <ReorderAvailabilityActionSheet
        show={showUnavailabilityActionSheet}
        unavailabilityDetails={unavailabilityDetails}
        onOrderWithoutPressed={orderWithoutPressed}
        onCancelPressed={(): void => setShowUnavailabilityActionSheet(false)}
      />
    </>
  );
};

export default Reorder;
