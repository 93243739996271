import { Selector } from "assets/dtos/anywhere-dto";

import { ItemCustomization } from "util/Customization.util";
import { CustomizedItem } from "util/Order.util";
import { deriveSize } from "util/Tag.util";

export function processAutoSizeSelector(
  selector: Selector,
  item: CustomizedItem,
  itemCustomization: ItemCustomization
): void {
  if (selector.onlyOneSize) {
    item.retailModifiedItem = itemCustomization.retailItem.retailModifiedItems[0];
  } else {
    const size = deriveSize(selector.tags ?? [], itemCustomization);
    item.retailModifiedItem = size;

    if (!size) {
      // TODO: Possibly throw error instead that is caught and logged to splunk.
      console.warn(`No size derived for auto size selector with tags='${selector.tags}'
      and itemCustomizationId='${itemCustomization.itemCustomizationId}'`);
    }
  }
}
