import { Formik, FormikProps } from "formik";
import React from "react";
import Barcode from "react-barcode";
import * as Yup from "yup";

import "./EditMySheetzCard.scss";

import { LoyaltyCard } from "assets/dtos/anywhere-dto";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzCheckbox from "components/misc/form/SheetzCheckbox/SheetzCheckbox";
import SheetzInput from "components/misc/form/SheetzInput/SheetzInput";

import { IconType, getIcon } from "util/Icon.util";
import { formatCardNumber } from "util/Loyalty.util";
import { sheetzCardNameValidation } from "util/Validation.util";

interface EditMySheetzCardProps {
  card: LoyaltyCard;
  submitFunction: (card: LoyaltyCard) => void;
  deleteFunction: (cardId: number) => void;
  cancelFunction: () => void;
}
const trashIcon = getIcon(IconType.trash, "trash-icon");

const EditMySheetzCard = (editProps: EditMySheetzCardProps) => {
  const initialValues = Object.assign({}, editProps.card);
  initialValues.cardNumber = formatCardNumber(initialValues.cardNumber);
  return (
    <>
      <div className="edit-mysheetzcard">
        <div className="loyalty-card-barcode">
          <Barcode value={editProps.card.cardNumber} displayValue={false} />
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={(values: LoyaltyCard): void => {
            values.cardNumber = values.cardNumber.replace(/\s/g, "");
            editProps.submitFunction(values);
          }}
          validationSchema={Yup.object({
            cardName: sheetzCardNameValidation,
          })}
        >
          {(props: FormikProps<LoyaltyCard>) => (
            <form className="edit-mysheetzcard-form" onSubmit={props.handleSubmit}>
              <SheetzInput
                type="text"
                name="cardNumber"
                placeholder="Card Number"
                label="Card Number"
                disabled
              />
              <SheetzInput
                type="text"
                name="cardName"
                placeholder="Card Name"
                label="Card Name"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
              />

              <SheetzCheckbox
                label="Preferred Card"
                name="primary"
                disabled={editProps.card.primary}
              />

              <div className="button-row">
                <SheetzButton
                  className="edit-mysheetzcard-button submit-button"
                  type="submit"
                  label="Save"
                />
              </div>
            </form>
          )}
        </Formik>

        <div className="delete-row">
          <div
            tabIndex={0}
            role="button"
            aria-pressed="false"
            onClick={(): void => editProps.deleteFunction(editProps.card.cardId)}
            className="delete-payment-button"
          >
            {trashIcon}
            <p className="delete-payment-button-label">Delete Card</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMySheetzCard;
