import { AxiosError } from "axios";
import classNames from "classnames";
import qs from "qs";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { BFFErrorResponse } from "axiosConfig";

import "./PickupTime.scss";

import {
  DeliveryAddress,
  ItemEvent,
  OrderType,
  PickupLocation,
  Store,
  ValidatePickupTimeRequest,
} from "assets/dtos/anywhere-dto";

import { OrderSubviewProps } from "components/Order/Order";
import OrderConfiguration from "components/Order/OrderConfiguration/OrderConfiguration";
import PickupTimeSelector from "components/Order/PickupTimeSelector/PickupTimeSelector";
import FlexContainer from "components/misc/containers/FlexContainer";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import PathListItem from "components/misc/list/PathListItem/PathListItem";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { AppContext } from "util/AppContext.util";
import { isInNativeMobileContext } from "util/MobileApp.util";
import {
  generateOrderSessionId,
  getStore,
  startOrderSession,
  validatePickupTime,
} from "util/Order.util";

interface PickupTimeProps extends OrderSubviewProps {
  deliveryAddress?: DeliveryAddress;
}

const PickupTime = (props: PickupTimeProps): ReactElement => {
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
  const [pickUpTimeValid, setPickUpTimeValid] = useState<boolean>(false);
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as
    | {
        event: ItemEvent;
        homepageBannerRedirect: string;
        redirectOnOrderFlowFinish: string;
      }
    | undefined;
  const orderType: OrderType = props.orderSession.delivery ? "DELIVERY" : "PICKUP";
  // Delivery orders are considered "IN_STORE" for purposes of pick-up location
  const pickupLocation: PickupLocation = props.orderSession.pickupLocation ?? "IN_STORE";
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const storeNumber = props.orderSession.store?.storeNumber;

  const pickupTimeContainerClasses = classNames("pickup-time-container", {
    "pickup-time-present": props.orderSession.pickupTime !== undefined,
  });

  // Handle the beginning of a session if this is a mobile app order with a store already selected.
  useEffect(() => {
    if (queryParams.storeNumber !== undefined) {
      const newStoreNumber = parseInt(queryParams.storeNumber as string);
      const existingStoreNumber =
        props.orderSession.store?.storeNumber ?? props.orderSession.storeNumber;

      // Only reset the session if the store numbers are different.
      if (existingStoreNumber !== undefined && existingStoreNumber === newStoreNumber) {
        return;
      }
      if (isInNativeMobileContext()) {
        appContext.showLoading();
        let store: Store | undefined;
        let orderSessionId: string | undefined;

        const getStorePromise = getStore(newStoreNumber);

        const startOrderSessionPromise = getStorePromise.then(
          (response) => {
            store = response.data.store;
            orderSessionId = generateOrderSessionId();
            return startOrderSession({ storeNumber: newStoreNumber }, orderSessionId);
          },
          (error) => appContext.hideLoading()
        );

        startOrderSessionPromise
          .then((response) => {
            // Response may be void from previous call - getting store data - failing.
            if (response !== undefined) {
              // This entry point into ordering via mobile app doesn't support integrated delivery due to a store already being selected.
              props.dispatch({ type: "CLEAR_ORDER_SESSION", payload: undefined });
              props.dispatch({ type: "SET_DELIVERY", payload: false });
              props.dispatch({ type: "SET_STORE", payload: store });
              props.dispatch({ type: "SET_ORDER_SESSION_ID", payload: orderSessionId });
              props.dispatch({ type: "SET_TIME_AND_AVAILABILITY", payload: response.data });
            }
          })
          .finally(appContext.hideLoading);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function timeSelected(deferredPickupTime?: string): void {
    if (deferredPickupTime && storeNumber !== undefined) {
      validateSelectedTime(storeNumber, deferredPickupTime);
    } else if (storeNumber !== undefined) {
      validateSelectedTime(storeNumber);
    }
  }

  function validateSelectedTime(storeNumber: number, time?: string): void {
    const request: ValidatePickupTimeRequest = {
      storeNumber: storeNumber,
      pickupTime: time ?? undefined,
      pickupLocation,
      orderType,
    };

    appContext.showLoading();

    validatePickupTime(request, props.orderSession.orderSessionId)
      .then((response): void => {
        // If there's a time, it's not an ASAP order.
        props.dispatch({ type: "SET_ASAP_ORDER", payload: !time });
        props.dispatch({
          type: "SET_PICKUP_DETAILS",
          payload: response.data,
        });
        props.dispatch({ type: "SET_DAYPART", payload: response.data.dayPart });
        // If a time was selected, then we need to hide the action sheet before continuing.
        if (!time) {
          navigateToNextStep();
        } else {
          // Setting the pickup time to a valid state and hiding the time picker will trigger pickUpTimeActionSheetClosed
          setPickUpTimeValid(true);
          setShowTimePicker(false);
        }
      })
      .catch((error: AxiosError<BFFErrorResponse>) => {
        console.warn(error);
      })
      .finally((): void => {
        appContext.hideLoading();
      });
  }

  /**
   * This will be called when the action sheet is done animating out.
   * If the validation call for pick up time was valid, then we can continue navigating.
   * Since a user could manually close the action sheet without selecting a deferred pick up time,
   * we need this check here.
   */
  function pickUpTimeActionSheetClosed(): void {
    if (pickUpTimeValid) {
      navigateToNextStep();
    }
  }

  function navigateToNextStep(): void {
    if (props.orderSession.driveThruAvailable || props.orderSession.curbsideOffered) {
      if (
        locationState &&
        (!!locationState.redirectOnOrderFlowFinish ||
          !!locationState.homepageBannerRedirect ||
          !!locationState.event)
      ) {
        navigate("/order/location", {
          state: locationState,
        });
      } else {
        navigate("/order/location");
      }
    } else {
      props.dispatch({
        type: "SET_PICKUP_LOCATION",
        payload: "IN_STORE",
      });
      if (locationState && !!locationState.redirectOnOrderFlowFinish) {
        navigate(locationState.redirectOnOrderFlowFinish, {
          state: locationState,
        });
      } else if (
        (locationState && !!locationState.homepageBannerRedirect) ||
        !!locationState?.event
      ) {
        navigate("/order/location", {
          state: locationState,
        });
      } else {
        navigate("/order/menu");
      }
    }
  }

  return (
    <>
      <FlexContainer flexStyles={{ flexDirection: "column", height: "100%" }}>
        <OrderConfiguration
          deliveryAddress={props.deliveryAddress}
          dispatch={props.dispatch}
          orderSession={props.orderSession}
        />
        <div className={pickupTimeContainerClasses}>
          <ResponsiveLayoutContainer>
            <div className="pickup-time-details">
              <p className="heading">Pick up Time</p>
              <ul>
                <li onClick={(): void => timeSelected()}>
                  <PathListItem
                    title="Pick up ASAP"
                    subtitle={
                      props.orderSession.estimatedPickupInMinutes !== undefined
                        ? `Approx. ${props.orderSession.estimatedPickupInMinutes}min`
                        : ""
                    }
                  />
                </li>
                <li onClick={(): void => setShowTimePicker(true)}>
                  <PathListItem title="Pick up later" subtitle="Later today or within 2 days." />
                </li>
              </ul>
            </div>
          </ResponsiveLayoutContainer>
        </div>
      </FlexContainer>
      <ActionSheet
        color={ActionSheetColor.red}
        title="Pick Up Later"
        overlay={true}
        shouldDisplay={showTimePicker}
        cancelFunction={(): void => setShowTimePicker(false)}
        onAnimationOutFinished={pickUpTimeActionSheetClosed}
      >
        <PickupTimeSelector
          orderSession={props.orderSession}
          dispatch={props.dispatch}
          onPickUpTimeSelected={timeSelected}
        />
      </ActionSheet>
    </>
  );
};

export default PickupTime;
