import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";

import { ItemEvent } from "assets/dtos/anywhere-dto";

import { ItemCustomization, ItemSwitchOption, SizeSelectOption } from "util/Customization.util";
import { OrderItemState } from "util/Order.util";

export const initialOrderItemState: OrderItemState = {
  showAIUActionSheet: false,
};

export type OrderItemActions = {
  readonly type:
    | "RESET_INITIAL_ORDER_ITEM_STATE"
    | "SET_AIU_INITIAL_STATE"
    | "SET_ITEM_EVENT"
    | "SET_ITEM_SIZE_INITIAL_STATE"
    | "SET_ITEM_SWITCH"
    | "SET_NAVIGATE"
    | "SET_SIZE"
    | "SHOW_AIU_ACTION_SHEET"
    | "SHOW_ITEM_CUSTOMIZATION_ACTION_SHEET";
  readonly payload:
    | boolean
    | ItemCustomization
    | ItemEvent
    | ItemSwitchOption
    | number
    | OrderItemState
    | SizeSelectOption
    | string
    | undefined;
};

export function orderItemReducer(
  prevState: OrderItemState,
  action: OrderItemActions
): OrderItemState {
  let newState: OrderItemState | undefined;
  switch (action.type) {
    case "RESET_INITIAL_ORDER_ITEM_STATE": {
      newState = {
        accompanyingItemUpsells: undefined,
        chosenCombo: undefined,
        chosenItem: undefined,
        itemCustomizationId: undefined,
        itemEvent: undefined,
        itemSwitchOption: undefined,
        menuCategory: undefined,
        navigate: undefined,
        sizeSelectOption: undefined,
        showCustomizationActionSheets: false,
        showAIUActionSheet: false,
      };
      break;
    }
    case "SET_AIU_INITIAL_STATE": {
      const payload = action.payload as OrderItemState;
      newState = {
        ...prevState,
        accompanyingItemUpsells: payload.accompanyingItemUpsells,
        itemCustomizationId: payload.itemCustomizationId,
        menuCategory: payload.menuCategory,
        navigate: payload.navigate,
        showAIUActionSheet: payload.showAIUActionSheet,
      };
      break;
    }
    case "SHOW_AIU_ACTION_SHEET": {
      const payload = action.payload as boolean;
      newState = {
        ...prevState,
        showAIUActionSheet: payload,
      };
      break;
    }
    case "SHOW_ITEM_CUSTOMIZATION_ACTION_SHEET": {
      const payload = action.payload as boolean;
      newState = {
        ...prevState,
        showCustomizationActionSheets: payload,
      };
      break;
    }
    case "SET_ITEM_EVENT": {
      const payload = action.payload as ItemEvent;
      newState = {
        ...prevState,
        itemEvent: payload,
      };
      break;
    }
    case "SET_ITEM_SIZE_INITIAL_STATE": {
      const payload = action.payload as OrderItemState;
      newState = {
        ...prevState,
        accompanyingItemUpsells: [],
        chosenCombo: payload.chosenCombo,
        chosenItem: payload.chosenItem,
        menuCategory: payload.menuCategory,
        showAIUActionSheet: false,
        showCustomizationActionSheets: true,
      };
      break;
    }
    case "SET_ITEM_SWITCH": {
      const payload = action.payload as ItemSwitchOption;
      newState = {
        ...prevState,
        itemSwitchOption: payload,
      };
      break;
    }
    case "SET_NAVIGATE": {
      const payload = action.payload as string;
      newState = {
        ...prevState,
        navigate: payload,
      };
      break;
    }
    case "SET_SIZE": {
      const payload = action.payload as SizeSelectOption;
      newState = {
        ...prevState,
        sizeSelectOption: payload,
      };
      break;
    }

    default:
      throw new SheetzError(
        "Unknown action type passed to itemSwitchSizeSelectorsSessionReducer.",
        {
          userReadableMessage: "Looks like we've hit a snag. Please try again.",
          primaryButton: SheetzErrorButtonType.OK,
        }
      );
  }

  return newState;
}
