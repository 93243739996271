import classNames from "classnames";
import React from "react";

import "./ListItem.scss";

import SheetzContainerButton from "components/misc/button/SheetzContainerButton/SheetzContainerButton";
import FlexContainer from "components/misc/containers/FlexContainer";

import { IconType, getIcon } from "util/Icon.util";

interface ListItemProps {
  accordion?: boolean;
  children: React.ReactNode;
  hideArrow?: boolean;
  useDots?: boolean;
  disabled?: boolean;
  expanded?: boolean;
  showTopBorder?: boolean;
  hideBottomBorder?: boolean;
  noLeftPadding?: boolean;
  noTopPadding?: boolean;
  clickHandler?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  buttonLabel?: string;
  rightButtonContent?: React.ReactNode;
  rightButtonClickHandler?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  rightButtonLabel?: string;
}

const ListItem = (props: ListItemProps) => {
  const iconType: IconType = props.useDots ? IconType.moreDots : IconType.arrow;
  const iconClass = props.useDots
    ? "more-dots-icon"
    : props.accordion && !props.expanded
    ? "arrow-icon accordion"
    : props.expanded
    ? "arrow-icon expanded"
    : "arrow-icon";
  const listItemContainerClasses = classNames("list-item-container", {
    "top-border": props.showTopBorder,
    "no-left-padding": props.noLeftPadding,
    "no-top-padding": props.noTopPadding,
    "no-bottom-border": props.hideBottomBorder,
  });

  const rightButtonContentClasses = classNames("right-button list-item-button", {
    "top-border": props.showTopBorder,
    "no-bottom-border": props.hideBottomBorder,
  });

  return (
    <FlexContainer>
      <SheetzContainerButton
        disabled={props.disabled}
        onClick={props.clickHandler}
        className="list-item-button"
        aria-label={props.buttonLabel}
      >
        <div className={listItemContainerClasses}>
          <div className="list-item-content-container">{props.children}</div>
          {!props.hideArrow && (
            <div className="list-item-icon-container">{getIcon(iconType, iconClass)}</div>
          )}
        </div>
      </SheetzContainerButton>
      {props.rightButtonContent && (
        <SheetzContainerButton
          disabled={props.disabled}
          className={rightButtonContentClasses}
          aria-label={props.rightButtonLabel}
          onClick={props.rightButtonClickHandler}
        >
          {props.rightButtonContent}
        </SheetzContainerButton>
      )}
    </FlexContainer>
  );
};

export default ListItem;
