import qs from "qs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./OrderHistoryDetail.scss";

import { Order } from "assets/dtos/anywhere-dto";

import CancelOrder from "components/Account/MyReceipts/Receipt/CancelOrder/CancelOrder";
import { OrderSubviewProps } from "components/Order/Order";
import ReorderAvailabilityActionSheet from "components/Order/Reorder/RedorderAvailabilityActionSheet/ReorderAvailabilityActionSheet";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";

import { ShoppingBag } from "util/Bag.util";
import { IconType, getIcon } from "util/Icon.util";
import { createFavoriteOrderRequest, getOrder } from "util/Order.util";
import {
  UnavailabilityDetails,
  addFavoriteOrder,
  areAllItemsUnavailable,
  createReorderItemsDisplay,
  filterUnavailableEntities,
  getUnavailabilityDetails,
  isOrderFullyAvailable,
  mapPreviousOrderToShoppingBag,
} from "util/Reorder.util";
import { getDateTimeFromString } from "util/Time.util";

export interface OrderHistoryDetailProps extends OrderSubviewProps {
  handleAddHistoryFavoriteToBag: (order: ShoppingBag, path?: string) => void;
}

const OrderHistoryDetail = (props: OrderHistoryDetailProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const locationState = location.state as { order: Order; storeNumber?: number };
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [isDelivery, setIsDelivery] = useState<boolean>(false);
  const [isOrderFavorite, setIsOrderFavorite] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>(locationState.order);
  const [orderDeliveryPickupTime, setOrderDeliveryPickupTime] = useState<string>();
  const [showUnavailabilityActionSheet, setShowUnavailabilityActionSheet] =
    useState<boolean>(false);
  const [unavailabilityDetails, setUnavailabilityDetails] = useState<
    UnavailabilityDetails | undefined
  >();

  useEffect(() => {
    // If the state is missing, simply navigate them back to the main history view. This shouldn't happen, however.
    if (!order) {
      navigate("/order/history", { replace: true });
    } else {
      getOrder(order.orderNumber, false, locationState.storeNumber).then((response) => {
        setIsDelivery(response.data.order.integratedDelivery !== undefined);
        setIsOrderFavorite(response.data.order.favoriteId !== undefined);
        setOrder(response.data.order);
        setOrderDeliveryPickupTime(
          response.data.order.integratedDelivery !== undefined
            ? response.data.order.integratedDelivery.estimatedDeliveryTime
            : response.data.order.pickupTime
        );
      });

      if (queryParams.addToBag) {
        if (!isOrderFullyAvailable(order)) {
          // Slight delay needed due to navigation immediately followed by action sheet showing.
          setTimeout(() => {
            setUnavailabilityDetails(getUnavailabilityDetails(order));
            setShowUnavailabilityActionSheet(true);
          }, 500);
        } else {
          addOrderToBag();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function addOrderToBag(filteredOrder?: Order): void {
    const tempShoppingBag = mapPreviousOrderToShoppingBag(filteredOrder ?? order, "REORDER");
    props.handleAddHistoryFavoriteToBag(tempShoppingBag, "/order/history");
  }

  function addToBagPressed(): void {
    if (
      (!props.orderSession.store ||
        !props.orderSession.pickupTime ||
        !props.orderSession.pickupLocation) &&
      !props.orderSession.delivery
    ) {
      navigate("/order/selectStore", {
        state: {
          redirectOnOrderFlowFinish: "/order/history/detail?addToBag=true",
          order: order,
        },
      });
    } else if (!isOrderFullyAvailable(order)) {
      setUnavailabilityDetails(getUnavailabilityDetails(order));
      setShowUnavailabilityActionSheet(true);
    } else {
      addOrderToBag();
    }
  }

  function addToFavoritesPressed(): void {
    addFavoriteOrder(createFavoriteOrderRequest(order.orderNumber, order.items, order.combos)).then(
      () => setIsOrderFavorite(true)
    );
  }

  function orderWithoutPressed(): void {
    setShowUnavailabilityActionSheet(false);

    const filteredOrder = filterUnavailableEntities(order) as Order;
    addOrderToBag(filteredOrder);
  }

  return (
    <>
      <div className="order-history-detail-container">
        <div className="order-history-detail-scroll-content">
          <div className="order-info">
            {order.state === "CANCELLED" && <p className="canceled-order-notice">Order Canceled</p>}
            {order.state === "PENDING_DELIVERY" && (
              <p className="pending-delivery-order-notice">Pending Delivery</p>
            )}
            <div className="order-number">Order #{order?.orderNumber}</div>
            {order?.pickupTime && (
              <>
                <div className="order-date">
                  {getDateTimeFromString(order?.pickupTime).toFormat("MMM d, yyyy")} at{" "}
                  {getDateTimeFromString(order?.pickupTime).toFormat("t")}
                </div>
              </>
            )}
          </div>

          <ul className="order-item-list">{createReorderItemsDisplay(order)}</ul>

          <div className="order-action-button-container">
            <SheetzButton
              buttonColor={ButtonColor.darkGray}
              transparentButton
              className="order-receipt-button"
              label="View Receipt"
              onClick={(): void => {
                navigate("/account/receipt", {
                  state: { orderNumber: order.orderNumber },
                });
              }}
            />
            <CancelOrder
              delivery={isDelivery}
              orderNumber={order.orderNumber}
              orderDeliveryPickupTime={orderDeliveryPickupTime}
              orderState={order.state}
            />
          </div>
        </div>

        {!areAllItemsUnavailable(order) && (
          <div className="order-history-detail-footer">
            <SheetzButton
              buttonColor={ButtonColor.white}
              transparentButton
              transparentButtonTextColor="light"
              className={
                isOrderFavorite ? "favorited add-to-favorites-button" : "add-to-favorites-button"
              }
              label={isOrderFavorite ? getIcon(IconType.heart, "heart-icon") : "Add to Favorites"}
              label2={isOrderFavorite ? "Added to Favorites" : undefined}
              disabled={isOrderFavorite}
              onClick={addToFavoritesPressed}
            />
            <SheetzButton label="Add to Bag" onClick={addToBagPressed} />
          </div>
        )}
      </div>

      <ReorderAvailabilityActionSheet
        show={showUnavailabilityActionSheet}
        unavailabilityDetails={unavailabilityDetails}
        onOrderWithoutPressed={orderWithoutPressed}
        onCancelPressed={(): void => setShowUnavailabilityActionSheet(false)}
      />
    </>
  );
};

export default OrderHistoryDetail;
