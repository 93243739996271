import ListItem from "../ListItem/ListItem";
import React, { ReactElement } from "react";

import "./PathListItem.scss";

interface PathListItemProps {
  accordion?: boolean;
  title: string | JSX.Element;
  hideArrow?: boolean;
  subtitle?: string | JSX.Element;
  disabled?: boolean;
  expanded?: boolean;
  clickHandler?: () => void;
  showTopBorder?: boolean;
  hideBottomBorder?: boolean;
  rightButtonContent?: React.ReactNode;
  rightButtonClickHandler?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  rightButtonLabel?: string;
}

const PathListItem = (props: PathListItemProps): ReactElement => {
  return (
    <ListItem
      accordion={props.accordion}
      disabled={props.disabled}
      expanded={props.expanded}
      clickHandler={props.clickHandler}
      hideArrow={props.hideArrow}
      showTopBorder={props.showTopBorder}
      hideBottomBorder={props.hideBottomBorder}
      rightButtonContent={props.rightButtonContent}
      rightButtonClickHandler={props.rightButtonClickHandler}
      rightButtonLabel={props.rightButtonLabel}
    >
      <p className={props.disabled ? "disabled title" : "title"}>{props.title}</p>
      {props.subtitle && (
        <p className={props.disabled ? "disabled subtitle" : "subtitle"}>{props.subtitle}</p>
      )}
    </ListItem>
  );
};

export default PathListItem;
