import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";

import {
  Combo,
  Condiment,
  DeliveryAddress,
  DeliveryTip,
  PickupLocation,
  PurchaseOrder,
  StartOrderSessionResponse,
  Store,
  ValidatePickupTimeResponse,
} from "assets/dtos/anywhere-dto";

import { TipChoice } from "components/Order/PaymentFooter/PaymentFooterTipChoice/PaymentFooterTipChoice";

import {
  ShoppingBag,
  ShoppingBagCombo,
  ShoppingBagItem,
  isCustomizedShoppingBagItem,
} from "util/Bag.util";
import {
  ExtraableOption,
  ItemCustomizationSelections,
  ItemSwitchOption,
  MultiOptionSwitchOption,
  PortionedCondiment,
  SingleSelectOption,
  SizeSelectOption,
} from "util/Customization.util";
import { UserVehicle } from "util/MyVehicles.util";
import { CustomizedItem, OrderSession, SearchResults } from "util/Order.util";
import { postWarningLog } from "util/ServerLogging.util";
import { setOrderSession } from "util/Storage.util";

export type CustomizationOption =
  | SizeSelectOption
  | SingleSelectOption
  | ExtraableOption
  | ExtraableOption[]
  | ItemSwitchOption
  | MultiOptionSwitchOption
  | Condiment[]
  | PortionedCondiment[]
  | boolean;

type ItemCustomizationChoice = [string, CustomizationOption];

type ComboItemCustomizationChoices = [number, ItemCustomizationSelections];

type CustomizedComboItem = [number, CustomizedItem];

type BagQuantityChange = [number, number];

type SavedCustomizedComboItem = [number, number, CustomizedItem];

export type OrderSessionAction = {
  readonly type:
    | "SET_USER_ID"
    | "SET_ORDER_SESSION_ID"
    | "SET_STORE"
    | "SET_STORE_NUMBER"
    | "SET_TIME_AND_AVAILABILITY"
    | "SET_PICKUP_DETAILS"
    | "SET_ASAP_ORDER"
    | "SET_PICKUP_LOCATION"
    | "SET_DELIVERY"
    | "SET_DELIVERY_ADDRESS_ID"
    | "SET_DELIVERY_TIP_SUGGESTIONS"
    | "SET_DELIVERY_TIP_CHOICE"
    | "SET_DELIVERY_TIP_AMOUNT"
    | "SET_DELIVERY_PHONE_NUMBER"
    | "SET_DELIVERY_PHONE_NUMBER_CONSENT"
    | "SET_DELIVERY_INSTRUCTIONS"
    | "SET_DELIVERY_ESTIMATED_DURATION"
    | "CLEAR_DELIVERY_DATA"
    | "SET_DAYPART"
    | "SEARCH"
    | "SET_ITEM_CUSTOMIZATION_ID"
    | "SET_ITEM_CUSTOMIZATIONS"
    | "RESET_ITEM_CUSTOMIZATIONS"
    | "REMOVE_CUSTOMIZATION_SELECTION"
    | "CLEAR_ITEM_CUSTOMIZATIONS"
    | "SET_CURRENT_CUSTOMIZED_COMBO"
    | "SET_CURRENT_COMBO_ITEM_INDEX"
    | "SET_COMBO_ITEM_CUSTOMIZATIONS"
    | "SET_COMBO_ITEM"
    | "CLEAR_COMBO_ITEM_CUSTOMIZATIONS"
    | "ADD_COMBO_TO_BAG"
    | "REPLACE_ITEM_CUSTOMIZATIONS"
    | "ADD_ITEM_TO_BAG"
    | "ADD_ITEMS_TO_BAG"
    | "SAVE_ITEM_TO_BAG"
    | "UPDATE_BAG_ITEM_QUANTITY"
    | "REMOVE_ITEM_FROM_BAG"
    | "SAVE_COMBO_TO_BAG"
    | "UPDATE_BAG_COMBO_QUANTITY"
    | "REMOVE_COMBO_FROM_BAG"
    | "SAVE_COMBO_ITEM_TO_BAG"
    | "CLEAR_PURCHASE_ORDER"
    | "SAVE_PURCHASE_ORDER"
    | "SAVE_ORDER_TOKEN"
    | "CLEAR_ORDER_SESSION"
    | "UPDATE_BAG_FROM_SERVER"
    | "SET_CURBSIDE_VEHICLE"
    | "SET_ORDER_STARTED";
  readonly payload:
    | Store
    | StartOrderSessionResponse
    | ValidatePickupTimeResponse
    | boolean
    | PickupLocation
    | string
    | number
    | SearchResults
    | ShoppingBagItem
    | ShoppingBagItem[]
    | ItemCustomizationChoice
    | Combo
    | ComboItemCustomizationChoices
    | CustomizedComboItem
    | SavedCustomizedComboItem
    | ShoppingBagCombo
    | BagQuantityChange
    | PurchaseOrder
    | ShoppingBag
    | UserVehicle
    | DeliveryAddress
    | TipChoice
    | DeliveryTip[]
    | undefined;
};

/**
 * This function takes in the previous state of our order session and a specific 'action' that will allow us
 * to build a new complete immutable order session. For instance, if we want to update the pickup location,
 * we'll pass in the "SET_PICKUP_LOCATION" action with a payload. Using the previous state, a new state
 * will be built with the new payload added in. This reducer behavior is key to large, complex, immutable state
 * changes that are triggered deep within the component tree.
 */
export function orderSessionReducer(
  prevState: OrderSession,
  action: OrderSessionAction
): OrderSession {
  let newState: OrderSession | undefined;
  switch (action.type) {
    case "SET_USER_ID": {
      newState = { ...prevState, userId: action.payload as number | undefined };
      break;
    }
    case "SET_ORDER_SESSION_ID": {
      newState = { ...prevState, orderSessionId: action.payload as string };
      break;
    }
    case "SET_STORE": {
      // Changing the selected store will invalidate any existing menu.
      newState = { ...prevState, store: action.payload as Store, menu: undefined };
      break;
    }
    // For delivery, only the store number is needed.
    case "SET_STORE_NUMBER": {
      // Changing the selected store will invalidate any existing menu.
      newState = { ...prevState, storeNumber: action.payload as number, menu: undefined };
      break;
    }
    case "SET_TIME_AND_AVAILABILITY": {
      const payload = action.payload as StartOrderSessionResponse;
      newState = {
        ...prevState,
        orderStartedTime: payload.orderStartedTime,
        driveThruAvailable: payload.driveThruAvailable,
        curbsideOffered: payload.curbsideOffered,
        open24x7: payload.open24x7,
        inStorePickup: payload.inStorePickup,
        estimatedPickupInMinutes: payload.estimatedPickupInMinutes,
      };
      break;
    }
    case "SET_PICKUP_DETAILS": {
      const payload = action.payload as ValidatePickupTimeResponse;
      // Changing pickup time will invalidate any existing menu.
      newState = {
        ...prevState,
        curbsideOpen: payload.curbsideOpen,
        curbsideNextTimeOpen: payload.curbsideNextTimeOpen,
        pickupTime: payload.pickupTime,
        menu: undefined,
      };
      break;
    }
    case "SET_ASAP_ORDER": {
      const payload = action.payload as boolean;
      // Changing to an ASAP order will invalidate any existing menu.
      newState = { ...prevState, asapOrder: payload, menu: undefined };
      break;
    }
    case "SET_PICKUP_LOCATION": {
      const payload = action.payload as PickupLocation;
      newState = { ...prevState, pickupLocation: payload };
      break;
    }
    case "SET_DELIVERY": {
      const delivery = action.payload as boolean;
      // Keep shopping bag, delivery tip, and user session data - discard all else.
      if (delivery) {
        newState = {
          delivery: delivery,
          shoppingBag: prevState.shoppingBag,
          deliveryTipAmount: prevState.deliveryTipAmount,
          deliveryTipChoice: prevState.deliveryTipChoice,
          userId: prevState.userId,
          orderSessionId: prevState.orderSessionId,
        };
      } else {
        newState = {
          delivery: delivery,
          shoppingBag: prevState.shoppingBag,
          userId: prevState.userId,
          orderSessionId: prevState.orderSessionId,
        };
      }
      break;
    }
    case "SET_DELIVERY_ADDRESS_ID": {
      const payload = action.payload as number;
      newState = { ...prevState, deliveryAddressId: payload };
      break;
    }
    case "SET_DELIVERY_TIP_SUGGESTIONS": {
      const payload = action.payload as DeliveryTip[] | undefined;
      newState = { ...prevState, deliveryTipSuggestions: payload };
      break;
    }
    case "SET_DELIVERY_TIP_CHOICE": {
      const payload = action.payload as TipChoice;
      newState = { ...prevState, deliveryTipChoice: payload, deliveryTipAmount: undefined };
      break;
    }
    case "SET_DELIVERY_TIP_AMOUNT": {
      const payload = action.payload as number;
      newState = { ...prevState, deliveryTipChoice: TipChoice.Other, deliveryTipAmount: payload };
      break;
    }
    case "SET_DELIVERY_PHONE_NUMBER": {
      const payload = action.payload as string;
      newState = { ...prevState, deliveryPhoneNumber: payload };
      break;
    }
    case "SET_DELIVERY_PHONE_NUMBER_CONSENT": {
      const payload = action.payload as boolean;
      newState = { ...prevState, deliveryPhoneNumberConsent: payload };
      break;
    }
    case "SET_DELIVERY_INSTRUCTIONS": {
      const payload = action.payload as string;
      newState = { ...prevState, deliveryInstructions: payload };
      break;
    }
    case "SET_DELIVERY_ESTIMATED_DURATION": {
      const payload = action.payload as number;
      newState = { ...prevState, deliveryEstimateInMinutes: payload };
      break;
    }
    case "CLEAR_DELIVERY_DATA": {
      newState = {
        ...prevState,
        delivery: undefined,
        deliveryAddressId: undefined,
        deliveryTipSuggestions: undefined,
        deliveryTipChoice: undefined,
        deliveryTipAmount: undefined,
        deliveryPhoneNumber: undefined,
        deliveryPhoneNumberConsent: undefined,
        deliveryInstructions: undefined,
        deliveryEstimateInMinutes: undefined,
      };
      break;
    }
    case "SET_DAYPART": {
      const payload = action.payload as string;
      // Changing day parts will invalidate any existing menu.
      newState = { ...prevState, dayPart: payload, menu: undefined };
      break;
    }
    case "SEARCH": {
      const payload = action.payload as SearchResults | undefined;
      newState = { ...prevState, search: payload };
      break;
    }
    case "SET_ITEM_CUSTOMIZATION_ID": {
      const payload = action.payload as number | undefined;
      newState = { ...prevState, itemCustomizationId: payload };
      break;
    }
    case "SET_ITEM_CUSTOMIZATIONS": {
      const payload = action.payload as ItemCustomizationChoice;
      newState = {
        ...prevState,
        itemCustomizationSelections: {
          ...prevState.itemCustomizationSelections,
          [payload[0]]: payload[1],
        },
      };
      break;
    }
    case "RESET_ITEM_CUSTOMIZATIONS": {
      const payload = action.payload as ItemCustomizationChoice;
      newState = {
        ...prevState,
        itemCustomizationSelections: {
          [payload[0]]: payload[1],
        },
      };
      break;
    }
    case "REMOVE_CUSTOMIZATION_SELECTION": {
      const payload = action.payload as string;
      if (prevState.itemCustomizationSelections) {
        const { [payload]: _, ...filteredItemCustomizations } =
          prevState.itemCustomizationSelections;

        newState = {
          ...prevState,
          itemCustomizationSelections: {
            ...filteredItemCustomizations,
          },
        };
      } else {
        newState = prevState;
      }
      break;
    }
    case "CLEAR_ITEM_CUSTOMIZATIONS": {
      newState = {
        ...prevState,
        itemCustomizationSelections: undefined,
      };
      break;
    }
    case "SET_CURRENT_CUSTOMIZED_COMBO": {
      const payload = action.payload as Combo | undefined;
      newState = { ...prevState, combo: payload };
      break;
    }
    case "SET_CURRENT_COMBO_ITEM_INDEX": {
      const payload = action.payload as number | undefined;
      newState = { ...prevState, currentComboItemIndex: payload };
      break;
    }
    case "SET_COMBO_ITEM_CUSTOMIZATIONS": {
      const payload = action.payload as ComboItemCustomizationChoices;
      const comboItemIndex = payload[0];

      // Make a copy of all combo item customizations and overwrite the item at the specified index.
      const comboItemCustomizationSelections = [
        ...(prevState.comboItemCustomizationSelections || []),
      ];
      comboItemCustomizationSelections[comboItemIndex] = payload[1];

      newState = {
        ...prevState,
        comboItemCustomizationSelections: comboItemCustomizationSelections,
      };
      break;
    }
    case "SET_COMBO_ITEM": {
      const payload = action.payload as CustomizedComboItem;
      const comboItemIndex = payload[0];

      // Make a copy of all combo item customizations and overwrite the item at the specified index.
      const customizedComboItems = [...(prevState.customizedComboItems || [])];
      customizedComboItems[comboItemIndex] = payload[1];

      newState = {
        ...prevState,
        customizedComboItems: customizedComboItems,
      };
      break;
    }
    case "CLEAR_COMBO_ITEM_CUSTOMIZATIONS": {
      newState = {
        ...prevState,
        comboItemCustomizationSelections: undefined,
        customizedComboItems: undefined,
      };
      break;
    }
    case "REPLACE_ITEM_CUSTOMIZATIONS": {
      const payload = action.payload as ItemCustomizationSelections;
      newState = {
        ...prevState,
        itemCustomizationSelections: payload,
      };
      break;
    }
    case "ADD_ITEM_TO_BAG": {
      const payload = action.payload as ShoppingBagItem;
      newState = {
        ...prevState,
        shoppingBag: {
          ...prevState.shoppingBag,
          items: [...(prevState.shoppingBag?.items || []), payload],
          combos: prevState.shoppingBag?.combos || [],
        },
      };
      break;
    }
    case "ADD_ITEMS_TO_BAG": {
      const payload = action.payload as ShoppingBagItem[];
      newState = {
        ...prevState,
        shoppingBag: {
          ...prevState.shoppingBag,
          items: [...(prevState.shoppingBag?.items || []), ...payload],
          combos: prevState.shoppingBag?.combos || [],
        },
      };
      break;
    }
    case "ADD_COMBO_TO_BAG": {
      const payload = action.payload as ShoppingBagCombo;
      newState = {
        ...prevState,
        shoppingBag: {
          ...prevState.shoppingBag,
          items: prevState.shoppingBag?.items || [],
          combos: [...(prevState.shoppingBag?.combos || []), payload],
        },
      };
      break;
    }
    case "SAVE_ITEM_TO_BAG": {
      const payload = action.payload as ShoppingBagItem;
      const items: ShoppingBagItem[] = prevState.shoppingBag?.items || [];
      const updatedItemIndex = items.findIndex(
        (shoppingBagItem) => payload.id === shoppingBagItem.id
      );
      if (updatedItemIndex >= 0) {
        const replacementItems: ShoppingBagItem[] = [...items];
        replacementItems[updatedItemIndex] = payload;
        newState = {
          ...prevState,
          shoppingBag: {
            ...prevState.shoppingBag,
            combos: prevState.shoppingBag?.combos || [],
            items: replacementItems,
          },
        };
      } else {
        newState = prevState;
      }
      break;
    }
    case "UPDATE_BAG_ITEM_QUANTITY": {
      const payload = action.payload as BagQuantityChange;
      const items = [...(prevState.shoppingBag?.items || [])];
      const updatedItemIndex = items.findIndex(
        (shoppingBagItem) => payload[0] === shoppingBagItem.id
      );
      const bagItem = items[updatedItemIndex];
      if (updatedItemIndex >= 0) {
        if (isCustomizedShoppingBagItem(bagItem)) {
          bagItem.quantity = payload[1];
          bagItem.itemDetails.quantity = payload[1];
        } else {
          bagItem.quantity = payload[1];
        }
      }
      newState = {
        ...prevState,
        shoppingBag: {
          ...prevState.shoppingBag,
          combos: prevState.shoppingBag?.combos || [],
          items: items,
        },
      };
      break;
    }
    case "REMOVE_ITEM_FROM_BAG": {
      const payload = action.payload as number;
      const items = [...(prevState.shoppingBag?.items || [])];
      const updatedItemIndex = items.findIndex((shoppingBagItem) => payload === shoppingBagItem.id);
      if (updatedItemIndex >= 0) {
        newState = {
          ...prevState,
          shoppingBag: {
            ...prevState.shoppingBag,
            combos: prevState.shoppingBag?.combos || [],
            items: items.filter((item) => item.id !== payload),
          },
        };
      } else {
        newState = prevState;
      }

      break;
    }
    case "UPDATE_BAG_COMBO_QUANTITY": {
      const payload: BagQuantityChange = action.payload as BagQuantityChange;
      const comboId = payload[0];
      const newQuantity = payload[1];
      const combos = [...(prevState.shoppingBag?.combos || [])];
      const updatedComboIndex = combos.findIndex((combo) => combo.id === comboId);
      const comboToUpdate = combos[updatedComboIndex];

      if (comboToUpdate !== undefined) {
        // We only update the condiment quantity and not the items within.
        comboToUpdate.quantity = newQuantity;
      } else {
        postWarningLog(
          `Combo at index=${updatedComboIndex} not found while trying to update quantity. Combos in bag=${JSON.stringify(
            combos
          )}`
        );
      }

      newState = {
        ...prevState,
        shoppingBag: {
          ...prevState.shoppingBag,
          combos: combos,
          items: prevState.shoppingBag?.items || [],
        },
      };

      break;
    }
    case "REMOVE_COMBO_FROM_BAG": {
      const payload = action.payload as number;
      const combos = [...(prevState.shoppingBag?.combos || [])];
      const updatedComboIndex = combos.findIndex(
        (shoppingBagCombo) => payload === shoppingBagCombo.id
      );
      if (updatedComboIndex >= 0) {
        newState = {
          ...prevState,
          shoppingBag: {
            ...prevState.shoppingBag,
            combos: combos.filter((combo) => combo.id !== payload),
            items: prevState.shoppingBag?.items || [],
          },
        };
      } else {
        newState = prevState;
      }

      break;
    }
    case "SAVE_COMBO_ITEM_TO_BAG": {
      const payload = action.payload as SavedCustomizedComboItem;
      const combos = [...(prevState.shoppingBag?.combos || [])];
      const savedCombo = combos.find((shoppingBagCombo) => payload[0] === shoppingBagCombo.id);
      if (savedCombo && savedCombo.items[payload[1]]) {
        savedCombo.items[payload[1]] = payload[2];
        newState = {
          ...prevState,
          shoppingBag: {
            ...prevState.shoppingBag,
            combos: combos,
            items: prevState.shoppingBag?.items || [],
          },
        };
      } else {
        newState = prevState;
      }

      break;
    }
    case "CLEAR_PURCHASE_ORDER": {
      newState = { ...prevState, purchaseOrder: undefined };
      break;
    }
    case "SAVE_PURCHASE_ORDER": {
      const payload = action.payload as PurchaseOrder;
      newState = { ...prevState, purchaseOrder: payload };
      break;
    }
    case "SAVE_ORDER_TOKEN": {
      const payload = action.payload as string;
      newState = { ...prevState, orderToken: payload };
      break;
    }
    case "CLEAR_ORDER_SESSION": {
      newState = {};
      break;
    }
    case "UPDATE_BAG_FROM_SERVER": {
      if (!prevState.shoppingBag) {
        newState = prevState;
        break;
      }
      const payload = action.payload as ShoppingBag;
      // Line numbers start at 1, but by sorting these ahead of time, lineNumber 1 should be index 0, and so on
      newState = {
        ...prevState,
        shoppingBag: payload,
      };
      break;
    }
    case "SET_CURBSIDE_VEHICLE": {
      const payload = action.payload as UserVehicle | undefined;
      newState = { ...prevState, curbsideVehicle: payload };
      break;
    }
    case "SET_ORDER_STARTED": {
      const payload = action.payload as boolean;
      newState = { ...prevState, orderStartedEventCaptured: payload };
      break;
    }
    default:
      throw new SheetzError("Unknown action type passed to orderSessionReducer.", {
        userReadableMessage: "Looks like we've hit a snag. Please try again.",
        primaryButton: SheetzErrorButtonType.OK,
      });
  }

  const orderSessionJSON = JSON.stringify(newState);
  setOrderSession(orderSessionJSON);
  return newState;
}
