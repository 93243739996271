import React, { useContext } from "react";

import "./AmountSelector.scss";

import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";

import { AddFundsHeaderContext } from "util/Payment.util";

interface AmountProps {
  className?: string;
  options: number[];
  buttonStyle: "header" | "body";
}

const AmountSelector = (props: AmountProps) => {
  const addFundsHeaderContext = useContext(AddFundsHeaderContext);
  const ButtonAmounts = (buttonProps: AmountProps) => {
    if (buttonProps) {
      const buttonAmounts = buttonProps.options.map((buttonAmount) => {
        const isSelected = addFundsHeaderContext.amount === buttonAmount;
        const isTransparentButton = !isSelected;
        const transparentButtonTextColor = props.buttonStyle === "header" ? "light" : "dark";
        const buttonColor =
          props.buttonStyle === "header"
            ? ButtonColor.white
            : props.buttonStyle === "body" && !isSelected
            ? ButtonColor.darkGray
            : ButtonColor.payGreen;

        return (
          <SheetzButton
            className="amount-select"
            key={buttonAmount}
            label={"$" + buttonAmount}
            onClick={(): void => {
              addFundsHeaderContext.setAmount(buttonAmount);
            }}
            buttonColor={buttonColor}
            transparentButton={isTransparentButton}
            transparentButtonTextColor={transparentButtonTextColor}
          />
        );
      });
      return <>{buttonAmounts}</>;
    } else {
      return <></>;
    }
  };

  // function determineButtonClass(prop: AmountProps, buttonAmount: number): string {
  //   if (prop.buttonStyle === "header") {
  //     return addFundsHeaderContext.amount === buttonAmount
  //       ? "amount-select amount-select-header-active"
  //       : "amount-select";
  //   } else {
  //     return addFundsHeaderContext.amount === buttonAmount
  //       ? "amount-select amount-select-body-active"
  //       : "amount-select";
  //   }
  // }

  return (
    <>
      <div className="amount-selector-container">
        <ButtonAmounts
          options={props.options}
          className={props.className}
          buttonStyle={props.buttonStyle}
        />
      </div>
    </>
  );
};

export default AmountSelector;
