import { Selector } from "assets/dtos/anywhere-dto";

import { CustomizedItem } from "util/Order.util";

export function processAutoQuantitySelector(selector: Selector, item: CustomizedItem): void {
  /**
   * If there is already a quantity set that isn't the same as the selector's auto-quantity value,
   * then this implies the user changed the quantity in the shopping bag.
   * In this case, the quantity of the item should be left unchanged.
   */
  if (item.quantity && selector.quantity && item.quantity !== selector.quantity) {
    return;
  }
  item.quantity = selector.quantity;
}
