import React, { ReactElement } from "react";

import "./PoweredByDeliveryProvider.scss";

import { IntegratedDeliveryPartner } from "../../../assets/dtos/anywhere-dto";
import { ReactComponent as DoorDashLogo } from "assets/icons/icon_doordash.svg";
import { ReactComponent as UberEatsLogo } from "assets/icons/icon_ubereats.svg";

interface PoweredByDeliveryProviderProps {
  provider: IntegratedDeliveryPartner;
}
const PoweredByDeliveryProvider = (props: PoweredByDeliveryProviderProps): ReactElement => {
  return (
    <div className="delivery-provider-container">
      Powered by
      {props.provider === "DOOR_DASH" && <DoorDashLogo />}
      {props.provider === "UBER_EATS" && <UberEatsLogo />}
    </div>
  );
};

export default PoweredByDeliveryProvider;
