import React, { ReactElement } from "react";

import "./QuantitySelector.scss";

import ActionSheetGrid from "components/misc/view/ActionSheet/ActionSheetGrid/ActionSheetGrid";

import { IconType, getIcon } from "util/Icon.util";

const QuantitySelector = (props: {
  selectedQuantity: number;
  setQuantity: (newQuantity: number) => void;
}): ReactElement => {
  const quantitySelectors = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((value) => {
    return (
      <button
        key={"quantity-selector-" + value}
        className={"quantity-selector" + (value === props.selectedQuantity ? " selected" : "")}
        onClick={(): void => props.setQuantity(value)}
      >
        {value === props.selectedQuantity &&
          getIcon(IconType.customizationCheck, "customization-check-icon")}
        <div className="flex-container">{value}</div>
      </button>
    );
  });
  return <ActionSheetGrid className="quantity-selector-grid">{quantitySelectors}</ActionSheetGrid>;
};

export default QuantitySelector;
