import { AxiosResponse } from "axios";
import React, { FC, ReactElement, useContext, useEffect, useState } from "react";

import "./ChoosePaymentMethod.scss";

import { PaymentMethod } from "assets/dtos/anywhere-dto";

import { AppContext } from "util/AppContext.util";
import { getIcon } from "util/Icon.util";
import { getCardName, getCreditCardIconType, getPaymentMethods } from "util/Payment.util";
import { getSelectedWalletPaymentMethodId } from "util/Storage.util";

interface PaymentMethodProps {
  paymentMethodList: PaymentMethod[];
}

interface ChoosePaymentMethodProps {
  paymentMethodSelectedCallback: (paymentMethod: PaymentMethod) => void;
}

const ChoosePaymentMethod = (choosePaymentMethodProps: ChoosePaymentMethodProps): ReactElement => {
  const appContext = useContext(AppContext);
  const showLoading = appContext.showLoading;
  const hideLoading = appContext.hideLoading;

  const [paymentMethodSelected, setPaymentMethodSelected] = useState<PaymentMethod>();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  useEffect(() => {
    showLoading();
    getPaymentMethods()
      .then((response: AxiosResponse) => {
        setPaymentMethods(response.data.paymentMethods);
        // User just added a new payment method
        const preselectedPaymentMethodId = getSelectedWalletPaymentMethodId();
        if (preselectedPaymentMethodId) {
          const preselectedPayment: PaymentMethod = response.data.paymentMethods.find(
            (payment: PaymentMethod) =>
              payment.paymentMethodId === parseInt(preselectedPaymentMethodId)
          );
          if (preselectedPayment && preselectedPayment.paymentType !== "ZCARD") {
            setPaymentMethodSelected(preselectedPayment);
            choosePaymentMethodProps.paymentMethodSelectedCallback(preselectedPayment);
          }
        } else if (!preselectedPaymentMethodId) {
          const defaultPayment: PaymentMethod = response.data.paymentMethods.find(
            (payment: PaymentMethod) => payment.isDefault
          );
          if (defaultPayment.paymentType !== "ZCARD" && !defaultPayment?.creditCard?.isExpired) {
            setPaymentMethodSelected(defaultPayment);
            choosePaymentMethodProps.paymentMethodSelectedCallback(defaultPayment);
          }
        }
      })
      .finally(() => {
        hideLoading();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideLoading, showLoading]);

  function handleEditPaymentMethodClick(paymentMethod: PaymentMethod): void {
    setPaymentMethodSelected(paymentMethod);
    choosePaymentMethodProps.paymentMethodSelectedCallback(paymentMethod);
  }

  const MyPaymentMethodElements: FC<PaymentMethodProps> = (paymentMethodProps) => {
    if (paymentMethods && paymentMethods.length > 0) {
      const paymentMethodElements = paymentMethodProps.paymentMethodList?.map((paymentMethod) => {
        if (!paymentMethod.creditCard) {
          return undefined;
        }

        return (
          <div
            className={
              paymentMethodSelected === paymentMethod
                ? "payment-method-item payment-method-selected"
                : "payment-method-item"
            }
            key={paymentMethod.paymentMethodId}
          >
            <button onClick={(): void => handleEditPaymentMethodClick(paymentMethod)}>
              <div>
                {paymentMethod.paymentType === "CREDIT_CARD" && (
                  <>
                    <div className="payment-method-icon-container">
                      {getIcon(
                        getCreditCardIconType(paymentMethod.creditCard?.cardType),
                        "payment-method-icon"
                      )}
                    </div>
                    <div className="payment-method-label-container">
                      <p className="payment-method-name">
                        {getCardName(paymentMethod.creditCard?.cardType) +
                          " *" +
                          paymentMethod.creditCard?.lastFourDigits}
                      </p>
                      {!paymentMethod.creditCard?.isExpired && (
                        <p className="payment-method-number">
                          {`Exp: ${paymentMethod.creditCard?.expirationMonth}/${paymentMethod.creditCard?.expirationYear}`}
                        </p>
                      )}
                      {paymentMethod.creditCard?.isExpired && (
                        <p className="payment-method-number-expired">Expired - Update now</p>
                      )}
                    </div>
                  </>
                )}
                <div className="payment-method-default-container">
                  {!paymentMethod.creditCard?.isExpired && paymentMethod.isDefault && "Default"}
                  <span className="payment-method-update-label">
                    {paymentMethod.creditCard?.isExpired && "Update"}
                  </span>
                </div>
              </div>
            </button>
          </div>
        );
      });
      return <>{paymentMethodElements}</>;
    } else {
      return <></>;
    }
  };

  return (
    <>
      <div className="choose-payment-method-container">
        <div className="choose-payment-method-label">Choose Payment Method</div>
        <MyPaymentMethodElements paymentMethodList={paymentMethods} />
      </div>
    </>
  );
};

export default ChoosePaymentMethod;
