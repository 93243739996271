import { Formik, FormikProps } from "formik";
import mapboxgl from "mapbox-gl";
import qs from "qs";
import React, { FC, ReactElement, useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import "./FindASheetz.scss";

import { Store, StoreLocation } from "assets/dtos/anywhere-dto";

import Header, { HeaderType } from "components/layout/Header/Header";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import SheetzInput, { SelectOption } from "components/misc/form/SheetzInput/SheetzInput";
import SheetzSelect from "components/misc/form/SheetzSelect/SheetzSelect";
import LoadingPlaceholder from "components/misc/indicators/LoadingPlaceholder/LoadingPlaceholder";
import ListItem from "components/misc/list/ListItem/ListItem";
import StoreDisplay from "components/misc/store/StoreDisplay/StoreDisplay";
import SheetzModal from "components/misc/view/SheetzModal/SheetzModal";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";
import FiltersModal from "components/pages/FindASheetz/FindASheetzFilters/FindASheetzFilters";
import StoreDetailsModal from "components/pages/FindASheetz/StoreDetailsModal/StoreDetailsModal";

import { useMediaQuery } from "hooks";

import states from "resources/states";

import { AppContext } from "util/AppContext.util";
import {
  getCurrentGeolocation,
  getGeolocationStatus,
  sheetzMapBoxAccessToken,
} from "util/Geolocation.util";
import { IconType, getIcon } from "util/Icon.util";
import { STORE_PAGINATION_SIZE, getOperatingStates, searchStores } from "util/Order.util";
import {
  EVTypeFilters,
  Filter,
  FuelTypeFilters,
  StoreFilters,
  StoreSearchFiltersType,
  resetFilters,
} from "util/Store.util";
import { searchValidation } from "util/Validation.util";

const validationSchema = Yup.object({
  search: searchValidation,
});

export interface MyStoresProps {
  stores: Store[];
}

interface FilterProps {
  filters: Filter[];
}

interface SearchFormValues {
  search?: string | undefined;
  state?: string | undefined;
}

export interface MapBoxFeature {
  type: string;
  properties: { id: number; lat: number; lng: number };
  geometry: { type: string; coordinates: [number, number] };
}

export const DefaultMapZoom = 9;

const FindASheetz = (): ReactElement => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { storeId } = useParams<{ storeId: string }>();
  const [cancelInitialLocationSearch, setCancelInitialLocationSearch] = useState(false);
  const [searchKey, setSearchKey] = useState<string | undefined>(undefined);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [stateFilter, setStateFilter] = useState<string | undefined>(undefined);
  const [geolocationStatus, setGeolocationStatus] = useState<string>();
  const [position, setPosition] = useState<GeolocationPosition | null>(null);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [locationRetrievalFinished, setLocationRetrievalFinished] = useState(false);
  const [loadedStores, setLoadedStores] = useState<Store[]>([]);
  const [closestStores, setClosestStores] = useState<Store[]>();
  const [savedStores, setSavedStores] = useState<Store[]>();
  const storeMarkers = useRef<mapboxgl.Marker[]>();
  const clusterMarkers = useRef<mapboxgl.Marker[]>();
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<mapboxgl.Map>();
  const [hasLocationTurnedOn, setHasLocationTurnedOn] = useState<boolean>(false);
  const [stateSelectOptions, setStateSelectOptions] = useState<SelectOption[]>([]);
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [showStoreDetailsModal, setShowStoreDetailsModal] = useState<boolean>(false);
  const [storeDetailLocation, setStoreDetailLocation] = useState<StoreLocation>(
    {} as StoreLocation
  );
  const [storeFilters, setStoreFilters] = useState<Filter[]>([...StoreFilters]);
  const [singleStore, setSingleStore] = useState<boolean>(false);
  const [evTypeFilters, setEvTypeFilters] = useState<Filter[]>([...EVTypeFilters]);
  const [fuelTypeFilters, setFuelTypeFilters] = useState<Filter[]>([...FuelTypeFilters]);
  const defaultLat = 40.47275;
  const defaultLng = -78.42507;
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const [useDesktopView] = useMediaQuery("(min-width:768px)");
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const initialValues: SearchFormValues = {
    search: "",
    state: "",
  };

  const [initialFormValues, setInitialFormValues] = useState<SearchFormValues>(initialValues);

  useEffect(() => {
    if (storeDetailLocation.storeNumber) {
      setShowStoreDetailsModal(true);
    }
  }, [storeDetailLocation]);

  useEffect(() => {
    if (storeId) {
      determineStoreDetailLocation(storeId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleStore]);

  useEffect(() => {
    if (cancelInitialLocationSearch) {
      return;
    }

    if (stateSelectOptions.length === 0) {
      getOperatingStates().then((response) => {
        const stateOpt = states.filter((state) => {
          const operationStateIndex = Object.values(response.data.states).indexOf(state.name);
          return operationStateIndex > -1;
        });

        const stateOptions = stateOpt.map((state) => ({
          label: state.name,
          value: state.abbrev,
        }));

        setStateSelectOptions(stateOptions);

        const createMap = (
          setMap: React.Dispatch<React.SetStateAction<mapboxgl.Map | undefined>>,
          mapContainer: React.RefObject<HTMLDivElement>
        ): void => {
          if (!mapContainer.current) {
            return;
          }

          const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/streets-v11",
            center: [defaultLng, defaultLat],
            zoom: DefaultMapZoom,
            maxZoom: 18,
            accessToken: sheetzMapBoxAccessToken,
          });

          setMap(map);
        };

        if (!map) {
          createMap(setMap, mapContainer);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const createMapEvents = (mapContainer: React.RefObject<HTMLDivElement>): void => {
      if (!mapContainer.current) {
        return;
      }

      map?.on("load", () => getStores());
      map?.on("moveend", () => resizeMapMarkers(map));
    };

    map && createMapEvents(mapContainer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, loadedStores]);

  useEffect(() => {
    if (map) {
      createMapMarkers(map);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedStores]);

  function getStores(): void {
    if (cancelInitialLocationSearch) {
      return;
    }

    if (Object.entries(queryParams).length !== 0) {
      filterQueryParams();
      return;
    } else {
      if (storeId) {
        reloadStoresWithFilters(storeId, null, undefined, false);
        return;
      }
      resetFilters();
    }

    getCurrentGeolocation(
      (position) => {
        if (cancelInitialLocationSearch) {
          return;
        }
        setPosition(position);
        setHasLocationTurnedOn(true);
        searchStores(0, position.coords.latitude, position.coords.longitude)
          .then((response) => storeSearchCompleted(response.data.stores))
          .finally(() => {
            setLocationRetrievalFinished(true);
            setHasMoreResults(true);
          });
      },
      () => {
        if (cancelInitialLocationSearch) {
          return;
        }
        searchStores(0, defaultLat, defaultLng)
          .then((response) => storeSearchCompleted(response.data.stores))
          .finally(() => {
            setLocationRetrievalFinished(true);
            setHasMoreResults(true);
          });
      },
      { maximumAge: 600 * 1000 }
    );
  }

  function filterQueryParams(): void {
    let filters: Filter[] = [];
    let stateValue: string | undefined;

    const search =
      queryParams.search && typeof queryParams.search === "string"
        ? (queryParams.search as string)
        : undefined;

    const state =
      queryParams.state && typeof queryParams.state === "string" && !queryParams.search
        ? (queryParams.state as string).toUpperCase()
        : undefined;

    const storeFilterQueryParams =
      queryParams.filters && typeof queryParams.filters === "string"
        ? (queryParams.filters as string)
        : undefined;

    if (storeFilterQueryParams) {
      const filtersArray = storeFilterQueryParams.split(",");

      evTypeFilters.forEach((evTypeFilter) => {
        return filtersArray.forEach((filter) => {
          if (evTypeFilter.id === filter) {
            evTypeFilter.selected = true;
          }
        });
      });

      fuelTypeFilters.forEach((fuelTypeFilter) => {
        return filtersArray.forEach((filter) => {
          if (fuelTypeFilter.id === filter) {
            fuelTypeFilter.selected = true;
          }
        });
      });

      storeFilters.forEach((storeFilter) => {
        return filtersArray.forEach((filter) => {
          if (storeFilter.id === filter) {
            storeFilter.selected = true;
          }
        });
      });

      setEvTypeFilters(evTypeFilters);
      setFuelTypeFilters(fuelTypeFilters);
      setStoreFilters(storeFilters);

      filters = [...EVTypeFilters, ...FuelTypeFilters, ...StoreFilters];
    }

    if (state) {
      const selectOption = stateSelectOptions.find((selectOption) => {
        return selectOption.value === state;
      });
      stateValue = selectOption?.value;
    }

    setInitialFormValues({ search, state: stateValue });
    reloadStoresWithFilters(search, state, filters, false);
  }

  function storeSearchCompleted(stores: Store[], filter = false): void {
    if (map) {
      if (map.getLayer("unclustered-point")) {
        map.removeLayer("unclustered-point");
      }

      if (map.getSource("store-pins")) {
        map.removeSource("store-pins");
      }
    }

    const newStores = loadedStores.length > 0 && !filter ? loadedStores.concat(stores) : stores;

    const closestStoresList = newStores?.filter((store) => !store.favorite);
    const savedStoresList = newStores?.filter((store) => store.favorite);

    setClosestStores(closestStoresList);
    setSavedStores(savedStoresList);
    setLoadedStores(newStores);

    getGeolocationStatus().then((response) => {
      setGeolocationStatus(response.state.toString());
    });
  }

  function scrollTop(): void {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTop = 0;
    }
  }

  function createMapMarkers(map: mapboxgl.Map): void {
    map.resize();

    if (loadedStores && loadedStores.length === 0) {
      storeMarkers?.current?.forEach((marker) => {
        marker.remove();
      });
      clusterMarkers?.current?.forEach((marker) => {
        marker.remove();
      });

      return;
    }

    const features: Array<MapBoxFeature> = [];
    loadedStores?.forEach((store) => {
      features.push({
        type: "Feature",
        properties: { id: store.storeNumber, lat: store.latitude, lng: store.longitude },
        geometry: {
          type: "Point",
          coordinates: [store.longitude, store.latitude],
        },
      });
    });

    const featureCollection = {
      type: "FeatureCollection",
      features,
    };

    const featureCollectionJSON = JSON.stringify(featureCollection);
    const blob = new Blob([featureCollectionJSON]);
    const fileDownloadUrl = URL.createObjectURL(blob);

    map.addSource("store-pins", {
      type: "geojson",
      data: fileDownloadUrl,
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    });

    // Adds a layer of unclustered-points that represent each store not shown in a cluster
    map.addLayer({
      id: "unclustered-point",
      type: "circle",
      source: "store-pins",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-opacity": 0,
      },
    });

    setTimeout(() => {
      const bounds = new mapboxgl.LngLatBounds();

      featureCollection.features.forEach((feature) => bounds.extend(feature.geometry.coordinates));

      map.fitBounds(bounds, { padding: useDesktopView ? 100 : 20 });
    }, 500);
  }

  function resizeMapMarkers(map: mapboxgl.Map): void {
    const myFeatures = map.querySourceFeatures("store-pins");

    if (myFeatures && myFeatures.length > 0 && loadedStores && loadedStores.length > 0) {
      // Each time the map changes remove the existing pins so the map can determine which new pins to show
      storeMarkers?.current?.forEach((marker) => {
        marker.remove();
      });
      clusterMarkers?.current?.forEach((marker) => {
        marker.remove();
      });

      // Used to store a reference to the maps markers and clusters. Mapbox does not store this reference
      const markerPinList: mapboxgl.Marker[] = [];
      const clusterMarkerList: mapboxgl.Marker[] = [];

      myFeatures.forEach((feature) => {
        if (feature.properties?.cluster_id) {
          // Assert that for this block to run, the geometry must be of type "Point"
          const lazyLoadedCoordinates = feature.geometry;
          if (lazyLoadedCoordinates.type !== "Point") {
            return;
          }

          // Mapbox does not support replacing the cluster circle with an icon so add the icon where the circle would have been
          const clusterIcon = document.createElement("div");
          const clusterCount = document.createElement("span");

          clusterCount.textContent = feature.properties.point_count;
          clusterCount.className = "store-cluster-count";
          clusterIcon.className = "store-cluster-icon-marker";
          clusterIcon.style.backgroundSize = "100%";
          clusterIcon.appendChild(clusterCount);

          clusterIcon.id = feature.properties?.cluster_id;

          // Create the cluster marker to represent a cluster on the map
          const clusterMarker = new mapboxgl.Marker(clusterIcon)
            .setLngLat([lazyLoadedCoordinates.coordinates[0], lazyLoadedCoordinates.coordinates[1]])
            .addTo(map);
          clusterMarkerList.push(clusterMarker);
        } else if (!feature.properties?.cluster) {
          const store = loadedStores.find((store) => store.storeNumber === feature.properties?.id);

          // if the cluster is an individual point, then add a store icon marker instead
          if (store) {
            const el = document.createElement("div");
            el.className = "store-pin-icon-marker";
            el.id = store.storeNumber.toString();

            // this click handler is for when the user clicks a marker
            el.addEventListener("click", function (event: MouseEvent) {
              event.stopPropagation();
              const target = event.currentTarget;
              if (target instanceof HTMLElement) {
                determineStoreDetailLocation(target.id);
              }
            });

            // marker has a reference to the actual point inside mapbox
            const marker = new mapboxgl.Marker(el)
              .setLngLat([store.longitude, store.latitude])
              .addTo(map);
            markerPinList.push(marker);
          }
        }
      });
      storeMarkers.current = markerPinList;
      clusterMarkers.current = clusterMarkerList;
    }

    /**
     * Set singleStore so modal doesn't reopen if user closes and zooms
     *  We set it here to make sure everything is loaded (map, markers) and search is complete so it doesnt' get overridden
     */

    setSingleStore(true);
  }

  function handleLoadMore(page: number): void {
    /**
     * When geolocation is set to Allow and user searches through search form, hasMoreResults isn't getting updated
     * in InfiniteScroll, allowing the user to scroll and get a duplicate result and duplicate key error.
     * Added if (hasMoreResults) {...} to patch.
     */
    const latitude = position && position?.coords.latitude ? position?.coords.latitude : defaultLat;
    const longitude =
      position && position?.coords.longitude ? position?.coords.longitude : defaultLng;

    if (hasMoreResults) {
      const filters = [...fuelTypeFilters, ...storeFilters];
      searchStores(
        page,
        latitude,
        longitude,
        searchText,
        stateFilter,
        getSelectedFilters(filters)
      ).then((response) => {
        storeSearchCompleted(response.data.stores);
        setHasMoreResults(response.data.stores.length === STORE_PAGINATION_SIZE);
      });
    }
  }

  function closeStoreDetailsModal(): void {
    setStoreDetailLocation({} as StoreLocation);
    setShowStoreDetailsModal(false);
    if (storeId) {
      navigate("/findASheetz");
    }
  }

  function determineStoreDetailLocation(storeNumber: string): void {
    const store = loadedStores?.find((store) => store.storeNumber.toString() === storeNumber);
    setStoreDetailLocation(store ? store : ({} as StoreLocation));
  }

  function updateStoreFavorite(store: Store, favorite: boolean): void {
    let favorites: Store[] | undefined = [];

    if (favorite) {
      store.favorite = true;
      favorites = savedStores?.concat([store]).sort((a, b) => a.storeNumber - b.storeNumber);
    } else {
      if (savedStores?.length) {
        favorites = savedStores.filter((favorite) => favorite.storeNumber !== store.storeNumber);
      }
    }

    setSavedStores(favorites);
  }

  const storeDetailsModal = (
    <SheetzModal
      className="store-details-modal"
      isOpen={showStoreDetailsModal}
      closeFunction={closeStoreDetailsModal}
      contentLabel="Show Store Details"
      onRequestClose={closeStoreDetailsModal}
      shouldCloseOnOverlayClick={false}
      headerText={"Store #" + storeDetailLocation?.storeNumber}
    >
      <StoreDetailsModal
        userLocation={
          position?.coords.latitude && position?.coords.longitude
            ? [position.coords.latitude, position.coords.longitude]
            : undefined
        }
        storeAttribute={storeDetailLocation}
        onFavorite={updateStoreFavorite}
      />
    </SheetzModal>
  );

  const StoreElements: FC<MyStoresProps> = (myStoresProps) => {
    if (myStoresProps.stores && myStoresProps.stores.length > 0) {
      const storeElements = myStoresProps.stores?.map((store) => {
        return (
          <ListItem key={store.storeNumber} hideArrow>
            <div
              tabIndex={0}
              role="button"
              aria-pressed="false"
              onClick={(event?): void => {
                event?.preventDefault();
                determineStoreDetailLocation(store.storeNumber.toString());
              }}
            >
              {store && (
                <StoreDisplay
                  store={store}
                  showFavorite
                  showMileage
                  showStoreNumber
                  showCurbside
                  showAlcohol
                  showStoreAlerts
                />
              )}
            </div>
          </ListItem>
        );
      });
      return <>{storeElements}</>;
    } else {
      return <></>;
    }
  };

  function getSelectedFilters(storeSearchFilters: Filter[]): StoreSearchFiltersType {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectedFilters: any = {};
    if (storeSearchFilters && storeSearchFilters.length > 0) {
      Object.values(storeSearchFilters).forEach((val) => {
        if (val.selected) {
          selectedFilters[val.id] = val.selected;
        }
      });
    }

    return selectedFilters;
  }

  function reloadStoresWithFilters(
    search?: string | null,
    state?: string | null,
    storeSearchFilters?: Filter[],
    updateUrl = true
  ): void {
    const latitude = position && position?.coords.latitude ? position?.coords.latitude : defaultLat;
    const longitude =
      position && position?.coords.longitude ? position?.coords.longitude : defaultLng;
    const filters =
      storeSearchFilters && getSelectedFilters(storeSearchFilters) !== undefined
        ? getSelectedFilters(storeSearchFilters)
        : null;

    appContext.showLoading();

    setTimeout(() => {
      searchStores(0, latitude, longitude, search, state, filters)
        .then((response) => {
          scrollTop();
          storeSearchCompleted(response.data.stores, true);
          setHasMoreResults(response.data.stores.length === STORE_PAGINATION_SIZE);
        })
        .finally(() => {
          appContext.hideLoading();
          setLocationRetrievalFinished(true);
        });
    }, 1500);

    if (updateUrl) {
      const filterValues =
        filters && Object.entries(filters).length !== 0 ? Object.keys(filters).join(",") : null;
      const searchValue = search ? search : null;
      const stateValue = state && !search ? state : null;

      const urlParams = qs.stringify(
        { search: searchValue, state: stateValue, filters: filterValues },
        { encode: false, skipNulls: true }
      );
      const url = "/findASheetz?" + urlParams;
      navigate(url, { replace: true });
    }
  }

  function centerMapOnUserLocation(): void {
    if (geolocationStatus === "prompt" || geolocationStatus === "granted") {
      if (position) {
        map?.setCenter([position?.coords.longitude, position?.coords.latitude]);
      } else {
        getCurrentGeolocation((userPosition) => {
          if (cancelInitialLocationSearch) {
            return;
          }
          setPosition(userPosition);
          setHasLocationTurnedOn(true);
        });
      }
      searchStores(0, position?.coords.latitude, position?.coords.longitude)
        .then((response) => {
          scrollTop();
          storeSearchCompleted(response.data.stores, true);
        })
        .finally(() => {
          setLocationRetrievalFinished(true);
          setHasMoreResults(true);
        });
    } else {
      setTimeout(
        () =>
          appContext.showToast(
            "Oh Sheetz!",
            "Please turn on location services in your browser so we can find you.",
            ToastType.error
          ),
        1000
      );
    }
  }

  const FilterElements: FC<FilterProps> = (filterProps) => {
    const filterElements = filterProps.filters.map((filter) => {
      if (!filter.selected) {
        return <span key={filter.id} />;
      }
      return (
        <SheetzButton
          className="store-filter-button"
          buttonColor={ButtonColor.darkGray}
          transparentButton
          smallButton
          label={getIcon(IconType.x, "close-icon")}
          label2={filter.title}
          key={filter.id}
          onClick={(event): void => {
            event?.preventDefault();

            evTypeFilters.forEach((evFilter) => {
              if (evFilter.id === filter.id) {
                evFilter.selected = false;
              }
            });

            fuelTypeFilters.forEach((fuelFilter) => {
              if (fuelFilter.id === filter.id) {
                fuelFilter.selected = false;
              }
            });

            storeFilters.forEach((storeFilter) => {
              if (storeFilter.id === filter.id) {
                storeFilter.selected = false;
              }
            });

            const filters = [...evTypeFilters, ...fuelTypeFilters, ...storeFilters];
            reloadStoresWithFilters(searchText, stateFilter, filters);
          }}
        />
      );
    });
    return <>{filterElements}</>;
  };

  function closeShowFiltersModal(): void {
    setShowFiltersModal(false);
  }

  function setFilters(): void {
    setShowFiltersModal(false);

    const evTypeFilters: Array<Filter> = [...EVTypeFilters];
    const fuelTypeFilters: Array<Filter> = [...FuelTypeFilters];
    const storeFilters: Array<Filter> = [...StoreFilters];

    setEvTypeFilters(evTypeFilters);
    setFuelTypeFilters(fuelTypeFilters);
    setStoreFilters(storeFilters);

    const filters = [...EVTypeFilters, ...FuelTypeFilters, ...StoreFilters];

    reloadStoresWithFilters(searchText, stateFilter, filters);
  }

  const storeFiltersModal = (
    <SheetzModal
      className="filters-modal"
      isOpen={showFiltersModal}
      closeFunction={closeShowFiltersModal}
      contentLabel="Store Filters"
      onRequestClose={closeShowFiltersModal}
      shouldCloseOnOverlayClick={false}
      headerText="Store Filters"
      backgroundColor="red"
    >
      <FiltersModal
        evTypeFilters={evTypeFilters}
        fuelTypeFilters={fuelTypeFilters}
        storeFilters={storeFilters}
        submitFunction={setFilters}
      />
    </SheetzModal>
  );

  const storeListContainer = (
    <div
      id="scroll-container"
      className="store-lists-container"
      ref={scrollContainer}
      key={searchKey}
    >
      {storeFilters && storeFilters.length > 0 && (
        <>
          <div className="filter-buttons-container">
            <FilterElements filters={evTypeFilters} />
            <FilterElements filters={fuelTypeFilters} />
            <FilterElements filters={storeFilters} />
          </div>
        </>
      )}
      {savedStores && savedStores.length > 0 && (
        <div className="results-container saved-stores-container">
          <p>My Saved Stores</p>
          <ul className="store-list-container">{<StoreElements stores={savedStores} />}</ul>
        </div>
      )}
      <div className="results-container">
        <p>Closest Stores</p>
        {!hasLocationTurnedOn && locationRetrievalFinished && (
          <div className="empty-closest-stores-container">
            <div className="whats-nearby-label">{"What's nearby?"}</div>
            <div className="empty-closest-stores-body">
              Please turn on location services or search by address, city or zip.
            </div>
          </div>
        )}
        {!locationRetrievalFinished && !closestStores?.length && (
          <>
            <LoadingPlaceholder />
            <LoadingPlaceholder />
            <LoadingPlaceholder />
          </>
        )}
        {locationRetrievalFinished && closestStores && closestStores.length > 0 && (
          <InfiniteScroll
            loadMore={handleLoadMore}
            hasMore={hasMoreResults}
            initialLoad={false}
            useWindow={!useDesktopView}
            getScrollParent={(): HTMLElement | null =>
              useDesktopView ? document.getElementById("scroll-container") : null
            }
          >
            <ul className="store-list-container">
              <StoreElements stores={closestStores} />
            </ul>
          </InfiniteScroll>
        )}
        {hasLocationTurnedOn && locationRetrievalFinished && closestStores?.length === 0 && (
          <div className="empty-closest-stores-container">
            <div className="store-no-results-icon">
              {getIcon(IconType.search, "store-search-icon")}
            </div>
            <div className="whats-nearby-label">No results</div>
            <div className="empty-closest-stores-body">
              Try removing some filters or searching again.
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <Header type={HeaderType.main} title={"Find a Sheetz"} fixed={true} />

      <div className="find-a-sheetz-container">
        <div className="search-filter-container">
          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={(values: SearchFormValues, actions): void => {
              setCancelInitialLocationSearch(true);
              appContext.showLoading();
              setSearchKey(values.search);
              setStateFilter(undefined);
              setSearchText(values.search);
              actions.setFieldValue("state", stateSelectOptions[0]);
              reloadStoresWithFilters(values.search, null, [
                ...EVTypeFilters,
                ...StoreFilters,
                ...FuelTypeFilters,
              ]);
            }}
          >
            {(props: FormikProps<SearchFormValues>): ReactElement => (
              <form onSubmit={props.handleSubmit}>
                <div className="store-search-button">
                  <SheetzInput
                    type="text"
                    name="search"
                    placeholder="Search by address, city, zip"
                    label="Search by address, city, zip"
                    autoComplete="off"
                    isSearchInput={true}
                  />
                </div>
                <div className="button-row">
                  <div className="state-filter">
                    <SheetzSelect
                      name="state"
                      label="Search by State"
                      placeholder="Search by State"
                      buttonStyle
                      options={stateSelectOptions}
                      onChangeEvent={(val): void => {
                        props.handleChange(val);
                        props.setFieldValue("search", "");
                        setSearchKey(val.target.value);
                        setSearchText(undefined);
                        setStateFilter(val.target.value);
                        reloadStoresWithFilters(null, val.target.value, [
                          ...EVTypeFilters,
                          ...FuelTypeFilters,
                          ...StoreFilters,
                        ]);
                      }}
                    />
                  </div>
                  <div className="map-filter">
                    <SheetzButton
                      className="map-filter-button"
                      buttonColor={ButtonColor.darkGray}
                      transparentButton
                      label="Filters"
                      onClick={(event): void => {
                        event?.preventDefault();
                        setShowFiltersModal(true);
                      }}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>

          {useDesktopView && storeListContainer}
        </div>

        <div ref={mapContainer} className="map-container">
          <div className="my-location-container">
            {/* This is a custom button due to the button's actual size */}
            <button
              className="my-location-button"
              title="My Location"
              onClick={(): void => centerMapOnUserLocation()}
            >
              {getIcon(IconType.location, "location-icon")}
            </button>
          </div>
        </div>

        {!useDesktopView && storeListContainer}
      </div>
      {showFiltersModal && storeFiltersModal}
      {showStoreDetailsModal && storeDetailsModal}
    </>
  );
};

export default FindASheetz;
