import React, { useEffect, useState } from "react";

import { Option, Selector } from "assets/dtos/anywhere-dto";

import CondimentGrid from "components/Order/ItemCustomization/CondimentGrid/CondimentGrid";
import CustomizationOption from "components/Order/ItemCustomization/CustomizationOption/CustomizationOption";
import SelectorHeader from "components/Order/ItemCustomization/SelectorHeader/SelectorHeader";
import InlineSubSelector from "components/Order/ItemCustomization/Selectors/InlineSubSelector/InlineSubSelector";
import { setInlineSubSelectorRowPlacement } from "components/Order/ItemCustomization/Selectors/InlineSubSelector/InlineSubSelector.util";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton, {
  TextButtonColor,
} from "components/misc/button/SheetzTextButton/SheetzTextButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";
import ActionSheetGrid from "components/misc/view/ActionSheet/ActionSheetGrid/ActionSheetGrid";

import { useMediaQuery } from "hooks";

import { desktopMediaQuery } from "util/AppContext.util";
import { ItemSwitchOption } from "util/Customization.util";

interface ItemSwitchSelectorProps {
  selector: Selector;
  itemSwitchOptions: ItemSwitchOption[];
  selectedOption?: ItemSwitchOption;
  onItemSwitchSelected: (itemSwitchOption: ItemSwitchOption) => void;
}

const ItemSwitchSelector = (props: ItemSwitchSelectorProps) => {
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);
  const gridRef = React.createRef<HTMLDivElement>();
  const inlineSubSelectorRef = React.createRef<HTMLDivElement>();
  const [showWarning, setShowWarning] = useState(false);
  const [selectedItemSwitchOption, setSelectedItemSwitchOption] = useState<
    ItemSwitchOption | undefined
  >();

  const options = props.itemSwitchOptions.map((itemSwitchOption) => {
    const isSelected =
      !!props.selectedOption && props.selectedOption.option.text === itemSwitchOption.option.text;
    return (
      <CustomizationOption
        key={itemSwitchOption.option.text}
        type={props.selector.type}
        itemSwitchOption={itemSwitchOption}
        isSelected={isSelected}
        onItemSwitchOptionSelected={itemSwitchOptionSelected}
      />
    );
  });

  useEffect(() => {
    if (props.selectedOption?.option.inlineSubSelector) {
      setInlineSubSelectorRowPlacement(
        useDesktopView,
        gridRef.current,
        inlineSubSelectorRef.current,
        props.itemSwitchOptions,
        props.selectedOption
      );
    }
  }, [
    gridRef,
    inlineSubSelectorRef,
    props.itemSwitchOptions,
    props.selectedOption,
    useDesktopView,
  ]);

  function getItemSwitchForMainDisplay(): JSX.Element {
    return (
      <>
        <CondimentGrid ref={gridRef}>
          {options}
          {props.selectedOption && props.selectedOption.option.inlineSubSelector && (
            <InlineSubSelector
              ref={inlineSubSelectorRef}
              selector={props.selectedOption.option.inlineSubSelector}
              selectedOption={props.selectedOption.selectedInlineSubOption}
              selected={!!props.selectedOption.selectedInlineSubOption}
              onInlineSubSelectorSelected={inlineSubSelectorOptionSelected}
            />
          )}
        </CondimentGrid>
        <ActionSheet
          color={ActionSheetColor.error}
          title="SON OF A SHMISCUIT!"
          text="Changing your item will remove any customizations in place!"
          overlay={true}
          shouldDisplay={showWarning}
          required
          onOverlayClick={(): void => {
            setShowWarning(false);
          }}
        >
          <div className="warning-flex-container">
            <SheetzTextButton
              buttonColor={TextButtonColor.mediumGray}
              textSize="large-text"
              underline
              label="No, Thanks"
              onClick={(): void => setShowWarning(false)}
            />
            <SheetzButton
              className="continue-button"
              label="Continue"
              onClick={(): void => {
                setShowWarning(false);
                if (selectedItemSwitchOption) {
                  props.onItemSwitchSelected(selectedItemSwitchOption);
                }
              }}
            />
          </div>
        </ActionSheet>
      </>
    );
  }

  function inlineSubSelectorOptionSelected(option?: Option): void {
    if (props.selectedOption) {
      itemSwitchOptionSelected(props.selectedOption, option);
    }
  }

  function itemSwitchOptionSelected(
    itemSwitchOption: ItemSwitchOption,
    inlineSubOption?: Option
  ): void {
    /**
     * Only show the warning if the item switch is NOT displayed as an action sheet.
     * This is because the ChangeSizeButton component already shows a warning before
     * displaying this selector.
     */
    const selectedItemSwitchOption: ItemSwitchOption = {
      ...itemSwitchOption,
      selectedInlineSubOption: inlineSubOption,
    } as ItemSwitchOption;
    if (props.selector.displayAsActionSheet) {
      props.onItemSwitchSelected(selectedItemSwitchOption);
    } else {
      if (props.selectedOption) {
        setShowWarning(true);
      } else {
        props.onItemSwitchSelected(selectedItemSwitchOption);
      }
      setSelectedItemSwitchOption(selectedItemSwitchOption);
    }
  }

  return (
    <div className="item-switch-selector">
      {!props.selector.displayAsActionSheet && (
        <SelectorHeader headerText={props.selector.text ?? ""} maxSelections={1} />
      )}
      {props.selector.displayAsActionSheet ? (
        <ActionSheetGrid>{options}</ActionSheetGrid>
      ) : (
        getItemSwitchForMainDisplay()
      )}
    </div>
  );
};

export default ItemSwitchSelector;
