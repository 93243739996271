import React, { useState } from "react";

import "./InlineSubSelector.scss";

import { Condiment, Option, Selector } from "assets/dtos/anywhere-dto";

import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { IconType, getIcon } from "util/Icon.util";

interface InlineSubSelectorProps {
  // Props for an inline-sub-selector with options (inside of Single Select Condiment Selector, or Item Switch Selector)
  selector?: Selector;
  selectedOption?: Option;
  // Prop for condiment associated with doubleable or extraable selector options
  condiment?: Condiment;
  selected: boolean;
  onInlineSubSelectorSelected: (selectedOption?: Option) => void;
}

const InlineSubSelector = React.forwardRef<HTMLDivElement, InlineSubSelectorProps>(
  (props, inlineSubSelectorRef) => {
    const [showOptionsActionSheet, setshowOptionsActionSheet] = useState(false);

    function getSelectorText(): string {
      if (props.selector && props.selector.shortText) {
        return props.selectedOption?.text ?? props.selector.shortText;
      } else if (props.condiment) {
        const punctuation = props.selected ? "" : "?";
        const appendAdd = props.selected ? "" : "Add ";
        return appendAdd + props.condiment.retailModifiedItem.receiptText + punctuation;
      } else {
        return "";
      }
    }

    // Currently, only extraable and doubleable extra options contain a price.
    function getSelectorPrice(): string {
      if (props.condiment && props.condiment.retailModifiedItem.price > 0) {
        return "+$" + props.condiment.retailModifiedItem.price.toFixed(2);
      } else {
        return "";
      }
    }

    function createOptionButtons(): JSX.Element {
      let buttons = null;
      if (props.selector?.options) {
        const allOptions = props.selector.noOption
          ? props.selector.options.concat([props.selector.noOption])
          : props.selector.options;
        buttons = allOptions.map((option) => {
          return (
            <li key={option.text}>
              <SheetzButton
                buttonColor={ButtonColor.darkGray}
                transparentButton
                className="inline-sub-option-button"
                label={option.text}
                onClick={(): void => {
                  setshowOptionsActionSheet(false);
                  props.onInlineSubSelectorSelected(option);
                }}
              />
            </li>
          );
        });
      }

      return <ul className="inline-options-list">{buttons}</ul>;
    }

    function handleInlineSubSelectorSelected(): void {
      if (props.selector) {
        setshowOptionsActionSheet(true);
      } else {
        props.onInlineSubSelectorSelected();
      }
    }

    return (
      <>
        <div
          className="inline-sub-selector"
          role="button"
          aria-roledescription="Select a sub-option such as extra or toasted."
          tabIndex={0}
          ref={inlineSubSelectorRef}
          onClick={(): void => handleInlineSubSelectorSelected()}
        >
          <div className="arrow-up"></div>
          <p className="display-text">
            {props.selected && getIcon(IconType.check, "checkmark-icon")} {getSelectorText()}
          </p>
          <p className="price">{getSelectorPrice()}</p>
        </div>
        <ActionSheet
          color={ActionSheetColor.red}
          title={props.selector?.text ?? "Choose an option"}
          overlay={true}
          shouldDisplay={showOptionsActionSheet}
          onOverlayClick={(): void => setshowOptionsActionSheet(false)}
          cancelFunction={(): void => setshowOptionsActionSheet(false)}
        >
          {props.selector?.options && createOptionButtons()}
        </ActionSheet>
      </>
    );
  }
);

// Needed to suppress ESLint React/Display-Name warning. See: https://github.com/yannickcr/eslint-plugin-react/issues/2269
InlineSubSelector.displayName = "InlineSubSelector";

export default InlineSubSelector;
