import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./Homepage.scss";

import { Store } from "assets/dtos/anywhere-dto";
import { ReactComponent as FooterBackground } from "assets/swoops/foot_swoop_down.svg";

import Header, { HeaderType } from "components/layout/Header/Header";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";
import HomepageCarousel from "components/pages/Homepage/HomepageCarousel/HomepageCarousel";
import ReorderGuide from "components/pages/Homepage/ReorderGuide/ReorderGuide";

import { isLoggedIn } from "util/Authentication.util";
import { isInNativeMobileContext, nativeMobileAuthTokenInvalid } from "util/MobileApp.util";
import { getRecentStore, getReorderActionSheetShown } from "util/Storage.util";

const Homepage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const recentStoreData = getRecentStore();
  const [recentStore, setRecentStore] = useState<Store | undefined>(undefined);
  const locationState = location.state as { checkForRecentStore: boolean };
  const [showRecentStoreActionSheet, setShowRecentStoreActionSheet] = useState<boolean>(false);

  useEffect(() => {
    if (recentStoreData) {
      try {
        const parsedRecentStoreData: Store = JSON.parse(recentStoreData);
        setRecentStore(parsedRecentStoreData);

        // This allows us to redirect from login to show the reorder popup,
        // but refresh the screen and have the homepage react as it normally would
        if (locationState && locationState.checkForRecentStore) {
          if (parsedRecentStoreData) {
            setTimeout(() => {
              setShowRecentStoreActionSheet(true);
            }, 300);
          } else {
            navigate("/order/favorites");
            return;
          }
          window.history.replaceState({ ...locationState, checkForRecentStore: undefined }, "");
        }
      } catch (e) {
        throw new SheetzError("Invalid recent store data", {
          userReadableMessage: "Store not selected. Please select a store and try again.",
          primaryButton: SheetzErrorButtonType.SELECT_STORE,
        });
      }
    }
  }, [locationState, navigate, recentStoreData]);

  function redirectForReorderButton(): void {
    if (isLoggedIn()) {
      if (recentStore) {
        setShowRecentStoreActionSheet(true);
      } else {
        navigate("/order/information", {
          state: {
            redirectOnOrderFlowFinish: "/order/favorites",
          },
        });
      }
    } else {
      // Mobile app users should never be on homepage, since they have their own, but we guard against login navigation regardless.
      if (isInNativeMobileContext()) {
        nativeMobileAuthTokenInvalid();
        return;
      }
      navigate(
        { pathname: "/auth/login", search: "?destination=" + location.pathname + location.search },
        {
          state: {
            checkForRecentStore: true,
          },
        }
      );
    }
  }

  return (
    <>
      <Header type={HeaderType.homepage} />
      <div className={"homepageContent"}>
        <HomepageCarousel />
      </div>

      <div className={"footer"}>
        <FooterBackground className={"footerBackground"} />
        <div className={"footerBar"}>
          <SheetzButton
            className={"orderButton"}
            label={"New Order"}
            onClick={(): void => {
              navigate("/order");
            }}
          />
          <SheetzButton
            className={"orderButton"}
            buttonColor={ButtonColor.white}
            transparentButton
            transparentButtonTextColor="light"
            label={"Reorder"}
            onClick={redirectForReorderButton}
          />
        </div>
      </div>

      <ActionSheet
        color={ActionSheetColor.red}
        title={
          getReorderActionSheetShown()
            ? "Delivery Is Here!"
            : "Ordering Your Favorites Just Got Faster!"
        }
        overlay={true}
        shouldDisplay={showRecentStoreActionSheet}
        required={true}
      >
        <ReorderGuide recentStore={recentStore} />
      </ActionSheet>
    </>
  );
};

export default Homepage;
