import React, { ReactElement } from "react";

import "./VehicleIcon.scss";

import { PickupVehicle } from "assets/dtos/anywhere-dto";

import { IconType, getIcon } from "util/Icon.util";
import { UserVehicle } from "util/MyVehicles.util";

function getVehicleIcon(type: string, color: string): ReactElement {
  const iconColorClass = getUserIconColor(color);
  let icon: ReactElement = <></>;
  switch (type) {
    case "Car":
      icon = getIcon(IconType.car, "user-vehicle-icon " + iconColorClass);
      break;
    case "Van":
      icon = getIcon(IconType.van, "user-vehicle-icon " + iconColorClass);
      break;
    case "Truck":
      icon = getIcon(IconType.truck, "user-vehicle-icon " + iconColorClass);
      break;
    case "SUV":
      icon = getIcon(IconType.SUV, "user-vehicle-icon " + iconColorClass);
      break;
    case "Motorcycle":
      icon = getIcon(IconType.otherVehicle, "user-vehicle-icon " + iconColorClass);
      break;
    default:
      icon = getIcon(IconType.crossover, "user-vehicle-icon " + iconColorClass);
      break;
  }
  return icon;
}

function getUserIconColor(color: string): string {
  let vehicleColorClass = "";
  switch (color) {
    case "Black":
      vehicleColorClass = "blackIcon";
      break;
    case "Blue":
      vehicleColorClass = "blueIcon";
      break;
    case "Gray":
      vehicleColorClass = "grayIcon";
      break;
    case "Green":
      vehicleColorClass = "greenIcon";
      break;
    case "Maroon":
      vehicleColorClass = "maroonIcon";
      break;
    case "Navy":
      vehicleColorClass = "navyIcon";
      break;
    case "Orange":
      vehicleColorClass = "orangeIcon";
      break;
    case "Other":
      vehicleColorClass = "otherIcon";
      break;
    case "Purple":
      vehicleColorClass = "purpleIcon";
      break;
    case "Red":
      vehicleColorClass = "redIcon";
      break;
    case "Silver":
      vehicleColorClass = "silverIcon";
      break;
    case "Tan":
      vehicleColorClass = "tanIcon";
      break;
    case "White":
      vehicleColorClass = "whiteIcon";
      break;
    case "Yellow":
      vehicleColorClass = "yellowIcon";
      break;
  }
  return vehicleColorClass;
}

interface VehicleIconProps {
  vehicle: UserVehicle | PickupVehicle;
  className?: string;
  forceGrayIcon?: boolean;
  useSmallerIcon?: boolean;
}

const VehicleIcon = (props: VehicleIconProps): ReactElement => {
  function getClassName(): string {
    let className = props.useSmallerIcon
      ? "vehicle-icon vehicle-icon-small "
      : "vehicle-icon-small ";
    if (props.className) {
      className = className + props.className + " ";
    }
    if (props.forceGrayIcon) {
      className = className + "force-gray";
    }
    return className;
  }

  return (
    <span className={getClassName()}>
      {getVehicleIcon(props.vehicle.type, props.vehicle.color)}
    </span>
  );
};

export default VehicleIcon;
