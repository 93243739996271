export const PAYMENT_METHODS = "/payment/paymentMethods";
export const PAYMENT_METHOD_DELETE = (paymentMethodId: number): string => {
  return `/payment/paymentMethods/` + paymentMethodId;
};

export const CREDIT_CARD_POST = "/payment/creditCards";
export const CREDIT_CARD = (paymentMethodId: number): string => {
  return `/payment/creditCards/` + paymentMethodId;
};

export const GIFT_CARD = (paymentMethodId: number): string => {
  return `/payment/zCards/` + paymentMethodId;
};
export const UPDATE_GIFT_CARD = (): string => {
  return `/payment/zCards`;
};
export const GIFT_CARD_BUY = "/payment/zCards/buy";
export const GIFT_CARD_ADD_FUNDS = "/payment/zCards/addFunds";
export const GIFT_CARD_POST = "/payment/zCards/convertZCard";
