import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";
import classNames from "classnames";
import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Navigate, Route, Routes, useLocation, useMatch, useNavigate } from "react-router-dom";

import "./Order.scss";

import {
  DeliveryAddress,
  ItemEvent,
  MenuCategory,
  OrderType,
  PaymentMethod,
  PickupLocation,
  PurchaseOrderItem,
  SubmitOrderResponse,
} from "assets/dtos/anywhere-dto";

import ConfirmOffers from "components/Order/ConfirmOffers/ConfirmOffers";
import CreateDeliveryAddress from "components/Order/Delivery/CreateDeliveryAddress/CreateDeliveryAddress";
import DeliveryAddresses from "components/Order/Delivery/DeliveryAddresses/DeliveryAddresses";
import DeliveryTime from "components/Order/Delivery/DeliveryTime/DeliveryTime";
import DeliveryPickupSubHeader from "components/Order/DeliveryPickupSubHeader/DeliveryPickupSubHeader";
import DeliveryPickupToggle from "components/Order/DeliveryPickupToggle/DeliveryPickupToggle";
import ItemCustomizationWithErrorBoundary, {
  ComboCustomizationWithErrorBoundary,
} from "components/Order/ItemCustomization/ItemCustomizationWithErrorBoundry/ItemCustomizationWithErrorBoundary";
import AIUSelector from "components/Order/ItemCustomization/Selectors/AIUSelector";
import ItemSwitchSizeSelectors from "components/Order/ItemCustomization/Selectors/ItemSwitchSizeSelectors";
import Menu from "components/Order/Menu/Menu";
import OrderConfirmation from "components/Order/OrderConfirmation/OrderConfirmation";
import OrderInformation from "components/Order/OrderInformation/OrderInformation";
import { initialOrderItemState, orderItemReducer } from "components/Order/OrderItemReducer";
import OrderPickupLocation from "components/Order/OrderPickupLocation/OrderPickupLocation";
import { OrderSessionAction, orderSessionReducer } from "components/Order/OrderSessionReducer";
import OrderSubmitted from "components/Order/OrderSubmitted/OrderSubmitted";
import PaymentTypeChoice from "components/Order/PaymentType/PaymentTypeChoice";
import PickupTime from "components/Order/PickupTime/PickupTime";
import EditFavorite from "components/Order/Reorder/EditFavorite/EditFavorite";
import OrderHistoryDetail from "components/Order/Reorder/OrderHistoryDetail/OrderHistoryDetail";
import Reorder, { ReorderViews } from "components/Order/Reorder/Reorder";
import StoreSelection from "components/Order/StoreSelection/StoreSelection";
import ShoppingBag from "components/layout/Bag/ShoppingBag";
import Header, { HeaderType } from "components/layout/Header/Header";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton, {
  TextButtonColor,
} from "components/misc/button/SheetzTextButton/SheetzTextButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { useMediaQuery } from "hooks";

import { getUserProfile } from "util/Account.util";
import { AppContext, desktopMediaQuery } from "util/AppContext.util";
import { isLoggedIn } from "util/Authentication.util";
import { BagUpdates, ShoppingBag as ShoppingBagModel } from "util/Bag.util";
import {
  ItemCustomization,
  ItemSwitchOption,
  SizeSelectOption,
  findItemSwitchSelector,
  findSizeSelector,
} from "util/Customization.util";
import { getMenuCategoryById } from "util/Menu.util";
import { isInNativeMobileContext, setPageTitleForMobileHeader } from "util/MobileApp.util";
import {
  OrderSession,
  getDeliveryInfo,
  getOrderSessionFromStorage,
  isOrderSessionExpired,
} from "util/Order.util";
import { getReorderAIUs, isReorderedShoppingBagItem } from "util/Reorder.util";
import { clearLastActivityTime, setOrUpdateLastActivityTime } from "util/Storage.util";

export interface OrderSubviewProps {
  orderSession: OrderSession;
  dispatch: React.Dispatch<OrderSessionAction>;
}

export interface OrderLocationState {
  event?: ItemEvent;
  homepageBannerRedirect?: string;
  itemCustomization?: ItemCustomization;
  orderSession?: OrderSession;
  submitOrderResponse?: SubmitOrderResponse;
  paymentMethod?: PaymentMethod;
  isFavoriteStore?: boolean;
  isFavoriteOrder?: boolean;
  showBag?: boolean;
}

const pickupRoutes = ["/order/location", "/order/time", "/order/selectStore"];
const reOrderRoutes = ["/order/history", "/order/favorites"];
const routesWithHiddenBag = [
  "/order/confirmOffers",
  "/order/confirm",
  "/order/history/detail",
  "/order/favorites/detail",
  "/order/information",
  "/order/location",
  "/order/time",
  "/order/selectStore",
  "/order/success",
  "/order/delivery/address",
  "/order/delivery/time",
  "/order/delivery/createAddress",
];
const routesWithPickupDeliveryToggle = [
  "/order/location",
  "/order/time",
  "/order/selectStore",
  "/order/delivery/address",
  "/order/delivery/time",
  "/order/delivery/createAddress",
];

const Order = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const evergageEventElement = useRef<HTMLDivElement>(null);
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);

  // Try and match the menu pathing. so we can retrieve the categoryId
  const menuCategoryMatch = useMatch("/order/menu/:categoryId");
  const menuCategoryId = menuCategoryMatch?.params.categoryId;
  const itemCustomizationMatch = useMatch("/order/customize/:itemCustomizationId");
  const comboCustomizationMatch = useMatch(
    "/order/combo/:menuComboId/customize/:itemCustomizationId"
  );
  const orderSessionExpired = isOrderSessionExpired(getOrderSessionFromStorage());

  const [orderItemState, dispatchOrderItem] = useReducer(orderItemReducer, initialOrderItemState);
  const [orderSession, dispatch] = useReducer(
    orderSessionReducer,
    getOrderSessionFromStorage() ?? {}
  );

  const [ageVerificationStatus, setAgeVerificationStatus] = useState<string | undefined>();
  const [bagUpdates, setBagUpdates] = useState<BagUpdates | undefined>(undefined);
  const [comboCustomizationTitle, setComboCustomizationTitle] = useState<string | undefined>();
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddress | undefined>(undefined);
  const [itemCustomizationSuccess, setItemCustomizationSuccess] = useState(false);
  const [itemCustomizationTitle, setItemCustomizationTitle] = useState<string | undefined>();
  const [menuCategories, setMenuCategories] = useState<MenuCategory[]>([]);
  const [openShoppingBag, setOpenShoppingBag] = useState(false);
  const [sessionExpiredRedirect, setSessionExpiredRedirect] = useState(false);

  const storeNumber = orderSession.store?.storeNumber ?? orderSession.storeNumber;
  const orderProps = { orderSession, dispatch };
  const orderType: OrderType = orderSession.delivery ? "DELIVERY" : "PICKUP";
  const pickupLocation: PickupLocation = orderSession.pickupLocation ?? "IN_STORE";

  const shouldDisplayBag =
    routesWithHiddenBag.indexOf(location.pathname) === -1 &&
    !location.pathname.includes("/customize") &&
    location.pathname !== "/order"; // The /order route is not a concrete route, so no bag needed.

  const showDeliveryPickupSubHeader =
    location.pathname.indexOf("/order/menu") !== -1 ||
    (reOrderRoutes.indexOf(location.pathname) !== -1 && orderSession);

  const showDeliveryPickupToggle = routesWithPickupDeliveryToggle.indexOf(location.pathname) !== -1;

  const orderLocationState = location.state as OrderLocationState | undefined;
  const state = {
    homepageBannerRedirect: orderLocationState?.homepageBannerRedirect,
    event: orderLocationState?.event,
  };
  const orderClasses = classNames("order-main-content evergage-event", {
    "in-mobile-app": isInNativeMobileContext(),
    "no-top-margin": showDeliveryPickupToggle && !useDesktopView,
  });

  const availabilityItemList = bagUpdates ? (
    bagUpdates.availability.map((item) => <li key={item}>{item}</li>)
  ) : (
    <></>
  );

  const priceChangeItemList = bagUpdates ? (
    bagUpdates.prices.map((item) => <li key={item}>{item}</li>)
  ) : (
    <></>
  );

  const parentMenuCategory =
    menuCategoryId !== undefined
      ? menuCategories.find((category) =>
          category.childrenMenuCategoryIds?.includes(parseInt(menuCategoryId))
        )
      : undefined;

  if (orderSessionExpired) {
    clearLastActivityTime();
    dispatch({ type: "CLEAR_ORDER_SESSION", payload: undefined });
    setSessionExpiredRedirect(true);
  } else {
    setOrUpdateLastActivityTime();
  }

  // Set the page title for the mobile apps
  setPageTitleForMobileHeader(getHeaderTitle(location.pathname));

  useEffect(() => {
    if (!orderItemState.chosenItem) {
      closeCustomizationActionSheets();
      return;
    }

    dispatch({ type: "CLEAR_ITEM_CUSTOMIZATIONS", payload: undefined });

    const { chosenCombo, chosenItem, itemSwitchOption, sizeSelectOption } = orderItemState;

    if (chosenCombo && chosenItem) {
      const sizeSelector = findSizeSelector(chosenItem.template);
      const itemSwitchSelector = findItemSwitchSelector(chosenItem.template);

      dispatch({ type: "SET_CURRENT_CUSTOMIZED_COMBO", payload: chosenCombo });
      dispatch({ type: "SET_ITEM_CUSTOMIZATION_ID", payload: undefined });
      dispatch({ type: "CLEAR_COMBO_ITEM_CUSTOMIZATIONS", payload: undefined });
      dispatch({ type: "SET_CURRENT_COMBO_ITEM_INDEX", payload: 0 });
      if (sizeSelector && sizeSelectOption) {
        dispatch({
          type: "SET_COMBO_ITEM_CUSTOMIZATIONS",
          payload: [0, { [sizeSelector.text ?? ""]: sizeSelectOption }],
        });
        // Set this choice as the initial customization at the item level.
        dispatch({
          type: "REPLACE_ITEM_CUSTOMIZATIONS",
          payload: { [sizeSelector.text ?? ""]: sizeSelectOption },
        });
      } else if (itemSwitchSelector && itemSwitchOption) {
        dispatch({
          type: "SET_COMBO_ITEM_CUSTOMIZATIONS",
          payload: [0, { [itemSwitchSelector.text ?? ""]: itemSwitchOption }],
        });
        // Set this choice as the initial customization at the item level.
        dispatch({
          type: "REPLACE_ITEM_CUSTOMIZATIONS",
          payload: { [itemSwitchSelector.text ?? ""]: itemSwitchOption },
        });
      }
    } else if (orderItemState.chosenItem) {
      const sizeSelector = findSizeSelector(chosenItem.template);
      const itemSwitchSelector = findItemSwitchSelector(chosenItem.template);

      dispatch({
        type: "SET_ITEM_CUSTOMIZATION_ID",
        payload: chosenItem.itemCustomizationId,
      });
      dispatch({ type: "SET_CURRENT_CUSTOMIZED_COMBO", payload: undefined });
      if (sizeSelector && sizeSelectOption) {
        dispatch({
          type: "RESET_ITEM_CUSTOMIZATIONS",
          payload: [sizeSelector.text ?? "", sizeSelectOption],
        });
      } else if (itemSwitchSelector && itemSwitchOption) {
        dispatch({
          type: "RESET_ITEM_CUSTOMIZATIONS",
          payload: [itemSwitchSelector.text ?? "", itemSwitchOption],
        });
      }
    }

    closeCustomizationActionSheets();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItemState.itemSwitchOption, orderItemState.sizeSelectOption]);

  useEffect(() => {
    const { chosenCombo, chosenItem, itemSwitchOption, menuCategory, sizeSelectOption } =
      orderItemState;

    const soldFromCategoryPath = menuCategory !== undefined ? [menuCategory.menuCategoryId] : [];
    const itemEvent: ItemEvent = { source: "MENU", soldFromCategoryPath };
    const event = orderLocationState?.event ?? itemEvent;

    if (itemCustomizationSuccess && (itemSwitchOption || sizeSelectOption)) {
      if (chosenCombo && chosenItem) {
        navigate(
          "/order/combo/" +
            chosenCombo.menuComboId +
            "/customize/" +
            chosenItem.itemCustomizationId,
          {
            state: {
              previousCategory: menuCategory,
              event: event,
            },
          }
        );
      } else if (chosenItem) {
        navigate("/order/customize/" + chosenItem.itemCustomizationId, {
          state: {
            previousCategory: menuCategory,
            event: event,
          },
        });
      }
    }

    setItemCustomizationSuccess(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    itemCustomizationSuccess,
    orderItemState.itemSwitchOption,
    orderItemState.chosenCombo,
    orderItemState.chosenItem,
    orderItemState.sizeSelectOption,
  ]);

  useEffect(() => {
    if (isLoggedIn()) {
      getUserProfile().then((response) => {
        setAgeVerificationStatus(response.data.ageVerified);
      });
    } else {
      // Treat logged-out users as unverified for the purpose of age restriction.
      setAgeVerificationStatus("UNVERIFIED");
    }
  }, []);

  useEffect(() => {
    if (sessionExpiredRedirect) {
      setSessionExpiredRedirect(false);
      navigate("/order/information", { replace: true });
    }
  }, [navigate, sessionExpiredRedirect]);

  useEffect(() => {
    const isPickupRoute = pickupRoutes.indexOf(location.pathname) !== -1;

    if (isPickupRoute) {
      setDeliveryAddress(undefined);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (orderSession.delivery && orderSession.deliveryAddressId) {
      getDeliveryInfo().then((response): void => {
        const deliveryAddress = response.data.deliveryAddresses.find(
          (address: DeliveryAddress) => address.deliveryAddressId === orderSession.deliveryAddressId
        );

        setDeliveryAddress(deliveryAddress);
      });
    }
  }, [orderSession.delivery, orderSession.deliveryAddressId]);

  function getHeaderType(path: string): HeaderType {
    // TODO: Can this be improved to not match via regex?
    if (/\/order\/paymentType/i.test(path) || /\/order\/confirm$/i.test(path)) {
      return HeaderType.main;
    }
    if (useDesktopView) {
      return HeaderType.order;
    }
    if (/\/order\/menu/i.test(path)) {
      return HeaderType.order;
    } else if (/\/order\/(customize|combo|confirmOffers|success)/i.test(path)) {
      return HeaderType.modal;
    } else {
      return HeaderType.main;
    }
  }

  function triggerBagOpen(): void {
    setOpenShoppingBag(true);
    setTimeout(() => {
      setOpenShoppingBag(false);
    }, 300);
  }

  function getHeaderTitle(pathname: string): string | undefined {
    const orderSubrouteTitleMap: { [key: string]: string } = {
      "/order": "Pick up information",
      "/order/information": "Order information",
      "/order/delivery/address": "Choose an address",
      "/order/delivery/createAddress": "Add new address",
      "/order/delivery/editAddress": "Edit address",
      "/order/delivery/phoneNumber": "Mobile phone number",
      "/order/delivery/time": "Delivery time",
      "/order/selectStore": "Pick up information",
      "/order/time": "Pick up information",
      "/order/location": "Pick up information",
      "/order/menu": "Full menu",
      "/order/search": "Search",
      "/order/paymentType": "Choose payment",
      "/order/confirmOffers": "Available rewardz",
      "/order/confirm": "Confirm my order",
      "/order/success": "Order #" + orderLocationState?.submitOrderResponse?.orderNumber,
      "/order/favorites": "Favorites & history",
      "/order/history": "Favorites & history",
      "/order/history/detail": "My order",
      "/order/favorites/detail": "Edit favorite",
    };

    const title = orderSubrouteTitleMap[pathname];

    if (title !== undefined) {
      return title;
    } else {
      // Look up name for menu category, item, or combo
      if (
        menuCategoryMatch &&
        menuCategories.length > 0 &&
        menuCategoryMatch.params.categoryId !== undefined
      ) {
        return (document.title =
          getMenuCategoryById(menuCategories, parseInt(menuCategoryMatch.params.categoryId))
            ?.name ?? "Sheetz Online Ordering");
      } else if (itemCustomizationMatch) {
        return (document.title = itemCustomizationTitle ?? "Sheetz Online Ordering");
      } else if (comboCustomizationMatch) {
        return (document.title = comboCustomizationTitle ?? "Sheetz Online Ordering");
      }
    }
  }

  function redirectBasedOnOrderSession() {
    if (orderSession.delivery === undefined) {
      return <Navigate to="/order/information" state={state} replace />;
    }

    // Delivery path
    if (orderSession.delivery === true) {
      if (orderSession?.deliveryAddressId === undefined) {
        return <Navigate to="/order/delivery/address" state={state} replace />;
      }

      if (
        orderSession.deliveryAddressId &&
        (!orderSession.deliveryPhoneNumber || !orderSession.deliveryPhoneNumberConsent)
      ) {
        return <Navigate to="/order/delivery/address" />;
      }

      if (
        orderSession.deliveryAddressId &&
        orderSession.deliveryPhoneNumber &&
        orderSession.orderSessionId === undefined
      ) {
        return <Navigate to="/order/delivery/time" state={state} replace />;
      }

      return <Navigate to="/order/menu" />;
    }

    // Pickup path
    if (orderSession.store === undefined) {
      return <Navigate to="/order/selectStore" state={state} replace />;
    }

    if (!orderSession.pickupTime) {
      return <Navigate to="/order/time" state={state} replace />;
    }

    if (!orderSession.pickupLocation) {
      return <Navigate to="/order/location" state={state} replace />;
    }

    return <Navigate to="/order/menu" replace />;
  }

  function closeCustomizationActionSheets(): void {
    dispatchOrderItem({
      type: "SHOW_ITEM_CUSTOMIZATION_ACTION_SHEET",
      payload: false,
    });
  }

  function evergageAddToBagEvent(itemRmiId?: number, price?: number): void {
    const addToBagEvent = new CustomEvent("addToBag", {
      detail: {
        itemRmiId,
        price,
      },
    });
    // Dispatch the event.
    evergageEventElement.current?.dispatchEvent(addToBagEvent);
  }

  function evergagePurchaseEvent(items: PurchaseOrderItem[], orderNumber: number): void {
    const purchaseEvent = new CustomEvent("purchase", {
      detail: {
        items: items
          .filter((item) => !item.virtual)
          .map((item) => ({
            rmiId: item.retailModifiedItemId,
            quantity: item.quantity,
            price: item.price,
          })),
        orderId: orderNumber,
      },
    });
    // Dispatch the event.
    evergageEventElement.current?.dispatchEvent(purchaseEvent);
  }

  function evergageViewCategoryEvent(id?: number, imageUrl?: string, name?: string): void {
    // The inputs may be undefined if the user reloads the browser
    if (id === undefined) {
      return;
    }

    const viewCategoryEvent = new CustomEvent("viewCategory", {
      detail: {
        id,
        imageUrl,
        name,
      },
    });

    // Dispatch the event.
    evergageEventElement.current?.dispatchEvent(viewCategoryEvent);
  }

  function evergageViewItemEvent(
    categories?: number,
    imageUrl?: string,
    itemRmiId?: number,
    name?: string,
    price?: number
  ): void {
    const categoryId = orderItemState.menuCategory
      ? orderItemState.menuCategory.menuCategoryId
      : categories;

    const viewItemEvent = new CustomEvent("viewItem", {
      detail: {
        categories: categoryId,
        imageUrl,
        itemRmiId,
        name,
        price,
      },
    });
    // Dispatch the event.
    evergageEventElement.current?.dispatchEvent(viewItemEvent);
  }

  function handleAddHistoryFavoriteToBag(tempShoppingBag: ShoppingBagModel, path?: string): void {
    const storeNumber = orderSession.store?.storeNumber ?? orderSession.storeNumber;

    if (storeNumber === undefined) {
      throw new SheetzError("Selected store not set.", {
        userReadableMessage: "Store not selected. Please select a store and try again.",
        primaryButton: SheetzErrorButtonType.SELECT_STORE,
      });
    }

    if (!orderSession.dayPart) {
      throw new SheetzError("DayPart not set.", {
        userReadableMessage:
          "Time for this order not selected. Please choose a time and try again.",
        primaryButton: SheetzErrorButtonType.SELECT_TIME,
      });
    }

    tempShoppingBag.items.forEach((item) => {
      if (isReorderedShoppingBagItem(item) && item.comboId !== undefined) {
        return;
      }

      if (isReorderedShoppingBagItem(item)) {
        dispatch({ type: "ADD_ITEM_TO_BAG", payload: item });

        evergageAddToBagEvent(item.retailModifiedItemId, item.price);
      }
    });

    tempShoppingBag.combos.forEach((combo) => {
      dispatch({ type: "ADD_COMBO_TO_BAG", payload: combo });

      // Dispatch evergage addToBag event
      combo.items.forEach((comboItem) => {
        const itemRmiId = isReorderedShoppingBagItem(comboItem)
          ? comboItem.retailModifiedItemId
          : comboItem.retailModifiedItem?.retailModifiedItemId;

        const price = isReorderedShoppingBagItem(comboItem)
          ? comboItem.price
          : comboItem.retailModifiedItem?.price;

        evergageAddToBagEvent(itemRmiId, price);
      });
    });

    appContext.showLoading();

    getReorderAIUs(storeNumber, orderSession.dayPart, orderType, pickupLocation)
      .then((response): void => {
        if (
          response.data.accompanyingItemUpsells &&
          response.data.accompanyingItemUpsells?.length > 0
        ) {
          dispatchOrderItem({
            type: "SET_AIU_INITIAL_STATE",
            payload: {
              accompanyingItemUpsells: response.data.accompanyingItemUpsells,
              navigate: path,
              showAIUActionSheet: true,
            },
          });

          return;
        } else {
          if (path !== undefined) {
            navigate(path, { replace: true });
          }

          setTimeout(() => appContext.showAddToBagPopup(false), 600);
        }
      })
      .finally((): void => appContext.hideLoading());
  }

  function handleItemSwitchSizeSelection(
    itemSwitchOption?: ItemSwitchOption,
    sizeSelectOption?: SizeSelectOption
  ): void {
    const { chosenCombo, chosenItem } = orderItemState;

    if (!chosenItem) {
      closeCustomizationActionSheets();
      return;
    }

    if (chosenItem && (itemSwitchOption || sizeSelectOption)) {
      const sizeSelector = findSizeSelector(chosenItem.template);
      const itemSwitchSelector = findItemSwitchSelector(chosenItem.template);

      dispatch({ type: "CLEAR_ITEM_CUSTOMIZATIONS", payload: undefined });

      // If the choice was part of a combo, set the combo ID and the initial customization choice.
      if (chosenCombo) {
        dispatch({ type: "SET_CURRENT_CUSTOMIZED_COMBO", payload: chosenCombo });
        dispatch({ type: "SET_ITEM_CUSTOMIZATION_ID", payload: undefined });
        dispatch({ type: "CLEAR_COMBO_ITEM_CUSTOMIZATIONS", payload: undefined });
        dispatch({ type: "SET_CURRENT_COMBO_ITEM_INDEX", payload: 0 });
        if (sizeSelector && sizeSelectOption) {
          dispatch({
            type: "SET_COMBO_ITEM_CUSTOMIZATIONS",
            payload: [0, { [sizeSelector.text ?? ""]: sizeSelectOption }],
          });
          // Set this choice as the initial customization at the item level.
          dispatch({
            type: "REPLACE_ITEM_CUSTOMIZATIONS",
            payload: { [sizeSelector.text ?? ""]: sizeSelectOption },
          });
        } else if (itemSwitchSelector && itemSwitchOption) {
          dispatch({
            type: "SET_COMBO_ITEM_CUSTOMIZATIONS",
            payload: [0, { [itemSwitchSelector.text ?? ""]: itemSwitchOption }],
          });
          // Set this choice as the initial customization at the item level.
          dispatch({
            type: "REPLACE_ITEM_CUSTOMIZATIONS",
            payload: { [itemSwitchSelector.text ?? ""]: itemSwitchOption },
          });
        }
      } else {
        dispatch({
          type: "SET_ITEM_CUSTOMIZATION_ID",
          payload: chosenItem.itemCustomizationId,
        });

        dispatch({ type: "SET_CURRENT_CUSTOMIZED_COMBO", payload: undefined });

        if (sizeSelector && sizeSelectOption) {
          dispatch({
            type: "RESET_ITEM_CUSTOMIZATIONS",
            payload: [sizeSelector.text ?? "", sizeSelectOption],
          });
        } else if (itemSwitchSelector && itemSwitchOption) {
          dispatch({
            type: "RESET_ITEM_CUSTOMIZATIONS",
            payload: [itemSwitchSelector.text ?? "", itemSwitchOption],
          });
        }
      }

      closeCustomizationActionSheets();
    }
  }

  function resetOrderItemState(): void {
    dispatchOrderItem({
      type: "RESET_INITIAL_ORDER_ITEM_STATE",
      payload: undefined,
    });
  }

  return (
    <>
      <Header
        // Pass an empty string instead of undefined to prevent getUserProfile being called twice
        ageVerificationStatus={ageVerificationStatus || ""}
        deliveryAddress={deliveryAddress}
        dispatch={dispatch}
        fixed={true}
        orderSession={orderSession}
        parentMenuCategory={parentMenuCategory}
        shouldDisplayBag={shouldDisplayBag}
        store={orderSession.store}
        title={getHeaderTitle(location.pathname)}
        type={getHeaderType(location.pathname)}
      >
        <ShoppingBag
          deliveryAddress={deliveryAddress}
          dispatch={dispatch}
          openBagExternal={openShoppingBag}
          orderSession={orderSession}
          setBagUpdateMessages={setBagUpdates}
        />
      </Header>

      {showDeliveryPickupToggle && !useDesktopView && (
        <DeliveryPickupToggle
          desktop={useDesktopView}
          orderSession={orderSession}
          dispatch={dispatch}
        ></DeliveryPickupToggle>
      )}

      <div className={orderClasses} ref={evergageEventElement}>
        {showDeliveryPickupSubHeader && !useDesktopView && (
          <DeliveryPickupSubHeader
            deliveryAddress={deliveryAddress}
            dispatch={dispatch}
            orderSession={orderSession}
            showRip
          ></DeliveryPickupSubHeader>
        )}

        {/* Parent route is `/order` */}
        <Routes>
          <Route path="/" element={redirectBasedOnOrderSession()} />
          <Route path="information" element={<OrderInformation {...orderProps} />} />
          <Route
            path="delivery/address"
            element={
              !isLoggedIn() ? (
                <Navigate
                  to="/auth/login?destination=/order/delivery/address"
                  state={state}
                  replace
                />
              ) : (
                <DeliveryAddresses {...orderProps} />
              )
            }
          />
          <Route
            path="delivery/createAddress"
            element={<CreateDeliveryAddress {...orderProps} />}
          />
          <Route
            path="delivery/time"
            element={
              orderSession?.deliveryAddressId !== undefined &&
              orderSession.deliveryPhoneNumber !== undefined ? (
                <DeliveryTime deliveryAddress={deliveryAddress} {...orderProps} />
              ) : (
                <Navigate to="/order" replace />
              )
            }
          />
          <Route path="selectStore" element={<StoreSelection {...orderProps} />} />
          {/* If this is a mobile app order, a storeNumber is not needed. */}
          <Route
            path="time"
            element={
              orderSession.store?.storeNumber !== undefined || isInNativeMobileContext() ? (
                <PickupTime deliveryAddress={deliveryAddress} {...orderProps} />
              ) : (
                <Navigate to="/order" replace />
              )
            }
          />
          <Route path="location" element={<OrderPickupLocation {...orderProps} />} />
          <Route
            path="menu"
            element={
              <Menu
                {...orderProps}
                ageVerificationStatus={ageVerificationStatus}
                deliveryAddress={deliveryAddress}
                dispatchOrderItem={dispatchOrderItem}
                evergageAddToBagEvent={evergageAddToBagEvent}
                evergageViewCategoryEvent={evergageViewCategoryEvent}
                evergageViewItemEvent={evergageViewItemEvent}
                menu={menuCategories}
                orderItemState={orderItemState}
                setMenuCategories={setMenuCategories}
                triggerBag={triggerBagOpen}
              />
            }
          />
          <Route
            path="menu/:categoryId"
            element={
              <Menu
                {...orderProps}
                ageVerificationStatus={ageVerificationStatus}
                deliveryAddress={deliveryAddress}
                dispatchOrderItem={dispatchOrderItem}
                evergageAddToBagEvent={evergageAddToBagEvent}
                evergageViewCategoryEvent={evergageViewCategoryEvent}
                evergageViewItemEvent={evergageViewItemEvent}
                menu={menuCategories}
                orderItemState={orderItemState}
                setMenuCategories={setMenuCategories}
              />
            }
          />
          <Route
            path="customize/:itemCustomizationId"
            element={
              <ItemCustomizationWithErrorBoundary
                {...orderProps}
                dispatchOrderItem={dispatchOrderItem}
                evergageAddToBagEvent={evergageAddToBagEvent}
                evergageViewItemEvent={evergageViewItemEvent}
                handleItemSwitchSizeSelection={handleItemSwitchSizeSelection}
                itemCustomization={orderLocationState?.itemCustomization}
                orderItemState={orderItemState}
                resetOrderItemState={resetOrderItemState}
                setItemCustomizationTitle={setItemCustomizationTitle}
              />
            }
          />
          <Route
            path="combo/:menuComboId/customize/:itemCustomizationId"
            element={
              <ComboCustomizationWithErrorBoundary
                {...orderProps}
                dispatchOrderItem={dispatchOrderItem}
                evergageAddToBagEvent={evergageAddToBagEvent}
                evergageViewItemEvent={evergageViewItemEvent}
                handleItemSwitchSizeSelection={handleItemSwitchSizeSelection}
                orderItemState={orderItemState}
                resetOrderItemState={resetOrderItemState}
                setComboTitle={setComboCustomizationTitle}
              />
            }
          />
          <Route
            path="favorites"
            element={
              <Reorder
                handleAddHistoryFavoriteToBag={handleAddHistoryFavoriteToBag}
                tab={ReorderViews["My Favorites"]}
                {...orderProps}
              />
            }
          />
          <Route
            path="favorites/detail"
            element={
              <EditFavorite
                {...orderProps}
                handleAddHistoryFavoriteToBag={handleAddHistoryFavoriteToBag}
              />
            }
          />
          <Route
            path="history"
            element={
              <Reorder
                handleAddHistoryFavoriteToBag={handleAddHistoryFavoriteToBag}
                tab={ReorderViews["Recent Orders"]}
                {...orderProps}
              />
            }
          />
          <Route
            path="history/detail"
            element={
              <OrderHistoryDetail
                {...orderProps}
                handleAddHistoryFavoriteToBag={handleAddHistoryFavoriteToBag}
              />
            }
          />
          <Route
            path="paymentType"
            element={
              storeNumber === undefined ||
              (!orderSession.delivery && !orderSession.pickupLocation) ||
              (!orderSession.delivery && !orderSession.pickupTime) ||
              !orderSession.shoppingBag ||
              (orderSession.shoppingBag.items.length === 0 &&
                orderSession.shoppingBag.combos.length === 0) ? (
                <Navigate to="/order" replace />
              ) : (
                <PaymentTypeChoice
                  deliveryAddress={deliveryAddress}
                  menu={menuCategories}
                  {...orderProps}
                />
              )
            }
          />
          <Route path="confirmOffers" element={<ConfirmOffers {...orderProps} />} />
          <Route
            path="confirm"
            element={
              orderSession.purchaseOrder && orderSession.purchaseOrder?.items.length ? (
                <OrderConfirmation
                  deliveryAddress={deliveryAddress}
                  evergagePurchaseEvent={evergagePurchaseEvent}
                  {...orderProps}
                />
              ) : (
                <Navigate to="/order/paymentType" replace />
              )
            }
          />
          <Route
            path="success"
            element={
              orderLocationState?.orderSession && orderLocationState?.submitOrderResponse ? (
                <OrderSubmitted
                  orderSession={orderLocationState.orderSession}
                  submitOrderResponse={orderLocationState.submitOrderResponse}
                  paymentMethod={orderLocationState.paymentMethod}
                />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route path="/*" element={<Navigate to="/order/information" replace />} />
        </Routes>

        {isInNativeMobileContext() && shouldDisplayBag && (
          <ShoppingBag
            deliveryAddress={deliveryAddress}
            dispatch={dispatch}
            openBagExternal={openShoppingBag}
            orderSession={orderSession}
            setBagUpdateMessages={setBagUpdates}
          />
        )}

        <ItemSwitchSizeSelectors
          dispatchOrderItem={dispatchOrderItem}
          orderItemState={orderItemState}
          setItemCustomizationSuccess={setItemCustomizationSuccess}
        />

        <ActionSheet
          color={ActionSheetColor.red}
          title={"Add a Goodie?"}
          overlay={true}
          shouldDisplay={orderItemState.showAIUActionSheet ?? false}
        >
          <AIUSelector
            AIUs={orderItemState.accompanyingItemUpsells ?? []}
            dispatch={dispatch}
            dispatchOrderItem={dispatchOrderItem}
            evergageAddToBagEvent={evergageAddToBagEvent}
            orderItemState={orderItemState}
            orderSession={orderSession}
            resetOrderItemState={resetOrderItemState}
          />

          <SheetzTextButton
            className="aiu-no-thanks-button"
            buttonColor={TextButtonColor.mediumGray}
            textSize="large-text"
            underline
            type="button"
            label="No Thanks"
            onClick={(): void => {
              dispatchOrderItem({
                type: "SHOW_AIU_ACTION_SHEET",
                payload: false,
              });

              if (orderItemState.navigate) {
                navigate(orderItemState.navigate, { replace: true });
              }

              resetOrderItemState();
              setTimeout(() => appContext.showAddToBagPopup(false), 600);
            }}
          />
        </ActionSheet>
      </div>

      <ActionSheet
        color={ActionSheetColor.red}
        title="Heads up!"
        overlay={true}
        shouldDisplay={
          !!bagUpdates && (bagUpdates.availability.length > 0 || bagUpdates.prices.length > 0)
        }
        required={true}
      >
        <div className="bag-updates-actionsheet">
          {bagUpdates && bagUpdates.availability.length > 0 && (
            <>
              <div className="availability-text">
                The following item(s) are unavailable and cannot be ordered:
              </div>
              <ul>{availabilityItemList}</ul>
            </>
          )}
          {bagUpdates && bagUpdates.prices.length > 0 && (
            <>
              <div className="price-text">
                The price has been updated for the following item(s):
              </div>
              <ul>{priceChangeItemList}</ul>
              <div className="price-text">You can view the new price in your bag.</div>
            </>
          )}
          <div className="bag-update-buttons">
            <SheetzTextButton
              className="bag-update-edit-button"
              buttonColor={TextButtonColor.mediumGray}
              textSize="large-text"
              underline
              label="Edit Bag"
              onClick={(): void => {
                setBagUpdates(undefined);
                triggerBagOpen();
              }}
            />
            <SheetzButton
              className="bag-update-continue-button"
              label="Continue"
              onClick={(): void => setBagUpdates(undefined)}
            />
          </div>
        </div>
      </ActionSheet>
    </>
  );
};

export default Order;
