import { SelectOption } from "components/misc/form/SheetzInput/SheetzInput";

export interface FAQElementProp {
  title: string;
  bodyText: string;
  buttons?: Array<FAQButton>;
}

export interface FAQTopics {
  topics: SelectOption[];
}

export interface FAQElementList {
  questionAnswerList: Array<FAQElementProp>;
}

export interface FAQButton {
  buttonText: string;
  buttonLink: string;
  externalLink?: boolean;
}

export enum FAQTopicsEnum {
  "General",
  "My Sheetz Rewardz Card",
  "Jobs",
  "Ordering & Nutrition",
  "Fuel",
  "All",
}
export const faqTopics: SelectOption[] = [
  { label: "All", value: "All" },
  { label: "General", value: "General" },
  { label: "My Sheetz Rewardz Card", value: "My Sheetz Rewardz Card" },
  { label: "Jobs", value: "Jobs" },
  { label: "Ordering & Nutrition", value: "Ordering & Nutrition" },
  { label: "Fuel", value: "Fuel" },
];

export const generalItems: Array<FAQElementProp> = [
  {
    title: "How do I check the balance on my Sheetz Gift Card (Z-Card)?",
    bodyText:
      "You can check the balance of your Sheetz Z-Card by calling us toll-free at 1-888-239-2856, or by looking up your card below.",
    buttons: [
      {
        buttonText: "Check my balance",
        buttonLink: "https://www.sheetz.com/gift-cards/balance",
        externalLink: true,
      },
    ],
  },
  {
    title: "How can I get a Sheetz franchise?",
    bodyText:
      "You could marry into the Sheetz family. Sheetz is a privately held, family owned and operated company. We take great pride in our private status and have no plans to offer franchise rights at this time.",
  },
  {
    title: "Can I purchase stock in Sheetz?",
    bodyText:
      "We prefer to give it away. The only catch is that you have to be an employee. Sheetz is a privately held company. As such, no public stock offerings are available. Employees are eligible for shares through our Employee Stock Ownership Plan (ESOP).",
    buttons: [
      {
        buttonText: "Check Job Openings",
        buttonLink: "https://jobs.sheetz.com/",
        externalLink: true,
      },
    ],
  },
  {
    title: "What is your operating area?",
    bodyText:
      "Sheetz operates more than 500 high-energy stores throughout six states: Pennsylvania, Virginia, West Virginia, Maryland, Ohio, and North Carolina",
  },
  {
    title: "I have a great location for a new store. How can I tell you about it?",
    bodyText:
      "We are always looking for new building locations. Visit our Real-Estate page and fill out the site form after viewing the requirements. If your property meets our criteria and we’re interested, a member of our site selection team will contact you.",
    buttons: [
      {
        buttonText: "Sheetz Real Estate",
        buttonLink: "https://www.sheetz.com/real-estate",
        externalLink: true,
      },
    ],
  },
  {
    title: "What does the word Sheetz mean?",
    bodyText: `Sheetz means many things to many people. To some, it's a place to get great food 24/7. To others, it's a coffee stop in the morning. Some think of us as a gas station. Some consider us a convenience store. We like to think we're there in any way a customer might need us. The word "Sheetz" is actually the name of the family that owns the company and leads it with a fun, customer-centric approach.`,
    buttons: [
      {
        buttonText: "What's a Sheetz?",
        buttonLink: "https://www.sheetz.com/sheetz",
        externalLink: true,
      },
    ],
  },
  {
    title: `Why does my bank say that Sheetz is "holding" pre-authorization funds?`,
    bodyText: `We occasionally receive questions regarding charges that appear on on-line banking or voice response systems that are not equal to the amount of gas purchased. The amounts are usually $75, but could be for other amounts between $25 and $200.
     \nThese entries on your bank inquiry are typically called "Pending Charges", or "pre-authorization holds" by banks. These type charges are controlled and applied to consumer bank accounts by the consumer's bank. When you pay at the pump, some card issuers place a “hold” on their cardholders’ funds in anticipation of the final transaction amount, to help ensure their cardholders’ accounts do not become overdrawn.
     \nSheetz, as all other gas providers, does not place holds on customer accounts. We follow the rules and guidelines of the card associations (i.e. Visa, MasterCard, etc.) in the manner that we obtain card validation information prior to turning on the gas pump. You may find VISA’s confirmation of this on Visa.com. Click on “Ask Visa” on the top right above the Search Bar. Each Bank, Savings Bank, Savings & Loan, and/or Credit Union may handle the request for card validation information in a different manner. Some will create "holds" on funds for gas purchases - the amounts will vary, and the number of days the hold lasts will vary. Your financial institution should be able to provide you with their policies. These are typically found in a document titled "Deposit Account Agreement" or "Electronic Funds Transfer Agreement". If the question is related to a credit card account, the document may be called a "Truth in Lending" or a "Credit Account Agreement".
     \nAll of this information will not solve your issue if there is a hold on your account, but hopefully will help you with the conversation you may have with your bank.
     \nIf there is a hold on your account currently, and you are concerned about its impact on other checks and debits that you expect to post to your account, here are some questions to ask your banker:
     \n-What will happen if a check posts against an account with negative "unavailable funds", but positive "collected funds"? If the only thing that would put you in the negative is the hold, what will happen? Be sure they hear and understand "unavailable" and "collected" as these mean different things to bankers.
     \n-Would this have happened if I used my Check Card with a PIN? They should say "no", holds for transactions with a PIN are to exist no longer than a few hours. You may refer them to debit network rules.
     \nA helpful hint: The local bank branch may not be the best place for you to take your questions on this topic. If the bank has a central customer service area, ATM operations, Card Operations, or some similarly named area, they usually are better equipped to respond to pre-authorization hold questions. Although we cannot influence or control the policies of individual financial institutions, our experience tells us that the practices are very different from bank to bank in our market area.
     `,
  },
  {
    title: "If you are open 24/7, why do your doors have locks?",
    bodyText:
      "Do you know how difficult it is to find doors without locks? Besides, we like to think of them as exterior design elements.",
  },
];

export const mySheetzRewardzItems: Array<FAQElementProp> = [
  {
    title: "How do I check the balance on my Sheetz Gift Card (Z-Card)?",
    bodyText:
      "You can check the balance of your Sheetz Z-Card by calling us toll-free at 1-888-239-2856, or by looking up your card below.",
    buttons: [
      {
        buttonText: "Check my balance",
        buttonLink: "https://www.sheetz.com/gift-cards/balance",
        externalLink: true,
      },
    ],
  },
  { title: "How much does the My Sheetz Rewardz Card cost?", bodyText: "It's Free!" },
  {
    title: "Do I need a separate online account for each My Sheetz Rewardz Card I get?",
    bodyText:
      "Nope! You can add as many cards as you need on a single account, mark which one should be your default card, or even remove cards if they turn up lost!",
  },
  {
    title: "Why didn't I get my $.03 discount on gas when I used my My Sheetz Rewardz Card?",
    bodyText:
      "Did you validate your email address? When you sign up or change your email address, we will send you an email with a link to validate your email. 24 hours after validating, we will register you for the 3¢ fuel discount. If you lost the validation email, you can have it resent to you from your online dashboard.",
  },
  {
    title: "How do I replace a lost card?",
    buttons: [
      {
        buttonText: "Download the app",
        buttonLink: "https://www.sheetz.com/app",
        externalLink: true,
      },
    ],
    bodyText:
      "Pick up a new, non-active card from the store. You can add the new card to your account when you log into the website or through the mobile app. After adding a new card, you can remove the old one.",
  },
  {
    title: "I have a new card, but can't remember my login information.",
    buttons: [
      {
        buttonText: "Reset my password",
        buttonLink: "https://order.sheetz.com/#/main/account/auth/forgot",
        externalLink: true,
      },
    ],
    bodyText:
      "Your password can be reset so that you can regain access to your account and add your new My Sheetz Rewardz Card.",
  },
  {
    title: "Does my card expire?",
    bodyText: "Not if you use it. We may close cards that haven't been used in 24 months.",
  },
  {
    title: "How do I sign up to get all those great freebiez?",
    buttons: [
      {
        buttonText: "Download the app",
        buttonLink: "https://www.sheetz.com/app",
        externalLink: true,
      },
    ],
    bodyText:
      "Sign up! You can create a My Sheetz Rewardz Card account on our website or when you download our mobile app. Once you've validated your email address, We'll send freebiez and exclusive offers to your inbox or mobile app.",
  },
  {
    title: "I have completed the online registration. Why I am not receiving emails?",
    bodyText:
      "Check your spam filter first to make sure our emails reach your inbox. Did you opt out of receiving communications from us during sign-up or unsubscribe? If so, you can either change your email address and revalidate it to receive future emails or contact us.",
  },
  {
    title: "When do I get my free stuff?",
    buttons: [
      {
        buttonText: "Download the app",
        buttonLink: "https://www.sheetz.com/app",
        externalLink: true,
      },
    ],
    bodyText:
      "We are constantly cooking up offers we think you’ll like. Watch for them in your inbox or on our mobile app",
  },
  {
    title: "Can I sign up for a My Sheetz Rewardz Card online?",
    buttons: [
      {
        buttonText: "Download the app",
        buttonLink: "https://www.sheetz.com/app",
        externalLink: true,
      },
      { buttonText: "Sign Up", buttonLink: "/auth/register" },
    ],
    bodyText:
      "Yes! Sign up on the website or download our mobile app. You'll still need a physical My Sheetz Rewardz Card to swipe at the pump for your discount. You can pick on up at any store and add it to your account on the website or on the app.",
  },
  {
    title: "Why didn't I get the same email offer that my friend did?",
    bodyText:
      "Your My Sheetz Rewardz Card is specific to you... glorious you. The offers you get will vary based on the stuff you typically buy. That way, you get the deals you're most likely to benefit from!",
  },
  {
    title: "How do I share an email address with my family members?",
    bodyText:
      "You can only have one email address per My Sheetz Rewardz Card account. However your family can share an account, by getting a physical card for each of them and adding it to a single account. You’ll rack up points faster this way - but remember, it's first-come-first-serve when it comes to that free item!",
  },
  {
    title: "How can I get my discount if I forgot my card?",
    buttons: [
      {
        buttonText: "Download the app",
        buttonLink: "https://www.sheetz.com/app",
        externalLink: true,
      },
    ],
    bodyText:
      "You need your card with you to get your discounts and points at the counter. If you download our mobile app, you can get your My Sheetz Rewardz Card scanned right off your phone!",
  },
  {
    title: "Does the My Sheetz Rewardz Card have value?",
    bodyText:
      "This card has no cash value; however, it is packed full of awesome deals and discounts!",
  },
  {
    title: "Do my freebiez expire?",
    bodyText:
      "Yes, the expiration date for the freebiez will be noted on the email or communication you receive when the item is added to your account.",
  },
  {
    title: "Why did you change the My Sheetz Rewardz Card program?",
    buttons: [
      {
        buttonText: "Learn More",
        buttonLink: "https://www.sheetz.com/biggerMSC",
        externalLink: true,
      },
    ],
    bodyText:
      "Our number one request from customers has been to earn Rewardz for all of their purchases. So, our answer was YES! With the new program, cardholders can earn Pointz on almost any purchase* made inside the store and redeem Pointz for customized Rewardz. It’s a win!",
  },
  {
    title: "Will I get more with the new program?",
    buttons: [
      {
        buttonText: "Learn More",
        buttonLink: "https://www.sheetz.com/biggerMSC",
        externalLink: true,
      },
    ],
    bodyText:
      "Most customers will see a difference and have many opportunities to benefit from the new program. The more you use your card, the more Pointz, Rewardz, and exclusive offers you will get. This program allows us to show even more love to our most loyal customers!",
  },
  {
    title: "How do I customize my Rewardz?",
    buttons: [
      {
        buttonText: "Learn More",
        buttonLink: "https://www.sheetz.com/biggerMSC",
        externalLink: true,
      },
    ],
    bodyText:
      "Registering online gives you the opportunity to select the Rewardz you want, get extra offers like birthday deals, plus we’ll throw in surprise offers and freebies along the way. With your email address, we can send you targeted offers based on what you like to buy. We respect your privacy and won’t share your info or clog your inbox with emails.",
  },
  {
    title: "What happens to the Rewardz I have already earned?",
    buttons: [
      {
        buttonText: "Learn More",
        buttonLink: "https://www.sheetz.com/biggerMSC",
        externalLink: true,
      },
    ],
    bodyText:
      "No worries, we’ve got you covered! You won’t lose Rewardz that you’ve earned in our current program. If you have earned enough credits for a free item in our club program, we will automatically load the free item onto your card. Any additional credits will be converted into Pointz.",
  },
  {
    title: "How do my Pointz add up?",
    buttons: [
      {
        buttonText: "Learn More",
        buttonLink: "https://www.sheetz.com/biggerMSC",
        externalLink: true,
      },
    ],
    bodyText:
      "You will get 5 Pointz per dollar on inside purchases, plus lots of opportunities to earn bonus Pointz. Move from Fan, to Friend, to Freak as you increase your Pointz and reap the high value Rewardz that go along with each level. You can redeem Pointz by selecting items from a list on our mobile app or website or redeem in store for a fountain drink, self-serve Sheetz Bros. Coffeez® drink or select Shweetz® bakery item.",
  },
  {
    title: "Do I still receive my 3 cents off everyday gas discount?",
    buttons: [
      {
        buttonText: "Learn More",
        buttonLink: "https://www.sheetz.com/biggerMSC",
        externalLink: true,
      },
    ],
    bodyText:
      "Totally! You’ve made it clear that this is one of the coolest benefits of having a My Sheetz Rewardz Card. All registered card-holders who opt-in online will receive the every day fuel discount along with more opportunities to save through our value packed email program.",
  },
  {
    title: "Do my Pointz expire?",
    buttons: [
      {
        buttonText: "Learn More",
        buttonLink: "https://www.sheetz.com/biggerMSC",
        externalLink: true,
      },
    ],
    bodyText:
      "Rewards pointz expire the last day of the month 1 year after earning. We do this so we don’t carry a balance that goes unused indefinitely. Items you select with your reward pointz and all other freebie or offer expirations will be communicated at the time of offer.",
  },
  {
    title: "How can I use my keychain card at the pump?",
    bodyText:
      "Our pumps don't have a barcode scanner to read your My Sheetz Rewardz Card keychain, but you can always swipe your full-size My Sheetz Rewardz Card with the magnetic strip.",
  },
  {
    title: "Can I use more than one discount at a time?",
    bodyText:
      "Yes, you can use your Weis® card and your My Sheetz Rewardz Card in the same transaction. You can even use your SHEETZ VISA® CARD for over $.30/gallon in rewards, for a triple dose of savings!",
  },
];

export const jobsItems: Array<FAQElementProp> = [
  {
    title: "Can I purchase stock in Sheetz?",
    buttons: [
      {
        buttonText: "Check job openings",
        buttonLink: "https://jobs.sheetz.com/",
        externalLink: true,
      },
    ],
    bodyText:
      "We prefer to give it away. The only catch is that you have to be an employee. Sheetz is a privately held company. As such, no public stock offerings are available. Employees are eligible for shares through our Employee Stock Ownership Plan (ESOP).",
  },
  {
    title: "What is your operating area?",
    bodyText:
      "Sheetz operates more than 500 high-energy stores throughout six states: Pennsylvania, Virginia, West Virginia, Maryland, Ohio, and North Carolina",
  },
];

export const orderingAndNutritionItems: Array<FAQElementProp> = [
  {
    title: "Where can I find nutritional information regarding your food & beverage products?",
    buttons: [
      {
        buttonText: "Nutrition Calculator",
        buttonLink: "https://www.sheetz.com/nutrition/calculator",
        externalLink: true,
      },
      {
        buttonText: "Self-serve beverages",
        buttonLink: "https://www.sheetz.com/nutrition/self-serve",
        externalLink: true,
      },
      {
        buttonText: "Shweetz",
        buttonLink: "https://www.sheetz.com/nutrition/shweetz",
        externalLink: true,
      },
    ],
    bodyText:
      "You can see nutritional information at select stores on our touchscreens! There's a toggle at the bottom of the screen to turn on the nutritional display. Until we have this available everywhere, here are a few other places you can check:",
  },
];

export const fuelItems: Array<FAQElementProp> = [
  {
    title: "Where does your gas supply come from?",
    bodyText: `Sheetz, Inc. is not in the business of buying or refining crude oil from any country. Furthermore, we do not import gasoline but instead buy it on a domestic basis. Therefore, we do not know or control where the crude oil or gasoline comes from. We buy gasoline from refineries located on the U.S. East and Gulf coast and other trading companies who are in the business of purchasing and refining crude oil. The U.S. needs to import about 60% of its crude oil requirements and 10% of its gasoline needs. Currently, the leading supplier of crude oil to the U.S. is Canada, followed by Saudi Arabia, Venezuela, Mexico and the United Kingdom. However, because crude oil is transported to the U.S. from so many places and immediately "mixed" in tanks and petroleum pipelines for transportation to the refineries, it's impossible to determine exactly "where" the crude oil came from. Likewise, in most areas of the U.S. gasoline from different refiners is mixed together for transportation by a common pipeline network. Additionally, a gallon of gasoline may be traded several times before reaching its final destination, further complicating tracking a gallon's origin.`,
  },
  {
    title: "What about E85?",
    bodyText: `Ethanol is a renewable energy source that is made from corn. Regular gasoline could also be called E10, which means it contains 10% ethanol. Some Sheetz locations also offer E85. E85 contains more ethanol (51%-83%) and is not compatible with all vehicles. It is designed specifically for "flexible fuel vehicles" or FFVs. FFVs can use regular gasoline (E10), E15, or E85. When available, E85 is clearly designated as a different fuel type and should not be used in standard vehicles. Check your owner’s manual to see if your car is able to run with E85.`,
  },
  {
    title: "What about E15?",
    bodyText:
      "Ethanol is a renewable energy source that is made from corn. Regular gasoline could also be called E10, which means it contains 10% ethanol. Some Sheetz locations also offer E15. E15 is gasoline blended with 15 percent ethanol, giving drivers a better, more affordable choice at the pump. It's a high-performance fuel that increases horsepower while burning cleaner and cooler than regular gasoline and according to the EPA, any car 2001 or newer can benefit from using it. It reduces harmful emissions and contains fewer toxic additives all while decreasing the need for foreign oil. Check your owner’s manual to see if your car is able to run with E15. Just look for the blue hose!",
  },
];
