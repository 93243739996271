import axios, { AxiosError } from "axios";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { BFFErrorResponse } from "axiosConfig";

import "./ConfirmOffers.scss";

import { DeliveryTip, UpdatePurchaseOrderRequest } from "assets/dtos/anywhere-dto";
import { ReactComponent as ShredRedIcon } from "assets/svg/rip_bottom.svg";

import { OrderSubviewProps } from "components/Order/Order";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import SheetzContainerButton from "components/misc/button/SheetzContainerButton/SheetzContainerButton";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";
import { suggestedDeliveryTipPercentages, updatePurchaseOrder } from "util/Order.util";

const ConfirmOffers = (props: OrderSubviewProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const purchaseOrder = props.orderSession.purchaseOrder;
  const confirmableOfferIds = purchaseOrder?.confirmableOffers?.map((offer) => offer.offerId);
  const selectedConfirmableOfferIds = purchaseOrder?.loyaltyDiscounts
    ?.filter((discount) => confirmableOfferIds?.includes(discount.offerId))
    .map((discount) => discount.offerId);
  const appContext = useContext(AppContext);
  const confirmOffersState = location.state as { goBack: boolean } | undefined;
  const [selectedOffers, setSelectedOffers] = useState<number[]>(selectedConfirmableOfferIds || []);

  function offerPressed(offerId: number): void {
    if (selectedOffers.includes(offerId)) {
      setSelectedOffers(selectedOffers.filter((selectedOfferId) => selectedOfferId !== offerId));
    } else {
      setSelectedOffers(selectedOffers.concat([offerId]));
    }
  }

  function getButtonClasses(offerId: number): string {
    let className = "offers-list-button";
    if (selectedOffers.includes(offerId)) {
      className = className + " selected";
    }
    return className;
  }

  function skipPressed(): void {
    applyOffersPressed(true);
  }

  function applyOffersPressed(skip?: boolean): void {
    appContext.showLoading();
    updatePurchaseOrder(
      {
        purchaseOrder: purchaseOrder,
        selectedOfferIds: skip ? [] : selectedOffers,
        deliveryTip: {
          tipPercentage: purchaseOrder?.integratedDelivery?.deliveryTipPercentage,
          tipAmount: purchaseOrder?.integratedDelivery?.deliveryTipPercentage
            ? undefined
            : purchaseOrder?.integratedDelivery?.deliveryTip,
        } as DeliveryTip,
        suggestedDeliveryTips:
          purchaseOrder?.integratedDelivery !== undefined
            ? suggestedDeliveryTipPercentages
            : undefined,
      } as UpdatePurchaseOrderRequest,
      props.orderSession.orderToken,
      props.orderSession.orderSessionId
    )
      .then((response) => {
        props.dispatch({ type: "SAVE_PURCHASE_ORDER", payload: response.data.purchaseOrder });
        props.dispatch({
          type: "SET_DELIVERY_TIP_SUGGESTIONS",
          payload: response.data.suggestedDeliveryTips,
        });
        props.dispatch({ type: "SAVE_ORDER_TOKEN", payload: response.headers["order-token"] });
        if (confirmOffersState?.goBack) {
          navigate(-1);
        } else {
          navigate("/order/confirm");
        }
      })
      .catch((error: Error | AxiosError<BFFErrorResponse>): void => {
        if (axios.isAxiosError(error)) {
          let message: string | undefined;

          // A response of 410 (Gone) indicates an expired purchase order
          if (error.response?.status === 410) {
            message = "Your checkout has expired, please re-submit your order.";
            navigate("/order/paymentType", { replace: true });
          }

          appContext.showToast(
            "Oh Sheetz!",
            message ?? error.response?.data.message ?? "An unknown error has occurred.",
            ToastType.error,
            error.response?.config.displayErrorInModal
          );
        }
      })
      .finally(appContext.hideLoading);
  }

  const offers =
    purchaseOrder?.confirmableOffers?.map((confirmableOffer) => {
      return (
        <li key={confirmableOffer.offerId} className="offers-list-item">
          <SheetzContainerButton
            className={getButtonClasses(confirmableOffer.offerId)}
            onClick={(): void => {
              offerPressed(confirmableOffer.offerId);
            }}
          >
            {selectedOffers.includes(confirmableOffer.offerId) && (
              <div className="on-selection-icon-background">
                {getIcon(IconType.customizationCheck, "offer-check-icon")}
                <ShredRedIcon className="rewardz-side-tear" />
              </div>
            )}
            {/* <img
            src={getImageSrc(confirmableOffer.imageName)}
            srcSet={getImageSrcSet(confirmableOffer.imageName)}
            sizes="50px"
            alt="Offer"
          /> */}
            {/* TODO: imageName is currently the full, absolute path. This will need to be changed. */}
            <img src={confirmableOffer.imageName} alt="Offer" />
            <p className="offer-name">{confirmableOffer.offerName}</p>
          </SheetzContainerButton>
        </li>
      );
    }) ?? null;

  return (
    <div className="confirm-offers">
      <div className="available-offers">
        <div className="available-offers-header bottom-rip sheetz-red">
          <p className="offer-heading-text">You have available Rewardz and Offerz!</p>
          <p className="offer-note">Wanna add one to your order?</p>
        </div>
        <div className="checkout-available-rewardz">{offers}</div>

        <div className="confirm-footer top-rip primary-dark-gray">
          <div className="button-row-save-changes-button">
            <SheetzButton
              className={"order-cancel-button"}
              buttonColor={ButtonColor.darkGray}
              transparentButton
              transparentButtonTextColor="light"
              label="Skip"
              type="button"
              onClick={(): void => skipPressed()}
            />

            {selectedOffers && (
              <SheetzButton
                className="apply-button"
                label="Apply"
                buttonColor={ButtonColor.white}
                type="button"
                disabled={selectedOffers.length === 0}
                onClick={(): void => applyOffersPressed()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmOffers;
