import { Formik, FormikProps } from "formik";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import "./AddVehicle.scss";

import VehicleIcon from "components/Account/AccountSettings/Preferences/MyOrderingPreferences/MyVehicles/VehicleIcon/VehicleIcon";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import ButtonFooterContainer from "components/misc/containers/ButtonFooterContainer/ButtonFooterContainer";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import { SelectOption } from "components/misc/form/SheetzInput/SheetzInput";
import SheetzSelect from "components/misc/form/SheetzSelect/SheetzSelect";
import EmptyPage from "components/misc/indicators/EmptyPage/EmptyPage";
import LoadingPlaceholder from "components/misc/indicators/LoadingPlaceholder/LoadingPlaceholder";
import ListContainer from "components/misc/list/ListContainer/ListContainer";
import ListItem from "components/misc/list/ListItem/ListItem";

import { AppContext } from "util/AppContext.util";
import { IconType } from "util/Icon.util";
import {
  NewVehicleValues,
  VehicleMake,
  VehicleType,
  addVehicle,
  getVehicleInformation,
} from "util/MyVehicles.util";

const initialVehicleValues: NewVehicleValues = {
  make: "",
  color: "",
  type: "",
};

interface AddVehicleProps {
  onVehicleAdded?: () => void;
}

interface VehicleItemProps {
  vehicleData: FormikProps<NewVehicleValues>;
}

const AddVehicle = (props: AddVehicleProps) => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const showLoading = appContext.showLoading;
  const hideLoading = appContext.hideLoading;
  const [loading, setLoading] = useState<boolean>(true);
  const [vehicleFormValues, setVehicleFormValues] = useState(initialVehicleValues);
  const [vehicleColors, setVehicleColors] = useState<SelectOption[]>();
  const [vehicleTypes, setVehicleTypes] = useState<VehicleType[]>();
  const [vehicleMakes, setVehicleMakes] = useState<VehicleMake[]>();

  const validationSchema = Yup.object({
    make: Yup.string().required("Required"),
    color: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
  });

  function addNewVehicle(vehicle: NewVehicleValues): void {
    addVehicle(vehicle).then(() => {
      if (props.onVehicleAdded) {
        props.onVehicleAdded();
      } else {
        navigate(-1);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    showLoading();

    initialVehicleValues.type = "";
    getVehicleInformation()
      .then((response) => {
        if (response && response.data) {
          setVehicleMakes(
            response.data.makes.map((make) => ({
              label: make.label,
              value: make.label,
            }))
          );
          setVehicleTypes(
            response.data.types.map((type) => ({
              label: type.label,
              value: type.label,
            }))
          );
          setVehicleColors(
            response.data.colors.map((color) => ({
              label: color.label,
              value: color.label,
            }))
          );
        }
      })
      .finally(() => {
        hideLoading();
        setLoading(false);
      });
  }, [hideLoading, showLoading]);

  function setVehicle(vehicleType: string, props: FormikProps<NewVehicleValues>): void {
    props.values.type = vehicleType;
    props.values.make = "";
    props.values.color = "";
  }

  const Vehicles = (props: VehicleItemProps) => {
    if (vehicleTypes) {
      const vehicleList = vehicleTypes.map((vehicle, index) => {
        return (
          <ListItem
            key={vehicle.label}
            hideArrow={true}
            clickHandler={(event) => {
              event?.preventDefault();
              if (props.vehicleData.values.type !== vehicle.value) {
                setVehicle(vehicle.value, props.vehicleData);
                setVehicleFormValues({ make: "", type: vehicle.value, color: "" });
              }
            }}
            hideBottomBorder={index + 1 === vehicleTypes.length}
          >
            <div className="vehicle-flex-container">
              <div tabIndex={0} role="button" aria-pressed="false" className="vehicle-container">
                <div className="vehicle-flex-container">
                  <VehicleIcon
                    vehicle={{ type: vehicle.value, make: "", color: "White", vehicleId: 0 }}
                    forceGrayIcon={true}
                  />
                  <div className="vehicle-icon-label">{vehicle.value}</div>
                </div>
                <div
                  className={
                    vehicleFormValues.type === vehicle.value ? "showOptions" : "hideOptions"
                  }
                >
                  <div>
                    <SheetzSelect
                      name={"make"}
                      placeholder={"Vehicle Make"}
                      label={"Vehicle Make"}
                      options={vehicleMakes ?? []}
                    />
                    <SheetzSelect
                      name={"color"}
                      placeholder={"Vehicle Color"}
                      label={"Vehicle Color"}
                      options={vehicleColors ?? []}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ListItem>
        );
      });
      return <ListContainer>{vehicleList}</ListContainer>;
    } else {
      return <></>;
    }
  };

  if (vehicleTypes && vehicleTypes.length > 0) {
    return (
      <div className="add-new-vehicle-container">
        <ResponsiveLayoutContainer className="account-settings">
          <Formik
            initialValues={initialVehicleValues}
            validationSchema={validationSchema}
            onSubmit={(values: NewVehicleValues): void => {
              addNewVehicle(values);
            }}
          >
            {(props: FormikProps<NewVehicleValues>): ReactElement => (
              <form onSubmit={props.handleSubmit}>
                <Vehicles vehicleData={props} />

                <ButtonFooterContainer>
                  <SheetzButton
                    buttonColor={ButtonColor.sheetzRed}
                    label={
                      props
                        ? `Add ${props.values.color} ${props.values.make} ${props.values.type}`
                        : "Add "
                    }
                  />
                </ButtonFooterContainer>
              </form>
            )}
          </Formik>
        </ResponsiveLayoutContainer>
      </div>
    );
  } else if (loading) {
    return <LoadingPlaceholder />;
  } else {
    return (
      <div className="add-new-vehicle-container">
        <EmptyPage title="Add Vehicles currently unavailable" icon={IconType.vehicle} />
      </div>
    );
  }
};

export default AddVehicle;
