import { Store } from "assets/dtos/anywhere-dto";
import axios, { AxiosPromise } from "axios";
import { ADD_DELETE_STORE_FAVORITE, GET_ALL_STORE_FAVORITES } from "endpoints/mystores.endpoints";

export interface StoreFavoriteResult {
  stores: Store[];
}

export function getAllStoreFavorites(): AxiosPromise<StoreFavoriteResult> {
  return axios({
    method: "GET",
    url: GET_ALL_STORE_FAVORITES
  });
}

export function addStoreFavorite(storeNumber: number): AxiosPromise<null> {
  return axios({
    method: "PUT",
    url: ADD_DELETE_STORE_FAVORITE(storeNumber)
  });
}

export function deleteStoreFavorite(storeNumber: number): AxiosPromise<null> {
  return axios({
    method: "DELETE",
    url: ADD_DELETE_STORE_FAVORITE(storeNumber)
  });
}
