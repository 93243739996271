import React, { useState } from "react";

import "./FindASheetzFilters.scss";

import { ReactComponent as FooterSwoop } from "assets/swoops/foot_swoop_down.svg";

import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";

import { IconType, getIcon } from "util/Icon.util";
import { Filter } from "util/Store.util";

interface FiltersProps {
  evTypeFilters: Filter[];
  fuelTypeFilters: Filter[];
  storeFilters: Filter[];
  submitFunction: () => void;
}

interface FilterIconProps {
  filters: Filter[];
}

const FiltersModal = (filtersProps: FiltersProps) => {
  const [evFilters, setEvFilters] = useState<Filter[]>([...filtersProps.evTypeFilters]);
  const [fuelFilters, setFuelFilters] = useState<Filter[]>([...filtersProps.fuelTypeFilters]);
  const [storeFilters, setStoreFilters] = useState<Filter[]>([...filtersProps.storeFilters]);

  function clearFilters(): void {
    const evFiltersClone = evFilters.map((filter) => {
      filter.selected = false;
      return filter;
    });

    const fuelFiltersClone = fuelFilters.map((filter) => {
      filter.selected = false;
      return filter;
    });

    const storeFilterClone = storeFilters.map((filter) => {
      filter.selected = false;
      return filter;
    });

    setEvFilters([...evFiltersClone]);
    setFuelFilters([...fuelFiltersClone]);
    setStoreFilters([...storeFilterClone]);
  }

  const FilterElements = (filterProps: FilterIconProps) => {
    const filterElements = filterProps.filters.map((filter) => {
      return (
        <div
          className={filter.selected ? "filter selected-filter" : "filter"}
          tabIndex={0}
          role="button"
          aria-pressed="false"
          key={filter.id}
          onClick={(): void => {
            filter.selected = !filter.selected;
            if (filter.isFuel) {
              // Find the filter that has been updated and replace the existing value in the state
              const fuelFiltersClone = fuelFilters.map((existingFilter) => {
                if (existingFilter.id === filter.id) {
                  return { ...existingFilter, filter };
                } else {
                  return existingFilter;
                }
              });
              setFuelFilters(fuelFiltersClone);
            } else if (filter.isEvCharger) {
              // Find the filter that has been updated and replace the existing value in the state
              const evFiltersClone = evFilters.map((existingFilter) => {
                if (existingFilter.id === filter.id) {
                  return { ...existingFilter, filter };
                } else {
                  return existingFilter;
                }
              });
              setEvFilters(evFiltersClone);
            } else {
              // Find the filter that has been updated and replace the existing value in the state
              const storeFilterClone = storeFilters.map((existingFilter) => {
                if (existingFilter.id === filter.id) {
                  return { ...existingFilter, filter };
                } else {
                  return existingFilter;
                }
              });
              setStoreFilters(storeFilterClone);
            }
          }}
        >
          {filter.selected && getIcon(IconType.customizationCheck, "customization-check-icon")}
          <div className="filter-icon">{filter.icon}</div>
          <p>{filter.title}</p>
        </div>
      );
    });
    return <>{filterElements}</>;
  };

  const evTypeFiltersElements = <FilterElements filters={evFilters} />;
  const fuelTypeFiltersElements = <FilterElements filters={fuelFilters} />;
  const storeFiltersElements = <FilterElements filters={storeFilters} />;
  return (
    <>
      <div className="store-filters-container">
        <div className="filters-container upper-container">
          <div className="filters-label">Fuel Types</div>
          <div className="filters-grid">{fuelTypeFiltersElements}</div>
        </div>
        <div className="filters-container upper-container">
          <div className="filters-label">EV Charger Types</div>
          <div className="filters-grid ev-charger-types">{evTypeFiltersElements}</div>
        </div>
        <div className="filters-container lower-container">
          <div className="filters-label">Store Features</div>
          <div className="filters-grid">{storeFiltersElements}</div>
        </div>
      </div>
      <div className="store-filters-footer-container">
        <div className="button-row">
          <SheetzButton
            className="clear-filters-button"
            buttonColor={ButtonColor.white}
            label="Clear"
            onClick={(): void => {
              clearFilters();
            }}
          />
          <SheetzButton
            className="apply-filters-button"
            label="Apply Filters"
            onClick={(): void => {
              filtersProps.submitFunction();
            }}
          />
        </div>
        <div className="footer-swoop">
          <FooterSwoop />
        </div>
      </div>
    </>
  );
};

export default FiltersModal;
