import { Formik, FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";

import "./AddMySheetzCard.scss";

import { AddCardFormValues } from "components/Account/MySheetzCards/MySheetzCards";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzInput from "components/misc/form/SheetzInput/SheetzInput";

import { sheetzCardNameValidation, sheetzCardValidation } from "util/Validation.util";

interface AddMySheetzCardProps {
  initialValues: AddCardFormValues;
  submitFunction: (values: AddCardFormValues) => void;
  cancelFunction: () => void;
}

const AddMySheetzCard = (addProps: AddMySheetzCardProps) => {
  const submitAddCardForm = (values: AddCardFormValues): void => {
    addProps.submitFunction(values);
  };

  const validationSchema = Yup.object({
    cardNumber: sheetzCardValidation.required("Required"),
    cardName: sheetzCardNameValidation,
  });
  return (
    <>
      <div className="add-mysheetzcard">
        <Formik
          initialValues={addProps.initialValues}
          onSubmit={(values: AddCardFormValues): void => {
            submitAddCardForm(values);
          }}
          validationSchema={validationSchema}
        >
          {(props: FormikProps<AddCardFormValues>) => (
            <form className="add-mysheetzcard-form" onSubmit={props.handleSubmit}>
              <SheetzInput
                type="text"
                name="cardNumber"
                placeholder="Card Number"
                label="Card Number"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
              />
              <SheetzInput
                type="text"
                name="cardName"
                placeholder="Card Name"
                label="Name it! (Optional)"
              />
              <div className="button-row">
                <SheetzButton
                  className="add-mysheetzcard-button submit-button"
                  type="submit"
                  label="Save"
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddMySheetzCard;
