import { Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { PatternFormat } from "react-number-format";
import * as Yup from "yup";

import "./CreditCard.scss";

import { CreditCard } from "assets/dtos/anywhere-dto";

import { CreditCardFormValues, SubmitWalletActionEnum } from "components/Account/MyWallet/MyWallet";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import SheetzCheckbox from "components/misc/form/SheetzCheckbox/SheetzCheckbox";
import SheetzInput, { SelectOption } from "components/misc/form/SheetzInput/SheetzInput";
import SheetzSelect from "components/misc/form/SheetzSelect/SheetzSelect";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import states from "resources/states";

import { getUserProfile } from "util/Account.util";
import { DetermineCreditCardType } from "util/CreditCardType.util";
import { IconType, getIcon } from "util/Icon.util";
import { CreditCardMonths } from "util/Payment.util";
import {
  addressValidation,
  cityValidation,
  creditCardValidation,
  dateMonthValidation,
  nameValidation,
  securityCodeValidation,
  stateValidation,
  zipValidation,
} from "util/Validation.util";

interface CreditCardProps {
  isDefault: boolean;
  card?: CreditCard;
  cardNumberMask?: string;
  isSubmittingCreditCard?: boolean;
  submitFunction: (
    values: CreditCardFormValues,
    submitWalletAction: SubmitWalletActionEnum
  ) => void;
}

const stateSelectOptions: SelectOption[] = states.map((state) => ({
  label: state.abbrev,
  value: state.abbrev,
}));

const createValidationSchema = Yup.object({
  cardNumber: creditCardValidation,
});

const defaultValidationSchema = Yup.object({
  cardSecurityCode: securityCodeValidation,
  expirationMonth: dateMonthValidation,
  expirationYear: dateMonthValidation,
  firstName: nameValidation,
  lastName: nameValidation,
  street: addressValidation.required("Required"),
  city: cityValidation.required("Required"),
  state: stateValidation.required("Required"),
  postalCode: zipValidation,
});

const CreditCardModal = (editProps: CreditCardProps) => {
  const [initialFormData, setInitialFormData] = useState<CreditCardFormValues | undefined>();
  const [showConfirmDeleteMessage, setShowConfirmDeleteMessage] = useState(false);
  const card = editProps.card;
  const defaultInitial = editProps.isDefault;
  const isAddNewCreditCard = editProps.card === undefined;
  const monthSelectOptions: SelectOption[] = CreditCardMonths;
  const yearSelectOptions: SelectOption[] = [];
  const validationSchema = isAddNewCreditCard
    ? defaultValidationSchema.concat(createValidationSchema)
    : defaultValidationSchema;

  for (let i = new Date().getFullYear(); i < new Date().getFullYear() + 10; i++) {
    yearSelectOptions.unshift({ label: i.toString(), value: i.toString() });
  }

  useEffect(() => {
    getUserProfile().then((response) => {
      const profile = response.data;
      const initialData: CreditCardFormValues = {
        cardSecurityCode: "",
        cardType: card ? card.cardType : "UNKNOWN",
        city: profile.city,
        expirationMonth: card ? card.expirationMonth.toString() : "",
        expirationYear: card ? card.expirationYear.toString() : "",
        firstName: profile.firstName,
        lastName: profile.lastName,
        postalCode: profile.zipCode,
        state: profile.stateCode,
        street: profile.address,
        isDefault: editProps.isDefault,
      };

      setInitialFormData(initialData);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submitFunction(values: CreditCardFormValues, action: SubmitWalletActionEnum): void {
    values.cardType = values.cardNumber
      ? DetermineCreditCardType(values.cardNumber)
      : card?.cardType;

    if (typeof values.expirationMonth === "string") {
      values.expirationMonth = parseInt(values.expirationMonth);
    }
    if (typeof values.expirationYear === "string") {
      values.expirationYear = parseInt(values.expirationYear);
    }

    if (!isAddNewCreditCard) {
      values.isExpired = card?.isExpired;
      values.lastFourDigits = card?.lastFourDigits;
      values.paymentMethodId = card?.paymentMethodId;
    }

    editProps.submitFunction(values, action);
  }

  const confirmCreditCardDeleteMessage = (
    <ActionSheet
      color={ActionSheetColor.red}
      title="Are you Sure?"
      shouldDisplay={showConfirmDeleteMessage}
      overlay={true}
      cancelFunction={(): void => {
        setShowConfirmDeleteMessage(false);
      }}
      required
    >
      <div className="credit-card-action-sheet-label">
        Are you sure you want to delete this Card?
      </div>
      <div className="user-action-sheet-decline-btn-wrapper">
        <SheetzButton
          className="user-action-sheet-decline-button"
          label="Cancel"
          buttonColor={ButtonColor.darkGray}
          transparentButton
          onClick={(): void => setShowConfirmDeleteMessage(false)}
        />
      </div>
      <SheetzButton
        className="credit-card-action-sheet-confirm-button"
        label="Confirm"
        onClick={(): void => {
          if (initialFormData) {
            submitFunction(initialFormData, SubmitWalletActionEnum.Delete);
            setShowConfirmDeleteMessage(false);
          }
        }}
      />
    </ActionSheet>
  );

  // If user info data has not returned, do not render
  if (initialFormData) {
    return (
      <>
        <div className="credit-card">
          <Formik
            initialValues={initialFormData}
            onSubmit={(values: CreditCardFormValues): void =>
              submitFunction(values, SubmitWalletActionEnum.Save)
            }
            validationSchema={validationSchema}
          >
            {(props: FormikProps<CreditCardFormValues>) => (
              <form className="credit-card-form" onSubmit={props.handleSubmit}>
                {!isAddNewCreditCard && (
                  <SheetzInput
                    type="text"
                    name="cardNumber"
                    placeholder="Card Number"
                    label="Card Number"
                    value={"•••• •••• •••• " + card?.lastFourDigits}
                    disabled={true}
                  />
                )}
                {isAddNewCreditCard && (
                  <PatternFormat
                    format="#### #### #### ####"
                    name="cardNumber"
                    placeholder="Card Number"
                    label="Card Number"
                    customInput={SheetzInput}
                    onValueChange={(val): void => props.setFieldValue("cardNumber", val.value)}
                    valueIsNumericString
                  />
                )}

                <div className="flex-row">
                  <div className="credit-card-labels">
                    <SheetzSelect
                      name="expirationMonth"
                      label="Exp. Month"
                      options={monthSelectOptions}
                    />
                  </div>
                  <div className="credit-card-labels">
                    <SheetzSelect
                      name="expirationYear"
                      label="Exp. Year"
                      options={yearSelectOptions}
                    />
                  </div>
                </div>
                <SheetzInput type="text" placeholder="CVV" label="CVV" name="cardSecurityCode" />

                <div className="flex-row">
                  <div className="credit-card-labels">
                    <SheetzInput
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      label="First Name"
                    />
                  </div>

                  <div className="credit-card-labels">
                    <SheetzInput
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      label="Last Name"
                    />
                  </div>
                </div>

                <SheetzInput
                  type="text"
                  name="street"
                  placeholder="Street Address"
                  label="Street Address"
                />

                <SheetzInput type="text" name="city" placeholder="City" label="City" />

                <div className="flex-row">
                  <div className="credit-card-labels">
                    <SheetzSelect
                      name="state"
                      placeholder="State"
                      label="State"
                      options={stateSelectOptions}
                    />
                  </div>

                  <div className="credit-card-labels">
                    <SheetzInput type="text" name="postalCode" placeholder="Zip" label="Zip" />
                  </div>
                </div>

                <div className="default-payment-row">
                  <SheetzCheckbox
                    green
                    label="Default Payment Method"
                    name="isDefault"
                    disabled={defaultInitial}
                  />

                  {defaultInitial && (
                    <p className="default-payment-text">
                      A default card cannot be removed unless another payment method is default
                    </p>
                  )}
                </div>

                <div className="button-wrapper">
                  <div className="button-row">
                    <SheetzButton
                      className="submit-button"
                      buttonColor={ButtonColor.payGreen}
                      type="submit"
                      label={isAddNewCreditCard ? "Add Credit Card" : "Update Credit Card"}
                      disabled={editProps.isSubmittingCreditCard}
                    />
                  </div>
                  {!isAddNewCreditCard && (
                    <div className="button-row delete-button-container">
                      <div
                        tabIndex={0}
                        role="button"
                        aria-pressed="false"
                        onClick={(event?): void => {
                          event?.preventDefault();
                          setShowConfirmDeleteMessage(true);
                        }}
                        className="delete-payment-button"
                      >
                        {getIcon(IconType.trash, "trash-icon")}
                        <p className="delete-payment-button-label">Delete Card</p>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
        {showConfirmDeleteMessage && confirmCreditCardDeleteMessage}
      </>
    );
  } else {
    return <></>;
  }
};

export default CreditCardModal;
