interface SheetzErrorConfig {
  userReadableMessage?: string;
  primaryButton: SheetzErrorButtonType;
  secondaybutton?: SheetzErrorButtonType;
}

export enum SheetzErrorButtonType {
  OK,
  TRY_AGAIN,
  CLOSE,
  SELECT_STORE,
  SELECT_TIME,
  CHOOSE_TIME,
  SELECT_ADDRESS,
  SELECT_NEW_ITEM,
  BACK_TO_ORDER,
  CHOOSE_NEW_SIZE,
  CHECKOUT_AGAIN,
}

export class SheetzError extends Error {
  config: SheetzErrorConfig;
  constructor(message: string, config: SheetzErrorConfig) {
    super(message);
    this.config = config;
    // 👇️ because we are extending a built-in class
    Object.setPrototypeOf(this, SheetzError.prototype);
  }
}
