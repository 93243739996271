import classNames from "classnames";
import { DateTime } from "luxon";
import React from "react";

import "./ReceiptOrderDetail.scss";

import { DateAsString, OrderingSystem, PickupLocation } from "assets/dtos/anywhere-dto";
import { ReactComponent as ClockIcon } from "assets/icons/icon_clock.svg";

import FlexContainer from "components/misc/containers/FlexContainer";

import { IconType, getIcon } from "util/Icon.util";
import { postWarningLog } from "util/ServerLogging.util";
import {
  SERVER_TIME_ZONE,
  getDateTimeFromString,
  isDateTimeNextDay,
  isDateTimeToday,
  isDateTimeTomorrow,
} from "util/Time.util";

interface ReceiptOrderDetailProps {
  isDeliveryOrder: boolean;
  pickupTime: DateAsString;
  pickupLocation?: PickupLocation;
  orderingSystem?: OrderingSystem;
}

const pickupOrderingSystems: OrderingSystem[] = ["MOBILE", "WEB"];

const ReceiptOrderDetail = (props: ReceiptOrderDetailProps) => {
  const orderTypeAvailable =
    props.pickupLocation !== undefined && props.orderingSystem !== undefined;
  const now = DateTime.now().setZone(SERVER_TIME_ZONE);
  const pickupDateTime = getDateTimeFromString(props.pickupTime);
  // Order is considered active if the pickup time is in the future.
  const orderIsActive = now < pickupDateTime;
  const durationUntilPickup = pickupDateTime.diff(now);

  let timeLabel: string | undefined;
  let time = pickupDateTime.toLocaleString(DateTime.TIME_SIMPLE);
  if (durationUntilPickup.as("minutes") <= 30) {
    timeLabel = "Approx.";
    time = Math.round(durationUntilPickup.as("minutes")) + " min";
  } else if (isDateTimeToday(pickupDateTime)) {
    timeLabel = "Today at";
  } else if (isDateTimeTomorrow(pickupDateTime)) {
    timeLabel = "Tomorrow at";
  } else if (isDateTimeNextDay(pickupDateTime)) {
    timeLabel = pickupDateTime.toFormat("cccc 'at' ");
  } else {
    timeLabel = "";
    postWarningLog(
      `Invalid pickup time={${pickupDateTime.toLocaleString(
        DateTime.DATETIME_SHORT
      )}}. Time is not within the 3-day window.`
    );
  }

  let typeLabel = "Pickup:";
  let orderType: string | undefined;
  if (
    (props.orderingSystem !== undefined && !pickupOrderingSystems.includes(props.orderingSystem)) ||
    props.isDeliveryOrder
  ) {
    typeLabel = "Order type:";
  }
  let typeIcon = IconType.store;
  let iconColorClass = "red";
  if (props.isDeliveryOrder) {
    orderType = "Delivery";
    typeIcon = IconType.delivery;
  } else if (props.pickupLocation === "CURBSIDE") {
    orderType = "Curbside";
    typeIcon = IconType.curbside;
  } else if (props.pickupLocation === "DRIVE_THRU") {
    orderType = "Drive-Thru";
    typeIcon = IconType.car;
  } else if (props.orderingSystem === "MPOS") {
    orderType = "SheetzGo!";
    typeIcon = IconType.sheetzGo666SQ;
    iconColorClass = "blue";
    // This check comes last, as a sort of fallback. This could be a mobile, web, or OP order.
  } else if (props.pickupLocation === "IN_STORE") {
    orderType = "In-Store";
  }

  // If the order is not active and there is no orderType information, then render nothing.
  if (!orderTypeAvailable && !orderIsActive) {
    return null;
  }

  return (
    <div className="receipt-order-detail">
      <FlexContainer
        className="order-detail-flex-container"
        flexStyles={{ gap: "10px", alignItems: "center" }}
      >
        {orderIsActive && (
          <div className="order-time">
            <FlexContainer flexStyles={{ alignItems: "center", gap: "10px" }}>
              <ClockIcon className="clock-icon" />
              <div>
                <p className="time-label">{timeLabel ?? ""}</p>
                <p className="time">
                  <span>{time}</span>
                </p>
              </div>
            </FlexContainer>
          </div>
        )}
        <div className="order-type">
          <FlexContainer flexStyles={{ alignItems: "center", gap: "10px" }}>
            {getIcon(typeIcon, classNames("order-type-icon", iconColorClass))}
            <div>
              <p className="type-label">{typeLabel}</p>
              <p className="type">
                <span>{orderType}</span>
              </p>
            </div>
          </FlexContainer>
        </div>
      </FlexContainer>
    </div>
  );
};

export default ReceiptOrderDetail;
