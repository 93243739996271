import { useFormikContext } from "formik";
import React, { useRef, useState } from "react";

import "./PinInput.scss";

import { PinFormValues } from "components/Account/MyWallet/Pin/PinEntry/PinEntry";
import SheetzInput from "components/misc/form/SheetzInput/SheetzInput";

const PinInput = () => {
  const entryInputOneRef = useRef<HTMLInputElement | null>(null);
  const entryInputTwoRef = useRef<HTMLInputElement | null>(null);
  const entryInputThreeRef = useRef<HTMLInputElement | null>(null);
  const entryInputFourRef = useRef<HTMLInputElement | null>(null);
  const pinEntryContainerRef = useRef<HTMLDivElement | null>(null);

  const [pinValues, setPinValues] = useState<PinFormValues>({
    pinEntryOne: "",
    pinEntryTwo: "",
    pinEntryThree: "",
    pinEntryFour: "",
  });
  const { setValues, values } = useFormikContext<PinFormValues>();

  function handleChange(event: React.FormEvent<HTMLInputElement>): void {
    const currentTargetInput = event.currentTarget;

    setPinValues(values);
    setValues({ ...values, [currentTargetInput.name]: currentTargetInput.value });

    switch (currentTargetInput.name) {
      case "pinEntryOne":
        if (currentTargetInput.value !== "") {
          entryInputTwoRef.current?.focus();
          entryInputTwoRef.current?.select();
        }
        break;
      case "pinEntryTwo":
        if (currentTargetInput.value !== "") {
          entryInputThreeRef.current?.focus();
          entryInputThreeRef.current?.select();
        }
        break;
      case "pinEntryThree":
        if (currentTargetInput.value !== "") {
          entryInputFourRef.current?.focus();
          entryInputFourRef.current?.select();
        }
        break;
      default:
        break;
    }
  }

  function handleKeypress(event: React.KeyboardEvent<HTMLInputElement>): void {
    const currentTargetInput = event.currentTarget;

    if (event.code === "Backspace" || event.code === "Delete") {
      switch (currentTargetInput.name) {
        case "pinEntryTwo":
          valueExists(entryInputTwoRef, entryInputOneRef);
          break;
        case "pinEntryThree":
          valueExists(entryInputThreeRef, entryInputTwoRef);
          break;
        case "pinEntryFour":
          valueExists(entryInputFourRef, entryInputThreeRef);
          break;
        default:
          break;
      }
    }
  }

  function valueExists(
    currentEntryInput: React.MutableRefObject<HTMLInputElement | null>,
    entryInput: React.MutableRefObject<HTMLInputElement | null>
  ): void {
    const currentEntryInputName = currentEntryInput.current?.name as
      | "pinEntryOne"
      | "pinEntryTwo"
      | "pinEntryThree"
      | "pinEntryFour";

    if (currentEntryInputName) {
      const previousValue: string = pinValues?.[currentEntryInputName];

      if (previousValue.length === 0) {
        entryInput.current?.focus();
        entryInput.current?.select();
      } else {
        setPinValues({
          ...pinValues,
          [currentEntryInputName]: currentEntryInput.current?.value,
        });
      }
    }
  }

  return (
    <>
      <div id="pin-entry-container" className="pin-entry-container" ref={pinEntryContainerRef}>
        <div className="pin-entry">
          <SheetzInput
            aria-label="Pin digit 1"
            type="text"
            name="pinEntryOne"
            maxLength={1}
            ref={entryInputOneRef}
            onChange={handleChange}
            onKeyUp={handleKeypress}
            autoComplete="off"
            inputMode="numeric"
            pattern="\d*"
          />
        </div>
        <div className="pin-entry">
          <SheetzInput
            aria-label="Pin digit 2"
            type="text"
            name="pinEntryTwo"
            maxLength={1}
            ref={entryInputTwoRef}
            onChange={handleChange}
            onKeyUp={handleKeypress}
            autoComplete="off"
            inputMode="numeric"
            pattern="\d*"
          />
        </div>
        <div className="pin-entry">
          <SheetzInput
            aria-label="Pin digit 3"
            type="text"
            name="pinEntryThree"
            maxLength={1}
            ref={entryInputThreeRef}
            onChange={handleChange}
            onKeyUp={handleKeypress}
            autoComplete="off"
            inputMode="numeric"
            pattern="\d*"
          />
        </div>
        <div className="pin-entry">
          <SheetzInput
            aria-label="Pin digit 4"
            type="text"
            name="pinEntryFour"
            maxLength={1}
            ref={entryInputFourRef}
            onChange={handleChange}
            onKeyUp={handleKeypress}
            autoComplete="off"
            inputMode="numeric"
            pattern="\d*"
          />
        </div>
      </div>
    </>
  );
};

export default PinInput;
