import { GetActiveOffersResponse, UpdateOfferRequest } from "assets/dtos/anywhere-dto";
import axios, { AxiosPromise } from "axios";
import { ACTIVE_OFFERS, UPDATE_OFFER } from "endpoints/loyalty.endpoints";

export enum OfferAction {
  "READ" = "READ",
  "ACCEPT" = "ACCEPT",
  "DECLINE" = "DECLINE"
}
export function getActiveOffers(): AxiosPromise<GetActiveOffersResponse> {
  return axios({
    method: "GET",
    url: ACTIVE_OFFERS
  });
}

export function updateOffer(offerId: number, request: UpdateOfferRequest): AxiosPromise<null> {
  return axios({
    method: "PUT",
    url: UPDATE_OFFER(offerId),
    data: request
  });
}
