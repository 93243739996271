import foodDrinkImage from "assets/images/fooddrink.png";

const storeImagesBasePath = "/image/store-images/";
const menuImagesBasePath = "/image/anywhere-menu/en/";

export const imageBuckets = {
  thumbnail: menuImagesBasePath + "thumbnail/",
  small: menuImagesBasePath + "small/",
  medium: menuImagesBasePath + "medium/",
  large: menuImagesBasePath + "large/",
  xlarge: menuImagesBasePath + "xlarge/"
};

export const imageBucketSizes = {
  thumbnail: "75w",
  small: "150w",
  medium: "300w",
  large: "600w",
  xlarge: "1200w"
};

export function getImageSrc(
  imageName?: string,
  imageBucketSize: keyof typeof imageBucketSizes = "small"
): string {
  if (imageName) {
    return imageBuckets[imageBucketSize] + imageName;
  }
  return foodDrinkImage;
}

export function getImageSrcSet(imageName?: string): string {
  if (imageName) {
    return `${imageBuckets.thumbnail}${imageName} ${imageBucketSizes.thumbnail},${imageBuckets.small}${imageName} ${imageBucketSizes.small},
    ${imageBuckets.medium}${imageName} ${imageBucketSizes.medium},${imageBuckets.large}${imageName} ${imageBucketSizes.large}, 
    ${imageBuckets.xlarge}${imageName} ${imageBucketSizes.xlarge}`;
  }
  return "";
}

export function getStoreImageSrc(storeNumber: number): string {
  // Store numbers are padded with zeroes to a length of 5 characters.
  const paddedStoreNumber = "0000" + storeNumber;
  const trimmedStoreNumber = paddedStoreNumber.substr(-5);
  return storeImagesBasePath + trimmedStoreNumber + ".jpg";
}

/**
 * Gets a static map image of the passed in lat/long from the Mapbox API.
 */
// TODO: Inject correct token once available.
export function getStaticMapImage(lat: number, long: number): string {
  return (
    "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+f00" +
    `(${long},${lat})/${long},${lat},16,0/500x350@2x?` +
    "access_token=pk.eyJ1Ijoic2hlZXR6LW1hcHMiLCJhIjoiY2tqeWMxd3A2MDdjYzJucDh5dG9yY2g1YSJ9._in8aErzpojn_UCquW_Ldg"
  );
}
