import { AxiosResponse } from "axios";
import { Formik, FormikProps } from "formik";
import qs from "qs";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import "./Login.scss";

import { LoginResponse } from "assets/dtos/anywhere-dto";

import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import SheetzCheckbox from "components/misc/form/SheetzCheckbox/SheetzCheckbox";
import SheetzInput from "components/misc/form/SheetzInput/SheetzInput";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext, tokenIssuerForcePasswordReset } from "util/AppContext.util";
import {
  clearOrderSession,
  getAuthTokenPayload,
  login,
  setAuthorization,
} from "util/Authentication.util";
import { OrderSession } from "util/Order.util";
import {
  getOrderSession,
  getUsername,
  removeAuthToken,
  removePinAuthToken,
  removeUsername,
  setFPRToken,
  setOrderSession,
  setRecentStore,
  setUserId,
  setUsername,
} from "util/Storage.util";
import { emailValidation } from "util/Validation.util";

interface FormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

const Login = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [tries, setTries] = useState(0);

  const savedName = getUsername();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const baseUrl = queryParams.baseUrl as string | undefined;
  const destination = queryParams.destination as string | undefined;

  const submitLogin = (values: FormValues): void => {
    login({ username: values.email, password: values.password })
      .then((response: AxiosResponse<LoginResponse>) => {
        removePinAuthToken();
        removeAuthToken();

        const isForcePasswordResetToken =
          getAuthTokenPayload(response.data.authorizationToken)?.iss ===
          tokenIssuerForcePasswordReset;

        // Only set the auth token if it is a regular token, and not the one for force password reset.
        if (!isForcePasswordResetToken) {
          setAuthorization(response.data.authorizationToken);
        } else {
          setFPRToken(response.data.authorizationToken);
        }

        setUserId(response.data.userId.toString());
        // Clear the existing order session if it has a different userId than the one just logged in.
        const orderSessionJSON = getOrderSession();
        const orderSession =
          orderSessionJSON !== undefined
            ? (JSON.parse(orderSessionJSON) as OrderSession)
            : undefined;

        if (
          orderSession &&
          orderSession.userId !== undefined &&
          orderSession.userId !== response.data.userId
        ) {
          clearOrderSession();
        } else if (orderSession) {
          setOrderSession(
            JSON.stringify({ ...orderSession, userId: response.data.userId } as OrderSession)
          );
        }
        if (response.data.recentStore) {
          setRecentStore(JSON.stringify(response.data.recentStore));
        }
        values.rememberMe ? setUsername(values.email) : removeUsername();
        appContext.hideToast();

        // true when JWT was issued for Force Password Reset
        if (isForcePasswordResetToken) {
          navigate("/auth/codeEntry");
          return;
        }

        if (baseUrl && !destination) {
          // User is coming from a site other than orders.sheetz.com such as sheetz.com.
          window.location.href = baseUrl;
        } else if (destination) {
          let link = destination;

          // User is coming from a site other than orders.sheetz.com such as sheetz.com where
          // users will log in and be redirected back for age restricted content.
          if (baseUrl) {
            link += `?baseUrl=${baseUrl}`;
          }

          navigate(link, { replace: true, state: location.state });
        } else {
          navigate("/order", { state: location.state });
        }

        const tagManagerArgs = {
          dataLayer: {
            gtmevent: "successfullogin",
          },
          dataLayerName: "PageDataLayer",
        };

        TagManager.dataLayer(tagManagerArgs);
      })
      .catch(() => {
        appContext.showToast("Invalid Username or Password", "Please try again", ToastType.error);
        setTries(tries + 1);
      });
  };

  useEffect(() => {
    if (tries === 5) {
      // This would happen after a normal error toast is thrown from the ToastHelper, so lets time it out a bit.
      setTimeout(() => {
        appContext.hideToast();
      }, 1000);
      setTimeout(() => {
        appContext.showToast(
          "Trouble Logging In?",
          "Reset your password or call 1-800-487-5444",
          ToastType.error
        );
      }, 1500);
    }
    // Specifically disabling this warning because otherwise this would call every time the context changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tries]);

  const initialValues: FormValues = {
    email: savedName ?? "",
    password: "",
    rememberMe: savedName !== null,
  };

  function goToRegistration(): void {
    const tagManagerArgs = {
      dataLayer: {
        gtmevent: "newaccountstart",
      },
      dataLayerName: "PageDataLayer",
    };

    TagManager.dataLayer(tagManagerArgs);

    navigate("/auth/register");
  }

  return (
    <div className="login-form">
      <Formik
        initialValues={initialValues}
        onSubmit={(values: FormValues): void => {
          submitLogin(values);
        }}
        validationSchema={Yup.object({
          email: emailValidation,
          password: Yup.string().required("Required"),
        })}
      >
        {(props: FormikProps<FormValues>): ReactElement => (
          <form onSubmit={props.handleSubmit}>
            <SheetzInput type="email" name="email" placeholder="Email" label="Email" required />

            <SheetzInput
              type="password"
              name="password"
              placeholder="password"
              label="Password"
              required
            />

            <SheetzCheckbox label="Remember Me" name="rememberMe" noPaddingTop />

            <SheetzButton className="submit-button" type="submit" label="Log In" />

            <SheetzButton
              className="registration-button"
              buttonColor={ButtonColor.darkGray}
              label="I need a Sheetz account"
              onClick={(): void => goToRegistration()}
            />
          </form>
        )}
      </Formik>

      <div className="forgot-password">
        <Link to="/auth/forgot">Trouble logging in?</Link>
      </div>
    </div>
  );
};

export default Login;
