import CondimentGrid from "../CondimentGrid/CondimentGrid";
import CustomizationOption from "../CustomizationOption/CustomizationOption";
import React, { ReactElement } from "react";

import "./SwitchSelectors/SwitchSelectors.scss";

import { RetailModifiedItem, Selector } from "assets/dtos/anywhere-dto";
import { ReactComponent as BottomSwoop } from "assets/swoops/action_swoop_bottom.svg";
import { ReactComponent as TopSwoop } from "assets/swoops/action_swoop_top.svg";

interface NoOptionSwitchSelectorProps {
  selector: Selector;
  isSelected: boolean;
  switchRMI: RetailModifiedItem;
  onSwitchToggled: () => void;
}

const NoOptionSwitchSelector = (props: NoOptionSwitchSelectorProps): ReactElement => {
  function switchSelected(): void {
    props.onSwitchToggled();
  }

  return (
    <div className={"no-option-switch-selector " + props.selector.theme}>
      <TopSwoop className="top-swoop swoop" />
      <div className="selector-content">
        <p className="selector-title">{props.selector.text}</p>
        <p className="selector-sub-title">{props.selector.longText}</p>
        <CondimentGrid>
          <CustomizationOption
            selector={props.selector}
            type={props.selector.type}
            isSelected={props.isSelected}
            rmi={props.switchRMI}
            onSwitchOptionSelected={switchSelected}
            isGreenBorder={props.selector.theme === "ULTIMATE"}
          />
        </CondimentGrid>
      </div>
      <BottomSwoop className="bottom-swoop swoop" />
    </div>
  );
};

export default NoOptionSwitchSelector;
