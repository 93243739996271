import axios, { AxiosPromise } from "axios";

import {
  GetDriverTypesResponse,
  GetUserProfileResponse,
  GetUserResponse,
  IdentityQuestionsRequest,
  IdentityQuestionsResponse,
  IdentityVerificationRequest,
  PrintVoucherRequest,
  PrintVoucherResponse,
  UpdateAgeRestrictedStatusRequest,
  UpdateDriverTypesRequest,
  UpdateUserProfileRequest,
} from "assets/dtos/anywhere-dto";

import {
  DRIVER_TYPES,
  PRINT_URL,
  UPDATE_AGE_RESTRICTED_OFFERS,
  USER_IDENTITY_QUESTIONS,
  USER_PROFILE,
  USER_SUMMARY,
  VALIDATE_EMAIL_TOKEN,
  VERIFY_IDENTITY,
} from "../endpoints/account.endpoints";
import { IDENTITY_QUESTIONS } from "endpoints/account.endpoints";

const ageVerifiedStatuses = ["VERIFIED", "MANUALLY_VERIFIED", "IDENTITY_VERIFIED"];

export interface TwentyOnePlusOptInPreferences {
  isAlcoholOffersEnabled?: boolean;
  isTobaccoOffersEnabled?: boolean;
}

export interface MobileAccountSettingsPreferences {
  isBioMetricsEnabled?: boolean;
  isPushNotificationsEnabled?: boolean;
}

export function isAgeVerified(status?: string): boolean {
  if (status === "" || status === undefined || status === null) {
    return false;
  }
  return ageVerifiedStatuses.includes(status);
}

export function isAgeUnverified(status?: string): boolean {
  return status === "MANUALLY_UNVERIFIED" || status === "UNVERIFIED";
}

export function updateTwentyOnePlusOptInPreferences(
  request: UpdateAgeRestrictedStatusRequest
): AxiosPromise<null> {
  return axios({
    method: "PUT",
    url: UPDATE_AGE_RESTRICTED_OFFERS,
    data: request,
  });
}

export function getUserSummary(): AxiosPromise<GetUserResponse> {
  return axios({ method: "GET", url: USER_SUMMARY });
}

export function getUserProfile(): AxiosPromise<GetUserProfileResponse> {
  return axios({
    method: "GET",
    url: USER_PROFILE,
  });
}

export function updateUserProfile(request: UpdateUserProfileRequest): AxiosPromise<null> {
  return axios({
    method: "PUT",
    url: USER_PROFILE,
    data: request,
    disableErrorMessageDisplay: true,
  });
}

export function getDriverTypes(): AxiosPromise<GetDriverTypesResponse> {
  return axios({
    method: "GET",
    url: DRIVER_TYPES,
  });
}

export function updateDriverTypes(request: UpdateDriverTypesRequest): AxiosPromise<null> {
  return axios({
    method: "PUT",
    url: DRIVER_TYPES,
    data: request,
  });
}

export function validateEmailToken(validationToken: string): AxiosPromise<null> {
  return axios({
    method: "POST",
    url: VALIDATE_EMAIL_TOKEN,
    data: { validationToken },
    disableErrorMessageDisplay: true,
  });
}

export function verifyIdentityQuestions(
  request: IdentityQuestionsRequest
): AxiosPromise<IdentityQuestionsResponse> {
  return axios({
    method: "POST",
    url: USER_IDENTITY_QUESTIONS,
    data: request,
    disableErrorMessageDisplay: true,
  });
}

export function getIdentityQuestions(
  request: IdentityQuestionsRequest
): AxiosPromise<IdentityQuestionsResponse> {
  return axios({
    method: "POST",
    url: IDENTITY_QUESTIONS,
    data: request,
  });
}

export function printVoucherData(request: PrintVoucherRequest): AxiosPromise<PrintVoucherResponse> {
  return axios({
    method: "POST",
    url: PRINT_URL,
    data: request,
    disableErrorMessageDisplay: true,
  });
}

export function identityQuestionsAnswers(
  request: IdentityVerificationRequest
): AxiosPromise<IdentityQuestionsResponse> {
  return axios({
    method: "POST",
    url: VERIFY_IDENTITY,
    data: request,
    disableErrorMessageDisplay: true,
  });
}

export function getPiiData(): AxiosPromise<Blob> {
  return axios({
    method: "GET",
    url: "/users/pii",
    responseType: "blob",
  });
}
