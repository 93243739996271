import {
  GetRewardsResponse,
  GetTransactionHistoryResponse,
  PurchaseRewardRequest
} from "assets/dtos/anywhere-dto";
import Axios, { AxiosPromise } from "axios";
import {
  LOYALTY_REWARDS,
  LOYALTY_REWARDS_PURCHASE,
  LOYALTY_TRANSACTION_HISTORY
} from "endpoints/loyalty.endpoints";

export function purchaseReward(request: PurchaseRewardRequest): AxiosPromise<null> {
  return Axios({
    method: "POST",
    url: LOYALTY_REWARDS_PURCHASE,
    data: request
  });
}

export function getUserRewards(): AxiosPromise<GetRewardsResponse> {
  return Axios({
    method: "GET",
    url: LOYALTY_REWARDS
  });
}

export function getUserTransactionHistory(): AxiosPromise<GetTransactionHistoryResponse> {
  // setting the dates here instead of the BFF to not force the endpoint to use one timeframe
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setDate(sixMonthsAgo.getDate() - 180);
  const startDate = sixMonthsAgo.toISOString();
  const now = new Date().toISOString();
  return Axios({
    method: "GET",
    url: LOYALTY_TRANSACTION_HISTORY,
    params: {
      endDate: startDate,
      startDate: now
    }
  });
}
