import { CardTypeEnum } from "assets/dtos/anywhere-dto";
import creditCardType, { types as ccType } from "credit-card-type";
import { CreditCardType } from "credit-card-type/dist/types";

export function DetermineCreditCardType(cardNumber: string): CardTypeEnum {
  const creditCard: Array<CreditCardType> = creditCardType(cardNumber).filter(function(card) {
    return (
      card.type === ccType.MASTERCARD ||
      card.type === ccType.VISA ||
      card.type === ccType.AMERICAN_EXPRESS ||
      card.type === ccType.DISCOVER ||
      card.type === ccType.UNKNOWN
    );
  });

  let cardName: CardTypeEnum = "UNKNOWN";
  if (creditCard && creditCard.length > 0) {
    switch (creditCard[0].niceType.toUpperCase()) {
      case "MASTERCARD":
        cardName = "MASTER_CARD";
        break;
      case "VISA":
        cardName = "VISA";
        break;
      case "DISCOVER":
        cardName = "DISCOVER";
        break;
      case "AMERICAN EXPRESS":
        cardName = "AMEX";
        break;
      default:
        cardName = "UNKNOWN";
        break;
    }
  }
  return cardName;
}
