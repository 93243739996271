import axios, { AxiosError, AxiosResponse } from "axios";
import classNames from "classnames";
import fileDownload from "js-file-download";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { BFFErrorResponse } from "axiosConfig";

import "./AccountSettings.scss";

import {
  UpdateAgeRestrictedStatusRequest,
  UpdateUserProfileRequest,
} from "assets/dtos/anywhere-dto";

import DriverType from "components/Account/AccountSettings/MyInformation/DriverType/DriverType";
import MyInformation from "components/Account/AccountSettings/MyInformation/MyInformation";
import PersonalInformation from "components/Account/AccountSettings/MyInformation/PersonalInformation/PersonalInformation";
import AddNewAddress from "components/Account/AccountSettings/Preferences/MyOrderingPreferences/MyAddresses/AddNewAddress/AddNewAddress";
import MyAddresses from "components/Account/AccountSettings/Preferences/MyOrderingPreferences/MyAddresses/MyAddresses";
import MyOrderingPreferences from "components/Account/AccountSettings/Preferences/MyOrderingPreferences/MyOrderingPreferences";
import MyStores from "components/Account/AccountSettings/Preferences/MyOrderingPreferences/MyStores/MyStores";
import AddVehicle from "components/Account/AccountSettings/Preferences/MyOrderingPreferences/MyVehicles/AddVehicle/AddVehicle";
import MyVehicles from "components/Account/AccountSettings/Preferences/MyOrderingPreferences/MyVehicles/MyVehicles";
import Notifications from "components/Account/AccountSettings/Preferences/Notifications/Notifications";
import Preferences from "components/Account/AccountSettings/Preferences/Preferences";
import TwentyOnePlus from "components/Account/AccountSettings/Preferences/TwentyOnePlus/TwentyOnePlus";
import ChangePassword from "components/Account/AccountSettings/Security/ChangePassword/ChangePassword";
import Security from "components/Account/AccountSettings/Security/Security";
import ProfanityFilterActionSheet from "components/Account/ErrorActionSheets/ProfanityFilterActionSheet";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import InformationFooterContainer from "components/misc/containers/InformationFooterContainer/InformationFooterContainer";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { useMediaQuery } from "hooks";

import {
  MobileAccountSettingsPreferences,
  TwentyOnePlusOptInPreferences,
  getPiiData,
  getUserProfile,
  getUserSummary,
  updateTwentyOnePlusOptInPreferences,
  updateUserProfile,
} from "util/Account.util";
import { AppContext, desktopMediaQuery } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";
import {
  downloadUserData,
  getAccountSettingsPreferences,
  isInNativeMobileContext,
} from "util/MobileApp.util";
import { checkUserForPin } from "util/Pin.util";

export interface AccountFormValues {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  streetAddress?: string;
  streetAddress2?: string;
  city?: string;
  state?: string;
  zip: string;
  employeeId?: string;
  vipCode?: string;
  fuelReceiptEmailOptOut?: boolean;
  textMessages: boolean;
  dob: string;
  employeeStatus: boolean;
  ageVerified?: string;
}

const AccountSettings = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const evergageEventElement = useRef<HTMLDivElement>(null);
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);
  const [accountFormValues, setAccountFormValues] = useState<AccountFormValues>({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    streetAddress: "",
    streetAddress2: "",
    city: "",
    state: "",
    zip: "",
    employeeId: "",
    vipCode: "",
    fuelReceiptEmailOptOut: undefined,
    textMessages: false,
    dob: "",
    employeeStatus: false,
    ageVerified: undefined,
  });
  const [showProfanityFilterActionSheet, setShowProfanityFilterActionSheet] = useState(false);
  const [pinExists, setPinExists] = useState(false);
  const [mobileAccountSettingsPreferences, setMobileAccountSettingsPreferences] =
    useState<MobileAccountSettingsPreferences>({
      isBioMetricsEnabled: undefined,
      isPushNotificationsEnabled: undefined,
    });
  const [twentyOnePlusOptInPreferences, setTwentyOnePlusOptInPreferences] =
    useState<TwentyOnePlusOptInPreferences>({
      isAlcoholOffersEnabled: undefined,
      isTobaccoOffersEnabled: undefined,
    });

  const showLoading = appContext.showLoading;
  const hideLoading = appContext.hideLoading;

  const showInformationFooter =
    location.pathname === "/account/settings" ||
    location.pathname === "/account/settings/personalInformation" ||
    location.pathname === "/account/settings/driverType" ||
    location.pathname === "/account/settings/employeeInformation";

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInNativeMobileContext()) {
      getAccountSettingsPreferences().then((response) => {
        if (response !== undefined) {
          const accountSettingPreferences = JSON.parse(response);

          if (accountSettingPreferences !== undefined) {
            setMobileAccountSettingsPreferences?.({
              isBioMetricsEnabled: accountSettingPreferences.isBioMetricsEnabled,
              isPushNotificationsEnabled: accountSettingPreferences.isPushNotificationsEnabled,
            });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function downloadData(): void {
    if (isInNativeMobileContext()) {
      downloadUserData();
    } else {
      showLoading();

      getPiiData()
        .then((response) => {
          const blob = response.data;
          fileDownload(blob, "MySheetzData.xlsx");

          appContext.showToast("Download Successful!", "", ToastType.success);
        })
        .catch(() => {
          appContext.showConfirmationActionSheet(
            "Son of a Shmiscuit! We couldn't download your data at this time, please try again ",
            "Retry",
            downloadData
          );
        })
        .finally(hideLoading);
    }

    appContext.hideConfirmationActionSheet();
  }

  function getUserData(updateMessage?: string): void {
    showLoading();

    getUserSummary()
      .then((response) => {
        const employeeStatus = response.data.employeeStatus.employee;

        getUserProfile().then((response) => {
          const ageVerified = response.data.ageVerified;

          setAccountFormValues({
            email: response.data.emailAddress,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            phoneNumber: response.data.phoneNumber,
            streetAddress: response.data.address ? response.data.address : "",
            streetAddress2: response.data.address2 ? response.data.address2 : "",
            city: response.data.city ? response.data.city : "",
            state: response.data.stateCode,
            zip: response.data.zipCode,
            fuelReceiptEmailOptOut: response.data.fuelReceiptEmailOptOut,
            textMessages: response.data.textMessages,
            employeeId: "",
            vipCode: "",
            dob: response.data.dob,
            employeeStatus,
            ageVerified,
          });

          setTwentyOnePlusOptInPreferences({
            isAlcoholOffersEnabled: response.data.alcoholOffers,
            isTobaccoOffersEnabled: response.data.tobaccoOffers,
          });

          checkUserForPin().then((response: AxiosResponse) => {
            setPinExists(response.data.checkPin.pinExists);
          });
        });
      })
      .finally(() => {
        hideLoading();

        if (updateMessage !== undefined) {
          setTimeout(() => appContext.showToast(updateMessage, "", ToastType.success), 1000);
        }
      });
  }

  function proceedDeleteAccount(): void {
    navigate("/contact");
    appContext.hideConfirmationActionSheet();
  }

  function updateUserProfileInformation(userProfileValues: UpdateUserProfileRequest): void {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    showLoading();

    // Send Evergage event if user has changed sms option
    if (userProfileValues.textMessages !== accountFormValues.textMessages) {
      const smsOptInOptOutEvent = new CustomEvent("smsOptInOptOut", {
        detail: {
          eventName: userProfileValues.textMessages
            ? "SMS OptIn Confirmed"
            : "SMS OptOut Confirmed",
        },
      });
      // Dispatch the event.
      evergageEventElement.current?.dispatchEvent(smsOptInOptOutEvent);
    }

    updateUserProfile(userProfileValues)
      .then(() => {
        getUserData("Profile updated!");
      })
      .catch((error: Error | AxiosError<BFFErrorResponse>): void => {
        if (axios.isAxiosError(error)) {
          // A response of 422 indicates profanity filter violation
          if (error.response?.status === 422) {
            setShowProfanityFilterActionSheet(true);
          } else {
            appContext.showToast(
              "Oh Sheetz!",
              error.response?.data.message ?? "An unknown error has occurred.",
              ToastType.error
            );
          }
        }
      })
      .finally(hideLoading);
  }

  function updateTwentyOnePlusPreferences(request: UpdateAgeRestrictedStatusRequest): void {
    showLoading();

    updateTwentyOnePlusOptInPreferences(request)
      .then(() => {
        getUserData("Settings Saved!");
      })
      .finally(hideLoading);
  }

  function isFormView(): boolean {
    return (
      location.pathname === "/account/settings" ||
      location.pathname.includes("personalInformation") ||
      location.pathname.includes("employeeInformation") ||
      location.pathname.includes("changePassword") ||
      location.pathname.includes("notifications") ||
      location.pathname.includes("driverType") ||
      location.pathname.includes("myVehicles") ||
      location.pathname.includes("addVehicle") ||
      location.pathname.includes("myAddresses") ||
      location.pathname.includes("addAddress") ||
      location.pathname.includes("twentyOnePlus")
    );
  }

  const tabContentClasses = classNames("tab-content", {
    "bottom-padding": isFormView(),
  });

  const myInfoClasses = classNames({
    "active-tab":
      !location.pathname.includes("security") && !location.pathname.includes("preferences"),
  });

  const securityClasses = classNames({
    "active-tab": location.pathname.includes("security"),
  });

  const preferencesClasses = classNames({
    "active-tab": location.pathname.includes("preferences"),
  });

  return (
    <div className="account-settings-container evergage-event" ref={evergageEventElement}>
      <div className="tab-bar bottom-rip sheetz-red">
        <div className="tab-list">
          <button className={myInfoClasses} onClick={() => navigate("/account/settings")}>
            <div className="account-settings-tab-label">
              {getIcon(IconType.accountInformation, "account-settings-tab-icon")} My Info
            </div>
          </button>
          <button
            className={securityClasses}
            onClick={() => navigate("/account/settings/security")}
          >
            <div className="account-settings-tab-label">
              {getIcon(IconType.accountSecurity, "account-settings-tab-icon")} Security
            </div>
          </button>
          <button
            className={preferencesClasses}
            onClick={() => navigate("/account/settings/preferences")}
          >
            <div className="account-settings-tab-label">
              {getIcon(IconType.accountPreferences, "account-settings-tab-icon")}{" "}
              {useDesktopView ? "Preferences" : "Prefs"}
            </div>
          </button>
        </div>
      </div>

      <div className={tabContentClasses}>
        <div className="account-settings-content bottom-rip sheetz-white">
          {/* Parent route is `/account/settings` */}
          <Routes>
            <Route
              path="/"
              element={
                <MyInformation
                  accountFormValues={accountFormValues}
                  updateUserProfileInformation={updateUserProfileInformation}
                />
              }
            />
            <Route
              path="personalInformation"
              element={
                <PersonalInformation
                  accountFormValues={accountFormValues}
                  updateUserProfileInformation={updateUserProfileInformation}
                />
              }
            />
            <Route path="driverType" element={<DriverType />} />
            <Route
              path="employeeInformation"
              element={
                <PersonalInformation
                  accountFormValues={accountFormValues}
                  employeeInformation
                  updateUserProfileInformation={updateUserProfileInformation}
                />
              }
            />

            {/* Security */}
            <Route
              path="security"
              element={
                <Security
                  isBioMetricsEnabled={mobileAccountSettingsPreferences.isBioMetricsEnabled}
                  pinExists={pinExists}
                  setPinExists={setPinExists}
                />
              }
            />
            <Route path="security/changePassword" element={<ChangePassword />} />

            {/* Preferences */}
            <Route
              path="preferences"
              element={<Preferences ageVerificationStatus={accountFormValues.ageVerified} />}
            />
            <Route
              path="preferences/notifications"
              element={
                <Notifications
                  accountFormValues={accountFormValues}
                  isPushNotificationsEnabled={
                    mobileAccountSettingsPreferences.isPushNotificationsEnabled
                  }
                  setMobileAccountSettingsPreferences={setMobileAccountSettingsPreferences}
                  updateUserProfileInformation={updateUserProfileInformation}
                />
              }
            />
            <Route
              path="preferences/twentyOnePLus"
              element={
                <TwentyOnePlus
                  twentyOnePlusOptInPreferences={twentyOnePlusOptInPreferences}
                  updateTwentyOnePlusPreferences={updateTwentyOnePlusPreferences}
                />
              }
            />
            <Route path="preferences/myOrderingPreferences" element={<MyOrderingPreferences />} />
            <Route path="preferences/myOrderingPreferences/myStores" element={<MyStores />} />
            <Route path="preferences/myOrderingPreferences/addVehicle" element={<AddVehicle />} />
            <Route path="preferences/myOrderingPreferences/myVehicles" element={<MyVehicles />} />
            <Route path="preferences/myOrderingPreferences/myAddresses" element={<MyAddresses />} />
            <Route
              path="preferences/myOrderingPreferences/addAddress"
              element={<AddNewAddress />}
            />
            <Route path="/*" element={<Navigate to="/account/settings" replace />} />
          </Routes>
        </div>

        {showProfanityFilterActionSheet && (
          <ProfanityFilterActionSheet
            setShowProfanityFilterActionSheet={setShowProfanityFilterActionSheet}
            showProfanityFilterActionSheet={showProfanityFilterActionSheet}
          />
        )}

        {showInformationFooter ? (
          <InformationFooterContainer mode="light">
            <p className="heading">Need help?</p>

            <ul>
              <li>
                <SheetzTextButton
                  className="footer-button"
                  label="Download my Data"
                  type="button"
                  onClick={(): void => {
                    appContext.showConfirmationActionSheet(
                      "Are you sure that you want to download your data?",
                      "Confirm",
                      downloadData
                    );
                  }}
                />
              </li>
              <li>
                <SheetzTextButton
                  className="footer-button"
                  label="Delete my Account"
                  type="button"
                  onClick={(): void => {
                    appContext.showConfirmationActionSheet(
                      "By continuing, you will be navigated to the Contact Us form to submit your request to our Customer Service team. It may take two to three business days for your account to be deleted.",
                      "Continue",
                      proceedDeleteAccount
                    );
                  }}
                />
              </li>
            </ul>
          </InformationFooterContainer>
        ) : (
          <div className="padding-for-mobile-devices"></div>
        )}
      </div>
    </div>
  );
};

export default AccountSettings;
