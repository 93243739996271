import React, { ReactElement } from "react";

import "./ConfirmGiftCardReload.scss";

import { PaymentMethod } from "assets/dtos/anywhere-dto";

import ConfirmWalletActionBody from "components/Account/MyWallet/ConfirmWalletActionBody/ConfirmWalletActionBody";
import PaymentFooter from "components/Order/PaymentFooter/PaymentFooter";

interface GiftCardReloadProps {
  className?: string;
  amount: number;
  closeGiftCardReload: () => void;
  submitGiftCardReload: () => void;
  paymentMethod: PaymentMethod;
}

const ConfirmGiftCardReload = (props: GiftCardReloadProps): ReactElement => {
  return (
    <>
      <div className="confirm-gift-card-reload-container">
        <div className="confirm-gift-card-reload-container-header">
          <span className="header-row">{"You're reloading this gift card in the amount of:"}</span>
          <span className="amount-header header-row">{"$" + props.amount}</span>
        </div>
        <ConfirmWalletActionBody amount={props.amount}></ConfirmWalletActionBody>
        <div className="confirm-gift-card-reload-footer">
          <PaymentFooter
            fetchPaymentMethods={false}
            total={props.amount}
            onSubmitButtonPressed={props.submitGiftCardReload}
            onChangePaymentPressed={props.closeGiftCardReload}
            paymentMethod={props.paymentMethod}
            isReloadGiftCard={true}
          ></PaymentFooter>
        </div>
      </div>
    </>
  );
};

export default ConfirmGiftCardReload;
