import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./AddPaymentMethod.scss";

import { PaymentMethod, ZCard } from "assets/dtos/anywhere-dto";

import CreditCardModal from "components/Account/MyWallet/CreditCard/CreditCard";
import GiftCard from "components/Account/MyWallet/GiftCard/GiftCard";
import { CreditCardFormValues } from "components/Account/MyWallet/MyWallet";
import Pin from "components/Account/MyWallet/Pin/Pin";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import ListItem from "components/misc/list/ListItem/ListItem";
import SheetzModal from "components/misc/view/SheetzModal/SheetzModal";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";
import { GiftCardState, addCreditCard, postGiftCard } from "util/Payment.util";
import { setSelectedPaymentMethodId, setSelectedWalletPaymentMethodId } from "util/Storage.util";

const AddPaymentMethod = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [addCreditCardModal, setAddCreditCardModal] = useState<boolean>(false);
  const [addGiftCardModal, setGiftCardModal] = useState<boolean>(false);
  const [giftCard, setGiftCard] = useState<GiftCardState>();
  const [pinAuthenticated, setPinAuthenticated] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialGiftCardFormValues = {
    zCard: { cardName: "", cardNumber: "", pin: "" },
  } as PaymentMethod;
  const paymentState = location.state as GiftCardState;

  useEffect(() => {
    if (paymentState && paymentState.paymentMethodId) {
      setGiftCard({
        paymentMethodId: paymentState.paymentMethodId,
        amount: paymentState.amount,
        reloadThreshold: paymentState.reloadThreshold,
        returnUri: paymentState.returnUri,
      });
      setAddCreditCardModal(true);
    } else if (paymentState && paymentState.returnUri) {
      setGiftCard({
        amount: paymentState.amount,
        returnUri: paymentState.returnUri,
      });
      setAddCreditCardModal(true);
    }
  }, [paymentState]);

  const openModal = (destination: string): void => {
    switch (destination) {
      case "AddGiftCard":
        setGiftCardModal(true);
        break;
      case "BuyGiftCard":
        navigate("/account/buyGiftCard", { state: paymentState });
        break;
      default:
        setAddCreditCardModal(true);
        break;
    }
  };

  const closeEditCard = (paymentMethodId?: number): void => {
    setAddCreditCardModal(false);
    setGiftCardModal(false);

    // If there was a payment method Id passed when navigating to this screen, this means we are coming from Add Funds
    // So, when we are done on this screen, navigate back to Add Funds with the payment method Id
    if (giftCard?.returnUri) {
      if (paymentMethodId && typeof paymentMethodId === "number") {
        setSelectedWalletPaymentMethodId(paymentMethodId);
      }
      navigate(-1);
    } else if (paymentState && paymentState.ordering) {
      if (paymentMethodId !== undefined) {
        setSelectedPaymentMethodId(paymentMethodId);
      }
      // Go back two entries to checkout confirmation.
      navigate(-2);
    } else {
      navigate("/account/myWallet");
    }
  };

  const saveCreditCard = (values: CreditCardFormValues): void => {
    setIsSubmitting(true);
    addCreditCard(values)
      .then((response) => {
        appContext.showToast("Card Added!", "", ToastType.success);
        closeEditCard(response.data.paymentMethod.paymentMethodId);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const saveGiftCard = (zCard: ZCard): void => {
    postGiftCard(zCard).then((response) => {
      appContext.showToast("Card Added!", "", ToastType.success);
      closeEditCard(response.data.paymentMethod.paymentMethodId);
    });
  };

  function setPinAuthentication(authenticated: boolean): void {
    setPinAuthenticated(authenticated);
  }

  const creditCardModal = (
    <SheetzModal
      className="credit-card-modal"
      isOpen={addCreditCardModal}
      closeFunction={closeEditCard}
      contentLabel="Add Credit Card"
      onRequestClose={closeEditCard}
      shouldCloseOnOverlayClick={false}
      headerText="Add Credit Card"
      backgroundColor="green"
    >
      <CreditCardModal
        isDefault={false}
        isSubmittingCreditCard={isSubmitting}
        submitFunction={saveCreditCard}
      />
    </SheetzModal>
  );

  const giftCardModal = (
    <SheetzModal
      className="gift-card-modal"
      isOpen={addGiftCardModal}
      closeFunction={closeEditCard}
      contentLabel="Add Gift Card"
      onRequestClose={closeEditCard}
      shouldCloseOnOverlayClick={false}
      headerText="Add Gift Card"
      backgroundColor="green"
    >
      <GiftCard
        giftCard={initialGiftCardFormValues}
        isDefault={false}
        submitFunction={saveGiftCard}
      />
    </SheetzModal>
  );

  function closedWithoutAuthenticating(): void {
    navigate(-1);
  }

  if (pinAuthenticated) {
    return (
      <ResponsiveLayoutContainer>
        <div className="add-payment-method-container">
          <p className="message">Tap a payment method to add to your wallet</p>
          <ListItem hideArrow={true}>
            <div className="payment-method-item">
              <button
                onClick={(event?): void => {
                  event?.preventDefault();
                  openModal("AddCreditCard");
                }}
              >
                <div>
                  <div className="payment-method-icon-container">
                    {getIcon(IconType.card, "payment-method-icon")}
                  </div>
                  <div className="payment-method-label-container">
                    <p className="payment-method-name">Add Credit Card</p>
                  </div>
                </div>
              </button>
            </div>
          </ListItem>
          <ListItem hideArrow={true}>
            <div className="payment-method-item">
              <button
                onClick={(event?): void => {
                  event?.preventDefault();
                  openModal("AddGiftCard");
                }}
              >
                <div>
                  <div className="payment-method-icon-container">
                    {getIcon(IconType.sheetzCard, "payment-method-icon")}
                  </div>
                  <div className="payment-method-label-container">
                    <p className="payment-method-name">Add Physical Gift Card</p>
                  </div>
                </div>
              </button>
            </div>
          </ListItem>
          <ListItem hideArrow={true}>
            <div className="payment-method-item">
              <button
                onClick={(event?): void => {
                  event?.preventDefault();
                  openModal("BuyGiftCard");
                }}
              >
                <div>
                  <div className="payment-method-icon-container">
                    {getIcon(IconType.sheetzCard, "payment-method-icon")}
                  </div>
                  <div className="payment-method-label-container">
                    <p className="payment-method-name">Buy a Gift Card</p>
                  </div>
                </div>
              </button>
            </div>
          </ListItem>
          {addCreditCardModal && creditCardModal}
          {addGiftCardModal && giftCardModal}
        </div>
      </ResponsiveLayoutContainer>
    );
  } else {
    return (
      <>
        <Pin
          setIsAuthenticatedCallback={setPinAuthentication}
          closedWithoutAuthenticatingCallback={closedWithoutAuthenticating}
        />
      </>
    );
  }
};

export default AddPaymentMethod;
