import axios, { AxiosPromise } from "axios";

import {
  FeedbackRequest,
  FeedbackUserResponse,
  GetFeedbackSubjectsResponse,
} from "assets/dtos/anywhere-dto";

import { CONTACT_US, SUBJECTS } from "endpoints/contact.endpoints";

export function sendFeedback(request: FeedbackRequest): AxiosPromise<null> {
  return axios({ method: "POST", url: CONTACT_US, data: request });
}

export function getFeedbackUserSummary(): AxiosPromise<FeedbackUserResponse> {
  return axios({ method: "GET", url: CONTACT_US });
}

export function getFeedbackSubjects(): AxiosPromise<GetFeedbackSubjectsResponse> {
  return axios({ method: "GET", url: SUBJECTS });
}
