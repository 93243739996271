import axios, { AxiosPromise } from "axios";

import { LoggingRequest } from "assets/dtos/anywhere-dto";

import { LOG_ERROR } from "endpoints/errorlogging.endpoints";

const source = "Anywhere-UI";
const clientVersion =
  process.env.REACT_APP_VERSION + "-" + (process.env.REACT_APP_BUILD_NUMBER ?? "local");

export function postWarningLog(message: string): void {
  const logRequest: LoggingRequest = {
    logLevel: "WARN",
    source,
    message,
    clientVersion,
  };

  makeLoggingRequest(logRequest);
}

export function postErrorLog(error: Error): void {
  const logRequest: LoggingRequest = {
    logLevel: "ERROR",
    source,
    message: error.message,
    stackTrace: error.stack,
    clientVersion,
  };

  makeLoggingRequest(logRequest);
}

function makeLoggingRequest(request: LoggingRequest): AxiosPromise<LoggingRequest> {
  return axios({
    method: "POST",
    url: LOG_ERROR,
    data: request,
  });
}
