import classNames from "classnames";
import React, { ReactElement } from "react";
import { useErrorBoundary } from "react-error-boundary";

import "./SheetzTextButton.scss";

export enum TextButtonColor {
  sheetzBlue = "sheetz-blue",
  darkGray = "dark-gray",
  mediumGray = "medium-gray",
  tertiaryGray = "tertiary-gray",
  white = "white",
}

interface SheetzTextButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  label: string | ReactElement;
  label2?: string | ReactElement;
  buttonColor?: TextButtonColor;
  underline?: boolean;
  textSize?: "small-text" | "large-text";
}

const SheetzTextButton = (props: SheetzTextButtonProps) => {
  const { showBoundary } = useErrorBoundary();

  const {
    label,
    label2,
    buttonColor = (props.buttonColor ?? TextButtonColor.sheetzBlue).toString(),
    textSize = props.textSize ?? "small-text",
    underline,
    onClick,
    ...rest
  } = props;

  const buttonClasses = classNames("text-button", rest.className, buttonColor, textSize, {
    underline,
    disabled: rest.disabled,
  });

  function handleClickWithErrorBoundary(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    try {
      props.onClick?.(event);
    } catch (error) {
      showBoundary(error);
    }
  }

  return (
    <button {...rest} onClick={handleClickWithErrorBoundary} className={buttonClasses}>
      {label}
      {label2}
    </button>
  );
};

export default SheetzTextButton;
