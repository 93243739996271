import { useEffect, useState } from "react";

/**
 * Custom hook that takes in a media query and maintains the state of whether the query matches or not.
 *
 * @param initialMediaQueryValue - The initial value of the media query we want to match against.
 * @returns A tuple of the current match state for the given media query AND a setter for
 * the media query should it require updating
 */
function useMediaQuery(initialMediaQueryValue: string) {
  const [mediaQuery, setMediaQuery] = useState(initialMediaQueryValue);
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    // Get the initial match state for the media query provided.
    const mql = window.matchMedia(mediaQuery);
    setMatches(mql.matches);

    // Handler when the change event on the MQL fires. This happens when a media (screen) change causes the match to change.
    const handleMediaChange = (changeEvent: MediaQueryListEvent) => {
      setMatches(changeEvent.matches);
    };

    // First try the modern way of listening for the event, if it fails, fall back to the legacy method.
    try {
      mql.addEventListener("change", handleMediaChange);
    } catch {
      mql.addListener(handleMediaChange);
    }

    // Clean up the listener upon the effect firing again or when the parent component is removed from the DOM.
    return () => {
      try {
        mql.removeEventListener("change", handleMediaChange);
      } catch {
        mql.removeListener(handleMediaChange);
      }
    };
  }, [mediaQuery]);

  /**
   * More info on `as const` here:
   * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#const-assertions
   */
  return [matches, setMediaQuery] as const;
}

export { useMediaQuery };
