import classNames from "classnames";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./DeliveryPickupToggle.scss";

import { ItemEvent } from "assets/dtos/anywhere-dto";

import { OrderSubviewProps } from "components/Order/Order";
import ListContainer from "components/misc/list/ListContainer/ListContainer";
import ListItem from "components/misc/list/ListItem/ListItem";

import { isInNativeMobileContext } from "util/MobileApp.util";

interface DeliveryPickupToggleProps extends OrderSubviewProps {
  desktop: boolean;
}

const DeliveryPickupToggle = (props: DeliveryPickupToggleProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state as {
    event: ItemEvent;
    homepageBannerRedirect: string;
    redirectOnOrderFlowFinish: string;
  };
  const deliveryPickupToggleClasses = classNames("delivery-pickup-toggle bottom-rip sheetz-red", {
    "in-mobile-app": isInNativeMobileContext(),
  });

  function handleChange(event?: React.ChangeEvent<HTMLInputElement>, delivery?: boolean): void {
    const checked = delivery ? delivery : event?.target.checked;
    const timeout = props.desktop ? 0 : 400;
    const url = checked ? "/order/delivery/address" : "/order/selectStore";

    setTimeout(() => {
      if (
        locationState &&
        (!!locationState.redirectOnOrderFlowFinish ||
          !!locationState.homepageBannerRedirect ||
          !!locationState.event)
      ) {
        navigate(url, {
          state: locationState,
        });
      } else {
        navigate(url);
      }

      props.dispatch({ type: "SET_DELIVERY", payload: checked });
    }, timeout);
  }

  return (
    <>
      {props.desktop ? (
        <div className="delivery-pickup-toggle-desktop">
          <ListContainer>
            <ListItem hideArrow showTopBorder clickHandler={() => handleChange()}>
              <div className={!props.orderSession.delivery ? "selected" : undefined}>
                <p>Pickup</p>
                <div className="selected-indicator"></div>
              </div>
            </ListItem>
            <ListItem hideArrow clickHandler={() => handleChange(undefined, true)}>
              <div className={props.orderSession.delivery ? "selected" : undefined}>
                <p>Delivery</p>
                <div className="selected-indicator"></div>
              </div>
            </ListItem>
          </ListContainer>
        </div>
      ) : (
        <>
          <div className={deliveryPickupToggleClasses}>
            <label className="switch">
              <input
                type="checkbox"
                onChange={handleChange}
                checked={props.orderSession.delivery}
              />
              <div className="slider round">
                <div className="slider-contents">
                  <div>
                    <p className={!props.orderSession.delivery ? "selected" : undefined}>Pickup</p>
                  </div>
                  <div>
                    <p className={props.orderSession.delivery ? "selected" : undefined}>Delivery</p>
                  </div>
                </div>
              </div>
            </label>
          </div>
        </>
      )}
    </>
  );
};

export default DeliveryPickupToggle;
