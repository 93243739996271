import axios, { AxiosPromise } from "axios";
import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";

import {
  GetAllMenuCategoriesResponse,
  MenuCategory,
  MenuHomeResponse,
  OrderType,
  PickupLocation,
} from "assets/dtos/anywhere-dto";

import { MENU_CATEGORIES, MENU_HOME } from "endpoints/menu.endpoints";

type Menu = MenuCategory[];

export function getMenuHome(): AxiosPromise<MenuHomeResponse> {
  return axios({
    method: "GET",
    url: MENU_HOME,
  });
}

export function getMenuCategories(
  storeNumber: number,
  dayPart: string,
  orderType: OrderType,
  pickupLocation: PickupLocation
): AxiosPromise<GetAllMenuCategoriesResponse> {
  return axios({
    method: "GET",
    url: MENU_CATEGORIES(storeNumber),
    params: { dayPart: dayPart, pickupLocation: pickupLocation, orderType: orderType },
  });
}

export function getTopLevelCategoryFromMenu(menu: Menu): MenuCategory | undefined {
  return menu.find((category) => category.top);
}

export function getMenuCategoryById(menu: Menu, categoryId: number): MenuCategory | undefined {
  return menu.find((category) => category.menuCategoryId === categoryId);
}

export function getMenuCategoriesByIds(menu: Menu, categoryIds: number[]): MenuCategory[] {
  const menuCategories = menu.filter((category) => categoryIds.includes(category.menuCategoryId));
  return menuCategories.sort(
    (a, b) => categoryIds.indexOf(a.menuCategoryId) - categoryIds.indexOf(b.menuCategoryId)
  );
}

export function handleInternalLinkType(internalLinkType: string): string {
  switch (internalLinkType) {
    case "ORDER_HISTORY":
      return "history";
    case "ORDER_FAVORITES":
      return "favorites";
    case "RECOMMENDATIONS":
      return "recommendations";
    default:
      throw new SheetzError("Unhandled Internal Link Type", {
        userReadableMessage: "Looks like we've hit a snag. Please try again.",
        primaryButton: SheetzErrorButtonType.TRY_AGAIN,
      });
  }
}
