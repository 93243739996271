import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import "./Receipt.scss";

import { Order, Store } from "assets/dtos/anywhere-dto";

import { ReceiptState } from "components/Account/MyReceipts/MyReceipts";
import CancelOrder from "components/Account/MyReceipts/Receipt/CancelOrder/CancelOrder";
import DeliveryInstructionsInfo from "components/Account/MyReceipts/Receipt/DeliveryInstructions/DeliveryInstructionsInfo";
import FavoriteOrder from "components/Account/MyReceipts/Receipt/FavoriteOrder/FavoriteOrder";
import OrderDeliveryAddress from "components/Order/OrderConfirmation/OrderDeliveryAddress/OrderDeliveryAddress";
import OrderTotals from "components/Order/OrderTotals/OrderTotals";
import ReceiptHeader from "components/Order/Receipt/ReceiptHeader/ReceiptHeader";
import ReceiptLoyaltyPointsEarned from "components/Order/Receipt/ReceiptLoyaltyPointsEarned/ReceiptLoyaltyPointsEarned";
import ReceiptOrderDetail from "components/Order/Receipt/ReceiptOrderDetail/ReceiptOrderDetail";
import ReceiptPaymentDetail from "components/Order/Receipt/ReceiptPaymentDetail/ReceiptPaymentDetail";
import ReceiptAdditionalOrderInfo from "components/Order/Receipt/RecieptAdditionalOrderInfo/ReceiptAdditionalOrderInfo";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import StoreDisplay from "components/misc/store/StoreDisplay/StoreDisplay";

import { getOrder, getStore } from "util/Order.util";
import { createReorderItemsDisplay } from "util/Reorder.util";

const Receipt = () => {
  const location = useLocation();
  const orderState = location.state as ReceiptState | undefined;
  const [store, setStore] = useState<Store>();
  const [order, setOrder] = useState<Order>();
  const [orderDeliveryPickupTime, setOrderDeliveryPickupTime] = useState<string>();
  const paymentDetails = useRef<HTMLDivElement>(null);

  const isDelivery = order?.integratedDelivery !== undefined;

  let numberOfItems = 0;
  order?.items.forEach((item) => (numberOfItems += item.quantity));

  useEffect(() => {
    if (orderState && !order) {
      getOrder(orderState.orderNumber, true).then((response) => {
        setOrder(response.data.order);
      });
    } else if (order) {
      if (isDelivery) {
        setOrderDeliveryPickupTime(order.integratedDelivery?.estimatedDeliveryTime ?? "");
      } else {
        setOrderDeliveryPickupTime(order.pickupTime);
      }
      // Get a fresh version of the store each time to ensure the data up latest
      getStore(order.storeNumber).then((response) => {
        setStore(response.data.store);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  if (order) {
    return (
      <>
        <ReceiptHeader
          numberOfItems={numberOfItems}
          orderTotal={order.total}
          pastOrder
          pickupTime={order.pickupTime}
          orderNumber={order.orderNumber}
          barcode={order.barcode}
          orderState={order.state}
          viewDetailsPressed={() => {
            paymentDetails.current?.scrollIntoView({ behavior: "smooth" });
          }}
        />
        <ResponsiveLayoutContainer>
          {order && (
            <div className="receipt-container">
              <ReceiptOrderDetail
                isDeliveryOrder={isDelivery}
                pickupTime={
                  order.integratedDelivery !== undefined
                    ? order.integratedDelivery.estimatedDeliveryTime
                    : order.pickupTime
                }
                pickupLocation={order.pickupLocation}
                orderingSystem={order.orderingSystem}
              />

              {isDelivery && (
                <div className="section-container">
                  <OrderDeliveryAddress
                    deliveryAddress={order?.integratedDelivery?.deliveryAddress}
                  />
                </div>
              )}

              {store && !isDelivery && (
                <div className="section-container">
                  <StoreDisplay store={store} showStoreNumber showFavoriteToggle />
                </div>
              )}

              {isDelivery && order?.integratedDelivery?.specialDeliveryInstructions && (
                <div className="section-container">
                  <DeliveryInstructionsInfo
                    orderIntegratedDelivery={order?.integratedDelivery}
                    deliveryInstructions={order?.integratedDelivery?.specialDeliveryInstructions}
                  />
                </div>
              )}

              <div className="section-container">
                <FavoriteOrder
                  isFavoriteOrder={order?.favoriteId ? true : false}
                  favoriteOrderId={order?.favoriteId}
                  orderNumber={order?.orderNumber}
                  items={order?.items}
                  combos={order?.combos}
                  orderingSystem={order?.orderingSystem}
                />
              </div>

              <div className="order-items-display">
                <div className="total-savings-bar-container">
                  {order && <OrderTotals purchaseOrder={order} totalSavingBar />}
                </div>

                {order.orderingSystem === "MPOS" && <div className="my-order-label">My Order</div>}
                <ul>{createReorderItemsDisplay(order, undefined, true, true, true)}</ul>
              </div>

              <div className="section-container" ref={paymentDetails}>
                <OrderTotals purchaseOrder={order} />
                <div className="points-earned-container">
                  <ReceiptLoyaltyPointsEarned pointsEarned={order.loyaltyPointsEarned} />
                </div>
              </div>

              {order.paymentMethod && (
                <div className="section-container">
                  <ReceiptPaymentDetail orderPaymentMethod={order.paymentMethod} />
                </div>
              )}

              <div className="section-container">
                <ReceiptAdditionalOrderInfo
                  orderNumber={order.orderNumber}
                  pickupTime={order.pickupTime}
                />
              </div>

              <div className="cancel-order-container">
                <CancelOrder
                  delivery={isDelivery}
                  orderNumber={order.orderNumber}
                  orderDeliveryPickupTime={orderDeliveryPickupTime}
                  orderState={order.state}
                />
              </div>
            </div>
          )}
        </ResponsiveLayoutContainer>
      </>
    );
  }

  return null;
};

export default Receipt;
