import { Formik, FormikProps } from "formik";
import { DateTime } from "luxon";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import { PatternFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import "./AgeVerification.scss";

import { IdentityQuestionsRequest } from "assets/dtos/anywhere-dto";

import AgeVerificationHeader from "components/Account/AgeVerification/AgeVerificationHeader/AgeVerificationHeader";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import SheetzInput, { SelectOption } from "components/misc/form/SheetzInput/SheetzInput";
import SheetzSelect from "components/misc/form/SheetzSelect/SheetzSelect";

import states from "resources/states";

import { getUserProfile, isAgeUnverified, isAgeVerified } from "util/Account.util";
import { AppContext } from "util/AppContext.util";
import {
  addressValidation,
  ageBirthDateVerifyAge,
  cityValidation,
  nameValidation,
  phoneValidation,
  stateValidation,
  zipValidation,
} from "util/Validation.util";

export interface VerifyYourAgeForm {
  birthdate: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  address: string;
  zip: string;
  city: string;
  stateCode: string;
}

const stateSelectOptions: SelectOption[] = states.map((state) => ({
  label: state.name,
  value: state.abbrev,
}));

const VerifyAge = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [ageUnverified, setAgeUnverified] = useState<boolean>(false);
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const validationSchema = Yup.object().shape({
    birthdate: ageBirthDateVerifyAge,
    firstName: nameValidation,
    lastName: nameValidation,
    mobilePhone: phoneValidation,
    address: addressValidation.required("Required"),
    city: cityValidation.required("Required"),
    stateCode: stateValidation.required("Required"),
    zip: zipValidation,
  });
  const baseUrl = queryParams.baseUrl as string | undefined;
  const destination = queryParams.destination as string | undefined;

  useEffect(() => {
    appContext.showLoading();
    getUserProfile()
      .then((response) => {
        appContext.hideLoading();
        setAgeUnverified(isAgeUnverified(response.data.ageVerified));
        if (isAgeVerified(response.data.ageVerified)) {
          // User is coming from a site other than orders.sheetz.com such as sheetz.com where
          // users will log in and be redirected back for age restricted content.
          if (baseUrl) {
            window.location.href = baseUrl;
          } else if (destination) {
            navigate(destination, { replace: true });
          } else {
            window.open("https://www.sheetz.com/tobacco", "_blank");
            navigate(-1);
          }
        }
      })
      .finally(() => {
        appContext.hideLoading();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onCancel(): void {
    if (baseUrl) {
      window.location.href = baseUrl;
    } else if (destination && destination !== "/account/ageRestrictedOffers") {
      navigate(destination);
    } else {
      navigate(-1);
    }
  }

  return (
    <div className="verify-age-container">
      {ageUnverified && (
        <>
          <AgeVerificationHeader ageVerification />

          <div className="verify-your-age-form-container">
            <ResponsiveLayoutContainer>
              <Formik
                initialValues={{
                  birthdate: "",
                  firstName: "",
                  lastName: "",
                  mobilePhone: "",
                  address: "",
                  city: "",
                  stateCode: "",
                  zip: "",
                }}
                onSubmit={(values: VerifyYourAgeForm): void => {
                  const dob = DateTime.fromFormat(values.birthdate, "MMddyyyy").toFormat(
                    "yyyy-MM-dd"
                  );

                  const request: IdentityQuestionsRequest = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    mobilePhone: values.mobilePhone,
                    address: values.address,
                    city: values.city,
                    stateCode: values.stateCode,
                    zipCode: values.zip,
                    dob,
                  };

                  let link = "/account/identityQuestions";

                  // User is coming from a site other than orders.sheetz.com such as sheetz.com where
                  // users will log in and be redirected back for age restricted content.
                  if (baseUrl) {
                    link += `?baseUrl=${baseUrl}`;
                  } else if (destination) {
                    link += `?destination=${destination}`;
                  }

                  navigate(link, {
                    state: request,
                  });
                }}
                validationSchema={validationSchema}
              >
                {(formProps: FormikProps<VerifyYourAgeForm>) => (
                  <form onSubmit={formProps.handleSubmit}>
                    <div className="step-container">
                      <div className="step-circle">
                        <p className="step-number">1</p>
                      </div>

                      <div className="step-instruction-label center-label">
                        Start with your date of birth:
                      </div>
                    </div>

                    <div className="birthdate-input">
                      <PatternFormat
                        format="##/##/####"
                        name="birthdate"
                        placeholder="Birthdate (mm/dd/yyyy)"
                        label="Birthdate (mm/dd/yyyy)"
                        customInput={SheetzInput}
                        onValueChange={(val): void =>
                          formProps.setFieldValue("birthdate", val.value)
                        }
                        valueIsNumericString
                      />
                    </div>

                    <div className="step-container">
                      <div className="step-circle">
                        <p className="step-number">2</p>
                      </div>
                      <div className="step-instruction-label">
                        {
                          "Enter your info, be sure to use the same info provided on your driver's license or state issued ID."
                        }
                      </div>
                    </div>

                    <SheetzInput
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      label="First Name"
                    />

                    <SheetzInput
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      label="Last Name"
                    />

                    <PatternFormat
                      type="tel"
                      format="(###) ###-####"
                      name="mobilePhone"
                      placeholder="Phone Number"
                      label="Mobile Phone Number (optional)"
                      customInput={SheetzInput}
                      onValueChange={(value): void =>
                        formProps.setFieldValue("mobilePhone", value.value)
                      }
                      valueIsNumericString
                    />

                    <SheetzInput
                      type="text"
                      name="address"
                      placeholder="Street Address"
                      label="Street Address"
                    />

                    <SheetzInput type="text" name="city" placeholder="City" label="City" />

                    <SheetzSelect
                      name="stateCode"
                      placeholder="State"
                      label="State"
                      options={stateSelectOptions}
                    />

                    <SheetzInput type="text" name="zip" placeholder="Zip" label="Zip" />

                    <SheetzButton
                      className="submit-verify-your-age-form"
                      label="Continue"
                      type="submit"
                    />

                    <SheetzButton
                      buttonColor={ButtonColor.darkGray}
                      transparentButton
                      className="submit-verify-your-age-form"
                      label="Cancel"
                      type="button"
                      onClick={onCancel}
                    />
                  </form>
                )}
              </Formik>
            </ResponsiveLayoutContainer>
          </div>
        </>
      )}
    </div>
  );
};

export default VerifyAge;
