import React, { ReactElement, useEffect } from "react";

import { Condiment, Option, Selector } from "assets/dtos/anywhere-dto";

import CondimentGrid from "components/Order/ItemCustomization/CondimentGrid/CondimentGrid";
import CustomizationOption from "components/Order/ItemCustomization/CustomizationOption/CustomizationOption";
import SelectorHeader from "components/Order/ItemCustomization/SelectorHeader/SelectorHeader";
import InlineSubSelector from "components/Order/ItemCustomization/Selectors/InlineSubSelector/InlineSubSelector";
import { setInlineSubSelectorRowPlacement } from "components/Order/ItemCustomization/Selectors/InlineSubSelector/InlineSubSelector.util";

import { useMediaQuery } from "hooks";

import { desktopMediaQuery } from "util/AppContext.util";
import { SingleSelectOption } from "util/Customization.util";

interface SingleSelectCondimentSelectorProps {
  selector: Selector;
  options: SingleSelectOption[];
  selectedOption?: SingleSelectOption;
  onOptionSelected: (singleSelectOption: SingleSelectOption) => void;
  onOptionDeSelected: (singleSelectOption: SingleSelectOption) => void;
}

const SingleSelectCondimentSelector = (props: SingleSelectCondimentSelectorProps): ReactElement => {
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);
  const gridRef = React.createRef<HTMLDivElement>();
  const inlineSubSelectorRef = React.createRef<HTMLDivElement>();

  const options = props.options.map((singleSelectOption) => {
    // The option is selected if there is a selected option already, and the condiment RMIs match or both have isNoOption = true.
    const isSelected =
      (props.selectedOption &&
        singleSelectOption.condiment?.retailModifiedItem.retailModifiedItemId ===
          props.selectedOption.condiment?.retailModifiedItem.retailModifiedItemId) ||
      (singleSelectOption.isNoOption && props.selectedOption?.isNoOption);
    return (
      <CustomizationOption
        key={singleSelectOption.option.text}
        option={singleSelectOption.option}
        condiment={singleSelectOption.condiment}
        type={props.selector.type}
        isSelected={isSelected}
        isNoOption={singleSelectOption.isNoOption}
        onCondimentOptionSelected={condimentOptionSelected}
        onNoOptionSelected={noOptionSelected}
      />
    );
  });

  useEffect(() => {
    if (props.selectedOption?.option.inlineSubSelector) {
      setInlineSubSelectorRowPlacement(
        useDesktopView,
        gridRef.current,
        inlineSubSelectorRef.current,
        props.options,
        props.selectedOption
      );
    }
  }, [gridRef, inlineSubSelectorRef, props.options, props.selectedOption, useDesktopView]);

  function updateSelectedOption(condiment: Condiment, optionSelected: boolean | undefined): void {
    const selectedOption = props.options.find(
      (option) =>
        option.condiment &&
        option.condiment.retailModifiedItem.retailModifiedItemId ===
          condiment.retailModifiedItem.retailModifiedItemId
    );

    if (selectedOption) {
      if (!optionSelected) {
        props.onOptionSelected(selectedOption);
      } else if (optionSelected && props.selector.optional) {
        props.onOptionDeSelected(selectedOption);
      }
    }
  }

  function condimentOptionSelected(condiment: Condiment): void {
    const optionSelected =
      props.selectedOption?.condiment &&
      props.selectedOption?.condiment.retailModifiedItem.retailModifiedItemId ===
        condiment.retailModifiedItem.retailModifiedItemId;

    updateSelectedOption(condiment, optionSelected);
  }

  function noOptionSelected(): void {
    if (props.selectedOption && props.selectedOption.isNoOption) {
      return;
    }

    const noOption = props.options.find((option) => option.isNoOption);
    if (noOption) {
      props.onOptionSelected(noOption);
    }
  }

  function inlineSubSelectorOptionSelected(option?: Option): void {
    if (!option || !props.selectedOption) {
      return;
    }
    props.onOptionSelected({
      option: props.selectedOption.option,
      condiment: props.selectedOption.condiment,
      selectedInlineSubOption: option,
    } as SingleSelectOption);
  }

  return (
    <div className="single-select-condiment-selector">
      <SelectorHeader
        headerText={props.selector.text ?? ""}
        maxSelections={1}
        required={props.selector.optional === false}
        selected={!!props.selectedOption}
      />
      <CondimentGrid ref={gridRef}>
        {options}
        {props.selectedOption && props.selectedOption.option.inlineSubSelector && (
          <InlineSubSelector
            ref={inlineSubSelectorRef}
            selector={props.selectedOption.option.inlineSubSelector}
            selectedOption={props.selectedOption.selectedInlineSubOption}
            selected={!!props.selectedOption.selectedInlineSubOption}
            onInlineSubSelectorSelected={inlineSubSelectorOptionSelected}
          />
        )}
      </CondimentGrid>
    </div>
  );
};

export default SingleSelectCondimentSelector;
