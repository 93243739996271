import React from "react";

import "./ReceiptAdditionalOrderInfo.scss";

import FlexContainer from "components/misc/containers/FlexContainer";

import { getDateTimeFromString } from "util/Time.util";

interface ReceiptAdditionalOrderInfoProps {
  orderNumber: number;
  pickupTime: string;
}

const ReceiptAdditionalOrderInfo = (props: ReceiptAdditionalOrderInfoProps) => {
  const pickupTimeAsDate = getDateTimeFromString(props.pickupTime);

  return (
    <div className="receipt-additional-order-info">
      <p className="heading">Order Details</p>
      <FlexContainer flexStyles={{ justifyContent: "space-between" }}>
        <span className="label">Order number</span>
        <span className="value">{props.orderNumber}</span>
      </FlexContainer>
      <FlexContainer flexStyles={{ justifyContent: "space-between" }}>
        <span className="label">Order date / time</span>
        <span className="value date-time">{pickupTimeAsDate.toFormat("MM/dd/yy - h:mma")}</span>
      </FlexContainer>
    </div>
  );
};

export default ReceiptAdditionalOrderInfo;
