import "./PaymentIcon.scss";

import { CardType, PaymentType } from "assets/dtos/anywhere-dto";

import { IconType, getIcon } from "util/Icon.util";
import { MobilePayType, getCreditCardIconType } from "util/Payment.util";

export interface PaymentIconProps {
  paymentType?: PaymentType;
  creditCardType?: CardType;
}

const PaymentIcon = (props: PaymentIconProps) => {
  if (props.paymentType === undefined && props.creditCardType === undefined) {
    return null;
  }

  if (props.creditCardType !== undefined && props.paymentType === "CREDIT_CARD") {
    return getIcon(getCreditCardIconType(props.creditCardType), "card-icon");
  }

  if (props.paymentType === MobilePayType.ApplePay) {
    return getIcon(IconType.applepay, "card-icon");
  }

  if (props.paymentType === MobilePayType.GooglePay) {
    return getIcon(IconType.googlepay, "card-icon");
  }

  if (props.paymentType === "ZCARD") {
    return getIcon(IconType.sheetzCard, "card-icon");
  }

  return null;
};

export default PaymentIcon;
