import React from "react";
import { useNavigate } from "react-router-dom";

import Header, { HeaderType } from "components/layout/Header/Header";
import BoomBoomBackgroundContainer from "components/misc/containers/BoomBoomBackgroundContainer/BoomBoomBackgroundContainer";
import ActionPanel from "components/misc/view/ActionPanel/ActionPanel";

import { getAppStoreURL } from "util/MobileApp.util";

interface PageNotFoundProps {
  featureNotAvailable?: boolean;
}

const PageNotFound = (props: PageNotFoundProps) => {
  const navigate = useNavigate();

  function sheetzButtonPressed(): void {
    window.open(props.featureNotAvailable ? getAppStoreURL() : "https://www.sheetz.com/", "_blank");
    navigate(-1);
  }

  return (
    <>
      <Header type={HeaderType.homepage} />
      <BoomBoomBackgroundContainer>
        <ActionPanel
          bodyText={
            props.featureNotAvailable
              ? "This feature is only available on the Sheetz App!"
              : "We can't find this page, please try again."
          }
          buttonText={props.featureNotAvailable ? "Download the app" : "Go to sheetz.com"}
          onClick={sheetzButtonPressed}
          titleText={"Son of a shmiscuit!"}
        />
      </BoomBoomBackgroundContainer>
    </>
  );
};

export default PageNotFound;
