import React, { useState } from "react";

import "./FavoriteStoreToggle.scss";

import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";

import { IconType, getIcon } from "util/Icon.util";
import { addStoreFavorite, deleteStoreFavorite } from "util/MyStores.util";

export interface FavoriteStoreToggleProps {
  favoriteToggledCallback?: () => void;
  isFavoriteStore: boolean;
  storeNumber: number;
  distance?: number;
}

const FavoriteStoreToggle = (props: FavoriteStoreToggleProps) => {
  const [isFavoriteStore, setIsFavoriteStore] = useState(props.isFavoriteStore);

  function toggleFavoriteStore(): void {
    if (props.isFavoriteStore) {
      deleteStoreFavorite(props.storeNumber).then(() => {
        setIsFavoriteStore(!isFavoriteStore);
        if (props.favoriteToggledCallback) {
          props.favoriteToggledCallback();
        }
      });
    } else {
      addStoreFavorite(props.storeNumber).then(() => {
        setIsFavoriteStore(!isFavoriteStore);
        if (props.favoriteToggledCallback) {
          props.favoriteToggledCallback();
        }
      });
    }
  }

  return (
    <SheetzButton
      className="save-store-button"
      label={isFavoriteStore ? "Store saved" : "Save this store"}
      label2={
        <div className="circle">
          {getIcon(IconType.star, isFavoriteStore ? "star-icon favorite" : "star-icon")}
        </div>
      }
      transparentButton
      textType="title"
      buttonColor={ButtonColor.sheetzBlue}
      onClick={toggleFavoriteStore}
    />
  );
};

export default FavoriteStoreToggle;
