import React, { ReactElement, useContext } from "react";
import { NumericFormat } from "react-number-format";

import "./AddFundsHeader.scss";

import AmountSelector from "components/Account/MyWallet/AddFunds/AmountSelector/AmountSelector";

import { AddFundsHeaderContext } from "util/Payment.util";

const AddFundsHeader = (): ReactElement => {
  const addFundsHeaderContext = useContext(AddFundsHeaderContext);
  return (
    <>
      <div className="funds-amount-header-container">
        <div className="flex-row">
          <NumericFormat
            className="fund-amount-input"
            name="amount"
            type="text"
            autoComplete="off"
            value={addFundsHeaderContext.amount}
            thousandSeparator={true}
            prefix={"$"}
            onValueChange={(val): void => {
              if (val && val.floatValue) {
                addFundsHeaderContext.setAmount(val.floatValue);
              }
            }}
            isAllowed={(values): boolean => {
              const { floatValue } = values;
              return floatValue ? floatValue >= 1 && floatValue <= 1000 : false;
            }}
          />
          <span className="funds-amount-header-label">Tap value to edit</span>
        </div>
        <AmountSelector options={[10, 25, 50, 100]} buttonStyle={"header"} />
      </div>
    </>
  );
};

export default AddFundsHeader;
