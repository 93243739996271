import React, { ReactElement, useContext, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import Account from "components/Account/Account";
import ResetPin from "components/Account/MyWallet/Pin/ResetPin/ResetPin";
import Auth from "components/Auth/Auth";
import Order from "components/Order/Order";
import UniversalLinks from "components/layout/UniversalLinks";
import SheetzToast from "components/misc/view/SheetzToast/SheetzToast";
import Contact from "components/pages/Contact/Contact";
import FAQ from "components/pages/FAQ/FAQ";
import FindASheetz from "components/pages/FindASheetz/FindASheetz";
import StoreDetails from "components/pages/FindASheetz/StoreDetails/StoreDetails";
import Homepage from "components/pages/Homepage/Homepage";
import PageNotFound from "components/pages/PageNotFound/PageNotFound";

import pageNames from "resources/pageNames";

import { AppContext } from "util/AppContext.util";

const MainLayout = (): ReactElement => {
  const appContext = useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    if (pageNames[location.pathname] !== undefined) {
      document.title = pageNames[location.pathname] ?? "Sheetz Online Ordering";
    }
  }, [location]);

  return (
    <div className="app">
      <main className="main-container">
        {/* This DIV is used by the Visa Branding SDK and populates it with iFrame content when `show()` is called. */}
        <div id="visa-sensory-branding"></div>
        {appContext.toast.render && !appContext.toast.modal && (
          <SheetzToast
            title={appContext.toast.title}
            text={appContext.toast.text}
            type={appContext.toast.type}
            shouldShowToast={appContext.toast.show}
            modal={appContext.toast.modal}
            toastPressed={() => appContext.hideToast()}
          />
        )}

        <Routes>
          <Route path="/" element={<Homepage />} />

          <Route path="/account/*" element={<Account />} />
          <Route path="/auth/*" element={<Auth />} />
          <Route path="/order/*" element={<Order />} />

          <Route path="/contact" element={<Contact />} />
          <Route path="/feature-not-available" element={<PageNotFound featureNotAvailable />} />
          <Route path="/findASheetz/store/:storeId" element={<FindASheetz />} />
          <Route path="/findASheetz" element={<FindASheetz />} />
          <Route path="/FAQ/:section" element={<FAQ />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/storeDetails" element={<Navigate to="/findASheetz" replace />} />
          <Route path="/storeDetails/:storeId" element={<StoreDetails />} />
          <Route path="/page-not-found" element={<PageNotFound />} />
          <Route path="/resetPin" element={<ResetPin />} />
          <Route path="/universal/*" element={<UniversalLinks />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </main>
    </div>
  );
};

export default MainLayout;
