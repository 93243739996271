import { Formik, FormikProps } from "formik";
import React, { FC, ReactElement, useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import "./StoreSelection.scss";

import { ItemEvent, Store } from "assets/dtos/anywhere-dto";

import { OrderSubviewProps } from "components/Order/Order";
import OrderConfiguration from "components/Order/OrderConfiguration/OrderConfiguration";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import SheetzInput from "components/misc/form/SheetzInput/SheetzInput";
import LoadingPlaceholder from "components/misc/indicators/LoadingPlaceholder/LoadingPlaceholder";
import ListItem from "components/misc/list/ListItem/ListItem";
import StoreDisplay from "components/misc/store/StoreDisplay/StoreDisplay";
import { MyStoresProps } from "components/pages/FindASheetz/FindASheetz";
import { StoreState } from "components/pages/FindASheetz/StoreDetailsModal/StoreDetailsModal";

import { AppContext } from "util/AppContext.util";
import { getCurrentGeolocation } from "util/Geolocation.util";
import { IconType, getIcon } from "util/Icon.util";
import {
  isInNativeMobileContext,
  notifyNativeMobileStoreSelectionLoaded,
  notifyNativeMobileStoreSelectionUnloaded,
} from "util/MobileApp.util";
import {
  STORE_PAGINATION_SIZE,
  generateOrderSessionId,
  pickupLocationsAvailable,
  searchStores,
  startOrderSession,
} from "util/Order.util";
import { getUserId } from "util/Storage.util";
import { searchValidation } from "util/Validation.util";

const validationSchema = Yup.object({
  search: searchValidation,
});

function isOrderingUnavailable(store: Store): boolean {
  return store.orderingDisabled || store.temporarilyClosed || !pickupLocationsAvailable(store);
}

const StoreSelection = (props: OrderSubviewProps): ReactElement => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as {
    event: ItemEvent;
    homepageBannerRedirect: string;
    redirectOnOrderFlowFinish: string;
  };
  const [position, setPosition] = useState<GeolocationPosition | null>(null);
  const [positionError, setPositionError] = useState<GeolocationPositionError | undefined>();
  const [locationRetrievalFinished, setLocationRetrievalFinished] = useState(false);
  const [cancelInitialLocationSearch, setCancelInitialLocationSearch] = useState(false);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const preselectedStoreNumber = location.state as StoreState;
  const [savedStores, setSavedStores] = useState<Store[]>();
  const [closestStores, setClosestStores] = useState<Store[]>();
  const [hasLocationTurnedOn, setHasLocationTurnedOn] = useState<boolean>(false);

  useEffect(() => {
    if (cancelInitialLocationSearch) {
      return;
    }

    // Update delivery properties in session if the user selects pickup from the toggle to avoid having both pickup and delivery options
    props.dispatch({ type: "SET_DELIVERY", payload: false });

    // This must follow the "SET_DELIVERY" dispatch above.
    // Otherwise, the store will not be available in the storeSelected function.
    if (preselectedStoreNumber && preselectedStoreNumber.store) {
      storeSelected(preselectedStoreNumber.store);
    }

    getCurrentGeolocation(
      (position) => setPosition(position),
      (error) => setPositionError(error),
      {
        maximumAge: 600 * 1000,
        timeout: 10 * 1000,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cancelInitialLocationSearch && position !== null) {
      setHasLocationTurnedOn(true);
      return;
    }

    if (position !== null) {
      setHasLocationTurnedOn(true);
      searchStores(0, position.coords.latitude, position.coords.longitude)
        .then((response) => {
          setSavedStores(response.data.stores?.filter((store) => store.favorite));
          setClosestStores(response.data.stores?.filter((store) => !store.favorite));
        })
        .finally(() => {
          setLocationRetrievalFinished(true);
          setHasMoreResults(true);
        });
    }
  }, [position, cancelInitialLocationSearch]);

  useEffect(() => {
    if (cancelInitialLocationSearch) {
      return;
    }

    if (positionError !== undefined) {
      searchStores(0)
        .then((response) => {
          setSavedStores(response.data.stores?.filter((store) => store.favorite));
          // If no location is known, closest stores cannot be set.
          setClosestStores([]);
        })
        .finally(() => {
          setLocationRetrievalFinished(true);
        });
    }
  }, [positionError, cancelInitialLocationSearch]);

  useEffect(() => {
    if (!isInNativeMobileContext()) {
      return;
    }
    notifyNativeMobileStoreSelectionLoaded();
  }, []);

  // This useEffect hook will run once, and do nothing. The cleanup is what is important - this effectively ensures it is only called during unmount.
  useEffect(() => {
    return notifyNativeMobileStoreSelectionUnloaded;
  }, []);

  function handleLoadMore(page: number): void {
    searchStores(page, position?.coords.latitude, position?.coords.longitude, searchText).then(
      (response) => {
        setClosestStores(closestStores?.concat(response.data.stores));
        // If the max number of results per page is not returned, then disable further scroll event listening.
        setHasMoreResults(response.data.stores.length === STORE_PAGINATION_SIZE);
      }
    );
  }

  function storeSelected(store: Store): void {
    if (isOrderingUnavailable(store)) {
      return;
    }

    let orderSessionId: string = generateOrderSessionId();
    // If the order session already has a session ID, then re-use that one.
    if (props.orderSession.orderSessionId !== undefined) {
      orderSessionId = props.orderSession.orderSessionId;
    }

    const userId = getUserId();

    props.dispatch({ type: "SET_STORE", payload: store });
    props.dispatch({ type: "SET_ORDER_SESSION_ID", payload: orderSessionId });
    props.dispatch({ type: "SET_USER_ID", payload: userId ? parseInt(userId) : undefined });

    appContext.showLoading();

    startOrderSession({ storeNumber: store.storeNumber }, orderSessionId)
      .then((response) => {
        props.dispatch({
          type: "SET_TIME_AND_AVAILABILITY",
          payload: response.data,
        });
        if (
          locationState &&
          (!!locationState.redirectOnOrderFlowFinish ||
            !!locationState.homepageBannerRedirect ||
            !!locationState.event)
        ) {
          navigate("/order/time", { state: locationState });
        } else {
          navigate("/order/time");
        }
      })
      .finally(() => {
        appContext.hideLoading();
      });
  }

  const StoreElements: FC<MyStoresProps> = (myStoresProps) => {
    if (myStoresProps.stores && myStoresProps.stores.length > 0) {
      const storeElements = myStoresProps.stores?.map((store) => {
        return (
          <ListItem key={store.storeNumber}>
            <div
              tabIndex={0}
              role="button"
              aria-pressed="false"
              onClick={(event?): void => {
                event?.preventDefault();
                storeSelected(store);
              }}
            >
              {store && (
                <StoreDisplay
                  store={store}
                  showFavorite
                  showStoreNumber
                  showStoreDistance
                  showMileage
                  showCurbside
                  showStoreAlerts
                />
              )}
            </div>
          </ListItem>
        );
      });
      return <>{storeElements}</>;
    } else {
      return <></>;
    }
  };

  return (
    <>
      <OrderConfiguration dispatch={props.dispatch} orderSession={props.orderSession} />
      <div className="store-selection">
        <ResponsiveLayoutContainer>
          <Formik
            initialValues={{ search: "" }}
            validationSchema={validationSchema}
            onSubmit={(values: { search: string }): void => {
              setCancelInitialLocationSearch(true);
              setLocationRetrievalFinished(true);
              appContext.showLoading();
              setSearchText(values.search);
              setTimeout(() => {
                searchStores(
                  0,
                  position?.coords.latitude,
                  position?.coords.longitude,
                  values.search
                )
                  .then((response) => {
                    setSavedStores(response.data.stores?.filter((store) => store.favorite));
                    setClosestStores(response.data.stores?.filter((store) => !store.favorite));
                    // If the max number of results per page is not returned, then disable further scroll event listening.
                    setHasMoreResults(response.data.stores.length === STORE_PAGINATION_SIZE);
                  })
                  .finally(() => {
                    appContext.hideLoading();
                  });
              }, 1500);
            }}
          >
            {(props: FormikProps<{ search: string }>): ReactElement => (
              <form onSubmit={props.handleSubmit}>
                <SheetzInput
                  type="text"
                  name="search"
                  placeholder="Search by address, city, zip"
                  label="Search by address, city, zip"
                  autoComplete="off"
                  isSearchInput={true}
                />
              </form>
            )}
          </Formik>

          {/* Setting the key causes this element and its children to rerender when a new search is entered.
              This is requred since there is no public method to reset the page counter on InfiniteScroll.
              See: https://github.com/CassetteRocks/react-infinite-scroller/issues/12#issuecomment-339375017
          */}
          <div className="store-lists-container" key={searchText}>
            {savedStores && savedStores.length > 0 && (
              <div className="results-container saved-stores-container">
                <p>My Saved Stores</p>
                <ul className="store-list-container">{<StoreElements stores={savedStores} />}</ul>
              </div>
            )}
            <div className="results-container">
              <p>Closest Stores</p>
              {!locationRetrievalFinished && !closestStores?.length && (
                <>
                  <LoadingPlaceholder />
                  <LoadingPlaceholder />
                  <LoadingPlaceholder />
                </>
              )}
              {locationRetrievalFinished && closestStores && closestStores.length > 0 && (
                <InfiniteScroll
                  loadMore={handleLoadMore}
                  hasMore={hasMoreResults}
                  initialLoad={false}
                >
                  <ul className="store-list-container">
                    <StoreElements stores={closestStores} />
                  </ul>
                </InfiniteScroll>
              )}
              {!hasLocationTurnedOn && locationRetrievalFinished && closestStores?.length === 0 && (
                <div className="empty-closest-stores-container">
                  <div className="whats-nearby-label">What&apos;s nearby?</div>
                  <div className="empty-closest-stores-body">
                    Please turn on location services or search by address, city or zip.
                  </div>
                </div>
              )}
              {hasLocationTurnedOn && locationRetrievalFinished && closestStores?.length === 0 && (
                <div className="empty-closest-stores-container">
                  <div className="store-no-results-icon">
                    {getIcon(IconType.search, "store-search-icon")}
                  </div>
                  <div className="whats-nearby-label">No results</div>
                </div>
              )}
            </div>
          </div>
        </ResponsiveLayoutContainer>
      </div>
    </>
  );
};

export default StoreSelection;
