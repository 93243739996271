import {
  GetLoyaltyCardsResponse,
  LoyaltyTierStatus,
  UpdateLoyaltyCardRequest
} from "assets/dtos/anywhere-dto";
import axios, { AxiosPromise } from "axios";
import { LOYALTY_CARDS, UPDATE_CARD } from "../endpoints/loyalty.endpoints";
import { IconType } from "./Icon.util";

export const orderedLoyaltyTierBadges = [IconType.fan, IconType.friend, IconType.freak];

export function isAtBaseLoyaltyTier(loyaltyStatus?: LoyaltyTierStatus): boolean {
  return !!loyaltyStatus && loyaltyStatus.currentTierIndex === 0;
}

export function isAtTopLoyaltyTier(loyaltyStatus?: LoyaltyTierStatus): boolean {
  return !!loyaltyStatus && loyaltyStatus.tiers.length - 1 === loyaltyStatus.currentTierIndex;
}

export function getLoyaltyTierName(loyaltyStatus?: LoyaltyTierStatus): string {
  if (loyaltyStatus) {
    return "Sheetz " + loyaltyStatus.tiers[loyaltyStatus.currentTierIndex].name;
  }
  return "";
}

export function getNextLoyaltyTierName(loyaltyStatus?: LoyaltyTierStatus): string {
  if (loyaltyStatus) {
    const tierIndex = isAtTopLoyaltyTier(loyaltyStatus)
      ? loyaltyStatus.currentTierIndex
      : loyaltyStatus.nextTierIndex;
    return "Sheetz " + loyaltyStatus.tiers[tierIndex].name;
  }
  return "";
}

export function getLoyaltyThresholdPoints(loyaltyStatus?: LoyaltyTierStatus): number {
  if (loyaltyStatus) {
    const tierIndex = isAtTopLoyaltyTier(loyaltyStatus)
      ? loyaltyStatus.currentTierIndex
      : loyaltyStatus.nextTierIndex;
    return loyaltyStatus.tiers[tierIndex].threshold;
  }
  return 0;
}

export function getPointsNeededToProgress(loyaltyStatus?: LoyaltyTierStatus): number {
  if (loyaltyStatus) {
    return getLoyaltyThresholdPoints(loyaltyStatus) - loyaltyStatus.totalPoints;
  }
  return 0;
}

export function getProgressToNextTier(loyaltyStatus?: LoyaltyTierStatus): number {
  if (loyaltyStatus) {
    return (loyaltyStatus.totalPoints / getLoyaltyThresholdPoints(loyaltyStatus)) * 100;
  }
  return 0;
}

export function getLoyaltyCards(): AxiosPromise<GetLoyaltyCardsResponse> {
  return axios({
    method: "GET",
    url: LOYALTY_CARDS
  });
}

export function updateLoyaltyCard(
  cardId: number,
  request: UpdateLoyaltyCardRequest
): AxiosPromise<null> {
  return axios({
    method: "PUT",
    url: UPDATE_CARD(cardId),
    data: request
  });
}

export function deleteLoyaltyCard(cardId: number): AxiosPromise<null> {
  return axios({
    method: "DELETE",
    url: UPDATE_CARD(cardId)
  });
}

export function addLoyaltyCard(cardNumber: string, pin: string, cardName: string): AxiosPromise {
  return axios({
    method: "POST",
    url: LOYALTY_CARDS,
    data: {
      cardNumber: cardNumber,
      pin: pin,
      cardName: cardName
    },
    displayErrorInModal: true
  });
}

//This function formats a 16 digit card number to include spaces after every four digits.
export function formatCardNumber(cardNumber: string): string {
  return cardNumber.length !== 16
    ? cardNumber
    : cardNumber.substr(0, 4) +
        " " +
        cardNumber.substr(4, 4) +
        " " +
        cardNumber.substr(8, 4) +
        " " +
        cardNumber.substr(12, 4);
}
