import React from "react";

import "./MyPickupInfo.scss";

import { DeliveryAddress } from "assets/dtos/anywhere-dto";

import { OrderSubviewProps } from "components/Order/Order";
import OrderDeliveryAddress from "components/Order/OrderConfirmation/OrderDeliveryAddress/OrderDeliveryAddress";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import ListItem from "components/misc/list/ListItem/ListItem";
import StoreDisplay from "components/misc/store/StoreDisplay/StoreDisplay";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { IconType, getIcon } from "util/Icon.util";
import { changeOrderTypeEvent } from "util/Metrics.util";
import { determinePickupTime, generateOrderSessionId } from "util/Order.util";

export interface MyPickupInfoProps extends OrderSubviewProps {
  close: () => void;
  deliveryAddress?: DeliveryAddress;
  redirect: (path: string) => void;
  show: boolean;
}

const MyPickupInfo = (props: MyPickupInfoProps) => {
  const purchaseOrder = props.orderSession.purchaseOrder;

  function getPickupLocationIcon(): IconType {
    let iconType: IconType;
    switch (props.orderSession.pickupLocation) {
      case "CURBSIDE":
        iconType = IconType.curbside;
        break;
      case "DRIVE_THRU":
        iconType = IconType.drivethru;
        break;
      case "IN_STORE":
        iconType = IconType.bag;
        break;
      default:
        iconType = IconType.pin;
    }
    return iconType;
  }

  function getPickupLocation(): React.ReactNode {
    const deliveryAddress = props.deliveryAddress;

    if (purchaseOrder?.integratedDelivery !== undefined && deliveryAddress !== undefined) {
      return <OrderDeliveryAddress deliveryAddress={deliveryAddress} />;
    }

    switch (props.orderSession.pickupLocation) {
      case "CURBSIDE":
        return <p>Curbside</p>;
      case "DRIVE_THRU":
        return <p>Drive-Thru</p>;
      case "IN_STORE":
      default:
        return <p>In-Store</p>;
    }
  }

  return (
    <>
      <ActionSheet
        required
        edgeToEdge
        color={ActionSheetColor.red}
        title={props.orderSession.delivery ? "My Delivery" : "My Pickup"}
        overlay={true}
        shouldDisplay={props.show}
        cancelFunction={props.close}
        onOverlayClick={props.close}
      >
        <div className="pickup-info-container">
          <ListItem hideArrow disabled>
            <div className="order-location pickup-info-list-item">
              <div className="pickup-info-list-icon-container">
                {getIcon(
                  props.orderSession.delivery ? IconType.delivery : IconType.store,
                  "order-location-icon"
                )}
              </div>
              <div className="pickup-info-list-item-label">
                <p>{props.orderSession.delivery ? "Delivery" : "Pick up"}</p>
              </div>
            </div>
          </ListItem>
          {props.orderSession.store && (
            <ListItem hideArrow disabled>
              <div className="selected-store">
                <StoreDisplay store={props.orderSession.store} showStoreNumber />
              </div>
            </ListItem>
          )}
          <ListItem hideArrow disabled>
            <div className="pickup-location pickup-info-list-item">
              <div className="pickup-info-list-icon-container">
                {getIcon(IconType.clock, "pickup-time-icon")}
              </div>
              <div className="pickup-info-list-item-label">
                <p>{determinePickupTime(props.orderSession)}</p>
              </div>
            </div>
          </ListItem>
          <ListItem hideArrow disabled>
            <div className="pickup-location pickup-info-list-item">
              <div className="pickup-info-list-icon-container">
                {getIcon(getPickupLocationIcon(), "pickup-location-icon")}
              </div>
              <div className="pickup-info-list-item-label">{getPickupLocation()}</div>
            </div>
          </ListItem>
          <div className="pickup-info-button-container">
            <SheetzButton label="Close" onClick={props.close} />
            <SheetzButton
              buttonColor={ButtonColor.darkGray}
              transparentButton
              label="Change"
              onClick={(): void => {
                changeOrderTypeEvent({
                  sessionId: props.orderSession.orderSessionId || generateOrderSessionId(),
                });
                props.redirect("/order/information");
              }}
            />
          </div>
        </div>
      </ActionSheet>
    </>
  );
};

export default MyPickupInfo;
