import { OrderSession } from "./Order.util";
import { postErrorLog } from "./ServerLogging.util";
import axios, { AxiosPromise } from "axios";
import { v4 as uuidv4 } from "uuid";

import {
  AIUServedEventRequest,
  AccompanyingItemUpsell,
  ChangeOrderTypeEventRequest,
  EmailVerificationTokenExpiredEventRequest,
  FIBEventRequest,
  MenuSearchEventRequest,
  ResetPinTokenExpiredEventRequest,
  StartOrderEventRequest,
} from "assets/dtos/anywhere-dto";

import {
  METRICS_AIU_SERVED,
  METRICS_CHANGE_ORDER_TYPE,
  METRICS_EMAIL_VERIFICATION_TOKEN_EXPIRED,
  METRICS_FIB_CLICKED,
  METRICS_FIB_SERVED,
  METRICS_MENU_SEARCHED,
  METRICS_RESET_PIN_TOKEN_EXPIRED,
  METRICS_START_ORDER,
} from "endpoints/metrics.endpoints";

export function generateUuid(): string {
  return uuidv4();
}

export function aiuServedEvent(
  orderSession: OrderSession,
  AIUs: AccompanyingItemUpsell[],
  aiuServedEventId: string
): void {
  const storeNumber = orderSession.storeNumber || orderSession.store?.storeNumber;
  if (
    storeNumber === undefined ||
    orderSession.dayPart === undefined ||
    orderSession.orderSessionId === undefined
  ) {
    postErrorLog(new Error("Missing order session data for aiuServedEvent metric."));
    return;
  }
  const aiuServedEventRequest: AIUServedEventRequest = {
    aius: AIUs,
    storeNumber: storeNumber,
    dayPart: orderSession.dayPart,
    sessionId: orderSession.orderSessionId,
    aiuServedEventId: aiuServedEventId,
  };
  aiuServed(aiuServedEventRequest);
}

export function aiuServed(request: AIUServedEventRequest): AxiosPromise<null> {
  return axios({ method: "POST", url: METRICS_AIU_SERVED, data: request });
}

export function emailVerificationTokenExpired(
  request: EmailVerificationTokenExpiredEventRequest
): AxiosPromise<null> {
  return axios({
    method: "POST",
    url: METRICS_EMAIL_VERIFICATION_TOKEN_EXPIRED,
    data: request,
  });
}

export function fibClicked(request: FIBEventRequest): AxiosPromise<null> {
  return axios({ method: "POST", url: METRICS_FIB_CLICKED, data: request });
}

export function fibServed(request: FIBEventRequest): AxiosPromise<null> {
  return axios({ method: "POST", url: METRICS_FIB_SERVED, data: request });
}

export function menuSearched(request: MenuSearchEventRequest): AxiosPromise<null> {
  return axios({ method: "POST", url: METRICS_MENU_SEARCHED, data: request });
}

export function resetPinTokenExpired(
  request: ResetPinTokenExpiredEventRequest
): AxiosPromise<null> {
  return axios({
    method: "POST",
    url: METRICS_RESET_PIN_TOKEN_EXPIRED,
    data: request,
  });
}

export function orderStarted(request: StartOrderEventRequest): AxiosPromise<null> {
  return axios({ method: "POST", url: METRICS_START_ORDER, data: request });
}

export function changeOrderTypeEvent(request: ChangeOrderTypeEventRequest): AxiosPromise<null> {
  return axios({ method: "POST", url: METRICS_CHANGE_ORDER_TYPE, data: request });
}
