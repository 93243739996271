import React from "react";

import { Selector } from "assets/dtos/anywhere-dto";

import SelectorContainer from "components/Order/ItemCustomization/SelectorContainer/SelectorContainer";
import DoubleableCondimentSelector from "components/Order/ItemCustomization/Selectors/DoubleCondimentSelector/DoubleableCondimentSelector";
import { createNoOption } from "components/Order/ItemCustomization/Selectors/ExtraableCondimentSelector/ExtraableCondimentSelector.util";
import { OrderSessionAction } from "components/Order/OrderSessionReducer";

import {
  ExtraableOption,
  ItemCustomization,
  handleDefaultOption,
  handleTagAlongTags,
  isCondimentInList,
} from "util/Customization.util";
import { CustomizedItem } from "util/Order.util";
import { deriveCondiment, getAllTags } from "util/Tag.util";

/**
 * Iterate over all options and derive the associated condiment along with the double version of the condiment.
 * We pass `true` for the `okIfEmpty` flag since we are now allowing explicit options to not
 * derive a condiment. For instance, in the Bread selector, the "croissant" option will not derive
 * anything when the size is half or whole sub, since there is no croissant sub roll.
 */
export function deriveCondiments(
  selector: Selector,
  existingTags: string[],
  itemCustomization: ItemCustomization
): ExtraableOption[] {
  const options: (ExtraableOption | null)[] = !selector.options
    ? []
    : selector.options?.map((option) => {
        const derivedCondiment = deriveCondiment(
          option.tags ?? [],
          existingTags,
          itemCustomization,
          false
        );

        if (!derivedCondiment) {
          return null;
        }

        const doubleOption = selector.doubleOptions?.find(
          (doubleOption) => option.text === doubleOption.text
        );

        if (!doubleOption) {
          return null;
        }

        const derivedDoubleCondiment = deriveCondiment(
          doubleOption.tags ?? [],
          // Also include tags from the regular, non-double condiment, otherwise the double version won't derive due to correlate tags.
          existingTags.concat(derivedCondiment.tags),
          itemCustomization,
          true
        );

        if (!derivedDoubleCondiment) {
          return null;
        }

        return {
          option: option,
          condiment: derivedCondiment,
          extraOption: doubleOption,
          extraCondiment: derivedDoubleCondiment,
        };
      });

  if (selector.noOption) {
    const noOption = createNoOption(selector.noOption, existingTags, itemCustomization);
    if (noOption) {
      options.push(noOption);
    }
  }

  // Filter out any elements that are null and log a warning if there aren't any options left.
  const filteredOptions = options.filter((option) => option !== null) as ExtraableOption[];
  if (!filteredOptions.length) {
    // TODO: Possibly throw error instead that is caught and logged to splunk.
    console.warn(`No options derived a condiment/doubleCondiment for doubleable condiment selector with name='${selector.text}'
    and itemCustomizationId='${itemCustomization.itemCustomizationId}'`);
  }
  return filteredOptions;
}

export function processDoubleableCondimentSelector(
  selector: Selector,
  selectorState: ExtraableOption | undefined,
  item: CustomizedItem,
  itemCustomization: ItemCustomization,
  dispatch: React.Dispatch<OrderSessionAction>,
  index: number
): JSX.Element | null {
  const existingTags = getAllTags(item);
  const options = deriveCondiments(selector, existingTags, itemCustomization);
  if (!options.length) {
    return null;
  }

  let selectedOption = selectorState;
  if (
    selectedOption &&
    !isCondimentInList(
      options.map((option) => option.condiment),
      selectedOption.condiment
    )
  ) {
    // TODO: Anything filtered out should be removed via a state update. Need an item customization where a choice generates a conflict on a selector below it.
    selectedOption = undefined;
  } else if (selectedOption) {
    // Add the condiment associated with the selected option.
    item.condiments.push(selectedOption.condiment);

    // Add the extra option condiment, if it exists.
    if (selectedOption.extraOptionSelected && selectedOption.extraCondiment) {
      item.condiments.push(selectedOption.extraCondiment);
    }

    // If the selected option has tagAlongTags, then derive that condiment as well and add it to the item.
    handleTagAlongTags(selectedOption.option, itemCustomization, item);
  } else {
    handleDefaultOption(selector, existingTags, itemCustomization, item);
  }

  const optionSelected = (extraableOption: ExtraableOption): void => {
    dispatch({
      type: "SET_ITEM_CUSTOMIZATIONS",
      payload: [selector.text ?? "", extraableOption],
    });
  };

  const optionDeselected = (): void => {
    dispatch({ type: "REMOVE_CUSTOMIZATION_SELECTION", payload: selector.text ?? "" });
  };

  const extraSelected = (): void => {
    if (!selectedOption) {
      return;
    }
    selectedOption.extraOptionSelected = !selectedOption.extraOptionSelected;
    dispatch({ type: "SET_ITEM_CUSTOMIZATIONS", payload: [selector.text ?? "", selectedOption] });
  };

  return (
    <SelectorContainer key={index} selector={selector}>
      <DoubleableCondimentSelector
        selector={selector}
        options={options}
        selectedOption={selectedOption}
        onOptionSelected={optionSelected}
        onOptionDeselected={optionDeselected}
        onExtraSelected={extraSelected}
        index={index}
      />
    </SelectorContainer>
  );
}
