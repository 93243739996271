import React, { ReactElement, useEffect, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { useNavigate } from "react-router-dom";

import "./AccountSidebar.scss";

import { LoyaltyTierStatus } from "assets/dtos/anywhere-dto";

import SidebarSubHeader from "components/Account/AccountSidebar/SidebarSubHeader/SidebarSubHeader";
import ListItem from "components/misc/list/ListItem/ListItem";

import { useMediaQuery } from "hooks";

import { getUserProfile, getUserSummary, isAgeVerified } from "util/Account.util";
import { desktopMediaQuery } from "util/AppContext.util";
import { logout } from "util/Authentication.util";
import { IconType, getIcon } from "util/Icon.util";
import { getLoyaltyTierName } from "util/Loyalty.util";
import { postWarningLog } from "util/ServerLogging.util";
import { setFirstName } from "util/Storage.util";

interface AccountSidebarProps {
  menuOpen: boolean;
  handleMenuOpenStateChange: (menuOpen: boolean) => void;
  ageVerificationStatus?: string;
}

interface AccountSidebarMenuItemProps {
  icon: IconType;
  itemLabel: string;
}

export interface SidebarData {
  firstName: string;
  lastName: string;
  loyaltyTierStatus: LoyaltyTierStatus;
  employeeStatus: {
    employee: boolean;
    monthlyRetailSpent: number;
    monthlyRetailLimit: number;
  };
}

const AccountSidebar = (props: AccountSidebarProps): ReactElement => {
  const navigate = useNavigate();
  const [ageVerificationStatus, setAgeVerificationStatus] = useState<string | undefined>(
    props.ageVerificationStatus
  );
  const [sidebarData, setSidebarData] = useState<SidebarData | null>(null);
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);

  useEffect(() => {
    getUserSummary().then((response) => {
      setFirstName(response.data.firstName);
      setSidebarData(response.data);
    });
  }, []);

  useEffect(() => {
    if (ageVerificationStatus === undefined) {
      getUserProfile().then((response) => {
        setAgeVerificationStatus(response.data.ageVerified);
      });
    }
  }, [ageVerificationStatus]);

  const closeMenu = (): void => {
    props.handleMenuOpenStateChange(false);
  };

  function handleTobaccoNavigation(): void {
    if (isAgeVerified(ageVerificationStatus)) {
      window.open("https://www.sheetz.com/tobacco", "_blank");
      navigate("/");
    } else {
      navigate("/account/verifyAge");
    }
  }

  function handleAgeRestrictedOffersNavigation(): void {
    if (isAgeVerified(ageVerificationStatus)) {
      navigate("/account/ageRestrictedOffers");
    } else {
      navigate("/account/verifyAge?destination=/account/ageRestrictedOffers");
    }
  }

  const menuItemPressed = (destination: string): void => {
    if (destination === "Log Out") {
      logout();
      window.location.href = "https://www.sheetz.com";
    } else if (destination === "My Wallet") {
      navigate("/account/myWallet");
    } else if (destination === "My Rewardz") {
      navigate("/account/rewardz");
    } else if (destination === "My Receipts") {
      navigate("/account/myReceipts");
    } else if (destination === "My Offerz") {
      navigate("/account/myOfferz");
    } else if (destination === "Age Restricted Offers") {
      handleAgeRestrictedOffersNavigation();
    } else if (destination === "Account Settings") {
      navigate("/account/settings");
    } else if (destination === "My Loyalty Card") {
      navigate("/account/mySheetzCards");
    } else if (destination === "My Favorites") {
      navigate("/order/favorites");
    } else if (destination === "My Orders") {
      navigate("/order/history");
    } else if (destination === "Tobacco Offerz") {
      handleTobaccoNavigation();
    } else {
      postWarningLog(`The destination=${destination} does not exist within the account sidebar.`);
    }
    closeMenu();
  };

  const menuItems = [
    { icon: IconType.gear, label: "Account Settings" },
    { icon: IconType.heart, label: "My Favorites" },
    { icon: IconType.bag, label: "My Orders" },
    { icon: IconType.list, label: "My Receipts" },
    { icon: IconType.wallet, label: "My Wallet" },
    { icon: IconType.reward, label: "My Rewardz" },
    { icon: IconType.offer, label: "My Offerz" },
    { icon: IconType.offer, label: "Age Restricted Offers" },
    { icon: IconType.card, label: "My Loyalty Card" },
    { icon: IconType.tobacco, label: "Tobacco Offerz" },
    { icon: IconType.user, label: "Log Out" },
  ];

  const MenuListItem = (listItemProps: AccountSidebarMenuItemProps): ReactElement => {
    return (
      <li>
        <ListItem
          hideArrow={true}
          clickHandler={(): void => menuItemPressed(listItemProps.itemLabel)}
        >
          <div className="sidebar-list-item-flex-container">
            <div className="menu-item-icon-container">
              {getIcon(listItemProps.icon, "menu-item-icon")}
            </div>
            <p className="menu-item-label">{listItemProps.itemLabel}</p>
            {listItemProps.icon === IconType.reward && (
              <div className="spendable-points-container">
                <p className="spendable-points-value">
                  {sidebarData?.loyaltyTierStatus.availablePoints.toLocaleString()}
                </p>
                <p className="spendable-points-label">Spendable Ptz</p>
              </div>
            )}
          </div>
        </ListItem>
      </li>
    );
  };

  const menuListItems = menuItems.map((menuItem) => (
    <MenuListItem key={menuItem.label} icon={menuItem.icon} itemLabel={menuItem.label} />
  ));

  return (
    <Menu
      right
      isOpen={props.menuOpen}
      onStateChange={(state): void => props.handleMenuOpenStateChange(state.isOpen)}
      customBurgerIcon={false}
      customCrossIcon={false}
      disableAutoFocus
      width={useDesktopView ? "480px" : "300px"}
      className="sideMenu"
      htmlClassName="menu-open"
    >
      <header>
        <div>
          <p>
            {sidebarData?.firstName} {sidebarData?.lastName}
          </p>
          <p className="tier-label">{getLoyaltyTierName(sidebarData?.loyaltyTierStatus)}</p>
        </div>
      </header>

      <SidebarSubHeader
        subHeaderType="loyalty"
        loyaltyTierStatus={sidebarData?.loyaltyTierStatus}
      />

      <SidebarSubHeader subHeaderType="employee" employeeStatus={sidebarData?.employeeStatus} />

      <ul className="account-menu-items">{menuListItems}</ul>
    </Menu>
  );
};

export default AccountSidebar;
