import React, { ReactElement } from "react";

import "./StoreDisplay.scss";

import { Store } from "assets/dtos/anywhere-dto";

import FavoriteStoreToggle from "components/Account/MyReceipts/Receipt/FavoriteStoreToggle/FavoriteStoreToggle";

import { IconType, getIcon } from "util/Icon.util";
import { formatStoreAddress, formatZipCode } from "util/Order.util";

interface StoreDisplayProps {
  store: Store;
  showStoreNumber?: boolean;
  showMileage?: boolean;
  showFavorite?: boolean;
  isOrderingPreferences?: boolean;
  showStoreDistance?: boolean;
  showCurbside?: boolean;
  showAlcohol?: boolean;
  showFavoriteToggle?: boolean;
  showStoreAlerts?: boolean;
}

const StoreDisplay = (props: StoreDisplayProps): ReactElement => {
  const showTemporarilyClosedAlert = props.store.temporarilyClosed;
  const showOnlineOrderingUnavailableAlert =
    !showTemporarilyClosedAlert && props.store.orderingDisabled;
  const showCurbsidePickupUnavailableAlert =
    !showTemporarilyClosedAlert &&
    props.showCurbside &&
    props.store.curbside &&
    props.store.curbside.offered &&
    props.store.curbside.disabled;

  return (
    <div className="store-display">
      {(showTemporarilyClosedAlert ||
        showOnlineOrderingUnavailableAlert ||
        showCurbsidePickupUnavailableAlert) &&
        props.showStoreAlerts && (
          <ul className="store-unavailable-alerts">
            {props.store.temporarilyClosed && <li>Store Temporarily Closed</li>}
            {!props.store.temporarilyClosed && props.store.orderingDisabled && (
              <li>Online Ordering Unavailable</li>
            )}
            {!props.store.temporarilyClosed &&
              props.showCurbside &&
              props.store.curbside &&
              props.store.curbside.offered &&
              props.store.curbside.disabled && <li>Curbside Pickup Unavailable</li>}
          </ul>
        )}

      <div className="store-display-flex-container">
        <div className="store-address-container">
          {props.showStoreNumber && (
            <p className="store-number">Store #{props.store.storeNumber}</p>
          )}

          {props.store.specialDirections && (
            <p className="directions">{props.store.specialDirections}</p>
          )}

          <div className="address">
            <p className="street">{formatStoreAddress(props.store.address)}</p>
            <p>
              {formatStoreAddress(props.store.city)}, {props.store.state}{" "}
              {formatZipCode(props.store.zip)}
            </p>
            {props.showMileage && props.store.distance && (
              <p className="mileage">
                {getIcon(IconType.storeCar, "car")}
                {props.store.distance} Miles
              </p>
            )}
          </div>
        </div>

        <div className="store-icon-container">
          {props.store.favorite && props.showFavorite && (
            <div className="circle">{getIcon(IconType.star, "star-icon favorite")}</div>
          )}
          {props.store.features && props.store.features.alcohol && props.showAlcohol && (
            <p className="wine-icon">{getIcon(IconType.beerWine, "wine")}</p>
          )}
        </div>
      </div>

      {props.showFavoriteToggle && (
        <div className="favorite-store-button-container">
          <FavoriteStoreToggle
            storeNumber={props.store.storeNumber}
            isFavoriteStore={props.store.favorite}
          />
        </div>
      )}
    </div>
  );
};

export default StoreDisplay;
