import React from "react";

import "./SheetzToggle.scss";

interface SheetzToggleProps {
  checked?: boolean;
  clickHandler?: () => void;
  disabled?: boolean;
}

const SheetzToggle = (props: SheetzToggleProps) => {
  return (
    <div className="sheetz-toggle">
      <label className="switch">
        <input
          type="checkbox"
          onClick={props.clickHandler}
          checked={props.checked}
          disabled={props.disabled}
        />
        <span className="slider"></span>
      </label>
    </div>
  );
};

export default SheetzToggle;
