import classNames from "classnames";
import React, { ReactElement } from "react";
import { useErrorBoundary } from "react-error-boundary";

import "./SheetzButton.scss";

export enum ButtonColor {
  sheetzGreen = "sheetz-green",
  sheetzRed = "sheetz-red",
  payGreen = "pay-green",
  sheetzBlue = "sheetz-blue",
  errorOrange = "error-orange",
  white = "white",
  darkGray = "dark-gray",
  tertiaryGray = "tertiary-gray",
}

interface SheetzButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  smallButton?: boolean;
  textType?: "title" | "body";
  buttonColor?: ButtonColor;
  transparentButton?: boolean;
  transparentButtonTextColor?: "light" | "dark";
  label: string | ReactElement;
  label2?: string | ReactElement;
}

const SheetzButton = (props: SheetzButtonProps) => {
  const { showBoundary } = useErrorBoundary();

  const {
    smallButton,
    textType = props.textType ?? "body",
    buttonColor = (props.buttonColor ?? ButtonColor.sheetzGreen).toString(),
    transparentButton,
    transparentButtonTextColor = props.transparentButtonTextColor ?? "dark",
    label,
    label2,
    onClick,
    ...rest
  } = props;

  // Force dark/tertiary gray buttons to be transparent (there are no solid dark/tertiary-gray buttons)
  const isTransparent =
    buttonColor === ButtonColor.darkGray || buttonColor === ButtonColor.tertiaryGray
      ? true
      : transparentButton;
  const sheetzButtonClasses = classNames(
    "sheetz-button",
    rest.className,
    textType,
    buttonColor,
    transparentButtonTextColor,
    {
      small: smallButton,
      transparent: isTransparent,
      disabled: rest.disabled,
    }
  );

  const buttonContents =
    label !== undefined && label2 !== undefined ? (
      <>
        <span className="text-left">{label}</span>
        <span className={typeof label2 == "string" ? "text-right" : "text-right"}>{label2}</span>
      </>
    ) : (
      <>
        <span className="text-center">{label}</span>
      </>
    );
  const buttonType = "type=" + rest.type ? rest.type : "button";

  function handleClickWithErrorBoundary(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    try {
      props.onClick?.(event);
    } catch (error) {
      showBoundary(error);
    }
  }

  return (
    <button
      {...rest}
      onClick={handleClickWithErrorBoundary}
      className={sheetzButtonClasses}
      type={buttonType}
    >
      {buttonContents}
    </button>
  );
};

export default SheetzButton;
