import React from "react";
import { FallbackProps, withErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

import "./ItemCustomizationWithErrorBoundry.scss";

import ComboCustomization from "components/Order/ItemCustomization/Combo/ComboCustomization";
import ItemCustomization from "components/Order/ItemCustomization/ItemCustomization";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import EmptyPage from "components/misc/indicators/EmptyPage/EmptyPage";

import { IconType } from "util/Icon.util";
import { postErrorLog } from "util/ServerLogging.util";

const ItemCustomizationErrorDisplay = (props: React.PropsWithChildren<FallbackProps>) => {
  const navigate = useNavigate();

  return (
    <div className="item-customization-error-display">
      <EmptyPage
        title="Yikes! Looks like there’s a problem with this item."
        detail={props.error.message}
        icon={IconType.emptyBag}
      >
        <SheetzButton
          className="go-back-button"
          label="Go Back"
          onClick={(): void => navigate(-1)}
        />
      </EmptyPage>
    </div>
  );
};

const ItemCustomizationWithErrorBoundary = withErrorBoundary(ItemCustomization, {
  FallbackComponent: ItemCustomizationErrorDisplay,
  onError: postErrorLog,
});

const ComboCustomizationWithErrorBoundary = withErrorBoundary(ComboCustomization, {
  FallbackComponent: ItemCustomizationErrorDisplay,
  onError: postErrorLog,
});

export { ItemCustomizationWithErrorBoundary as default, ComboCustomizationWithErrorBoundary };
