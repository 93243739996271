import { AddressAutofill, useConfirmAddress } from "@mapbox/search-js-react";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";

import "./DeliveryAddressForm.scss";

import { DeliveryAddress } from "assets/dtos/anywhere-dto";

import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import ButtonFooterContainer from "components/misc/containers/ButtonFooterContainer/ButtonFooterContainer";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import SheetzInput, { SelectOption } from "components/misc/form/SheetzInput/SheetzInput";
import SheetzSelect from "components/misc/form/SheetzSelect/SheetzSelect";
import SheetzTextArea from "components/misc/form/SheetzTextArea/SheetzTextArea";

import states from "resources/states";

import { mapboxConfirmConfig, sheetzMapBoxAccessToken } from "util/Geolocation.util";
import { AddNewAddressFormValues } from "util/Order.util";
import {
  addressValidation,
  cityValidation,
  stateValidation,
  zipValidation,
} from "util/Validation.util";

const stateSelectOptions: SelectOption[] = states.map((state) => ({
  label: state.name,
  value: state.abbrev,
}));

interface CreateDeliveryAddressProps {
  deliveryAddress?: DeliveryAddress;
  submitCallback: (
    values: AddNewAddressFormValues,
    formikHelpers: FormikHelpers<AddNewAddressFormValues>,
    verifyAddress: boolean
  ) => void;
}

const DeliveryAddressForm = (props: CreateDeliveryAddressProps) => {
  const validationSchema = Yup.object().shape({
    alias: Yup.string()
      .min(3, "The name must contain at least 3 characters.")
      .max(128)
      .required("Required"),
    street: addressValidation.required("Required"),
    unit: addressValidation,
    city: cityValidation.required("Required"),
    stateCode: stateValidation.required("Required"),
    zipCode: zipValidation,
    specialInstructions: Yup.string().max(500),
  });

  const { formRef, showConfirm } = useConfirmAddress(mapboxConfirmConfig);

  function handleSubmit(
    values: AddNewAddressFormValues,
    formikHelpers: FormikHelpers<AddNewAddressFormValues>
  ): void {
    showConfirm().then((result) => {
      switch (result.type) {
        case "change": {
          // The user has accepted MapBox's suggestion, so mark the address as verified.
          const addressProperties = result.feature.properties;
          // MapBox doesn't verify units at this time, so if it exists use that, otherwise use what the user entered.
          const unit =
            addressProperties.address_line2 !== undefined && addressProperties.address_line2 !== ""
              ? addressProperties.address_line2
              : values.unit;

          const formValues: AddNewAddressFormValues = {
            alias: values.alias,
            street: addressProperties.address_line1 ?? "",
            unit,
            city: addressProperties.address_level2 ?? "",
            stateCode: addressProperties.address_level1 ?? "",
            zipCode: addressProperties.postcode ?? "",
            specialInstructions: values.specialInstructions,
          };
          props.submitCallback(formValues, formikHelpers, true);
          break;
        }
        case "nochange": {
          // The user has decided to keep their own, manually edited, address. Do not mark address as verified.
          props.submitCallback(values, formikHelpers, false);
          break;
        }
        case "cancel": {
          formikHelpers.setSubmitting(false);
          break;
        }
      }
    });
  }

  return (
    <>
      <div className="delivery-address-form">
        <ResponsiveLayoutContainer className="account-settings">
          <Formik
            initialValues={{
              alias: props.deliveryAddress?.alias ?? "",
              street: props.deliveryAddress?.street ?? "",
              unit: props.deliveryAddress?.unit ?? "",
              city: props.deliveryAddress?.city ?? "",
              stateCode: props.deliveryAddress?.stateCode ?? "",
              zipCode: props.deliveryAddress?.zipCode ?? "",
              specialInstructions: props.deliveryAddress?.specialInstructions ?? "",
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {(formProps: FormikProps<AddNewAddressFormValues>) => (
              <Form className="create-delivery-address-form" ref={formRef}>
                <SheetzInput type="text" name="alias" placeholder="Name it" label="Name it" />
                <AddressAutofill
                  accessToken={sheetzMapBoxAccessToken}
                  options={{ country: "US" }}
                  onRetrieve={(response) => {
                    // Set the state field programatically. Needed because it isn't a text input.
                    const stateCode = response.features[0].properties.address_level1;
                    if (response.features[0].properties.address_level1) {
                      formProps.setFieldValue("stateCode", stateCode);
                    }
                  }}
                >
                  <SheetzInput
                    type="text"
                    name="street"
                    placeholder="Address line 1"
                    label="Address line 1"
                    autoComplete="address-line1"
                  />
                </AddressAutofill>

                <SheetzInput
                  type="text"
                  name="unit"
                  placeholder="Address line 2 (Optional)"
                  label="Address line 2 (Optional)"
                  autoComplete="address-line2"
                />
                <SheetzInput
                  type="text"
                  name="city"
                  placeholder="City"
                  label="City"
                  autoComplete="address-level2"
                />

                <div className="flex-row">
                  <div className="column">
                    <SheetzSelect
                      name="stateCode"
                      placeholder="State"
                      label="State"
                      options={stateSelectOptions}
                    />
                  </div>
                  <div className="column">
                    <SheetzInput
                      type="text"
                      name="zipCode"
                      placeholder="Zip"
                      label="Zip"
                      autoComplete="postal-code"
                    />
                  </div>
                </div>

                <div className="special-instructions">
                  <SheetzTextArea
                    numberOfRows={10}
                    maxLength={500}
                    placeholderText="Delivery Instructions - 0/500 Characters"
                    name="specialInstructions"
                  />
                </div>

                <ButtonFooterContainer>
                  <SheetzButton
                    type="submit"
                    buttonColor={ButtonColor.sheetzRed}
                    label="Save &amp; Continue"
                    disabled={formProps.isSubmitting}
                  />
                </ButtonFooterContainer>
              </Form>
            )}
          </Formik>
        </ResponsiveLayoutContainer>
      </div>
    </>
  );
};

export default DeliveryAddressForm;
