import classNames from "classnames";
import React, { PropsWithChildren, ReactElement } from "react";

import "./OrderDetailListItem.scss";

import SheetzTextButton, {
  TextButtonColor,
} from "components/misc/button/SheetzTextButton/SheetzTextButton";

import { IconType, getIcon } from "util/Icon.util";

interface OrderDetailListItemProps {
  action?: () => void;
  actionTitle: string;
  icon?: IconType;
  iconClass?: string;
  noFlex?: boolean;
  showAction?: boolean;
}

const OrderDetailListItem = (
  props: PropsWithChildren<OrderDetailListItemProps>
): ReactElement | null => {
  const orderDetailsListItemContentClasses = classNames("order-detail-list-item-content", {
    "no-flex": props.noFlex,
  });

  return (
    <div className="order-detail-list-item">
      {props.icon && (
        <div className="order-detail-list-item-icon">
          {getIcon(props.icon, props.iconClass ?? "")}
        </div>
      )}
      <div className={orderDetailsListItemContentClasses}>{props.children}</div>
      {props.showAction && (
        <SheetzTextButton
          buttonColor={TextButtonColor.sheetzBlue}
          textSize="small-text"
          underline
          label={props.actionTitle}
          onClick={props.action}
        />
      )}
    </div>
  );
};

export default OrderDetailListItem;
