import React, { useContext } from "react";

import "./DeliveryInstructionsInfo.scss";

import { OrderIntegratedDelivery } from "assets/dtos/anywhere-dto";

import { AppContext } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";

export interface DeliveryInstructionsInfoProps {
  deliveryInstructions?: string;
  orderIntegratedDelivery?: OrderIntegratedDelivery;
}

const DeliveryInstructionsInfo = (props: DeliveryInstructionsInfoProps) => {
  const appContext = useContext(AppContext);
  if (!props.deliveryInstructions || !props.orderIntegratedDelivery) {
    return <></>;
  }

  function showSpecialDirections(): void {
    if (props.deliveryInstructions) {
      appContext.showAlertActionSheet(
        props.deliveryInstructions,
        () => (): void => {
          appContext.hideAlertActionSheet();
        },
        "Delivery Instructions"
      );
    }
  }

  return (
    <div className="delivery-instructions-container">
      <div className="delivery-instructions-info">
        <div className="instructions-container">
          <div className="delivery-instructions-header">Delivery Instructions</div>
          <div className="delivery-instructions-label">{props.deliveryInstructions}</div>
        </div>
        <div className="delivery-instructions-icon">
          <button
            className="delivery-dots-btn"
            onClick={(): void => {
              showSpecialDirections();
            }}
          >
            {getIcon(IconType.moreDots, "icon-more-dots")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeliveryInstructionsInfo;
