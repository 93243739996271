import { CheckPinResponse, PinValidationResponse, ResetPinRequest } from "assets/dtos/anywhere-dto";
import axios, { AxiosPromise } from "axios";
import {
  CHECK_USER_FOR_PIN,
  CREATE_USER_PIN,
  RESET_USER_PIN,
  UPDATE_USER_PIN,
  VERIFY_RESET_PIN_TOKEN,
  VERIFY_USER_PIN
} from "endpoints/pin.endpoints";
import { getPinAuthToken, removePinAuthToken } from "./Storage.util";

export interface PinAuthentication {
  jwtToken: string;
  expiration: Date;
}

export function checkUserForPin(): AxiosPromise<CheckPinResponse> {
  return axios({
    method: "GET",
    url: CHECK_USER_FOR_PIN
  });
}

export function createUserPin(pin: string): AxiosPromise<PinValidationResponse> {
  return axios({
    method: "PUT",
    url: CREATE_USER_PIN,
    data: { pinNumber: pin }
  });
}

export function changeUserPin(pin: string, authToken: string): AxiosPromise<PinValidationResponse> {
  return axios({
    method: "PUT",
    url: UPDATE_USER_PIN,
    data: { pinNumber: pin },
    headers: { Authorization: authToken },
    disableErrorMessageDisplay: true
  });
}

export function resetUserPin(data: ResetPinRequest): AxiosPromise<null> {
  return axios({
    method: "POST",
    url: RESET_USER_PIN,
    data,
    disableErrorMessageDisplay: true
  });
}

export function verifyResetPinToken(resetToken: string): AxiosPromise<null> {
  return axios({
    method: "POST",
    url: VERIFY_RESET_PIN_TOKEN,
    data: { resetToken }
  });
}

export function verifyUserPin(pin: string): AxiosPromise<PinValidationResponse> {
  return axios({
    method: "PUT",
    url: VERIFY_USER_PIN,
    data: { pinNumber: pin },
    disableErrorMessageDisplay: true
  });
}

export function verifyPinStillValid(): boolean {
  const pinAuthJWT = getPinAuthToken();
  const exp = pinAuthJWT ? new Date(pinAuthJWT?.expiration) : null;
  // Check if pin has not expired
  // TODO: This only checks minutes, not the year. This should use Luxon to do a proper date check.
  if (!exp || (exp && exp?.getMinutes() + 30 >= Date.now())) {
    removePinAuthToken();
    return false;
  }
  return true;
}

export interface PinStatus {
  ascending?: boolean;
  descending?: boolean;
  matching?: boolean;
  repeating?: boolean;
  valid: boolean;
  vertical?: boolean;
}

export function validatePin(stepOnePin: string, stepTwoPin?: string): PinStatus {
  const pin = stepTwoPin ? stepTwoPin : stepOnePin;

  const one = parseInt(pin.charAt(0));
  const two = parseInt(pin.charAt(1));
  const three = parseInt(pin.charAt(2));
  const four = parseInt(pin.charAt(3));

  const asc = one + 1 === two && one + 2 === three && one + 3 === four;
  const desc = one - 1 === two && one - 2 === three && one - 3 === four;
  const repeat = one === two && one === three && one === four;
  const vert = parseInt(pin) === 2580 || (one === 0 && two === 8 && three === 5 && four === 2);

  let match;
  let valid = !asc && !desc && !repeat && !vert;

  if (stepTwoPin) {
    match = stepOnePin !== stepTwoPin;
    valid = valid && !match;
  }

  return {
    ascending: asc,
    descending: desc,
    repeating: repeat,
    matching: match,
    valid,
    vertical: vert
  };
}

//Pin Creation
export const PIN_DOES_NOT_MEET_REQUIREMENTS = "Pin does not meet requirements";
export const USER_HAS_PIN = "Oh Sheetz! You already have a PIN. Please enter your PIN.";
export const USER_PIN_HAS_BEEN_USED = "Oh Sheetz! You've already used this PIN, please try again";
export const PIN_NOT_SETUP = "Oh Sheetz! PIN not set up, please try again.";
export const NO_SKIPS_LEFT = "Oh Sheetz! No skips left, please create a PIN to continue.";
export const ACCOUNT_NOT_FOUND = "Account not found";
export const CONTACT_CUSTOMER_SERVICE_MESSAGE =
  "Oh Sheetz! Your account has been disabled. Please contact customer service at 1-800-765-4686.";
export const PIN_RESET_APPLICATION_EXCEPTION_MESSAGE = "Pin could not be reset.";
export const INVALID_LINK_EXCEPTION_MESSAGE = "Pin reset link has expired or is invalid.";
// Pin Entry
export const INCORRECT_PIN = "Incorrect PIN, please try again.";
export const USER_HAS_TEMPORARY_LOCK =
  "Oh Sheetz! You are temporarily locked out of Sheetz Pay. Try again in one hour.";
export const USER_HAS_PERMANENT_LOCK =
  "Oh Sheetz! You are locked out of Sheetz Pay. Please contact customer service at 1-800-765-4686.";
