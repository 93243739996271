import React from "react";
import { NumericFormat } from "react-number-format";

import "./SubmitOrderButton.scss";

import { MobilePayment, PaymentMethod, PurchaseOrder } from "assets/dtos/anywhere-dto";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";

import { getMobilePaymentAuthorization, isInNativeMobileContext } from "util/MobileApp.util";
import { SelectedMobilePaymentMethod, SelectedPaymentMethod } from "util/Payment.util";

interface SubmitOrderButtonProps {
  creditCardExpired: boolean;
  isReloadGiftCard?: boolean;
  giftCardHasInsufficientFunds: boolean;
  onSubmitButtonPressed?: (paymentMethod?: PaymentMethod, mobilePayment?: MobilePayment) => void;
  payInStore: boolean;
  purchaseOrder?: PurchaseOrder;
  selectedMobilePaymentMethod?: SelectedMobilePaymentMethod;
  selectedPaymentMethod?: SelectedPaymentMethod;
  total?: number;
}

const SubmitOrderButton = ({
  creditCardExpired,
  isReloadGiftCard,
  giftCardHasInsufficientFunds,
  onSubmitButtonPressed,
  payInStore,
  selectedMobilePaymentMethod,
  selectedPaymentMethod,
  total,
}: SubmitOrderButtonProps) => {
  function onSubmitOrder(paymentMethod?: PaymentMethod) {
    if (isInNativeMobileContext() && selectedMobilePaymentMethod?.selected) {
      if (total !== undefined) {
        getMobilePaymentAuthorization(total).then((response) => {
          if (response) {
            const mobilePayment: MobilePayment = {
              paymentType: selectedMobilePaymentMethod.mobilePayType,
              base64Data: response,
            };

            onSubmitButtonPressed?.(undefined, mobilePayment);
          }
        });
      }
    } else {
      onSubmitButtonPressed?.(paymentMethod);
    }
  }

  return (
    <div className="submit-order-button-container">
      <SheetzButton
        className="submit-order-button"
        textType="title"
        label={isReloadGiftCard ? "Confirm Purchase" : "Submit Order!"}
        label2={
          <NumericFormat
            value={total}
            displayType="text"
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
          />
        }
        onClick={(): void => onSubmitOrder(selectedPaymentMethod?.paymentMethod)}
        disabled={
          (creditCardExpired || giftCardHasInsufficientFunds) &&
          !selectedMobilePaymentMethod?.selected &&
          !payInStore &&
          !isReloadGiftCard
        }
      />
    </div>
  );
};

export default SubmitOrderButton;
