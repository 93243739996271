import React from "react";
import { NumericFormat } from "react-number-format";

import "./SidebarSubHeader.scss";

import { LoyaltyTierStatus } from "assets/dtos/anywhere-dto";

import { SidebarData } from "components/Account/AccountSidebar/AccountSidebar";
import ProgressBar from "components/misc/indicators/ProgressBar/ProgressBar";

import { IconType, getIcon } from "util/Icon.util";
import {
  getLoyaltyThresholdPoints,
  getLoyaltyTierName,
  getNextLoyaltyTierName,
  getPointsNeededToProgress,
  getProgressToNextTier,
  isAtBaseLoyaltyTier,
  isAtTopLoyaltyTier,
} from "util/Loyalty.util";

interface SidebarSubHeaderProps {
  subHeaderType: "loyalty" | "employee";
  loyaltyTierStatus?: LoyaltyTierStatus;
  employeeStatus?: SidebarData["employeeStatus"];
}

export const orderedLoyaltyTierBadges = [IconType.fan, IconType.friend, IconType.freak];

const SidebarSubHeader = (props: SidebarSubHeaderProps) => {
  if (props.subHeaderType === "loyalty") {
    return (
      <div className="sub-header">
        <div>
          {getIcon(
            orderedLoyaltyTierBadges[props.loyaltyTierStatus?.currentTierIndex || 0],
            "tier-icon"
          )}
        </div>
        <div>
          {!isAtBaseLoyaltyTier(props.loyaltyTierStatus) && (
            <p className="tier-expires-label">
              {getLoyaltyTierName(props.loyaltyTierStatus)} until 12/31/
              {props.loyaltyTierStatus?.reevaluationYear}
            </p>
          )}
          <p className="tier-progress-label">
            {props.loyaltyTierStatus?.totalPoints} /{" "}
            {getLoyaltyThresholdPoints(props.loyaltyTierStatus)} Yearly Pointz
          </p>
          <ProgressBar
            maxValue={100}
            currentValue={getProgressToNextTier(props.loyaltyTierStatus)}
          />
          {getPointsNeededToProgress(props.loyaltyTierStatus) > 0 && (
            <p className="tier-maintain-label">
              You need {getPointsNeededToProgress(props.loyaltyTierStatus)} ptz this year to{" "}
              {isAtTopLoyaltyTier(props.loyaltyTierStatus) ? "maintain" : "reach"}{" "}
              {getNextLoyaltyTierName(props.loyaltyTierStatus)} Status
            </p>
          )}
          {getPointsNeededToProgress(props.loyaltyTierStatus) <= 0 && (
            <p className="tier-maintain-label">
              You need 0 ptz this year to{" "}
              {isAtTopLoyaltyTier(props.loyaltyTierStatus) ? "maintain" : "reach"}{" "}
              {getNextLoyaltyTierName(props.loyaltyTierStatus)} Status
            </p>
          )}
        </div>
      </div>
    );
  } else if (
    props.subHeaderType === "employee" &&
    props.employeeStatus &&
    props.employeeStatus.employee
  ) {
    const monthlyRetailLimit = props.employeeStatus.monthlyRetailLimit / 100;
    const monthlyRetailSpent = props.employeeStatus.monthlyRetailSpent / 100;
    const monthlySpentWithCeiling =
      monthlyRetailSpent > monthlyRetailLimit ? monthlyRetailLimit : monthlyRetailSpent;
    const remainingMonthlyBalance =
      monthlyRetailLimit < monthlyRetailSpent ? 0 : monthlyRetailLimit - monthlyRetailSpent;

    return (
      <div className="employee-sub-header">
        <div>{getIcon(IconType.zsquad, "zsquad-icon")}</div>
        <div>
          <p className="tier-expires-label">You&apos;ve spent</p>
          <p className="tier-progress-label">
            <NumericFormat
              value={monthlySpentWithCeiling}
              displayType="text"
              decimalScale={2}
              prefix="$"
              fixedDecimalScale
              thousandSeparator
            />{" "}
            /{" "}
            <NumericFormat
              value={monthlyRetailLimit}
              displayType="text"
              decimalScale={2}
              prefix="$"
              fixedDecimalScale
              thousandSeparator
            />{" "}
            this month
          </p>
          <ProgressBar
            maxValue={100}
            currentValue={(monthlyRetailSpent / monthlyRetailLimit) * 100}
          />

          <p className="tier-maintain-label">
            <NumericFormat
              value={remainingMonthlyBalance}
              displayType="text"
              decimalScale={2}
              prefix="$"
              fixedDecimalScale
              thousandSeparator
            />{" "}
            remaining balance. Your balance resets the first of each month.
          </p>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default SidebarSubHeader;
