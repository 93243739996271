import { isAgeUnverified } from "./Account.util";
import { getMenuCategoryById, getTopLevelCategoryFromMenu } from "./Menu.util";
import Fuse from "fuse.js";

import { Combo, MenuCategory, MenuCategoryRetailItem } from "../assets/dtos/anywhere-dto";

const categorySearchOptions = {
  keys: [
    { name: "name", weight: 3 },
    { name: "nameWithContext", weight: 2 },
    { name: "searchTerms", weight: 1.5 },
    { name: "menuItems.retailItems.receiptText", weight: 1 },
    { name: "menuItems.retailItems.nameWithContext", weight: 1 },
    { name: "menuItems.retailItems.searchTerms", weight: 0.5 },
    { name: "menuItems.retailItems.descriptiveText", weight: 0.5 },
  ],
  threshold: 0.25,
  includeScore: true,
};
const itemSearchOptions = {
  keys: [
    { name: "receiptText", weight: 3 },
    { name: "nameWithContext", weight: 3 },
    { name: "descriptiveText", weight: 1 },
    { name: "searchTerms", weight: 1.5 },
  ],
  threshold: 0.2,
  includeScore: true,
};
const comboSearchOptions = {
  keys: [
    { name: "name", weight: 3 },
    { name: "description", weight: 2 },
    { name: "searchTerms", weight: 1.5 },
  ],
  threshold: 0.2,
  includeScore: true,
};

export function createCategorySearchInstance(menu: MenuCategory[]): Fuse<MenuCategory> {
  return new Fuse(menu, categorySearchOptions);
}

export function createItemSearchInstance(
  menu: MenuCategory[],
  ageVerificationStatus: string | undefined
): Fuse<MenuCategoryRetailItem> {
  const itemMenu: MenuCategoryRetailItem[] = buildItemMenu(menu, ageVerificationStatus);
  return new Fuse(itemMenu, itemSearchOptions);
}

export function createComboSearchInstance(menu: MenuCategory[]): Fuse<Combo> {
  const comboMenu: Combo[] = buildComboMenu(menu);
  return new Fuse(comboMenu, comboSearchOptions);
}

function buildItemMenu(
  menu: MenuCategory[],
  ageVerificationStatus: string | undefined
): MenuCategoryRetailItem[] {
  const topLevelMenuCategory = getTopLevelCategoryFromMenu(menu) ?? menu[0];
  let allReachableMenuItems: MenuCategoryRetailItem[] = [];

  const ageUnverified =
    ageVerificationStatus === undefined || isAgeUnverified(ageVerificationStatus);

  addAllMenuItemsRecursively(menu, topLevelMenuCategory.menuCategoryId);

  function addAllMenuItemsRecursively(menu: MenuCategory[], categoryId: number): void {
    const menuCategory = getMenuCategoryById(menu, categoryId);

    if (menuCategory === undefined) {
      return;
    }

    // Do not allow search of any items if they are in disabled categories or if age restricted and user is not verified.
    if (!menuCategory?.enabled || (menuCategory?.ageRestricted && ageUnverified)) {
      return;
    }

    // Collect all menu items from this category.
    if (menuCategory?.menuItems?.retailItems !== undefined) {
      allReachableMenuItems = allReachableMenuItems.concat(menuCategory.menuItems.retailItems);
    }

    // Iterate over all child menu categories and operate on them recursively by calling this function again.
    if (menuCategory?.childrenMenuCategoryIds !== undefined) {
      menuCategory.childrenMenuCategoryIds.forEach((categoryId) =>
        addAllMenuItemsRecursively(menu, categoryId)
      );
    }
  }

  const itemSet = new Set();
  return allReachableMenuItems
    .filter((item) => {
      if (itemSet.has(item.itemCustomizationId)) {
        return false;
      } else {
        itemSet.add(item.itemCustomizationId);
        return true;
      }
    })
    .sort((a, b) => (a.itemCustomizationId > b.itemCustomizationId ? 1 : -1));
}

function buildComboMenu(menu: MenuCategory[]): Combo[] {
  const combosWithDuplicates: Combo[] = Array.from(
    new Set(menu.flatMap((category) => category.menuItems?.combos ?? []))
  );

  const comboSet = new Set();
  return combosWithDuplicates
    .filter((combo) => {
      if (comboSet.has(combo.menuComboId)) {
        return false;
      } else {
        comboSet.add(comboSet);
        return true;
      }
    })
    .sort((a, b) => (a.menuComboId > b.menuComboId ? 1 : -1));
}
//eslint-disable-next-line
export function isMenuCategory(item: any): item is MenuCategory {
  return item && item.menuCategoryId;
}
//eslint-disable-next-line
export function isMenuCategoryRetailItem(item: any): item is MenuCategoryRetailItem {
  return item && item.itemCustomizationId;
}

//eslint-disable-next-line
export function isCombo(item: any): item is Combo {
  return item && item.menuComboId;
}

export function sortResultsByScore(
  item1: Fuse.FuseResult<MenuCategoryRetailItem | MenuCategory | Combo>,
  item2: Fuse.FuseResult<MenuCategoryRetailItem | MenuCategory | Combo>
): number {
  if (!item1.score || !item2.score) {
    return 0;
  }
  return item1.score < item2.score ? -1 : 1;
}
