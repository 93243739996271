import React, { PropsWithChildren, ReactElement } from "react";
import Styled from "styled-components";

interface FlexContainerProps {
  flexStyles?: React.CSSProperties;
}

const UnstyledFlexContainer = (
  props: PropsWithChildren<FlexContainerProps> & React.HTMLAttributes<HTMLDivElement>
): ReactElement => {
  return <div className={props.className}>{props.children}</div>;
};

const FlexContainer = Styled(UnstyledFlexContainer)((props) => ({
  ...props.flexStyles,
  display: "flex",
  width: "100%",
}));

export default FlexContainer;
