import { ReorderDetailListItemProps } from "components/Order/Reorder/ReorderDetailListItem/ReorderDetailListItem";

import { ShoppingBagCombo, ShoppingBagItem, isCustomizedShoppingBagItem } from "util/Bag.util";

export function calculateSavedComboPrice(combo: ShoppingBagCombo, comboIndex: number): number {
  let price = 0;

  combo?.items.forEach((item, index) => {
    if (index !== comboIndex) {
      price += (item.price ?? 0) + (item.discount ?? 0);
    }
  });

  return price;
}

export function calculateItemDisplayTotal(item: ShoppingBagItem): number {
  let discount: number | undefined;
  let price: number;

  if (isCustomizedShoppingBagItem(item)) {
    discount = item.itemDetails.discount;
    price = item.quantity * item.itemDetails.price;
  } else {
    discount = item.discount;
    // Price for reordered items may initially not be known until the bag is sent to the server.
    price = item.quantity * (item.price ?? 0);
  }

  return discount === undefined || discount === 0 ? price : price + discount;
}

export function calculateReorderDetailListItemTotal(item: ReorderDetailListItemProps): number {
  const discount = item.discount?.discount;

  // Price for reordered items may initially not be known until the bag is sent to the server.
  let price = item.quantity * (item.price ?? 0);

  if (item.discount && item.discount?.price) {
    price = item.quantity * item.discount?.price;
  }

  return discount === undefined || discount === 0 ? price : price - discount;
}
