import React, { Dispatch, SetStateAction } from "react";

import { createItemSwitchSelector } from "components/Order/ItemCustomization/Selectors/ItemSwitchSelector/ItemSwitchSelector.util";
import { createSizeSelector } from "components/Order/ItemCustomization/Selectors/SizeSelector/SizeSelector.util";
import { OrderItemActions } from "components/Order/OrderItemReducer";

import { ItemSwitchOption, SizeSelectOption } from "util/Customization.util";
import { OrderItemState } from "util/Order.util";

interface ItemSwitchSizeSelectorsProps {
  dispatchOrderItem: React.Dispatch<OrderItemActions>;
  orderItemState: OrderItemState;
  setItemCustomizationSuccess: Dispatch<SetStateAction<boolean>>;
}

const ItemSizeSwitchSelectors = (props: ItemSwitchSizeSelectorsProps) => {
  function handleSizeSelectOptionSelected(sizeSelectOption: SizeSelectOption): void {
    props.dispatchOrderItem({
      type: "SET_SIZE",
      payload: sizeSelectOption,
    });
  }

  function handleItemSwitchOptionSelected(itemSwitchOption: ItemSwitchOption): void {
    props.dispatchOrderItem({
      type: "SET_ITEM_SWITCH",
      payload: itemSwitchOption,
    });
  }

  function closeCustomizationActionSheets(): void {
    props.dispatchOrderItem({
      type: "SHOW_ITEM_CUSTOMIZATION_ACTION_SHEET",
      payload: false,
    });
  }

  return (
    <>
      {createItemSwitchSelector(
        closeCustomizationActionSheets,
        props.orderItemState.showCustomizationActionSheets ?? false,
        handleItemSwitchOptionSelected,
        props.orderItemState.chosenItem,
        props.setItemCustomizationSuccess
      )}

      {createSizeSelector(
        closeCustomizationActionSheets,
        props.orderItemState.showCustomizationActionSheets ?? false,
        handleSizeSelectOptionSelected,
        props.orderItemState.chosenItem,
        props.setItemCustomizationSuccess
      )}
    </>
  );
};

export default ItemSizeSwitchSelectors;
