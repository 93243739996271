import axios, { AxiosPromise } from "axios";
import {
  GetDeferredFavoriteOrderResponse,
  AddDeferredFavoriteOrderRequest,
  AddDeferredFavoriteOrderResponse
} from "assets/dtos/anywhere-dto";
import {
  GET_DEFERRED_FAVORITE_ORDER,
  ADD_DEFERRED_FAVORITE_ORDER,
  DELETE_DEFERRED_FAVORITE_ORDER
} from "../endpoints/deferredfavorite.endpoints";

export function getDeferredFavoriteOrder(rememberMeToken: string): AxiosPromise<GetDeferredFavoriteOrderResponse> {
  return axios({
    method: "GET",
    url: GET_DEFERRED_FAVORITE_ORDER(rememberMeToken)
  });
}

export function addDeferredFavoriteOrder(
  request: AddDeferredFavoriteOrderRequest
): AxiosPromise<AddDeferredFavoriteOrderResponse> {
  return axios({
    method: "POST",
    url: ADD_DEFERRED_FAVORITE_ORDER,
    data: request
  });
}

export function deleteDeferredFavoriteOrder(
  deferredFavoriteOrderId: number, rememberMeToken: string
): AxiosPromise<null> {
  return axios({
    method: "DELETE",
    url: DELETE_DEFERRED_FAVORITE_ORDER(deferredFavoriteOrderId, rememberMeToken)
  });
}

