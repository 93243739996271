import React, { useEffect, useState } from "react";

import "./ReceiptPaymentDetail.scss";

import { OrderPaymentMethod, PaymentMethod } from "assets/dtos/anywhere-dto";

import PaymentIcon from "components/Account/MyReceipts/Receipt/PaymentIcon/PaymentIcon";
import FlexContainer from "components/misc/containers/FlexContainer";

import { MobilePayType, getCardName, getGiftCard } from "util/Payment.util";

interface ReceiptPaymentDetailProps {
  paymentMethod?: PaymentMethod;
  orderPaymentMethod?: OrderPaymentMethod;
}

const ReceiptPaymentDetail = (props: ReceiptPaymentDetailProps) => {
  const paymentType = props.orderPaymentMethod?.paymentType || props.paymentMethod?.paymentType;
  const paymentMethodId =
    props.orderPaymentMethod?.paymentMethodId || props.paymentMethod?.paymentMethodId;
  const creditCardType =
    props.orderPaymentMethod?.creditCardType || props.paymentMethod?.creditCard?.cardType;
  const lastFourDigits =
    props.orderPaymentMethod?.lastFourDigits ||
    props.paymentMethod?.creditCard?.lastFourDigits ||
    props.paymentMethod?.zCard?.cardNumber.substring(
      props.paymentMethod?.zCard?.cardNumber.length - 4
    );

  const [paymentMethodName, setPaymentMethodName] = useState<string | undefined>();

  useEffect(() => {
    if (paymentType === MobilePayType.ApplePay) {
      setPaymentMethodName("Apple Pay");
    } else if (paymentType === MobilePayType.GooglePay) {
      setPaymentMethodName("Google Pay");
    } else if (paymentType === "ZCARD") {
      if (paymentMethodId !== undefined) {
        getGiftCard(paymentMethodId).then((response) => {
          setPaymentMethodName(response.data.zcard.zCard?.cardName ?? "My Gift Card");
        });
      } else {
        setPaymentMethodName("My Gift Card");
      }
    } else if (creditCardType !== undefined) {
      setPaymentMethodName(getCardName(creditCardType));
    }
  }, [creditCardType, paymentMethodId, paymentType]);

  if (props.paymentMethod === undefined && props.orderPaymentMethod === undefined) {
    return null;
  }

  return (
    <div className="receipt-payment-detail">
      <p className="heading">My Payment</p>
      <FlexContainer flexStyles={{ gap: "15px", alignItems: "center" }}>
        <PaymentIcon paymentType={paymentType} creditCardType={creditCardType} />
        <p className="payment-detail-label">
          {paymentMethodName} {lastFourDigits}
        </p>
      </FlexContainer>
    </div>
  );
};

export default ReceiptPaymentDetail;
