import { DateTime } from "luxon";

export const SERVER_TIME_ZONE = "America/New_York";

export enum PickUpDay {
  Today = "Today",
  Tomorrow = "Tomorrow",
}
export type PickUpDayKey = keyof typeof PickUpDay;

export enum PickUpHour {
  one = "1",
  two = "2",
  three = "3",
  four = "4",
  five = "5",
  six = "6",
  seven = "7",
  eight = "8",
  nine = "9",
  ten = "10",
  eleven = "11",
  twelve = "12",
}
export type PickUpHourKey = keyof typeof PickUpHour;

export enum PickUpMinute {
  zero = "00",
  ten = "10",
  twenty = "20",
  thirty = "30",
  fourty = "40",
  fifty = "50",
}
export type PickUpMinuteKey = keyof typeof PickUpMinute;

export enum PickUpPeriod {
  AM = "AM",
  PM = "PM",
}
export type PickUpPeriodKey = keyof typeof PickUpPeriod;

export function getDateTimeFromString(time: string): DateTime {
  return DateTime.fromISO(time, { setZone: true }).setZone(SERVER_TIME_ZONE);
}

export function getTomorrowFromDateTime(today: DateTime): DateTime {
  return today.plus({ days: 1 });
}

export function getFormattedHourFromDateTime(time: DateTime): string {
  return time.toFormat("h");
}

export function getFormattedMinutesFromDateTime(time: DateTime): string {
  return time.toFormat("mm");
}

export function getMeridiemFromDateTime(time: DateTime): string {
  return time.toFormat("a");
}

export function getDateTimeFromFormValues(
  dateTime: DateTime,
  day: PickUpDay,
  hour: PickUpHour,
  minute: PickUpMinute,
  period: PickUpPeriod
): DateTime {
  let pickupDateTime: DateTime;
  if (day === PickUpDay.Tomorrow) {
    pickupDateTime = dateTime.plus({ days: 1 });
  } else {
    pickupDateTime = dateTime;
  }

  if (period === PickUpPeriod.PM) {
    if (hour !== PickUpHour.twelve) {
      const militaryHour = parseInt(hour) + 12;
      pickupDateTime = pickupDateTime.set({ hour: militaryHour });
    } else {
      pickupDateTime = pickupDateTime.set({ hour: 12 });
    }
  } else {
    if (hour !== PickUpHour.twelve) {
      pickupDateTime = pickupDateTime.set({ hour: parseInt(hour) });
    } else {
      pickupDateTime = pickupDateTime.set({ hour: 0 });
    }
  }

  pickupDateTime = pickupDateTime.set({ minute: parseInt(minute) });
  return pickupDateTime;
}

export function isDateTimeToday(dateTime: DateTime): boolean {
  const now = DateTime.local().setZone(SERVER_TIME_ZONE);
  return (
    dateTime.hasSame(now, "day") && dateTime.hasSame(now, "month") && dateTime.hasSame(now, "year")
  );
}

export function isDateTimeTomorrow(dateTime: DateTime): boolean {
  const now = DateTime.local().setZone(SERVER_TIME_ZONE);
  const tomorrow = now.plus({ days: 1 });
  return dateTime.hasSame(tomorrow, "day");
}

export function isDateTimeNextDay(dateTime: DateTime): boolean {
  const now = DateTime.local().setZone(SERVER_TIME_ZONE);
  const nextday = now.plus({ days: 1 });
  return dateTime.hasSame(nextday, "day");
}

export function getHoursSinceDateTime(dateTime: DateTime): number {
  const timeElapsed = DateTime.now().diff(dateTime, "hours");
  return timeElapsed.hours;
}
