import sumBy from "lodash/sumBy";
import React, { FC, ReactElement } from "react";

import "./RewardzHistory.scss";

import { GetTransactionHistoryResponse } from "assets/dtos/anywhere-dto";

import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";

import { getDateTimeFromString } from "util/Time.util";

interface RewardzHistoryProps {
  transactionHistory?: GetTransactionHistoryResponse;
}

const getPointzClass = function (amount: number): string {
  return amount > 0 ? " reward-history-ptz-color-positive" : " reward-history-ptz-color-negative";
};

const RewardzHistory: FC<RewardzHistoryProps> = (props) => {
  const rewardHistory = (transactions?: GetTransactionHistoryResponse): ReactElement => {
    if (transactions) {
      // eslint-disable-next-line array-callback-return
      const transactionList = transactions.transactions.map((transaction) => {
        let pointsTotal: number;
        // Getting records and parameters under "StoredValue"
        if (transaction.storedValues && transaction.storedValues.length > 0) {
          pointsTotal = sumBy(transaction.storedValues, "amount");
          return (
            <>
              <div className="reward-history-item">
                <div className="reward-history-item-date">
                  {getDateTimeFromString(transaction.details.transactionDate).toFormat(
                    "M'/'d'/'yy"
                  )}
                  <br></br>
                  {transaction.storedValues[0].programDesc}
                </div>
                <div className={"reward-history-item-ptz" + getPointzClass(pointsTotal)}>
                  {pointsTotal}
                  <br></br> Ptz
                </div>
              </div>
            </>
          );
        } else {
          // Getting records and parameters under "Points"

          if (transaction.points && transaction.points.length > 0) {
            pointsTotal = sumBy(transaction.points, "amount");
            return (
              <>
                <div className="reward-history-item">
                  <div className="reward-history-item-date">
                    {getDateTimeFromString(transaction.details.transactionDate).toFormat(
                      "M'/'d'/'yy"
                    )}
                    <br></br>
                    {transaction.points[0].programDesc}
                  </div>
                  <div className={"reward-history-item-ptz" + getPointzClass(pointsTotal)}>
                    {pointsTotal}
                    <br></br>
                    Ptz
                  </div>
                </div>
              </>
            );
          }
        }
      });
      return <>{transactionList}</>;
    } else {
      return <></>;
    }
  };

  return (
    <ResponsiveLayoutContainer>
      <div className="reward-history-container">{rewardHistory(props.transactionHistory)}</div>
    </ResponsiveLayoutContainer>
  );
};

export default RewardzHistory;
