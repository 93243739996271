import AppCupImage from "assets/images/loading/2Col_Apps_Cup.png";
import CookieImage from "assets/images/loading/2Col_Conv_Shweetz_Chocolate_Chip_Cookie.png";
import CowboyImage from "assets/images/loading/2Col_Hamburger_Cowboy.png";
import HotChocolateImage from "assets/images/loading/2Col_Hot_Chocolate_Caramel.png";
import MilkshakeImage from "assets/images/loading/2Col_Milkshake_Oreo.png";
import RoastBeefSandwichImage from "assets/images/loading/2Col_Sandwich_Turkey.png";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../util/AppContext.util";
import InlineLoading from "./InlineLoading";
import "./Loading.scss";

const Loading = (): ReactElement => {
  const state = useContext(AppContext);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const imageList = [
    AppCupImage,
    CookieImage,
    CowboyImage,
    HotChocolateImage,
    MilkshakeImage,
    RoastBeefSandwichImage
  ];

  function getRandomImage(): string {
    const imagePos = Math.round(Math.random() * (imageList.length - 1));
    return imageList[imagePos];
  }

  const [loadImage] = useState<string>(getRandomImage);
  useEffect(() => {
    setShowLoading(state.loading.show);
  }, [state.loading.show]);

  return (
    <div className={"fader" + (showLoading ? " show" : " hide")}>
      <div className="loading-overlay" />
      <div className="loading-container">
        <InlineLoading>
          <img src={loadImage} className="loading-image" alt="Loading!" />
        </InlineLoading>
      </div>
    </div>
  );
};

export default Loading;
