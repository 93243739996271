import React from "react";

import "./RestrictedItemPickupTermsActionSheet.scss";

import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

interface RestrictedItemPickupTermsActionSheetProps {
  show: boolean;
  setShowRestrictedItemPickupTermsActionSheet: React.Dispatch<React.SetStateAction<boolean>>;
}

const RestrictedItemPickupTermsActionSheet = (props: RestrictedItemPickupTermsActionSheetProps) => {
  return (
    <ActionSheet
      color={ActionSheetColor.red}
      title="Get Prepared For 21+ Pick-up"
      overlay={true}
      shouldDisplay={props.show}
    >
      <div className="restricted-item-pickup-terms">
        <div className="instructions">
          <p>
            <strong>Bring your ID for age verification</strong>
          </p>
          <p className="instruction">
            Store team members will scan your ID to verify your age and identity before handing over
            your order.
          </p>
          <p className="instruction">Your order cannot be picked up by anyone other than you.</p>
        </div>
        <p className="disclaimer">
          You won’t receive any part of the order if you are underage, intoxicated, or unable to
          present an ID.
        </p>
        <div className="accept-continue-button">
          <SheetzButton
            buttonColor={ButtonColor.sheetzGreen}
            label="Accept & Continue"
            onClick={(): void => props.setShowRestrictedItemPickupTermsActionSheet(false)}
          />
        </div>
      </div>
    </ActionSheet>
  );
};

export default RestrictedItemPickupTermsActionSheet;
