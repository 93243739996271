import { ExtraableOption, ItemSwitchOption, SingleSelectOption } from "util/Customization.util";

export function setInlineSubSelectorRowPlacement(
  useDesktopView: boolean,
  grid: HTMLDivElement | null,
  inlineSubSelector: HTMLDivElement | null,
  selectorOptions: ExtraableOption[] | SingleSelectOption[] | ItemSwitchOption[],
  selectedOption?: ExtraableOption | SingleSelectOption | ItemSwitchOption
): void {
  if (!selectedOption || !grid || !inlineSubSelector) {
    return;
  }

  // Calculate the number of columns and the index of the selected option to figure out the row placement.
  const numberOfColumns = getComputedStyle(grid).gridTemplateColumns.split(" ").length;

  const selectedOptionIndex =
    selectorOptions.findIndex(
      (extraOption: ExtraableOption | SingleSelectOption | ItemSwitchOption) =>
        extraOption.option.text === selectedOption.option.text
    ) + 1;
  const rowStart = Math.ceil(selectedOptionIndex / numberOfColumns) + 1;

  inlineSubSelector.style.gridRowStart = rowStart.toString();

  // Calculate the arrow offset so it is pointing to the right option (the selected one)
  const selectedOptionElement = grid.getElementsByClassName(
    "customization-option condiment selected"
  )[0] as HTMLElement | undefined;
  const arrow = inlineSubSelector.getElementsByClassName("arrow-up")[0] as HTMLElement | undefined;
  const itemDetailsContainer = document.getElementsByClassName("item-details-container")[0] as
    | HTMLElement
    | undefined;

  if (selectedOptionElement && arrow) {
    const offsetLeft =
      useDesktopView && itemDetailsContainer
        ? selectedOptionElement.offsetLeft - itemDetailsContainer.offsetWidth
        : selectedOptionElement.offsetLeft;

    const arrowOffset =
      // Formula is ("offsetLeft" - "outside padding") + "half of the selected option's width" - "half tof the arrow's width"
      offsetLeft - 16 + selectedOptionElement.offsetWidth / 2 - 6;

    arrow.style.left = arrowOffset + "px";
  }
}
