import classNames from "classnames";
import React, { PropsWithChildren, ReactElement } from "react";

import "./EmptyPage.scss";

import { IconType, getIcon } from "util/Icon.util";

interface EmptyPageProps {
  detail?: string;
  icon: IconType;
  noMargin?: boolean;
  title: string;
}

const EmptyPage = (props: PropsWithChildren<EmptyPageProps>): ReactElement => {
  const emptyPageClassnames = classNames("empty-page", {
    "no-margin": props.noMargin,
  });
  const iconContainerClassnames = classNames("empty-page-icon-container", {
    "alert-icon": props.icon === IconType.alert,
    "check-icon": IconType.check,
    "empty-bag-icon": props.icon === IconType.emptyBag,
  });
  const emptyPageIcon = getIcon(props.icon, "empty-page-icon");

  return (
    <div className="empty-page-container">
      <div className={emptyPageClassnames}>
        <div className={iconContainerClassnames}>{emptyPageIcon}</div>
        <div className="empty-page-title">{props.title}</div>
        {props.detail !== undefined && <div className="empty-page-detail">{props.detail}</div>}
        {props.children}
      </div>
    </div>
  );
};

export default EmptyPage;
