import React, { ReactElement, useContext, useEffect, useState } from "react";

import "./PaymentFooterTipManager.scss";

import { DeliveryTip } from "assets/dtos/anywhere-dto";

import PaymentFooterTipChoice, {
  TipChoice,
} from "components/Order/PaymentFooter/PaymentFooterTipChoice/PaymentFooterTipChoice";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";

import { AppContext } from "util/AppContext.util";
import { getTipChoiceFromPercentage } from "util/Order.util";

interface PaymentFooterTipManagerProps {
  itemTotal: number;
  deiveryTipSuggestions: DeliveryTip[];
  otherAmount?: number;
  tipChoice?: TipChoice;
  onTipSelected: (tipChoice: TipChoice) => void;
}

const PaymentFooterTipManager = (props: PaymentFooterTipManagerProps): ReactElement => {
  const appContext = useContext(AppContext);
  const [selectedTipChoice, setSelectedTipChoice] = useState<TipChoice | undefined>(
    props.tipChoice
  );

  useEffect(() => {
    setSelectedTipChoice(props.tipChoice);
  }, [props.tipChoice]);

  function tipChoiceSelected(tipChoice: TipChoice): void {
    appContext.hideToast();
    if (tipChoice !== TipChoice.Other) {
      setSelectedTipChoice(tipChoice);
    }
    props.onTipSelected(tipChoice);
  }

  const tipChoices = props.deiveryTipSuggestions.map((tipSuggestion) => {
    const tipChoice = getTipChoiceFromPercentage(tipSuggestion.tipPercentage);
    if (tipChoice === undefined || tipSuggestion.tipAmount === undefined) {
      return <></>;
    }
    return (
      <PaymentFooterTipChoice
        key={tipSuggestion.tipPercentage}
        tipChoice={tipChoice}
        tipAmount={tipSuggestion.tipAmount}
        isSelected={selectedTipChoice === tipChoice}
        onSelect={(): void => tipChoiceSelected(tipChoice)}
      />
    );
  });

  // Add the "other" tip amount option
  tipChoices.push(
    <PaymentFooterTipChoice
      key={TipChoice.Other.toString()}
      tipChoice={TipChoice.Other}
      tipAmount={props.otherAmount ?? 0}
      isSelected={selectedTipChoice === TipChoice.Other}
      onSelect={(): void => tipChoiceSelected(TipChoice.Other)}
    />
  );

  return (
    <ResponsiveLayoutContainer>
      <div className="payment-footer-tip-manager">
        <p className="prompt-text">Add a tip for your driver?</p>
        <div className="tip-choices-flex-container">{tipChoices}</div>
      </div>
    </ResponsiveLayoutContainer>
  );
};

export default PaymentFooterTipManager;
