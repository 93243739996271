import { Formik, FormikErrors, FormikProps } from "formik";
import React, { ReactElement, useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import "./ChangePassword.scss";

import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import ButtonFooterContainer from "components/misc/containers/ButtonFooterContainer/ButtonFooterContainer";
import PasswordChecklist, {
  processValidationErrors,
} from "components/misc/form/PasswordChecklist/PasswordChecklist";
import SheetzInput from "components/misc/form/SheetzInput/SheetzInput";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { changePassword } from "util/Authentication.util";

interface FormValues {
  currentPassword: string;
  password: string;
  passwordConfirm: string;
}

const ChangePassword = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  const showLoading = appContext.showLoading;
  const hideLoading = appContext.hideLoading;

  const submitChangePassword = (values: FormValues): void => {
    showLoading();

    changePassword({ currentPassword: values.currentPassword, newPassword: values.password })
      .then(() => {
        appContext.hideToast();
        navigate("/account/settings/security");
        setTimeout(() => {
          appContext.showToast(
            "Password Successfully Changed!",
            "You're good to go!",
            ToastType.success
          );
        }, 500);
      })
      .finally(() => {
        hideLoading();
      });
  };

  const changePasswordValidationSchema = Yup.object({
    currentPassword: Yup.string().required("Required").max(256).min(8),
    password: Yup.string()
      .required("Required")
      .max(256)
      .min(8)
      .matches(new RegExp("^[0-9a-zA-Z@!#$%^&*()_<>` ]+$"), "Invalid character")
      .matches(new RegExp("^.*[A-Z].*$"), "New password must contain at least one uppercase letter")
      .matches(new RegExp("^.*[a-z].*$"), "New password must contain at least one lowercase letter")
      .matches(new RegExp("^.*[0-9].*$"), "New password must contain at least one number")
      .matches(
        new RegExp("^.*[!@#$%^&*()_].*$"),
        "New password must contain at least special character"
      )
      .notOneOf(
        [Yup.ref("currentPassword"), null],
        "New password must be different than current password"
      ),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("New Password Confirm is required"),
  });

  const validateSchema = (values: FormValues): Promise<FormValues | FormikErrors<FormValues>> => {
    const emptyError: FormikErrors<FormValues> = {};
    return changePasswordValidationSchema
      .validate(values, { abortEarly: false })
      .then(() => {
        return emptyError;
      })
      .catch((errors: Yup.ValidationError) => {
        return processValidationErrors(errors);
      });
  };

  return (
    <div className="change-password-container">
      <Formik
        initialValues={{
          currentPassword: "",
          password: "",
          passwordConfirm: "",
        }}
        onSubmit={(values: FormValues): void => {
          submitChangePassword(values);
        }}
        validate={(values: FormValues): Promise<FormValues | FormikErrors<FormValues>> =>
          validateSchema(values)
        }
      >
        {(props: FormikProps<FormValues>): ReactElement => (
          <div className="change-password-form">
            <form onSubmit={props.handleSubmit}>
              <PasswordChecklist
                trackChanges={props.values.password.length > 0}
                passwordError={props.errors.password || ""}
                validateCurrentPassword={true}
              />

              <SheetzInput
                type="password"
                name="currentPassword"
                placeholder="Current Password"
                label="Current Password"
              />

              <SheetzInput
                type="password"
                name="password"
                placeholder="New Password"
                label="New Password"
                showError={false}
              />

              <SheetzInput
                type="password"
                name="passwordConfirm"
                placeholder="Confirm New Password"
                label="Confirm New Password"
              />

              <ButtonFooterContainer>
                <SheetzButton
                  className="submit-button"
                  buttonColor={ButtonColor.sheetzRed}
                  type="submit"
                  label="Save Changes"
                />
              </ButtonFooterContainer>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ChangePassword;
