import React, { ReactElement, useContext } from "react";

import "./SelectorHeader.scss";

import { CustomizationSubmittedContext } from "components/Order/ItemCustomization/ItemCustomization";

interface SelectorHeaderProps {
  headerText: string;
  maxSelections?: number;
  required?: boolean;
  selected?: boolean;
}

const SelectorHeader = (props: SelectorHeaderProps): ReactElement => {
  const customizationSubmitted = useContext(CustomizationSubmittedContext);

  const instructionsClassnames =
    props.required && !props.selected && customizationSubmitted
      ? "required selector-instructions"
      : "selector-instructions";

  return (
    <div className="selector-header">
      <p className="selector-title">{props.headerText}</p>
      <p className={instructionsClassnames}>
        {props.maxSelections !== undefined && "Choose up to " + props.maxSelections}
        {props.required && <span>(Required)</span>}
      </p>
    </div>
  );
};

export default SelectorHeader;
