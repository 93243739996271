import React, { ReactElement } from "react";

import "./ConfirmWalletActionBody.scss";

export interface ConfirmWalletActionBodyProps {
  amount?: number;
  purchaseNewGiftCard?: boolean;
  reloadAmount?: number;
  reloadThreshold?: number;
}

const ConfirmWalletActionBody = (props: ConfirmWalletActionBodyProps): ReactElement => {
  return (
    <>
      <div className="add-funds-body">
        <div className="purchase-details-label">Purchase Details</div>

        {props.amount && (
          <div className="purchase-details-row">
            <div className="purchase-amount-label">
              {props.purchaseNewGiftCard ? "Amount" : "Reload Amount"}
            </div>
            <div className="purchase-amount">{"$" + props.amount}</div>
          </div>
        )}

        {props.reloadAmount && (
          <div className="purchase-details-row">
            <div className="purchase-amount-label">Auto Reload</div>
            <div className="purchase-amount">{"$" + props.reloadAmount}</div>
          </div>
        )}

        {props.reloadThreshold && (
          <div className="purchase-details-row">
            <div className="purchase-amount-label">When Balance is below:</div>
            <div className="purchase-amount">{"$" + props.reloadThreshold}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default ConfirmWalletActionBody;
