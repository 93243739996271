import React from "react";

import "./ReceiptLoyaltyPointsEarned.scss";

interface ReceiptLoyaltyPointsEarnedProps {
  pointsEarned?: number;
}

const ReceiptLoyaltyPointsEarned = (props: ReceiptLoyaltyPointsEarnedProps) => {
  if (props.pointsEarned === undefined || props.pointsEarned <= 0) {
    return null;
  }

  return (
    <div className="loyalty-points-earned">
      <span className="points">+{props.pointsEarned} </span>
      <span className="points-label">Spendable Ptz Earned</span>
    </div>
  );
};

export default ReceiptLoyaltyPointsEarned;
