import React, { PropsWithChildren, useContext, useEffect } from "react";
import Modal from "react-modal";

import "./SheetzModal.scss";

import SheetzToast from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";

export type ModalBackgroundColor = "green" | "red";

// react-modal doesn't currently have a types file... here's a basic interface to make things simpler
// There's definitely more things we could add if needed, this is just a basic set.
interface BaseModalProps {
  className: string;
  isOpen: boolean;
  contentLabel: string;
  onRequestClose: () => void;
  shouldCloseOnOverlayClick: boolean;
  headerText: string;
  closeFunction: () => void;
  backgroundColor?: ModalBackgroundColor;
  hideHeader?: boolean;
}

// Note - The way the Modal is rendered, a component with a formik form will need to pass initial values from the outside.
// Look to MySheetzCards and AddMySheetzCards as an example... The state is lost between rerenders of the modal.
// This was tried with a normal ReactElement as well, instead of making SheetzModal an FC
const SheetzModal = (props: PropsWithChildren<BaseModalProps>) => {
  const appContext = useContext(AppContext);
  Modal.setAppElement("#root");

  useEffect(() => {
    return () => {
      if (props.isOpen) {
        appContext.hideToast();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  useEffect((): (() => void) => {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100vh";
    return (): void => {
      document.body.style.overflow = "unset";
      document.body.style.height = "unset";
    };
  }, []);

  useEffect(() => {
    const modalContent = document.getElementById("modal-content");
    if (modalContent) {
      modalContent.scrollTop = 0;
    }
  }, [appContext.toast.render]);

  let modalHeaderClass = "modal-header";
  if (props.backgroundColor && props.backgroundColor === "green") {
    modalHeaderClass = modalHeaderClass + " modal-header-green";
  } else if (props.backgroundColor && props.backgroundColor === "red") {
    modalHeaderClass = modalHeaderClass + " modal-header-red";
  }

  return (
    <Modal
      className={"sheetz-modal " + props.className}
      overlayClassName="modal-overlay"
      isOpen={props.isOpen}
      contentLabel={props.contentLabel}
      onRequestClose={props.onRequestClose}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
    >
      {!props.hideHeader && (
        <div className={modalHeaderClass}>
          <div className="modal-header-text">{props.headerText}</div>
          <button
            className="modal-close"
            aria-label={"Close " + props.contentLabel + " modal"}
            onClick={(): void => {
              appContext.hideToast();
              props.closeFunction();
            }}
          >
            {getIcon(IconType.x, "modal-close-icon")}
          </button>
        </div>
      )}
      <div className="modal-content" id="modal-content">
        {appContext.toast.render && appContext.toast.modal && (
          <SheetzToast
            title={appContext.toast.title}
            text={appContext.toast.text}
            type={appContext.toast.type}
            shouldShowToast={appContext.toast.show}
            modal={appContext.toast.modal}
            toastPressed={() => appContext.hideToast()}
          />
        )}
        {props.children}
      </div>
      <div className="modal-footer" />
    </Modal>
  );
};

export default SheetzModal;
