import {
  CustomizedShoppingBagItem,
  ShoppingBagItem,
  isCustomizedShoppingBagItem,
} from "./Bag.util";
import { CustomizedItem } from "./Order.util";
import { ReorderedShoppingBagItem } from "./Reorder.util";
import { deriveCondiment, getAllTags } from "./Tag.util";
import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";

import {
  Condiment,
  FavoriteOrderCondiment,
  GetItemCustomizationResponse,
  ItemCustomizationTemplate,
  ItemEvent,
  Option,
  RetailItem,
  RetailModifiedItem,
  Selector,
  SelectorType,
} from "assets/dtos/anywhere-dto";

import { processAutoCondimentSelector } from "../components/Order/ItemCustomization/Selectors/AutoCondimentSelector.util";
import { processAutoQuantitySelector } from "../components/Order/ItemCustomization/Selectors/AutoQuantitySelector.util";
import { processAutoSizeSelector } from "../components/Order/ItemCustomization/Selectors/AutoSizeSelector.util";
import { SelectorTab } from "components/Order/ItemCustomization/SelectorTabs/SelectorTabs";
import { CustomizationOption } from "components/Order/OrderSessionReducer";

export type ItemCustomization = GetItemCustomizationResponse;

/**
 * A type that represents the current state of all selectors for an item customization.
 * Basically, a hash map.
 */
export type ItemCustomizationSelections = { [key: string]: CustomizationOption };

export interface SizeSelectOption {
  option: Option;
  retailModifiedItem: RetailModifiedItem;
}

export interface SingleSelectOption {
  option: Option;
  condiment?: Condiment;
  isNoOption?: boolean;
  selectedInlineSubOption?: Option;
}

export interface ExtraableOption {
  option: Option;
  condiment: Condiment;
  extraOption?: Option;
  extraCondiment?: Condiment;
  extraOptionSelected?: boolean;
}

export interface ItemSwitchOption {
  option: Option;
  switchRi: RetailItem;
  switchRmi: RetailModifiedItem;
  selectedInlineSubOption?: Option;
}

export interface InlineSubOption {
  option: Option;
  condiment: Condiment;
}

export interface MultiOptionSwitchOption {
  option: Option;
  condiment: Condiment;
  inlineSubOptions?: InlineSubOption[];
  selectedInlineSubOption?: InlineSubOption;
  priceDifference?: number;
}

export type DoubleOrSwitchOption = MultiOptionSwitchOption | ExtraableOption;

export enum PortionTypes {
  LESS = "less",
  REGULAR = "regular",
  MORE = "more",
  SIDE = "side",
}

export interface PortionedCondiment {
  condiment: Condiment;
  selectedPortion?: PortionTypes;
}

//eslint-disable-next-line
export function isPortionedCondiment(condiment: any): condiment is PortionedCondiment {
  return condiment && (condiment as PortionedCondiment).condiment !== undefined;
}

//eslint-disable-next-line
export function isFavoriteOrderCondiment(condiment: any): condiment is FavoriteOrderCondiment {
  return condiment && condiment.retailModifiedItemId;
}

export const autoSelectorTypes = ["AUTO_SIZE", "AUTO_CONDIMENT", "AUTO_QUANTITY"] as SelectorType[];
export const invisibleSelectorTypes = autoSelectorTypes.concat(["SIZE"]) as SelectorType[];

export function findSizeSelector(
  customizationTemplate: ItemCustomizationTemplate
): Selector | undefined {
  return customizationTemplate.selectors.find((selector) => selector.type === "SIZE");
}

export function findItemSwitchSelector(
  customizationTemplate: ItemCustomizationTemplate
): Selector | undefined {
  return customizationTemplate.selectors.find((selector) => selector.type === "ITEM_SWITCH");
}

export function getSizeSelectorText(customizationTemplate?: ItemCustomizationTemplate): string {
  if (!customizationTemplate) {
    return "";
  }
  return findSizeSelector(customizationTemplate)?.text || "";
}

export function findAutoSizeSelector(
  customizationTemplate: ItemCustomizationTemplate
): Selector | undefined {
  return customizationTemplate.selectors.find((selector) => selector.type === "AUTO_SIZE");
}

export function getSelectorTabs(selectors: Selector[]): SelectorTab[] {
  const tabs = [] as SelectorTab[];
  const visibleSelectors = selectors.filter(
    (selector) =>
      !invisibleSelectorTypes.includes(selector.type) &&
      selector.shortText &&
      !selector.displayAsActionSheet
  );
  visibleSelectors.forEach((selector, index) => {
    // TODO: Currently, some item customization templates have selectors with the same `shortText`. Should we validate against this?
    if (!selector.shortText) {
      // eslint-disable-next-line no-template-curly-in-string
      throw new SheetzError("Selector of type=${selector.type} is missing short text.", {
        userReadableMessage: "Looks like we've hit a snag. Please try again.",
        primaryButton: SheetzErrorButtonType.TRY_AGAIN,
      });
    }
    const selectorTabId = selector.tags
      ? selector.shortText.toLowerCase() + "_" + selector.tags.toString()
      : selector.shortText.toLowerCase() + "_" + selector.text;
    tabs.push({ title: selector.shortText ?? "", id: selectorTabId });
  });
  return tabs;
}

/**
 * Simple convenience function that returns true if the condiment passed in is contained
 * within the list of condiments passed in.
 */
export function isCondimentInList(existingCondiments: Condiment[], condiment: Condiment): boolean {
  return !!existingCondiments.find(
    (exisitngCondiment) =>
      exisitngCondiment.retailModifiedItem.retailModifiedItemId ===
      condiment.retailModifiedItem.retailModifiedItemId
  );
}

/**
 * Return a new condiment list with the condimentToRemove filtered out.
 */
export function filterCondiment(
  exisitngCondiments: PortionedCondiment[],
  condimentToRemove: Condiment
): PortionedCondiment[] {
  return exisitngCondiments.filter(
    (portionedCondiment) =>
      portionedCondiment.condiment.retailModifiedItem.retailModifiedItemId !==
      condimentToRemove.retailModifiedItem.retailModifiedItemId
  );
}

/**
 * Convenience function that takes multiple condiments to remove and returns a
 * new list with them filtered out.
 */
export function filterCondiments(
  exisitngCondiments: PortionedCondiment[],
  condimentsToRemove: Condiment[]
): PortionedCondiment[] {
  let filteredCondiments = exisitngCondiments;
  condimentsToRemove.forEach(
    (condimentToRemove) =>
      (filteredCondiments = filterCondiment(filteredCondiments, condimentToRemove))
  );
  return filteredCondiments;
}

export function handleDefaultOption(
  selector: Selector,
  existingTags: string[],
  itemCustomization: ItemCustomization,
  item: CustomizedItem
): void {
  if (selector.defaultOption) {
    const defaultCondiment = deriveCondiment(
      selector.defaultOption.tags ?? [],
      existingTags,
      itemCustomization,
      false
    );
    item.condiments.push(defaultCondiment);
    handleTagAlongTags(selector.defaultOption, itemCustomization, item);
  }
}

export function handleTagAlongTags(
  option: Option,
  itemCustomization: ItemCustomization,
  item: CustomizedItem
): void {
  if (option.tagAlongTags) {
    const tagAlongCondiment = deriveCondiment(
      option.tagAlongTags,
      getAllTags(item),
      itemCustomization,
      false
    );
    if (tagAlongCondiment) {
      item.condiments.push(tagAlongCondiment);
    }
  }
}

export function handleInlineSubSelectorNoOption(
  selectedOption: SingleSelectOption,
  existingTags: string[],
  itemCustomization: ItemCustomization,
  item: CustomizedItem
): void {
  if (
    selectedOption.selectedInlineSubOption === undefined &&
    selectedOption.option.inlineSubSelector?.noOption?.tags !== undefined
  ) {
    const noOptionCondiment = deriveCondiment(
      selectedOption.option.inlineSubSelector.noOption.tags,
      existingTags,
      itemCustomization,
      false
    );
    if (noOptionCondiment !== undefined) {
      item.condiments.push(noOptionCondiment);
    }
  }
}

export function calculateItemSubtotal(item: CustomizedItem): number {
  let price = item.retailModifiedItem?.price || 0;
  item.condiments.forEach((condiment) => {
    let rmi;
    if (isPortionedCondiment(condiment)) {
      if (condiment.selectedPortion) {
        rmi = condiment.condiment.portions[condiment.selectedPortion];
      } else {
        rmi = condiment.condiment.retailModifiedItem;
      }
    } else {
      rmi = condiment.retailModifiedItem;
    }
    price += rmi?.price || 0;
  });

  return price;
}

export function isItemCustomizable(item: ItemCustomization): boolean {
  let customizable = false;
  for (const selector of item.template.selectors) {
    if (!autoSelectorTypes.includes(selector.type)) {
      customizable = true;
      break;
    }
  }
  return customizable;
}

export function buildNonCustomizableItem(
  itemCustomization: ItemCustomization,
  aiuEvent?: ItemEvent
): CustomizedShoppingBagItem {
  const item: CustomizedItem = {
    retailItem: itemCustomization.retailItem,
    retailModifiedItem: undefined,
    condiments: [],
    price: 0,
    itemCustomizationId: itemCustomization.itemCustomizationId.toString(),
    event: aiuEvent,
  };

  itemCustomization.template.selectors.forEach((selector) => {
    switch (selector.type) {
      case "AUTO_SIZE":
        processAutoSizeSelector(selector, item, itemCustomization);
        break;
      case "AUTO_QUANTITY":
        processAutoQuantitySelector(selector, item);
        break;
      case "AUTO_CONDIMENT": {
        processAutoCondimentSelector(selector, item, itemCustomization);
        break;
      }
      default:
        // Shouldn't be processing anything other than auto selectors here...
        processInvalidSelector();
    }
  });

  item.price = calculateItemSubtotal(item);
  return {
    id: createShoppingBagItemUUID(),
    itemDetails: item,
    itemCustomizationId: itemCustomization.itemCustomizationId.toString(),
    quantity: 1,
  };
}

export function processInvalidSelector(): never {
  throw new SheetzError("Tried processing an invalid selector", {
    userReadableMessage: "Looks like we've hit a snag. Please try again.",
    primaryButton: SheetzErrorButtonType.TRY_AGAIN,
  });
}

/**
 * Generate a semi-unique number by combining the current date (millis since 1970) and a random integer.
 */
export function createShoppingBagItemUUID(): number {
  const datePlusRandomString = "" + Date.now() + Math.floor(Math.random() * 1000000);
  const trimmedString = datePlusRandomString.substring(7);
  return parseInt(trimmedString);
}

export function buildCondimentListString(item?: ShoppingBagItem | CustomizedItem): string | null {
  if (!item) {
    return null;
  }

  const bagItem: CustomizedItem | ReorderedShoppingBagItem = isCustomizedShoppingBagItem(item)
    ? item.itemDetails
    : item;

  if (!bagItem.condiments || bagItem.condiments.length === 0) {
    return null;
  }

  const condiments: string[] = [];
  const quantity = bagItem.quantity ?? 1;

  bagItem.condiments.forEach(
    (condiment: Condiment | PortionedCondiment | FavoriteOrderCondiment) => {
      let condimentText = "";
      let condimentPrice;

      if (isPortionedCondiment(condiment)) {
        condimentPrice = (condiment as PortionedCondiment).condiment.retailModifiedItem.price;
        condimentText = (condiment as PortionedCondiment).condiment.retailModifiedItem.receiptText;

        if ((condiment as PortionedCondiment).selectedPortion) {
          condimentText += " - " + (condiment as PortionedCondiment).selectedPortion;
        }
      } else {
        condimentPrice = isFavoriteOrderCondiment(condiment)
          ? condiment.price
          : condiment.retailModifiedItem.price;

        condimentText = isFavoriteOrderCondiment(condiment)
          ? condiment.receiptText
          : condiment.retailModifiedItem.receiptText;
      }

      if (condimentPrice) {
        condimentText += ` (+${(condimentPrice * quantity).toFixed(2)})`;
      }

      condiments.push(condimentText);
    }
  );

  return condiments.length ? condiments.filter((condiment) => condiment !== "*").join(", ") : "";
}
