import React from "react";

import SheetzContainerButton from "components/misc/button/SheetzContainerButton/SheetzContainerButton";

import { IconType, getIcon } from "util/Icon.util";
import { isInNativeMobileContext } from "util/MobileApp.util";
import {
  MobilePayType,
  MobilePaymentMethods,
  SelectedMobilePaymentMethod,
} from "util/Payment.util";

interface MobilePaymentButtonProps {
  mobilePaymentMethods?: MobilePaymentMethods;
  onPaymentMethodClicked?: () => void;
  payInStore: boolean;
  selectedMobilePaymentMethod?: SelectedMobilePaymentMethod;
}

const MobilePaymentTypeButton = (props: MobilePaymentButtonProps) => {
  function getMobilePaymentMethod(): JSX.Element | undefined {
    if (isInNativeMobileContext()) {
      if (
        props.mobilePaymentMethods?.googlePayAvailable ||
        props.mobilePaymentMethods?.applePayAvailable
      ) {
        const paymentIcon =
          props.selectedMobilePaymentMethod?.mobilePayType === MobilePayType.ApplePay
            ? IconType.applepay
            : IconType.googlepay;

        const paymentType =
          props.selectedMobilePaymentMethod?.mobilePayType === MobilePayType.ApplePay
            ? "Apple Pay"
            : "Google Pay";

        const statusIcon = props.selectedMobilePaymentMethod?.paymentRestriction
          ? getIcon(IconType.exclamationCircle, "selected-payment")
          : getIcon(
              props.selectedMobilePaymentMethod?.selected
                ? IconType.customizationCheck
                : IconType.customizationUnChecked,
              "selected-payment"
            );

        return (
          <>
            {statusIcon}
            {getIcon(paymentIcon, "payment-method-icon")}{" "}
            <p className="payment-type">{paymentType}</p>
          </>
        );
      }
    }

    return undefined;
  }

  return (
    <>
      {isInNativeMobileContext() && !props.payInStore && (
        <SheetzContainerButton
          className="payment-info-container mobile-payment"
          onClick={
            !props.selectedMobilePaymentMethod?.paymentRestriction
              ? props.onPaymentMethodClicked
              : undefined
          }
        >
          {getMobilePaymentMethod()}
        </SheetzContainerButton>
      )}
    </>
  );
};

export default MobilePaymentTypeButton;
