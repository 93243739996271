import React, { ReactElement } from "react";

import { Condiment, Selector } from "assets/dtos/anywhere-dto";

import CondimentGrid from "components/Order/ItemCustomization/CondimentGrid/CondimentGrid";
import CustomizationOption from "components/Order/ItemCustomization/CustomizationOption/CustomizationOption";
import SelectorHeader from "components/Order/ItemCustomization/SelectorHeader/SelectorHeader";

import {
  PortionTypes,
  PortionedCondiment,
  filterCondiment,
  isCondimentInList,
} from "util/Customization.util";

interface MultiSelectCondimentSelectorProps {
  selector: Selector;
  condiments: PortionedCondiment[];
  selectedCondiments: PortionedCondiment[];
  onOptionSelected: (selectedCondiments: PortionedCondiment[]) => void;
}

const MultiSelectCondimentSelector = (props: MultiSelectCondimentSelectorProps): ReactElement => {
  const maxNumberAllowed = props.selector.maxSelected;

  const options = props.condiments.map((portionedCondiment) => {
    const isSelected =
      !!props.selectedCondiments?.length &&
      isCondimentInList(
        props.selectedCondiments.map((selectedCondiment) => selectedCondiment.condiment),
        portionedCondiment.condiment
      );
    return (
      <CustomizationOption
        key={portionedCondiment.condiment.retailModifiedItem.retailModifiedItemId}
        portionedCondiment={portionedCondiment}
        type={props.selector.type}
        isSelected={isSelected}
        onCondimentOptionSelected={condimentOptionSelected}
      />
    );
  });

  // Check that the number of condiments selected hasn't reached the allowed limit.
  const isMaxNumberSelected =
    maxNumberAllowed !== undefined && props.selectedCondiments.length >= maxNumberAllowed;

  function condimentOptionSelected(condiment: Condiment, portion?: PortionTypes): void {
    const isCondimentAlreadySelected = !!props.selectedCondiments.find(
      (selectedCondiment) =>
        selectedCondiment.condiment.retailModifiedItem.retailModifiedItemId ===
        condiment.retailModifiedItem.retailModifiedItemId
    );

    /**
     * If the max number of condiments has been reached, the condiment isn't being removed,
     * and it's not a portion change, then do nothing.
     */
    if (isMaxNumberSelected && !isCondimentAlreadySelected && !portion) {
      return;
    }

    let allSelectedCondiments: PortionedCondiment[] = [];
    if (isCondimentAlreadySelected && !portion) {
      allSelectedCondiments = filterCondiment(props.selectedCondiments, condiment);
    } else {
      if (portion) {
        allSelectedCondiments = filterCondiment(props.selectedCondiments, condiment);
      } else {
        allSelectedCondiments = props.selectedCondiments;
      }

      allSelectedCondiments = allSelectedCondiments.concat([
        { condiment: condiment, selectedPortion: portion },
      ]);
    }
    props.onOptionSelected(allSelectedCondiments);
  }

  return (
    <div
      className={
        isMaxNumberSelected
          ? "multi-select-condiment-selector limit-reached"
          : "multi-select-condiment-selector"
      }
    >
      <SelectorHeader headerText={props.selector.text ?? ""} maxSelections={maxNumberAllowed} />
      <CondimentGrid>{options}</CondimentGrid>
    </div>
  );
};

export default MultiSelectCondimentSelector;
