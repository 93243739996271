import React, { ReactElement } from "react";

import "./ComboPagination.scss";

interface ComboPaginationProps {
  currentComboItemIndex: number;
  numberOfItemsInCombo: number;
}

const ComboPagination = (props: ComboPaginationProps): ReactElement => {
  const dotPlaceholders = Array.from(Array(props.numberOfItemsInCombo).keys());

  const dots = dotPlaceholders.map((placeholder, index) => {
    return (
      <div
        key={index}
        className={
          index <= props.currentComboItemIndex ? "pagination-dot current" : "pagination-dot"
        }
      ></div>
    );
  });

  return (
    <div className="combo-item-pagination">
      <div className="combo-item-pagination-container">
        <p>
          Item {props.currentComboItemIndex + 1}/{props.numberOfItemsInCombo}
        </p>
        <div className="pagination-dots-container">{dots}</div>
        <div className="arrow-down"></div>
      </div>
    </div>
  );
};

export default ComboPagination;
