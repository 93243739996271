import React, { Dispatch, SetStateAction } from "react";

import "./ItemQuantityDisplay.scss";

interface ItemQuantityDisplayProps {
  editable?: boolean;
  quantity: number;
  showEditQuantity?: Dispatch<SetStateAction<boolean>>;
}

const ItemQuantityDisplay = (props: ItemQuantityDisplayProps) => {
  return (
    <div className="item-quantity">
      {props.editable ? (
        <>
          <p>Quantity</p>
          <button onClick={(): void => props.showEditQuantity?.(true)}>
            <div className="quantity">{props.quantity}</div>
            <div className="quantity-selector">
              <p>+</p>
              <p>-</p>
            </div>
          </button>
        </>
      ) : (
        <>
          <p>Quantity</p>
          <button disabled>
            <div className="quantity">{props.quantity}</div>
          </button>
        </>
      )}
    </div>
  );
};

export default ItemQuantityDisplay;
