import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import Barcode from "react-barcode";
import { useLocation, useNavigate } from "react-router-dom";

import "./PrintVoucher.scss";

import { PrintVoucherResponse } from "assets/dtos/anywhere-dto";

import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import EmptyPage from "components/misc/indicators/EmptyPage/EmptyPage";

import { printVoucherData } from "util/Account.util";
import { AppContext } from "util/AppContext.util";
import { IconType } from "util/Icon.util";
import { getDateTimeFromString } from "util/Time.util";

const PrintVoucher = () => {
  const location = useLocation();
  const [voucher, setVoucher] = useState<PrintVoucherResponse>();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const appContext = useContext(AppContext);
  const showLoading = appContext.showLoading;
  const hideLoading = appContext.hideLoading;
  const openNewTab = (url: string): void => {
    const newWindow = window.open(url, "_blank");
    newWindow?.focus();
  };

  useEffect(() => {
    setLoading(true);
    showLoading();
    if (queryParams.vk && queryParams.st && queryParams.et && queryParams.s) {
      printVoucherData({
        voucherKey: queryParams.vk as string,
        startTime: queryParams.st as string,
        endTime: queryParams.et as string,
        signature: queryParams.s as string,
      })
        .then((response) => setVoucher(response.data))
        .finally(() => {
          hideLoading();
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function retryVoucherDetails(): void {
    window.location.reload();
    navigate("/account/printVoucher");
  }

  function printScreen(): void {
    window.print();
  }

  if (voucher) {
    return (
      <>
        <div className="print-voucher-homepage">
          <div className="sheetz-header">
            <div className="voucher-details">
              <div className="voucher-icon">
                <img className="offer-image" src={voucher.cardImageUrl} alt={""} />
              </div>

              <div className="card-barcode">
                <Barcode value={voucher.cardNumber} displayValue={false} />
              </div>

              <div className="card-container">
                <div className="card-title">Card Number</div>
                <div className="card-details">{voucher.cardNumber}</div>

                <div className="card-title">Card Pin</div>
                <div className="card-details">{voucher.cardPin}</div>

                <div className="card-title">
                  {"Balance ( as of " +
                    getDateTimeFromString(voucher.dateOfBalance).toFormat("L'/'d'/'yy h:mm a") +
                    ")"}
                </div>
                <div className="card-details">${voucher.balance}</div>
              </div>

              <div className="button-row">
                <SheetzButton
                  className={"print-card-button"}
                  buttonColor={ButtonColor.darkGray}
                  transparentButton
                  label="Print this Card"
                  type="button"
                  onClick={printScreen}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (!voucher && !loading) {
    return (
      <EmptyPage title="Son of a Shmiscuit!" icon={IconType.alert}>
        <div className="error-page-description">
          Unable to redeem this gift card voucher. Please try again.
        </div>
        <div className="error-page-button-container">
          <div className="button-row">
            <SheetzButton
              buttonColor={ButtonColor.darkGray}
              transparentButton
              className="retry-voucher"
              label="Retry"
              onClick={retryVoucherDetails}
            />{" "}
          </div>
          <SheetzTextButton
            className="resend-code"
            onClick={(): void => {
              openNewTab("https://orders.sheetz.com");
            }}
            label="Return to Sheetz.com"
            underline
          />
        </div>
      </EmptyPage>
    );
  } else {
    return null;
  }
};
export default PrintVoucher;
