import { DateTime } from "luxon";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./OrderConfiguration.scss";

import { DeliveryAddress, ItemEvent } from "assets/dtos/anywhere-dto";

import DeliveryAddressListItem from "components/Order/Delivery/DeliveryAddressListItem/DeliveryAddressListItem";
import DeliveryPickupToggle from "components/Order/DeliveryPickupToggle/DeliveryPickupToggle";
import { OrderSubviewProps } from "components/Order/Order";
import ListItem from "components/misc/list/ListItem/ListItem";
import StoreDisplay from "components/misc/store/StoreDisplay/StoreDisplay";

import { useMediaQuery } from "hooks";

import { desktopMediaQuery } from "util/AppContext.util";
import { getDateTimeFromString, isDateTimeToday } from "util/Time.util";

interface OrderConfigurationProps extends OrderSubviewProps {
  deliveryAddress?: DeliveryAddress;
}

const OrderConfiguration = (props: OrderConfigurationProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);
  const locationState = location.state as {
    event: ItemEvent;
    homepageBannerRedirect: string;
    redirectOnOrderFlowFinish: string;
  };

  function getPickupTimeText(): string {
    if (props.orderSession.asapOrder) {
      return `ASAP - Approx. ${props.orderSession.estimatedPickupInMinutes ?? "15"}min`;
    } else if (props.orderSession.pickupTime) {
      const pickupDateTime = getDateTimeFromString(props.orderSession.pickupTime);
      if (isDateTimeToday(pickupDateTime)) {
        return "Today at " + pickupDateTime.toLocaleString(DateTime.TIME_SIMPLE);
      }
      return pickupDateTime.toFormat("ccc L'/'d 'at' t");
    }
    return "Please select a pickup time";
  }

  function redirectTo(route: string): void {
    if (
      locationState &&
      (!!locationState.redirectOnOrderFlowFinish ||
        !!locationState.homepageBannerRedirect ||
        !!locationState.event)
    ) {
      navigate(route, {
        state: locationState,
      });
    } else {
      navigate(route);
    }
  }

  return (
    <ul className="order-config-list">
      {useDesktopView && (
        <li>
          <DeliveryPickupToggle
            desktop
            orderSession={props.orderSession}
            dispatch={props.dispatch}
          ></DeliveryPickupToggle>
        </li>
      )}

      <li className="heading-list-item">
        <ListItem hideArrow={true}>My order information</ListItem>
      </li>

      {props.deliveryAddress && (
        <li className="delivery">
          <DeliveryAddressListItem
            deliveryAddress={props.deliveryAddress}
            onDeliveryAddressSelected={(): void =>
              navigate("/order/delivery/address", { state: locationState })
            }
          />
        </li>
      )}

      {props.orderSession.store && (
        <li className="store">
          <ListItem clickHandler={(): void => redirectTo("/order/selectStore")}>
            <StoreDisplay store={props.orderSession.store} showStoreNumber />
          </ListItem>
        </li>
      )}

      {props.orderSession.store && props.orderSession.asapOrder !== undefined && (
        <li className="pickup-time">
          <ListItem clickHandler={(): void => redirectTo("/order/time")}>
            {getPickupTimeText()}
          </ListItem>
        </li>
      )}
    </ul>
  );
};

export default OrderConfiguration;
