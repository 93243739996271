import React, { Dispatch } from "react";

import "./Notifications.scss";

import { UpdateUserProfileRequest } from "assets/dtos/anywhere-dto";

import { AccountFormValues } from "components/Account/AccountSettings/AccountSettings";
import PersonalInformation from "components/Account/AccountSettings/MyInformation/PersonalInformation/PersonalInformation";

import { MobileAccountSettingsPreferences } from "util/Account.util";
import { setPageTitleForMobileHeader } from "util/MobileApp.util";

interface NotificationProps {
  accountFormValues: AccountFormValues;
  isPushNotificationsEnabled?: boolean;
  setMobileAccountSettingsPreferences: Dispatch<
    React.SetStateAction<MobileAccountSettingsPreferences>
  >;
  updateUserProfileInformation: (userProfileValues: UpdateUserProfileRequest) => void;
}

const Notifications = (props: NotificationProps) => {
  setPageTitleForMobileHeader("Notifications");

  return (
    <div className="notifications-container">
      <PersonalInformation
        accountFormValues={props.accountFormValues}
        emailSettings
        isPushNotificationsEnabled={props.isPushNotificationsEnabled}
        setMobileAccountSettingsPreferences={props.setMobileAccountSettingsPreferences}
        updateUserProfileInformation={props.updateUserProfileInformation}
      />
    </div>
  );
};

export default Notifications;
