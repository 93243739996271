import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";
import { Formik, FormikProps } from "formik";
import Fuse from "fuse.js";
import React, { ReactElement } from "react";
import * as Yup from "yup";

import "./Search.scss";

import {
  Combo,
  DeliveryAddress,
  MenuCategory,
  MenuCategoryRetailItem,
  MenuSearchEventRequest,
} from "assets/dtos/anywhere-dto";

import DeliveryPickupSubHeader from "components/Order/DeliveryPickupSubHeader/DeliveryPickupSubHeader";
import { OrderSubviewProps } from "components/Order/Order";
import SheetzInput from "components/misc/form/SheetzInput/SheetzInput";
import MenuItem from "components/misc/item/MenuItem/MenuItem";

import { useMediaQuery } from "hooks";

import { desktopMediaQuery } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";
import { generateUuid, menuSearched } from "util/Metrics.util";
import { MenuItemType, SearchResults } from "util/Order.util";
import { isCombo, isMenuCategoryRetailItem, sortResultsByScore } from "util/Search.util";
import { searchValidation } from "util/Validation.util";

export interface SearchProps extends OrderSubviewProps {
  categorySearch: Fuse<MenuCategory>;
  closeFunction: () => void;
  comboSearch: Fuse<Combo>;
  deliveryAddress?: DeliveryAddress;
  itemSearch: Fuse<MenuCategoryRetailItem>;
  onComboSelected: (combo: Combo) => void;
  onItemSelected: (itemCustomizationId: number) => void;
}

const Search = (props: SearchProps): ReactElement => {
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);
  const validationSchema = Yup.object({
    search: searchValidation
      .required("We can't search for 'nothing'!")
      .min(3, "Try adding a bit more."),
  });

  let submissionTimeout: ReturnType<typeof setTimeout> | undefined;

  function handleItemClick(id: number): void {
    props.onItemSelected(id);
    props.closeFunction();
  }

  function handleComboClick(combo: Combo): void {
    props.onComboSelected(combo);
    props.closeFunction();
  }

  function performSearch(searchTerm: string): Promise<SearchResults> {
    return new Promise<SearchResults>((resolve) => {
      const itemResults: Fuse.FuseResult<MenuCategoryRetailItem>[] =
        props.itemSearch.search(searchTerm);
      const comboResults: Fuse.FuseResult<Combo>[] = props.comboSearch.search(searchTerm);
      const results: SearchResults = {
        searchTerm: searchTerm,
        results: [...itemResults, ...comboResults].sort(sortResultsByScore).map((result) => {
          const item = result.item;

          if (isMenuCategoryRetailItem(item)) {
            return {
              id: "item-" + item.itemCustomizationId,
              name: item.nameWithContext ?? item.receiptText,
              description: item.descriptiveText,
              image: item.image,
              type: MenuItemType.item,
              onClick: (): void => {
                handleItemClick(item.itemCustomizationId);
              },
            };
          } else if (isCombo(item)) {
            return {
              id: "combo-" + item.menuComboId,
              name: item.name,
              description: item.description,
              image: item.image,
              type: MenuItemType.combo,
              onClick: (): void => {
                handleComboClick(item);
              },
            };
          } else {
            throw new SheetzError("Unexpected search result type", {
              userReadableMessage: "Unexpected error while searching. Please try again.",
              primaryButton: SheetzErrorButtonType.TRY_AGAIN,
            });
          }
        }),
      };

      const menuSearchEventRequest: MenuSearchEventRequest = {
        dayPart: props.orderSession.dayPart,
        pickupLocation: props.orderSession.pickupLocation,
        resultsFound: results.results?.length,
        searchQuery: results.searchTerm,
        sessionId: generateUuid(),
      };

      menuSearched(menuSearchEventRequest);

      resolve(results);
    });
  }

  const resultListItems = props.orderSession.search?.results?.map((item) => (
    <li key={item.id}>
      <MenuItem
        name={item.name}
        subtext={item.description}
        link={item.link}
        image={item.image}
        onClick={item.onClick}
        type={item.type}
      />
    </li>
  ));

  const NoResults = (): ReactElement => {
    return (
      <div className="search-results-container no-results">
        <div>{getIcon(IconType.search, "no-results-icon")}</div>
        <p className="no-results-text">
          No results for &quot;{props.orderSession.search?.searchTerm}&quot;
        </p>
        <p>Please search again</p>
      </div>
    );
  };

  const Results = (): ReactElement => {
    return resultListItems?.length ? (
      <>
        <div className="search-results-container">
          <div>
            <p className="result-count">
              {resultListItems?.length} item
              {resultListItems?.length || resultListItems.length !== 1 ? "s" : ""}
            </p>
          </div>
          <ul className="search-results-list">{resultListItems}</ul>
        </div>
      </>
    ) : (
      <NoResults />
    );
  };

  return (
    <div className="search-container">
      {!useDesktopView && (
        <DeliveryPickupSubHeader
          deliveryAddress={props.deliveryAddress}
          dispatch={props.dispatch}
          orderSession={props.orderSession}
          showRip
        ></DeliveryPickupSubHeader>
      )}

      <div className="search-input">
        <Formik
          initialValues={{ search: props.orderSession.search?.searchTerm || "" }}
          validationSchema={validationSchema}
          onSubmit={(values: { search: string }): void => {
            performSearch(values.search).then((response) => {
              props.dispatch({
                type: "SEARCH",
                payload: response,
              });
            });
          }}
        >
          {(props: FormikProps<{ search: string }>): ReactElement => (
            <form onSubmit={props.handleSubmit}>
              <SheetzInput
                type="text"
                name="search"
                placeholder="Search our menu"
                label="Search our menu"
                autoComplete="off"
                isSearchInput={true}
                /* eslint-disable-next-line jsx-a11y/no-autofocus */
                autoFocus
                onChange={(e): void => {
                  if (submissionTimeout !== undefined) {
                    clearTimeout(submissionTimeout);
                  }
                  props.setFieldValue("search", e.currentTarget.value);
                  if (e.currentTarget.value.length > 2) {
                    submissionTimeout = setTimeout(props.handleSubmit, 250);
                  }
                }}
              />
            </form>
          )}
        </Formik>
      </div>
      {props.orderSession.search && <Results />}
    </div>
  );
};

export default Search;
