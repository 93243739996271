import classNames from "classnames";
import React, { useContext } from "react";
import { NumericFormat } from "react-number-format";

import "./OrderTotals.scss";

import {
  Order,
  OrderHistoryItem,
  OrderLoyaltyDiscount,
  OrderSpecialDiscount,
  PurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderLoyaltyDiscount,
  PurchaseOrderSpecialDiscount,
} from "assets/dtos/anywhere-dto";

import SheetzContainerButton from "components/misc/button/SheetzContainerButton/SheetzContainerButton";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import DiscountDisplay from "components/misc/item/DiscountDisplay/DiscountDisplay";

import { AppContext } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";

interface OrderTotalsProps {
  purchaseOrder?: PurchaseOrder | Order;
  addMargin?: boolean;
  totalSavingBar?: boolean;
  totalSavingBarHeader?: boolean;
}

const integratedDeliveryServiceFeeRMI = 1073917;

const OrderTotals = (props: OrderTotalsProps) => {
  const appContext = useContext(AppContext);

  if (props.purchaseOrder === undefined) {
    return null;
  }

  const containerDepositFees = props.purchaseOrder.containerDepositFees ?? 0;
  const shouldDisplaySavings =
    props.purchaseOrder.discountTotal !== 0 || props.purchaseOrder.virtualDiscountTotal !== 0;
  const items: (PurchaseOrderItem | OrderHistoryItem)[] = props.purchaseOrder.items;
  const isPurchaseOrderItem = (
    value: PurchaseOrderItem | OrderHistoryItem
  ): value is PurchaseOrderItem => "virtual" in value;
  const integratedDelivery = props.purchaseOrder.integratedDelivery;
  const integratedDeliveryServiceFee = items
    .filter(isPurchaseOrderItem)
    .find(
      (item) => item.virtual && item.retailModifiedItemId === integratedDeliveryServiceFeeRMI
    )?.price;

  let deliveryFee = integratedDelivery?.deliveryFee ?? 0;
  let itemTotal = props.purchaseOrder.itemTotal ?? 0;
  let virtualDiscountTotal = props.purchaseOrder.virtualDiscountTotal ?? 0;

  if (containerDepositFees > 0) {
    itemTotal -= containerDepositFees;
  }

  if (
    integratedDelivery &&
    integratedDelivery.deliveryFee &&
    integratedDelivery.deliveryFeeDiscount
  ) {
    // Display 0.00 if there is a delivery fee discount
    deliveryFee += integratedDelivery.deliveryFeeDiscount;

    // Delivery fee discount comes through in the virtualDiscountTotal, so we remove it from that total
    virtualDiscountTotal -= integratedDelivery.deliveryFeeDiscount;
  }

  function renderDiscountList() {
    const loyaltyDiscounts = props.purchaseOrder?.loyaltyDiscounts as
      | PurchaseOrderLoyaltyDiscount[]
      | OrderLoyaltyDiscount[];
    const specialDiscounts = props.purchaseOrder?.specialDiscounts as
      | PurchaseOrderSpecialDiscount[]
      | OrderSpecialDiscount[];

    if (
      (loyaltyDiscounts && loyaltyDiscounts.length > 0) ||
      (specialDiscounts && specialDiscounts.length > 0)
    ) {
      const loyaltyDiscountRows: JSX.Element[] = [];
      const specialDiscountRows: JSX.Element[] = [];

      if (loyaltyDiscounts?.length) {
        loyaltyDiscounts.forEach((loyaltyDiscount, index) => {
          const name =
            "receiptText" in loyaltyDiscount
              ? loyaltyDiscount.receiptText
              : loyaltyDiscount.offerName;

          loyaltyDiscountRows.push(
            <DiscountDisplay
              discount={loyaltyDiscount.amount}
              name={name}
              showDiscountPrice
              key={index}
            />
          );
        });
      }

      if (specialDiscounts?.length) {
        specialDiscounts.forEach((specialDiscount, index) =>
          specialDiscountRows.push(
            <DiscountDisplay
              discount={specialDiscount.amount}
              name={specialDiscount.receiptText}
              showDiscountPrice
              key={index}
            />
          )
        );
      }

      return (
        <div className="discount-list">
          {loyaltyDiscountRows}
          {specialDiscountRows}
        </div>
      );
    }

    return null;
  }

  if ((props.totalSavingBar || props.totalSavingBarHeader) && shouldDisplaySavings) {
    const totalSavingBarClasses = classNames("total-savings-bar", {
      "bottom-rip sheetz-red": props.totalSavingBarHeader,
      standalone: props.totalSavingBar,
    });

    return (
      <div className={totalSavingBarClasses}>
        <ResponsiveLayoutContainer>
          <div className="total-savings-bar-content">
            <p className="label">My Total Savings</p>
            <p className="amount">
              <NumericFormat
                value={Math.abs((props.purchaseOrder.discountTotal ?? 0) + virtualDiscountTotal)}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
              />
            </p>
          </div>
        </ResponsiveLayoutContainer>
      </div>
    );
  }

  if (!props.totalSavingBar && !props.totalSavingBarHeader) {
    return (
      <div
        className={
          props.addMargin
            ? "order-totals-container order-total-container-margin"
            : "order-totals-container"
        }
      >
        <p className="order-summary">Order Summary</p>

        <div className="price-list-container">
          <span className="label">Item Total</span>
          <p className="amount">
            {itemTotal || itemTotal === 0 ? (
              <NumericFormat
                value={itemTotal}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
              />
            ) : (
              "0.00"
            )}
          </p>
        </div>

        {renderDiscountList()}

        {shouldDisplaySavings && (
          <div className="price-list-container">
            <span className="label">Discount Total</span>
            <p className="amount">
              <NumericFormat
                value={(props.purchaseOrder.discountTotal ?? 0) + virtualDiscountTotal}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
              />
            </p>
          </div>
        )}

        {containerDepositFees > 0 && (
          <div className="price-list-container">
            <span className="label">Bottle Deposit</span>
            <p className="amount">
              <NumericFormat
                value={containerDepositFees}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
              />
            </p>
          </div>
        )}

        {integratedDelivery?.deliveryFee !== undefined && integratedDelivery.deliveryFee > 0 && (
          <div className="price-list-container">
            <span className="label">Delivery Fee</span>
            <p className="amount">
              <NumericFormat
                value={deliveryFee}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
              />
            </p>
          </div>
        )}

        {integratedDelivery?.longDistanceDeliveryFee !== undefined &&
          integratedDelivery.longDistanceDeliveryFee > 0 && (
            <div className="price-list-container">
              <div className="label-container">
                <SheetzContainerButton
                  className="help-icon-button"
                  onClick={() =>
                    appContext.showAlertActionSheet(
                      "The additional mileage fee is charged for each mile exceeding 4 miles from the store to the delivery address.",
                      undefined,
                      "What the Sheetz?"
                    )
                  }
                >
                  {getIcon(IconType.help, "help-icon")}{" "}
                  <span className="label">Extended Delivery Fee</span>
                </SheetzContainerButton>
              </div>
              <p className="amount">
                <NumericFormat
                  value={integratedDelivery.longDistanceDeliveryFee}
                  displayType="text"
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                />
              </p>
            </div>
          )}

        {integratedDelivery !== undefined &&
          integratedDeliveryServiceFee !== undefined &&
          integratedDeliveryServiceFee > 0 && (
            <div className="price-list-container">
              <div className="label-container">
                <SheetzContainerButton
                  className="help-icon-button"
                  onClick={() =>
                    appContext.showAlertActionSheet(
                      "The service fee helps us offset our delivery costs.",
                      undefined,
                      "What the Sheetz?"
                    )
                  }
                >
                  {getIcon(IconType.help, "help-icon")} <span className="label">Service Fee</span>
                </SheetzContainerButton>
              </div>
              <p className="amount">
                <NumericFormat
                  value={integratedDeliveryServiceFee}
                  displayType="text"
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                />
              </p>
            </div>
          )}

        {integratedDelivery !== undefined && (
          <div className="price-list-container">
            <span className="label">Tip</span>
            <p className="amount">
              <NumericFormat
                value={integratedDelivery?.deliveryTip || 0}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
              />
            </p>
          </div>
        )}

        <div className="price-list-container tax-total">
          <span className="label">Tax</span>
          <p className="amount">
            <NumericFormat
              value={props.purchaseOrder.taxTotal || 0}
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
            />
          </p>
        </div>

        <div className="price-list-container order-total">
          <span className="label">Total</span>
          <p className="amount">
            <NumericFormat
              value={props.purchaseOrder.total || 0}
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
            />
          </p>
        </div>
      </div>
    );
  }

  return null;
};

export default OrderTotals;
