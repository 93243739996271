import React, { useState } from "react";
import { PatternFormat } from "react-number-format";

import "./CurbsideInfo.scss";

import { GetUserProfileResponse, UserVehicleResponse } from "assets/dtos/anywhere-dto";

import AddVehicle from "components/Account/AccountSettings/Preferences/MyOrderingPreferences/MyVehicles/AddVehicle/AddVehicle";
import MyVehicles from "components/Account/AccountSettings/Preferences/MyOrderingPreferences/MyVehicles/MyVehicles";
import VehicleIcon from "components/Account/AccountSettings/Preferences/MyOrderingPreferences/MyVehicles/VehicleIcon/VehicleIcon";
import ChangePhoneNumber from "components/Order/Curbside/ChangePhoneNumber/ChangePhoneNumber";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ListItem from "components/misc/list/ListItem/ListItem";
import SheetzModal from "components/misc/view/SheetzModal/SheetzModal";

import { UserVehicle } from "util/MyVehicles.util";

interface CurbsideInfoProps {
  currentUserVehicle?: UserVehicle;
  userProfile: GetUserProfileResponse;
  onSavePressed: (userVehicle?: UserVehicleResponse, phoneNumber?: string) => void;
}

const CurbsideInfo = (props: CurbsideInfoProps) => {
  const [showVehicleModal, setShowVehicleModal] = useState<boolean>(false);
  const [showAddVehicleModal, setShowAddVehicleModal] = useState<boolean>(false);
  const [showChangePhoneNumberModal, setShowChangePhoneNumberModal] = useState<boolean>(false);
  const [currentVehicle, setCurrentVehicle] = useState<UserVehicleResponse | undefined>(
    props.currentUserVehicle
  );
  const currentVehicleDescription = currentVehicle ? (
    currentVehicle.color + " " + currentVehicle.type
  ) : (
    <span className="select-curbside-vehicle">Select Curbside vehicle</span>
  );
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState<string | undefined>(
    props.userProfile.phoneNumber
  );
  const phoneNumber =
    currentPhoneNumber !== undefined ? (
      <PatternFormat
        value={currentPhoneNumber}
        displayType="text"
        format="(###) ###-####"
        valueIsNumericString
      />
    ) : (
      <span className="add-phone-number">Add a phone number</span>
    );

  const curbsideInfoNeeded = !currentVehicle || !currentPhoneNumber;

  function vehicleInfoPressed(): void {
    if (!currentVehicle) {
      setShowAddVehicleModal(true);
    } else {
      setShowVehicleModal(true);
    }
  }

  function phoneNumberPressed(): void {
    setShowChangePhoneNumberModal(true);
  }

  function onVehicleSelected(userVehicle: UserVehicleResponse): void {
    setShowVehicleModal(false);
    setCurrentVehicle(userVehicle);
  }

  function onAddVehicleSelected(): void {
    setShowVehicleModal(false);
    setShowAddVehicleModal(true);
  }

  function onVehicleAdded(): void {
    setShowAddVehicleModal(false);
    setShowVehicleModal(true);
  }

  function addVehicleRequestClose(): void {
    onVehicleAdded();
  }

  function onPhoneNumberChanged(newPhoneNumber: string): void {
    setShowChangePhoneNumberModal(false);
    setCurrentPhoneNumber(newPhoneNumber);
  }

  function changePhoneNumberRequestClose(): void {
    setShowChangePhoneNumberModal(false);
  }

  return (
    <>
      {curbsideInfoNeeded && (
        <p className="curbside-info-instructions">
          Please add the following missing info before continuing.
        </p>
      )}
      <ul className="my-curbside-info">
        <li className="user-vehicle">
          <ListItem clickHandler={vehicleInfoPressed} hideArrow>
            <div className="vehicle-flex-container">
              {currentVehicle && (
                <div className="vehicle-icon-container">
                  <VehicleIcon vehicle={currentVehicle} className="curbside-vehicle-icon" />
                </div>
              )}
              <p className="vehicle-description">{currentVehicleDescription}</p>
              <p className="change-vehicle">Change Vehicle</p>
            </div>
          </ListItem>
        </li>
        <li className="phone-number">
          <ListItem clickHandler={phoneNumberPressed} hideArrow>
            <div className="phone-flex-container">
              <p className="phone-number">{phoneNumber}</p>
              <p className="change-phone-number">Change Phone Number</p>
            </div>
          </ListItem>
        </li>
      </ul>
      <SheetzButton
        label="Save"
        className="curbside-info-close-button"
        onClick={(): void => props.onSavePressed(currentVehicle, currentPhoneNumber)}
      />
      {showVehicleModal && (
        <SheetzModal
          className="change-vehicle-modal"
          isOpen={showVehicleModal}
          closeFunction={(): void => setShowVehicleModal(false)}
          contentLabel="My Vehicles"
          onRequestClose={(): void => setShowVehicleModal(false)}
          shouldCloseOnOverlayClick={false}
          headerText="My Vehicles"
        >
          <MyVehicles
            hideDelete
            onVehicleSelected={onVehicleSelected}
            onAddVehicleSelected={onAddVehicleSelected}
          />
        </SheetzModal>
      )}
      {showAddVehicleModal && (
        <SheetzModal
          className="add-vehicle-modal"
          isOpen={showAddVehicleModal}
          closeFunction={addVehicleRequestClose}
          contentLabel="Add a Vehicle"
          onRequestClose={addVehicleRequestClose}
          shouldCloseOnOverlayClick={false}
          headerText="Add a Vehicle"
        >
          <>
            <AddVehicle onVehicleAdded={onVehicleAdded} />
            <div className="padding-for-mobile"></div>
          </>
        </SheetzModal>
      )}
      {showChangePhoneNumberModal && (
        <SheetzModal
          className="change-phone-number-modal"
          isOpen={showChangePhoneNumberModal}
          closeFunction={changePhoneNumberRequestClose}
          contentLabel="Change my phone number"
          onRequestClose={changePhoneNumberRequestClose}
          shouldCloseOnOverlayClick={false}
          headerText="Change my phone number"
        >
          <ChangePhoneNumber
            userProfile={props.userProfile}
            onPhoneNumberChanged={onPhoneNumberChanged}
          />
        </SheetzModal>
      )}
    </>
  );
};

export default CurbsideInfo;
