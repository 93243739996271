import React, { ReactElement } from "react";

import "./OrderConfirmationDetails.scss";

import { DeliveryAddress, PurchaseOrder } from "assets/dtos/anywhere-dto";

import OrderDeliveryAddress from "components/Order/OrderConfirmation/OrderDeliveryAddress/OrderDeliveryAddress";
import OrderDetailListItem from "components/Order/OrderConfirmation/OrderDetailsListItem/OrderDetailListItem";
import PoweredByDeliveryProvider from "components/Order/PoweredByDeliveryProvider/PoweredByDeliveryProvider";
import StoreDisplay from "components/misc/store/StoreDisplay/StoreDisplay";

import { IconType } from "util/Icon.util";
import { OrderSession, determinePickupTime, pickupLocationsDisplayText } from "util/Order.util";

interface OrderConfirmationDetailsProps {
  children?: Array<React.ReactNode>;
  deliveryAddress?: DeliveryAddress;
  displayOnly?: boolean;
  onShowPickupInfo?: () => void;
  orderSession: OrderSession;
  purchaseOrder?: PurchaseOrder;
}

const OrderConfirmationDetails = (props: OrderConfirmationDetailsProps): ReactElement | null => {
  if (props.purchaseOrder === undefined) {
    return null;
  }

  const isDelivery = props.purchaseOrder.integratedDelivery !== undefined;

  return (
    <div className="order-confirmation-details">
      <div className="order-confirmation-detail-list">
        {!isDelivery && (
          <OrderDetailListItem
            action={(): void => props.onShowPickupInfo?.()}
            actionTitle="Update"
            noFlex
            showAction={!props.displayOnly}
          >
            {props.orderSession.store && (
              <StoreDisplay store={props.orderSession.store} showStoreNumber />
            )}
          </OrderDetailListItem>
        )}

        {isDelivery && (
          <>
            <OrderDetailListItem
              action={(): void => props.onShowPickupInfo?.()}
              actionTitle="Update"
              icon={IconType.delivery}
              iconClass={"delivery-icon"}
              showAction={!props.displayOnly}
            >
              <div className="delivery-details">
                <h1>Delivery</h1>
                {props.purchaseOrder.integratedDelivery?.integratedDeliveryPartner && (
                  <PoweredByDeliveryProvider
                    provider={props.purchaseOrder.integratedDelivery?.integratedDeliveryPartner}
                  />
                )}
                <p>{`Approx. ${props.purchaseOrder.integratedDelivery?.estimatedDeliveryInMinutes}min`}</p>
              </div>
            </OrderDetailListItem>

            <OrderDetailListItem
              action={(): void => props.onShowPickupInfo?.()}
              actionTitle="Update"
              icon={IconType.mapPin}
              iconClass={"map-pin-icon"}
              showAction={!props.displayOnly}
            >
              <OrderDeliveryAddress deliveryAddress={props.deliveryAddress} />
            </OrderDetailListItem>
          </>
        )}

        {!isDelivery && (
          <OrderDetailListItem
            action={(): void => props.onShowPickupInfo?.()}
            actionTitle="Update"
            icon={
              props.orderSession.pickupLocation === "CURBSIDE" ? IconType.curbside : IconType.store
            }
            iconClass={
              props.orderSession.pickupLocation === "CURBSIDE" ? "curbside-icon" : "store-icon"
            }
            showAction={!props.displayOnly}
          >
            <>
              <p className="pickup-location">
                {pickupLocationsDisplayText[props.purchaseOrder.pickupLocation]}
              </p>
              <p className="pickup-time">{determinePickupTime(props.orderSession)}</p>
            </>
          </OrderDetailListItem>
        )}

        {props.children}
      </div>
    </div>
  );
};

export default OrderConfirmationDetails;
