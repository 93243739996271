import React from "react";
import { slide as Menu } from "react-burger-menu";
import { useNavigate } from "react-router-dom";

import "./NavigationSidebar.scss";

import { ReactComponent as SheetzLogo } from "assets/icons/logo_sheetz.svg";

import NavMenuItems from "components/layout/NavigationSidebar/NavigationSidebarItems";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";

import { IconType, getIcon } from "util/Icon.util";

interface NavigationSidebarProps {
  menuOpen: boolean;
  handleMenuOpenStateChange: (menuOpen: boolean) => void;
}

const NavigationSidebar = (props: NavigationSidebarProps) => {
  const navigate = useNavigate();

  const closeNavMenu = (): void => {
    props.handleMenuOpenStateChange(false);
  };

  const openUrlInNewTab = (url: string): void => {
    closeNavMenu();
    const newWindow = window.open(url, "_blank");
    newWindow?.focus();
  };

  return (
    <Menu
      isOpen={props.menuOpen}
      onStateChange={(state): void => props.handleMenuOpenStateChange(state.isOpen)}
      customBurgerIcon={false}
      customCrossIcon={false}
      disableAutoFocus
      className="nav-menu"
      htmlClassName="menu-open"
    >
      <header>
        <div className="header-icons-container">
          <button
            aria-label="Sheetz Facebook Page"
            onClick={(): void => openUrlInNewTab("https://www.facebook.com/sheetz")}
          >
            {getIcon(IconType.fb, "fb-icon")}
          </button>
          <button
            aria-label="Sheetz Instagram Page"
            onClick={(): void => openUrlInNewTab("https://www.instagram.com/sheetz")}
          >
            {getIcon(IconType.insta, "insta-icon")}
          </button>
          <button
            aria-label="Sheetz Twitter"
            onClick={(): void => openUrlInNewTab("https://x.com/sheetz")}
          >
            {getIcon(IconType.twitter, "twitter-icon")}
          </button>
          <button
            aria-label="Sheetz Twitch"
            onClick={(): void => openUrlInNewTab("https://twitch.tv/SheetzLive")}
          >
            {getIcon(IconType.twitch, "twitch-icon")}
          </button>
          <SheetzLogo className={"sheetz-logo"} />
        </div>
      </header>

      <SheetzButton
        className="order-button"
        label="Order"
        onClick={(): void => {
          // There's no way to hook into the animation lifecycle of the menu, so we use manual timing.
          closeNavMenu();
          window.setTimeout((): void => navigate("/order"), 500);
        }}
      />

      <ul className="nav-menu-items">
        <NavMenuItems closeFunction={closeNavMenu} />
      </ul>
      <p className="copyright">&copy; Sheetz Inc. 1952 and beyond. All rights reserved</p>
      <p className="version">
        Online Ordering v{process.env.REACT_APP_VERSION}-
        {process.env.REACT_APP_BUILD_NUMBER ?? "local"}
      </p>
    </Menu>
  );
};

export default NavigationSidebar;
