import classNames from "classnames";
import React, { ReactElement } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import "./Account.scss";

import AccountSettings from "components/Account/AccountSettings/AccountSettings";
import IdentityQuestions from "components/Account/AgeVerification/IdentityQuestions";
import VerifyAge from "components/Account/AgeVerification/VerifyAge";
import DeferredFavorite from "components/Account/DeferredFavorite/DeferredFavorite";
import EmailValidation from "components/Account/EmailValidation/EmailValidation";
import MyReceipts from "components/Account/MyReceipts/MyReceipts";
import Receipt from "components/Account/MyReceipts/Receipt/Receipt";
import MySheetzCards from "components/Account/MySheetzCards/MySheetzCards";
import AddFunds from "components/Account/MyWallet/AddFunds/AddFunds";
import AddPaymentMethod from "components/Account/MyWallet/AddPaymentMethod/AddPaymentMethod";
import BuyGiftCard from "components/Account/MyWallet/AddPaymentMethod/BuyGiftCard/BuyGiftCard";
import AutoReload from "components/Account/MyWallet/AutoReload/AutoReload";
import MyWallet from "components/Account/MyWallet/MyWallet";
import AgeRestrictedOffers from "components/Account/Offerz/AgeRestrictedOffers";
import MyOfferz from "components/Account/Offerz/MyOfferz";
import Rewardz from "components/Account/Rewardz/Rewardz";
import PrintVoucher from "components/Account/Vouchers/PrintVoucher";
import Header, { HeaderBackgroundColor, HeaderType } from "components/layout/Header/Header";

import { isInNativeMobileContext, setPageTitleForMobileHeader } from "util/MobileApp.util";
import { getFirstName } from "util/Storage.util";

const Account = (): ReactElement => {
  const location = useLocation();
  const firstName = getFirstName();

  const accountClasses = classNames("account-main-content", {
    "in-mobile-app": isInNativeMobileContext(),
    "account-settings": location.pathname.includes("/account/settings"),
  });

  const accountSubRouteTitleMap: { [key: string]: string } = {
    "/account/settings": "Account settings",
    "/account/settings/personalInformation": "Personal info",
    "/account/settings/employeeInformation": "Sheetz employee info",
    "/account/settings/driverType": "Driver Type",
    "/account/settings/security": "Account settings",
    "/account/settings/security/changePassword": "Change password",
    "/account/settings/preferences": "Account settings",
    "/account/settings/preferences/notifications": "Notifications",
    "/account/settings/preferences/twentyOnePlus": "21+ Offers",
    "/account/settings/preferences/myOrderingPreferences": "My ordering preferences",
    "/account/settings/preferences/myOrderingPreferences/myStores": "My stores",
    "/account/settings/preferences/myOrderingPreferences/addVehicle": "Add vehicles",
    "/account/settings/preferences/myOrderingPreferences/myVehicles": "My vehicles",
    "/account/settings/preferences/myOrderingPreferences/addAddress": "Add addresses",
    "/account/settings/preferences/myOrderingPreferences/myAddresses": "My addresses",
    "account/activate": "Email Validation",
    "/account/favorites": "Favorites",
    "/account/orders": "Orders",
    "/account/mySheetzCards": "My loyalty card",
    "/account/myWallet": firstName !== null ? firstName + "'s wallet" : "My wallet",
    "/account/addPaymentMethod": "Add payment method",
    "/account/buyGiftCard": "Buy a gift card",
    "/account/autoReload": "Auto reload",
    "/account/addFunds": "Add funds",
    "/account/rewardz": "My Rewardz",
    "/account/myOfferz": "My Offerz",
    "/account/ageRestrictedOffers": "Age Restricted Offers",
    "/account/printVoucher": "My Gift Card",
    "/account/myReceipts": "My receipts",
    "/account/receipt": "Receipt",
    "/account/deferredFavorite": "My favorites",
    "/account/verifyAge": "Verify my age",
    "/account/identityQuestions": "Identity Questions",
    "/account/verifyIdentity": "Verify Identity",
  };

  const accountSubRouteHeaderMap: { [key: string]: HeaderType } = {
    "/account/settings": HeaderType.main,
    "/account/settings/driverType": HeaderType.main,
    "/account/settings/personalInformation": HeaderType.main,
    "/account/settings/employeeInformation": HeaderType.main,
    "/account/settings/security": HeaderType.main,
    "/account/settings/security/changePassword": HeaderType.main,
    "/account/settings/preferences": HeaderType.main,
    "/account/settings/preferences/notifications": HeaderType.main,
    "/account/settings/preferences/twentyOnePlus": HeaderType.main,
    "/account/settings/preferences/myOrderingPreferences": HeaderType.main,
    "/account/settings/preferences/myOrderingPreferences/myStores": HeaderType.main,
    "/account/settings/preferences/myOrderingPreferences/addVehicle": HeaderType.main,
    "/account/settings/preferences/myOrderingPreferences/myVehicles": HeaderType.main,
    "/account/settings/preferences/myOrderingPreferences/addAddress": HeaderType.main,
    "/account/settings/preferences/myOrderingPreferences/myAddresses": HeaderType.main,
    "/account/activate": HeaderType.main,
    "/account/favorites": HeaderType.modal,
    "/account/orders": HeaderType.modal,
    "/account/mySheetzCards": HeaderType.main,
    "/account/myWallet": HeaderType.main,
    "/account/addPaymentMethod": HeaderType.main,
    "/account/buyGiftCard": HeaderType.main,
    "/account/autoReload": HeaderType.main,
    "/account/addFunds": HeaderType.main,
    "/account/rewardz": HeaderType.main,
    "/account/myOfferz": HeaderType.main,
    "/account/ageRestrictedOffers": HeaderType.main,
    "/account/myReceipts": HeaderType.main,
    "/account/receipt": HeaderType.main,
    "/account/deferredFavorite": HeaderType.deepLink,
    "/account/verifyAge": HeaderType.main,
    "/account/printVoucher": HeaderType.main,
    "/account/identityQuestions": HeaderType.main,
    "/account/verifyIdentity": HeaderType.main,
  };

  const accountSubRouteBackgroundColorMap: { [key: string]: HeaderBackgroundColor } = {
    "/account/myWallet": "green",
    "/account/addPaymentMethod": "green",
    "/account/autoReload": "green",
    "/account/addFunds": "green",
    "/account/buyGiftCard": "green",
  };

  setPageTitleForMobileHeader(accountSubRouteTitleMap[location.pathname]);

  return (
    <>
      <Header
        type={accountSubRouteHeaderMap[location.pathname]}
        title={accountSubRouteTitleMap[location.pathname]}
        backgroundColor={accountSubRouteBackgroundColorMap[location.pathname]}
        fixed
      />
      <div className={accountClasses}>
        {/* Parent route is `/account` */}
        <Routes>
          <Route path="activate" element={<EmailValidation />} />
          <Route path="addFunds" element={<AddFunds />} />
          <Route path="addPaymentMethod" element={<AddPaymentMethod />} />
          <Route path="ageRestrictedOffers" element={<AgeRestrictedOffers />} />
          <Route path="autoReload" element={<AutoReload />} />
          <Route path="buyGiftCard" element={<BuyGiftCard />} />
          <Route path="deferredFavorite" element={<DeferredFavorite />} />
          <Route path="identityQuestions" element={<IdentityQuestions />} />
          <Route path="myOfferz" element={<MyOfferz />} />
          <Route path="myReceipts" element={<MyReceipts />} />
          <Route path="mySheetzCards" element={<MySheetzCards />} />
          <Route path="myWallet" element={<MyWallet />} />
          <Route path="receipt" element={<Receipt />} />
          <Route path="rewardz" element={<Rewardz />} />
          <Route path="verifyAge" element={<VerifyAge />} />
          <Route path="printVoucher" element={<PrintVoucher />} />
          <Route path="settings/*" element={<AccountSettings />} />
          <Route path="/*" element={<Navigate to="/account/settings" replace />} />
        </Routes>
      </div>
    </>
  );
};

export default Account;
