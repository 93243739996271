import { AxiosResponse } from "axios";
import React, { useContext, useEffect, useState } from "react";

import "./MySheetzCards.scss";

import { LoyaltyCard, LoyaltyTierStatus, UpdateLoyaltyCardRequest } from "assets/dtos/anywhere-dto";
import { ReactComponent as BottomSwoop } from "assets/swoops/action_swoop_bottom.svg";

import AddMySheetzCard from "components/Account/MySheetzCards/AddMySheetzCard/AddMySheetzCard";
import EditMySheetzCard from "components/Account/MySheetzCards/EditMySheetzCard/EditMySheetzCard";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import LoadingPlaceholder from "components/misc/indicators/LoadingPlaceholder/LoadingPlaceholder";
import ProgressBar from "components/misc/indicators/ProgressBar/ProgressBar";
import SheetzModal from "components/misc/view/SheetzModal/SheetzModal";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { getUserSummary } from "util/Account.util";
import { AppContext } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";
import {
  addLoyaltyCard,
  deleteLoyaltyCard,
  getLoyaltyCards,
  getLoyaltyThresholdPoints,
  getLoyaltyTierName,
  getNextLoyaltyTierName,
  getPointsNeededToProgress,
  getProgressToNextTier,
  isAtBaseLoyaltyTier,
  isAtTopLoyaltyTier,
  orderedLoyaltyTierBadges,
  updateLoyaltyCard,
} from "util/Loyalty.util";

export interface AddCardFormValues {
  cardName: string;
  cardNumber: string;
  pin: string;
}

const MySheetzCards = () => {
  const appContext = useContext(AppContext);
  // Break out these methods from appContext to avoid useEffect() lint warning regarding dependencies.
  // Having appContext within the dependencies array is bad - it will cause useEffect() to fire on all renders.
  const showLoading = appContext.showLoading;
  const hideLoading = appContext.hideLoading;
  const [loyaltyCards, setLoyaltyCards] = useState<LoyaltyCard[]>();
  const [retry, setRetry] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [editCard, setEditCard] = useState<boolean>(false);
  const [currentCard, setCurrentCard] = useState<LoyaltyCard>();
  const [addCard, setAddCard] = useState<boolean>(false);
  const [loyaltyTierStatus, setLoyaltyTierStatus] = useState<LoyaltyTierStatus | null>(null);
  const initialAddCardFormValues: AddCardFormValues = {
    cardName: "",
    cardNumber: "",
    pin: "",
  };
  const [addCardFormValues, setAddCardFormValues] =
    useState<AddCardFormValues>(initialAddCardFormValues);

  useEffect(() => {
    setLoading(true);
    getLoyaltyCards()
      .then((response) => {
        setLoyaltyCards(response.data.loyaltyCards);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [retry, hideLoading, showLoading]);

  useEffect(() => {
    getUserSummary().then((response: AxiosResponse) => {
      setLoyaltyTierStatus(response.data.loyaltyTierStatus);
    });
  }, []);

  const openEditCard = (card: LoyaltyCard): void => {
    setCurrentCard(card);
    setEditCard(true);
  };

  const saveCard = (card: UpdateLoyaltyCardRequest): void => {
    updateLoyaltyCard(card.cardId, card).then(() => {
      appContext.showToast("Card successfully updated!", "", ToastType.success);
      closeEditCard();
      retryLoyaltyCardFetch();
    });
  };

  const addNewCard = (values: AddCardFormValues): void => {
    addLoyaltyCard(values.cardNumber, values.pin, values.cardName).then(() => {
      appContext.showToast("Card successfully added!", "", ToastType.success);
      closeAddCard();
      retryLoyaltyCardFetch();
    });
  };

  const deleteCard = (cardId: number): void => {
    deleteLoyaltyCard(cardId).then(() => {
      appContext.showToast("Card deleted", "", ToastType.success);
      closeEditCard();
      retryLoyaltyCardFetch();
    });
  };

  const closeEditCard = (): void => {
    setEditCard(false);
    setCurrentCard(undefined);
  };

  const openAddCard = (): void => {
    setAddCard(true);
  };

  const closeAddCard = (): void => {
    setAddCard(false);
    setAddCardFormValues(initialAddCardFormValues);
  };

  function maskCardNumber(cardNumber: string): string {
    return "*" + cardNumber.substr(12);
  }

  // We're just making the state change in order to reload the data.
  function retryLoyaltyCardFetch(): void {
    setRetry(!retry);
  }

  const CardListItem = (card: LoyaltyCard) => {
    return (
      <li className="card-item">
        <button
          onClick={(): void => {
            openEditCard(card);
          }}
        >
          <div>
            <div className="card-icon-container">{getIcon(IconType.sheetzCard, "card-icon")}</div>
            <div className="card-label-container">
              <p className="card-name">{card.cardName}</p>
              <p className="card-number">{maskCardNumber(card.cardNumber)}</p>
            </div>
            <div className="card-attribute-container">{card.primary && "Preferred"}</div>
          </div>
        </button>
      </li>
    );
  };

  const CardListRetry = () => {
    return (
      <li className="card-item">
        <button onClick={(): void => retryLoyaltyCardFetch()}>
          <div>
            <div className="card-icon-container">{getIcon(IconType.card, "card-icon")}</div>
            <div className="card-label-container">
              <p className="card-name">Could not load cards</p>
              <p className="card-number">Tap to Retry</p>
            </div>
            <div className="card-attribute-container">
              {getIcon(IconType.refresh, "refresh-icon")}
            </div>
          </div>
        </button>
      </li>
    );
  };

  const loyaltyCardList = loading ? (
    <>
      <LoadingPlaceholder />
      <LoadingPlaceholder />
    </>
  ) : loyaltyCards ? (
    loyaltyCards.map((loyaltyCard) => {
      return (
        <CardListItem
          key={loyaltyCard.cardId}
          cardId={loyaltyCard.cardId}
          cardNumber={loyaltyCard.cardNumber}
          cardName={loyaltyCard.cardName}
          primary={loyaltyCard.primary}
        />
      );
    })
  ) : (
    <CardListRetry />
  );

  const CardEditModal = () => {
    return currentCard ? (
      <SheetzModal
        className="loyalty-card-modal"
        isOpen={editCard}
        closeFunction={closeEditCard}
        contentLabel="Edit MySheetz Card"
        onRequestClose={closeEditCard}
        shouldCloseOnOverlayClick={false}
        headerText={currentCard?.cardName}
      >
        {currentCard && (
          <EditMySheetzCard
            card={currentCard}
            submitFunction={saveCard}
            deleteFunction={deleteCard}
            cancelFunction={closeEditCard}
          />
        )}
      </SheetzModal>
    ) : (
      <></>
    );
  };

  const CardAddModal = () => {
    return (
      <SheetzModal
        className="loyalty-card-modal"
        isOpen={addCard}
        closeFunction={closeAddCard}
        contentLabel="Add Loyalty Card"
        onRequestClose={closeAddCard}
        shouldCloseOnOverlayClick={false}
        headerText={"Add Loyalty Card"}
      >
        <AddMySheetzCard
          initialValues={addCardFormValues}
          submitFunction={addNewCard}
          cancelFunction={closeAddCard}
        />
      </SheetzModal>
    );
  };

  return (
    <>
      <div className="loyalty-tier-container">
        <div>
          {getIcon(orderedLoyaltyTierBadges[loyaltyTierStatus?.currentTierIndex || 0], "tier-icon")}
        </div>
        {loyaltyTierStatus && (
          <div>
            {!isAtBaseLoyaltyTier(loyaltyTierStatus) && (
              <p className="tier-expires-label">
                {getLoyaltyTierName(loyaltyTierStatus)} until 12/31/
                {loyaltyTierStatus.reevaluationYear}
              </p>
            )}
            <p className="tier-progress-label">
              {loyaltyTierStatus.totalPoints} / {getLoyaltyThresholdPoints(loyaltyTierStatus)}{" "}
              Yearly Pointz
            </p>

            <ProgressBar maxValue={100} currentValue={getProgressToNextTier(loyaltyTierStatus)} />
            {getPointsNeededToProgress(loyaltyTierStatus) > 0 && (
              <p className="tier-maintain-label">
                You need {getPointsNeededToProgress(loyaltyTierStatus)} ptz this year to{" "}
                {isAtTopLoyaltyTier(loyaltyTierStatus) ? "maintain" : "reach"}{" "}
                {getNextLoyaltyTierName(loyaltyTierStatus)} Status
              </p>
            )}
            {getPointsNeededToProgress(loyaltyTierStatus) <= 0 && (
              <p className="tier-maintain-label">
                You need 0 ptz this year to{" "}
                {isAtTopLoyaltyTier(loyaltyTierStatus) ? "maintain" : "reach"}{" "}
                {getNextLoyaltyTierName(loyaltyTierStatus)} Status
              </p>
            )}
          </div>
        )}
      </div>
      <div className="loyalty-tier-swoop">
        <BottomSwoop className="bottom-swoop swoop" />
      </div>
      <p className="message">Tap on a card to edit</p>
      <ResponsiveLayoutContainer>
        <ul className="card-list">
          {loyaltyCardList}
          <li className="add-card-row">
            <SheetzButton
              buttonColor={ButtonColor.darkGray}
              transparentButton
              className="add-mysheetz-button"
              label="Add Loyalty Card"
              onClick={openAddCard}
            />
          </li>
        </ul>
      </ResponsiveLayoutContainer>
      {editCard && <CardEditModal />}
      {addCard && <CardAddModal />}
    </>
  );
};

export default MySheetzCards;
