import { Formik, FormikProps } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import "./OrderDeliveryInstructions.scss";

import OrderDetailListItem from "components/Order/OrderConfirmation/OrderDetailsListItem/OrderDetailListItem";
import { OrderSessionAction } from "components/Order/OrderSessionReducer";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextArea from "components/misc/form/SheetzTextArea/SheetzTextArea";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { IconType } from "util/Icon.util";

interface OrderDeliveryInstructionsProps {
  deliveryInstructions?: string;
  dispatch?: React.Dispatch<OrderSessionAction>;
  displayOnly?: boolean;
}

const OrderDeliveryInstructions = (props: OrderDeliveryInstructionsProps) => {
  const [showDeliveryInstructionsActionSheet, setShowDeliveryInstructionsActionSheet] =
    useState(false);

  function handleSubmit(values: { instructions: string }): void {
    props.dispatch?.({ type: "SET_DELIVERY_INSTRUCTIONS", payload: values.instructions });
    setShowDeliveryInstructionsActionSheet(false);
  }

  return (
    <>
      <OrderDetailListItem
        action={
          props.displayOnly ? undefined : (): void => setShowDeliveryInstructionsActionSheet(true)
        }
        actionTitle="Update"
        icon={IconType.edit}
        iconClass={"edit-icon"}
        showAction={!props.displayOnly}
      >
        <div className="delivery-instructions">
          <h1>Delivery Instructions</h1>
          {props.deliveryInstructions && (
            <p className="instructions">{props.deliveryInstructions}</p>
          )}
        </div>
      </OrderDetailListItem>

      <ActionSheet
        color={ActionSheetColor.red}
        title="Delivery Instructions"
        overlay={true}
        shouldDisplay={showDeliveryInstructionsActionSheet}
      >
        <div className="delivery-instructions-action-sheet">
          <p className="header">Add some instructions for your delivery driver</p>
          <Formik
            initialValues={{ instructions: props.deliveryInstructions ?? "" }}
            validationSchema={Yup.object({
              instructions: Yup.string().max(200),
            })}
            onSubmit={handleSubmit}
          >
            {(formProps: FormikProps<{ instructions: string }>) => (
              <form className="delivery-instructions-form" onSubmit={formProps.handleSubmit}>
                <SheetzTextArea
                  numberOfRows={10}
                  maxLength={500}
                  placeholderText="Delivery Instructions - 0/500 Characters"
                  name="instructions"
                />
                <SheetzButton
                  type="submit"
                  label="Continue"
                  className="save-delivery-instructions-button"
                />
                <SheetzButton
                  type="button"
                  label="Cancel"
                  buttonColor={ButtonColor.darkGray}
                  transparentButton
                  className="save-delivery-instructions-button"
                  onClick={(): void => setShowDeliveryInstructionsActionSheet(false)}
                />
              </form>
            )}
          </Formik>
        </div>
      </ActionSheet>
    </>
  );
};

export default OrderDeliveryInstructions;
