import classNames from "classnames";
import React from "react";

import "./ResponsiveLayoutContainer.scss";

interface ResponsiveLayoutContainerProps {
  className?: string;
}

const ResponsiveLayoutContainer = (
  props: React.PropsWithChildren<ResponsiveLayoutContainerProps>
) => {
  const responsiveLayoutContainerClasses = classNames(
    "responsive-layout-container",
    props.className
  );

  return <div className={responsiveLayoutContainerClasses}>{props.children}</div>;
};

export default ResponsiveLayoutContainer;
