import { FormikHelpers } from "formik";
import React, { ReactElement, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./CreateDeliveryAddress.scss";

import { ItemEvent } from "assets/dtos/anywhere-dto";

import DeliveryAddressForm from "components/Order/Delivery/DeliveryAddressForm/DeliveryAddressForm";
import DeliveryCurrentlyUnavailableActionSheet from "components/Order/Delivery/DeliveryCurrentlyUnavailableActionSheet/DeliveryCurrentlyUnavailableActionSheet";
import PhoneNumberConsent from "components/Order/Delivery/PhoneNumberConsent/PhoneNumberConsent";
import { OrderSubviewProps } from "components/Order/Order";
import OrderConfiguration from "components/Order/OrderConfiguration/OrderConfiguration";
import FlexContainer from "components/misc/containers/FlexContainer";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import SheetzModal from "components/misc/view/SheetzModal/SheetzModal";

import { AppContext } from "util/AppContext.util";
import {
  AddNewAddressFormValues,
  createDeliveryAddress,
  shouldShowPhoneNumberConsent,
} from "util/Order.util";

const CreateDeliveryAddress = (props: OrderSubviewProps): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as { homepageBannerRedirect: string; event: ItemEvent };
  const appContext = useContext(AppContext);
  const [showPhoneNumberConsent, setShowPhoneNumberConsent] = useState(false);
  const [showDeliveryStatusActionSheet, setShowDeliveryStatusActionSheet] = useState(false);
  const [isAddressDeliverable, setIsAddressDeliverable] = useState(false);

  const submitDeliveryAddress = (
    values: AddNewAddressFormValues,
    formikHelpers: FormikHelpers<AddNewAddressFormValues>,
    verifyAddress: boolean
  ): void => {
    appContext.showLoading();
    // TODO: Pass in verify boolean once server side is done.
    createDeliveryAddress({
      ...values,
      bypassDeliveryEstimate: false,
      verified: verifyAddress,
    })
      .then((response) => {
        const deliveryEstimate = response.data.deliveryEstimate;
        // Check to see if delivery is available for the new address
        if (!deliveryEstimate.available) {
          setIsAddressDeliverable(
            deliveryEstimate.unavailableReasonType === "DELIVERY_NOT_CURRENTLY_AVAILABLE"
          );
          setShowDeliveryStatusActionSheet(true);
          return;
        }

        props.dispatch({
          type: "SET_DELIVERY_ADDRESS_ID",
          payload: response.data.deliveryAddress.deliveryAddressId,
        });
        props.dispatch({
          type: "SET_DELIVERY_ESTIMATED_DURATION",
          payload: deliveryEstimate.estimatedNumberOfMinutesToDelivery,
        });
        props.dispatch({ type: "SET_STORE_NUMBER", payload: deliveryEstimate.storeNumber });
        props.dispatch({ type: "SET_DAYPART", payload: deliveryEstimate.dayPart });

        if (shouldShowPhoneNumberConsent(props.orderSession)) {
          setShowPhoneNumberConsent(true);
        } else {
          navigate("/order/delivery/time", {
            state: locationState,
          });
        }
      })
      .catch(() => formikHelpers.setSubmitting(false))
      .finally(() => {
        appContext.hideLoading();
      });
  };

  function closePhoneNumberConsent(): void {
    setShowPhoneNumberConsent(false);
  }

  function choosePickup(): void {
    props.dispatch({ type: "SET_DELIVERY", payload: false });
    navigate("/order", {
      state: locationState,
    });
  }

  function chooseAnotherAddress(): void {
    navigate("/order/delivery/address", {
      state: locationState,
    });
  }

  const phoneNumberConsentModal = (
    <SheetzModal
      className="phone-number-consent-modal"
      isOpen={showPhoneNumberConsent}
      closeFunction={closePhoneNumberConsent}
      contentLabel="Mobile Phone Number"
      onRequestClose={closePhoneNumberConsent}
      shouldCloseOnOverlayClick={false}
      headerText="Mobile Phone Number"
      backgroundColor="red"
    >
      <PhoneNumberConsent orderSession={props.orderSession} dispatch={props.dispatch} />
    </SheetzModal>
  );

  return (
    <FlexContainer flexStyles={{ flexDirection: "column", height: "100%" }}>
      <OrderConfiguration dispatch={props.dispatch} orderSession={props.orderSession} />

      <div className="create-delivery-addresses-container">
        <ResponsiveLayoutContainer>
          <DeliveryAddressForm submitCallback={submitDeliveryAddress} />

          <div>{showPhoneNumberConsent && phoneNumberConsentModal}</div>

          {showDeliveryStatusActionSheet && (
            <div>
              <DeliveryCurrentlyUnavailableActionSheet
                isAddressDeliverable={isAddressDeliverable}
                choosePickupCallback={choosePickup}
                chooseAnotherAddressCallback={chooseAnotherAddress}
                shouldDisplay={showDeliveryStatusActionSheet}
              />
            </div>
          )}
        </ResponsiveLayoutContainer>
      </div>
    </FlexContainer>
  );
};

export default CreateDeliveryAddress;
