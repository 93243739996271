import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./MyInformation.scss";

import { UpdateUserProfileRequest } from "assets/dtos/anywhere-dto";

import { AccountFormValues } from "components/Account/AccountSettings/AccountSettings";
import PathListItem from "components/misc/list/PathListItem/PathListItem";

import { getUserProfile, isAgeUnverified } from "util/Account.util";
import { IconType, getIcon } from "util/Icon.util";

interface MyInformationProps {
  accountFormValues: AccountFormValues;
  updateUserProfileInformation: (userProfileValues: UpdateUserProfileRequest) => void;
}

const MyInformation = (props: MyInformationProps) => {
  const navigate = useNavigate();
  const [ageVerificationStatus, setAgeVerificationStatus] = useState<string | undefined>(
    props.accountFormValues.ageVerified
  );

  useEffect(() => {
    if (ageVerificationStatus === undefined) {
      getUserProfile().then((response) => {
        setAgeVerificationStatus(response.data.ageVerified);
      });
    }
  }, [ageVerificationStatus]);

  function onClickEmployeeInformation(): void {
    navigate("/account/settings/employeeInformation");
  }

  return (
    <div className="my-information-container">
      <PathListItem
        title="Personal Info"
        clickHandler={() => navigate("/account/settings/personalInformation")}
      />
      <PathListItem
        title="Driver Type"
        clickHandler={() => navigate("/account/settings/driverType")}
      />
      <PathListItem
        title="Age Verification (21+)"
        hideArrow={ageVerificationStatus !== undefined && !isAgeUnverified(ageVerificationStatus)}
        disabled={ageVerificationStatus !== undefined && !isAgeUnverified(ageVerificationStatus)}
        rightButtonContent={
          ageVerificationStatus && !isAgeUnverified(ageVerificationStatus)
            ? getIcon(IconType.customizationCheck, "icon-customization-check")
            : undefined
        }
        hideBottomBorder={!isAgeUnverified(ageVerificationStatus)}
        clickHandler={() => navigate("/account/verifyAge?destination=/account/settings")}
      />
      <PathListItem
        title="Sheetz Employee Info"
        hideArrow={props.accountFormValues.employeeStatus}
        rightButtonContent={
          props.accountFormValues.employeeStatus
            ? getIcon(IconType.customizationCheck, "icon-customization-check")
            : undefined
        }
        showTopBorder={!isAgeUnverified(ageVerificationStatus)}
        hideBottomBorder
        clickHandler={onClickEmployeeInformation}
        rightButtonClickHandler={onClickEmployeeInformation}
      />
    </div>
  );
};

export default MyInformation;
