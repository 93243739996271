import React, { useEffect, useState } from "react";

import "./FavoriteOrder.scss";

import { OrderHistoryCombo, OrderHistoryItem, OrderingSystem } from "assets/dtos/anywhere-dto";

import { IconType, getIcon } from "util/Icon.util";
import { createFavoriteOrderRequest } from "util/Order.util";
import { addFavoriteOrder, deleteFavoriteOrder } from "util/Reorder.util";

export interface FavoriteOrderToggleProps {
  favoriteToggledCallback?: () => void;
  isFavoriteOrder: boolean;
  favoriteOrderId?: number;
  orderNumber: number;
  items: OrderHistoryItem[];
  combos: OrderHistoryCombo[] | undefined;
  orderingSystem: OrderingSystem;
}

const FavoriteOrder = (props: FavoriteOrderToggleProps) => {
  const [isFavoriteOrder, setIsFavoriteOrder] = useState<boolean>(props.isFavoriteOrder);
  const [favoriteOrderId, setFavoriteOrderId] = useState<number>();

  useEffect(() => {
    if (props.favoriteOrderId) {
      setFavoriteOrderId(props.favoriteOrderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // CURBSIDE and MPOS orders cannot be favorited
  if (props.orderingSystem === "MPOS") {
    return <></>;
  }

  function toggleFavoriteOrder(): void {
    if (isFavoriteOrder && favoriteOrderId) {
      deleteFavoriteOrder(favoriteOrderId).then(() => {
        setIsFavoriteOrder(!isFavoriteOrder);
      });
    } else {
      const request = createFavoriteOrderRequest(props.orderNumber, props.items, props.combos);
      addFavoriteOrder(request).then((response) => {
        setIsFavoriteOrder(true);
        setFavoriteOrderId(response.data.order.favoriteId);
      });
    }
  }

  return (
    <div className="favorite-my-order">
      <div
        className="save-order-button-flex-container"
        role="button"
        tabIndex={0}
        onClick={toggleFavoriteOrder}
      >
        <div className="circle">
          {getIcon(IconType.heart, isFavoriteOrder ? "heart-icon favorite" : "heart-icon")}
        </div>
        <p className="label">Favorite entire order</p>
      </div>
    </div>
  );
};

export default FavoriteOrder;
