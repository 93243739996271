import { UserVehicleResponse } from "assets/dtos/anywhere-dto";
import Axios, { AxiosPromise } from "axios";
import {
  CURBSIDE_VEHICLE,
  USER_VEHICLES_DELETE,
  USER_VEHICLES_GET
} from "endpoints/myvehicles.endpoints";

export type UserVehicle = UserVehicleResponse;

export interface NewVehicleValues {
  make: string;
  color: string;
  type: string;
}

export interface VehicleInformation {
  makes: Array<VehicleMake>;
  types: Array<VehicleType>;
  colors: Array<VehicleColor>;
}

export interface VehicleMake {
  value: string;
  label: string;
}

export interface VehicleType {
  value: string;
  label: string;
}

export interface VehicleColor {
  value: string;
  label: string;
}

export function addVehicle(request: NewVehicleValues): AxiosPromise<null> {
  return Axios({
    method: "POST",
    url: CURBSIDE_VEHICLE,
    data: request
  });
}

export function getVehicleInformation(): AxiosPromise<VehicleInformation> {
  return Axios({
    method: "GET",
    url: CURBSIDE_VEHICLE
  });
}

export function getUserVehicles(): AxiosPromise<UserVehicleResponse[]> {
  return Axios({
    method: "GET",
    url: USER_VEHICLES_GET
  });
}

export function removeUserVehicle(vehicleId: number): AxiosPromise<null> {
  return Axios({
    method: "DELETE",
    url: USER_VEHICLES_DELETE(vehicleId)
  });
}
