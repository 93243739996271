import React from "react";

import "./DeliveryPickupInformation.scss";

import { DeliveryAddress } from "assets/dtos/anywhere-dto";

import { OrderSession } from "util/Order.util";

interface StoreInformationProps {
  deliveryAddress?: DeliveryAddress;
  orderSession: OrderSession;
}

const DeliveryPickupInformation = (props: StoreInformationProps) => {
  const deliveryAddress = props.deliveryAddress;
  const isDelivery = props.orderSession.delivery;

  const address = isDelivery ? deliveryAddress?.street : props.orderSession.store?.address;
  const unit = isDelivery ? deliveryAddress?.unit : undefined;
  const city = isDelivery ? deliveryAddress?.city : props.orderSession.store?.city;
  const state = isDelivery ? deliveryAddress?.stateCode : props.orderSession.store?.state;
  const zip = isDelivery ? deliveryAddress?.zipCode : props.orderSession.store?.zip;

  return (isDelivery && deliveryAddress) || props.orderSession.store ? (
    <div className="store-information">
      {isDelivery && deliveryAddress && deliveryAddress.alias && (
        <p className="delivery-pickup-title">
          <strong>{deliveryAddress.alias}</strong>
        </p>
      )}
      {!isDelivery && props.orderSession.store?.storeNumber && (
        <>
          <p className="delivery-pickup-title">
            <strong>Store #{props.orderSession.store?.storeNumber}</strong>
          </p>
        </>
      )}
      <p className="address">{address}</p>
      {unit !== undefined && <p>{unit}</p>}
      <p>{city + " " + state + " " + zip?.substring(0, 5)}</p>
    </div>
  ) : (
    <></>
  );
};

export default DeliveryPickupInformation;
