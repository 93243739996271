import React, { ReactElement } from "react";
import "./ActionSheetGrid.scss";

const ActionSheetGrid = (props: {
  children: React.ReactNode;
  className?: string;
}): ReactElement => {
  return (
    <div className={"action-sheet-grid" + (props.className ? " " + props.className : "")}>
      {props.children}
    </div>
  );
};

export default ActionSheetGrid;
