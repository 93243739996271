import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";
import classNames from "classnames";
import Fuse from "fuse.js";
import React, { Dispatch, ReactElement, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import "./Menu.scss";

import {
  Combo,
  DeliveryAddress,
  FIBEventRequest,
  FeaturedItemBanner,
  ItemEvent,
  MenuCategory,
  MenuCategoryRetailItem,
  OrderType,
  PickupLocation,
} from "assets/dtos/anywhere-dto";
import searchIcon from "assets/icons/icon_search.svg";

import { OrderLocationState, OrderSubviewProps } from "components/Order/Order";
import { OrderItemActions } from "components/Order/OrderItemReducer";
import Search from "components/Order/Search/Search";
import SheetzContainerButton from "components/misc/button/SheetzContainerButton/SheetzContainerButton";
import MenuItem from "components/misc/item/MenuItem/MenuItem";
import SheetzModal from "components/misc/view/SheetzModal/SheetzModal";

import { useSessionStorage } from "hooks";

import { isAgeUnverified, isAgeVerified } from "util/Account.util";
import { AppContext } from "util/AppContext.util";
import { CustomizedShoppingBagItem } from "util/Bag.util";
import {
  ItemCustomization,
  buildNonCustomizableItem,
  findItemSwitchSelector,
  findSizeSelector,
  isItemCustomizable,
} from "util/Customization.util";
import { getImageSrc, getImageSrcSet } from "util/Image.util";
import {
  getMenuCategories,
  getMenuCategoriesByIds,
  getMenuCategoryById,
  getTopLevelCategoryFromMenu,
  handleInternalLinkType,
} from "util/Menu.util";
import { fibClicked, fibServed, generateUuid } from "util/Metrics.util";
import { isInNativeMobileContext } from "util/MobileApp.util";
import {
  MenuItemType,
  OrderItemState,
  getComboDetails,
  getItemCustomization,
} from "util/Order.util";
import {
  createCategorySearchInstance,
  createComboSearchInstance,
  createItemSearchInstance,
} from "util/Search.util";

const MAIN_MENU_CATEGORY_ID = 1;
export const categoryPathStorageKey = "categoryPath";

interface MenuProps extends OrderSubviewProps {
  ageVerificationStatus?: string;
  deliveryAddress?: DeliveryAddress;
  dispatchOrderItem: React.Dispatch<OrderItemActions>;
  evergageAddToBagEvent: (itemRmiId?: number, price?: number) => void;
  evergageViewCategoryEvent: (id?: number, imageUrl?: string, name?: string) => void;
  evergageViewItemEvent: (
    categories?: number,
    imageUrl?: string,
    itemRmiId?: number,
    name?: string,
    price?: number
  ) => void;
  menu: MenuCategory[];
  orderItemState: OrderItemState;
  setMenuCategories: Dispatch<React.SetStateAction<MenuCategory[]>>;
  triggerBag?: () => void;
}

const Menu = (props: MenuProps): ReactElement => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  const { categoryId } = useParams<{ categoryId: string }>();
  const isMainMenu = categoryId === undefined || categoryId === "1";
  const menuCategory =
    props.menu.length > 0 && isMainMenu
      ? getTopLevelCategoryFromMenu(props.menu)
      : getMenuCategoryById(props.menu, parseInt(categoryId ?? ""));
  const childCategories =
    menuCategory && menuCategory.childrenMenuCategoryIds
      ? getMenuCategoriesByIds(props.menu, menuCategory.childrenMenuCategoryIds)
      : [];
  const [currentMenuCategoryId, setCurrentMenuCategoryId] = useState<number>();
  const retailItems = menuCategory?.menuItems?.retailItems || [];
  const combos = menuCategory?.menuItems?.combos || [];
  const orderType: OrderType = props.orderSession.delivery ? "DELIVERY" : "PICKUP";
  // Delivery orders are considered "IN_STORE" for purposes of pick-up location
  const pickupLocation: PickupLocation = props.orderSession.pickupLocation ?? "IN_STORE";

  // Menu search data
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [categorySearch, setCategorySearch] = useState<Fuse<MenuCategory>>(
    () => new Fuse<MenuCategory>([])
  );
  const [itemSearch, setItemSearch] = useState<Fuse<MenuCategoryRetailItem>>(
    () => new Fuse<MenuCategoryRetailItem>([])
  );
  const [menuCategories, setMenuCategories] = useState<MenuCategory[]>([]);
  const [comboSearch, setComboSearch] = useState<Fuse<Combo>>(() => new Fuse<Combo>([]));

  // Used to track the category path a user takes while ordering an item the traditional way (navigating through menu categories).
  const [categoryPath, setCategoryPath] = useSessionStorage(categoryPathStorageKey, [] as number[]);

  const orderLocationState = location.state as OrderLocationState | undefined;

  useEffect(() => {
    if (
      menuCategory?.featuredItemBanner !== undefined &&
      menuCategory.menuCategoryId !== currentMenuCategoryId
    ) {
      const fib = menuCategory.featuredItemBanner;
      const fibServedEvent: FIBEventRequest = {
        featuredItemBannerServedEventId: generateUuid(),
        featuredItemBannerBundleId: fib.featuredItemBannerBundleId,
        featuredItemBannerBundleName: fib.featuredItemBannerBundleName,
        featuredItemBannerId: fib.featuredItemBannerId,
        featuredItemBannerName: fib.name,
        featuredItemBannerImage: fib.image,
        fibLocationMenuCategoryId: menuCategory.menuCategoryId,
        fibLocationMenuCategoryName: getMenuCategoryById(props.menu, menuCategory.menuCategoryId)
          ?.name,
      };

      switch (fib.navigationType) {
        case "MENU_CATEGORY":
          fibServedEvent.fibDestinationMenuCategoryId = fib.destinationObjectId;
          fibServedEvent.fibDestinationMenuCategoryName = getMenuCategoryById(
            props.menu,
            fib.destinationObjectId
          )?.name;
          break;
        case "ITEM_CUSTOMIZATION":
          fibServedEvent.retailItemId = fib.destinationObjectId;
          fibServedEvent.retailItemName = fib.name;
          break;
        case "COMBO":
          fibServedEvent.fibDestinationMenuComboId = fib.destinationObjectId;
          fibServedEvent.fibDestinationComboName = fib.name;
          break;
        default:
          throw new SheetzError(
            "Unhandled navigation type found for FIB with ID: " +
              menuCategory.featuredItemBanner.featuredItemBannerId,
            {
              userReadableMessage: "Looks like we've hit a snag. Please try again.",
              primaryButton: SheetzErrorButtonType.TRY_AGAIN,
              secondaybutton: SheetzErrorButtonType.CLOSE,
            }
          );
      }

      setCurrentMenuCategoryId(menuCategory.menuCategoryId);
      fibServed(fibServedEvent);
    }
  }, [currentMenuCategoryId, menuCategory, props.menu]);

  // Set a function on the `window.anywhere` namespace that allows the mobile apps to get the status of the search modal.
  useEffect(() => {
    if (!isInNativeMobileContext() || window.anywhere === undefined) {
      return;
    }

    window.anywhere.isSearchOpen = (): boolean => showSearch;
    window.anywhere.closeSearch = (): void => {
      setShowSearch(false);
    };

    // Cleanup when search modal is unmounted.
    return (): void => {
      if (window.anywhere !== undefined) {
        window.anywhere.isSearchOpen = (): boolean => false;
        window.anywhere.closeSearch = (): void => {
          return;
        };
      }
    };
  }, [showSearch]);

  useEffect(() => {
    setCategoryPath((prevValue) => {
      // If we're on the main menu, start the path tracking with a single "Main Menu" entry.
      if (isMainMenu) {
        return [MAIN_MENU_CATEGORY_ID];
      }

      const currentCategoryId = parseInt(categoryId);
      if (prevValue[prevValue.length - 1] === currentCategoryId) {
        return prevValue;
      } else {
        return [...prevValue, currentCategoryId];
      }
    });

    // Send the menu category image to Salesforce, if there isn't a menu category image, send the featured banner.
    let imageUrl = menuCategory ? menuCategory.image : undefined;

    if (!imageUrl && menuCategory?.featuredItemBanner) {
      imageUrl = menuCategory?.featuredItemBanner.image;
    }

    props.evergageViewCategoryEvent(menuCategory?.menuCategoryId, imageUrl, menuCategory?.name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, setCategoryPath, isMainMenu]);

  useEffect(() => {
    // For a delivery order, we only have the store number.
    const storeNumber = props.orderSession.store?.storeNumber ?? props.orderSession.storeNumber;
    if (storeNumber === undefined) {
      navigate("/order", {
        state: {
          homepageBannerRedirect: orderLocationState?.homepageBannerRedirect,
          event: orderLocationState?.event,
        },
        replace: true,
      });
    } else if (!props.orderSession.dayPart) {
      navigate("/order/time", {
        state: {
          homepageBannerRedirect: orderLocationState?.homepageBannerRedirect,
          event: orderLocationState?.event,
        },
        replace: true,
      });
    } else {
      /**
       * Age verification isn't available on first render, so we set categories after getMenuCategories so when
       * age verification is updated, it just updates the Fuse data and doesn't make a second API call
       */
      if (!menuCategories.length) {
        appContext.showLoading();
        getMenuCategories(storeNumber, props.orderSession.dayPart, orderType, pickupLocation)
          .then((response) => {
            setCategorySearch(createCategorySearchInstance(response.data.menuCategories));
            setComboSearch(createComboSearchInstance(response.data.menuCategories));
            setItemSearch(
              createItemSearchInstance(response.data.menuCategories, props.ageVerificationStatus)
            );
            setMenuCategories(response.data.menuCategories);
            props.setMenuCategories?.(response.data.menuCategories);
          })
          .finally(() => {
            appContext.hideLoading();
          });
      } else {
        setItemSearch(createItemSearchInstance(menuCategories, props.ageVerificationStatus));
      }
    }

    // As part of a navigation, it may be requesting for the bag to be shown.
    if (orderLocationState?.showBag) {
      props.triggerBag?.();
      // Update the location state in case the user refreshes the page, so that the bag won't show again.
      navigate(location.pathname, {
        state: { ...orderLocationState, showBag: false },
        replace: true,
      });
    }

    // Whenever we enter the menu for the first time, search shouldn't be set.
    props.dispatch({
      type: "SEARCH",
      payload: undefined,
    });
    // Disable lint check due to numerous dependencies involved in useEffect hook above.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ageVerificationStatus]);

  useEffect(() => {
    if (categoryId === "search") {
      setTimeout(() => {
        setShowSearch(true);
      }, 200);
    }
  }, [categoryId]);

  const clearSearch = (): void => {
    props.dispatch({
      type: "SEARCH",
      payload: undefined,
    });
    setShowSearch(false);
  };

  const closeSearch = (): void => {
    setShowSearch(false);
  };

  const SearchModal = (
    <SheetzModal
      className={"search-modal" + !showSearch ?? " hidden"}
      isOpen={showSearch}
      closeFunction={clearSearch}
      contentLabel="Search"
      onRequestClose={clearSearch}
      shouldCloseOnOverlayClick={true}
      headerText={"Search"}
      hideHeader={isInNativeMobileContext()}
    >
      <Search
        categorySearch={categorySearch}
        closeFunction={closeSearch}
        comboSearch={comboSearch}
        deliveryAddress={props.deliveryAddress}
        dispatch={props.dispatch}
        itemSearch={itemSearch}
        onComboSelected={onComboSelected}
        onItemSelected={onItemSelected}
        orderSession={props.orderSession}
      />
    </SheetzModal>
  );

  function getLinkToCategory(childCategory: MenuCategory): string {
    const linkPath = childCategory.internalLinkType
      ? handleInternalLinkType(childCategory.internalLinkType)
      : "menu/" + childCategory.menuCategoryId;
    return "/order/" + linkPath;
  }

  function onMenuCategorySelected(category: MenuCategory): void {
    if (category.enabled) {
      if (!category.ageRestricted || isAgeVerified(props.ageVerificationStatus)) {
        navigate(getLinkToCategory(category));
      } else if (isAgeUnverified(props.ageVerificationStatus)) {
        navigate("/account/verifyAge?destination=/order/menu/" + category.menuCategoryId);
      } else {
        // This handles the case of a user with a failed validation (i.e. underage) - we do nothing.
        return;
      }
    } else {
      appContext.showAlertActionSheet(category.disabledMessage ?? "Category unavailable.");
    }
  }

  function onItemSelected(itemCustomizationId: number, event?: ItemEvent): void {
    // For a delivery order, we only have the store number
    const storeNumber = props.orderSession.store?.storeNumber ?? props.orderSession.storeNumber;
    if (storeNumber === undefined) {
      throw new SheetzError("Selected store not set.", {
        userReadableMessage: "Store not selected. Please select a store and try again.",
        primaryButton: SheetzErrorButtonType.SELECT_STORE,
      });
    }

    if (!props.orderSession.dayPart) {
      throw new SheetzError("DayPart not set.", {
        userReadableMessage: "Looks like we've hit a snag. Please try again.",
        primaryButton: SheetzErrorButtonType.TRY_AGAIN,
      });
    }

    appContext.showLoading();

    getItemCustomization(
      itemCustomizationId,
      storeNumber,
      props.orderSession.dayPart,
      orderType,
      pickupLocation
    )
      .then((response): void => {
        beginCustomization(response.data, undefined, event);
      })
      .finally((): void => appContext.hideLoading());
  }

  /**
   * Since an FIB only contains the menuComboId, and not the details, (price, nutrition, etc.)
   * we need to search through the menu and find the combo in there before continuing on.
   * @param event
   * @param menuComboId
   */
  function onComboSelectedFromBanner(menuComboId: number, event?: ItemEvent): void {
    let comboDetails: Combo | undefined;
    for (let i = 0; i < props.menu.length; i++) {
      const menuCategory = props.menu[i];
      const combo = menuCategory.menuItems?.combos?.find(
        (combo) => combo.menuComboId === menuComboId
      );
      if (combo) {
        comboDetails = combo;
        break;
      }
    }

    if (comboDetails) {
      onComboSelected(comboDetails, event);
    } else {
      throw new SheetzError(
        "Count not find combo with combo ID: " + menuComboId + " -- when selected through FIB",
        {
          userReadableMessage: "Unable to find item. Please try again.",
          primaryButton: SheetzErrorButtonType.TRY_AGAIN,
        }
      );
    }
  }

  function onComboSelected(combo: Combo, event?: ItemEvent): void {
    const storeNumber = props.orderSession.store?.storeNumber || props.orderSession.storeNumber;
    if (storeNumber === undefined) {
      throw new SheetzError("Selected store not set.", {
        userReadableMessage: "Store not selected. Please select a store and try again.",
        primaryButton: SheetzErrorButtonType.SELECT_STORE,
      });
    }

    if (!props.orderSession.dayPart) {
      throw new SheetzError("DayPart not set.", {
        userReadableMessage: "Looks like we've hit a snag. Please try again.",
        primaryButton: SheetzErrorButtonType.TRY_AGAIN,
      });
    }

    appContext.showLoading();

    getComboDetails(
      combo.menuComboId,
      storeNumber,
      props.orderSession.dayPart,
      orderType,
      pickupLocation
    )
      .then((response): void => {
        const firstComboItem = response.data.itemCustomizations[0];
        beginCustomization(firstComboItem, combo, event);
      })
      .finally((): void => appContext.hideLoading());
  }

  function dispatchViewMenuItemEvent(item?: CustomizedShoppingBagItem): void {
    if (item === undefined) {
      return;
    }

    const rmiDetails = item.itemDetails.retailModifiedItem;

    props.evergageViewItemEvent(
      menuCategory?.menuCategoryId,
      rmiDetails?.image,
      rmiDetails?.retailModifiedItemId,
      rmiDetails?.receiptText,
      rmiDetails?.price
    );
  }

  function beginCustomization(
    itemCustomization: ItemCustomization,
    combo?: Combo,
    event?: ItemEvent
  ): void {
    // Item unavailable
    if (
      itemCustomization.retailItem.retailModifiedItems === undefined ||
      itemCustomization.retailItem.retailModifiedItems.length === 0
    ) {
      appContext.showAlertActionSheet(
        "This item is currently unavailable, please try another item."
      );
      return;
    }

    const soldFromMenuPathEvent: ItemEvent = { source: "MENU", soldFromCategoryPath: categoryPath };

    // Path for item with size or item switch selector that has `displayAsActionSheet` set to true.
    const sizeSelector = findSizeSelector(itemCustomization.template);
    const itemSwitchSelector = findItemSwitchSelector(itemCustomization.template);

    if (
      (sizeSelector && sizeSelector.displayAsActionSheet) ||
      (itemSwitchSelector && itemSwitchSelector.displayAsActionSheet)
    ) {
      props.dispatchOrderItem({
        type: "SET_ITEM_SIZE_INITIAL_STATE",
        payload: {
          chosenCombo: combo,
          chosenItem: itemCustomization,
          itemSwitchOption: undefined,
          menuCategory,
          showCustomizationActionSheets: true,
        },
      });

      return;
    }

    // Item isn't customizable and isn't part of a combo, add to bag and handle AUIs
    if (!isItemCustomizable(itemCustomization) && !combo) {
      const item = buildNonCustomizableItem(
        itemCustomization,
        event ?? orderLocationState?.event ?? soldFromMenuPathEvent
      );

      props.dispatch({
        type: "ADD_ITEM_TO_BAG",
        payload: item,
      });

      props.evergageAddToBagEvent(
        item?.itemDetails.retailModifiedItem?.retailModifiedItemId,
        item?.itemDetails.retailModifiedItem?.price
      );

      dispatchViewMenuItemEvent(item);

      // AIUs are available
      if (itemCustomization.accompanyingItemUpsells?.length > 0) {
        props.dispatchOrderItem({
          type: "SET_AIU_INITIAL_STATE",
          payload: {
            accompanyingItemUpsells: itemCustomization.accompanyingItemUpsells,
            itemCustomizationId: itemCustomization.itemCustomizationId,
            menuCategory,
            showAIUActionSheet: true,
          },
        });

        return;
      }

      // AIUs are not available, show added to bag popup
      setTimeout(() => appContext.showAddToBagPopup(false), 600);
      return;
    }

    // Path for customizable item or combo.
    props.dispatch({ type: "CLEAR_ITEM_CUSTOMIZATIONS", payload: undefined });

    if (combo) {
      props.dispatch({ type: "SET_CURRENT_CUSTOMIZED_COMBO", payload: combo });
      props.dispatch({ type: "SET_ITEM_CUSTOMIZATION_ID", payload: undefined });
      props.dispatch({ type: "CLEAR_COMBO_ITEM_CUSTOMIZATIONS", payload: undefined });
      props.dispatch({ type: "SET_CURRENT_COMBO_ITEM_INDEX", payload: 0 });
      navigate(
        "/order/combo/" + combo.menuComboId + "/customize/" + itemCustomization.itemCustomizationId,
        {
          state: {
            previousCategory: menuCategory,
            event: event ?? orderLocationState?.event ?? soldFromMenuPathEvent,
          },
        }
      );
    } else {
      props.dispatch({
        type: "SET_ITEM_CUSTOMIZATION_ID",
        payload: itemCustomization.itemCustomizationId,
      });
      props.dispatch({ type: "SET_CURRENT_CUSTOMIZED_COMBO", payload: undefined });
      navigate("/order/customize/" + itemCustomization.itemCustomizationId, {
        state: {
          previousCategory: menuCategory,
          itemCustomization: itemCustomization,
          event: event ?? orderLocationState?.event ?? soldFromMenuPathEvent,
        },
      });
    }
  }

  function featuredItemBannerPressed(fib: FeaturedItemBanner): void {
    const fibClickedRequest: FIBEventRequest = {
      featuredItemBannerServedEventId: generateUuid(),
      featuredItemBannerBundleId: fib.featuredItemBannerBundleId,
      featuredItemBannerBundleName: fib.featuredItemBannerBundleName,
      featuredItemBannerId: fib.featuredItemBannerId,
      featuredItemBannerName: fib.name,
      featuredItemBannerImage: fib.image,
      fibLocationMenuCategoryId: menuCategory?.menuCategoryId,
      fibLocationMenuCategoryName: menuCategory?.name,
    };

    const event: ItemEvent = {
      source: "FEATURED_ITEM_BANNER",
      featuredItemBannerServedEventId: generateUuid(),
      featuredItemBannerBundleId: fib.featuredItemBannerBundleId,
      featuredItemBannerBundleName: fib.featuredItemBannerBundleName,
      featuredItemBannerId: fib.featuredItemBannerId,
      featuredItemBannerName: fib.name,
    };

    switch (fib.navigationType) {
      case "MENU_CATEGORY":
        fibClickedRequest.fibDestinationMenuCategoryId = fib.destinationObjectId;
        fibClickedRequest.fibDestinationMenuCategoryName = getMenuCategoryById(
          props.menu,
          fib.destinationObjectId
        )?.name;

        if (menuCategory?.menuCategoryId !== fib.destinationObjectId) {
          fibClicked(fibClickedRequest);
        }

        navigate("/order/menu/" + fib.destinationObjectId, { state: { event: event } });
        break;
      case "ITEM_CUSTOMIZATION":
        fibClickedRequest.retailItemId = fib.destinationObjectId;
        fibClickedRequest.retailItemName = fib.name;

        if (menuCategory?.menuCategoryId !== fib.destinationObjectId) {
          fibClicked(fibClickedRequest);
        }

        onItemSelected(fib.destinationObjectId, event);
        break;
      case "COMBO":
        fibClickedRequest.fibDestinationMenuComboId = fib.destinationObjectId;
        fibClickedRequest.fibDestinationComboName = fib.name;

        if (menuCategory?.menuCategoryId !== fib.destinationObjectId) {
          fibClicked(fibClickedRequest);
        }

        onComboSelectedFromBanner(fib.destinationObjectId, event);
        break;
      default:
        throw new SheetzError(
          "Unhandled navigation type found for FIB with ID: " + fib.featuredItemBannerId,
          {
            userReadableMessage: "Looks like we've hit a snag. Please try again.",
            primaryButton: SheetzErrorButtonType.TRY_AGAIN,
          }
        );
    }
  }

  const childCategoryListItems = childCategories.map((childCategory) => (
    <li key={childCategory.menuCategoryId}>
      <MenuItem
        name={childCategory.name}
        subtext={childCategory.description}
        image={childCategory.image}
        onClick={() => onMenuCategorySelected(childCategory)}
        type={MenuItemType.category}
      />
    </li>
  ));

  const menuItemListItems = retailItems.map((retailItem) => (
    <li key={retailItem.itemCustomizationId}>
      <MenuItem
        name={retailItem.receiptText}
        subtext={retailItem.descriptiveText}
        image={retailItem.image}
        price={retailItem.startsAtPrice.toFixed(2)}
        calories={retailItem.nutrition?.calories}
        type={MenuItemType.item}
        isCustomizable={retailItem.isCustomizable}
        onClick={(): void => onItemSelected(retailItem.itemCustomizationId)}
      />
    </li>
  ));

  const comboListItems = combos.map((combo) => (
    <li key={combo.menuComboId}>
      <MenuItem
        name={combo.name}
        subtext={combo.description}
        image={combo.image}
        price={combo.startsAtPrice.toFixed(2)}
        calories={combo.nutrition?.calories}
        type={MenuItemType.combo}
        isCustomizable={combo.isCustomizable}
        onClick={(): void => onComboSelected(combo)}
      />
    </li>
  ));

  const featuredContentContainerClassNames = classNames("featured-content-container", {
    "fixed-at-top": menuCategory?.featuredItemBanner?.fixedAtTop,
  });

  const menuContentContainerClassNames = classNames("menu-content-container", {
    "no-featured-item-banner": !menuCategory?.featuredItemBanner,
  });

  return (
    <>
      <div className="menu" id="ordering-menu">
        {menuCategory?.featuredItemBanner && (
          <div className={featuredContentContainerClassNames}>
            <SheetzContainerButton
              onClick={(): void =>
                menuCategory.featuredItemBanner &&
                featuredItemBannerPressed(menuCategory.featuredItemBanner)
              }
            >
              <img
                className="feature-image"
                src={getImageSrc(menuCategory.featuredItemBanner.image)}
                srcSet={getImageSrcSet(menuCategory.featuredItemBanner.image)}
                sizes="100vw"
                alt={menuCategory.featuredItemBanner.name}
              />
            </SheetzContainerButton>
          </div>
        )}
        {menuCategory !== undefined && (
          <div className={menuContentContainerClassNames}>
            <ul className="child-category-list">
              {isMainMenu && (
                <li>
                  <MenuItem
                    name="Search Our Menu"
                    staticImage={searchIcon}
                    type={MenuItemType.search}
                    onClick={(): void => {
                      props.dispatch({
                        type: "SEARCH",
                        payload: undefined,
                      });
                      setShowSearch(true);
                    }}
                  />
                </li>
              )}
              {childCategoryListItems}
              {comboListItems}
              {menuItemListItems}
            </ul>
          </div>
        )}
      </div>

      {showSearch && SearchModal}
    </>
  );
};

export default Menu;
