import React, { useEffect, useState } from "react";

import "./PaymentMethodErrorManager.scss";

import { PaymentMethod, PurchaseOrder } from "assets/dtos/anywhere-dto";

import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";

import { isInNativeMobileContext } from "util/MobileApp.util";
import {
  SelectedMobilePaymentMethod,
  SelectedPaymentMethod,
  mobilePaymentRestrictionsExist,
} from "util/Payment.util";

interface PaymentMethodErrorManagerProps {
  changePaymentMethod: () => void;
  children: React.ReactNode;
  creditCardExpired: boolean;
  isReloadGiftCard?: boolean;
  giftCardHasInsufficientFunds: boolean;
  payInStore: boolean;
  paymentMethods?: PaymentMethod[];
  purchaseOrder?: PurchaseOrder;
  selectedMobilePaymentMethod?: SelectedMobilePaymentMethod;
  selectedPaymentMethod?: SelectedPaymentMethod;
  paymentMethodErrorManagerRefresh?: boolean;
}

const PaymentMethodErrorManager = ({
  changePaymentMethod,
  children,
  creditCardExpired,
  isReloadGiftCard,
  giftCardHasInsufficientFunds,
  payInStore,
  paymentMethods,
  purchaseOrder,
  selectedMobilePaymentMethod,
  selectedPaymentMethod,
  paymentMethodErrorManagerRefresh,
}: PaymentMethodErrorManagerProps) => {
  const [showSubmitOrderButton, setShowSubmitOrderButton] = useState(true);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    if (paymentMethodErrorManagerRefresh !== undefined) {
      setShowSubmitOrderButton(true);
      setPaymentErrorMessage(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodErrorManagerRefresh]);

  useEffect(() => {
    if (isReloadGiftCard || payInStore) {
      return;
    }

    if (paymentMethodErrorManagerRefresh === undefined) {
      let paymentErrorMessage;

      const mobilePayType =
        selectedMobilePaymentMethod?.mobilePayType === "APPLE_PAY" ? "Apple Pay" : "Google Pay";

      const useMobilePaymentMethodMessage = `This transaction cannot be completed with your default payment method. Proceed with ${mobilePayType} or update your payment method to continue.`;

      // Traditional payment method (credit card & gift card) restriction exists.
      // Note: Not sure if we will ever make it into this scenario until we introduce split payment.
      const traditionalPaymentMethodHasRestrictions =
        selectedPaymentMethod && selectedPaymentMethod?.paymentRestriction !== undefined;

      // All selected mobile and traditional payment methods have errors or restrictions
      const allPaymentMethodsHaveErrorsRestrictions =
        (creditCardExpired ||
          giftCardHasInsufficientFunds ||
          traditionalPaymentMethodHasRestrictions) &&
        selectedMobilePaymentMethod?.paymentRestriction !== undefined;

      //
      const mobilePaymentMethodHasRestrictions =
        mobilePaymentRestrictionsExist(purchaseOrder, selectedMobilePaymentMethod?.mobilePayType) &&
        isInNativeMobileContext();

      if (allPaymentMethodsHaveErrorsRestrictions) {
        // Mobile payment method is selected and has purchase order restrictions AND traditional payment methods (credit card & gift card) are invalid or have purchase order restrictions.
        paymentErrorMessage =
          "This transaction cannot be completed with these payment methods, please update your payment method to continue.";
        setShowSubmitOrderButton(false);
      } else if (mobilePaymentMethodHasRestrictions) {
        // Mobile payment method has restrictions
        paymentErrorMessage = `This transaction cannot be completed with ${mobilePayType}. Proceed with your default payment method or update your payment method to continue.`;
        setShowSubmitOrderButton(false);
      } else if (traditionalPaymentMethodHasRestrictions) {
        // Traditional payment method (credit card & gift card) has purchase order restrictions.
        paymentErrorMessage = selectedPaymentMethod?.paymentRestriction?.description;
        setShowSubmitOrderButton(false);
      } else if (giftCardHasInsufficientFunds || creditCardExpired) {
        // Determine appropriate message
        if (
          selectedMobilePaymentMethod?.selected &&
          !selectedMobilePaymentMethod.paymentRestriction
        ) {
          paymentErrorMessage = useMobilePaymentMethodMessage;
        } else if (giftCardHasInsufficientFunds) {
          paymentErrorMessage = isInNativeMobileContext()
            ? useMobilePaymentMethodMessage
            : "Insufficient funds, please update your payment to continue.";
        } else if (creditCardExpired) {
          paymentErrorMessage =
            "This transaction cannot be completed with your default payment method, please update your payment method to continue.";
        }

        setShowSubmitOrderButton(false);
      } else if (
        !allPaymentMethodsHaveErrorsRestrictions &&
        !creditCardExpired &&
        !giftCardHasInsufficientFunds &&
        !mobilePaymentMethodHasRestrictions &&
        !traditionalPaymentMethodHasRestrictions
      ) {
        setShowSubmitOrderButton(true);
        setPaymentErrorMessage(undefined);
      }

      setPaymentErrorMessage(paymentErrorMessage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    creditCardExpired,
    isReloadGiftCard,
    giftCardHasInsufficientFunds,
    payInStore,
    paymentMethods,
    paymentMethodErrorManagerRefresh,
    selectedMobilePaymentMethod,
    selectedPaymentMethod,
  ]);

  return (
    <ResponsiveLayoutContainer>
      <div className="payment-method-error-manager">
        {paymentErrorMessage !== undefined && (
          <div className="payment-method-errors">
            <div className="payment-method-errors-message">
              <p>{paymentErrorMessage}</p>
            </div>
            {!showSubmitOrderButton && (
              <SheetzButton
                buttonColor={ButtonColor.errorOrange}
                className="change-payment"
                textType="title"
                label="Change Payment"
                onClick={changePaymentMethod}
              />
            )}
          </div>
        )}

        {showSubmitOrderButton && <div>{children}</div>}
      </div>
    </ResponsiveLayoutContainer>
  );
};

export default PaymentMethodErrorManager;
