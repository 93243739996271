import React, { ReactElement } from "react";

import "./SelectorTabs.scss";

export interface SelectorTab {
  title: string;
  id: string;
}

interface SelectorTabsProps {
  tabs: SelectorTab[];
  selectedTab: string;
}

const SelectorTabs = (props: SelectorTabsProps): ReactElement => {
  return (
    <div className="selector-tabs-container">
      <ul className="selector-tabs">
        {props.tabs.map((tab): ReactElement => {
          return (
            <li key={tab.id}>
              <button
                onClick={(): void => {
                  document.getElementById(tab.id)?.scrollIntoView();
                }}
              >
                {tab.title}
              </button>
              {props.selectedTab === tab.id ? <div className="underline"></div> : null}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SelectorTabs;
