import React, { ReactElement } from "react";

import "./ActionPanel.scss";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";

import { IconType, getIcon } from "util/Icon.util";

interface ActionPanelProps {
  bodyText?: string;
  buttonText?: string;
  onClick?: () => void;
  titleText?: string;
}

const ActionPanel = (props: ActionPanelProps): ReactElement => {
  return (
    <div className="action-panel">
      {getIcon(IconType.defaultStore, "icon-default-store")}
      {props.titleText && <h1>{props.titleText}</h1>}
      {props.bodyText && <p>{props.bodyText}</p>}
      {props.buttonText && (
        <SheetzButton
          label={props.buttonText}
          onClick={(): void => props.onClick && props.onClick()}
        ></SheetzButton>
      )}
    </div>
  );
};

export default ActionPanel;
