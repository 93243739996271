import { Formik, FormikErrors, FormikProps } from "formik";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import "./ResetPassword.scss";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import PasswordChecklist, {
  processValidationErrors,
} from "components/misc/form/PasswordChecklist/PasswordChecklist";
import SheetzInput from "components/misc/form/SheetzInput/SheetzInput";

import { AppContext } from "util/AppContext.util";
import {
  logout,
  resetPassword,
  updatePassword,
  validateResetToken,
} from "util/Authentication.util";
import { closeWebView, isInNativeMobileContext } from "util/MobileApp.util";
import { getAuthToken } from "util/Storage.util";

enum ResetPasswordState {
  VALID = "valid",
  INVALID = "invalid",
  ERROR = "error",
  SUCCESS = "success",
}

enum tokenValidity {
  VALID = "Valid",
  INVALID = "Invalid",
}

interface FormValues {
  password: string;
  passwordConfirm: string;
}

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const [pageState, setPageState] = useState<ResetPasswordState>(ResetPasswordState.VALID);
  const [resetToken, setResetToken] = useState<string>("");
  const resetPasswordState = location.state as { bypassValidateReset: boolean } | undefined;
  const isInNativeWebView = isInNativeMobileContext();

  useEffect(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    appContext.showLoading();
    setTimeout(() => {
      // Force password reset does not require JWT to be validated
      if (!resetPasswordState?.bypassValidateReset) {
        validateResetToken({ resetToken: queryParams.rt as string })
          .then((response) => {
            if (response.data.tokenStatus === tokenValidity.VALID) {
              setPageState(ResetPasswordState.VALID);
              setResetToken(queryParams.rt as string);
            } else {
              setPageState(ResetPasswordState.INVALID);
            }
          })
          .catch(() => {
            setPageState(ResetPasswordState.ERROR);
          })
          .finally(() => {
            appContext.hideLoading();
          });
      } else {
        appContext.hideLoading();
        const token = getAuthToken();
        if (token) {
          setResetToken(token);
        } else {
          logout();
        }
      }
    }, 2000);
    // Specifically disabling this warning because otherwise this would call every time the context changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const submitResetPassword = (values: FormValues): void => {
    appContext.showLoading();
    if (!resetPasswordState?.bypassValidateReset) {
      updatePassword({ resetToken: resetToken, password: values.password }) // Uses a reset token which is a UUID
        .then(() => {
          setPageState(ResetPasswordState.SUCCESS);
        })
        .finally(() => appContext.hideLoading());
    } else {
      resetPassword({ resetToken: resetToken, password: values.password }) // Uses the JWT specific to reseting a user's password
        .then(() => {
          setPageState(ResetPasswordState.SUCCESS);
        })
        .finally(() => appContext.hideLoading());
    }
  };

  interface ResetPasswordInvalidProps {
    error: boolean;
  }

  const ResetPasswordInvalid = ({ error }: ResetPasswordInvalidProps) => {
    return (
      <>
        <div className="reset-message">
          <div className="title">{error ? "Error Checking Reset" : "Invalid Reset"}</div>
          <div className="message">Please try again later.</div>
        </div>
        <SheetzButton
          className="submit-button"
          label="Return to Login"
          onClick={(): void => {
            if (isInNativeWebView) {
              closeWebView();
            } else {
              navigate("/auth/login");
            }
          }}
        />
      </>
    );
  };

  const ResetPasswordSuccess = () => {
    return (
      <>
        <div className="reset-message">
          <div className="title">Password Successfully Reset</div>
          <div className="message">You&apos;re good to go!</div>
        </div>
        <SheetzButton
          className="submit-button"
          label={isInNativeWebView ? "Continue" : "Return to Login"}
          onClick={(): void => {
            if (isInNativeWebView) {
              closeWebView();
            } else {
              navigate("/auth/login");
            }
          }}
        />
      </>
    );
  };

  const resetPasswordValidationSchema = Yup.object({
    password: Yup.string()
      .required("Required")
      .max(256)
      .min(8)
      .matches(new RegExp("^[0-9a-zA-Z@!#$%^&*()_<>` ]+$"), "Invalid character")
      .matches(new RegExp("^.*[A-Z].*$"), "Password must contain at least one uppercase letter")
      .matches(new RegExp("^.*[a-z].*$"), "Password must contain at least one lowercase letter")
      .matches(new RegExp("^.*[0-9].*$"), "Password must contain at least one number")
      .matches(
        new RegExp("^.*[!@#$%^&*()_].*$"),
        "Password must contain at least special character"
      ),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const validateSchema = (values: FormValues): Promise<FormValues | FormikErrors<FormValues>> => {
    const emptyError: FormikErrors<FormValues> = {};
    return resetPasswordValidationSchema
      .validate(values, { abortEarly: false })
      .then(() => {
        return emptyError;
      })
      .catch((errors) => {
        return processValidationErrors(errors);
      });
  };

  const ResetPasswordForm = () => {
    return (
      <>
        <div className="reset-message margin-bottom">
          <div className="title">Enter a New Password</div>
          <div className="message">Your previous password cannot be used.</div>
        </div>
        <Formik
          initialValues={{ password: "", passwordConfirm: "" }}
          onSubmit={(values: FormValues): void => {
            submitResetPassword(values);
          }}
          validate={(values: FormValues): Promise<FormValues | FormikErrors<FormValues>> =>
            validateSchema(values)
          }
        >
          {(props: FormikProps<FormValues>) => (
            <form onSubmit={props.handleSubmit}>
              <PasswordChecklist
                trackChanges={props.dirty}
                passwordError={props.errors.password || ""}
                validateCurrentPassword={false}
              />
              <SheetzInput
                type="password"
                name="password"
                placeholder="Password"
                label="Password"
                showError={false}
              />

              <SheetzInput
                type="password"
                name="passwordConfirm"
                placeholder="Confirm Password"
                label="Confirm Password"
              />
              <SheetzButton className="submit-button" type="submit" label="Reset Password" />
            </form>
          )}
        </Formik>
      </>
    );
  };

  const resetPageContent = () => {
    switch (pageState) {
      case ResetPasswordState.VALID:
        return <ResetPasswordForm />;
      case ResetPasswordState.INVALID:
        return <ResetPasswordInvalid error={false} />;
      case ResetPasswordState.SUCCESS:
        return <ResetPasswordSuccess />;
      case ResetPasswordState.ERROR:
      default:
        return <ResetPasswordInvalid error={true} />;
    }
  };
  return <div className="reset-form">{resetPageContent()}</div>;
};

export default ResetPassword;
