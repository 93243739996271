import { Formik, FormikHelpers, FormikProps } from "formik";
import React, { Dispatch, useEffect, useState } from "react";
import { PatternFormat } from "react-number-format";
import * as Yup from "yup";

import "./PersonalInformation.scss";

import { UpdateUserProfileRequest } from "assets/dtos/anywhere-dto";

import { AccountFormValues } from "components/Account/AccountSettings/AccountSettings";
import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import ButtonFooterContainer from "components/misc/containers/ButtonFooterContainer/ButtonFooterContainer";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import SheetzCheckbox from "components/misc/form/SheetzCheckbox/SheetzCheckbox";
import SheetzInput, { SelectOption } from "components/misc/form/SheetzInput/SheetzInput";
import SheetzSelect from "components/misc/form/SheetzSelect/SheetzSelect";
import SheetzToggle from "components/misc/form/SheetzToggle/SheetzToggle";
import PathListItem from "components/misc/list/PathListItem/PathListItem";

import states from "resources/states";

import { MobileAccountSettingsPreferences } from "util/Account.util";
import { isInNativeMobileContext, showPushNotificationStatus } from "util/MobileApp.util";
import {
  addressValidation,
  cityValidation,
  emailValidation,
  employeeIdValidation,
  nameValidation,
  phoneValidation,
  stateValidation,
  zipValidation,
} from "util/Validation.util";

interface PersonalInformationProps {
  accountFormValues: AccountFormValues;
  emailSettings?: boolean;
  employeeInformation?: boolean;
  isPushNotificationsEnabled?: boolean;
  setMobileAccountSettingsPreferences?: Dispatch<
    React.SetStateAction<MobileAccountSettingsPreferences>
  >;
  updateUserProfileInformation: (userProfileValues: UpdateUserProfileRequest) => void;
}

const PersonalInformation = (props: PersonalInformationProps) => {
  const [receiveFullReceipts, setReceiveFuelReceipts] = useState<boolean | undefined>(
    props.accountFormValues.fuelReceiptEmailOptOut !== undefined
      ? props.accountFormValues.fuelReceiptEmailOptOut
      : false
  );

  const personalInfo = !props.emailSettings && !props.employeeInformation;

  useEffect(() => {
    setReceiveFuelReceipts(props.accountFormValues.fuelReceiptEmailOptOut);
  }, [props.accountFormValues]);

  const defaultValidationSchema = Yup.object({
    email: emailValidation,
    firstName: nameValidation,
    lastName: nameValidation,
    streetAddress: addressValidation,
    streetAddress2: addressValidation,
    city: cityValidation,
    state: stateValidation,
    zip: zipValidation,
    employeeId: employeeIdValidation,
    vipCode: Yup.string().when("employeeId", {
      is: (value: string) => value && value.length > 0,
      then: Yup.string().required("Required"),
    }),
  });

  const phoneNumberValidationSchema = Yup.object({
    phoneNumber: phoneValidation.required("Required"),
  });

  const validationSchema =
    personalInfo || props.emailSettings
      ? defaultValidationSchema.concat(phoneNumberValidationSchema)
      : defaultValidationSchema;

  const stateSelectOptions: SelectOption[] = states.map((state) => ({
    label: state.name,
    value: state.abbrev,
  }));

  const updateAccountSettings = (
    values: AccountFormValues,
    formikHelpers?: FormikHelpers<AccountFormValues>,
    setFuelReceipts = false
  ): void => {
    const userProfileValues: UpdateUserProfileRequest = {
      emailAddress: personalInfo ? values.email : props.accountFormValues.email,
      firstName: personalInfo ? values.firstName : props.accountFormValues.firstName,
      lastName: personalInfo ? values.lastName : props.accountFormValues.lastName,
      phoneNumber:
        personalInfo || props.emailSettings
          ? values.phoneNumber
          : props.accountFormValues.phoneNumber,
      zipCode: personalInfo ? values.zip : props.accountFormValues.zip,
      address: personalInfo ? values.streetAddress : props.accountFormValues.streetAddress,
      address2: personalInfo ? values.streetAddress2 : props.accountFormValues.streetAddress2,
      city: personalInfo ? values.city : props.accountFormValues.city,
      dob: props.accountFormValues.dob,
      stateCode: personalInfo ? values.state : props.accountFormValues.state,
      fuelReceiptEmailOptOut: setFuelReceipts
        ? !props.accountFormValues.fuelReceiptEmailOptOut
        : props.accountFormValues.fuelReceiptEmailOptOut,
      textMessages: props.emailSettings
        ? values.textMessages
        : props.accountFormValues.textMessages,
      employeeId: values.employeeId,
      vipCode: values.vipCode,
    };

    props.updateUserProfileInformation(userProfileValues);
  };

  function toggleFuelReceiptEmails(): void {
    updateAccountSettings(props.accountFormValues, undefined, true);

    setReceiveFuelReceipts(!receiveFullReceipts);
  }

  function togglePushNotifications(): void {
    showPushNotificationStatus(!props.isPushNotificationsEnabled);
  }

  const emailToggle = (
    <div className="toggle">
      <SheetzToggle clickHandler={toggleFuelReceiptEmails} checked={!receiveFullReceipts} />
    </div>
  );

  const pushNotificationsToggle = (
    <div className="toggle">
      <SheetzToggle
        clickHandler={togglePushNotifications}
        checked={props.isPushNotificationsEnabled}
      />
    </div>
  );

  const transactionalEmailToggle = (
    <div className="toggle">
      <SheetzToggle checked disabled />
    </div>
  );

  return (
    <div className="personal-information-form">
      <ResponsiveLayoutContainer className="account-settings">
        {props.emailSettings && (
          <div className="personal-information-list-items">
            {isInNativeMobileContext() && props.isPushNotificationsEnabled !== undefined && (
              <PathListItem
                title="Push Notifications"
                hideArrow
                rightButtonContent={pushNotificationsToggle}
              />
            )}
            <PathListItem title="Fuel Receipts Emails" hideArrow rightButtonContent={emailToggle} />
            <PathListItem
              title="Transactional Emails"
              subtitle={<span className="transactional-email-subtitle">Required</span>}
              hideArrow
              rightButtonContent={transactionalEmailToggle}
              disabled
              hideBottomBorder
            />
          </div>
        )}

        {props.accountFormValues.email.length ? (
          <Formik
            enableReinitialize={true}
            initialValues={props.accountFormValues}
            validationSchema={validationSchema}
            onSubmit={updateAccountSettings}
          >
            {(formProps: FormikProps<AccountFormValues>) => (
              <form>
                {!props.emailSettings && !props.employeeInformation && (
                  <div className="personal-information-container">
                    <SheetzInput
                      type={"email"}
                      name={"email"}
                      placeholder={"Email Address"}
                      label={"Email Address"}
                      required
                    />
                    <SheetzInput
                      type={"text"}
                      name={"firstName"}
                      placeholder={"First Name"}
                      label={"First Name"}
                      required
                    />
                    <SheetzInput
                      type={"text"}
                      name={"lastName"}
                      placeholder={"Last Name"}
                      label={"Last Name"}
                      required
                    />
                    <PatternFormat
                      type="tel"
                      format="(###) ###-####"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      label="Phone Number"
                      customInput={SheetzInput}
                      defaultValue={props.accountFormValues.phoneNumber}
                      onValueChange={(value): void =>
                        formProps.setFieldValue("phoneNumber", value.value)
                      }
                      valueIsNumericString
                    />
                    <SheetzInput
                      type={"text"}
                      name={"streetAddress"}
                      placeholder={"Address line 1"}
                      label={"Address line 1"}
                    />
                    <div className="flex-row">
                      <div className="address-line-2-input-container">
                        <SheetzInput
                          type={"text"}
                          name={"streetAddress2"}
                          placeholder={"Address line 2"}
                          label={"Address line 2"}
                        />
                      </div>
                      <span className="address-line-2-optional">Optional</span>
                    </div>
                    <SheetzInput type={"text"} name={"city"} placeholder={"City"} label={"City"} />
                    <SheetzSelect
                      name={"state"}
                      placeholder={"State"}
                      label={"State"}
                      options={stateSelectOptions}
                      hideSelectArrow
                      disabled
                    />
                    <SheetzInput
                      type={"text"}
                      name={"zip"}
                      placeholder={"Zip"}
                      label={"Zip"}
                      required
                    />
                  </div>
                )}

                {props.employeeInformation && (
                  <div>
                    <p className="employee-information">Sheetz Employee Info</p>

                    <SheetzInput
                      type={"text"}
                      name={"employeeId"}
                      placeholder={"Employee Id"}
                      label={"Employee Id"}
                    />
                    <SheetzInput
                      type={"text"}
                      name={"vipCode"}
                      placeholder={"VIP Code"}
                      label={"VIP Code"}
                    />
                  </div>
                )}

                {props.emailSettings && (
                  <>
                    <p>SMS Notifications</p>

                    <PatternFormat
                      type="tel"
                      format="(###) ###-####"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      label="Phone Number"
                      customInput={SheetzInput}
                      defaultValue={props.accountFormValues.phoneNumber}
                      onValueChange={(value): void =>
                        formProps.setFieldValue("phoneNumber", value.value)
                      }
                      valueIsNumericString
                    />

                    <p className="text-message-optin">
                      Opt in to receive the latest updates from Sheetz regarding dealz, offerz, your
                      Rewardz account, and general info about our products, services, and stores!{" "}
                      <SheetzTextButton
                        label="Terms"
                        onClick={() =>
                          window.open(
                            "https://www.sheetz.com/mobile/terms-and-conditions",
                            "_blank"
                          )
                        }
                        type="button"
                      />
                      &
                      <SheetzTextButton
                        label="Privacy Policy"
                        onClick={() =>
                          window.open(
                            "https://www.sheetz.com/mobile/privacy-policy-notice",
                            "_blank"
                          )
                        }
                        type="button"
                      />
                    </p>

                    <SheetzCheckbox
                      label="By providing my mobile number and checking this box, I authorize Sheetz to deliver marketing messages using an automatic telephone dialing system and I understand that I am not required to opt in as a condition of purchasing any property, goods, or services. Message frequency varies. Message & Data Rates may apply. Text HELP to 543375 for Help. Text STOP to 543375 to cancel."
                      name="textMessages"
                    />
                  </>
                )}

                <ButtonFooterContainer>
                  <SheetzButton
                    className="submit-button"
                    buttonColor={ButtonColor.sheetzRed}
                    disabled={!formProps.dirty}
                    type="button"
                    label="Save Changes"
                    onClick={(): void => {
                      formProps.validateForm().then((values) => {
                        if (Object.keys(values).length > 0) {
                          const firstErrorName = Object.keys(values)[0];

                          if (firstErrorName) {
                            const element = document.getElementsByName(firstErrorName);
                            element[0].scrollIntoView({ behavior: "smooth", block: "center" });
                          }
                        }

                        formProps.handleSubmit();
                      });
                    }}
                  />
                </ButtonFooterContainer>
              </form>
            )}
          </Formik>
        ) : null}
      </ResponsiveLayoutContainer>
    </div>
  );
};

export default PersonalInformation;
