import React, { ReactElement } from "react";

import "./SelectorContainer.scss";

import { Selector } from "assets/dtos/anywhere-dto";

interface SelectorContainerProps {
  selector: Selector;
  children: React.ReactNode;
}

export function getSelectorId(selector: Selector): string {
  return selector.shortText?.toLowerCase() + "_" + (selector.tags || selector.text)?.toString();
}

const SelectorContainer = (props: SelectorContainerProps): ReactElement => {
  const isPaddingNotNeeded =
    props.selector.type === "NO_OPTION_SWITCH" || props.selector.type === "MULTI_OPTION_SWITCH";

  return (
    <>
      <div
        className={"item-customization-selector" + (isPaddingNotNeeded ? " no-padding" : "")}
        id={getSelectorId(props.selector)}
      >
        {props.children}
      </div>
      <div className="selector-divider-container">
        <div className="divider"></div>
      </div>
    </>
  );
};

export default SelectorContainer;
