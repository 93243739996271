import { IconType, getIcon } from "./Icon.util";
import { ReactElement } from "react";

export interface Filter {
  title: string;
  icon: ReactElement;
  id: string;
  selected: boolean;
  isEvCharger?: boolean;
  isFuel?: boolean;
}

export interface StoreSearchFiltersType {
  bulkDef: boolean;
  evCharger: boolean;
  evPlugTypeCcs: boolean;
  evPlugTypeChaDemo: boolean;
  evPlugTypeJ1772: boolean;
  evPlugTypeNacs: boolean;
  e85: boolean;
  e15: boolean;
  e0: boolean;
  kerosene: boolean;
  propane: boolean;
  diesel: boolean;
  highFlowDiesel: boolean;
  open24x7: boolean;
  indoorSeating: boolean;
  outdoorSeating: boolean;
  freeWifi: boolean;
  carWash: boolean;
  driveThru: boolean;
  alcohol: boolean;
  atm: boolean;
  pncAtm: boolean;
  weisCard: boolean;
  iceCream: boolean;
  delivery: boolean;
  inStorePickup: boolean;
  mobilePointOfSale: boolean;
  showers: boolean;
  truckScales: boolean;
  truckParking: boolean;
  highFlowAutoDiesel: boolean;
}

export function resetFilters(): void {
  EVTypeFilters.forEach((filter) => (filter.selected = false));
  FuelTypeFilters.forEach((filter) => (filter.selected = false));
  StoreFilters.forEach((filter) => (filter.selected = false));
}

export const EVTypeFilters: Filter[] = [
  {
    title: "CCS",
    icon: getIcon(IconType.chargePortCCS, "ev-type-icon"),
    id: "evPlugTypeCcs",
    selected: false,
    isEvCharger: true,
  },
  {
    title: "CHAdeMO",
    icon: getIcon(IconType.chargePortCHAdeMO, "ev-type-icon"),
    id: "evPlugTypeChaDemo",
    selected: false,
    isEvCharger: true,
  },
  {
    title: "J1772",
    icon: getIcon(IconType.chargePortJ1772, "ev-type-icon"),
    id: "evPlugTypeJ1772",
    selected: false,
    isEvCharger: true,
  },
  {
    title: "NACS (Tesla)",
    icon: getIcon(IconType.chargePortTesla, "ev-type-icon"),
    id: "evPlugTypeNacs",
    selected: false,
    isEvCharger: true,
  },
];

export const FuelTypeFilters: Filter[] = [
  {
    title: "Auto Diesel",
    icon: getIcon(IconType.diesel, "fuel-type-icon"),
    id: "diesel",
    selected: false,
    isFuel: true,
  },
  {
    title: "High Flow Auto Diesel",
    icon: getIcon(IconType.highFlowAutoDiesel, "fuel-type-icon"),
    id: "highFlowAutoDiesel",
    selected: false,
    isFuel: true,
  },
  {
    title: "Flex Fuel (E85)",
    icon: getIcon(IconType.e85, "fuel-type-icon"),
    id: "e85",
    selected: false,
    isFuel: true,
  },
  {
    title: "Unleaded 88",
    icon: getIcon(IconType.e15, "fuel-type-icon"),
    id: "e15",
    selected: false,
    isFuel: true,
  },
  {
    title: "Ethanol Free Plus 90",
    icon: getIcon(IconType.e0, "fuel-type-icon"),
    id: "e0",
    selected: false,
    isFuel: true,
  },
  {
    title: "Kerosene",
    icon: getIcon(IconType.kerosene, "fuel-type-icon"),
    id: "kerosene",
    selected: false,
    isFuel: true,
  },
  {
    title: "Propane",
    icon: getIcon(IconType.propane, "fuel-type-icon"),
    id: "propane",
    selected: false,
    isFuel: true,
  },
  {
    title: "Truck Diesel",
    icon: getIcon(IconType.diesel, "fuel-type-icon"),
    id: "highFlowDiesel",
    selected: false,
    isFuel: true,
  },
  {
    title: "Bulk DEF",
    icon: getIcon(IconType.bulkDef, "fuel-type-icon"),
    id: "bulkDef",
    selected: false,
    isFuel: true,
  },
];

export const StoreFilters: Filter[] = [
  {
    title: "Open 24/7",
    icon: getIcon(IconType.clock, "store-filter-icon"),
    id: "open24x7",
    selected: false,
  },
  {
    title: "Indoor Seating",
    icon: getIcon(IconType.insideSeating, "store-filter-icon"),
    id: "indoorSeating",
    selected: false,
  },
  {
    title: "Picnic Seating",
    icon: getIcon(IconType.seating, "store-filter-icon"),
    id: "outdoorSeating",
    selected: false,
  },
  {
    title: "Free Wifi",
    icon: getIcon(IconType.wifi, "store-filter-icon"),
    id: "freeWifi",
    selected: false,
  },
  {
    title: "Carwash",
    icon: getIcon(IconType.carwash, "store-filter-icon"),
    id: "carWash",
    selected: false,
  },
  {
    title: "Drive Thru",
    icon: getIcon(IconType.drivethru, "store-filter-icon"),
    id: "driveThru",
    selected: false,
  },
  {
    title: "Beer & Wine",
    icon: getIcon(IconType.beerWineFiltersStoreDetail, "store-filter-icon"),
    id: "alcohol",
    selected: false,
  },
  {
    title: "PNC Bank ATM",
    icon: getIcon(IconType.pnc, "store-filter-icon"),
    id: "pncAtm",
    selected: false,
  },
  {
    title: "Ice Cream",
    icon: getIcon(IconType.iceCream, "store-filter-icon"),
    id: "iceCream",
    selected: false,
  },
  {
    title: "Delivery",
    icon: getIcon(IconType.delivery, "store-filter-icon"),
    id: "delivery",
    selected: false,
  },
  {
    title: "ATM",
    icon: getIcon(IconType.atm, "store-filter-icon"),
    id: "atm",
    selected: false,
  },
  {
    title: "SheetzGO!",
    icon: getIcon(IconType.sheetzGo666SQ, "store-filter-icon"),
    id: "mobilePointOfSale",
    selected: false,
  },
  {
    title: "Showers",
    icon: getIcon(IconType.showers, "store-filter-icon"),
    id: "showers",
    selected: false,
  },
  {
    title: "Truck Parking Spots",
    icon: getIcon(IconType.truckParking, "store-filter-icon"),
    id: "truckParking",
    selected: false,
  },
  {
    title: "Truck Scales",
    icon: getIcon(IconType.truckScales, "store-filter-icon"),
    id: "truckScales",
    selected: false,
  },
];

export function loadStoreOnGoogleMaps(
  address: string,
  city: string,
  state: string,
  zip: string
): void {
  window.open(
    `${
      "https://www.google.com/maps/search/?api=1&query=" +
      address +
      "%2C%20" +
      city +
      "%2C%20" +
      state +
      "%2C%" +
      zip
    }`
  );
}
