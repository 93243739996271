import qs from "qs";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ResetPinRequest, ResetPinTokenExpiredEventRequest } from "assets/dtos/anywhere-dto";

import CreatePin from "components/Account/MyWallet/Pin/CreatePin/CreatePin";
import Header, { HeaderType } from "components/layout/Header/Header";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { generateUuid, resetPinTokenExpired } from "util/Metrics.util";
import { resetUserPin, verifyResetPinToken } from "util/Pin.util";

const ResetPin = (): ReactElement => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  // Break out these methods from appContext to avoid useEffect() lint warning regarding dependencies.
  // Having appContext within the dependencies array is bad - it will cause useEffect() to fire on all renders.
  const hideLoading = appContext.hideLoading;
  const showLoading = appContext.showLoading;

  const [resetToken, setResetToken] = useState<string>("");
  const [pinServerValidationError, setPinServerValidationError] = useState<string>("");
  const [showCreatePin, setShowCreatePin] = useState<boolean>(false);

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) as { rt: string };

  useEffect(() => {
    showLoading();

    if (queryParams.rt) {
      const resetToken = queryParams.rt;

      if (resetToken) {
        setResetToken(resetToken);
        verifyResetPinToken(resetToken).then(
          () => {
            hideLoading();
            setShowCreatePin(true);
          },
          (error) => {
            if (error.response) {
              const resetPinTokenExpiredEventRequest: ResetPinTokenExpiredEventRequest = {
                errorMessage: error.response?.data.message,
                sessionId: generateUuid(),
              };

              resetPinTokenExpired(resetPinTokenExpiredEventRequest);
            }

            hideLoading();
            navigate("/");
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideLoading, showLoading]);

  function resetPin(pinNumber: string): void {
    setPinServerValidationError("");

    const resetPinRequest: ResetPinRequest = {
      pinNumber,
      resetToken: resetToken,
    };

    resetUserPin(resetPinRequest).then(
      () => {
        appContext.showToast("Your PIN has been reset!", "", ToastType.success);
        navigate("/auth/login");
      },
      (error) => {
        if (error.response?.data.message.includes("Your account has been disabled")) {
          appContext.showAlertActionSheet(error.response?.data.message, () => navigate("/"));
        } else {
          setPinServerValidationError(error.response?.data.message);
        }
      }
    );
  }

  function closeCreatePin(): void {
    navigate("/");
  }

  const pinCreateActionSheet = (
    <ActionSheet
      color={ActionSheetColor.currency}
      title={"RESET PIN"}
      shouldDisplay={showCreatePin}
      overlay={true}
      required
    >
      {showCreatePin && (
        <CreatePin
          closeCreatePinCallback={closeCreatePin}
          resetPin
          savePinCallback={resetPin}
          serverValidationError={pinServerValidationError}
        />
      )}
    </ActionSheet>
  );

  return (
    <>
      <Header type={HeaderType.main} title={"Reset PIN"} fixed={true} />
      <ResponsiveLayoutContainer>
        <div>{showCreatePin && pinCreateActionSheet}</div>
      </ResponsiveLayoutContainer>
    </>
  );
};

export default ResetPin;
