import React, { ReactElement } from "react";

import "./ConfirmBuyGiftCard.scss";

import { PaymentMethod } from "assets/dtos/anywhere-dto";

import ConfirmWalletActionBody from "components/Account/MyWallet/ConfirmWalletActionBody/ConfirmWalletActionBody";
import PaymentFooter from "components/Order/PaymentFooter/PaymentFooter";

interface ConfirmBuyGiftCardProps {
  reloadAmount?: number;
  reloadThreshold?: number;
  amount: number;
  paymentMethod: PaymentMethod;
  submitCallback: () => void;
  cancelCallback: () => void;
}

const ConfirmBuyGiftCard = (props: ConfirmBuyGiftCardProps): ReactElement => {
  return (
    <>
      <div className="confirm-gift-card-purchase-container">
        <div className="confirm-gift-card-purchase-container-header">
          <span className="header-row">{"You're buying a new gift card in the amount of:"}</span>
          <span className="amount-header header-row">{"$" + props.amount}</span>
        </div>
        <ConfirmWalletActionBody
          amount={props.amount}
          purchaseNewGiftCard={true}
          reloadAmount={props.reloadAmount}
          reloadThreshold={props.reloadThreshold}
        ></ConfirmWalletActionBody>
        <div className="confirm-gift-card-purchase-footer">
          <PaymentFooter
            fetchPaymentMethods={false}
            total={props.amount}
            onSubmitButtonPressed={props.submitCallback}
            onChangePaymentPressed={props.cancelCallback}
            paymentMethod={props.paymentMethod}
            isReloadGiftCard={true}
          ></PaymentFooter>
        </div>
      </div>
    </>
  );
};

export default ConfirmBuyGiftCard;
