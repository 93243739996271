import { Formik, FormikProps } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import "./Forgot.scss";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzInput from "components/misc/form/SheetzInput/SheetzInput";

import { sendResetPasswordEmail } from "util/Authentication.util";
import { emailValidation } from "util/Validation.util";

interface FormValues {
  email: string;
}

const Forgot = () => {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState<boolean>(false);

  const submitForgotPassword = (values: FormValues): void => {
    sendResetPasswordEmail({ username: values.email }) // This will be enforced with Formik to not be null
      .then(() => {
        setSubmitted(true);
      });
  };

  const ForgotForm = () => {
    if (submitted) {
      return (
        <div className="forgot-form">
          <div className="forgot-message">
            <div className="title">Email Sent</div>
            <div className="message">
              Check your email and follow the provided instructions to recover your password.
            </div>
          </div>
          <SheetzButton
            className="submit-button"
            label="Return to Login"
            onClick={(): void => {
              navigate("/auth/login");
            }}
          />
        </div>
      );
    } else {
      return (
        <div className="forgot-form">
          <div className="forgot-message">
            <div className="title">What&apos;s Your Email?</div>
            <div className="message">
              We&apos;ll email you a link to help recover your password.
            </div>
          </div>
          <Formik
            initialValues={{ email: "" }}
            onSubmit={(values: FormValues): void => {
              submitForgotPassword(values);
            }}
            validationSchema={Yup.object({
              email: emailValidation,
            })}
          >
            {(props: FormikProps<FormValues>) => (
              <form onSubmit={props.handleSubmit}>
                <SheetzInput type="email" name="email" placeholder="Email" label="Email" />
                <SheetzButton className="submit-button" type="submit" label="Send Recovery Email" />
                <div className="cancel">
                  <Link to="/auth/login">Cancel</Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
      );
    }
  };

  return (
    <>
      <ForgotForm />
    </>
  );
};

export default Forgot;
