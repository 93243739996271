import Ravelin from "ravelinjs/core";

import { getRavelinIdFromNativeMobileBridge, isInNativeMobileContext } from "util/MobileApp.util";

function getOrGenerateRavelinId(): Promise<string> {
  // If we're running in a native webview, then we ask the mobile app for the ID.
  if (isInNativeMobileContext()) {
    const ravelinId = getRavelinIdFromNativeMobileBridge();
    // Guard against multiple null-ish values - each mobile platform returns a slightly different null type.
    // It's also possible that the version of the app running is older and doesn't support this bridge method.
    // This results in a value of 'undefined'.
    if (ravelinId !== undefined && ravelinId !== null && ravelinId !== "") {
      return Promise.resolve(ravelinId);
    }
  }

  // Retrieve the ID via the JS library. This will occur outside of webviews and on older app versions.
  const ravelin = new Ravelin({ key: "fake key" });
  return ravelin.core.id();
}

export default getOrGenerateRavelinId;
